import React, { Component, Fragment } from "react";

import { Message, Segment, Header, Grid } from "semantic-ui-react";
import "./index.css";

import Accordian from "./accordian";

class Zotero extends Component {

    render() {
        const { zotero, serach_tag_name } = this.props;
        return (
            <Fragment>
                <Segment>
                    <Message>
                        <Header as="h3" textAlign="center">
                            Reference PDF'S for {serach_tag_name}
                        </Header>
                    </Message>
                    <Grid.Row>
                        <Grid.Column>
                            {
                                zotero && zotero.tag.zotero_data.map(({ title, abstractNote, tags, publication_title, year_of_publication, url }, i) => {
                                    return (
                                        <Accordian key={i}
                                            title={title}
                                            abstractNote={abstractNote}
                                            tags={tags}
                                            publicationTitle={publication_title}
                                            yearOfPublication={year_of_publication}
                                            url={url}
                                        />
                                    )
                                })
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Segment>
            </Fragment>
        )
    }
}

export default Zotero;