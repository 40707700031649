//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: StartFormulation                                    //
// SubSubSubModule: FlourishInfo                                     //
// Tasks: 1) Receives and displays FlourishInfo                      //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import CustomDivider from "../../custom_elements/CustomDivider";
import FlourishOrganism from "./FlourishOrganism";

import React from "react";
import {
  TextArea,
  Table,
  Icon,
  Input,
  Grid,
  Form,
  Popup
} from "semantic-ui-react";

const FlourishInfo = props => {
 return (
    <React.Fragment>
      <CustomDivider h_size="h3" title="Flourish" icon_name="flask" />
      <Grid.Row>
        <Grid.Column>
          <Table celled definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4} />
                <Table.HeaderCell width={3}>
                  Final Amount Added
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Inventory Quantity (lot only)
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>
                  Inventory Quantity (all lots)
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.organisms && props.organisms.map(organism => {
                return (
                  <FlourishOrganism
                    key={organism.id}
                    organism_barcode={ organism.organism_barcode }
                    allergen={organism.allergens}
                    sortQuantity={Math.floor(organism.qty * 1000) / 1000}
                    sortLot={Math.floor(organism.total_lot * 1000) / 1000}
                    final_amount_added={Math.floor(organism.final_amount_added * 1000) / 1000}
                  />
                );
              })}
              <Table.Row>
                <Table.Cell positive width={4}>
                  <Icon name="check circle" />
                  Totals
                </Table.Cell>
                <Table.Cell positive width={3}>
                  {props.total_final_amount_added.toFixed(1)} g
                </Table.Cell>
                <Table.Cell positive width={4}>
                  {/* g */}
                </Table.Cell>
                <Table.Cell positive width={4}>
                  {/* g */}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
      {props.flourish_capsule_size !== "powder" && <Grid.Column>
          <Form.Field>
            <Popup
              trigger={<label>Filled Tube</label>}
              content="Enter the Filled Tube."
            />
            <Input
              id="filled_tube"
              name="filled_tube"
              onChange={props.onModalValueChangePill}
              value={props.filled_tube }

              style={{ width: "70%" }}
              icon="weight"
              iconPosition="left"
              label={{ content: "g" }}
              disabled={(!props.permission)?true : props.flourish_capsule_size === "powder" ? true : false}
              labelPosition="right"
            />
          </Form.Field>
        </Grid.Column> }

        {props.flourish_capsule_size === "powder" && <Grid.Column>

        <Form.Field>
            <Popup
              trigger={<label>Spoon</label>}
              content="Enter the Spoon value."
            />
            <Input
              id="filled_spoon"
              name="filled_spoon"
              onChange={props.onModalValueChangeQcWeight}
              value={props.spoon}

              style={{ width: "70%" }}
              icon="weight"
              iconPosition="left"
              label={{ content: "g" }}
              disabled={(!props.permission)?true :  false}
              labelPosition="right"
            />
          </Form.Field> 
        </Grid.Column> }


        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup
              trigger={<label>QC Weight</label>}
              content="Enter the QC Weight."
            />
            
            <Input
              id="flourish_fill_qc_weight"
              type="number"
              name="flourish_fill_qc_weight"
              // onChange={props.onModalValueChange}
              onChange={props.onModalValueChangeQcWeight}
              // value={(props.flourish_capsule_size == "powder" ? '' :
              value={((props.filled_tube === "" ||
                props.filled_tube === {} ||
                props.filled_tube === undefined ?
                (props.spoon == ""
                 && props.flourish_capsule_size === "powder"
                 && props.flourish_fill_qc_weight == undefined ) ?"":                
                props.flourish_fill_qc_weight  :
                Math.floor(props.flourish_fill_qc_weight * 1000)/1000))}

              style={{ width: "70%" }}
              icon="weight"
              iconPosition="left"
              label={{ content: "g" }}
              labelPosition="right"
              disabled={
                (
                  (!props.permission)?true:
                 (props.filled_tube === "" ||
                  props.filled_tube === undefined
                  || props.filled_tube === null
                  ? props.spoon == "" ?false:true : true))}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field fluid="true">
            <Popup
              trigger={<label>Capsule Size</label>}
              content="It is the Capsule Size."
            />
            <Input
              id="flourish_capsule_size"
              name="flourish_capsule_size"
              className="full_opacity"
              disabled
              value={props.flourish_capsule_size || 0}
              icon="weight"
              iconPosition="left"
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field fluid="true">
            <Popup
              trigger={<label>Total Capsules Made</label>}
              content="Enter Total Capsules Made."
            />
            <Input
              id="flourish_total_capsules_made"
              name="flourish_total_capsules_made"
              onChange={props.onModalValueChange}
              
              disabled={(props.permission)?false:true}
              value={props.flourish_total_capsules_made || ""}
              icon="check"
              iconPosition="left"
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field fluid="true" style={{ width: "80%" }}>
            <Popup
              trigger={<label>Powder Remaining</label>}
              content="Enter the Powder Remaining."
            />
            <Input
              id="flourish_powder_remaining"
              name="flourish_powder_remaining"
              onChange={props.onModalValueChange}
              value={props.flourish_powder_remaining || ""}
              disabled={(props.permission)?false:true}
              icon="weight"
              iconPosition="left"
              label={{ content: "g" }}
              labelPosition="right"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Popup trigger={<label>Admin Notes</label>} content="Admin notes." />
          <TextArea
            id="admin_notes"
            name="admin_notes"
            className="full_opacity"
            disabled
            value={
              props.admin_notes ||
              "No record of Admin Notes being written for this Formulation."
            }
            style={{ minHeight: 100 }}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Popup
            trigger={<label>Operator Notes</label>}
            content="Operator notes."
          />
          <TextArea
            id="operator_notes"
            name="operator_notes"
            className="full_opacity"
            value={props.operator_notes || "Operator Notes"}
            disabled={props.permission?false:true}
            onChange={props.onModalValueChange}
            style={{ minHeight: 100 }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Popup
            trigger={<label>QA Notes</label>}
            content="QA Notes."
          />
          <TextArea
            id="quality_assurance_notes"
            name="quality_assurance_notes"
            className="full_opacity"
            onChange={(e)=>props.onChange(e)}
            value={props.qa_notes}
            disabled={props.permission}
            style={{ minHeight: 100 }}
          />
        </Grid.Column>
      {/* </Grid.Row>} */}
      </Grid.Row>
      
      
    </React.Fragment>
  );
};

export default FlourishInfo;
