//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: Flourish                                            //
// SubSubSubModule: Organisms                                        //
// Tasks: 1) Receives and displays Organisms                         //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import Organism from "./Organism";
import RefillOrganism from "./RefillOrganism";
import CustomDivider from "../custom_elements/CustomDivider";
import React, { Component } from "react";
import { Grid, Icon, Button, Popup } from "semantic-ui-react";
class Organisms extends Component {
  state = {
    organismArray: []
  };
  render() {

    return (
      <Grid columns="equal">

        <CustomDivider
          h_size="h4"
          title="Contained Ingredients"
          icon_name="dna"
        />
        {this.props.formulation_organism && this.props.formulation_organism.map((organism, index) =>
          (organism.inventory && organism.inventory != null) ? (

            <Grid.Row id={organism.id}>

              <RefillOrganism
                key={123}
                is_disabled={false}
                organism={organism}
                index={index}
                refillInventory_id={this.props.refillInventory_id[index]}
                refillamountAdded={this.props.refillamountAdded}
                organismArray={this.state.organismArray}
                onDeleteOrganismClick={this.props.onDeleteOrganismClick}
                //methods
                inventory_options={this.props.inventory_options}
                onChangeOf_organism={this.props.onChangeOf_organism}
                onChange={this.props.onChange}
              />


            </Grid.Row>

          ) : "")}

        <Grid.Row>
          <Grid.Column>
            {this.props.organisms.map(organism => (
              <Organism
                is_disabled={true}
                organism={organism}
                key={organism.organism_barcode}
                manual_add_organism={this.props.manual_add_organism}
                //methods
                onDeleteOrganismClick={this.props.onDeleteOrganismClick}
              />
            ))}
            <br />
            <CustomDivider
              h_size="h4"
              title="New Ingredient"
              icon_name="add circle"
            />
            <Organism
              key={13}
              is_disabled={false}
              disabledPermission={this.props.disabledPermission}
              inventory_options={this.props.inventory_options}
              duplicate={this.props.duplicate}
              selectedOrganism={this.props.selectedOrganism}
              final_amount_added={this.props.final_amount_added}
              total_cfu={this.props.total_cfu}
              organism_barcode={this.props.organism_barcode}
              inventory_id={this.props.inventory_id}
              amount_added={this.props.amount_added}
              concentration={this.props.concentration}
              final_concentration={this.props.final_concentration}
              onChange={this.props.onChange}

              changeStructureFunction={this.props.changeStructureFunction}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column style={{ textAlign: "center" }}>
            <Popup
              trigger={
                <Button
                  animated="vertical"
                  className="small"
                  onClick={this.props.onAddOrganismClick}
                  color="blue"
                  disabled={this.props.disabledPermission}
                >
                  <Button.Content visible>
                    <Icon name="plus circle" />
                    Insert
                  </Button.Content>
                  <Button.Content hidden>
                    More&nbsp;
                    <Icon name="arrow right" />
                  </Button.Content>
                </Button>
              }
              content="Add more Organism(s)."
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Organisms;
