import React, { Component } from "react";
import axios from "axios";
import { Message, Header, Segment, Grid, Button, Icon } from "semantic-ui-react";
import { baseURL, portalURL, portalDev } from "../../../configuration/constants";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./samples.css"; 
import { useLocation } from 'react-router-dom';

import { onGridReady, getHeaders, exportAsCSV, Product_Type } from "../../helper";
// global variables
let api, column_api;

class Samples extends Component {
  is_mounted = true;
  state = {
    samples: [],
    sgNumberInQueue: [],
    pre_formulation: true,
    columnDefs: [
      {
        headerName: "Sample ID",
        field: "sg_number",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 120
      },
      {
        headerName: "Open Report",
        field: "report_id",
        width: 90,
        cellRenderer: (params) => {
          let x = portalDev + params.value
          let target = "_blank"
          return (
            `<a href=${x} target=${target} >
            <i class="fa fa-external-link" aria-hidden="true"></i>
           </a>
            `
          )
        }
      },
      {
        headerName: "Sample Received Date",
        field: "sample_received_date",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 130,
      },
      {
        headerName: "Customer Type",
        field: "customer_type",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        width: 130,
      },
      {
        headerName: "Age",
        field: "profile_dob",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 180,

      },
      {
        headerName: "Product Type",
        field: "product_type",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        width: 250
      },
      {
        headerName: "TimePoint No.",
        field: "sg_number_count",
        filter: "agTextColumnFilter",
        },
    ]
  };
  componentDidMount() {
    this.is_mounted = true;
    this.getQueueSgNubmers();
    this.getSamples();
  }

  getSamples = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    let sample_id = queryParameters.get("sample_id");
    console.log(sample_id);
    if(sample_id !== null && sample_id !== ""){
      sample_id = '/'+sample_id
    }else{
      sample_id ="";
    }
  
    let  url = `${portalURL}/getSGListUpgrade/U3VuX2dlbm9fMTIz${sample_id}`;
    axios
      .get(url)
      .then(res => {
        let data = res.data.data;
        return data;
      })
      .then(samples => {
        let index;
        this.state.sgNumberInQueue.forEach(details => {
            index = samples.findIndex(x => (x.sg_number == details.sample_id && Product_Type[x.product_type] == details.product_type))
          if (index > -1) {
            samples.splice(index, 1);
          }

        })
        if (samples.length > 0) {
          this.setState({
            samples: samples,
            pre_formulation: false
          });
          if(sample_id !== null && sample_id !== ""){
            this.props.handleSelectedSample(samples[0]);
          }
          
        }
      });
  };

  getQueueSgNubmers = () => {
    axios
      .get(baseURL + "/getQueueRecordsFormulation", getHeaders())
      .then(response => {
        if (this.is_mounted) {
          this.setState({ sgNumberInQueue: response.data })
        }
      })

  }

  pastFormulation = () => {
    this.props.openPastFormulas()
  }
  render() {
    return (
      <>
        <Message style={{ marginBottom: "0" }}>
          <Header as="h2" textAlign="center">
            Samples
          </Header>
        </Message>
        <Segment
          compact
          raised
          padded
          style={{ display: "block", marginTop: "0" }}
        >
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Button
                  basic
                  onClick={() => exportAsCSV(api, "samples_to_formulate")}
                  icon
                  labelPosition="right"
                >
                  Export as CSV
                  <Icon name="share square" />
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  basic
                  onClick={() => this.pastFormulation()}
                  icon
                  labelPosition="right"
                  disabled={this.state.pre_formulation}
                >
                  Previous Formulation
                  <Icon name="eye" />
                </Button>

              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <div
                  id="myGrid"
                  className="ag-theme-balham"
                  ag-grid="gridOptions"
                  style={{
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <AgGridReact
                    rowHeight="30"
                    rowSelection="single"
                    onRowClicked={e => {
                      let sample = "";
                      sample = e.data;
                      this.props.handleSelectedSample(sample);
                    }}
                    onGridReady={params => {
                      api = params.api;
                      api.showLoadingOverlay();
                      api.sizeColumnsToFit();
                      column_api = params.column_api;
                      onGridReady(
                        params,
                        api,
                        column_api,
                        this.props.initial_sort
                      );
                    }}
                    animateRows={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationNumberFormatter={params => {
                      return "[" + params.value.toLocaleString() + "]";
                    }}
                    columnDefs={this.state.columnDefs}
                    floatingFilter={true}
                    rowData={this.state.samples}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    );
  }
}

export default Samples;