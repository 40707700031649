import React, { Component } from "react";
import {
  Grid,
  Segment,
  Form,
  Input,
  Button,
  Popup,
  Dropdown,
  Icon
} from "semantic-ui-react";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Select from 'react-select';

class Organism extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organism_barcode: "",
      insertAmount: false,
      all: "",
      array_organism: []
    };
  }

  handleClick = () => {
    document.getElementById("inventory_id").style.backgroundColor = "transparent";
    document.getElementById('amount_added').style.backgroundColor = "transparent";
    document.getElementById('concentration').style.backgroundColor = "transparent";
    document.getElementById('final_concentration').style.backgroundColor = "transparent";
  }

  instanceArr = [];
  organism_array = [];

  onRefreshClick = () => {
    toast.success("Refreshed Successfully");
    this.props.changeStructureFunction()
  }

  changeTag = (tag) => { this.props.onSelectAfterInsert(tag, this.props.organism.organism_barcode); }

  componentWillMount = () => { this.setState({ array_organism: this.organism_array }) }

  render() {

    return (

      <Segment>
        <Grid>

          {this.props.is_disabled && (
            <Grid.Row>
              <Grid.Column width={4}>
                <Form.Field required fluid="true">
                  <Popup
                    trigger={<label>Ingredient Barcode</label>}
                    content="Enter the Ingredient Barcode."
                  />
                  <Input
                    id="inventory_id"
                    name="inventory_id"
                    style={{ opacity: 1, backgroundColor: "#b8fcff" }}
                    disabled={true}
                    value={this.props.organism.organism_barcode}
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Field required fluid="true" >
                  <Popup
                    trigger={<label>Amount Added</label>}
                    content="Enter the amount added."
                  />
                  <Input
                    step="any"
                    type="number"
                    style={{ opacity: 1 }}
                    onChange={(e) => this.props.onChangeDataUp(e, this.props.organism.inventory_id, this.props.organism.organism_barcode)}
                    value={isNaN(this.props.organism.amount_added) ? 0 : Math.floor(this.props.organism.amount_added * 1000) / 1000}
                    label={{ content: "g" }}
                    labelPosition="right"
                    placeholder="Amount Added"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Concentration</label>}
                    content="Enter the Concentration."
                  />
                  <Input
                    id="concentration"
                    name="concentration"
                    onChange={this.props.onChange}
                    value={this.props.organism.concentration}
                    className="full_opacity"
                    disabled
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Concentration"
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column width={4}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Total CFU</label>}
                    content="Enter Total CFU."
                  />
                  <Input
                    id="final_concentration"
                    name="final_concentration"
                    onChange={this.props.onChange}
                    value={this.props.organism.total_cfu}
                    className="full_opacity"
                    disabled
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Total CFU"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={12}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Tags</label>}
                    content="Multiple Select Tags."
                  />
                  <Select
                    options={this.props.organism.nonSelectedTag.filter(x => !this.props.organism.tags.find(y => (y.label == x.label)))}
                    value={this.props.organism.tags}
                    onChange={this.changeTag}
                    isMulti={true}
                  />

                </Form.Field>
              </Grid.Column>

              <Grid.Column width={1} style={{ textAlign: "right" }}>
                <Form.Field fluid="true">
                  <label style={{ visibility: "hidden" }}>.</label>
                  <Popup
                    trigger={
                      <Button
                        onClick={() => {
                          this.props.onDeleteOrganismClick(
                            this.props.organism.organism_barcode,
                            this.props.organism.inventory_id
                          );
                        }}
                        icon="delete"
                        negative
                        circular
                      />
                    }
                    content="Delete this Organism."
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

          )}


          {!this.props.is_disabled && (
            <Grid.Row>
              <Grid.Column width={13}>
                <Form.Field required fluid="true" >
                  <Button
                    id="deleteRecordsButton"
                    basic
                    onClick={this.onRefreshClick}
                    icon
                    labelPosition="right"
                    style={{ marginBottom: "15px" }}
                  >
                    Refresh Inventory
                    <Icon name="refresh" />
                  </Button>
                </Form.Field>
              </Grid.Column>


              <Grid.Column width={4} className="ingredient-barcode">
                <Form.Field required fluid="true">
                  <Popup
                    trigger={<label>Ingredient Barcode</label>}
                    content="Enter the Ingredient Barcode."
                  />
                  <Dropdown
                    id="inventory_id"
                    name="inventory_id"
                    style={{ opacity: 1, backgroundColor: "#b8sfcff" }}
                    onClick={this.handleClick}
                    value={this.props.inventory_id}
                    
                    onChange={(event, data) => {
                      let organism_barcode = "";
                      data.options.forEach(inventory => {
                        if (inventory.key === data.value) {
                          organism_barcode = inventory.text;
                        }
                      });
                      this.props.onChange(event, {
                        name: "organism_barcode",
                        value: organism_barcode
                      });
                      this.props.onChange(event, {
                        name: data.name,
                        value: data.value
                      });
                    }}
                    fluid
                    search
                    selection
                    options={
                      this.instanceArr = [],
                      this.props.inventory_options.filter((instance, index) => {
                        if (this.props.inventory_options[index]['active'] !== 0) {
                          this.instanceArr.push({
                            key: instance.key,
                            value: instance.value,
                            active: instance.active,
                            text: instance.text,
                            icon: "dna"
                          })
                          return this.instanceArr;
                        }
                      })
                    }

                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Field required fluid="true" >
                  <Popup
                    trigger={<label>Amount Added</label>}
                    content="Enter the amount added."
                  />
                  <Input
                    id="amount_added"
                    type="number"
                    style={{ opacity: 1 }}
                    name="amount_added"
                    onChange={this.props.onChange}
                    disabled={this.props.disabledPermission ? this.props.disabledPermission : this.props.is_disabled}
                    value={this.props.amount_added}
                    onClick={this.handleClick}
                    icon="weight"
                    iconPosition="left"
                    label={{ content: "g" }}
                    labelPosition="right"
                    placeholder="Amount Added"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Concentration</label>}
                    content="Enter the Concentration."
                  />
                  <Input
                    id="concentration"
                    name="concentration"
                    onChange={this.props.onChange}
                    value={this.props.concentration}
                    className="full_opacity"
                    disabled
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Concentration"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={4}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Total CFU</label>}
                    content="Enter Total CFU."
                  />
                  <Input
                    id="final_concentration"
                    name="final_concentration"
                    onChange={this.props.onChange}
                    value={this.props.concentration * this.props.amount_added}
                    className="full_opacity"
                    disabled
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Total CFU"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={15}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Tags</label>}
                    content="Multiple Select Tags."
                  />
                  <Select
                    options={this.props.multiSelectData}
                    onChange={this.props.onSelect}
                    isMulti={true}
                    ref={this.props.multiselectRef}
                  />


                </Form.Field>
              </Grid.Column>

              <Grid.Column width={1} style={{ textAlign: "right" }}>
                <Form.Field fluid="true">
                  <label style={{ visibility: "hidden" }}>.</label>
                  <Popup
                    trigger={
                      <Button
                        icon="delete"
                        disabled
                        negative
                        circular
                      />
                    }
                    content="Delete this Organism."
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}

        </Grid>
      </Segment>
    );
  }
}

export default Organism;
