import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import "./flore.css"
import axios from "axios";

import { Button, Form, Grid, Header, Input, Message,Loader, Popup, Segment, Radio } from "semantic-ui-react";
import { getHeaders, onGridReady } from "../../helper";
import { baseURL } from "../../../configuration/constants";
import { toast } from "react-toastify";
import config from "../../../configuration/config";
import CustomLoader from "../../custom_elements/CustomLoader";

var api, column_api;

var output;
var filterOptio = ['Turmar','Soy'];
const self = this;

// class ManageAllergens extends Component 
class ManageAllergens extends Component {
    
    is_mounted = true;
    constructor(props) {
        super(props);
        this.state = {
            is_confirm_open: false,
            getDeleteId: "",
            deleteMsg: "",
            allergen: "",
            samples: [],
            allergen_id: [],
            update_allergen_id:"",
            is_btn_loading:true,
            is_page_loading: true,
            text_update:false,
            show_on_label:false,
            columnDefs: [

                {
                    headerName: "",
                    field: "",
                    checkboxSelection: true,
                    width: 40,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                },
                {
                    headerName: "Allergen Name",
                    field: "allergen",
                    width: 150,
                    pinned: "center",
                    filter: "agTextColumnFilter",
                    align:"center",
                },
                {
                    headerName: "Allergen Image",
                    width: 150,
                    pinned: "center",
                    cellRendererFramework: function (params) {
                        return <img width="20px" src={params.data.image}/>
                    },
                   },
                {
                    headerName: "Show on label?",
                    width: 150,
                    pinned: "center",
                    cellRendererFramework: function (params) {
                        console.log((params));
                        return params.data.status == 1?"Yes":"No"
                    },
                   },
                ],
            is_popUp_disabled:false,
            btnName:"Submit",
            allergen_color:"",
            allegen_img:"",
            headerMsg:"Add Allergens"
        };
    }
    componentDidMount = () => {
        document.title = config.formulations.title.allergean;
        this.agGrid();
        output = document.getElementById("output");
    }
    agGrid = () => {
         axios
            .get(baseURL + '/allergens', getHeaders())
            .then(res => {

                api.showLoadingOverlay();
                if (res.data.data.length > 0) {
                    this.setState({ samples: res.data.data })
                    api.hideOverlay();
                    this.setState({ is_page_loading: false })
                }
                else {
                    api.showNoRowsOverlay();
                    api.hideOverlay();
                    this.setState({ is_page_loading: false })
                }
        })
    }

    cellValueChaged = () => {

        var samples = [...this.state.samples]
        var allergeanName = false;
            
        if(this.state.text_update){
           samples.map((data) => {
            if (data.allergen.toLowerCase() == this.state.allergen.toLowerCase()) {
                allergeanName = true;
                return;
            }
            });
        }

        if (allergeanName) {
            toast.warning("This allergen name already exists");
            return;
        }
        var x  = window.confirm('Are you sure? Do you want to update this allergen?')
        
        if(!x) return;
        this.setState({is_page_loading :true});

        var samples = [...this.state.samples]
       var payload = { allergen: this.state.allergen, color:this.state.allergen_color,status:this.state.show_on_label};

       if(this.state.allegen_img !== ''){
        payload.image = this.state.allegen_img
       }
        axios
            .post(baseURL + "/allergens/" + this.state.update_allergen_id, payload, getHeaders())
            .then(res => {
                this.setState({is_page_loading :false});
                this.resetAllergen();
                samples = samples.map((item)=>{
                    if(item.id == this.state.update_allergen_id){
                        item = res.data.data      
                    }
                    return item;
                })
                this.setState({samples});
                toast.success("Supplement has been updated successfully");
            })
    }
    onRowSelected = (value) => {
        document.getElementById("allergen_file").value=""
        
        var x = this.state.samples.filter((item)=>item.id == value.data.id)[0]; 
        var allergen_id = this.state.allergen_id;
        if (value.node.selected) {
            if (this.state.allergen_id.indexOf(value.data.id) == -1) {
                allergen_id.push(value.data.id);
            }
            output.src=value.data.image
            this.setState({allergen:x.allergen,
                btnName:"Update Allergen",
                update_allergen_id:x.id,
                // allergen_color:value.data.color,
                allergen_img:value.data.image,
                show_on_label:(value.data.status == 1 )&& true,
                headerMsg:"Update Allergens"
            });

        }
        else {
            allergen_id = allergen_id.filter(data => data !== value.data.id)
        }
        this.setState({ allergen_id })

    }

    submitAllergey = () => {
        if (this.state.allergen == "") {
            toast.warning("Please Enter Allergens Name")
            return;
        }
        var samples = [...this.state.samples]
        var allergeanName = false;
            samples.map((data) => {
            if (data.allergen.toLowerCase() == this.state.allergen.toLowerCase()) {
                allergeanName = true;
                return;
            }
        });
        if (allergeanName) {
            toast.warning("This allergen already exists");
            return;
        }
        var payload = { allergen: this.state.allergen,
                        color:this.state.allergen_color,
                        status:this.state.show_on_label,
            image:this.state.allegen_img };

            this.setState({is_page_loading :true});

        axios.post(baseURL + '/allergens',payload, getHeaders())
            .then(res => {
                document.getElementById('output').src="";
                document.getElementById("allergen_file").value=""
                this.setState({is_page_loading :false,
                    text_update:false});

                if (res.data.success) {
                    samples.push(res.data.data)
                    output.src= ''
                    this.setState({ samples, allergen: "",allergen_color:"",allegen_img:"",show_on_label:false });
                    toast.success(res.data.msg);
                }
                else {
                    toast.error("Something Goes Wrong");
                }
            })

    }
   
    deleteAllergey = () => {
        this.resetAllergen();
        axios
            .post(baseURL + "/delete_allergens", {
                "ids": this.state.allergen_id
            }, getHeaders())
            .then(data => {
                if (data.data.success) {
                    toast.success(data.data.msg);
                    this.setState({ samples: [], allergen: "" });
                    this.agGrid()
                }
                else {
                    toast.error(data.msg);
                }
            })
            .catch(err => toast.error("Something Goes Wrong"))
    }


    fileUploader = (e)=>{
        var fuData = document.getElementById('allergen_file');
        var FileUploadPath = fuData.value;
        const self = this;
       
        if (FileUploadPath == '') {
        toast.warning("Please upload an image");
        return;
        }   
       
        if(fuData.size >60){ toast.warning("Please upload an image"); return;}
        else {
        var Extension = FileUploadPath.substring(FileUploadPath.lastIndexOf('.') + 1).toLowerCase();
        if (Extension == "png") {
        if (fuData.files && fuData.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
        output.src = e.target.result;
        self.setState({ allegen_img:e.target.result }) 
        }
        reader.readAsDataURL(fuData.files[0]);
        }
        } 
        else {
            toast.error("Select file upload correct");
            this.setState({allegen_img:""});
            output.src= "";
        }
        }
        console.log(output);
    }
    resetAllergen = ()=>{
        output.src = "";
        this.setState({
            allergen:"",
            allergen_id:[],
            allegen_img:"",
            btnName:"Submit",
            headerMsg:"Add Allergens",
            text_update:false,
            show_on_label:false,
        })

        document.getElementById("allergen_file").value='';
    }
    render() {
  
        const { samples, columnDefs, allergen } = this.state;
        return (
            <>
            {this.state.is_page_loading && <CustomLoader />}
            <br/><br/>
            
                <Grid columns="equal">
                    <Message style={{ width:"100%",marginBottom: "0", marginTop: "10px" }}>
                    <Header as="h3" textAlign="center">
                        Manage Allergens
                    </Header>
                    </Message>
                </Grid>
                <br/><br/>
                        <Grid>
                            <Grid.Row>
                        <br/><br/>
                                <Grid.Column className="h-100" style={{width:"50%"}}>
                                <Form className="h-100">
                                <Segment className="h-100">
                                <Header as="h3" textAlign="center">
                                        {this.state.headerMsg}
                                    </Header>
                                    <Form.Field required fluid="true" >
                                        <Popup

                                            trigger={<label>Allergens Name</label>}
                                            content="Enter the Allergens Name."
                                        />
                                        <div className="form-group mb-20 allergen-input-box">
                                            <Input
                                                id="allergiesName"
                                                name="allergiesName"
                                                onChange={(e) =>{
                                                    // if(this.state.btnName !== "Submit" && this.state.)

                                                    this.setState({ allergen: e.target.value,text_update:true })
                                                    
                                                    }}
                                                value={allergen}
                                                icon="nutritionix"
                                                iconPosition="left"
                                                placeholder="Enter Allergens Name"
                                            />
                                        </div>
                                        
                                        <div className="form-group mb-20 allergen-toggle-btn">
                                        <Form.Field fluid="true">
                                        <Popup
                                        // className="remove_asterik_allergen"
                                        trigger={<label>Show on label?</label>}
                                        content="Show on label?"
                                        />
                                        <Radio
                                        toggle
                                        className="allergen-radio-btn"
                                        onClick={()=>this.setState({show_on_label:!this.state.show_on_label})}
                                        checked={this.state.show_on_label}
                                        />
                                    </Form.Field>
                                        </div>
                                        <div className="form-group mb-20">
                                            <div  className="allergen-file-block">
                                                <input
                                                    id="allergen_file"
                                                    name="allergen_file"
                                                    type="file"
                                                    onChange={(e) => { this.fileUploader(e) } }
                                                />
                                                <Button className="choose-file-btn" primary>Choose file</Button>
                                            </div>
                                            <div className="uploadImgbox allergen-img">
                                                <img  id="output" alt="" />
                                            </div>
                                        </div>
                                    </Form.Field>
                                    <br />
                                    <Grid textAlign="center" style={{ paddingBottom: "13px", }}>
                                        <Button.Group >
                                            <Popup
                                                trigger={
                                                    (this.state.is_btn_loading)?
                                                    <Button
                                                        content={this.state.btnName}
                                                        icon="check"
                                                        labelPosition="left"
                                                        positive
                                                        onClick={
                                                            this.state.btnName !== "Submit"
                                                            ?this.cellValueChaged:
                                                            this.submitAllergey}
                                                    />:<Loader />
                                                }
                                                content="Enter the Manage Allergens Information."
                                            />
                                        </Button.Group>
                                        <Button.Group >
                                            <Popup
                                                trigger={
                                                    <Button
                                                        content="Reset"
                                                        icon="check"
                                                        labelPosition="left"
                                                        primary
                                                        disabled={!(this.state.allergen.length>0)}
                                                        onClick={()=>{ this.resetAllergen()}}
                                                    />
                                                }
                                                content="Reset Allergens Information."
                                            />
                                        </Button.Group>
                                    </Grid>
                                    
                                </Segment>

                            </Form>
                </Grid.Column>
                <Grid.Column style={{width:"50%"}}>
                  
                
                <Segment
                compact
                raised
                padded
                style={{ display: "block", marginTop: "0" }}>
    <Grid columns="equal">


        <button
            className="ui red basic button text-left"
            style={{ paddingRight: "0 !important", marginLeft: "32.2rem" }}
            onClick={()=>{

                var r = window.confirm("Are you sure? Do you want to delete this allergen?");  
                if (r) {  
                    this.deleteAllergey();
                }      
            }
            
            }
        >Delete</button>
         <Grid.Row>

            <Grid.Column>
                    <input type="file" name="" id="inputFile"
                    onChange={()=>{console.log("asdf")}}
                    style={{display:"none"}}/>

                <div
                    id="myGrid"
                    className="ag-theme-balham"
                    ag-grid="gridOptions"
                    style={{
                        height: "100%",
                    }}
                >

                    <AgGridReact
                        rowSelection={'multiple'}
                        onGridReady={params => {
                            api = params.api;

                            column_api = params.column_api;
                            onGridReady(
                                params,
                                api,
                                column_api,
                                this.props.initial_sort
                            );
                        }}
                        onRowSelected={this.onRowSelected}
                        onCellValueChanged={this.cellValueChaged}
                        animateRows={true}
                        pagination={true}
                        paginationPageSize={13}
                        paginationNumberFormatter={params => {
                            return "[" + params.value.toLocaleString() + "]";
                        }}
                        overlayNoRowsTemplate={
                            '<span className="ag-overlay-loading-center">Please wait while your data are Fetching....</span>'
                        }
                        overlayLoadingTemplate={
                            '<span className="ag-overlay-loading-center">No Data Found</span>'
                        }
                        cellEditor={true}
                        editable={true}
                        floatingFilter={true}
                        columnDefs={columnDefs}
                        rowData={samples}
                    />
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
</Segment>

                </Grid.Column>
           </Grid.Row>
           </Grid>

            </>
    );
    }
}
export default ManageAllergens