//-------------------------------------------------------------------//
// Module: ColumnDefs                                                //
// SubModule: FormulationsOperatorColumnDefs                         //
// Tasks: 1) Returns an Array of Formulations Operator Column Defs   //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

export function getFormulationsOperatorColumnDefsArray(
  converted_user_options_json,
  all_user_ids,
  dateComparator,
  formualtion_type,
) {
  let is_chrome = sessionStorage.getItem("is_chrome") === true;
  let editablePermission = localStorage.getItem("displayFormulation");
  return [

    // {
    //   headerName: "id",
    //   field: "id",
    //   hide: true
    // },
    // {
    //   headerName: "inventory_id",
    //   field: "inventory_id",
    //   hide: true
    // },
    // {
    //   headerName: "organisms_id",
    //   field: "organisms_id",
    //   hide: true
    // },
    // {
    //   headerName: "Ingredients",
    //   field: "organisms",
    //   hide: true
    // },
    {
      headerName: "Formulation Info",
      children: [
        {
          headerName: "Sample ID",
          field: "sample_id",
          filter: "agTextColumnFilter",
          pinned: "left",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          editable: editablePermission ? true : false,
          width: 145
        },
        {
          headerName: "Formula ID",
          field: "formula_id",
          filter: "agTextColumnFilter",
          pinned: "left",
          editable: editablePermission ? true : false,
          width: 130
        },
        {
          headerName: "Type",
          field: "type",
          editable: false,
          pinned: "left",
          filter: "agNumberColumnFilter",
          width: 150
        },
        {
          headerName: "Product Type",
          field: "product_type",
          editable: false,
          pinned: "left",
          filter: "agNumberColumnFilter",
          width: 150
        },
        {
          headerName: "Number of Months",
          field: "number_of_months",
          type: "numericColumn",
          editable: editablePermission ? true : false,
          filter: "agNumberColumnFilter",
          width: 150
        },
        // {
        //   headerName: "Formulation Factor",
        //   field: "formulation_factor",
        //   type: "numericColumn",
        //   editable: false,
        //   filter: "agNumberColumnFilter",
        //   width: 150
        // },

        {
          headerName: "Admin Notes",
          field: "admin_notes",
          editable: false,
          filter: "agTextColumnFilter",
          width: 150
        },
        {
          headerName: "Operator Notes",
          field: "operator_notes",
          editable: editablePermission ? true : false,
          filter: "agTextColumnFilter",
          width: 150
        },
        {
          headerName: "Is Prime Added?",
          field: "is_prime",
          editable: editablePermission ? false : true,
          filter: "agTextColumnFilter",
          width: 150
        },
      ]
    },
    //dates
    {
      headerName: "",
      children: [

        {
          headerName: "Capsule Size",
          field: "flourish_capsule_size",
          editable: true,
          width: 110
        },
      ]
    },
    {
      headerName: "Dates",
      children: [

        {
          headerName: "Date Created",
          field: "date_created",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 110
        },
        // {
        //   headerName: "Date Made",
        //    //headerName: "Probiotics Shipped Date",
        //   field: "date_made",
        //   filter: is_chrome ? "agDateColumnFilter" : false,
        //   editable:editablePermission?true:false,
        //   comparator: dateComparator,
        //   width: 110
        // },
      ]
    },
    //prime
    // {
    //   headerName: "Prime",
    //   children: [
    //     {
    //       headerName: "Flourish Amount Added",
    //       field: "prime_flourish_amount_added",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 145
    //     },
    //     {
    //       headerName: "Filler",
    //       field: "prime_filler_inventory_id",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 130
    //     },
    //     {
    //       headerName: "Filler Amount Added",
    //       field: "prime_filler_amount_added",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 145
    //     },
    //     {
    //       headerName: "Total Amount Added",
    //       field: "prime_total_amount_added",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 130
    //     },

    //     {
    //       headerName: "Fill QC Weight",
    //       field: "prime_fill_qc_weight",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 150
    //     },
    //     {
    //       headerName: "Capsule Size",
    //       field: "prime_capsule_size",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 120
    //     },
    //     {
    //       headerName: "Total Capsules Made",
    //       field: "prime_total_capsules_made",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 150
    //     },
    //     {
    //       headerName: "Powder Remaining",
    //       field: "prime_powder_remaining",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 145
    //     }
    //   ]
    // },

    //flourish
    // {
    //   headerName: "Flourish",
    //   children: [
    //     {
    //       headerName: "Fill QC Weight",
    //       field: "flourish_fill_qc_weight",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 130
    //     },

    //     {
    //       headerName: "Capsule Size",
    //       field: "flourish_capsule_size",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 120
    //     },
    //     {
    //       headerName: "Total Capsules Made",
    //       field: "flourish_total_capsules_made",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 150
    //     },
    //     {
    //       headerName: "Powder Remaining",
    //       field: "flourish_powder_remaining",
    //       type: "numericColumn",
    //       editable: true,
    //       filter: "agNumberColumnFilter",
    //       width: 145
    //     }
    //   ]
    // },

    //total info
    {
      headerName: "Total Info",
      children: [
        // {
        //   headerName: "Total Amount Added",
        //   field: "total_amount_added",
        //   type: "numericColumn",
        //   editable: false,
        //   filter: "agNumberColumnFilter",
        //   width: 155
        // },
        {
          headerName: "Total Final Amount Added",
          field: "total_final_amount_added",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 190,
          cellRenderer: (params) => {
            return (
              `
              <div>
              ${parseFloat(params.value).toFixed(3)}
              </div>
              `
            )
          }
        }
      ]
    },

    //timestamp
    {
      headerName: "Logs",
      children: [
        {
          headerName: "Created At",
          field: "created_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 150
        },
        {
          headerName: "Last Updated At",
          field: "updated_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 150
        },
        // {
        //   headerName: "Deleted At",
        //   field: "deleted_at",
        //   filter: is_chrome ? "agDateColumnFilter" : false,
        //   editable: false,
        //   comparator: dateComparator,
        //   width: 150
        // },
        {
          headerName: "Username",
          field: "user_id",
          filter: "agTextColumnFilter",
          editable: false,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: all_user_ids
          },
          refData: converted_user_options_json,
          width: 150

          // refData: { 0: "yash", 1: "jash", 2: "kash" }
        }
      ]
    },
    // {
    //   headerName: "Is Completed?",
    //   field: "is_completed",
    //   filter: true,
    //   editable: true,
    //   cellEditor: "agSelectCellEditor",
    //   cellEditorParams: {
    //     values: [0, 1]
    //   },
    //   refData: { 0: "No", 1: "Yes" },
    //   width: 135
    // },

    // {
    //   headerName: "Start Formulation",
    //   field: "start_formulation",
    //   filter: false,
    //   editable: false,
    //   width: 150
    // }
  ]; //formulation-operator end;
}
