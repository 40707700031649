import React, { Component } from "react";

import "./index.css";

export class HoverTagsData extends Component {
    render() {
        const { tags } = this.props;
        return (
            <>

                <div className={(this.props.previous_formula) ? "tooltip-a pos-bottom" : "tooltip-a"} style={{ position: "relative" }}>
                    <span>{this.props.tags[0].tag_name}</span>
                    <div className={(this.props.previous_formula) ? "tooltiptext-a width-change" : "tooltiptext-a"}>
                        <ul>{
                            tags && tags.map((e, index) => {
                                return (
                                    <li>
                                        {e.tag_name}
                                    </li>

                                )
                            })
                        }</ul>
                    </div>
                </div>
            </>

        )
    }
}

export default HoverTagsData;