import React, { Component } from 'react'
import labelImg from '../../../images/10-right-screen.jpg'
import { Form, Input } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

class AdditionOfFlourishIngredients extends Component {
    state = {
        performed_by_pin: "", confirmed_by_pin: "", performed_by: "", performed_by_name: "", confirmed_by: "", confirmed_by_name: "", formErrors: { performed_by_pin: false, confirmed_by_pin: false },
        performed_by_pin_mixing: "", confirmed_by_pin_mixing: "", performed_by_mixing: "", performed_by_name_mixing: "", confirmed_by_mixing: "", confirmed_by_name_mixing: "", formErrors_mixing: { performed_by_pin_mixing: false, confirmed_by_pin_mixing: false }
    }

    componentDidMount() {
        this.getCurrentStep('addition_of_flourish_ingredients');
        this.getCurrentStepMixing('mixing_of_flourish_ingredients');
    }
    getCurrentStep = (step_name) => {
        let req = {
            formula_id: this.props.selected_row_value.formula_id,
            step_name: step_name
        }
        axios.post(baseURL + "/get-current-step", req, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true && result.current_step !== null) {
                    let current_step = result.current_step;
                    let step_json = JSON.parse(current_step.step_json);
                    this.setState({ step_current: current_step });
                    this.onInputChange(0)(null, { name: "performed_by_pin", "value": current_step.performed_by != null ? current_step.performed_by : "" });
                    this.onInputChange(0)(null, { name: "confirmed_by_pin", "value": current_step.confirmed_by != null ? current_step.confirmed_by : "" });
                }
            })
    }

    getCurrentStepMixing = (step_name) => {
        let req = {
            formula_id: this.props.selected_row_value.formula_id,
            step_name: step_name
        }
        axios.post(baseURL + "/get-current-step", req, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true && result.current_step !== null) {
                    let current_step = result.current_step;
                    let step_json = JSON.parse(current_step.step_json);
                    this.setState({ step_current_mixing: current_step });
                    this.onInputChange(0)(null, { name: "performed_by_pin_mixing", "value": current_step.performed_by != null ? current_step.performed_by : "" });
                    this.onInputChange(0)(null, { name: "confirmed_by_pin_mixing", "value": current_step.confirmed_by != null ? current_step.confirmed_by : "" });
                }
            })
    }

    handleClick = () => {
        // Call the function passed from the parent
        this.props.onPrevClick('weighing_of_flourish_ingredients');
    };

    handleNextClick = () => {
        // Call the function passed from the parent
        // this.props.onNextClick('mixing_of_flourish_ingredients')   
        this.props.onNextClick('weight_of_mixed_flourish_ingredients')
    };

    handlePauseClick = () => {
        this.props.onNextClick('pause');
    }
    handleQuitClick = () => {
        this.props.onNextClick('quit');
    }

    onInputChange = (i) => (e, { name, value }) => {
        switch (name) {
            case 'performed_by_pin':
                if (value.length >= 3) {
                    if (this.state.confirmed_by_pin !== value) {
                        this.getPerformUser(value, 'performer');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ performed_by: "", performed_by_name: "" });
                }
                break;
            case 'confirmed_by_pin':
                if (value.length >= 3) {
                    if (this.state.performed_by_pin !== value) {
                        this.getPerformUser(value, 'confirmer');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ confirmed_by: "", confirmed_by_name: "" });
                }
                break;
            case 'performed_by_pin_mixing':
                if (value.length >= 3) {
                    if (this.state.confirmed_by_pin_mixing !== value) {
                        this.getPerformUser(value, 'performer_mixing');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ performed_by_mixing: "", performed_by_name_mixing: "" });
                }
                break;
            case 'confirmed_by_pin_mixing':
                if (value.length >= 3) {
                    if (this.state.performed_by_pin_mixing !== value) {
                        this.getPerformUser(value, 'confirmer_mixing');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ confirmed_by_mixing: "", confirmed_by_name_mixing: "" });
                }
                break;
            default:
                break;
        }

        this.setState({ [name]: value });

    };
    getPerformUser = (pin, user_type) => {
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
        axios.post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
            .then(res => {
                if (res.data.success === true) {
                    let user = res.data.user;
                    if (user_type === 'performer') {
                        this.setState(prevState => ({
                            performed_by: user ? user.id : "",
                            performed_by_name: user ? user.full_name : "",
                            formErrors: {
                                ...prevState.formErrors,
                                performed_by_pin: user ? false : true
                            }
                        }));
                    } else if (user_type === 'performer_mixing') {
                        this.setState(prevState => ({
                            performed_by_mixing: user ? user.id : "",
                            performed_by_name_mixing: user ? user.full_name : "",
                            formErrors_mixing: {
                                ...prevState.formErrors_mixing,
                                performed_by_pin_mixing: user ? false : true
                            }
                        }));
                    } else if (user_type === 'confirmer_mixing') {
                        this.setState(prevState => ({
                            confirmed_by_mixing: user ? user.id : "",
                            confirmed_by_name_mixing: user ? user.full_name : "",
                            formErrors_mixing: {
                                ...prevState.formErrors_mixing,
                                confirmed_by_pin_mixing: user ? false : true
                            }
                        }));
                    } else {
                        this.setState(prevState => ({
                            confirmed_by: user ? user.id : "",
                            confirmed_by_name: user ? user.full_name : "",
                            formErrors: {
                                ...prevState.formErrors,
                                confirmed_by_pin: user ? false : true
                            }
                        }));
                    }

                    //this.setState({user_full_name:user.full_name}); 
                }
            })
    }

    handleSubmit = () => {
        this.setState((prevState) => ({
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                performed_by_pin: !this.state.performed_by_name ? true : false,
                confirmed_by_pin: !this.state.confirmed_by_name ? true : false
            },
            formErrors_mixing: {
                ...prevState.formErrors_mixing, // spread the existing formErrors
                performed_by_pin_mixing: !this.state.performed_by_name_mixing ? true : false,
                confirmed_by_pin_mixing: !this.state.confirmed_by_name_mixing ? true : false
            }
        }));
        if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
            toast.error("PIN is missing");
            return;
        }

        if ((!this.state.performed_by_name && this.state.performed_by_pin) ||
            (!this.state.confirmed_by_name && this.state.confirmed_by_pin)) {
            toast.error("PIN is incorrect");
            return;
        }

        if (!this.state.confirmed_by_pin_mixing || !this.state.performed_by_pin_mixing) {
            toast.error("PIN is missing");
            return;
        }

        if ((!this.state.performed_by_name_mixing && this.state.performed_by_pin_mixing) ||
            (!this.state.confirmed_by_name_mixing && this.state.confirmed_by_pin_mixing)) {
            toast.error("PIN is incorrect");
            return;
        }

        let param = {
            sample_id: this.props.selected_row_value.sample_id,
            formula_id: this.props.selected_row_value.formula_id,
            step: 10,
            step_json: "",
            step_name: 'addition_of_flourish_ingredients',
            step_next: 'weight_of_mixed_flourish_ingredients',
            performed_by: this.state.performed_by_pin,
            confirmed_by: this.state.confirmed_by_pin
        }
        axios.post(baseURL + "/mmr-formulations", param, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true) {
                    // toast.success("Successfully saved Step");
                    // this.props.onNextStep();
                    this.handleSubmitMixing()
                } else {
                    toast.error("Step has not saved");
                }
            })
    }

    handleSubmitMixing = () => {
        let param = {
            sample_id: this.props.selected_row_value.sample_id,
            formula_id: this.props.selected_row_value.formula_id,
            step: 11,
            step_json: "",
            step_name: 'mixing_of_flourish_ingredients',
            step_next: 'weight_of_mixed_flourish_ingredients',
            performed_by: this.state.performed_by_pin_mixing,
            confirmed_by: this.state.confirmed_by_pin_mixing
        }
        axios.post(baseURL + "/mmr-formulations", param, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true) {
                    toast.success("Successfully saved Step");
                    this.props.onNextStep();
                } else {
                    toast.error("Step has not saved");
                }
            })
    }

    render() {
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div className='cs-box'>
                        <div className='cs-body'>
                            <h3>Addition of Flourish Ingredients</h3>
                            <div className='mt-2 row'>
                                <div className='col-md-6 border-r'>
                                    <ul className='cs-list'>
                                        <li>Perform visual inspection of weight out ingredients for foreign materials.</li>
                                        <li>Combine weight out ingredients in vestibule and confirm in BPR.</li>
                                        <li>Pour into smaller half of cocktail shaker. </li>
                                        <li>Repeat previous two steps for rest of probiotics.</li>
                                        <li>Initial “Ingredient Weight QC” field on Batch Record and QC Checklist.</li>
                                        <li>Have secondary employee review and confirm probiotic weighing before mixing.</li>
                                        <li>Secondary employee shall initial as the secondary confirmation in “Ingredient Weight QC”. </li>
                                        <li>Return probiotics to proper place in refrigerator once weighing is complete.</li>
                                    </ul>
                                </div>
                                <div className='col-md-6'>
                                    <img className='mxw-100' src={labelImg} alt='label' />
                                </div>
                            </div>
                            <div className='text-center mt-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={this.state.formErrors.performed_by_pin}
                                                    id="performed_by_pin"
                                                    name="performed_by_pin"
                                                    onChange={this.onInputChange(0)}
                                                    value={this.state.performed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-2 mb-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={this.state.formErrors.confirmed_by_pin}
                                                    id="confirmed_by_pin"
                                                    name="confirmed_by_pin"
                                                    onChange={this.onInputChange(0)}
                                                    value={this.state.confirmed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                                {this.props.step_name === 'weighing_of_flourish_ingredients' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                                {this.props.step_name !== "weighing_of_flourish_ingredients" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
                            </div>
                        </div> */}
                    </div>
                </Form>
                <Form onSubmit={this.handleSubmit}>
                    <div className='cs-box'>
                        <div className='cs-body'>
                            <h3>Mixing of Flourish Ingredients</h3>
                            <div className='mt-2 row'>
                                <div className='col-md-6 border-r'>
                                    <ul className='cs-list'>
                                        <li>Mix Flourish Probiotics:</li>
                                        <li>Place shaker ball into smaller half of probiotic shaker and cover with larger half.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Vigorously shake powder for 10 - 20 seconds to thoroughly mix. </li>
                                        <li>Utilize timer to ensure material is mixed for at least 10 seconds.</li>
                                        <li>NOTE: Powdering and Pilling may be completed by two different operators at different workstations. If this is the case Powders shall be added to new single use stand up barrier pouches (Part Number: MAN_000016), sealed, and transported in same bin as packaging material.</li>
                                        <li>If a queue forms the bins shall be placed in order of completion.</li>
                                        <li>Unfinished formulations shall be stored on the in-process rack and stored in walk in fridge.</li>
                                    </ul>
                                </div>
                                <div className='col-md-6'>
                                    <img className='mxw-100' src={labelImg} alt='label' />
                                </div>
                            </div>
                            <div className='text-center mt-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.performed_by_name_mixing}</u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={this.state.formErrors_mixing.performed_by_pin_mixing}
                                                    id="performed_by_pin_mixing"
                                                    name="performed_by_pin_mixing"
                                                    onChange={this.onInputChange(0)}
                                                    value={this.state.performed_by_pin_mixing}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-2 mb-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.confirmed_by_name_mixing} </u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={this.state.formErrors_mixing.confirmed_by_pin_mixing}
                                                    id="confirmed_by_pin_mixing"
                                                    name="confirmed_by_pin_mixing"
                                                    onChange={this.onInputChange(0)}
                                                    value={this.state.confirmed_by_pin_mixing}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                                {this.props.step_name === 'weighing_of_flourish_ingredients' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                                {this.props.step_name !== "weighing_of_flourish_ingredients" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )
    }
}
export default AdditionOfFlourishIngredients;