import React, { Component } from "react";
import { Form, Popup, Input, Item } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders,equipmentList } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { result } from "lodash";

class ProductionLineCheckout extends Component {

    state = { first_line_id: "",performed_by_pin:"",second_line_id:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:"",fLineID_Arr:[],sLineID_Arr:[],step_current:{},formErrors: {first_line_id:false,second_line_id: false,performed_by_pin: false,confirmed_by_pin:false}};
    
    componentDidMount(){
        this.getCurrentStep('production_line_checkout');
        this.getFormulaOfDay();
    }

    getCurrentStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name:step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;       
            console.log(result);
            if(result.success === true && result.current_step !== null){
              let current_step = result.current_step;
              current_step.step_json = JSON.parse(current_step.step_json);             
              this.setState({step_current : current_step});
              this.onInputChange(null,{name:"performed_by_pin","value":current_step.performed_by})
              this.onInputChange(null,{name:"confirmed_by_pin","value":current_step.confirmed_by})
              this.onInputChange(null,{name:"first_line_id","value":current_step.step_json.first_line_id})
              this.onInputChange(null,{name:"second_line_id","value":current_step.step_json.second_line_id})
            }
        })
    }

    getFormulaOfDay=()=>{
        axios.get(baseURL+"/formula_of_day",getHeaders())
        .then(res=>{
            let result = res.data;
            var line_ids = result.data
            if(result.success){                
                this.onInputChange(null,{name:"first_line_id","value":line_ids.first_line_id})
                this.onInputChange(null,{name:"second_line_id","value":line_ids.second_line_id})

            }
           
        })

    }
    onInputChange = (e, { name, value }) => {        
        switch (name) {
            case 'first_line_id':
                if(!value && value > 5){
                    toast.error("Please fill vailid Line ID.");
                    return; 
                }
                this.setState({fLineID_Arr:equipmentList[value]})                
                break;
            case 'second_line_id':
                if(!value && value > 5){
                    toast.error("Please fill vailid Line ID.");
                    return; 
                }
                this.setState({sLineID_Arr:equipmentList[value]})                
                break;
                case 'performed_by_pin':
                    if (value.length >= 3 ) {
                        this.getPerformUser(value, 'performer');
                        
                    } else {
                        this.setState({ performed_by: "", performed_by_name: "" });
                    }
                    break;
                case 'confirmed_by_pin':
                    if (value.length >= 3) {                    
                        this.getPerformUser(value, 'confirmer');                        
                    } else {
                        this.setState({ confirmed_by: "", confirmed_by_name: "" });
                    }
                    break;
        
            default:
                break;
        }
            
      this.setState({ [name]: value });
      
    };

    getPerformUser = (pin,user_type)=>{
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }   
          axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
           if(res.data.success === true ){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState(prevState => ({
                        performed_by: user?user.id:"",
                        performed_by_name: user?user.full_name:"",
                        formErrors: {
                            ...prevState.formErrors,
                            performed_by_pin:user?false:true
                        }
                    }));
                }else{
                    this.setState(prevState=>({
                        confirmed_by: user?user.id:"",
                        confirmed_by_name: user?user.full_name:"",
                        formErrors:{
                            ...prevState.formErrors,
                            confirmed_by_pin:user?false:true
                        }
                    }));
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }   
        })
    }

    handleSubmit = ()=>{
       
        const {first_line_id,second_line_id,performed_by_name,confirmed_by_name} = this.state;
        
        this.setState((prevState) => ({            
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                first_line_id: first_line_id && first_line_id < 5 ?false:true,
                second_line_id: second_line_id && second_line_id < 5 ?false:true,
                performed_by_pin: performed_by_name?false:true,
                confirmed_by_name: confirmed_by_name?false:true,
            }
        }));

        if(!this.state.first_line_id || this.state.first_line_id > 5){
            toast.error("Please complete all required field(s) ");
            return; 
        }
        if(!this.state.second_line_id || this.state.second_line_id > 5){
            toast.error("Please complete all required field(s)");
            return; 
        }
        
        if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
            toast.error("PIN is missing");
            return;
        }
        
        if ((!this.state.performed_by_name && this.state.performed_by_pin) || 
            (!this.state.confirmed_by_name && this.state.confirmed_by_pin) ) {
            toast.error("PIN is incorrect");
            return;
        }

        
      
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:3,
            step_json:JSON.stringify({first_line_id:this.state.first_line_id,second_line_id:this.state.second_line_id}),
            step_name:'production_line_checkout',
            step_next:'label_generation',
            performed_by:this.state.performed_by_pin,
            confirmed_by:this.state.confirmed_by_pin
        }
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onNextStep();
          }else{
            toast.error("Step has not saved");
          }
        })
    }
    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('operator_sign_in');        
    };
    handlePauseClick = ()=>{
    this.props.onNextClick('pause');
  }
  handleQuitClick = ()=>{
    this.props.onNextClick('quit');
  }

  handleNextClick = () => {     
        // Call the function passed from the parent
        this.props.onNextClick('label_generation')        
    };
    
    render(){        
        console.log(this.state);
        const {formErrors} = this.state;
        return (
            <>
            <Form onSubmit={this.handleSubmit}>
            <div className='cs-box'>
                <div className='cs-body'>
                    <h3>Production Line Checkout</h3>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div>
                                <div>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div><h4 className='m-0 pe-4'>Line ID:</h4></div>
                                        <div className='ui input'>
                                            {console.log(this.state.first_line_id)}
                                            <Form.Field required fluid="true" autocomplete="false">                    
                                                <Input
                                                error={formErrors.first_line_id}
                                                id="first_line_id"
                                                name="first_line_id"
                                                onChange={this.onInputChange}
                                                value={this.state.first_line_id}
                                                placeholder="Enter Line ID"
                                                type="text"
                                                className="full_opacity"
                                                autocomplete="off"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div><h4 className='m-0 pe-1'>Line Checked Out: By </h4></div>
                                        <div>
                                            <h4 className='m-0'><u>{this.state.performed_by_name} </u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> Performed PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">                    
                                                <Input
                                                error={formErrors.performed_by_pin}
                                                id="performed_by_pin"
                                                name="performed_by_pin"
                                                onChange={this.onInputChange}
                                                value={this.state.performed_by_pin}
                                                placeholder="Enter Your PIN"
                                                type="password"
                                                className="full_opacity form-control"
                                                autoComplete="new-password"
                                                />
                                            </Form.Field>
                                                                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div>
                                <div>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div><h4 className='m-0 pe-4'>Line ID:</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">                    
                                                <Input
                                                error={formErrors.second_line_id}
                                                id="second_line_id"
                                                name="second_line_id"
                                                onChange={this.onInputChange}
                                                value={this.state.second_line_id}
                                                placeholder="Enter Line ID"
                                                type="text"
                                                className="full_opacity"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div><h4 className='m-0 pe-1'>Line Checked Out: By </h4></div>
                                        <div>
                                            <h4 className='m-0'><u>{this.state.confirmed_by_name}</u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> Performed PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">                    
                                                <Input
                                                error={formErrors.confirmed_by_pin}
                                                id="confirmed_by_pin"
                                                name="confirmed_by_pin"
                                                onChange={this.onInputChange}
                                                value={this.state.confirmed_by_pin}
                                                placeholder="Enter Your PIN"
                                                type="password"
                                                className="full_opacity"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5 mb-2'>
                        <div className='col-md-6'>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding="0" cellSpacing="0">
                                 <tbody>
                                    <tr>
                                        <th colSpan={2}>
                                            Line <u>{this.state.first_line_id}</u> Equipment List
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Equipment ID</th>
                                        <th>Equipment Description</th>
                                    </tr>
                                   
                                    {this.state.fLineID_Arr && this.state.fLineID_Arr.map((Item)=>{
                                        return(
                                        <tr>
                                            <td>{Item.equipment_id}</td>
                                            <td>{Item.equipment_desc}</td>
                                        </tr>
                                        )
                                    })} 
                                    </tbody>                                   
                                </table>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>
                                            Line <u>{this.state.second_line_id}</u> Equipment List
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Equipment ID</th>
                                        <th>Equipment Description</th>
                                    </tr>
                                   
                                    {this.state.sLineID_Arr && this.state.sLineID_Arr.map((Item)=>{
                                        return(
                                        <tr>
                                            <td>{Item.equipment_id}</td>
                                            <td>{Item.equipment_desc}</td>
                                        </tr>
                                        )
                                    })}  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 mb-3 text-center">Sanitization and Maintenence Log Mantained in Binder by Date.</div>
                </div>
                <div className='cs-footer text-center'>
                    <hr />
                    <div className='text-center mt-2'>
                        <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                        <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                        <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                        { this.props.step_name === 'operator_sign_in' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                        { this.props.step_name !== "operator_sign_in" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}                        
                    </div>
                </div>
            </div>
            </Form>                
            </>
          )
    }
}

export default ProductionLineCheckout