import React, { Component } from "react";
import axios from "axios";
import { Message, Header, Segment, Grid} from "semantic-ui-react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { onGridReady, getHeaders } from "../helper";
import { baseURL } from "../../configuration/constants";
// global variables
let api, column_api;

class RoleTable extends Component {
    is_mounted = true;

    constructor(props) {
        super(props);
        this.state = {
            paramsRole:"",
            samples: [],
            columnDefs: [
                {
                    headerName: "Module_ name",
                    field: "modules",
                    filter: "agTextColumnFilter",
                    pinned: "left",
                    width: 300,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true
                },
                {
                    headerName: "View",
                    field: "right_view",
                    filter: "agTextColumnFilter",
                    width: 250,
                    cellRenderer: function (params) {
                        var input = document.createElement('input');
                        input.type = "checkbox";
                        input.checked = params.value;
                        input.addEventListener('click', function (event) {
                            if (input.checked === false) {
                                params.value = 0;
                            }
                            else if (input.checked === true) {
                                params.value = 1;
                            }
                            params.data.right_view = params.value;
                            let res = params.data;
                           let data={
                                delete_permission:res.right_delete,
                                edit_permission:res.right_edit,
                                view_permission:res.right_view,
                                module_name:res.modules,
                                role_name:res.role_name,
                                roleId:res.role_id,
                                permissionId:res.id
                            }
                            let id = res.id;
                            axios
                                .put(
                                    baseURL +
                                    "/users/updatePermissionDetails/" +
                                    id,
                                    data,
                                    getHeaders(),
                                )
                                .then(response => {
                                    toast.success("Successfully updated the User Permission");
                                    if (!response.data.success) {
                                        toast.warn("Updating failed! Please contact Admin.");
                                    }
                                })
                                .catch(error => {
                                    alert("Updating from Display Grid | Error: ", error);
                                });
                        });
                        return input;
                    }
                },
                {
                    headerName: "Edit",
                    field: "right_edit",
                    filter: "agTextColumnFilter",
                    width: 250,
                    cellRenderer: function (params) {
                        var input = document.createElement('input');
                        input.type = "checkbox";
                        input.checked = params.value;
                        input.addEventListener('click', function (event) {
                            if (input.checked === false) {
                                params.value = 0;
                            }
                            else if (input.checked === true) {
                                params.value = 1;
                            }
                            params.data.right_edit = params.value;
                            let res = params.data;
                            let data={
                                delete_permission:res.right_delete,
                                edit_permission:res.right_edit,
                                view_permission:res.right_view,
                                module_name:res.modules,
                                role_name:res.role_name,
                                roleId:res.role_id,
                                permissionId:res.id
                            }
                            let id = res.id;
                            axios
                                .put(
                                    baseURL +
                                    "/users/updatePermissionDetails/" +
                                    id,
                                    data,
                                    getHeaders(),
                                )
                                .then(response => {
                                    toast.success("Successfully updated the User Permission");
                                    if (!response.data.success) {
                                        toast.warn("Updating failed! Please contact Admin.");
                                    }
                                })
                                .catch(error => {
                                    alert("Updating from Display Grid | Error: ", error);
                                });
                        });
                        return input;
                    }
                }
                , {
                    headerName: "Delete",
                    field: "right_delete",
                    filter: "agTextColumnFilter",
                    width: 250,
                    cellRenderer: function (params) {
                        var input = document.createElement('input');
                        input.type = "checkbox";
                        input.checked = params.value;
                        input.addEventListener('click', function (event) {
                            if (input.checked === false) {
                                params.value = 0;
                            }
                            else if (input.checked === true) {
                                params.value = 1;
                            }
                            params.data.right_delete = params.value;
                            let res = params.data;
                            let data={
                                delete_permission:res.right_delete,
                                edit_permission:res.right_edit,
                                view_permission:res.right_view,
                                module_name:res.modules,
                                role_name:res.role_name,
                                roleId:res.role_id,
                                permissionId:res.id
                            }
                            let id = res.id;
                            axios
                                .put(
                                    baseURL +
                                    "/users/updatePermissionDetails/" +
                                    id,
                                    data,
                                    getHeaders(),
                                )
                                .then(response => {
                                    toast.success("Successfully updated the User Permission");
                                    if (!response.data.success) {
                                        toast.warn("Updating failed! Please contact Admin.");
                                    }
                                })
                                .catch(error => {
                                    alert("Updating from Display Grid | Error: ", error);
                                });
                        });
                        return input;
                    }
                }
            ]
        };
    }

    componentWillReceiveProps = (nextState,nextProps) =>{
        this.setState({
            paramsRole:nextState
        })  
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.role !== prevProps.role) {
            if (this.props.role !== prevProps) {
                axios
                .get(baseURL + "/users/PermissionBasedonRoles/" + this.props.role, getHeaders())
                .then(res => {
                    let data = res.data;
                    return data;
                })
                .then(samples => {
                    this.setState({
                        samples: samples
                    });
                });
              }
          }
    }

    render() {
        return (
            <>
                <Message style={{ marginBottom: "0",marginTop: "10px" }}>
                    <Header as="h2" textAlign="center">
                        Permissions
                    </Header>
                </Message>
                <Segment
                    compact
                    raised
                    padded
                    style={{ display: "block", marginTop: "0" }}
                >
                    <Grid columns="equal">
                        <Grid.Row>
                            <Grid.Column>
                                <div
                                    id="myGrid"
                                    className="ag-theme-balham"
                                    ag-grid="gridOptions"
                                    style={{
                                        height: "100%",
                                        width: "100%"
                                    }}
                                >
                                    <AgGridReact
                                        rowHeight="30"
                                        rowSelection="single"
                                        onGridReady={params => {
                                            api = params.api;
                                            api.showLoadingOverlay();
                                            column_api = params.column_api;
                                            onGridReady(
                                                params,
                                                api,
                                                column_api,
                                                this.props.initial_sort
                                            );
                                        }}
                                        animateRows={true}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationNumberFormatter={params => {
                                            return "[" + params.value.toLocaleString() + "]";
                                        }}
                                        columnDefs={this.state.columnDefs}
                                        floatingFilter={true}
                                        rowData={this.state.samples}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <ToastContainer autoClose={5000} />
            </>
        );
    }
}

export default RoleTable;
