import axios from "axios";
import React, { Component } from "react";
import {Message,Header,Segment,Grid,Button,Icon,Dropdown,} from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import {onGridReady,getHeaders,onPageSizeChanged,resetFilter,resetSort,startFormulation,getUserRole,simplifyModulePermissionData,
} from "../helper";

import {baseURL,
  page_size_options,
  portalURL,
} from "../../configuration/constants";
import Config from "../../configuration/config";
import moment from "moment";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomConfirm from "../custom_elements/CustomConfirm";
import Summary from "./Summary"
import CustomLoader from "../custom_elements/CustomLoader";


let api, column_api;
let typeParams = "";
let lowerCase = "";
// loading row
/* const rowDatas = [{
    id: 1,
    type: "loading..",
    cellStyle: {
        textAlign: 'center'
    }
}] */
const options = [{ value: 0 }, { value: 1 }, { value: "powder" }];
const selectModalStyles = {
  opacity: "0.6",
  width: "100%",
  padding: "5px 0",
  marginTop: "6px",
  display: "inherit",
  border: "1px solid darkgray",
};
let date = new Date();

class MmrBpr extends Component {
  gridRef;
  state = {
    id: "",
    qa_notes: "",
    qa_note_permission: true,
    user_name: "",
    type_formula: "",
    sampleId: "",
    formulaId: "",
    createdAt: "",
    manufactring_organisms: [],
    ManufactureQueFacts: "",
    ManufactureQueForm: "",
    disabledPermission: "",
    deletePermission: "",
    selected_row_value: {},
    is_page_loading: true,
    users_column_defs: [],
    inventory_column_defs: [],
    enableFormulation: false,
    formulations_column_defs: [],
    formulations_admin_column_defs: [],
    formulations_operator_column_defs: [],
    formulations_organism_column_defs: [],
    module_permission_column_defs: [],
    get_response: false,
    international: "",
    admin_notes: null,
    dataRow: [],

    specificRowData: [],
    showSegement: false,

    number_of_months: "",
    flourish_capsule_size: "",
    operator_notes: "",
    selected_row_value_qc_weight: {},
    selected_row_value_date_made:
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear(),
    is_special_button_disabled: true,
    sortFilterOrganism: "",
    reformulation_request_source: "",
    sample_received: "",
    is_waiting_for_response: false,

    // delete msg part
    deleteMsg: "",
    is_confirm_open: false,
    selected_row_value_filled_tube: {},
    qa_accepted_by: "",
    select: "",
    editType: "fullRow",
    defaultColDef: {
      resizable: true,
      sortable: true,
      enableCellChangeFlash: true,
    },
    filled_spoon: "",

    columnDefs: [
      {
        headerName: "Formulation Info",
        children: [
          {
            headerName: "Sample ID",
            field: "sample_id",
            filter: "agTextColumnFilter",           
            sortable: true,
          },
          {
            headerName: "Formula ID",
            field: "formula_id",
            filter: "agTextColumnFilter",           
            sortable: true,
          },
          {
            headerName: "Type",
            field: "formulation.type",           
            filter: "agTextColumnFilter",
            cellRenderer: (params) => {
                if (params.value === "refill") {
                    return (
                        `
                        <div>
                        Refill
                        </div>
                        `
                    )
                }
                else {
                    return (
                        `
                        <div>
                        ${params.value.charAt(0).toUpperCase() + params.value.slice(1)}
                        </div>
                        `
                    )
                }
            }
          },
          {
            headerName: "Product Type",
            field: "display_product_type",           
            filter: true,
            cellEditor: "agSelectCellEditor",
          },          
          {
            headerName: "Is Completed?",
            field: "is_completed",
            filter: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Yes", "No"]
            },
            refData: { "No": "No", "Yes": "Yes" },
            cellEditorParams: {
                values: ["No", "Yes"]
            }, 
            width:140           
          },          
          {
            headerName: "Date Created",
            field: "created_at",
            filter: "agTextColumnFilter",
            width: 140,
            sortable: true,
            cellRenderer: (params) => {
              return moment(params.data.created_at).format("YYYY-MM-DD");
            },
          },
        ],
      },
    ],
    accept_total_capsule_size_popup: false,
  };

  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
  }

  

  componentDidMount() {
    document.title = Config.formulationsOperator.title.display;
    this.getUserPermission();
    var scope = this;
    this.setState({ is_waiting_for_response: true });
    axios
      .get(baseURL + "/mmr-formulations", getHeaders())
      .then((response) => {
        this.setState({ dataRow: response.data.mmr_bpr})
        this.setState({ is_waiting_for_response: false });
        // this.setState({ dataRow: response.data.data})
        
      })
      .catch((err) => {
        this.setState({ is_waiting_for_response: false });
        this.toast.error("Something goes wrong! try again later");
      });
  }

  getUserPermission = () => {
    let Role = getUserRole();
    let login = localStorage.getItem("login_success");
    if (login == 1) {
      axios
        .get(baseURL + "/users/PermissionDetails", getHeaders())
        .then((response) => {
          let permissionData = null;
          permissionData = simplifyModulePermissionData(response.data);
          permissionData = permissionData.filter((data) => {
            if (data.role_name === Role) {
              return data;
            }
          });
          let ManufactureQueFacts = 0;
          let ManufactureQueForm = 0;
          let disabledPermission;
          let deletePermission;

          permissionData.forEach((result) => {
            if (
              result.module_name === "Manufacturing Queue" &&
              result.delete_permission === 1
            ) {
              ManufactureQueFacts = 1;
              deletePermission = false;
            }
            if (
              result.module_name === "Manufacturing Queue" &&
              result.edit_permission === 1
            ) {
              ManufactureQueForm = 1;
              disabledPermission = false;
            }
          });
          if (ManufactureQueForm == 0) {
            ManufactureQueForm = 0;
            disabledPermission = true;
          } else {
            ManufactureQueForm = 1;
          }
          this.setState({
            ManufactureQueForm,
            disabledPermission,
            ManufactureQueFacts,
          });
        });
    }
  };

  handleFormData = () => {
    const {
      id,
      sampleId,
      formulaId,
      number_of_months,
      flourish_capsule_size,
      operator_notes,
    } = this.state;
    let data = {
      flourish_capsule_size: flourish_capsule_size,
      formula_id: formulaId,
      number_of_months: number_of_months,
      sample_id: sampleId,
      operator_notes: operator_notes,
      organism: {},
      id: id,
    };
    axios
      .put(baseURL + `/formulations/${id}`, data, getHeaders())
      .then((response) => {
        toast.success(response.data.message);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  stopLoading() {
    this.setState({ is_page_loading: false });
  }

  fetchSpecificForumlation = (params) => {
    console.log(params.data.formulation);
    this.setState({
      selected_row_value: {},
      selected_row_value_filled_tube: {},
      selected_row_value_qc_weight: {},
      sampleId: params.data.sample_id,
      formulaId: params.data.formula_id,
      createdAt: params.data.created_at,
      international: params.data.international,
      id: params.data.id,
      is_special_button_disabled: false,
      type: params.data.type,
      filled_spoon: "",
      is_waiting_for_response: true,
      reformulation_request_source: params.data.reformulation_request_source,
      sample_received: params.data.sample_received,
      accept_total_capsule_size_popup: false,
    });
    axios
      .get(
        baseURL + `/formulations-operator-new/info/${params.data.formulation.id}/${params.data.formulation.is_completed}`,
        getHeaders()
      )
      .then((response) => {
        var manufactring_organisms = [];        
        response.data.organisms &&
          response.data.organisms.map((data) => {
            manufactring_organisms.push({
              inventory_id: data.inventory_id,
              final_amount: data.final_amount_added,
              scrap_amount: data.scrap_amount,
              total_amount: data.scrap_amount + data.final_amount_added,
              allergen: data.allergen,
            });
          });
      
        var qa_notes = response.data.qa_notes || " ";
        var verifiedBy =
          response.data.qa_verified_by !== null
            ? response.data.qa_verified_by.full_name
            : " ";
        this.setState({
          specificRowData: response.data,
          showSegement: true,
          user_name: response.data.user.full_name,
          qa_accepted_by: verifiedBy,
          number_of_months: response.data.number_of_months,
          operator_notes: response.data.operator_notes || "",
          flourish_capsule_size: response.data.flourish_capsule_size || "",
          selected_row_value: response.data,
          
          formulation_id: response.data.id,
          manufactring_organisms: manufactring_organisms,
          qa_notes: qa_notes,
        });
        this.setState({is_waiting_for_response: false});
      })
      .catch((err) => {});
  };

  onDeleteClick = () => {
    let selectedData = api.getSelectedRows();
    selectedData.map((selectedRow) => {
      this.setState({
        deleteMsg:
          " Are you sure you want to delete the formula " +
          selectedRow.formula_id +
          " associated with sample " +
          selectedRow.sample_id +
          " ?",
      });
    });

    this.setState({ is_confirm_open: true });
  };
  handleChange = (e) => {
    this.setState({
      flourish_capsule_size: e.target.value,
    });
  };

  onSelectionChanged = () => {
    let selected_rows = api.getSelectedRows();
    if (selected_rows.length != 1) {
      this.setState({ is_special_button_disabled: true });
    }
  };

  // Handles 'confirm delete' cancel click.
  onCancel = () => {
    this.setState({ is_confirm_open: false });
  };

  // Handles 'confirm delete' click.
  onConfirm = () => {
    let ids = [];
    this.setState({ is_confirm_open: false });
    let selectedData = api.getSelectedRows();
    let idArr = "";
    let sampleId;

    for (let i = 0; i < selectedData.length; i++) {
      if (selectedData[i] !== "") {
        idArr = selectedData.map((record) => record.id);

        sampleId = selectedData.map((record) => record.sample_id);
        sampleId.filter((elem) => {
          typeParams = elem.split("_", 1);
        });

        for (let i = 0; i < typeParams.length; i++) {
          let axis = typeParams[i];
          lowerCase = axis.toLowerCase();
        }

        ids = idArr;

        axios
          .get(
            baseURL +
              "/delete_formula/" +
              ids.toString() +
              "?type=" +
              lowerCase,
            getHeaders()
          )
          .then((response) => {
            alert(response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            alert("Deleting from Display Grid | Error: ", error);
          });
      }
    }
  };
  onStateChange = (params) => {
    let keys = Object.keys(params);
    keys.forEach((key) => {
      this.setState({ [key]: params[key] });
    });
  };

  onModalValueChange = (e, { name, value }) => {
    let selected_row_value = this.state.selected_row_value;
    selected_row_value[name] = value;
    this.setState({ selected_row_value });
  };

  onModalValueChange_Date = (e, { name, value }) => {
    this.setState({ selected_row_value_date_made: value });
  };

  onModalValueChangePill = (e, { name, value }) => {
    let selected_row_value_filled_tube =
      this.state.selected_row_value_filled_tube;
    selected_row_value_filled_tube[name] = value;
    this.setState({ selected_row_value_filled_tube });
    let tubeWeight = 3.25;
    let NumberOfPills = 4;
    let emptyCapusle;
    if (this.state.capsuleSize == 1) {
      emptyCapusle = 0.08;
    } else {
      emptyCapusle = 0.1;
    }

    let x = value - tubeWeight;
    let y = NumberOfPills * emptyCapusle;
    let z = x - y;
    let omega = z / NumberOfPills;
    if (value === "") {
      omega = "";
      this.onModalValueChangeQcWeight(e, {
        name: "flourish_fill_qc_weight",
        value: omega,
      });
    }
    if (value >= 0) {
      this.onModalValueChangeQcWeight(e, {
        name: "flourish_fill_qc_weight",
        value: omega,
      });
    }
  };

  onModalValueChangeQcWeight = (e, { name, value }) => {
    startFormulation();

    if (name == "filled_spoon") {
      let selected_row_value_qc_weight =
        this.state.selected_row_value_qc_weight;
      selected_row_value_qc_weight["flourish_fill_qc_weight"] = value / 30;
      this.setState({
        filled_spoon: value,
        selected_row_value_qc_weight,
      });
      return;
    }

    let selected_row_value_qc_weight = this.state.selected_row_value_qc_weight;
    selected_row_value_qc_weight[name] = value;
    this.setState({ selected_row_value_qc_weight });
  };

  rowStyle = (params) => {
    if ([90, 45, 83, 166].indexOf(params.data.total_final_amount_added) == -1) {
      return { background: "yellow" };
    }
  };
  exportCSV = () => {
    this.setState({
      is_page_loading: true,
    });
    axios
      .get(baseURL + "/operators-export", getHeaders())
      .then((response) => {
        if (response.data.success === true) {
          window.open(response.data.url, "_blank");
          this.setState({
            is_page_loading: false,
          });
        }
      })
      .catch((error) => {});
  };

  render() {
    const highOrderValue = this;
    console.log(this.state);
    return (
      <>
        <br />
        {this.state.is_waiting_for_response && (
          <CustomLoader loading_text="Fetching MMR BPR Data" />
        )}

        <Message>
          <Header as="h2" textAlign="center">
            MMR & BPR
          </Header>
        </Message>
        <CustomConfirm
          is_confirm_open={this.state.is_confirm_open}
          content={this.state.deleteMsg}
          header="Delete selected Record(s)"
          cancel_button_text="Eh! Never Mind."
          confirm_button_text="DELETE"
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
        />

        <Segment
          compact
          raised
          padded
          style={{ display: "block" }}
          className="transparent"
        >
          {/* Page size */}
          <div className="filter-flex">
            <div className="col-filter">
              <Dropdown
                id="page-size"
                placeholder="Page Size"
                selection
                options={page_size_options}
                onChange={(newPageSize) => onPageSizeChanged(newPageSize, api)}
              />
            </div>
            {/* Reset sort  */}
            <div className="col-filter">
              <Button
                basic
                icon
                onClick={() => resetSort(api)}
                labelPosition="left"
              >
                <Icon name="sort alphabet up" />
                Reset Sort(s)
              </Button>
            </div>
            {/* Reset filter  */}
            <div className="col-filter">
              <Button
                basic
                icon
                onClick={() => resetFilter(api)}
                labelPosition="left"
              >
                <Icon name="filter icon" />
                Reset Filter(s)
              </Button>
            </div>
            {/* export csv  */}
           {/*  <div className="col-filter">
              <Button basic onClick={this.exportCSV} icon labelPosition="left">
                Export as CSV
                <Icon name="share square" />
              </Button>
            </div> */}

            {/* start formulation */}

            

          

            {/* start Refill */}
           

            
            {/* delete column */}
            {/* <div className="col-filter">
              <Button
                id="deleteRecordsButton"
                basic
                negative
                onClick={this.onDeleteClick}
                icon
                labelPosition="right"
                disabled={this.state.is_special_button_disabled}
              >
                Delete Record(s)
                <Icon name="trash" />
                <br />
              </Button>
            </div> */}
          </div>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <div
                  id="myGrid"
                  ag-grid="gridOptions"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  className="ag-theme-balham"
                >
                  <AgGridReact
                    rowHeight="30"
                    ref={this.gridRef}
                    rowSelection={"single"}
                    onCellValueChanged={this.cellValueChaged}
                    editType={this.state.editType}
                    floatingFilter={true}
                    onRowClicked={this.fetchSpecificForumlation}                    
                    enableCellChangeFlash={true}
                    onGridReady={(params) => {
                      api = params.api;
                      api.showLoadingOverlay();
                      column_api = params.column_api;
                      onGridReady(
                        params,
                        api,
                        column_api,
                        this.props.initial_sort
                      );
                    }}
                    animateRows={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationNumberFormatter={(params) => {
                      return "[" + params.value.toLocaleString() + "]";
                    }}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.dataRow}
                    onSelectionChanged={this.onSelectionChanged}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {Object.keys(this.state.selected_row_value).length > 0  &&
              (
        <Grid.Row>
          {/* <Segment style={{ display: this.state.showSegement ? 'block' : 'none' }} > */}
          <Segment style={{ display: "block" }}>            
            <Summary
                selected_row_value={this.state.selected_row_value}
              />
          </Segment>         
        </Grid.Row>
        )}
      </>
    );
  }
}
export default MmrBpr;
