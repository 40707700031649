
import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";

import Config from "../../configuration/config";
import CustomLoader from "../custom_elements/CustomLoader";
import CustomDivider from "../custom_elements/CustomDivider";
import { focusFirstElement,formatDate,  increaseDateYear, getHeaders, getSessionStoredJsonItem, changeAvailablityOfOrganism, checkValueExistOrNotInMultiVFun, multi_v_availablity, isAvailable_lower } from "../helper";
import { portalURL, baseURL } from "../../configuration/constants";
import { toast } from 'react-toastify';

import { Form, Popup, Segment, Button, Input, Grid, Radio, TextArea } from "semantic-ui-react";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from 'multiselect-react-dropdown';

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Custom_DropDown } from "./DropDown";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
}

let selectTax = ""

class CreateProbiotic extends Component {
    is_mounted = false;
    getInitState = () => {
        return {
            multi_v:"available",
            ingredient_type:"",
            multi_v_gram:"",
            files: [],
            is_avaialble_value: "",
            attachment_arr: [],
            multiSelectData: [],
            benefit: [],
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            is_page_loading: true,
            barcode: "",
            is_available: 0,
            concentration: 0,
            qty: "",
            reorder_point: 0,
            scrap_factor: 0,
            name: "",
            real_name: "",
            receive_date: new Date(),
            open_date: new Date(),
            expiration_date: increaseDateYear(new Date()),
            manufacture: "",
            manufacture_lot: "",
            storage_location: "",
            shelf: 0,
            notes: "",
            tax_id: "",
            microbe_name: ""
        };
    };

    state = this.getInitState();

    constructor(props) {
        super(props);
        this.multiselectRef = React.createRef();
    }

    AnswerKeyPress = (e) => {
        let code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    componentDidMount = () => {
        this.fetchBenefit()
    }
    

    fetchBenefit = () => {
        axios
            .get(baseURL + "/benefits", getHeaders())
            .then(response => {
                let x = response.data.data;
                let emptyArray = [];
                x && x.map((elem) => {
                    let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id }
                    emptyArray.push(obj);
                })
                this.setState({
                    multiSelectData: emptyArray
                })
            })
    }

    onSelect = (selectedList) => {
        if (selectedList && selectedList.length) {
            this.setState({
                benefit: selectedList
            })
        }
        else {
            this.setState({
                benefit: []
            })
        }
    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };
    /**
    * Handles the date change
    *
    * @param {String} name
    * @param {Date} date
    */
    onDateChange = (name, date) => {
        if (name === Config.inventory.receive_date) {
            this.setState({ receive_date: date });
            if (date) {
                this.setState({ expiration_date: increaseDateYear(date) });
            }
        } else if (name === Config.inventory.open_date) {
            this.setState({ open_date: date });
        } else if (name === Config.inventory.expiration_date) {
            this.setState({ expiration_date: date });
        } else {
            alert("Some Date Error Enterred!");
        }
    };

    changeAvailablity = async value => {
        var data = await changeAvailablityOfOrganism(value);
        this.setState(data);
    };

    // Handles submit click
    onSubmitClick = () => {
        let { manufacture, manufacture_lot, name, multi_v,ingredient_type,multi_v_gram,real_name, qty, scrap_factor, reorder_point } = this.state;
        var datass = checkValueExistOrNotInMultiVFun(multi_v,ingredient_type,multi_v_gram)
        if(!datass.unavailable && datass.checked){
            alert(datass.info);
            return;
        }
        


        if (!manufacture || !manufacture_lot || !name || !real_name) {
            toast.info("Please fill all the mandatory information.");
            return;
        }

        if (scrap_factor === "" || reorder_point === "" || qty === "") {
            toast.info("Please fill all the mandatory information.");
            return;
        }
        this.setState({
            is_saving_successful: false,
            is_saving_failed: false
        });
        let res = _.pick(this.state, [
            "receive_date",
            "open_date",
            "multi_v","ingredient_type","multi_v_gram",
            "expiration_date",
            "is_available",
            "concentration",
            "manufacture",
            "manufacture_lot",
            "qty",
            "reorder_point",
            "storage_location",
            "shelf",
            "scrap_factor",
            "notes",
            "name",
            "real_name",
            "tax_id",
            "benefit",
            "attachment_arr"
        ]);

        // adding the remaning fields.
        res.user_id = getSessionStoredJsonItem("user").user_id;

        res.open_date = formatDate(this.state.open_date);
        res.expiration_date = formatDate(this.state.expiration_date);
        res.receive_date = formatDate(this.state.receive_date);
        this.setState({ is_waiting_for_response: true });

        axios
            .post(baseURL + "/add_new_barcodes?type=pro", res, getHeaders())
            .then(response => {
                toast.success("Inventory Added Successfully" + "  Barcode is  " + response.data.barcode);
                let init_state = this.getInitState();
                init_state["is_page_loading"] = false;
                init_state["is_waiting_for_response"] = false;
                this.setState(init_state);
                focusFirstElement();
                this.fetchBenefit()
                setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);
            })
            .catch(error => {
                toast.error("Invalid Data");
                this.setState({ is_page_loading: false });
            });
        this.multiselectRef.current.resetSelectedValues();
        this.setState({
            benefit: []
        })
    };

    handleSubmit = () => {
        let data = {
            tax_id: this.state.tax_id,
            token: Config.token
        }
        axios
            .post(portalURL + "/getmicrobnames", data)
            .then(response => {
                if (response.data.data.microbe_name === "") {
                    selectTax = response.data.data.microbe_name
                    this.setState({
                        name: response.data.data.microbe_name
                    })
                    toast.info("Tax ID doesn't exists");
                    return;
                }
                else {
                    selectTax = response.data.data.microbe_name
                    this.setState({
                        name: response.data.data.microbe_name
                    })
                }
            })
    }
    handleBlur = () => {
        this.handleSubmit()
    }

    onCancelClick = () => {
        let init_state = this.getInitState();
        this.setState(init_state);
    };

    onRemove = (value) => {
        this.setState({
            benefit: value
        })
    }

    onupdatefiles = (fileItems) => {
        const scope = this;

        let attachment_arr_temp = this.state.attachment_arr;
        attachment_arr_temp = [];
        fileItems.map((elem, index) => {
            let mediaTypePdf = elem.file.type.split("/")[1];
            let mediaSize = elem.file.size;
            let mediaType = elem.file.type;
            let mediaName = elem.file.name;
            var selectedFile = elem.file;

            if (selectedFile) {
                var fileToLoad = selectedFile;
                var fileReader = new FileReader();
                var base64;
                fileReader.readAsDataURL(fileToLoad);
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let res = base64.slice(28);
                    if (mediaTypePdf === "pdf" && mediaSize <= 5242880) {
                        let obj = {};
                        obj = {
                            "base64Media": res,
                            "mediaName": mediaName,
                            "type": mediaType,
                            "size": mediaSize
                        }

                        attachment_arr_temp.push(obj);
                        const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                        scope.setState({
                            attachment_arr: final_array
                        })
                    }
                };
            }
        })
    }

    handleRemove = () => {
        const scope = this;
        this.setState({
            attachment_arr: []
        })
        let attachment_arr_temp = this.state.attachment_arr;
        if (this.pond) {
            const remainingAttachments = this.pond.getFiles().map((file) => file.source);
            remainingAttachments.map((elem, index) => {
                let mediaSize = elem.size;
                let mediaType = elem.type;
                let mediaName = elem.name;
                var selectedFile = remainingAttachments[0];
                if (selectedFile) {
                    var fileToLoad = selectedFile;
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        let res = base64.slice(28);
                        if (mediaSize <= 5242880) {
                            let obj = {};
                            obj = {
                                "base64Media": res,
                                "mediaName": mediaName,
                                "type": mediaType
                            }
                            attachment_arr_temp.push(obj);
                            const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                            scope.setState({
                                attachment_arr: final_array
                            })
                        }
                    };

                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
    }

    multi_v_availablity_fn = (type,value)=>{
        var multi_value = multi_v_availablity(type,value,this.state.multi_v);
        this.setState(multi_value);
    }


    render() {
        const { is_waiting_for_response, real_name, name, is_available, concentration, qty, reorder_point, manufacture_lot, tax_id,
            expiration_date, open_date, scrap_factor, notes, multiSelectData, shelf, storage_location, manufacture, receive_date } = this.state;
        return (
            <React.Fragment>
                <Grid.Row />
                <Segment className="transparent">
                    {is_waiting_for_response && (
                        <CustomLoader loading_text="Saving Inventory Data" />
                    )}
                    <Form>
                        <Grid columns="equal">
                            <CustomDivider
                                h_size="h3"
                                title="Probiotic Information"
                                icon_name="dna"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Tax Id</label>}
                                            content="Enter Tax Id"
                                        />
                                        <form onSubmit={this.handleSubmit}>
                                            <Input
                                                name="tax_id"
                                                value={tax_id}
                                                onChange={this.onInputChange}
                                                onBlur={this.handleBlur}
                                                iconPosition="left"
                                                placeholder="Tax Id"
                                                icon="hashtag"
                                            />
                                        </form>
                                    </Form.Field>
                                </Grid.Column>



                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Display Name</label>}
                                            content="Display Name."
                                        />
                                        <Input
                                            className="full_opacity_red opacity-1"
                                            name="real_name"
                                            onChange={this.onInputChange}
                                            value={real_name}
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Display Name"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Internal Name</label>}
                                            content="Internal Name."
                                        />
                                        <Input
                                            className="full_opacity_red opacity-1"
                                            name="name"
                                            value={tax_id ? name : ""}
                                            onChange={this.onInputChange}
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Internal Name"
                                            disabled={selectTax && tax_id ? true : false}

                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>

                                    <Custom_DropDown
                                        label={"Is Available"}
                                        content={"Is Organism available ?"}
                                        onChange={this.changeAvailablity}
                                        isAvailable={this.props.isAvailable}
                                        is_available={"is_available"}
                                        classNames={"is-available opacity-1"}
                                        dropDownValue={this.state.is_avaialble_value}
                                        inventory={this.props.inventory}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Concentration</label>}
                                            content="Enter the concentration of the Ingredient."
                                        />
                                        <Input
                                            name="concentration"
                                            onChange={this.onInputChange}
                                            value={concentration}
                                            placeholder="Concentnration"
                                            label={{ content: "B CFU/g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity</label>}
                                            content="Enter the Ingredient Quantity."
                                        />
                                        <Input
                                            name="qty"

                                            onChange={this.onInputChange}
                                            value={qty}
                                            placeholder="Quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.onInputChange}
                                            value={reorder_point}
                                            placeholder="Reorder Point"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Scrap Factor</label>}
                                            content="Enter the Ingredient Scrap Factor."
                                        />
                                        <Input
                                            name="scrap_factor"
                                            onChange={this.onInputChange}
                                            value={scrap_factor}
                                            placeholder="Scrap Factor"
                                            label={{ content: "%" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Popup
                                        trigger={<label>Notes</label>}
                                        content="Enter notes."
                                    />
                                    <TextArea
                                        name="notes"
                                        onChange={this.onInputChange}
                                        value={notes}
                                        placeholder="Add Notes here..."
                                        style={{ minHeight: 100 }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row >
            
                <Grid.Column width={4}>
                <div>Multi-V Availability</div>
                <Radio
                toggle
                className="allergen-radio-btn"
                onClick={()=>this.multi_v_availablity_fn('radio',this.state.multi_v_pre)}
                checked={(this.state.multi_v == "available")}
                />
                
                </Grid.Column>
                {(this.state.multi_v == "available")&& (
                <>
                <Grid.Column  width={6}>
                    <Custom_DropDown
                            label={"Ingredient Type"}
                            content={"Ingredient type."}
                            onChange={(e)=>this.multi_v_availablity_fn("ingredient_type",e)}
                            isAvailable={isAvailable_lower}
                            is_available={"is_available"}
                            classNames={"is-available"}                
                            dropDownValue={this.state.ingredient_type}
                            inventory={inventory}
                        />
                </Grid.Column>
                <Grid.Column  width={6}>
                        <span>Price per gram </span>
                        <Input
                        name="price_per_gram"
                        type="number"
                        fluid
                        value={this.state.multi_v_gram}
                        placeholder="Price per gram"
                        onChange={(e)=>{ this.multi_v_availablity_fn('multi_v_gram',e.target.value) }}
                    />
                </Grid.Column></>)}
            
         </Grid.Row >

                            {/* <CustomDivider
                                h_size="h3"
                                title="Benefits"
                                icon_name="cart plus"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <Popup
                                            trigger={<label>Benefits</label>}
                                            content="Benefits."
                                        />
                                        <Multiselect
                                            options={multiSelectData}
                                            closeOnSelect={false}
                                            onSelect={this.onSelect}
                                            onRemove={this.onRemove}
                                            displayValue="name"
                                            ref={this.multiselectRef}
                                            placeholder="Select Benefits"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row> */}

                            <CustomDivider
                                h_size="h3"
                                title="Manufacturer Information"
                                icon_name="industry"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Receive Date</label>}
                                            content="Enter the Ingredient Receive Date."
                                        />
                                        <DatePicker
                                            selected={receive_date}
                                            placeholderText="Click to select a date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(receive_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.receive_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Open Date</label>}
                                            content="Enter the Ingredient Open Date."
                                        />

                                        <DatePicker
                                            selected={open_date}
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(open_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.open_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Expiration Date</label>}
                                            content="Enter the Ingredient Expiration Date."
                                        />
                                        <DatePicker
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            selected={expiration_date}
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(Config.default_calendar_date)}
                                            onChange={date => {
                                                this.onDateChange(
                                                    Config.inventory.expiration_date,
                                                    date
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer</label>}
                                            content="Enter the Manufacturer of the Ingredient."
                                        />
                                        <Input
                                            placeholder="Manufacturer"
                                            icon="building"
                                            iconPosition="left"
                                            name="manufacture"
                                            onChange={this.onInputChange}
                                            value={manufacture}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer Lot</label>}
                                            content="Enter the Manufacturer Lot of the Ingredient."
                                        />
                                        <Input
                                            name="manufacture_lot"
                                            onChange={this.onInputChange}
                                            value={manufacture_lot}
                                            placeholder="Manufacturer Lot"
                                            icon="hashtag"
                                            iconPosition="left"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />

                            <CustomDivider
                                h_size="h3"
                                title="Storage Information"
                                icon_name="dropbox"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Storage Location</label>}
                                            content="Enter the Ingredient Storage Location (Fridge Number)."
                                        />
                                        <Input
                                            placeholder="Storage Location (Fridge Number)"
                                            icon="boxes"
                                            iconPosition="left"
                                            name="storage_location"
                                            onChange={this.onInputChange}
                                            value={storage_location}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Shelf</label>}
                                            content="Enter the Ingredient Shelf Number."
                                        />
                                        <Input
                                            placeholder="Shelf"
                                            icon="box"
                                            iconPosition="left"
                                            name="shelf"
                                            onChange={this.onInputChange}
                                            value={shelf}
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <CustomDivider
                                h_size="h3"
                                title="Upload Documents"
                                icon_name="upload"
                            />
                            <Grid.Column>
                                <FilePond
                                    acceptedFileTypes={['application/pdf']}
                                    ref={ref => (this.pond = ref)}
                                    allowMultiple={true}
                                    allowReorder={true}
                                    allowRemove={true}
                                    maxFiles={10}
                                    allowFileSizeValidation={true}
                                    maxFileSize="5MB"
                                    onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                                    onremovefile={this.handleRemove}
                                    checkValidity={true}
                                    dropValidation
                                />
                            </Grid.Column>
                            <Grid.Row />

                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid textAlign="center">
                                        <Button.Group>
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onSubmitClick} positive>
                                                        Submit
                                                    </Button>
                                                }
                                                content="Submit the Probiotic Information to the Database."
                                            />
                                            <Button.Or />
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onCancelClick}>Cancel</Button>
                                                }
                                                content="Discard the Probiotic Informtaion."
                                            />
                                        </Button.Group>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Form>
                </Segment>

            </React.Fragment>
        );
    }
}

export default CreateProbiotic;