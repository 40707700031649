//----------------------------------------------------------------------//
// Module: User                                                         //
// SubModule: CreateUser                                                //
// Tasks: 1) API call to Reset Password in database.                    //
// Author: Yash P Shah                                                  //
//----------------------------------------------------------------------//

import Config from "../../configuration/config";
import CustomLoader from "../custom_elements/CustomLoader";
import SuccessMessage from "../custom_elements/SuccessMessage";
import ErrorMessage from "../custom_elements/ErrorMessage";
import { focusFirstElement, customPrint, isFormValid,refreshPage } from "../helper";
import { baseURL, PRINT_CATEGORIES } from "../../configuration/constants";
import { Redirect } from "react-router-dom";

import React, { Component } from "react";
import axios from "axios";
import {
  Form,
  Popup,
  Segment,
  Button,
  Header,
  Message,
  Input,
  Grid,
  Label
} from "semantic-ui-react";

class ResetPassword extends Component {
  is_mounted = false;

  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  goToLogin = () => {
    this.setState({ go_to_login: true });
  };

  getInitState = () => {
    return {
      is_reset_successful: false,
      is_reset_failed: false,
      is_waiting_for_response: false,
      is_message_visible: true,
      response_message: "",
      is_page_loading: true,
      go_to_login: false,

      token: -1,
      username: "abc@gmail.com",
      password: "",
      confirm_password: "",

      form_errors: {
        password: "",
        confirm_password: ""
      }
    };
  };

  state = this.getInitState();

  componentDidMount() {
    // let params = this.props.location.search;
    // let token = QueryString.parse(params).token;
    
    let token = this.props.match.params.token;
  
    document.title = Config.reset_password.title;
    focusFirstElement();

    this.is_mounted = true;

    // API call to fetch the roles
    customPrint(
      Config.reset_password.section_title,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/password/find/" + token

    );
   
    axios
      .get(baseURL + "/password/find/" + token)
      .then(response => {
        customPrint(
          Config.reset_password.section_title,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        //fetches new roles apart from Admin, Op, Viewer
        let data = response.data;

        if (this.is_mounted) {
          this.setState({
            username: data.username,
            token,
            is_page_loading: false
          });
        }
      })
      .catch(error => {
      alert("Fetching Roles | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false,
           });
        }
        
      });
  }

  //handles changes by user to input field or selection on role
  onInputChange = (e, { name, value }) => {
    let form_errors = { ...this.state.form_errors };

    switch (name) {
      case "confirm_password":
        form_errors.confirm_password =
          value !== this.state.password
            ? "Password and Confirm Password don't match."
            : "";
        break;

      default:
        break;
    }

    this.setState({ form_errors, [name]: value });
  };

  //handles click on input button
  onResetPasswordClick = event => {
    if (isFormValid(this.state)) {
      this.setState({
        is_reset_successful: false,
        is_reset_failed: false,
        is_waiting_for_response: true
      });

      let res = {
         username:this.state.username,
         password: this.state.password,
         confirm_password:this.state.confirm_password,
         token:this.props.match.params.token,
         
       };

      // api call to save the user data
      customPrint(
        Config.reset_password.section_title,
        PRINT_CATEGORIES.REQUEST,
        baseURL + "/password/reset",
        res
      );

     

      axios
        .post(baseURL + "/password/reset",res)
        .then(response => {
          customPrint(
            Config.reset_password.section_title,
            PRINT_CATEGORIES.RESPONSE,
            response
          );

          this.setState({
            is_reset_successful: response.data.success,
            is_reset_failed: !response.data.success,
            response_message: response.data.message,
            is_waiting_for_response: false,
            is_message_visible: true,

            username: "",
            password: "",
            confirm_password: ""
          });
        })
        .catch(error => {
          alert("Reset Password | Error: " + error);
          this.setState({ is_waiting_for_response: false });
        });
    } else {
      alert(
        "Please remove the errors shown in red font prior to submiting the form."
      );
    }
  };

  //handles click on cancel button
  onCancelClick = () => {this.setState(this.getInitState())
    refreshPage();
  };

  //hides the SUCCESS/FAILURE message
  hideMessage = () => {
    this.setState({ is_message_visible: false });
  };

  render() {
    if (this.state.go_to_login) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        {this.state.is_page_loading && <CustomLoader />}

        {this.state.is_reset_successful && (
          <SuccessMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Reset Password"
            message={this.state.response_message}
          />
        )}

        {this.state.is_reset_failed && (
          <ErrorMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Reset Password"
            message={this.state.response_message}
          />
        )}
        <br />

        <Grid
          columns={3}
          style={{
            background:
              'url("assets/images/bg/10_1.jpg") no-repeat center top !important'
          }}
        >
          <Grid.Row>
            <Grid.Column />
            <Grid.Column>
              <Message>
                <Header as="h2" textAlign="center">
                  Reset Password
                </Header>
              </Message>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0px" }}>
            <Grid.Column />
            <Grid.Column>
              <Segment className="transparent">
                {this.state.is_waiting_for_response && (
                  <CustomLoader loading_text="Resetting Password..." />
                )}
                <Form id="ResetPasswordForm">
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Email Address</label>}
                      content="Username."
                    />
                    <Input
                      className="full_opacity"
                      value={this.state.username}
                      icon="user"
                      iconPosition="left"
                      id="username"
                      name="username"
                      disabled
                    />
                  </Form.Field>

                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>New Password</label>}
                      content="Enter the New Password."
                    />

                    <Input
                      name="password"
                      onChange={this.onInputChange}
                      value={this.state.password}
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      placeholder="Enter Your New Password"
                      className={
                        this.state.form_errors.password !== ""
                          ? "validation_error_input"
                          : ""
                      }
                    />
                    {this.state.form_errors.password && (
                      <Label basic color="red" pointing>
                        {this.state.form_errors.password}
                      </Label>
                      // <span className="validation_error_message">
                      //   {" "}
                      //   {this.state.form_errors.password}{" "}
                      // </span>
                    )}
                  </Form.Field>

                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Confirm New Password</label>}
                      content="Confirm the new Password."
                    />

                    <Input
                      name="confirm_password"
                      onChange={this.onInputChange}
                      value={this.state.confirm_password}
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      placeholder="Confirm Your Password"
                      className={
                        this.state.form_errors.confirm_password !== ""
                          ? "validation_error_input"
                          : ""
                      }
                    />
                    {this.state.form_errors.confirm_password && (
                      <Label basic color="red" pointing>
                        {this.state.form_errors.confirm_password}
                      </Label>
                    )}
                  </Form.Field>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <Grid.Row>
                    <Grid.Column>
                      <Grid textAlign="center">
                        <Button.Group>
                          <Popup
                            trigger={
                              <Button
                                content="Reset"
                                icon="check"
                                labelPosition="left"
                                positive
                                onClick={this.onResetPasswordClick}
                              />
                            }
                            content="Reset the Password."
                          />

                          <Button.Or />
                          <Popup
                            trigger={
                              <Button
                                content="Cancel"
                                icon="ban"
                                labelPosition="right"
                                negative
                                onClick={this.onCancelClick}
                              />
                            }
                            content="Discard it."
                          />
                        </Button.Group>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid textAlign="center">
                    <Grid.Row />
                    <Grid.Row>
                      <Grid.Column>
                        <Label
                          id="hyper-link"
                          className="hyper-link"
                          basic
                          color="blue"
                          onClick={this.goToLogin}
                        >
                          Go back to Login Page
                        </Label>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid.Row />
                  <Grid.Row />
                  <br />
                  <br />
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ResetPassword;
