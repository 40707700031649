import React, { Component } from 'react'
import labelImg from '../../../images/11-right-screen.jpg'
import { Form, Popup, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders,isClinical } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

class WeightofMixedFlourishIngredients extends Component {
    state = { performed_by_pin: "", confirmed_by_pin: "", performed_by: "", performed_by_name: "", confirmed_by: "", confirmed_by_name: "",is_probiotic:false,is_multi_v:false,flourish_capsule_size:"",step_next:"weight_of_prime_ingredients",traget_weight:0,measured:"",primary_operator:"",secondary_signoff:"",secondary_signoff_name:"",primary_operator_name:"",formErrors: {performed_by_pin: false,confirmed_by_pin:false,primary_operator:false,secondary_signoff:false,measured:false} }

    componentDidMount() {

        this.getCurrentStep('weight_of_mixed_flourish_ingredients');
        this.setState({flourish_capsule_size:this.props.selected_row_value.flourish_capsule_size})
        if(this.props.selected_row_value.is_prebiotic === 'No'){
            this.setState({is_probiotic:true})
        }       
        let is_clinical = isClinical(this.props.selected_row_value.product_type);
        //|| this.props.selected_row_value.is_prebiotic === 'Yes' || this.state.is_multi_v 
        if(this.props.selected_row_value.is_prime === 'No'){
            if(this.props.selected_row_value.flourish_capsule_size === "powder"){
                this.setState({step_next:"flourish_label_generation"}) 
            }else{
                this.setState({step_next:"pilling_of_mixed_prime_ingredient"})
                
            }
            
        }

        let total_final_amount = 0;

        this.props.selected_row_value.organisms.map((org)=>{
            total_final_amount = total_final_amount+org.final_amount_added;
        })
        this.setState({traget_weight:total_final_amount});
    }

    getCurrentStep = (step_name) => {
        let req = {
            formula_id: this.props.selected_row_value.formula_id,
            step_name: step_name
        }
        axios.post(baseURL + "/get-current-step", req, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true && result.current_step !== null) {
                    let current_step = result.current_step;
                    let step_json = JSON.parse(current_step.step_json);
                    console.log(step_json);
                    this.setState({ step_current: current_step });
                    // this.onInputChange(null, { name: "performed_by_pin", "value": current_step.performed_by });
                    // this.onInputChange(null, { name: "confirmed_by_pin", "value": current_step.confirmed_by });
                    this.onInputChange(null, { name: "measured", "value": step_json.measured });
                    this.onInputChange(null, { name: "primary_operator", "value": step_json.primary_operator });
                    this.onInputChange(null, { name: "secondary_signoff", "value": step_json.secondary_signoff });
                    this.onInputChange(null, { name: "operator_notes", "value": step_json.operator_notes });
                }
            })
    }

    handleClick = () => {
        // Call the function passed from the parent
        this.props.onPrevClick('addition_of_flourish_ingredients');
    };

    handleNextClick = () => {
        // Call the function passed from the parent
        this.props.onNextClick(this.state.step_next);
    };

    handlePauseClick = ()=>{
        this.props.onNextClick('pause');
    }
    handleQuitClick = ()=>{
        this.props.onNextClick('quit');
    }

    onInputChange = (e, { name, value }) => {
        console.log(name);
        switch (name) {
            case "performed_by_pin":
                if (value.length === 3) {
                    if (this.state.confirmed_by_pin !== value) {
                        this.getPerformUser(value, "performer");
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ performed_by: "", performed_by_name: "" });
                }
                break;
            case "confirmed_by_pin":
                if (value.length === 3) {
                    if (this.state.performed_by_pin !== value) {
                        this.getPerformUser(value, "confirmer");
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ confirmed_by: "", confirmed_by_name: "" });
                }
                break;
            case 'measured':
                this.setState({ yield : ((value/this.state.traget_weight) * 100).toFixed(2)});
                break;
            case "primary_operator":
                this.setState({primary_operator:value});        
                if (value.length === 3) {
                    if (this.state.secondary_signoff !== value) {
                        this.getPerformUser(value, "primary_operator");
                    } else {
                        toast.error("Please enter another pin");
                    }
                } else {
                    this.setState({primary_operator_name: ""});                   
                }
                break;
            case "secondary_signoff":
                this.setState({secondary_signoff : value});        
                if (value.length === 3) {
                    if (this.state.primary_operator !== value) {
                        this.getPerformUser(value, "secondary_signoff");
                    } else {
                        toast.error("Please enter another pin");
                    }
                } else {
                    this.setState({secondary_signoff_name : ""});                    
                }
                break;
            default:
                break;
        }

        this.setState({ [name]: value });

    };
    getPerformUser = (pin, user_type) => {
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
        axios.post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
            .then(res => {
                if (res.data.success === true) {
                    let user = res.data.user;
                    switch (user_type) {
                        case "performer":
                            this.setState(prevState => ({
                                performed_by: user?user.id:"",
                                performed_by_name: user?user.full_name:"",
                                formErrors: {
                                    ...prevState.formErrors,
                                    performed_by_pin:user?false:true
                                }
                            }));                         
                        break;
                        case "confirmer":
                            this.setState(prevState=>({
                                confirmed_by: user?user.id:"",
                                confirmed_by_name: user?user.full_name:"",
                                formErrors:{
                                    ...prevState.formErrors,
                                    confirmed_by_pin:user?false:true
                                }
                            }));
                        break;
                        case "primary_operator":
                            this.setState(prevState=>({
                                primary_operator: user?user.id:"",
                                primary_operator_name: user?user.full_name:"",
                                formErrors:{
                                    ...prevState.formErrors,
                                    primary_operator:user?false:true
                                }
                            }));                        
                        break;
                        case "secondary_signoff":
                            this.setState(prevState=>({
                                secondary_signoff: user?user.id:"",
                                secondary_signoff_name: user?user.full_name:"",
                                formErrors:{
                                    ...prevState.formErrors,
                                    secondary_signoff:user?false:true
                                }
                            }));                        
                        break;
                        default:
                        break;
                    }                   

                    //this.setState({user_full_name:user.full_name}); 
                }
            })
    }

    handleSubmit = () => {
        
        let input_field = false;        
        if(this.state.measured === "" || this.state.primary_operator === "" || this.state.secondary_signoff === "" || this.state.primary_operator_name === "" || this.state.secondary_signoff_name === "" ){
            input_field = true;
        }    

        this.setState((prevState) => ({            
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                // performed_by_pin: !this.state.performed_by_name?true:false,
                // confirmed_by_pin: !this.state.confirmed_by_name?true:false,
                primary_operator: !this.state.primary_operator_name?true:false,
                secondary_signoff: !this.state.secondary_signoff_name?true:false,
                measured: !this.state.measured?true:false
            }
        })); 

        if(input_field){
            toast.error("Please complete all required field(s) ");
            return;
        }

        // if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
        //     toast.error("PIN is missing");
        //     return;
        // }
        
        // if ((!this.state.performed_by_name && this.state.performed_by_pin) || 
        //     (!this.state.confirmed_by_name && this.state.confirmed_by_pin) ) {
        //     toast.error("PIN is incorrect");
        //     return;
        // }

        if(this.state.yield >= 95 && this.state.yield <= 105){           
        }else{
            toast.error("Yeld % needs to be within 95% - 105% ");
            return
        }

        // if (!this.state.performed_by_name) {
        //     toast.error("Please fill vaild pin.");
        //     return;
        // }
        // if (!this.state.confirmed_by_name) {
        //     toast.error("Please fill vaild pin.");
        //     return;
        // }
        let step_json = {
            traget_weight:this.state.traget_weight,
            operator_notes: this.state.operator_notes,
            measured: this.state.measured,
            yield: this.state.yield,
            primary_operator: this.state.primary_operator,
            secondary_signoff: this.state.secondary_signoff
        }
        let param = {
            sample_id: this.props.selected_row_value.sample_id,
            formula_id: this.props.selected_row_value.formula_id,
            step:12,
            step_json: JSON.stringify(step_json),
            step_name: 'weight_of_mixed_flourish_ingredients',
            step_next: this.state.step_next,
            // performed_by: this.state.performed_by_pin,
            // confirmed_by: this.state.confirmed_by_pin
        }
        axios.post(baseURL + "/mmr-formulations", param, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true) {
                    toast.success("Successfully saved Step");
                    this.props.onNextStep();
                } else {
                    toast.error("Step has not saved");
                }
            })
    }
    render() {        
        const {formErrors} = this.state;
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div className='cs-box'>
                        <div className='cs-body'>
                            <h3>Weighing of Mixed Flourish Ingredients</h3>
                            <div className='mt-2'>
                                <ul className='cs-list'>
                                    <li>Operator weighs mixed product and records weight into BPR. Operator, date, and time information is recorded into BPR. Weight is confirmed via secondary signoff via PIN.</li>
                                    <li>Weight will be recorded inside a stand-up barrier pouch. </li>
                                    <li>Weight of pouch will be taxed prior to weighing.  </li>
                                    <li>Weight of mixed product is input in formulation system table. </li>
                                    <li>Primary and secondary signoffs are performed to ensure target weight is accurate and within acceptable range as outlined in table below.</li>
                                </ul>
                            </div>
                            {/* || (!this.state.is_multi_v) */}
                            {
                                (this.state.is_probiotic && this.state.flourish_capsule_size !== 'powder') &&(
                                    <>
                                    {/* <div className='mt-2'>
                                        <div className='mb-1'>Capsules 1 month</div>
                                        <div className='lineh-20'>
                                            <strong>Manufacture 40 pills=3 for retain +30 for customer<br/>
                                            Acceptable Yield range: 82.5% - 100%</strong>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <div className='mb-1'>Capsules 3 month</div>
                                        <div className='lineh-20'>
                                            <strong>Manufacture 100 pills= 3 for retain +90 for customer <br/>
                                            Acceptable Yield range: 93% - 100%</strong>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <div className='mb-1'>Capsules 6 month</div>
                                        <div className='lineh-20'>
                                            <strong>Manufacture 204 pills=3 for retain +180 for customer <br/>
                                            Acceptable Yield range: 89.70% - 100%</strong>
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <div className='mb-1'>Capsules &gt;6 Month</div>
                                        <div className='lineh-20'>
                                            <strong>Manufacture 30 Pills per cotainer, no separate retain necessary <br/>
                                            Acceptable Yield range: 93% - 100%</strong>
                                        </div>
                                    </div> */}
                                    </>
                                )
                            }
                            {
                                (!this.state.is_probiotic && this.state.flourish_capsule_size === "powder")&&(
                                    <>
                                        <div className='mt-2'>
                                            <div className='mb-1'>Per each month of prebiotics</div>
                                            <div className='lineh-20'>
                                                <strong>Manufacture 245 g = 5 g for retain + 240 g for customer <br/>
                                                Acceptable Yield range: 98% - 102%</strong>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                (this.state.is_probiotic && this.state.flourish_capsule_size === "powder")&&(
                                    <>
                                        {/* <div className='mt-2'>
                                            <div className='mb-1'>Powder 1 month</div>
                                            <div className='lineh-20'>
                                                <strong>Manufacture 32.67 g = 5 g for retain + 26.67 g for customer <br/>
                                                Acceptable Yield range: 93.88% - 106.5%</strong>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='mb-1'>Powder 3 month</div>
                                            <div className='lineh-20'>
                                                <strong>Manufacture 88.00 g = 5 g for retain +83.00 g for customer <br/>
                                                Acceptable Yield range: 97.70.% -102.35%</strong>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='mb-1'>Powder 6 month</div>
                                            <div className='lineh-20'>
                                                <strong>Manufacture 171.00 g = 5 g for retain +166.00 g for customer <br/>
                                                Acceptable Yield range: 98.83% - 101.18%</strong>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='mb-1'>Powder &gt;6 Month</div>
                                            <div className='lineh-20'>
                                                <strong>Manufacture 26.67g per month of formulation + 5g retain <br/>
                                                Acceptable Yield range varies but &gt;95% or maximum 2g deviation from target formulation weight</strong>
                                            </div>
                                        </div> */}
                                    </>
                                ) 
                            }
                            

                            <div className='mt-5'>
                                <div className='custom-table custom-header-table'>
                                    <table className='w-100' cellPadding={0} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <th>Target Weight of <br />Mixed Ingredient (g)</th>
                                                <th>Measured</th>
                                                <th>Yield (%)</th>
                                                <th>Primary <br />Operator</th>
                                                <th>Secondary <br />Signoff</th>
                                            </tr>
                                            <tr>
                                                <th>{this.state.traget_weight}</th>
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error = {formErrors.measured}
                                                            id={`measured`}
                                                            name="measured"
                                                            onChange={this.onInputChange}
                                                            value={this.state.measured ? this.state.measured : ""}
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                </td>
                                                <td className='text-nowrap text-green'>{this.state.yield}</td>
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error = {formErrors.primary_operator}
                                                            id={`primary_operator`}
                                                            name="primary_operator"
                                                            onChange={this.onInputChange}
                                                            value={this.state.primary_operator ? this.state.primary_operator : ""}
                                                            type="password"
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                    {this.state.primary_operator_name && (
                                                        <div className="ui input">
                                                            {
                                                                this.state.primary_operator_name
                                                            }
                                                        </div>
                                                        )}
                                                </td>
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error = {formErrors.secondary_signoff}
                                                            id={`secondary_signoff`}
                                                            name="secondary_signoff"
                                                            onChange={this.onInputChange}
                                                            type="password"
                                                            value={this.state.secondary_signoff ? this.state.secondary_signoff : ""}
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                    {
                                                        this.state.secondary_signoff_name &&(
                                                            <div className="ui input">{this.state.secondary_signoff_name}</div>
                                                        )  
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div><h4 className='m-0'>Operator Notes:</h4></div>
                                <div>
                                    <Form.Field required fluid="true">
                                        <TextArea
                                            id="operator_notes"
                                            name="operator_notes"
                                            onChange={this.onInputChange}
                                            value={this.state.operator_notes}
                                            placeholder="Operator Notes"
                                            className="notes white-textarea text-black"
                                        />
                                    </Form.Field>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div><h4 className='m-0 text-center mb-1'>Statement Of Deviation Requirements:</h4></div>
                                <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                            </div>
                            {/* <div className='text-center mt-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={formErrors.performed_by_pin}
                                                    id="performed_by_pin"
                                                    name="performed_by_pin"
                                                    onChange={this.onInputChange}
                                                    value={this.state.performed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-center mt-2 mb-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={formErrors.confirmed_by_pin}
                                                    id="confirmed_by_pin"
                                                    name="confirmed_by_pin"
                                                    onChange={this.onInputChange}
                                                    value={this.state.confirmed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"

                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                                {this.props.step_name === 'addition_of_flourish_ingredients' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                                {this.props.step_name !== "addition_of_flourish_ingredients" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )
    }
}
export default WeightofMixedFlourishIngredients;