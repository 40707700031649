import React, { Component } from 'react'
import { Form, Input,TextArea} from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders,loadImage } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import CustomLoader from '../../../custom_elements/CustomLoader';



class ReviewFinishedProductSpecifications extends Component {
    state = {performed_by_pin:"",performed_by:"",performed_by_name:"",operator_notes:"",is_waiting_for_response:false,formErrors: {performed_by_pin: false}}
    
    componentDidMount(){                
        this.getCurrentStep('review_finished_product_specifications');
    }
    getCurrentStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;
            if(result.success === true && result.current_step !== null){
                let current_step = result.current_step;
                let step_json = JSON.parse(current_step.step_json);             
                this.setState({step_current : current_step});
                this.onInputChange(null,{name:"performed_by_pin","value":current_step.performed_by});                
                this.onInputChange(null,{name:"operator_notes","value":step_json.operator_notes});
            }
        })
    } 

    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('equipment_sanitization');        
    };

    handleNextClick = () => {     
        // Call the function passed from the parent
        this.props.onNextClick('bpr_review_submission')        
    };
    
    handlePauseClick = ()=>{
     this.props.onNextClick('pause');
    }
    handleQuitClick = ()=>{
     this.props.onNextClick('quit');
    }
    
    

    onInputChange =(e, { name, value }) => {      
        console.log(name); 
        switch (name) {         
            case 'performed_by_pin':
                if( value.length >= 3){
                    this.getPerformUser(value,'performer');
                }else{
                    this.setState({performed_by:"",performed_by_name:""});
                }
                break;
            default:
                break;
           
        }
            
      this.setState({ [name]: value });
      
    };
    getPerformUser = (pin,user_type)=>{
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }   
          axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
            if(res.data.success === true){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState(prevState => ({
                        performed_by: user?user.id:"",
                        performed_by_name: user?user.full_name:"",
                        formErrors: {
                            ...prevState.formErrors,
                            performed_by_pin:user?false:true
                        }
                    })); 
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }
        })
    }

    handleSubmit = ()=>{ 
        this.setState((prevState) => ({            
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                performed_by_pin: !this.state.performed_by_name?true:false
            }
        }));       
        if ( !this.state.performed_by_pin) {
            toast.error("PIN is missing");
            return;
        }
        
        if ((!this.state.performed_by_name && this.state.performed_by_pin)) {
            toast.error("PIN is incorrect");
            return;
        }
        this.setState({is_waiting_for_response: true});

        let step_json = {operator_notes:this.state.operator_notes}
       
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:26,
            step_json:JSON.stringify(step_json),
            step_name:'review_finished_product_specifications',
            step_next:'bpr_review_submission',
            performed_by:this.state.performed_by_pin           
        }       
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(data=>{               
            let result = data.data;
            if(result.success === true){
                toast.success("Successfully saved Step");
                this.props.onNextStep();
            }else{
                toast.error("Step has not saved");
            }                   
          
        })
    }
    

    render() {
        return (
            <>
             {this.state.is_waiting_for_response && (
                <CustomLoader loading_text="Updating Formulation Data" />                
            )}
            <Form onSubmit={this.handleSubmit}>
                <div className='cs-box'>
                    <div className='cs-body'>
                    <h3>Spec Sheet review</h3>
                        <div className='mt-2 border-b pb-2'>
                            <ul className='cs-list'>
                                <li>Review finished product specifications to confirm product is up to specifications.</li>
                                <li>Confirm packaging material</li>
                            </ul>
                            <div className='rfp-box'>
                                <table className='rfp-main-table w-100' cellPadding={0} cellSpacing={0}>
                                    <tr>
                                        <td className='text-center rfp-title'>SUN GENOMICS FINISHED PRODUCT SPECIFICATION</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='rfp-md-title border-b border-r'><strong>Product Description:</strong> Supplement (capsule) in sachet.</td>
                                                    <td className='rfp-md-title border-b'><strong>Date</strong> 08-17-2023</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} className='text-center rfp-md-title'><strong>Flore Flourish</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Shipping Information</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>A. BOTTLE DIMENSION</td>
                                                    <td className='text-start fw-500'>2.3" in diameter, 2.1" tall</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>B. OUTER CASE DIMENSION</td>
                                                    <td className='text-start fw-500'>8" x 11" x 1"</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>C. CASE CUBIC FEETL</td>
                                                    <td className='text-start fw-500'>&gt; 1 of</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>D. PALLET PATTERN</td>
                                                    <td className='text-start fw-500'>N/A</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>E. CASE WEIGHT</td>
                                                    <td className='text-start fw-500'>7 ounces</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>F. SPECIAL</td>
                                                    <td className='text-start fw-500'></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Sun Genomice Internal Product Code and General Information</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500 border-r'><strong>SKU:</strong> SUN 00019</td>
                                                    <td className='text-start ps-3 fw-500'><strong>Pack Size:</strong> 1</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Nutritional Information</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500' style={{ width: "30%" }}>Serving Size</td>
                                                    <td className='text-start fw-500'>1 Capsule</td>
                                                    <td className='text-start fw-500'>Varies</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500' style={{ width: "30%" }}>Calories</td>
                                                    <td className='text-start fw-500'>0</td>
                                                    <td className='text-start fw-500'></td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500' style={{ width: "30%" }}>Servings per container</td>
                                                    <td className='text-start fw-500'>30</td>
                                                    <td className='text-start fw-500'></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Probiotic Information</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500' style={{ width: "30%" }}>Concentration</td>
                                                    <td className='text-start fw-500'>Probiotic Blend</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500' style={{ width: "30%" }}>Varies</td>
                                                    <td className='text-start fw-500'>Varies</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Ingredient statement</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>Varies</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Other Ingredients</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>Vegan Capsule (HPMC and Water)</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-0'>
                                            <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='p-0' style={{ width: "50%" }}>
                                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start rfp-md-title ps-3'>Raw Material Information</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500'>Ingredients sourced from USA</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td className='p-0' style={{ width: "50%" }}>
                                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start rfp-md-title'>Country of Origin</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500'>USA</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-0'>
                                            <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='p-0' style={{ width: "50%" }}>
                                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start rfp-md-title ps-3'>Individual Fill Weights (no capsule)</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500' style={{ width: "50%" }}>A Target weight per pill</td>
                                                                            <td className='text-start fw-500'>450 mg</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500' style={{ width: "50%" }}>B. Lower limit</td>
                                                                            <td className='text-start fw-500'>380 mg</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500' style={{ width: "50%" }}>C. Upper limit</td>
                                                                            <td className='text-start fw-500'>520 mg</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500' style={{ width: "50%" }}>D. Capsules per bottle</td>
                                                                            <td className='text-start fw-500'>30</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td className='p-0' style={{ width: "50%" }}>
                                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start rfp-md-title ps-3'>Physical Attributes or Standards</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                                        <tr>
                                                                            <td className='border-b text-start ps-3 fw-500'>A. Clear capsule</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='border-b text-start ps-3 fw-500'>B. Varies</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='border-b text-start ps-3 fw-500'>C. Finely powdered</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500'>D. Capsule completely full</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Shelf Life</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>1 year from production date at 4deg C</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title  ps-3'>Testing Frequency</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>
                                                        Sun Genomics performs skip lot finished product testing as per SOP_014 Section 9, including:<br />
                                                        Cohort testing of all microbial and chemical criteria is performed monthly on 2-4 select production days from the month.<br />
                                                        Probiotic label claims performed on 1 random formulation weekly via flow cytometry enumeration.
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start rfp-md-title rfp-w-50 ps-3'>Microbiological Criteria</td>
                                                    <td className='text-start rfp-md-title rfp-w-50'>Methodology (Eurofine Test Code)</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='rfp-w-50 border-r'>
                                                        <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>A Yeast & Mold</td>
                                                                <td className='text-start ps-3 fw-500'>&lt;1.00E+6 CFU/g</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>B. E. coli</td>
                                                                <td className='text-start ps-3 fw-500'>&lt;1.00E+3 CFU/g</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>C. Salmonella</td>
                                                                <td className='text-start ps-3 fw-500'>0 CFU/g</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>D. BTGN</td>
                                                                <td className='text-start ps-3 fw-500'>&lt;1.00E+5 CFU/g</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>E. Listeria M</td>
                                                                <td className='text-start ps-3 fw-500'>ND per 25g</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>F. Staphylococcus A</td>
                                                                <td className='text-start ps-3 fw-500'>ND per 10g</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td className='rfp-w-50'>
                                                        <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start fw-500'>UM70J</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>ZMCKU</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>ZMEV2</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>ZME88</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>FDA BAM chapter 10</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>USP &lt;2022&gt;</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start rfp-md-title ps-3 rfp-w-50'>Chemical Criteria</td>
                                                    <td className='text-start rfp-md-title rfp-w-50'>Methodology (Eurofine Test Code)</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='rfp-w-50 border-r'>
                                                        <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>Arsenic</td>
                                                                <td className='text-start fw-500'>&lt;20 ppb</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>Cadmium</td>
                                                                <td className='text-start fw-500'>&lt;8.2 ppb</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>Lead</td>
                                                                <td className='text-start fw-500'>&lt;1 ppb</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>Mercury</td>
                                                                <td className='text-start fw-500'>&lt;12 ppb</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td className='rfp-w-50'>
                                                        <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start fw-500'>FS001</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>FS001</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>FS001</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start fw-500'>FS001</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-0'>
                                            <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='p-0'>
                                                        <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start rfp-md-title ps-3'>Storage Information</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500'>Store in refrigerator.</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td className='p-0'>
                                                        <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start rfp-md-title ps-3'>Usage Instructions</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                                        <tr>
                                                                            <td className='text-start ps-3 fw-500'>Consume 1 pill/day, 20 minutes before eating.</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-0'>
                                            <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start rfp-md-title ps-3'>'General Requiements</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>The product shall be manufactured in accordance with Good Manufacturing Practices as outlined by FDA
(21CFR 111) and shall comply with Federal, State, and Local governing statues.</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-0'>
                                            <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start rfp-md-title ps-3 rfp-w-50'>Packaging and Labeling Requirements</td>
                                                    <td className='text-start rfp-md-title ps-3 rfp-w-50'>Date Codes</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-0'>
                                            <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='rfp-w-50' style={{verticalAlign:"top"}}>
                                                        <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>A. 1x 30 capsule sachet of Flourish probiotics</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>B. [VARIES] 1 x 7 capsule sachet of Prime probiotics (low strength)</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>C. [VARIES] 1x Laser engraved bamboo bottle</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>D. Expiration date on sachets and monthly product box</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>E. Customer ID and Name on sachets and Monthly product box</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>F. 1 x instruction card</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>G. 1 x monthly product box</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>H. Nutrition label applied to back of monthly product box</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>I. Box, bottle, and ice pack are packaged in insulated mailer </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>J. Insulated mailer is packaged in Floré branded envelope</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b text-start ps-3 fw-500'>K. [VARIES] Monthly gift is packaged in Floré branded envelope</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-start ps-3 fw-500'>L. Shipping label is applied to center of package</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td className='rfp-w-50' style={{verticalAlign:"top"}}>
                                                        <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                            <tr>
                                                                <td className='text-center ps-3 fw-500 border-b'>Each sachet will be printed with expiration date </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='border-b'>
                                                                    <table className='w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                                        <tr>
                                                                            <td>Format:</td>
                                                                            <td>MM-DD-YYYY</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Example:</td>
                                                                            <td>01-30-2023</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-center ps-3 fw-500'>Exp: 01-30-2023</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-start rfp-md-title ps-3'>Supplemental Pictures</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className='rfp-border w-100 rfp-md-title' cellPadding={0} cellSpacing={0}>
                                                <tr>
                                                    <td className='text-start ps-3 fw-500'>Capsule bottle components</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        
                        <div className='d-flex mt-2'>
                            <div className='w-50 border-r pe-3'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p>1. Probiotic sachets, instruction card, and labeled bamboo bottle </p>
                            </div>
                            <div className='w-50 ps-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step28/1.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        
                        <div className='d-flex'>
                            <div className='w-50 border-r pe-3'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p>2. Nutrition Label </p>
                            </div>
                            <div className='w-50 ps-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step28/2.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        
                        <div className='d-flex'>
                            <div className='w-50 border-r pe-3'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p>3. Packaging Components</p>
                            </div>
                            <div className='w-50 ps-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step28/3.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className='w-50 border-r pe-3'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p>4. Assembly of Package</p>
                            </div>
                            <div className='w-50 ps-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step28/4.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex border-b'>
                            <div className='w-50 border-r pe-3'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p>5. Packaged Sealing and labeling for shipment</p>
                            </div>
                            <div className='w-50 ps-3'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step28/5.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0'>Operator Notes:</h4></div>
                            <div>
                            <Form.Field required fluid="true">                    
                                <TextArea
                                id="operator_notes"
                                name="operator_notes"
                                onChange={this.onInputChange}
                                value={this.state.operator_notes}
                                placeholder="Operator Notes"
                                className="notes white-textarea text-black"
                                />
                            </Form.Field>
                            </div>
                        </div>
                        <div className='text-center mt-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            error={this.state.formErrors.performed_by_pin}
                                            id="performed_by_pin"
                                            name="performed_by_pin"
                                            onChange={this.onInputChange}
                                            value={this.state.performed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                        <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                        <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                        <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                        { this.props.step_name === 'equipment_sanitization' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                        { this.props.step_name !== "equipment_sanitization" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}                        
                                           
                        </div>
                    </div>
                </div>
            </Form>
            </>
        )
    }
}
export default ReviewFinishedProductSpecifications;