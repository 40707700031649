import CustomConfirm from "../custom_elements/CustomConfirm";
import Config from "../../configuration/config";
import {
  exportAsCSV,
  onPageSizeChanged,
  resetFilter,
  resetSort,
  onGridReady,
  simplifyFormulationsData,
  simplifyFormulationsOrganismData,
  simplifyModulePermissionData,
  deepClone,
  customPrint,
  getHeaders,
  getSessionStoredJsonItem,
  refreshPage,
  getUserRole,
  startFormulation,
  filledTube,
  totalCapsuleMade,
  flourishPowederRemaining,
  PrimefillQcWeight,
  PrimePowderRemaining,
  PrimeTotalCapsuleMade
} from "../helper";

import {
  page_size_options,
  baseURL,
  PRINT_CATEGORIES,
  portalURL
} from "../../configuration/constants";
import { Redirect } from "react-router-dom";
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import axios from "axios";
import {
  Segment,
  Icon,
  Header,
  Button,
  Message,
  Grid,
  Dropdown
} from "semantic-ui-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import moment from 'moment';
import CustomLoader from "./CustomLoader";
import StartFormulationModal from "../formulation_mod/start_formulation/StartFormulationModal";
import StartRefillModal from "../formulation_mod/start_formulation/StartRefillModal";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";

//global variables
let countPopUp = 0;
let api, column_api;
let module_name = "";
// let selected_user_Id = "";
let formulation_type = "";
let count = "";
let typeParams = "";
let lowerCase = "";
let date = new Date();

class CustomDisplayGrid extends Component {
  is_mounted = true;

  state = {
    deleteMsg: "",
    currentAge: "",
    selected_row_value_date_made: date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear(),
    disabledUserName: false,
    selected_row_value_qc_weight: {},
    selected_row_value_filled_tube: {},
    probiotic_blend: "",
    qc_weight: "",
    pre_names: "",
    pro_names: "",
    downloadData: "",
    calories: "",
    pdfId: "",
    pdfSampleId: "",
    pdfFormullaId: "",
    defaultValue: false,
    unit: "g",
    inventory: [],
    id: "",
    empty: [],
    sortFilter: [],
    sortFilterOrganism: "",
    role_new_id: '',
    is_confirm_open: false,
    is_page_loading: true,

    is_updating_successful: false,
    is_updating_failed: false,
    is_waiting_for_response: false,
    is_message_visible: true,
    response_message: "",
    SelectedRole: [],
    selected_row_value: {},
    refillData: null,
    is_special_button_disabled: true,
    pdfs: true,
    gridOptions: {
    },
    rowSelection: "multiple",
    editType: "fullRow",
    defaultColDef: {
      resizable: true,
      sortable: true,
      enableCellChangeFlash: true,

    },
    role_options: [],
    roleId: "",
    roleName: "",
    rowData: [],
    tax_id: "",
    organism_barcode: "",
    columnDefs: this.props.column_defs,
    displayFormulation: "",
    disabledPermission: "",
    AllFormulation: 0,
    userManagement: 0,
    displayInventory: 0,
    formulationQueue: 0,
    allformulationEdit: 0,
    groupmanagementEdit: 0,
    createGroupDelete: 0,
    usermanagementEdit: 0,
    InventoryEdit: 0,
    prebioticsEdit: 0,
    exportAllFormulationView: 0,
    exportAllFormulationEdit: 0,
    exportFormulationOperatorView: 0,
    exportFormulationOperatorEdit: 0,
    capsuleSize: 0
  };

  constructor(props) {
    super(props);
    this.state.infiniteInitialRowCount = 1000;
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  componentDidMount() {
    count = 1;

    this.is_mounted = true;
    document.title = this.props.title;
    localStorage.removeItem("disabledPermission");
    let loginSucess = localStorage.getItem('login_success');
    if (loginSucess == 1) {

      this.getUserPermission();
    }
    // API call to get the data according to module
    module_name = this.props.module_name;

    if (module_name === "formulations_organism") {

      axios
        .get(baseURL + "/inventory/getAllBarcodes", getHeaders())
        .then(response => {


          let rowData = null;

          rowData = simplifyFormulationsOrganismData(response.data);

          if (this.is_mounted) {
            rowData = rowData || response.data;

            this.setState({
              rowData,
              is_page_loading: false
            });
          }
        })
        .catch(error => {
          // alert("Fetching Display Grid | Error: ", error);
          alert("Something went wrong! Please switch to Google Chrome browser for the best viewing experience!", error);

          if (this.is_mounted) {
            this.setState({ is_page_loading: false });
          }
        });
    }
    else if (module_name === "userPermission") {
      this.userRoleOptions();

      axios
        .get(baseURL + "/users/PermissionDetails", getHeaders())
        .then(response => {
          let rowData = null;
          rowData = simplifyModulePermissionData(response.data);
          rowData = rowData.filter(data => {
            if (data.roleId === 1) {
              return data;
            }

          })

          if (this.is_mounted) {
            rowData = rowData || response.data;

            this.setState({
              rowData,
              is_page_loading: false
            });
          }
        })
        .catch(error => {
          // alert("Fetching Display Grid | Error: ", error);
          alert("Something went wrong! Please switch to Google Chrome browser for the best viewing experience!", error);

          if (this.is_mounted) {
            this.setState({ is_page_loading: false });
          }
        });
    }

    else {

      customPrint(
        Config[module_name].section_title.display,
        PRINT_CATEGORIES.REQUEST,
        baseURL + "/" + module_name,
        " | Headers: ",
        getHeaders()
      );
      let module_name_old = ""
      if (module_name === "inventory") {
        module_name_old = 'inventory/list/0'
      }
      else {
        module_name_old = module_name
      }

      axios
        .get(baseURL + "/" + module_name_old, getHeaders())
        .then(response => {
          customPrint(
            Config[module_name].section_title.display,
            PRINT_CATEGORIES.RESPONSE,
            response
          );

          let rowData = null;
          let formulationData = null;

          // Any formulations
          if (module_name.indexOf("formulations") === 0) {
            // Only formulationOperator


            if (module_name === "formulationsOperator") {

              // Selects formulations which are either incomplete and those who have inventoryObj.
              formulationData = response.data.filter(f => {
                if (f.is_completed === 1) return false;
                if (f.is_completed === 0) {
                  f.date_made = "";
                }
                let flag = true;
                f.organisms.forEach(o => {

                  if (o.inventory === null) flag = false;
                });
                return flag;
              });
              rowData = formulationData;
              rowData.forEach(rowValue => {
                if (rowValue.type === "refill") {

                  rowValue.formulation_type = "Refill";
                }
                else if (rowValue.type === "formulation") {
                  rowValue.formulation_type = "Formulation";
                }
                else {
                  rowValue.formulation_type = "Reformulation";
                }

              })


            } else {

              rowData = simplifyFormulationsData(response.data);

              rowData = rowData.filter(f => {

                if (f.is_completed === 0) {
                  f.date_made = "";
                }

                return f;
              });
            }

          }

          if (this.is_mounted) {
            rowData = rowData || response.data;

            this.setState({
              rowData,
              is_page_loading: false
            });
          }
        })
        .catch(error => {
          // alert("Fetching Display Grid | Error: ", error);
          alert("Something went wrong! Please switch to Google Chrome browser for the best viewing experience!", error);

          if (this.is_mounted) {
            this.setState({ is_page_loading: false });
          }
        });
    }

  }

  getUserPermission = () => {

    let Role = getUserRole();
    let login = localStorage.getItem('login_success');


    if (login == 1) {
      axios
        .get(baseURL + "/users/PermissionDetails", getHeaders())
        .then(response => {
          let permissionData = null;

          permissionData = simplifyModulePermissionData(response.data);
          permissionData = permissionData.filter(data => {
            if (data.role_name === Role) {
              return data;
            }
          })

          // let formulationRationale = 0;
          let displayFormulation = 0;
          let AllFormulation = 0;
          let userManagement = 0;
          let disabledPermission
          let displayInventory = 0;
          let formulationQueue = 0;
          let allformulationEdit = 0;
          let groupmanagementEdit = 0;
          let createGroupDelete = 0;
          let usermanagementEdit = 0;
          let InventoryEdit = 0;
          let prebioticsEdit = 0;
          let exportAllFormulationView = 0;
          let exportAllFormulationEdit = 0;
          let exportFormulationOperatorView = 0;
          let exportFormulationOperatorEdit = 0;
          permissionData.forEach(result => {

            if (result.module_name === "Manufacturing Queue" && result.delete_permission === 1) {
              displayFormulation = 1;
              disabledPermission = false;
            }
            if (result.module_name === "All Formulations" && result.delete_permission === 1) {
              AllFormulation = 1;
              disabledPermission = false;
            }
            if (result.module_name === "User Management" && result.delete_permission === 1) {

              userManagement = 1;
              disabledPermission = false;

            }
            if (result.module_name === "Inventory" && result.delete_permission === 1) {
              displayInventory = 1;
              disabledPermission = false;
            }
            // if (result.module_name === "Formulation Rationale" && result.edit_permission === 1) {
            //   formulationRationale = 1;
            //   localStorage.setItem("disabledPermission", false);
            //   disabledPermission = false;
            // }
            if (result.module_name === "All Formulations" && result.edit_permission === 1) {
              allformulationEdit = 1;

            }
            if (result.module_name === "Group Managements" && result.edit_permission === 1) {
              groupmanagementEdit = 1;

            }
            if (result.module_name === "Group Management" && result.delete_permission === 1) {
              createGroupDelete = 1;

            }
            if (result.module_name === "User Management" && result.edit_permission === 1) {
              usermanagementEdit = 1;


            }
            // if (result.module_name == "Prebiotics & Probiotics Management" && result.edit_permission === 1) {
            //   prebioticsEdit = 1;

            // }
            if (result.module_name === "Inventory" && result.edit_permission === 1) {
              InventoryEdit = 1;

            }
            if (result.module_name === "Manufacturing Queue" && result.edit_permission === 1) {
              formulationQueue = 1;
            }

            if (result.module_name === "Export Manufacturing Queue" && result.view_permission === 1) {

              exportFormulationOperatorView = 1;

            }
            if (result.module_name === "Export Manufacturing Queue" && result.edit_permission === 1) {

              exportFormulationOperatorEdit = 1;

            }
            if (result.module_name === "Export All Formulation" && result.view_permission === 1) {

              exportAllFormulationView = 1;

            }
            if (result.module_name === "Export All Formulation" && result.edit_permission === 1) {
              exportAllFormulationEdit = 1;
            }

          })

          this.setState({
            displayFormulation,
            disabledPermission,
            AllFormulation,
            userManagement,
            displayInventory,
            formulationQueue,
            allformulationEdit,
            groupmanagementEdit,
            createGroupDelete,
            usermanagementEdit,
            InventoryEdit,
            prebioticsEdit,
            exportAllFormulationView,
            exportAllFormulationEdit,
            exportFormulationOperatorView,
            exportFormulationOperatorEdit,
          });

        })
        .catch(error => {
          alert("Something went wrong! Please switch to Google Chrome browser for the best viewing experience!", error);
        });
    }
  }
  // when the modal value gets changed
  onModalValueChange = (e, { name, value }) => {
    totalCapsuleMade()
    flourishPowederRemaining()
    PrimefillQcWeight()
    PrimePowderRemaining()
    PrimeTotalCapsuleMade()
    let selected_row_value = this.state.selected_row_value;
    selected_row_value[name] = value;
    this.setState({ selected_row_value })
  };

  onModalValueChange_Date = (e, { name, value }) => {
    this.setState({
      selected_row_value_date_made: value
    })
  };

  onModalValueChangePill = (e, { name, value }) => {
    filledTube()
    let selected_row_value_filled_tube = this.state.selected_row_value_filled_tube;
    selected_row_value_filled_tube[name] = value;
    this.setState({ selected_row_value_filled_tube });
    let tubeWeight = 3.25;
    let NumberOfPills = 4;
    let emptyCapusle;
    if (this.state.capsuleSize == 1) {
      emptyCapusle = 0.08;
    }
    else {
      emptyCapusle = 0.1;
    }

    let x = value - tubeWeight;
    let y = NumberOfPills * emptyCapusle;
    let z = x - y;
    let omega = z / NumberOfPills;
    if (value === "") {
      omega = "";
      this.onModalValueChangeQcWeight(e, { name: "flourish_fill_qc_weight", value: omega })
    }
    if (value >= 0) {
      this.onModalValueChangeQcWeight(e, { name: "flourish_fill_qc_weight", value: omega })
    }
  };

  onModalValueChangeQcWeight = (e, { name, value }) => {
    startFormulation()
    let selected_row_value_qc_weight = this.state.selected_row_value_qc_weight;
    selected_row_value_qc_weight[name] = value;
    this.setState({ selected_row_value_qc_weight });
  };


  onStateChange = params => {
    let keys = Object.keys(params);
    keys.forEach(key => {
      this.setState({ [key]: params[key] });
    });
  };

  /**
   * Handles the row value editing.
   *
   * @param {Object} params
   * @param {Object} required_fields
   */
  onRowValueChanged(params, required_fields) {
    let UpdatedArray = params.data;
    let res = _.pick(params.data, required_fields);

    if (module_name === "formulations_organism" && this.state.prebioticsEdit === 0) {

      return false;
    }
    if (module_name === "inventory" && this.state.InventoryEdit === 0) {

      return false;
    }
    if (module_name === "users" && this.state.usermanagementEdit === 0) {


      return false;
    }
    if (module_name === "userPermission" && this.state.groupmanagementEdit === 0) {

      return false;
    }
    if (module_name === "userPermission" && this.state.createGroupDelete === 0) {

      return false;
    }
    if (module_name === "formulations" && this.state.allformulationEdit === 0) {

      return false;
    }
    if (module_name === "formulationsOperator" && this.state.formulationQueue === 0) {

      return false;
    }
    if (module_name === "users") {
      if (UpdatedArray["role_id"] === 0) {

        UpdatedArray["role_id"] = this.state.selected_row_value["role_id"];
      }
      let count = 0;
      let updateCounter = 0;
      for (let proping in UpdatedArray) {
        if (UpdatedArray.hasOwnProperty(proping) && this.state.selected_row_value.hasOwnProperty(proping)) {
          if (String(UpdatedArray[proping]) !== String(this.state.selected_row_value[proping])) {
            count = 1;
            updateCounter++;

          }

        }

      }
      if (updateCounter !== 0) {
        let r = window.confirm("Are you sure you want to update?");

        if (r === false) {


          this.state.rowData.forEach(val => {

            if (val.id == this.state.selected_row_value["id"]) {

              val = this.state.selected_row_value;
            }

          })

          return false;
        }
      }
      if (count !== 1) {

        return false;
      }
    }
    if (module_name === "inventory") {
      if (UpdatedArray["notes"] === "") {

        UpdatedArray["notes"] = null;
      }
      if (UpdatedArray["full_name"] == 22) {

        UpdatedArray["full_name"] = this.state.selected_row_value["full_name"];
      }
      if (UpdatedArray["user_id"] == 22) {

        UpdatedArray["user_id"] = this.state.selected_row_value["user_id"];
      }

      let count = 0;
      let updateCounter = 0;

      for (let proping in UpdatedArray) {

        if (UpdatedArray.hasOwnProperty(proping) && this.state.selected_row_value.hasOwnProperty(proping)) {
          if (String(UpdatedArray[proping]) !== String(this.state.selected_row_value[proping])) {

            count = 1;
            updateCounter++;

          }

        }

      }
      if (updateCounter !== 0) {
        let r = window.confirm("Are you sure you want to update?");

        if (r == - false) {


          this.state.rowData.forEach(val => {

            if (val.id == this.state.selected_row_value["id"]) {

              val = this.state.selected_row_value;
            }

          })

          return false;
        }
      }
      if (count !== 1) {

        return false;
      }

    }
    if (module_name === "formulations") {
      let count = 0;
      let updateCounter = 0;
      if (UpdatedArray["operator_notes"] === "") {

        UpdatedArray["operator_notes"] = null;
      }
      if (UpdatedArray["admin_notes"] === "") {

        UpdatedArray["admin_notes"] = null;
      }



      for (let proping in UpdatedArray) {

        if (UpdatedArray.hasOwnProperty(proping) && this.state.selected_row_value.hasOwnProperty(proping)) {
          if (String(UpdatedArray[proping]) !== String(this.state.selected_row_value[proping])) {


            count = 1;
            updateCounter++;

          }

        }

      }
      if (updateCounter !== 0) {

        let r = window.confirm("Are you sure you want to update??");

        if (r === false) {


          this.state.rowData.forEach(val => {

            if (val.id == this.state.selected_row_value["id"]) {

              val = this.state.selected_row_value;
            }

          })

          return false;
        }
      }
      if (count !== 1) {

        return false;
      }

    }


    if (module_name === "formulationsOperator") {
      if (UpdatedArray["operator_notes"] === "") {

        UpdatedArray["operator_notes"] = null;
      }
      let count = 0;
      let updateCounter = 0;
      for (let proping in UpdatedArray) {

        if (UpdatedArray.hasOwnProperty(proping) && this.state.selected_row_value.hasOwnProperty(proping)) {
          if (String(UpdatedArray[proping]) !== String(this.state.selected_row_value[proping])) {

            count = 1;
            updateCounter++;

          }

        }

      }
      if (updateCounter !== 0) {
        let r = window.confirm("This change may send an email so are you sure you want to update??");

        if (r === false) {
          this.state.rowData.forEach(val => {

            if (val.id == this.state.selected_row_value["id"]) {

              val = this.state.selected_row_value;
            }

          })

          return false;
        }
      }
      if (count !== 1) {

        return false;
      }

    }
    //update on prebiotics and probiiotics page 
    if (module_name === "formulations_organism") {
      if (UpdatedArray["organism_name"] === "") {

        UpdatedArray["organism_name"] = null;
      }
      if (UpdatedArray["tax_id"] === "") {

        UpdatedArray["tax_id"] = null;
      }
      let count = 0;
      let updateCounter = 0;
      for (let proping in UpdatedArray) {

        if (UpdatedArray.hasOwnProperty(proping) && this.state.selected_row_value.hasOwnProperty(proping)) {
          if (String(UpdatedArray[proping]) !== String(this.state.selected_row_value[proping])) {
            count = 1;
            updateCounter++;
          }

        }

      }
      if (updateCounter !== 0) {
        let r = window.confirm("Are you sure you want to update?");

        if (r === false) {
          this.state.rowData.forEach(val => {
            if (val.id == this.state.selected_row_value["id"]) {

              val = this.state.selected_row_value;
            }

          })

          return false;
        }
      }
      if (count !== 1) {

        return false;
      }

      let id = "or" + res.organism_id;
      axios
        .put(
          baseURL +
          "/inventory/" +
          id,
          res,
          getHeaders()
        )
        .then(response => {
          alert(response.data.message);

          if (!response.data.success) {
            alert("Updating " + module_name + " failed! Please contact Admin.");
            refreshPage();
          }
          //refreshPage();
        })
        .catch(error => {
          alert("Updating from Display Grid | Error: ", error);
        });

    }
    else if (module_name == "userPermission") {
      let id = res.permissionId;

      axios
        .put(
          baseURL +
          "/users/updatePermissionDetails/" +
          id,
          res,
          getHeaders()
        )
        .then(response => {
          alert(response.data.message);

          if (!response.data.success) {
            alert("Updating " + module_name + " failed! Please contact Admin.");

          }

        })
        .catch(error => {
          alert("Updating from Display Grid | Error: ", error);
        });
    }
    else {
      if (module_name.indexOf("formulations") === 0) {
        let updated_formulation = {};
        let updated_organism = {};

        let selected_row_value = this.state.selected_row_value;
        let updated_row_value = res;

        // let rowValue = this.state.rowData.find(i => i.id === updated_row_value["id"]);
        // let userID = rowValue['user_id']
        updated_formulation["id"] = updated_row_value["id"];

        for (let prop in selected_row_value) {

          if (prop === "full_name") {
            // console.log(
            //   String(selected_row_value[prop]),
            //   " vs. ",
            //   String(updated_row_value[prop])
            // );

            // userID = updated_row_value[prop];
          }

          if (
            selected_row_value.hasOwnProperty(prop) &&
            updated_row_value.hasOwnProperty(prop)
          ) {
            if (
              String(selected_row_value[prop]) !== String(updated_row_value[prop])
            ) {

              if (
                prop === "organism_barcode" ||
                prop === "amount_added" ||
                prop === "scrap_amount" ||
                prop === "inventory_id"
              ) {


                if (!updated_organism["id"]) {
                  updated_organism["id"] = updated_row_value["organisms_id"];
                } else {
                  updated_organism[prop] = updated_row_value[prop];
                }
              } else {
                updated_formulation[prop] = updated_row_value[prop];
              }
            }
          }
        }

        updated_formulation["organism"] = updated_organism;
        // updated_formulation["user_id"] = userID;

        res = updated_formulation;
      }


      // API call to update formulation(/organism).

      customPrint(
        Config[module_name].section_title.display,
        PRINT_CATEGORIES.REQUEST,
        baseURL + "/" + module_name + "/" + res.id,
        res
      );
      axios
        .put(
          baseURL +
          "/" +
          (module_name.indexOf("formulations") === 0
            ? "formulations"
            : module_name) +
          "/" +
          res.id,
          res,
          getHeaders()
        )
        .then(response => {
          alert(response.data.message);
          customPrint(
            Config[module_name].section_title.display,
            PRINT_CATEGORIES.RESPONSE,
            response
          );

          if (!response.data.success) {
            alert("Updating " + module_name + " failed! Please contact Admin.");
            // refreshPage();
          }
          refreshPage();
        })
        .catch(error => {
          alert("Updating from Display Grid | Error: ", error);
        });
    }
  }

  /**
   * Handles the different ag-grid row selection.
   */
  onSelectionChanged = () => {
    let selected_rows = api.getSelectedRows();

    if (selected_rows.length > 0) {
      if (this.props.module_name === "formulations") {
        selected_rows[0]['date_made'] = "";

      }
      else {
        const currentDate = moment().format('MM/DD/YYYY');

        selected_rows[0]['date_made'] = currentDate;
      }
      // selected_user_Id = selected_rows[0]['user_id'];
      if (selected_rows[0]['type'] === "refill") {

        formulation_type = "Refill";
      }
      else {

        formulation_type = "Formulation";
      }
    }

    let cloned_selected_row_value = deepClone(selected_rows[0]);
    this.setState({ selected_row_value: cloned_selected_row_value });

    if (selected_rows.length > 0)
      this.setState({ is_special_button_disabled: false });
    else this.setState({ is_special_button_disabled: true });

  };


  // Handles the 'delete record(s)' click.
  onDeleteClick = () => {

    let selectedData = api.getSelectedRows();

    selectedData.map(selectedRow => {
      if (module_name === "formulations" || module_name === "formulationsOperator") {
        this.setState({

          deleteMsg: " Are you sure you want to delete the formula " + selectedRow.formula_id + " associated with sample " + selectedRow.sample_id + " ?"
        })
      }
      else if (module_name === "inventory") {
        this.setState({


          deleteMsg: "Are you sure you want to delete Ingredient Barcode- " + selectedRow.organism_barcode
        })
      }
      else {
        this.setState({

          deleteMsg: "Are you sure you want to delete"
        })
      }

    })
    if (module_name === "userPermission") {
      this.setState({
        deleteMsg: "Are you sure you want to delete group"
      })
    }


    this.setState({ is_confirm_open: true });
  };

  // Handles 'confirm delete' cancel click.
  onCancel = () => {
    this.setState({ is_confirm_open: false });
  };

  // Handles 'confirm delete' click.
  onConfirm = () => {

    this.setState({ is_confirm_open: false });
    let selectedData = api.getSelectedRows();
    let ids = [];
    // let barcode = [];
    let idArr = "";
    let OrganismBarcode = "";
    // let splitWise = ""
    if (formulation_type === "Refill" && selectedData !== "") {
      idArr = selectedData.map(record => record.id);
      OrganismBarcode = selectedData.map(record => record.organism_barcode);
      // splitWise = selectedData.map((elem, index) => {
      //   typeParams = elem.sample_id.split("_", 1);
      // })
      for (let i = 0; i < typeParams.length; i++) {
        let axis = typeParams[i];
        lowerCase = axis.toLowerCase();
      }
      ids = idArr;
      axios
        .get(
          baseURL +
          "/delete_formula/" +
          ids.toString() + "?type=" + lowerCase,
          getHeaders()
        )
        .then(response => {
          alert(response.data.message);

        })
        .catch(error => {
          alert("Deleting from Display Grid | Error: ", error);
        });
    }


    else {

      if (module_name === "formulations_organism") {
        for (let i = 0; i < selectedData.length; i++) {
          let idArr = selectedData[i]["organism_id"];
          ids = idArr;
        }

        axios
          .delete(
            baseURL +
            "/inventory/" +
            ids.toString(),

            getHeaders()
          )
          .then(response => {
            alert("Successfully deleted the barcode");

          })
          .catch(error => {
            alert("Deleting from Display Grid | Error: ", error);
          });

      }
      else if (module_name === "userPermission") {
        axios
          .get(
            baseURL +
            "/users/PermissionModuleDelete/" + this.state.roleId
            ,
            getHeaders()
          )
          .then(response => {
            alert(response.data.message);
            window.location.reload();
          })
          .catch(error => {
            alert("Deleting from Display Grid | Error: ", error);
          });
      }
      else {
        if (module_name === "formulationsAdmin") {
          ids = selectedData.map(record => record.organisms_id);
          OrganismBarcode = selectedData.map(record => record.organism_barcode);

        }
        else if (module_name === "inventory") {
          ids = selectedData.map(record => record.organism_barcode);
          ids.filter((elem => {
            typeParams = elem.split("_", 1);
          }))
          for (let i = 0; i < typeParams.length; i++) {
            let axis = typeParams[i];
            lowerCase = axis.toLowerCase() + "_display";
          }
          customPrint(
            Config[module_name].section_title.display,
            PRINT_CATEGORIES.REQUEST,
            baseURL +
            "/delete_formula/" +
            ids.toString() + "?type=prm",
          );
          axios
            .get(
              baseURL +
              "/delete_formula/" +
              ids.toString() + "?type=" + lowerCase,
              getHeaders()
            )
            .then(response => {
              alert(response.data.message);
              customPrint(
                Config[module_name].section_title.display,
                PRINT_CATEGORIES.RESPONSE,
                response
              );
            })
            .catch(error => {
              alert("Deleting from Display Grid | Error: ", error);
            });
        }
        else if (module_name === "formulationsOperator") {
          //selectedData.forEach(row => row.organisms.forEach(o => ids.push(o.id)));
          let idArr = "";
          let sampleId;
          for (let i = 0; i < selectedData.length; i++) {

            if (selectedData[i] !== "") {
              idArr = selectedData.map(record => record.id);

              sampleId = selectedData.map(record => record.sample_id);
              sampleId.filter((elem => {
                typeParams = elem.split("_", 1);
              }))
              for (let i = 0; i < typeParams.length; i++) {
                let axis = typeParams[i];
                lowerCase = axis.toLowerCase();
              }

            }
            ids = idArr;
          }
          customPrint(
            Config[module_name].section_title.display,
            PRINT_CATEGORIES.REQUEST,
            baseURL +
            "/delete_formula/" +
            ids.toString() + "?type=prm",
          );
          axios
            .get(
              baseURL +
              "/delete_formula/" +
              ids.toString() + "?type=" + lowerCase,
              getHeaders()
            )
            .then(response => {
              alert(response.data.message);
              customPrint(
                Config[module_name].section_title.display,
                PRINT_CATEGORIES.RESPONSE,
                response
              );
            })
            .catch(error => {
              alert("Deleting from Display Grid | Error: ", error);
            });
        }

        else if (module_name === "formulations") {
          let sampleId;
          ids = "" + selectedData.map(record => record.id);
          sampleId = selectedData.map(record => record.sample_id);
          sampleId.filter((elem => {
            typeParams = elem.split("_", 1);
          }))
          for (let i = 0; i < typeParams.length; i++) {
            let axis = typeParams[i];
            lowerCase = axis.toLowerCase();
          }
          customPrint(
            Config[module_name].section_title.display,
            PRINT_CATEGORIES.REQUEST,
            baseURL +
            "/delete_formula/" +
            ids.toString() + "?type=prm",
          );
          axios
            .get(
              baseURL +
              "/delete_formula/" +
              ids.toString() + "?type=" + lowerCase,
              getHeaders()
            )
            .then(response => {
              alert(response.data.message);
              customPrint(
                Config[module_name].section_title.display,
                PRINT_CATEGORIES.RESPONSE,
                response
              );
            })
            .catch(error => {
              alert("Deleting from Display Grid | Error: ", error);
            });
        }
        else {
          ids = "" + selectedData.map(record => record.id);
          axios
            .get(
              baseURL +
              "/users/delete/" +
              ids,
              getHeaders()
            )
            .then(response => {
              alert(response.data.message);
            })
            .catch(error => {
              alert("Deleting from Display Grid | Error: ", error);
            });
        }


      }
    }
    // Delete the row in UI.
    api.updateRowData({ remove: selectedData });
  };

  userRoleOptions = () => {
    this.setState({

      role_options: [
        {
          key: 1,
          text: "Admin",
          value: 1,
          icon: "user secret"
        },
        {
          key: 2,
          text: "Manager",
          value: 2,
          icon: "user circle"
        },
        {
          key: 3,
          text: "Technician",
          value: 3,
          icon: "user md"
        }
      ],
      SelectedRole: [
        {
          key: 1,
          text: "Admin",
          value: 1,
          icon: "user secret"
        },
      ]
    })

    // API call to fetch the roles
    customPrint(
      Config.users.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/users/roleOptions"
    );

    axios
      .get(baseURL + "/users/roleOptions", getHeaders())
      .then(response => {
        customPrint(
          Config.users.section_title.create,
          PRINT_CATEGORIES.RESPONSE,
          response
        );
        this.setState({
          roleId: response.data[0].id
        })
        //fetches new roles apart from Admin, Op, Viewer
        let new_roles = response.data.filter(role => {
          return (
            role.name.toUpperCase() !== "ADMIN" &&
            role.name.toUpperCase() !== "MANAGER" &&
            role.name.toUpperCase() !== "TECHNICIAN"
          );
        });

        //pushes new_role-object in new_role_options[]
        if (new_roles !== " ") {
          let new_role_options = this.state.role_options;
          let i = 3;
          new_roles.map(new_role => {
            i++;
            new_role_options.push({
              key: new_role.id,
              value: i,
              icon: "user",
              text: new_role.name
                .charAt(0)
                .toUpperCase()
                .concat(new_role.name.substr(1))
            });
            return new_role;
          });


          if (this.is_mounted) {
            this.setState({
              role_options: new_role_options,
              is_page_loading: false
            });
          }
        }
      })
      .catch(error => {
        alert("Fetching Roles | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });

  }

  onSelectedRoleChanged = (event, { value }) => {

    var x = this.state.role_options;
    var y;
    x.filter((elem) => {
      if (elem.value === value) {
        y = elem.key;
        this.setState({
          roleId: y
        })
      }

    })
    let rowValue = null;

    axios
      .get(baseURL + "/users/PermissionBasedonRoles/" + y, getHeaders())
      .then(response => {
        rowValue = simplifyModulePermissionData(response.data);

        if (this.is_mounted) {
          // this.state.rowData = rowValue || response.data;
          this.setState({
            rowData: rowValue || response.data,
            is_page_loading: false
          });
        }
      })
      .catch(error => {
        // alert("Fetching Display Grid | Error: ", error);
        alert("Something went wrong! Please switch to Google Chrome browser for the best viewing experience!", error);

        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });

  }

  sortOrganism = (value) => {
    if (value.data.formulation_type === "formulation" || value.data.formulation_type === "refill") {
      this.setState({
        pdfs: false
      })
    }
    if (value.data.formulation_type === "premade") {
      this.setState({
        pdfs: true
      })
    }
    if (value.data.is_completed === 1) {
      this.setState({
        pdfs: false
      })
    }
    if (value.data.is_completed === 0) {
      this.setState({
        pdfs: true
      })
    }
    if (value.data.full_name !== getSessionStoredJsonItem("user").full_name) {
      this.setState({
        disabledUserName: false
      })
    }
    else {
      this.setState({
        disabledUserName: true
      })
    }

    this.setState({
      pdfId: value.data.id,
      pdfSampleId: value.data.sample_id,
      pdfFormullaId: value.data.formula_id,
      capsuleSize: value.data.flourish_capsule_size,
      selected_row_value_qc_weight: {},
      selected_row_value_filled_tube: {}
    })

    let FormullaId = value.data.formula_id;
    let typeParams = "";
    if (value.data.organism_barcode !== undefined) {
      let valueType = value.data.organism_barcode;
      typeParams = valueType.split("_", 1);
    }

    this.setState({
      tax_id: value.data.tax_id,
      sortFilterOrganism: FormullaId,
      id: value.data.id,
    })
  }

  cellValueChaged = (value) => {
    countPopUp = ++countPopUp;
    if (value.data.tax_id !== "NA" && countPopUp === 1) {
      let data = {
        tax_id: value.data.tax_id,
        token: Config.token
      }
      axios
        .post(portalURL + "/getmicrobnames", data)
        .then(response => {
          if (response.data.data.microbe_name === "") {
            alert("No data found");
          }
          else {
            window.confirm(" Organism Name is " + response.data.data.microbe_name);
          }
        })

    }
  }

  onDownloadPdf = () => {
    this.setState({
      is_page_loading: true,
    })
    axios
      .get(baseURL + "/pdf-data/" + this.state.pdfId + "/" + this.state.pdfSampleId, getHeaders())
      .then(res => {
        this.setState({
          is_page_loading: false,
          defaultValue: true,
          downloadData: res.data[0].data.calculation,
          calories: res.data[0].data.calories[0].total_value,
          currentAge: res.data[0].data.currentAge,
          pre_names: res.data[0].data.pre_names,
          pro_names: res.data[0].data.pro_names,
          qc_weight: res.data[0].data.qc_weight,
          servings_per_container: res.data[0].data.servings_per_container,
          probiotic_blend: res.data[0].data.probiotic_blend
        })
      })
  }


  render() {
    if (this.state.defaultValue) {
      return (<Redirect
        to={{
          pathname: "/pdf",
          downloadData: this.state.downloadData,
          pre_names: this.state.pre_names,
          qc_weight: this.state.qc_weight,
          pro_names: this.state.pro_names,
          currentAge: this.state.currentAge,
          servings_per_container: this.state.servings_per_container,
          probiotic_blend: this.state.probiotic_blend,
          pdfSampleId: this.state.pdfSampleId,
          pdfFormullaId: this.state.pdfFormullaId,
          calories: this.state.calories
        }} />)
    }
    return (
      <React.Fragment>
        {this.props.module_name === "formulationsOperator" && (
          <React.Fragment>
            {this.state.is_updating_successful && (
              <SuccessMessage
                is_message_visible={this.state.is_message_visible}
                hideMessage={this.hideMessage}
                message={this.state.response_message}
              />
            )}

            {this.state.is_updating_failed && (
              <ErrorMessage
                is_message_visible={this.state.is_message_visible}
                hideMessage={this.hideMessage}
                message={this.state.response_message}
              />
            )}

            {this.state.is_waiting_for_response && (
              <CustomLoader loading_text="Updating Formulation Data" />
            )}
          </React.Fragment>
        )}

        {this.state.is_page_loading && <CustomLoader />}
        <br />
        <Message>
          <Header as="h2" textAlign="center">
            {this.props.section_title}
          </Header>
        </Message>
        <CustomConfirm
          is_confirm_open={this.state.is_confirm_open}
          content={this.state.deleteMsg}
          header="Delete selected Record(s)"
          cancel_button_text="Eh! Never Mind."
          confirm_button_text="DELETE"
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}

        />
        <Segment
          compact
          raised
          padded
          style={{ display: "block" }}
          className="transparent"
        >
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Dropdown
                  id="page-size"
                  placeholder="Page Size"
                  selection
                  options={page_size_options}
                  onChange={newPageSize => onPageSizeChanged(newPageSize, api)}
                />
              </Grid.Column>
              {this.props.module_name === "userPermission" && (
                <Grid.Column className="centerAlign">
                  <Dropdown
                    id="role_dropdown"
                    placeholder="Admin"
                    fluid
                    search
                    selection
                    options={this.state.role_options}
                    onChange={this.onSelectedRoleChanged}
                    value={this.state.role_options.value}

                  />
                </Grid.Column>
              )}

              {this.props.module_name === "userPermission" && this.state.createGroupDelete === 1 && (
                <Grid.Column className="centerAlign">
                  <Button
                    id="deleteRecordsButton"
                    basic
                    negative
                    onClick={this.onDeleteClick}
                    icon
                    labelPosition="right"
                  >
                    Delete Group
                    <Icon name="trash" />
                  </Button>
                </Grid.Column>
              )}
              <Grid.Column className="centerAlign">
                <Button
                  basic
                  icon
                  onClick={() => resetSort(api)}
                  labelPosition="left"
                  style={{ marginLeft: "22px" }}
                >
                  <Icon name="sort alphabet up" />
                  Reset Sort(s)
                </Button>
              </Grid.Column>
              {this.props.module_name !== "userPermission" && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => resetFilter(api)}
                    icon
                    labelPosition="left"
                  >
                    Reset Filter(s)
                    <Icon name="filter" />
                  </Button>
                </Grid.Column>
              )}

              {getSessionStoredJsonItem("user").role_name !== "admin" && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => refreshPage()}
                    icon
                    labelPosition="left"
                  >
                    Refresh
                    <Icon name="refresh" />
                  </Button>
                </Grid.Column>
              )}

              {getSessionStoredJsonItem("user").role_name === "admin" && this.props.module_name !== "formulations" && this.props.module_name !== "userPermission" && this.props.module_name !== "formulationsOperator" && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    // onClick={() => exportAsCSV(api, "users")}
                    onClick={() => exportAsCSV(api, "formulation_inventory_" + moment().format('YYYYMMDD'))}
                    icon
                    labelPosition="right"
                  >
                    Export as CSV
                    <Icon name="share square" />
                  </Button>
                </Grid.Column>
              )}

              {this.props.module_name === "formulationsOperator" && this.props.module_name !== "formulations" && this.state.exportFormulationOperatorView === 1 && this.state.exportFormulationOperatorEdit === 0 && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => exportAsCSV(api, "formulation_queue_" + moment().format('YYYYMMDD'))}
                    icon
                    disabled="true"
                    labelPosition="right"
                  >
                    Export as CSV
                    <Icon name="share square" />
                  </Button>
                </Grid.Column>
              )}
              {this.props.module_name === "formulationsOperator" && this.props.module_name !== "formulations" && this.state.exportFormulationOperatorEdit === 1 && this.state.formulationQueue === 0 && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => exportAsCSV(api, "formulation_queue_" + moment().format('YYYYMMDD'))}
                    icon
                    disabled="true"
                    labelPosition="right"
                  >
                    Export as CSV
                    <Icon name="share square" />
                  </Button>
                </Grid.Column>
              )}
              {this.props.module_name === "formulationsOperator" && this.props.module_name !== "formulations" && this.state.exportFormulationOperatorEdit === 1 && this.state.formulationQueue === 1 && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => exportAsCSV(api, "manufacturing_queue_" + moment().format('YYYYMMDD'))}
                    icon
                    labelPosition="right"
                  >
                    Export as CSV
                    <Icon name="share square" />
                  </Button>
                </Grid.Column>
              )}
              {this.props.module_name === "formulations" && this.props.module_name !== "formulationsOperator" && this.state.exportAllFormulationView === 1 && this.state.exportAllFormulationEdit === 0 && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => exportAsCSV(api, "all_formulations_" + moment().format('YYYYMMDD'))}
                    icon
                    disabled="true"
                    labelPosition="right"
                  >
                    Export as CSV
                    <Icon name="share square" />
                  </Button>
                </Grid.Column>
              )}
              {this.props.module_name === "formulations" && this.props.module_name !== "formulationsOperator" && this.state.exportAllFormulationEdit === 1 && this.state.allformulationEdit === 0 && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => exportAsCSV(api, "all_formulations_" + moment().format('YYYYMMDD'))}
                    icon
                    disabled="true"
                    labelPosition="right"
                  >
                    Export as CSV
                    <Icon name="share square" />
                  </Button>
                </Grid.Column>
              )}
              {this.props.module_name === "formulations" && this.props.module_name !== "formulationsOperator" && this.state.exportAllFormulationEdit === 1 && this.state.allformulationEdit === 1 && (
                <Grid.Column className="centerAlign">
                  <Button
                    basic
                    onClick={() => exportAsCSV(api, "all_formulations_" + moment().format('YYYYMMDD'))}
                    icon
                    labelPosition="right"
                  >
                    Export as CSV
                    <Icon name="share square" />
                  </Button>
                </Grid.Column>
              )}
              {this.props.module_name === "formulationsOperator" && formulation_type === "Formulation" && this.state.formulationQueue === 1 && (
                <Grid.Column className="centerAlign">
                  <StartFormulationModal
                    selected_row_value={this.state.selected_row_value}

                    selected_row_value_date_made={this.state.selected_row_value_date_made}

                    selected_row_value_qc_weight={this.state.selected_row_value_qc_weight}
                    selected_row_value_filled_tube={this.state.selected_row_value_filled_tube}
                    is_special_button_disabled={
                      this.state.is_special_button_disabled
                    }
                    is_waiting_for_response={this.state.is_waiting_for_response}

                    //methods
                    onStateChange={this.onStateChange}
                    onModalValueChange={this.onModalValueChange}
                    onModalValueChangeQcWeight={this.onModalValueChangeQcWeight}
                    onModalValueChangePill={this.onModalValueChangePill}

                    onModalValueChange_Date={this.onModalValueChange_Date}

                    shareSorting={this.state.sortFilterOrganism}
                  />
                </Grid.Column>
              )}
              {this.props.module_name === "formulationsOperator" && formulation_type === "Refill" && this.state.formulationQueue === 1 && (
                <Grid.Column className="centerAlign">
                  <StartRefillModal
                    selected_row_value={this.state.selected_row_value}
                    selected_row_value_qc_weight={this.state.selected_row_value_qc_weight}
                    selected_row_value_filled_tube={this.state.selected_row_value_filled_tube}

                    selected_row_value_date_made={this.state.selected_row_value_date_made}

                    is_special_button_disabled={
                      this.state.is_special_button_disabled
                    }
                    is_waiting_for_response={this.state.is_waiting_for_response}
                    //methods
                    onStateChange={this.onStateChange}
                    onModalValueChange={this.onModalValueChange}
                    onModalValueChangeQcWeight={this.onModalValueChangeQcWeight}
                    onModalValueChangePill={this.onModalValueChangePill}
                    onModalValueChange_Date={this.onModalValueChange_Date}
                    shareSorting={this.state.sortFilterOrganism}
                  />
                </Grid.Column>
              )}

              {this.props.module_name === "formulationsOperator" && formulation_type === "Reformulation" && this.state.formulationQueue === 1 && (
                <Grid.Column className="centerAlign">
                  <StartRefillModal
                    selected_row_value={this.state.selected_row_value}
                    selected_row_value_qc_weight={this.state.selected_row_value_qc_weight}
                    selected_row_value_filled_tube={this.state.selected_row_value_filled_tube}

                    selected_row_value_date_made={this.state.selected_row_value_date_made}

                    is_special_button_disabled={
                      this.state.is_special_button_disabled
                    }
                    is_waiting_for_response={this.state.is_waiting_for_response}
                    //methods
                    onStateChange={this.onStateChange}
                    onModalValueChange={this.onModalValueChange}
                    onModalValueChangeQcWeight={this.onModalValueChangeQcWeight}
                    onModalValueChangePill={this.onModalValueChangePill}
                    onModalValueChange_Date={this.onModalValueChange_Date}
                    shareSorting={this.state.sortFilterOrganism}
                  />
                </Grid.Column>
              )}

              {this.props.module_name === "formulations" && this.state.AllFormulation === 1 && (

                <Grid.Column className="centerAlign" style={{ width: "0" }}>
                  <Button
                    id="deleteRecordsButton"
                    basic
                    // negative
                    onClick={this.onDownloadPdf}
                    icon
                    labelPosition="right"
                    disabled={this.state.pdfs}
                  >
                    View Label
                    <Icon name="download" />
                  </Button>

                </Grid.Column>
              )}

              {/* {getSessionStoredJsonItem("user").role_name !== "technician" && this.props.module_name !== "userPermission"&&( */}
              {/* { (this.props.module_name =="formulationsOperator" && this.state.displayFormulation == 1 )||(this.props.module_name=="formulations" && this.state.AllFormulation==1) || (this.props.module_name == "userPermission" && this.state.groupManagement==1) &&( */}
              {this.props.module_name === "formulations" && this.state.AllFormulation === 1 && (

                <Grid.Column className="centerAlign" style={{ width: "0" }}>
                  <Button
                    id="deleteRecordsButton"
                    basic
                    negative
                    onClick={this.onDeleteClick}
                    icon
                    labelPosition="right"
                    disabled={this.state.is_special_button_disabled}
                  >
                    Delete Record(s)
                    <Icon name="trash" />
                  </Button>
                </Grid.Column>
              )}
              {(this.props.module_name === "formulationsOperator" && this.state.displayFormulation === 1) && (

                <Grid.Column className="centerAlign">
                  <Button
                    id="deleteRecordsButton"
                    basic
                    negative
                    onClick={this.onDeleteClick}
                    icon
                    labelPosition="right"
                    disabled={this.state.is_special_button_disabled}
                  >
                    Delete Record(s)
                    <Icon name="trash" />
                  </Button>
                </Grid.Column>
              )}

              {(this.props.module_name === "users" && this.state.userManagement === 1) && (

                <Grid.Column className="centerAlign">
                  <Button
                    id="deleteRecordsButton"
                    basic
                    negative
                    onClick={this.onDeleteClick}
                    icon
                    labelPosition="right"
                    disabled={this.state.disabledUserName || this.state.is_special_button_disabled}
                  >
                    Delete Record(s)
                    <Icon name="trash" />
                  </Button>
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div
                  id="myGrid"
                  ag-grid="gridOptions"
                  style={{
                    height: "100%",
                    width: "100%"
                  }}

                  className="ag-theme-balham"
                >
                  <AgGridReact
                    rowHeight="30"
                    rowSelection={this.state.rowSelection}
                    onCellValueChanged={this.cellValueChaged}
                    editType={this.state.editType}
                    floatingFilter={true}
                    onRowClicked={this.sortOrganism}
                    onGridReady={params => {
                      api = params.api;
                      api.showLoadingOverlay();
                      column_api = params.column_api;
                      onGridReady(
                        params,
                        api,
                        column_api,
                        this.props.initial_sort
                      );
                    }}
                    animateRows={true}
                    pagination={true}
                    paginationPageSize={20}
                    paginationNumberFormatter={params => {

                      return "[" + params.value.toLocaleString() + "]";
                    }}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    onRowValueChanged={params =>

                      this.onRowValueChanged(params, this.props.required_fields)
                    }
                    onSelectionChanged={params =>
                      this.onSelectionChanged(params)
                    }
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </React.Fragment >
    );
  }
}

export default CustomDisplayGrid;
