import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import CustomLoader from "../custom_elements/CustomLoader";
import { changeAvailablityOfOrganism, checkValueExistOrNotInMultiVFun, isAvailable_lower, multi_v_availablity, onGridReady } from "../helper";
import CustomDivider from "../custom_elements/CustomDivider";
import { baseURL } from "../../configuration/constants";
import Config from "../../configuration/config";
import { focusFirstElement, getDateString, increaseDateYear, formatDate, getHeaders, getSessionStoredJsonItem } from "../helper";
import { Form, Popup, Segment, Button, Input, Grid, Radio, TextArea } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Custom_DropDown } from "./DropDown";
import MultiVAvailablity from "./multi_v_availablity";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

let api, column_api;
let pdf_name = [];
var multi_v_value = "";

const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
}

class ExistProbiotic extends Component {
  state = {
    multi_v: "available",
    ingredient_type: "",
    multi_v_gram: "",
    files: [],
    is_avaialble_value: "",
    attachment_arr: [],
    id: "",
    empty: [],
    multiSelectData: [],
    benefit: [],
    organism_barcode: "",
    concentration: "",
    qty: "",
    manufacture: "",
    storage_location: "",
    shelf: "",
    name: "",
    real_name: "",
    expiration_date: increaseDateYear(new Date()),
    is_available: 0,
    reorder_point: 0,
    scrap_factor: 0,
    receive_date: new Date(),
    open_date: new Date(),
    manufacture_lot: "",
    tax_id: "",
    barcode: "",
    is_saving_successful: false,
    is_saving_failed: false,
    is_message_visible: true,
    response_message: "",
    showStore: false,
    samples: [],
    columnDefs: [
      {
        headerName: "Ingredient Code",
        field: "organism_barcode",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 130,
      },
      {
        headerName: "Concentration",
        field: "concentration",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 120,
      },

      {
        headerName: "Quantity",
        field: "qty",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        width: 110,
      },
      {
        headerName: "Manufacturer",
        field: "manufacture",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 110,
      },
      {
        headerName: "Expiration Date",
        field: "expiration_date",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        width: 135,
      },
      {
        headerName: "Data Storage",
        field: "storage_location",
        filter: "agTextColumnFilter",
        width: 120,
        sortable: true,
      },
      {
        headerName: "Shelf",
        field: "shelf",
        filter: "agTextColumnFilter",
        width: 100,
        sortable: true,
      },
      {
        headerName: "Internal Name",
        field: "name",
        filter: "agTextColumnFilter",
        width: 135,
        sortable: true,
      },
      {
        headerName: "Display Name",
        field: "real_name",
        filter: "agTextColumnFilter",
        width: 180,
        sortable: true,
      },
    ],
  };

  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
  }

  componentWillMount = () => {
    axios
      .get(baseURL + "/barcode_Type_Based?type=pro", getHeaders())
      .then((res) => {
        let data = res.data;
        return data;
      })
      .then((samples) => {
        this.setState({
          samples: samples,
        });
      });
  };

  componentDidMount = () => {
    this.fetchBenefitData();
  };
  fetchBenefitData = () => {
    axios.get(baseURL + "/benefits", getHeaders()).then((response) => {
      let x = response.data.data;
      let emptyArray = [];
      x &&
        x.map((elem, index) => {
          let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id };
          emptyArray.push(obj);
        });
      this.setState({
        multiSelectData: emptyArray,
      });
    });
  };
  tableData = (value) => {
    let emptyArray = [];
    console.log(value);
    this.setState({
    ingredient_type: value.ingredient_type !== null ? value.ingredient_type : "",
    multi_v_gram: value.multi_v_gram !== null ? value.multi_v_gram : "",
    multi_v: value.multi_v !== null ? value.multi_v : "available"
   });     


    axios.get(baseURL + "/benefits/" + value.id, getHeaders()).then((response) => {
      let x = response.data.data;

      x &&
        x.map((elem, index) => {
          let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id };
          emptyArray.push(obj);
        });
      this.setState({
        showStore: true,
        organism_barcode: value.organism_barcode,
        concentration: value.concentration,
        qty: value.qty,
        name: value.name,
        real_name: value.real_name,
        shelf: value.shelf,
        storage_location: value.storage_location,
        manufacture: value.manufacture,
        tax_id: value.tax_id,
        empty: emptyArray,
      });
      this.onSelect(emptyArray, {});
    });
  };

  onSelect = (selectedList, selectedItem) => {
    if (selectedList && selectedList.length) {
      this.setState({
        benefit: selectedList,
      });
    } else {
      this.setState({
        benefit: [],
      });
    }
  };

  /**
   * Handles the date change
   *
   * @param {String} name
   * @param {Date} date
   */
  onDateChange = (name, date) => {
    if (name === Config.inventory.receive_date) {
      this.setState({ receive_date: date });
      if (date) {
        this.setState({ expiration_date: increaseDateYear(date) });
      }
    } else if (name === Config.inventory.open_date) {
      this.setState({ open_date: date });
    } else if (name === Config.inventory.expiration_date) {
      this.setState({ expiration_date: date });
    } else {
      alert("Some Date Error Enterred!");
    }
  };

  changeAvailablity = async (value) => {
    var data = await changeAvailablityOfOrganism(value);
    this.setState(data);
  };

  AnswerKeyPress = (e) => {
    let code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  };

  onInputChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  // Handles submit click
  onSubmitClick = () => {
    let { manufacture_lot, qty, scrap_factor, reorder_point,multi_v, ingredient_type, multi_v_gram } = this.state;
    var datass = checkValueExistOrNotInMultiVFun(multi_v, ingredient_type, multi_v_gram);
    if (!datass.unavailable && datass.checked) {
      alert(datass.info);
      return;
    }

    if (!manufacture_lot) {
      toast.info("Please fill all the mandatory fields.");
      return;
    }
    if (scrap_factor === "" || reorder_point === "" || qty === "") {
      toast.info("Please fill all the mandatory information.");
      return;
    }
    this.setState({
      is_saving_successful: false,
      is_saving_failed: false,
    });

    let res = _.pick(this.state, ["receive_date",  "ingredient_type", "multi_v_gram", "open_date", "expiration_date", "is_available", "concentration", "manufacture", "multi_v",
    "manufacture_lot", "qty", "reorder_point", "storage_location", "shelf", "scrap_factor", "notes", "name", "real_name", "organism_barcode", "tax_id", "benefit", "attachment_arr"]);
    
    // adding the remaning fields.
    res.user_id = getSessionStoredJsonItem("user").user_id;
    res.open_date = formatDate(this.state.open_date);
    res.receive_date = formatDate(this.state.receive_date);
    res.expiration_date = formatDate(this.state.expiration_date);
    this.setState({ is_waiting_for_response: true });
    axios
      .post(baseURL + "/add_new_barcodes?type=pro", res, getHeaders())
      .then((response) => {
        toast.success("Inventory Added Successfully" + "  Barcode is  " + response.data.barcode);
        this.setState({
          is_waiting_for_response: false,
          is_page_loading: false,
        });
        focusFirstElement();
        this.fetchBenefitData();
        this.setState({
          benefit: [],
          showStore: false,
        });
        if (response.data.success === true) {
          setInterval(() => {
            window.location.reload(false);
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error("Invalid Data");
        this.setState({ is_page_loading: false });
      });

    this.multiselectRef.current.resetSelectedValues();
  };

  onCancelClick = () => {
    this.setState({
      receive_date: "",
      open_date: "",
      expiration_date: "",
      is_available: "",
      concentration: "",
      manufacture: "",
      manufacture_lot: "",
      qty: "",
      reorder_point: "",
      storage_location: "",
      shelf: "",
      scrap_factor: "",
      notes: "",
      name: "",
      real_name: "",
    });
  };

  onRemove = (value) => {
    this.setState({
      benefit: value,
    });
  };

  onupdatefiles = (fileItems) => {
    const scope = this;
    let attachment_arr_temp = this.state.attachment_arr;
    attachment_arr_temp = [];
    fileItems.map((elem, index) => {
      let mediaTypePdf = elem.file.type.split("/")[1];
      let mediaSize = elem.file.size;
      let mediaType = elem.file.type;
      let mediaName = elem.file.name;
      var selectedFile = elem.file;
      if (selectedFile) {
        var fileToLoad = selectedFile;
        var fileReader = new FileReader();
        var base64;
        fileReader.readAsDataURL(fileToLoad);
        fileReader.onload = function (fileLoadedEvent) {
          base64 = fileLoadedEvent.target.result;
          let res = base64.slice(28);
          if (mediaTypePdf === "pdf" && mediaSize <= 5242880) {
            let obj = {};
            obj = {
              base64Media: res,
              mediaName: mediaName,
              type: mediaType,
              size: mediaSize,
            };
            attachment_arr_temp.push(obj);
            const final_array = [...new Map(attachment_arr_temp.map((item) => [JSON.stringify(item), item])).values()];
            scope.setState({
              attachment_arr: final_array,
            });
          }
        };
      }
    });
  };

  handleRemove = () => {
    const scope = this;
    this.setState({
      attachment_arr: [],
    });
    let attachment_arr_temp = this.state.attachment_arr;
    if (this.pond) {
      const remainingAttachments = this.pond.getFiles().map((file) => file.source);
      remainingAttachments.map((elem, index) => {
        let mediaSize = elem.size;
        let mediaType = elem.type;
        let mediaName = elem.name;
        var selectedFile = remainingAttachments[0];
        if (selectedFile) {
          var fileToLoad = selectedFile;
          var fileReader = new FileReader();
          var base64;
          fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            let res = base64.slice(28);
            if (mediaSize <= 5242880) {
              let obj = {};
              obj = {
                base64Media: res,
                mediaName: mediaName,
                type: mediaType,
              };
              attachment_arr_temp.push(obj);
              const final_array = [...new Map(attachment_arr_temp.map((item) => [JSON.stringify(item), item])).values()];
              scope.setState({
                attachment_arr: final_array,
              });
            }
          };

          fileReader.readAsDataURL(fileToLoad);
        }
      });
    }
  };
  multi_v_availablity_fn = (type, value) => {
    var multi_value = multi_v_availablity(type, value, this.state.multi_v);
      console.log(multi_value);
    this.setState(multi_value);
  };

  render() {
    const { is_waiting_for_response, real_name, name, concentration, qty, reorder_point, manufacture_lot, tax_id, expiration_date, open_date, scrap_factor, notes, multiSelectData, shelf, storage_location, manufacture, receive_date, is_page_loading, showStore, benefit, columnDefs } = this.state;
    return (
      <React.Fragment>
        {is_page_loading && <CustomLoader />}
        <br />
        <Segment className="transparent">
          <Grid.Row>
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <div
                    id="myGrid"
                    className="ag-theme-balham"
                    ag-grid="gridOptions"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <AgGridReact
                      rowHeight="30"
                      rowSelection="single"
                      onRowClicked={(e) => {
                        let sample = e.data;
                        this.tableData(sample);
                      }}
                      onGridReady={(params) => {
                        api = params.api;
                        api.showLoadingOverlay();
                        api.sizeColumnsToFit();
                        column_api = params.column_api;
                        onGridReady(params, api, column_api, this.props.initial_sort);
                      }}
                      animateRows={true}
                      pagination={true}
                      paginationPageSize={10}
                      paginationNumberFormatter={(params) => {
                        return "[" + params.value.toLocaleString() + "]";
                      }}
                      columnDefs={columnDefs}
                      floatingFilter={true}
                      rowData={this.state.samples}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Row>
          <Grid.Row />
        </Segment>

        <Segment
          className="transparent"
          style={{ display: showStore ? "block" : "none", marginTop: "70px" }}
        >
          {is_waiting_for_response && <CustomLoader loading_text="Saving Inventory Data" />}
          <Form id="transparent">
            <Grid columns="equal">
              <CustomDivider
                h_size="h3"
                title="Probiotic Information"
                icon_name="dna"
              />
              <Grid.Row>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Tax Id</label>}
                      content="Enter Tax Id"
                    />
                    <form>
                      <Input
                        name="tax_id"
                        value={tax_id}
                        onChange={this.onInputChange}
                        iconPosition="left"
                        icon="hashtag"
                        placeholder="Tax Id"
                        disabled
                      />
                    </form>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Display Name</label>}
                      content="Enter the Barcode Digits."
                    />
                    <Input
                      className="full_opacity_red"
                      name="real_name"
                      onChange={this.onInputChange}
                      value={real_name}
                      icon="weight"
                      iconPosition="left"
                      placeholder="Organism Display Name"
                      disabled
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Internal Name</label>}
                      content="Enter the Barcode Digits."
                    />
                    <Input
                      className="full_opacity_red"
                      name="name"
                      value={name}
                      onChange={this.onInputChange}
                      icon="weight"
                      iconPosition="left"
                      placeholder="Add Name"
                      disabled
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Custom_DropDown
                    label={"Is Available"}
                    content={"Is Organism available ?"}
                    onChange={this.changeAvailablity}
                    isAvailable={this.props.isAvailable}
                    is_available={"is_available"}
                    classNames={"is-available opacity-1"}
                    dropDownValue={this.state.is_avaialble_value}
                    inventory={this.props.inventory}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Concentration</label>}
                      content="Enter the concentration of the Organism."
                    />
                    <Input
                      name="concentration"
                      onChange={this.onInputChange}
                      value={concentration}
                      placeholder="Concentnration"
                      label={{ content: "B CFU/g" }}
                      labelPosition="right"
                      type="text"
                      onKeyPress={this.AnswerKeyPress}
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Quantity</label>}
                      content="Enter the Organism Quantity."
                    />
                    <Input
                      name="qty"
                      onChange={this.onInputChange}
                      value={qty}
                      placeholder="Quantity"
                      icon="weight"
                      iconPosition="left"
                      label={{ content: "g" }}
                      labelPosition="right"
                      type="text"
                      onKeyPress={this.AnswerKeyPress}
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Reorder Point</label>}
                      content="Enter the Reorder Point."
                    />
                    <Input
                      className="full_opacity_red"
                      name="reorder_point"
                      onChange={this.onInputChange}
                      value={reorder_point}
                      placeholder="Reorder Point"
                      icon="weight"
                      iconPosition="left"
                      label={{ content: "g" }}
                      labelPosition="right"
                      type="text"
                      onKeyPress={this.AnswerKeyPress}
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Scrap Factor</label>}
                      content="Enter the Organism Scrap Factor."
                    />
                    <Input
                      name="scrap_factor"
                      onChange={this.onInputChange}
                      value={scrap_factor}
                      placeholder="Scrap Factor"
                      label={{ content: "%" }}
                      labelPosition="right"
                      type="text"
                      onKeyPress={this.AnswerKeyPress}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Popup
                    trigger={<label>Notes</label>}
                    content="Enter notes."
                  />
                  <TextArea
                    name="notes"
                    onChange={this.onInputChange}
                    value={notes}
                    placeholder="Add Notes here..."
                    style={{ minHeight: 100 }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
    <Grid.Column >
    <div>Multi-V Availability</div>
    <Radio
    toggle
    className="allergen-radio-btn"
    onClick={()=>this.multi_v_availablity_fn('radio',this.state.multi_v)}
    checked={(this.state.multi_v == "available")?true:false}
    />
    
    </Grid.Column>
    {(this.state.multi_v == "available")&& (
    <>
      <Grid.Column  width={6}>
          <Custom_DropDown
                label={"Ingredient Type"}
                content={"Ingredient type."}
                onChange={(e)=>this.multi_v_availablity_fn("ingredient_type",e)}
                isAvailable={isAvailable_lower}
                is_available={"is_available"}
                classNames={"is-available"}                
                dropDownValue={this.state.ingredient_type}
                inventory={inventory}
            />
    </Grid.Column>
      <Grid.Column  width={6}>
            <span>Price per gram </span>
            <Input
            name="price_per_gram"
            type="number"
            fluid
            value={this.state.multi_v_gram}
            placeholder="Price per gram"
            onChange={(e)=>{ this.multi_v_availablity_fn('multi_v_gram',e.target.value) }}
        />
      </Grid.Column></>)}
    
    </Grid.Row>

              {/* <CustomDivider
                h_size="h3"
                title="Benefits"
                icon_name="cart plus"
              />
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <Popup
                      trigger={<label>Benefits</label>}
                      content="Benefits."
                    />
                    <Multiselect
                      options={multiSelectData}
                      onSelect={this.onSelect}
                      selectedValues={benefit}
                      onRemove={this.onRemove}
                      displayValue="name"
                      ref={this.multiselectRef}
                      closeOnSelect={false}
                      placeholder="Select Benefits"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row> */}

              <CustomDivider
                h_size="h3"
                title="Manufacturer Information"
                icon_name="industry"
              />
              <Grid.Row>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Receive Date</label>}
                      content="Enter the Organism Receive Date."
                    />
                    <DatePicker
                      selected={receive_date}
                      placeholderText="Click to select a date"
                      todayButton={"Current Date"}
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      openToDate={new Date(receive_date)}
                      onChange={(date) => {
                        this.onDateChange(Config.inventory.receive_date, date);
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Open Date</label>}
                      content="Enter the Organism Open Date."
                    />

                    <DatePicker
                      selected={open_date}
                      placeholderText="Click here to select date"
                      todayButton={"Current Date"}
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      openToDate={new Date(open_date)}
                      onChange={(date) => {
                        this.onDateChange(Config.inventory.open_date, date);
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Expiration Date</label>}
                      content="Enter the Organism Expiration Date."
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      todayButton={"Current Date"}
                    />
                    <DatePicker
                      placeholderText="Click here to select date"
                      todayButton={"Current Date"}
                      dateFormat="MM/dd/yyyy"
                      selected={expiration_date}
                      isClearable={true}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      openToDate={new Date(Config.default_calendar_date)}
                      onChange={(date) => {
                        this.onDateChange(Config.inventory.expiration_date, date);
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Manufacturer</label>}
                      content="Enter the Manufacturer of the Organism."
                    />
                    <Input
                      placeholder="Manufacturer"
                      icon="building"
                      iconPosition="left"
                      name="manufacture"
                      onChange={this.onInputChange}
                      value={manufacture}
                      disabled
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column>
                  <Form.Field
                    required
                    fluid="true"
                  >
                    <Popup
                      trigger={<label>Manufacturer Lot</label>}
                      content="Enter the Manufacturer Lot of the Organism."
                    />
                    <Input
                      name="manufacture_lot"
                      onChange={this.onInputChange}
                      value={manufacture_lot}
                      placeholder="Manufacturer Lot"
                      icon="hashtag"
                      iconPosition="left"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row />

              <CustomDivider
                h_size="h3"
                title="Storage Information"
                icon_name="dropbox"
              />

              <Grid.Row>
                <Grid.Column>
                  <Form.Field fluid="true">
                    <Popup
                      trigger={<label>Storage Location</label>}
                      content="Enter the Organism Storage Location (Fridge Number)."
                    />
                    <Input
                      placeholder="Storage Location (Fridge Number)"
                      icon="boxes"
                      iconPosition="left"
                      name="storage_location"
                      onChange={this.onInputChange}
                      value={storage_location ? storage_location : " "}
                    />
                  </Form.Field>
                </Grid.Column>

                <Grid.Column>
                  <Form.Field fluid="true">
                    <Popup
                      trigger={<label>Shelf</label>}
                      content="Enter the Organism Shelf Number."
                    />
                    <Input
                      placeholder="Shelf"
                      icon="box"
                      iconPosition="left"
                      name="shelf"
                      onChange={this.onInputChange}
                      value={shelf}
                      type="text"
                      onKeyPress={this.AnswerKeyPress}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>

              <CustomDivider
                h_size="h3"
                title="Upload Documents"
                icon_name="upload"
              />
              <Grid.Column>
                <FilePond
                  acceptedFileTypes={["application/pdf"]}
                  ref={(ref) => (this.pond = ref)}
                  allowMultiple={true}
                  allowReorder={true}
                  allowRemove={true}
                  maxFiles={10}
                  allowFileSizeValidation={true}
                  maxFileSize="5MB"
                  onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                  onremovefile={this.handleRemove}
                  checkValidity={true}
                  dropValidation
                />
              </Grid.Column>
              <Grid.Row />

              <Grid.Row />
              <Grid.Row />
              <Grid.Row />
              <Grid.Row />
              <Grid.Row>
                <Grid.Column>
                  <Grid textAlign="center">
                    <Button.Group>
                      <Popup
                        trigger={
                          <Button
                            onClick={this.onSubmitClick}
                            positive
                          >
                            Submit
                          </Button>
                        }
                        content="Submit the Organism Information to the Database."
                      />
                      <Button.Or />
                      <Popup
                        trigger={<Button onClick={this.onCancelClick}>Cancel</Button>}
                        content="Discard the Organism Informtaion."
                      />
                    </Button.Group>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row />
              <Grid.Row />
            </Grid>
          </Form>
        </Segment>
      </React.Fragment>
    );
  }
}

export default ExistProbiotic;
