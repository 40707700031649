import React, { Component } from "react";

import "./index.css";
import HoverTagsData from "./hoverTagsData";


let arr =[];

class TableAccordian extends Component {
    state = {
        expanded: false
    };

    getArray = (value) => {
        this.props.getArrayFromTagAccordian(value)
    }

    render() {
        const { data, sampleId, formulaId, dateCreated, dateMade, userName } = this.props;
        const { expanded } = this.state;
        return (
            <tbody>
                <tr key="main" className="success-data">
                    <td onClick={() => this.setState({ expanded: !this.state.expanded })}>
                        <i className={`fa fa-${expanded ? 'minus' : 'plus'}-square fa-2x`}></i>
                    </td>

                    <td>{sampleId}</td>
                    <td>{formulaId}</td>
                    <td>{dateCreated}</td>
                    <td>{dateMade}</td>
                    <td>{userName}</td>
                </tr>
                {expanded && (
                    <tr className="expandable" key="tr-expander">
                        <td colSpan={6} className="uk-background-muted">
                            <div ref="expanderBody" className="inner uk-grid">
                                <table id="expand-table">
                                    <thead>
                                        <tr>
                                            <th>Add</th>
                                            <th>Ingredient Barcode</th>
                                            <th>Amount Added</th>
                                            <th>Total CFU</th>
                                            <th>Concentration </th>
                                            <th>Tags</th>
                                        </tr>
                                    </thead>
                                    {
                                        data && data.inventory && data.inventory.length ?
                                            // data.inventory.map(({ organism_barcode, final_amount_added, total_cfu, final_concentration, tags }, i) => {

                                            data.inventory.map((elem, i) => {
                                                return (
                                            <tbody key={i}>
                                                        <td onClick={() => this.getArray(elem, i)} style={{cursor:"pointer"}}>
                                                            <i className="fa fa-plus"
                                                             aria-hidden="true"
                                                            //  style={{color:this.props.arr.indexOf(elem.organism_barcode)!= -1?"red":"green"}}
                                                             ></i>
                                                        </td>
                                                        <td>{elem.organism_barcode}</td>
                                                        <td>{elem.amount_added}</td>
                                                        <td>{elem.amount_added * elem.concentration}</td>
                                                        <td>{elem.concentration}</td>
                                                        <td className="tooltip-table">
                                                            <HoverTagsData tags={elem.tags} />
                                                        </td>
                                                    </tbody>
                                                )
                                            })
                                            : null
                                    }
                                </table>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>

        )
    }
}

export default TableAccordian;