//-------------------------------------------------------------------//
// Module: ColumnDefs                                                //
// Tasks: 1) Returns specific colDefs according to module            //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import {
  convertRolesJSON,
  convertUserOptionsJSON,
  convertUserNameOptionsJSON,
  dateComparator,
  getHeaders,
  convertInventoryOptionsJSON
} from "../helper";
import axios from "axios";
import { getInventoryColumnDefsArray } from "./InventoryColumnDefsArray";
import { getUsersColumnDefsArray } from "./UsersColumnDefsArray";
import { getModulePermissionDefsArray } from "./ModulePermissionColumnDefsArray";
import { getPrebioticsAndProbioticsNamesDefsArray } from "./PrebioticsAndProbioticsNamesDefsArray";
import { getFormulationsColumnDefsArray } from "./FormulationsColumnDefsArray";
import { getFormulationsAdminColumndDefsArray } from "./FormulationsAdminColumnDefsArray";
import { getFormulationsOperatorColumnDefsArray } from "./FormulationsOperatorColumnDefsArray";
import { baseURL } from "../../configuration/constants";

/**
 * API calls to get User and InventoryOptions.
 */
function getUserAndInventoryOptions() {
  // Requests will be executed in parallel...
  return axios.all([getUserOptions(), getInventoryOptions()]).then(
    axios.spread((user_options, inventory_options) => {
      return {
        user_options: user_options,
        inventory_options: inventory_options
      };
    })
  );
}

/**
 * API call to get roleOptions json.
 */
function getRoleOptions() {
  return axios
    .get(baseURL + "/users/roleOptions", getHeaders())
    .then(response => response.data)
    .catch(error => {
      console.warn(error);
      return null;
    });
}

/**
 * API call to get userOptions json
 */
function getUserOptions() {
  return axios
    .get(baseURL + "/users/userOptions", getHeaders())
    .then(response => response.data)
    .catch(error => {
      console.warn(error);
      return null;
    });
}

/**
 * API call to get inventoryOptions json
 */
function getInventoryOptions() {
  return axios
    .get(baseURL + "/inventory/inventoryOptions", getHeaders())
    .then(response => 
      response.data)
    .catch(error => {
      console.warn(error);
      return null;
    });
}
function getAllBarcodes() {
  return axios
    .get(baseURL + "/inventory/getAllBarcodes", getHeaders())
    .then(response => response.data
      )
    .catch(error => {
      console.warn(error);
      return null;
    });
}
function getpermissions() {
  return axios
    .get(baseURL + "/users/PermissionDetails", getHeaders())
    .then(response => response.data
      )
    .catch(error => {
      console.warn(error);
      return null;
    });
}

/**
 * Gets the User Column Defintions for ag-grid.
 */
export function getPrebioticsAndProbioticsNamesDefs() {
 
  return getAllBarcodes().then(data => {
   
    return getPrebioticsAndProbioticsNamesDefsArray(data);
  });
}
export function getUsersColumnDefs() {
  return getRoleOptions().then(data => {
     let   BlankData = [
      {
        id: 0,
        name: "select",  
      }
  ];
  data= BlankData.concat(data);
    let converted_role_options_json = convertRolesJSON(data);
    let all_role_ids = data.map(role => role.id);

    return getUsersColumnDefsArray(converted_role_options_json, all_role_ids);
  });
}
/**
 * Gets the Module permission  for ag-grid.
 */
export function getModulePermissionDefs() {
 
  return getpermissions().then(data => {
   let PermissionDetailsArray=[{
     "0":"false",
     "1":"true"
   }]
 
    return getModulePermissionDefsArray(data);
  });
}

/**
 * Gets the Inventory Column Defintions for ag-grid.
 */
export function getInventoryColumnDefs() {
  return getUserOptions().then(data => {
    let converted_user_options_json = convertUserOptionsJSON(data);
    let all_user_ids = data.map(user => user.id);

    return getInventoryColumnDefsArray(
      converted_user_options_json,
      all_user_ids,
      dateComparator
    );
  });
}

/**
 * Gets the Formulations Column Defintions for ag-grid.
 */
export function getFormulationsColumnDefs() {
  return getUserOptions().then(data => {
    let converted_user_options_json = convertUserOptionsJSON(data);
    let all_user_ids = data.map(user => user.id);
   let converted_user_name_options_json=convertUserNameOptionsJSON(data);
  let all_user_name = data.map(user => user.full_name);
  
    return getFormulationsColumnDefsArray(
      converted_user_options_json,
      converted_user_name_options_json,
     all_user_ids,
     all_user_name,
      dateComparator,
      data
    );
  });
}

/**
 * Gets the FormulationsAdmin Column Defintions for ag-grid.
 */
export function getFormulationsAdminColumnDefs() {
  return getUserAndInventoryOptions().then(data => {
    let user_options = data.user_options;
    let inventory_options = data.inventory_options;

    let all_user_ids = user_options.map(user => user.id);
  
    let all_inventory_ids = inventory_options.map(inventory => inventory.id);
    let converted_inventory_options_json = convertInventoryOptionsJSON(
      inventory_options
    );
    
    let converted_user_options_json = convertUserOptionsJSON(user_options);
    // console.log("converted_user_options_json",converted_user_options_json);
    return getFormulationsAdminColumndDefsArray(
      converted_user_options_json,
      all_user_ids,
      dateComparator,
      converted_inventory_options_json,
      all_inventory_ids
    );
  });
}

/**
 * Gets the FormulationsOperator Column Defintions for ag-grid.
 */
export function getFormulationsOperatorColumnDefs() {
  return getUserOptions().then(data => {
    let converted_user_options_json = convertUserOptionsJSON(data);
    let all_user_ids = data.map(user => user.id);
 
    return getFormulationsOperatorColumnDefsArray(
      converted_user_options_json,
      all_user_ids,
      dateComparator
    );
  });
}
