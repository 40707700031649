
//----------------------------------------------------------------------//
// Module: User                                                         //
// SubModule: CreateUser                                                //
// Tasks: 1) Fetches Roles from db                                      //
//        2) API call to save user in database.                         //
// Author: Yash P Shah                                                  //
//----------------------------------------------------------------------//

import Config from "../../src/configuration/config";
import {
    focusFirstElement,
    customPrint,
    getHeaders,
    isFormValid, getUserRole,
    simplifyModulePermissionData,
} from "./helper";
import {
    baseURL,
    PRINT_CATEGORIES,
    EMAIL_REGEX
} from "../../src/configuration/constants";

import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import { Header,Message, Grid} from "semantic-ui-react";
    
class NotAuthorised extends Component {
    is_mounted = false;

    componentWillUnmount() {
        this.is_mounted = false;
    }

    // STATE INFO
    // default role_id -> 3 (viewer)
    getInitState = () => {
        return {
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            is_message_visible: true,
            response_message: "",
            is_page_loading: true,
            createuserpermission: 0,
            createuser: 0,
            disabledPermission: "",
            full_name: "",
            username: "",
            password: "",
            role_id: 3,

            form_errors: {
                full_name: "",
                username: "",
                password: ""
            },

            role_options: [
                {
                    key: 1,
                    text: "Admin",
                    value: 1,
                    icon: "user secret"
                },
                {
                    key: 2,
                    text: "Manager",
                    value: 2,
                    icon: "user circle"
                },
                {
                    key: 3,
                    text: "Technician",
                    value: 3,
                    icon: "user md"
                }
            ]
        };
    };

    state = this.getInitState();

    componentDidMount() {
        document.title = Config.users.title.create;
        let loginSucess = localStorage.getItem('login_success');
        if (loginSucess == 1) {
            this.getUserPermission();
        }
        focusFirstElement();

        this.is_mounted = true;

        // API call to fetch the roles
        customPrint(
            Config.users.section_title.create,
            PRINT_CATEGORIES.REQUEST,
            baseURL + "/users/roleOptions"
        );

        axios
            .get(baseURL + "/users/roleOptions", getHeaders())
            .then(response => {
                customPrint(
                    Config.users.section_title.create,
                    PRINT_CATEGORIES.RESPONSE,
                    response
                );

                //fetches new roles apart from Admin, Op, Viewer
                let new_roles = response.data.filter(role => {
                    return (
                        role.name.toUpperCase() !== "ADMIN" &&
                        role.name.toUpperCase() !== "MANAGER" &&
                        role.name.toUpperCase() !== "TECHNICIAN"
                    );
                });

                //pushes new_role-object in new_role_options[]
                let new_role_options = this.state.role_options;
                let i = 3;
                new_roles.map(new_role => {
                    i++;
                    new_role_options.push({
                        key: new_role.id,
                        value: i,
                        icon: "user",
                        text: new_role.name
                            .charAt(0)
                            .toUpperCase()
                            .concat(new_role.name.substr(1))
                    });
                    return new_role;
                });

                if (this.is_mounted) {
                    this.setState({
                        role_options: new_role_options,
                        is_page_loading: false
                    });
                }
            })
            .catch(error => {
                // alert("Fetching Roles | Error: " + error);
                if (this.is_mounted) {
                    this.setState({ is_page_loading: false });
                }
            });
    }

    getUserPermission = () => {

        let Role = getUserRole();
        let login = localStorage.getItem('login_success');


        if (login == 1) {
            axios
                .get(baseURL + "/users/PermissionDetails", getHeaders())
                .then(response => {
                    let permissionData = null;

                    permissionData = simplifyModulePermissionData(response.data);
                    permissionData = permissionData.filter(data => {
                        if (data.role_name === Role) {
                            return data;
                        }

                    })


                    let createuser = 0;

                    let disabledPermission
                    let createuserpermission = permissionData.forEach(result => {


                        if (result.module_name == "Create user" && result.edit_permission === 1) {
                            createuser = 1;
                            disabledPermission = false;
                        }

                    })


                    if (createuser == 0) {
                        createuserpermission = 0;
                        disabledPermission = true;
                    }
                    else {

                        createuserpermission = 1;
                    }

                    this.setState({
                        createuserpermission,
                        createuser,
                        disabledPermission,
                    });


                })
                .catch(error => {
                    // alert("Fetching Display Grid | Error: ", error);
                });
        }
    }
    //handles changes by user to input field or selection on role
    onInputChange = (e, { name, value }) => {
        let form_errors = { ...this.state.form_errors };

        switch (name) {
            case "full_name":
                form_errors.full_name =
                    value.length < 3 ? "Minimum 3 characaters required" : "";
                break;
            case "username":
                form_errors.username = EMAIL_REGEX.test(value)
                    ? ""
                    : "Invalid email address used for username";
                break;
            case "password":
                form_errors.password =
                    value.length < 6 ? "Minimum 6 characaters required" : "";
                break;

            default:
                break;
        }

        this.setState({ form_errors, [name]: value });
    };

    //handles click on input button
    onSubmitClick = event => {
        if (isFormValid(this.state)) {
            this.setState({
                is_saving_successful: false,
                is_saving_failed: false
            });

            let res = _.pick(this.state, [
                "username",
                "role_id",
                "password",
                "full_name"
            ]);

            this.setState({ is_waiting_for_response: true });

            // API call to save the user data
            customPrint(
                Config.users.section_title.create,
                PRINT_CATEGORIES.REQUEST,
                baseURL + "/users",
                res
            );

            axios
                .post(baseURL + "/users", res, getHeaders())
                .then(response => {
                    customPrint(
                        Config.users.section_title.create,
                        PRINT_CATEGORIES.RESPONSE,
                        response
                    );

                    this.setState({
                        is_saving_successful: response.data.success,
                        is_saving_failed: !response.data.success,
                        response_message: response.data.message,
                        is_waiting_for_response: false,
                        is_message_visible: true,
                        full_name: "",
                        username: "",
                        password: "",
                        role_id: 3
                    });

                    focusFirstElement();
                })
                .catch(error => {
                    alert("Saving User | Error: " + error);
                    this.setState({ is_waiting_for_response: false });
                });
        } else {
            alert(
                "Please remove the errors shown in red font prior to submiting the form."
            );
        }
    };

    // handles cancel click
    onCancelClick = () => {
        let init_state = this.getInitState();
        init_state["is_page_loading"] = false;
        init_state["role_options"] = this.state.role_options;

        this.setState(init_state);
    };

    //hides the SUCCESS/FAILURE message
    hideMessage = () => {
        this.setState({ is_message_visible: false });
    };

    render() {
        return (
            <React.Fragment>
                <Grid>
                    <Grid.Row>
                        
                        <Grid.Column style={{ width: "100%", marginTop :"50px"}} >
                            <Message>
                                <Header as="h2" textAlign="center" margin-top="40px">
                                   "You Have no permission to access this page"
                                </Header>
                            </Message>
                        </Grid.Column>
                        
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        )
      
    }
}

export default NotAuthorised;
