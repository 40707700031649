import React, { Component } from "react";
import { Form, Popup, Input } from "semantic-ui-react";
import { Link } from 'react-router-dom'
import axios from "axios";
import { baseURL, PRINT_CATEGORIES } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class MmrApproval extends Component {

  componentDidMount(){
    this.getCurrentStep('mmr_approval');
  }

  getCurrentStep=(step_name)=>{
    let req = {
      formula_id : this.props.selected_row_value.formula_id,
      step_name : step_name
    }
    axios.post(baseURL+"/get-current-step",req, getHeaders())
      .then(res=>{
        let result = res.data; 
        if(result.success === true && result.current_step !== null){
          let current_step = result.current_step;
          current_step.step_json = JSON.parse(current_step.step_json);             
          this.setState({step_current : current_step});
          this.onInputChange(null,{name:"pin","value":current_step.performed_by});          
        }
    })
  }
   
  state = { pin: "",user:"" };
  onInputChange = (e, { name, value }) => { 
            
      this.setState({ [name]: value });
      if (!value) {
        toast.error("Please fill pin number.");
        return;
       }   
      axios.post(baseURL+"/users/getUserByPin",{pin:value}, getHeaders())
      .then(res=>{               
        if(res.data.success === true && res.data.user !== null){
            let user = res.data.user;
            this.setState({user:user}); 
            this.props.onNextStep();
          //this.setState({user_full_name:user.full_name}); 
        }
      })
  };

  handleSubmit = () => {
    
      const { pin } = this.state;
      if (!pin) {
          toast.error("Please fill pin.");
          return;
      }else if(Object.keys(this.state.user).length == 0){
        toast.error("Please fill vaild pin.");
        return;
      }
      
      let param = {
        sample_id : this.props.selected_row_value.sample_id,
        formula_id : this.props.selected_row_value.formula_id,
        step:1,
        step_json:"",
        step_name:'mmr_approval',
        step_next:'operator_sign_in',
        performed_by:this.state.user.pin
      }
      axios.post(baseURL+"/mmr-formulations",param, getHeaders())
    .then(res=>{               
      let result = res.data;                   
      if(result.success === true){
          toast.success("Successfully saved Step");
          this.props.onNextStep();
      }else{
        toast.error("Step has not saved");
      }
    })
    
    this.setState({ pin: pin});      
  };

  handlePauseClick = ()=>{
    this.props.onNextClick('pause');
  }
  handleQuitClick = ()=>{
    this.props.onNextClick('quit');
  }


  handleNextClick = () => {     
    // Call the function passed from the parent
    this.props.onNextClick('operator_sign_in');
  };
  

  render() {
    console.log(this.props.selected_row_value);
    const { pin } = this.state
    return (
     <>
        <Form onSubmit={this.handleSubmit}>
          <div className="cs-box">
            <div className="cs-body">
              <h3>MMR Approval</h3>
              <div className='d-flex'>
                <h4 className='m-0 pe-1 w-30'>QA validation</h4>
                <ul className='cs-list mt-0 w-70 cs-border-l ps-5'>
                    <li>Before a formulation is released to the manufacturing queue QA personnel must review and approve</li>
                    <li>QA will review the following
                        <ul>
                            <li>Confirm total amounts added equate to requested formulation factor</li>
                            <li>Inventory Quantity for the lot is positive</li>
                            <li>Any admin notes</li>
                            <li>Review allergen content (PRE_096)</li>
                        </ul>
                    </li>
                    <li>QA will fill "QA Notes" with their findings and accept or reject the formulation
                        <ul>
                            <li>If rejected the formula will go back to formulations queue for re-evaluation</li>
                            <li>If accepted the formula will go to manufacturing queue for manufacturing</li>
                            <li>A log of the decision shall be recorded electronically</li>
                        </ul>
                    </li>
                    <li>The Master Manufacturing Record is generated upon approval and stored on a Sun Genomics server
                        <ul>
                            <li>The MMR content is outlined in <em>Master Manufacturing Memo</em> found on <a href="https://www.medialab.com" target="_blank">www.MediaLab.com</a></li>
                        </ul>
                    </li>
                </ul>
              </div>
            </div>
            <div className="cs-footer text-center">
              <hr />
              <div className='d-inline-flex align-items-center mb-2'>
                  <div><h4 className='m-0 pe-4'>PIN</h4></div>
                  <div className="ui input">
                  <Form.Field required fluid="true">                    
                    <Input
                      id="pin"
                      name="pin"
                      onChange={this.onInputChange}
                      value={this.state.pin}
                      placeholder="Enter Your PIN"
                      type="password"
                      className="full_opacity"
                      autoComplete="new-password"
                    />
                  </Form.Field>
                  {/* <input type='text' className='form-control'/>     */}
                </div>
              </div>
              <h4 className='mt-0 mb-2'>Performed By:&nbsp; 
                  <span className='theme-text'><u> <u>{this.state.user.full_name}</u></u></span>
              </h4>
             
              <div className="text-center mt-2">
                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                { this.props.step_prev === '' && (<button type="submit" className="ui icon secondary labeled button ps-1-5em" > Next </button>)}
                { this.props.step_prev === 'mmr_approval' && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
              </div>
            </div>
          </div>
        </Form>
     </>
    );
  }
}

export default MmrApproval;
