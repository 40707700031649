import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";

import CustomLoader from "../../custom_elements/CustomLoader";
import { baseURL } from "../../../configuration/constants";
import Config from "../../../configuration/config";
import { focusFirstElement, getHeaders, getUserRole, simplifyModulePermissionData } from "../../helper";
import SupplementTable from "./CreateSupplementTable";

import { Form, Popup, Dropdown, Segment, Button, Header, Message, Input, Grid } from "semantic-ui-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class CreateSupplementFacts extends Component {
    is_mounted = false;

    componentWillUnmount() {
        this.is_mounted = false;
    }

    getInitState = () => {
        return {
            disabledPermission: "",
            SupplementFacts: "",
            SupplementForm: "",
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            is_message_visible: true,
            response_message: "",
            is_page_loading: true,
            supplement: "",
            unit: "",
            dv_for_adult: "",
            dv_for_children: "",
            dv_for_infants: "",
            supplement_type: "",
            role_options: [
                {
                    key: 1,
                    text: "no unit",
                    value: "no unit"
                },
                {
                    key: 2,
                    text: "mcg",
                    value: "mcg"
                },
                {
                    key: 3,
                    text: "mg",
                    value: "mg"
                },
                {
                    key: 3,
                    text: "g",
                    value: "g"
                },

            ],
            supplement_options: [
                {
                    key: 1,
                    text: "Food component",
                    value: "food_component"
                },
                {
                    key: 2,
                    text: "Nutrient",
                    value: "nutrient"
                },
                {
                    key: 3,
                    text: "Nutrient without RDI/DRV",
                    value: "nutrient_without_rdi_drv"
                },
            ]
        }
    };

    state = this.getInitState();

    componentDidMount = () => {
        this.is_mounted = true;
        document.title = Config.ManageSupplement.section_title_manage.create;
        this.getUserPermission();
    }
    getUserPermission = () => {
        let Role = getUserRole();
        let login = localStorage.getItem('login_success');
        if (login == 1) {
            axios
                .get(baseURL + "/users/PermissionDetails", getHeaders())
                .then(response => {
                    let permissionData = null;
                    permissionData = simplifyModulePermissionData(response.data);
                    permissionData = permissionData.filter(data => {
                        if (data.role_name === Role) {
                            return data;
                        }
                    })
                    let SupplementFacts = 0;
                    let SupplementForm = 0;
                    let disabledPermission;

                    permissionData.forEach(result => {
                        if (result.module_name === "Manage Supplement Facts" && result.delete_permission === 1) {
                            SupplementFacts = 1;
                            disabledPermission = false;
                        }
                        if (result.module_name === "Manage Supplement Facts" && result.edit_permission === 1) {
                            SupplementForm = 1;
                            disabledPermission = false;
                        }
                    })
                    if (SupplementForm == 0) {
                        SupplementForm = 0;
                        disabledPermission = true;
                    }
                    else {
                        SupplementForm = 1;
                    }
                    this.setState({
                        SupplementFacts,
                        disabledPermission,
                        SupplementForm

                    })
                })

        }
    }


    onselectChange = (event, { value }) => {

        this.setState({
            unit: value
        })
    }

    onInputChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };

    onSubmitClick = () => {
        let { supplement, dv_for_children, supplement_type, dv_for_infants, dv_for_adult, unit } = this.state;

        if (!supplement || !dv_for_children || !dv_for_infants || !dv_for_adult || !unit || supplement_type === "") {
            toast.info("Please fill all the mandatory information.");
            return;
        }
        this.setState({
            is_saving_successful: false,
            is_saving_failed: false
        });
        let res = _.pick(this.state, [
            "supplement",
            "dv_for_children",
            "dv_for_infants",
            "dv_for_adult",
            "unit",
            "supplement_type"
        ]);

        this.setState({ is_waiting_for_response: true });

        axios
            .post(baseURL + "/add-supplement", res, getHeaders())
            .then(response => {
                toast.success("Supplement has been added successfully");
                let init_state = this.getInitState();
                init_state["is_page_loading"] = false;
                init_state["is_waiting_for_response"] = false;
                this.setState(init_state);
                focusFirstElement();
                window.location.reload()
            })
            .catch(error => {
                toast.error("Invalid Data");
                this.setState({ is_page_loading: false });
            });
    }

    render() {
        const { supplement, disabledPermission, SupplementFacts, supplement_options, role_options, dv_for_children, dv_for_infants,
            dv_for_adult, is_waiting_for_response } = this.state;
        return (
            <React.Fragment>
                <Grid columns={3}>
                    <Grid.Row>
                        <Grid.Column />
                        <Grid.Column>
                            <Message style={{ marginTop: "40px" }}>
                                <Header as="h3" textAlign="center">
                                    Create Supplement Facts
                                </Header>
                            </Message>
                        </Grid.Column>
                        <Grid.Column />
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: "0px" }}>
                        <Grid.Column />
                        <Grid.Column>
                            <Segment className="transparent">
                                {is_waiting_for_response && (
                                    <CustomLoader loading_text="Saving User Data" />
                                )}
                                <Form id="createUserForm">
                                    <Form.Field required fluid="true">
                                        <Popup

                                            trigger={<label>Supplement Name</label>}
                                            content="Enter the Supplement name."
                                        />

                                        <Input
                                            id="supplement"
                                            name="supplement"
                                            onChange={this.onInputChange}
                                            value={supplement}
                                            icon="nutritionix"
                                            iconPosition="left"
                                            placeholder="Enter Supplement Name"
                                            disabled={disabledPermission}
                                        />
                                    </Form.Field>

                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>DV for adults (4 year or more)</label>}
                                            content="Enter DV for adults (4 year or more)."
                                        />

                                        <Input
                                            name="dv_for_adult"
                                            value={dv_for_adult}
                                            onChange={this.onInputChange}
                                            icon="spy"
                                            iconPosition="left"
                                            placeholder="Enter DV for Adults"
                                            type="number"
                                            disabled={disabledPermission}
                                        />
                                    </Form.Field>

                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>DV for children (from 1 to less than 4 year)</label>}
                                            content="Enter DV for children (from 1 to less than 4 year)."
                                        />
                                        <Input
                                            name="dv_for_children"
                                            onChange={this.onInputChange}
                                            value={dv_for_children}
                                            icon="child"
                                            iconPosition="left"
                                            placeholder="Enter DV for Children"
                                            type="number"
                                            disabled={disabledPermission}
                                        />
                                    </Form.Field>


                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>DV for infants (less than 1 year)</label>}
                                            content="Enter DV for infants (less than 1 year)."
                                        />
                                        <Input
                                            name="dv_for_infants"
                                            onChange={this.onInputChange}
                                            value={dv_for_infants}
                                            icon="shield"
                                            iconPosition="left"
                                            placeholder="Enter DV for infants"
                                            type="number"
                                            disabled={disabledPermission}
                                        />
                                    </Form.Field>

                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Supplement Type</label>}
                                            content="Supplement Type."
                                        />

                                        <Dropdown
                                            unit="role_id"
                                            onChange={(event, { value }) => this.setState({ supplement_type: value })}
                                            value={supplement_options.text}
                                            fluid
                                            search
                                            placeholder='Select Supplement'
                                            selection
                                            options={supplement_options}
                                            disabled={disabledPermission}
                                        />
                                    </Form.Field>

                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Unit</label>}
                                            content="Assign Unit."
                                        />

                                        <Dropdown
                                            unit="role_id"
                                            onChange={this.onselectChange}
                                            value={role_options.value}
                                            fluid
                                            search
                                            placeholder='Select Unit'
                                            selection
                                            options={role_options}
                                            disabled={disabledPermission}
                                        />
                                    </Form.Field>

                                    <br />
                                    <br />

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Grid textAlign="center">
                                                <Button.Group>
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                content="Submit"
                                                                icon="check"
                                                                labelPosition="left"
                                                                positive
                                                                onClick={this.onSubmitClick}
                                                                disabled={disabledPermission}
                                                            />
                                                        }
                                                        content="Enter the Formulation Information."
                                                    />
                                                </Button.Group>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>

                                </Form>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column />
                    </Grid.Row>
                </Grid>
                <div>
                    <SupplementTable
                        supplement={supplement}
                        disabledPermission={disabledPermission}
                        SupplementFacts={SupplementFacts}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default CreateSupplementFacts;
