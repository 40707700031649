//------------------------------------------------------------------//
// Module: Index                                                     //
// Timestamp: 04/24/2019 13:13:13                                    //
// Author: Yash P Shah                                               //
//------------------------------------------------------------------//

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //
// 1. imported classes follow PascalNaming convention.              //
// 2. variables follow linux-naming-convention.                     //
// 3. imported JSONs, methods, functions, ag-grid variables follow  //
//    camelCaseNaming convention.                                   //
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

import App from "./components/App";

import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.css";
import { BrowserRouter } from "react-router-dom";


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("container")
);
