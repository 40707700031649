//-------------------------------------------------------------------//
// Module: NavBar                                                    //
// Tasks: 1) Display Header                                          //
//        2) Uses Route from React-Router to change path             //
//        3) Handles Logout click                                    //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import CustomNavButton from "./CustomNavButton";
import { getSessionStoredJsonItem, getHeaders, simplifyModulePermissionData, getUserId } from "../helper";
import Auth from "../../authentication/Auth";

import React, { Component } from "react";
import { Container, Icon, Menu, Segment, Dropdown } from "semantic-ui-react";
import { Redirect } from "react-router";
import axios from "axios";
import { baseURL } from "../../configuration/constants";

class NavBar extends Component {
  is_mounted = false;

  state = {
    // when the user logs in, the active item is always display formulations
    active_item: "formulations",
    redirect_login: false,
    permissionData: [],
    Formulation_module_dropdown: "",
    Inventory_module_dropdown: "",
    Ai_module_dropdown: "",
    User_and_group_Management_dropdown: "",
    Admin_dropdown: "",
    formulationRationale: 0,
    allFormulations: 0,
    ManageSupplementFacts: 0,
    formulationQueue: 0,
    createRefill: 0,
    floreAiDahboard: 0,
    monthlyUsageInfo: 0,
    logsApi: 0,
    logs: 0,
    createReformulation: 0,
    floreAiSuccessMaker: 0,
    displayInventory: 0,
    createInventory: 0,
    createUser: 0,
    createGroup: 0,
    userManagement: 0,
    groupManagement: 0,
    prebioticsProbioticsManagement: 0,
    qualtiyAssurance:0,
  };

  componentDidMount() {
    this.is_mounted = true;
    let loginSucess = localStorage.getItem('login_success');
    if (loginSucess == 1) {
      this.getUserPermission();
    }

  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  onLogoutClick = () => {
    localStorage.removeItem('login_success');
    localStorage.clear();
    this.setState({ redirect_login: true });
    Auth.logout();
  };

  onItemClick = (e, { name }) => {
    this.setState({ active_item: name });
  };
  getUserPermission = () => {
    let userId = getUserId();
    let login = localStorage.getItem('login_success');
    if (login == 1) {
      axios
        .get(baseURL + "/users/PermissionBasedonRoles/" + userId, getHeaders())
        .then(response => {
          let permissionData;
          permissionData = simplifyModulePermissionData(response.data);
          permissionData = permissionData.filter(data => {
            if (data.roleId === userId) {
              return data;
            }
          })

          let formulationRationale = 0;
          let allFormulations = 0;
          let ManageSupplementFacts = 0;
          let formulationQueue = 0;
          let createRefill = 0;
          let floreAiDahboard = 0;
          let monthlyUsageInfo = 0;
          let logsApi = 0;
          let logs = 0;
          let createReformulation = 0;
          let floreAiSuccessMaker = 0;
          let displayInventory = 0;
          let createInventory = 0;
          let createUser = 0;
          let createGroup = 0;
          let userManagement = 0;
          let groupManagement = 0;
          let createFormulationAI = 0;
          let prebioticsProbioticsManagement = 0;
          let qualtiyAssurance = 0;
          let Formulation_module_dropdown = permissionData.forEach(result => {

            if (result.module_name === "Create Formulation" && (result.view_permission === 1 || result.edit_permission === 1)) {
              formulationRationale = 1;
            }
            if (result.module_name === "Quality Assurance" && (result.view_permission === 1 || result.edit_permission === 1)) {
              qualtiyAssurance = 1;
            }
            if (result.module_name === "All Formulations" && (result.view_permission === 1 || result.edit_permission === 1)) {
              allFormulations = 1;
            }
            if (result.module_name === "Manage Supplement Facts" && (result.view_permission === 1 || result.edit_permission === 1)) {
              ManageSupplementFacts = 1;
            }
            if (result.module_name === "Manufacturing Queue" && (result.view_permission === 1 || result.edit_permission === 1)) {
              formulationQueue = 1;
            }
            if (result.module_name === "Create Refill" && (result.view_permission === 1 || result.edit_permission === 1)) {
              createRefill = 1;
            }
            if (result.module_name === "Flore AI Dashboard" && (result.view_permission === 1 || result.edit_permission === 1)) {
              floreAiDahboard = 1;
            }
            if (result.module_name === "Monthly Usage Info" && (result.view_permission === 1 || result.edit_permission === 1)) {
              monthlyUsageInfo = 1;
            }
            if (result.module_name === "Flore AI Success Marker" && (result.view_permission === 1 || result.edit_permission === 1)) {
              floreAiSuccessMaker = 1;
            }
            if (result.module_name === "Logs" && (result.view_permission === 1 || result.edit_permission === 1)) {
              logs = 1;
            }
            if (result.module_name === "Logs Api" && (result.view_permission === 1 || result.edit_permission === 1)) {
              logsApi = 1;
            }
            if (result.module_name === "Create Reformulation" && (result.view_permission === 1 || result.edit_permission === 1)) {
              createReformulation = 1;
            }
          })
          if (formulationRationale == 0
            && allFormulations == 0
            && ManageSupplementFacts == 0
            && formulationQueue == 0
            && qualtiyAssurance == 0
            && createRefill == 0) {
            Formulation_module_dropdown = 0 &&
              floreAiDahboard == 0 &&
              qualtiyAssurance == 0 &&
              floreAiSuccessMaker == 0 &&
              logs == 0 &&
              logsApi == 0 &&
              createReformulation == 0
              && monthlyUsageInfo == 0;
          }
          else {
            Formulation_module_dropdown = 1;
          }

          let Inventory_module_dropdown = permissionData.forEach(details => {

            if (details.module_name === "Create Inventory" && (details.view_permission == 1 || details.editpermission == 1)) {

              createInventory = 1;
            }
            if (details.module_name === "Inventory" && (details.view_permission == 1 || details.editpermission == 1)) {

              displayInventory = 1;
            }
          })
          if (displayInventory == 0 && createInventory == 0) {
            Inventory_module_dropdown = 0;
          }
          else {
            Inventory_module_dropdown = 1;
          }

          let Ai_module_dropdown = permissionData.forEach(details => {

            if (details.module_name === "Flore AI Success Marker" && (details.view_permission == 1 || details.editpermission == 1)) {
              floreAiSuccessMaker = 1;
            }
            if (details.module_name === "Create Formulation AI" && (details.view_permission == 1 || details.editpermission == 1)) {
              createFormulationAI = 1;
            }
            if (details.module_name === "Flore Ai Dashboard" && (details.view_permission == 1 || details.editpermission == 1)) {
              floreAiDahboard = 1;
            }
          })

          if (floreAiSuccessMaker == 0 && floreAiDahboard == 0 && createFormulationAI == 0) {
            Ai_module_dropdown = 0;
          }
          else {
            Ai_module_dropdown = 1;
          }
          let User_and_group_Management_dropdown = permissionData.forEach(res => {
            if (res.module_name === "Create User" && (res.view_permission === 1 || res.edit_permission === 1)) {
              createUser = 1;
            }

            if (res.module_name === "Create Group" && (res.edit_permission === 1)) {
              createGroup = 1;
            }
            if (res.module_name === "User Management" && (res.view_permission === 1 || res.edit_permission === 1)) {
              userManagement = 1;
            }
            if (res.module_name === "Group Management" && (res.view_permission === 1 || res.edit_permission === 1)) {
              groupManagement = 1;
            }
          })

         if (createGroup == 0 && userManagement == 0 && groupManagement == 0 && createFormulationAI == 0) {
            User_and_group_Management_dropdown = 0;
          }
          else {
            User_and_group_Management_dropdown = 1;
          }

          let Admin_dropdown = permissionData.forEach(detail => {
            if (detail.module_name === "Prebiotics & Probiotics Management" && (detail.view_permission === 1 || detail.edit_permission === 1)) {
              prebioticsProbioticsManagement = 1;
            }
          })
          if (prebioticsProbioticsManagement == 0) {
            Admin_dropdown = 0;
          }
          else {
            Admin_dropdown = 1;
          }

          if (this.is_mounted) {
            permissionData = permissionData || response.data;
            this.setState({
              permissionData,
              qualtiyAssurance,
              Formulation_module_dropdown,
              Inventory_module_dropdown,
              Ai_module_dropdown,
              User_and_group_Management_dropdown,
              Admin_dropdown,
              formulationRationale,
              allFormulations,
              ManageSupplementFacts,
              formulationQueue,
              createRefill,
              floreAiDahboard,
              monthlyUsageInfo,
              floreAiSuccessMaker,
              logs,
              logsApi,
              createReformulation,
              displayInventory,
              createInventory,
              createUser,
              createGroup,
              userManagement,
              createFormulationAI,
              groupManagement,
              prebioticsProbioticsManagement,

            });
          }

        })
        .catch(error => {
          console.log("Fetching Display Grid | Error: ", error);
        });
    }
  }
  render() {

    const { active_item } = this.state;

    if (this.state.redirect_login) {
      this.state.redirect_login = false;
      return <Redirect to="/" />;
    }

    if (!Auth.isAuthenticated()) return <div />;
    return (

      <React.Fragment>
        <Segment
          inverted
          size="small"
          style={{ margin: "0px", borderRadius: 0 }}
        >
          <Container>
            <Menu inverted size="small">
              <Menu.Item name="home" active={active_item === "home"}>
                <Icon name="home" />
              </Menu.Item>

              <Menu.Menu>
                {(this.state.Formulation_module_dropdown === 1 &&

                  <Dropdown
                    className={active_item === "formulations" ? "active" : ""}
                    pointing
                    item
                    name="formulations"
                    onClick={this.onItemClick}
                    text="Formulations"
                  >

                    <Dropdown.Menu>

                      {this.state.formulationRationale === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Create Formulation"
                            icon="barcode"
                            path="/create_formulation"
                          />
                        </Dropdown.Item>
                      }

                      {this.state.createRefill === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Create Refill"
                            icon="flask"
                            path="/create_refill"
                          />
                        </Dropdown.Item>
                      }

                      {this.state.createReformulation === 1 &&
                        <Dropdown.Item>
                          <CustomNavButton
                            text="Create Reformulation"
                            icon="cogs"
                            path="/create_reformulation"
                          />
                        </Dropdown.Item>
                      }
                      
                      {this.state.qualtiyAssurance === 1 &&
                        <Dropdown.Item>
                          <CustomNavButton
                            text="Quality Assurance"
                            icon="hourglass half"
                            path="/quality_assurance"
                          />
                        </Dropdown.Item>
                      }
                    
                      {this.state.formulationQueue === 1 &&
                        <Dropdown.Item>
                          <CustomNavButton
                            text="Manufacturing Queue"
                            icon="user"
                            path="/display_formulationsOperator"
                          />
                        </Dropdown.Item>
                      }

                      {this.state.allFormulations === 1 &&

                        <Dropdown.Item >
                          <CustomNavButton
                            text="All Formulations"
                            icon="warehouse"
                            path="/new_display_formulations"
                          />
                        </Dropdown.Item>

                      }

                      {this.state.allFormulations === 1 &&

                      <Dropdown.Item >
                        <CustomNavButton
                          text="MMR & BPR"
                          icon="warehouse"
                          path="/mmr_bpr"
                        />
                      </Dropdown.Item>

                      }


                      {/* {this.state.allFormulations === 1 &&

                        <Dropdown.Item >
                          <CustomNavButton
                            text="All Formulations (old)"
                            icon="warehouse"
                            path="/display_formulations"
                          />
                        </Dropdown.Item>
                      } */}



                      {/* {is_admin && (
                      <Dropdown.Item>
                        <CustomNavButton
                          text="Display for Admin"
                          icon="user secret"
                          path="/display_formulationsAdmin"
                        />
                      </Dropdown.Item>
                    )} */}

                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {(this.state.Inventory_module_dropdown === 1 &&

                  <Dropdown
                    pointing
                    item
                    name="inventory"
                    className={active_item === "inventory" ? "active" : ""}
                    onClick={this.onItemClick}
                    text="Inventory"

                  >

                    <Dropdown.Menu>
                      {/* {this.state.createInventory === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Create Inventory (old)"
                            icon="dna"
                            path="/create_inventory"
                          />
                        </Dropdown.Item>
                      } */}


                      {this.state.createInventory === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Create Inventory"
                            icon="dna"
                            path="/create_new_inventory"
                          />
                        </Dropdown.Item>
                      }


                      {this.state.displayInventory === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Inventory"
                            icon="warehouse"
                            path="/inventory_management"
                          />
                        </Dropdown.Item>
                      }
                      {this.state.monthlyUsageInfo === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Manage Allergens"
                            icon="tint"
                            path="/manage_allergens"
                          />
                        </Dropdown.Item>}

                      {this.state.ManageSupplementFacts === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Manage Supplement Facts"
                            icon="modx"
                            path="/manage_supplement_facts"
                          />
                        </Dropdown.Item>
                      }

                      {this.state.monthlyUsageInfo === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Monthly Usage Info"
                            icon="space shuttle"
                            path="/monthly_usage_info"
                          />
                        </Dropdown.Item>
                      }


                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {/* {is_admin && ( */}
                {(this.state.Ai_module_dropdown === 1 &&
                  <Dropdown
                    pointing
                    item
                    onClick={this.onItemClick}
                    text="Flore AI">
                    <Dropdown.Menu>
                      {this.state.floreAiSuccessMaker == 1 &&
                        <Dropdown.Item>
                          <CustomNavButton
                            text="Success Marker"
                            icon="universal access"
                            path="/create_flore_ai_success_marker"
                          />
                        </Dropdown.Item>}
                      {this.state.floreAiDahboard === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Dashboard"
                            icon="dashboard"
                            path="/create_flore_ai_dashboard"
                          />
                        </Dropdown.Item>}
                      {this.state.createFormulationAI === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Formulation AI"
                            icon="barcode"
                            path="/formulation_ai"
                          />
                        </Dropdown.Item>}

                    </Dropdown.Menu>
                  </Dropdown>)}

                {(this.state.User_and_group_Management_dropdown == 1 &&

                  <Dropdown
                    pointing
                    onClick={this.onItemClick}
                    item
                    name="User & Group Management"
                    text="User & Group Management"
                    className={active_item === "users" ? "active" : ""}
                  >

                    <Dropdown.Menu>
                      {this.state.createUser === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Create User"
                            icon="user"
                            path="/Create_User"
                          />
                        </Dropdown.Item>
                      }
                      {this.state.createGroup === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Create Group"
                            icon="dna"
                            path="/create_group"
                          />
                        </Dropdown.Item>
                      }
                      {this.state.userManagement === 1 &&

                        <Dropdown.Item >
                          <CustomNavButton
                            text="User Management"
                            icon="users"
                            path="/display_users"
                          />
                        </Dropdown.Item>

                      }

                      {this.state.groupManagement === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Group Management"
                            icon="warehouse"
                            path="/Group_Management"
                          />
                        </Dropdown.Item>

                      }
                    </Dropdown.Menu>
                  </Dropdown>

                )}

                {(this.state.User_and_group_Management_dropdown === 1 &&
                  <Dropdown
                    pointing
                    onClick={this.onItemClick}
                    item
                    name="Logs"
                    text="Logs"
                    className={active_item === "users" ? "active" : ""}
                  >

                    <Dropdown.Menu>
                      {this.state.logs === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Logs"
                            icon="cog"
                            path="/logs"
                          />
                        </Dropdown.Item>
                      }
                      {this.state.logsApi === 1 &&
                        <Dropdown.Item >
                          <CustomNavButton
                            text="Logs Api"
                            icon="tablet alternate"
                            path="/get-logs-api"
                          />
                        </Dropdown.Item>
                      }
                    </Dropdown.Menu>
                  </Dropdown>

                )}
               </Menu.Menu>
              <Menu.Menu position="right">
                {sessionStorage.getItem("user") ? (
                  <Menu.Item position="right">
                    <Icon name="user" />
                    {getSessionStoredJsonItem("user").full_name}
                  </Menu.Item>
                ) : (
                  ""
                )}

                <Menu.Item
                  position="right"
                  name="logout"
                  active={active_item === "logout"}
                  onClick={this.onLogoutClick}
                >
                  <Icon name="power off" />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Container>
        </Segment>
      </React.Fragment>
    );

  }
}

export default NavBar;
