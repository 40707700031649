/* eslint-disable react/jsx-pascal-case */
import { Grid, Input, Radio } from "semantic-ui-react";
import React from 'react';
import { Custom_DropDown } from "./DropDown";
import { isAvailable_lower } from "../helper";


function MultiVAvailablity(props){
        const inventory = {
        content: {
            height: '30px',
            marginTop: '6px',
        }
    }
return (
<>
<Grid>
    <Grid.Column width={4}>
    <div>Multi-V Availability</div>
    <Radio
    toggle
    className="allergen-radio-btn"
    onClick={()=>props.muliti_v_availablity_fn('radio',props.multi_v)}
    checked={(props.multi_v == "available")?true:false}
    />
    
    </Grid.Column>
    {(props.multi_v == "available")&& (
    <>
    <Grid.Column  width={6}>
          <Custom_DropDown
                label={"Ingredient Type"}
                content={"Ingredient type."}
                onChange={(e)=>props.muliti_v_availablity_fn("ingredient_type",e)}
                isAvailable={isAvailable_lower}
                is_available={"is_available"}
                classNames={"is-available"}                
                dropDownValue={props.ingredient_type}
                inventory={inventory}
            />
    </Grid.Column>
    <Grid.Column  width={6}>
            <span>Price per gram </span>
            <Input
            name="price_per_gram"
            type="number"
            fluid
            value={props.multi_v_gram}
            placeholder="Price per gram"
            onChange={(e)=>{ props.muliti_v_availablity_fn('multi_v_gram',e.target.value) }}
        />
    </Grid.Column></>)}
    

</Grid>
    </>
    )
}
export default MultiVAvailablity 