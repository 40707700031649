//----------------------------------------------------------------------//
// Module: Inventory                                                    //
// SubModule: CreateInventory                                           //
// Tasks: 1) Receive next probable list of Organism Barcodes            //
//        2) API call to save Inventory in database.                     //
// Author: Yash P Shah                                                  //
//----------------------------------------------------------------------//

import Config from "../../../configuration/config";
import CustomSearchBar from "../../custom_elements/CustomSearchBar.jsx";
import CustomLoader from "../../custom_elements/CustomLoader";
import SuccessMessage from "../../custom_elements/SuccessMessage";
import ErrorMessage from "../../custom_elements/ErrorMessage";
import {
    focusFirstElement,
    getDateString,
    increaseDateYear,
    customPrint,
    getHeaders,
    getSessionStoredJsonItem,
    isFormValid,
    getUserRole,
    formatDate,
    simplifyModulePermissionData,
} from "../../helper";
import {
    baseURL,
    PRINT_CATEGORIES,
    ORGANISM_BARCODE_REGEX
} from "../../../configuration/constants";
import CustomDivider from "../../custom_elements/CustomDivider";

import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import {
    Form,
    Popup,
    Segment,
    Button,
    Header,
    Message,
    Input,
    Grid,
    Radio,
    Label,
    TextArea
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



class CreateInventory extends Component {
    is_mounted = false;

    componentWillUnmount() {
        this.is_mounted = false;
    }

    getInitState = () => {
        return {

            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            is_message_visible: true,
            response_message: "",
            is_page_loading: true,

            organism_barcode: "",
            barcode_digits: "",
            is_available: 0,
            concentration: 0,
            qty: 0,
            reorder_point: 0,
            scrap_factor: 0,
            createinventorypermission: 0,
            createInventory: 0,
            disabledPermission: "",
            receive_date: new Date(),
            open_date: new Date(),
            expiration_date: increaseDateYear(new Date()),
            manufacture: "",
            manufacture_lot: "",

            storage_location: "",
            shelf: 0,

            next_organism_barcodes_json: {},
            notes: "",

            form_errors: {
                organism_barcode: ""
            }
        };
    };

    state = this.getInitState();

    componentDidMount() {
        this.is_mounted = true;
        document.title = Config.inventory.title.create;

        this.getUserPermission();

        focusFirstElement();

        // API call to get the availableBarcodes.
        customPrint(
            Config.inventory.section_title.create,
            PRINT_CATEGORIES.REQUEST,
            baseURL + "/inventory/availableBarcodes"
        );

        axios
            .get(baseURL + "/inventory/availableBarcodes", getHeaders())
            .then(response => {
                customPrint(
                    Config.inventory.section_title.create,
                    PRINT_CATEGORIES.RESPONSE,
                    response
                );
                let data = response.data;
                let next_organism_barcodes_json = [];
                data.forEach(elem => {
                    next_organism_barcodes_json.push({ title: elem });
                });

                if (this.is_mounted) {
                    this.setState({
                        next_organism_barcodes_json,
                        is_page_loading: false
                    });
                }
            })
            .catch(error => {
                alert("Fetching Available Barcode | Error: ", error);
                if (this.is_mounted) {
                    this.setState({
                        is_page_loading: false
                    });
                }
            });
    }

    getUserPermission = () => {

        let Role = getUserRole();
        let login = localStorage.getItem('login_success');


        if (login === 1) {
            axios
                .get(baseURL + "/users/PermissionDetails", getHeaders())
                .then(response => {
                    let permissionData = null;

                    permissionData = simplifyModulePermissionData(response.data);
                    permissionData = permissionData.filter(data => {
                        if (data.role_name === Role) {
                            return data;
                        }

                    })


                    let createInventory = 0;

                    let disabledPermission
                    let createinventorypermission = permissionData.forEach(result => {


                        if (result.module_name === "Create Inventory" && result.edit_permission === 1) {
                            createInventory = 1;
                            disabledPermission = false;
                        }

                    })


                    if (createInventory === 0) {
                        createinventorypermission = 0;
                        disabledPermission = true;
                    }
                    else {

                        createinventorypermission = 1;
                    }

                    this.setState({
                        createinventorypermission,
                        createInventory,
                        disabledPermission,
                    });


                })
                .catch(error => {
                    alert("Fetching Display Grid | Error: ", error);
                });
        }
    }
    // Handles cancel click
    onCancelClick = () => {
        let init_state = this.getInitState();
        init_state["is_page_loading"] = false;
        init_state[
            "next_organism_barcodes_json"
        ] = this.state.next_organism_barcodes_json;

        this.setState(init_state);
    };

    // Handles submit click
    onSubmitClick = () => {
        //reset the success/fail
        if (isFormValid(this.state)) {
            this.setState({
                is_saving_successful: false,
                is_saving_failed: false
            });

            let res = _.pick(this.state, [
                "receive_date",
                "open_date",
                "expiration_date",
                "organism_barcode",
                "is_available",
                "concentration",
                "manufacture",
                "manufacture_lot",
                "qty",
                "reorder_point",
                "storage_location",
                "shelf",
                "scrap_factor",
                "notes"
            ]);

            // adding the remaning fields.
            res.user_id = getSessionStoredJsonItem("user").user_id;

            res.open_date = formatDate(this.state.open_date);
            res.expiration_date = formatDate(this.state.expiration_date);
            res.receive_date = formatDate(this.state.receive_date);

            this.setState({ is_waiting_for_response: true });

            // API call to save the user data
            customPrint(
                Config.inventory.section_title.create,
                PRINT_CATEGORIES.REQUEST,
                baseURL + "/inventory",
                res
            );

            axios
                .post(baseURL + "/inventory", res, getHeaders())
                .then(response => {
                    customPrint(
                        Config.inventory.section_title.create,
                        PRINT_CATEGORIES.RESPONSE,
                        response
                    );

                    let init_state = this.getInitState();
                    init_state[
                        "next_organism_barcodes_json"
                    ] = this.state.next_organism_barcodes_json;

                    init_state["is_saving_successful"] = response.data.success;
                    init_state["is_saving_failed"] = !response.data.success;
                    init_state["response_message"] = response.data.message;
                    init_state["is_page_loading"] = false;
                    init_state["is_waiting_for_response"] = false;
                    init_state["is_message_visible"] = true;

                    this.setState(init_state);

                    focusFirstElement();
                })
                .catch(error => {
                    alert("Saving Inventory | Error: " + error);
                    this.setState({ is_page_loading: false });
                });
        } else {
            alert(
                "Please remove the errors shown in red font prior to submiting the form."
            );
        }
    };

    // Handles  the changes by user to input field or selection on role
    onInputChange = (e, { name, value }) => {
        if (
            name === "reorder_point" ||
            name === "concentration" ||
            name === "qty" ||
            name === "scrap_factor" ||
            name === "shelf"
        )
            value = Number(value);

        if (name === "organism_barcode") {
            if (
                value.indexOf("_") !== -1 &&
                value.lastIndexOf("_") !== -1 &&
                value.indexOf("_") !== value.lastIndexOf("_")
            ) {
                let barcode_digits = value
                    .substr(value.indexOf("_") + 1, value.lastIndexOf("_"))
                    .replace("_", "");
                this.setState({ barcode_digits });
            } else {
                this.setState({ barcode_digits: "0" });
            }

            let form_errors = { ...this.state.form_errors };
            form_errors.organism_barcode = ORGANISM_BARCODE_REGEX.test(value)
                ? ""
                : 'Pattern to follow: "FOR_12345_123" or "PRO_12345_123" or "PRM_12345_123"';
            this.setState({ form_errors });
        }

        this.setState({
            [name]: value
        });
    };

    /**
     * Handles the date change
     *
     * @param {String} name
     * @param {Date} date
     */
    onDateChange = (name, date) => {
        if (name === Config.inventory.receive_date) {
            this.setState({ receive_date: date });
            if (date) {
                this.setState({ expiration_date: increaseDateYear(date) });
            }
        } else if (name === Config.inventory.open_date) {
            this.setState({ open_date: date });
        } else if (name === Config.inventory.expiration_date) {
            this.setState({ expiration_date: date });
        } else {
            alert("Some Date Error Enterred!");
        }
    };

    changeAvailablity = event => {
        this.setState({ is_available: this.state.is_available === 0 ? 1 : 0 });
    };

    //hides the SUCCESS/ FAILURE message
    hideMessage = () => {
        this.setState({ is_message_visible: false });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.is_page_loading && <CustomLoader />}
                {this.state.is_saving_successful && (
                    <SuccessMessage
                        is_message_visible={this.state.is_message_visible}
                        hideMessage={this.hideMessage}
                        module_name="Inventory"
                        message={this.state.response_message}
                    />
                )}

                {this.state.is_saving_failed && (
                    <ErrorMessage
                        is_message_visible={this.state.is_message_visible}
                        hideMessage={this.hideMessage}
                        module_name="Inventory"
                        message={this.state.response_message}
                    />
                )}

                <br />
                <Message>
                    <Header as="h2" textAlign="center">
                        {Config.inventory.section_title.create}
                    </Header>
                </Message>
                <Grid.Row />
                <Segment className="transparent">
                    {this.state.is_waiting_for_response && (
                        <CustomLoader loading_text="Saving Inventory Data" />
                    )}
                    <Form>
                        <Grid columns="equal">
                            <CustomDivider
                                h_size="h3"
                                title="Ingredient Information"
                                icon_name="dna"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Ingredient Barcode</label>}
                                            content="Enter the Ingredient Barcode."
                                        />

                                        <CustomSearchBar
                                            id="custom_search_bar"
                                            custom_value={this.state.organism_barcode}
                                            custom_data={this.state.next_organism_barcodes_json}
                                            custom_name="organism_barcode"
                                            onChange={this.onInputChange}
                                            placeholder="Enter Ingredient Barcode here."
                                            disabled={this.state.disabledPermission}
                                            className={
                                                this.state.form_errors.organism_barcode !== ""
                                                    ? "validation_error_input"
                                                    : ""
                                            }
                                        />
                                        {this.state.form_errors.organism_barcode && (
                                            <Label basic color="red" pointing>
                                                {this.state.form_errors.organism_barcode}
                                            </Label>

                                            // <span className="validation_error_message">
                                            //   {" "}
                                            //   {this.state.form_errors.organism_barcode}{" "}
                                            // </span>
                                        )}
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Barcode Digits</label>}
                                            content="Enter the Barcode Digits."
                                        />
                                        <Input
                                            disabled
                                            className="full_opacity"
                                            name="barcode_digits"
                                            onChange={this.onInputChange}
                                            value={this.state.barcode_digits}
                                            icon="hashtag"
                                            iconPosition="left"
                                            placeholder="Barcode Digits"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment>
                                        <Form.Field required fluid="true">
                                            <Popup
                                                trigger={<label>Is Available?</label>}
                                                content="Is Organism available ?"
                                            />
                                            <Radio
                                                toggle
                                                onClick={this.changeAvailablity}
                                                checked={this.state.is_available === 1}
                                                disabled={this.state.disabledPermission}
                                            />
                                        </Form.Field>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Concentration</label>}
                                            content="Enter the concentration of the Organism."
                                        />
                                        <Input
                                            name="concentration"
                                            onChange={this.onInputChange}
                                            value={this.state.concentration}
                                            placeholder="Concentnration"
                                            label={{ content: "B CFU/gm" }}
                                            labelPosition="right"
                                            disabled={this.state.disabledPermission}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity</label>}
                                            content="Enter the Organism Quantity."
                                        />
                                        <Input
                                            name="qty"
                                            onChange={this.onInputChange}
                                            value={this.state.qty}
                                            placeholder="Quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "gm" }}
                                            labelPosition="right"
                                            disabled={this.state.disabledPermission}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.onInputChange}
                                            value={this.state.reorder_point}
                                            placeholder="Reorder Point"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "gm" }}
                                            labelPosition="right"
                                            disabled={this.state.disabledPermission}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Scrap Factor</label>}
                                            content="Enter the Organism Scrap Factor."
                                        />
                                        <Input
                                            name="scrap_factor"
                                            onChange={this.onInputChange}
                                            value={this.state.scrap_factor}
                                            placeholder="Scrap Factor"
                                            label={{ content: "%" }}
                                            labelPosition="right"
                                            disabled={this.state.disabledPermission}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Popup
                                        trigger={<label>Notes</label>}
                                        content="Enter notes."
                                    />
                                    <TextArea
                                        name="notes"
                                        onChange={this.onInputChange}
                                        value={this.state.notes}
                                        placeholder="Add Notes here..."
                                        style={{ minHeight: 100 }}
                                        disabled={this.state.disabledPermission}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />

                            <CustomDivider
                                h_size="h3"
                                title="Manufacturer Information"
                                icon_name="industry"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Receieve Date</label>}
                                            content="Enter the Organism Receive Date."
                                        />
                                        <DatePicker
                                            selected={this.state.receive_date}
                                            placeholderText="Click to select a date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            disabled={this.state.disabledPermission}
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(Config.default_calendar_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.receive_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Open Date</label>}
                                            content="Enter the Organism Open Date."
                                        />

                                        <DatePicker
                                            selected={this.state.open_date}
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            disabled={this.state.disabledPermission}
                                            dropdownMode="select"
                                            openToDate={new Date(Config.default_calendar_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.open_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Expiration Date</label>}
                                            content="Enter the Organism Expiration Date."
                                        />
                                        <DatePicker
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            selected={this.state.expiration_date}
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            disabled={this.state.disabledPermission}
                                            openToDate={new Date(Config.default_calendar_date)}
                                            onChange={date => {
                                                this.onDateChange(
                                                    Config.inventory.expiration_date,
                                                    date
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer</label>}
                                            content="Enter the Manufacturer of the Organism."
                                        />
                                        <Input
                                            placeholder="Manufacturer"
                                            icon="building"
                                            iconPosition="left"
                                            name="manufacture"
                                            onChange={this.onInputChange}
                                            disabled={this.state.disabledPermission}
                                            value={this.state.manufacture}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer Lot</label>}
                                            content="Enter the Manufacturer Lot of the Organism."
                                        />
                                        <Input
                                            name="manufacture_lot"
                                            onChange={this.onInputChange}
                                            value={this.state.manufacture_lot}
                                            disabled={this.state.disabledPermission}
                                            placeholder="Manufacturer Lot"
                                            icon="hashtag"
                                            iconPosition="left"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />

                            <CustomDivider
                                h_size="h3"
                                title="Storage Information"
                                icon_name="dropbox"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Storage Location</label>}
                                            content="Enter the Organism Storage Location (Fridge Number)."
                                        />
                                        <Input
                                            placeholder="Storage Location (Fridge Number)"
                                            icon="boxes"
                                            iconPosition="left"
                                            name="storage_location"
                                            onChange={this.onInputChange}
                                            disabled={this.state.disabledPermission}
                                            value={this.state.storage_location}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Shelf</label>}
                                            content="Enter the Organism Shelf Number."
                                        />
                                        <Input
                                            placeholder="Shelf"
                                            icon="box"
                                            iconPosition="left"
                                            name="shelf"
                                            onChange={this.onInputChange}
                                            disabled={this.state.disabledPermission}
                                            value={this.state.shelf}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid textAlign="center">
                                        <Button.Group>
                                            <Popup
                                                trigger={
                                                    <Button disabled={this.state.disabledPermission} onClick={this.onSubmitClick} positive>
                                                        Submit
                                                    </Button>
                                                }
                                                content="Submit the Organism Information to the Database."
                                            />
                                            <Button.Or />
                                            <Popup
                                                trigger={
                                                    <Button disabled={this.state.disabledPermission} onClick={this.onCancelClick}>Cancel</Button>
                                                }
                                                content="Discard the Organism Informtaion."
                                            />
                                        </Button.Group>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Form>
                </Segment>
            </React.Fragment>
        );
    }
}

export default CreateInventory;
