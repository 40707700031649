

import React, { Component } from "react";
class _404 extends Component {
  state = {
    redirect_home: false
  };
  render() {
    let style = {
      width: "100%",
      height: "100%",
      left: "0px",
      textAlign: "center",
      backgroundColor: "white",
      position: "absolute"
    };
    return (
      <React.Fragment>
        <div style={style}>
          <img
            onClick={() => {
              this.setState({ redirect_home: true });
            }}
            alt="404 Page Not Found"
            src="assets/images/404.gif"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default _404;
