//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// Tasks: 1) Receive next probable list of FormulaID, SampleID       //
//        2) API call to save Formulation in database.               //
//        3) Renders FormulationInfo, Prime, Flourish, TotalInfo     //
//        4) Handles complicated logic to update fields on the go    //
//        5) Handles Organism Info change                            //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import Config from "../../configuration/config";
import {
  focusFirstElement,
  getDateString,
  customPrint,
  convertReqdInventoryOptionsJSON,
  getHeaders,
  getSessionStoredJsonItem,
  isFormValid,
  getUserRole,
  simplifyModulePermissionData,
  digitsAfterPointOrganism,
  prime_filler_amount_added,
  digitsAfterPrimeFlourish,
  Product_Type,
  validation_product_type,
  product_type_probiotics_validation,
  month_wise_validation,
  product_type_3_month,
  product_type_six_twelve_validation,
  probiotic_product_type,
  six_twelve_month_wise_validation,
  Clinical_Prebiotic
} from "../helper";
import {
  getPrimeTotalAmountAdded,
  getFormulationFactor,
  getFinalAmountAdded,
  getTotalFinalAmountAdded,
  getTotalCFU,
  getFinalConcentration,
  getScrapAmount,
  getScrap,
  getTotalFinalConcentration,
  getTotalAmountAdded,
  getFormulationFactorForClinicalFormula
} from "./Formulas";
import {
  baseURL,
  PRINT_CATEGORIES
} from "../../configuration/constants";
import TotalRefillInfo from "./TotalRefillInfo";
import FormulationInfoRefill from "./FormulationInfoRefill";
import SuccessMessage from "../custom_elements/SuccessMessage";
import ErrorMessage from "../custom_elements/ErrorMessage";
import CustomLoader from "../custom_elements/CustomLoader";
import CustomDimmer from "../custom_elements/CustomDimmer";
import Flourish from "./Flourish";
import PrimeRefill from "./PrimeRefill";

import React, { Component } from "react";
import axios from "axios";
import { Form, Segment, Header, Message, Grid } from "semantic-ui-react";
import _, { sample } from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { selectedSample, selectedFormula, sampleData, InQueueSample, selectedProduct } from "./FormulationInfoRefill"

import ShowModal from "../Pop Up/Modal";
import { toast } from "react-toastify";
import { colgroup } from "react-dom-factories";

//export {sample_Id};
let sample_Id;
let totalConcentration = 0;
let totalAmmountAdded = 0;
let totalGain = 0;
let profit = 0;
let type;

const options = [
  { value: 0 },
  { value: 1 },
  { value: "powder" }
];

const payementOptions = [
  { payment_type: "Paid" },
  { payment_type: "Free" }
];
var prime_section_arr = {};

let formulation_factor = 0;
let warning = '';
let is_popUp_disabled = false;
let popUp_description = '';
let submit_event = null;
let total_amount_added = 0;
var prime_json = [];
var selectedOrganismArray  = [];
var type_of_sample  = '';


class CreateRefill extends Component {
  is_mounted = true;

  constructor(props) {
    super(props);
    const me = this;
    me.userInputArr = []
    

  }


  assignStateValue(sampleData) {
    this.selectedOrganismArray = [];
    this.userInputArr = [];
    
    type_of_sample = "";
    warning = '';
    is_popUp_disabled = false;
    popUp_description = '';
    submit_event = null;
    total_amount_added = 0;

    this.setState({ organisms: [] });
    totalConcentration = 0;
    totalAmmountAdded = 0;
    totalGain = 0;
    formulation_factor = 0;
    let tempArray = [];

    
    sampleData.organisms.filter(data => {
      this.userInputArr.push(data.inventory.organism_barcode);
      totalConcentration = totalConcentration + data.final_concentration;
      totalAmmountAdded = totalAmmountAdded + data.amount_added;
      let obj = {};
      obj = { "cfu_total": data.amount_added * data.inventory.concentration }
      tempArray.push(obj);
        console.log(type_of_sample);
      if(!type_of_sample && data.replacement){
        type_of_sample = 'reformulation';
      }
    })
    
    const result = tempArray.reduce((acc, value) => ({
      cfu_total: parseFloat(acc.cfu_total) + parseFloat(value.cfu_total),
    }));


    totalGain = totalGain + result.cfu_total;
    var type = "";

    this.selectedOrganismArray = this.userInputArr;
    console.log(this.selectedOrganismArray);
    this.userInputArr.map((item)=>{
      var barcode = item.split("_")[0];
      if(["PRO","PRE"].includes(barcode)){ type = "pre,pro"; return;}
      if(["FOR"].includes(barcode)) { type="for"; return;} 
    })
    

    this.fetchData(type,this.userInputArr);

    this.setState({
      TotalCfu: totalGain + profit,
      TotalCfu: totalGain,
      formulation_organism: sampleData.organisms,
      organism_barcode: (sampleData && sampleData.organism_barcode ? sampleData.organism_barcode : ""),
      amount_added: (sampleData && sampleData.amount_added ? sampleData.amount_added : ""),
      concentration: (sampleData && sampleData.concentration ? sampleData.concentration : 0),
      final_concentration: (sampleData && sampleData.final_concentration ? sampleData.final_concentration : 0),
      inventory_id: (sampleData && sampleData.inventory_id ? sampleData.inventory_id : 0),

      total_amount_added: totalAmmountAdded,
      total_final_concentration: totalConcentration,
      total_final_amount_added: this.state.formulation_factor * totalAmmountAdded,
      gain: totalGain / totalAmmountAdded,
      calculationTop: totalGain,
      calculationCorrect: totalAmmountAdded,
      existingTotalAmount: totalAmmountAdded,
      existingTotalFinalAmount: this.state.formulation_factor * totalAmmountAdded,
      existingGain: totalGain,
      is_prime_added: false,
      prime_filler_inventory_id: null,
      prime_filler_scrap_amount: 0,
    });
  }

  // STATE INFO
  getInitState = () => {
    return {

      is_prime_added_disabled:false,
      is_number_of_month_disabled:false,
      prime_filler_scrap_amount: 0,
      btn_disable: true,
      is_popUp_disabled: false,
      existingGain: 0,
      existingTotalAmount: 0,
      existingTotalFinalAmount: 0,
      check_insert: 0,
      check: 0,
      formulation_check: 1,
      TotalCfu: 0,
      gain: 0,
      calculationTop: 0,
      calculationCorrect: 0,
      payment_type: "Paid",
      is_saving_successful: false,
      is_saving_failed: false,
      email_sent: 0,
      is_waiting_for_response: false,
      is_message_visible: true,
      is_page_loading: true,
      response_message: "",
      form_error: "",
      product_type: "",
      //formula info state(s)
      sample_id: "",
      formula_id: "",
      date_created: new Date(),
      lot: 0,
      formulation_factor: 1,
      number_of_months: 3,
      sgNumber: "",
      //prime
      prime_filler_inventory_id: null,
      prime_flourish_amount_added: 0,
      prime_filler_amount_added: 0,
      prime_total_amount_added: 0,
      prime_capsule_size: 0,
      is_prime_added: false,

      //flourish
      flourish_capsule_size: 0,
      admin_notes: "",
      //organisms state(s)
      organisms: [],
      inventory_id: 0,
      createRefillpermission: 0,
      createRefillFormulation: 0,
      disabledPermission: "",
      refillAmountAdded: 0,
      refillInventory_id: 0,
      refillInventory_id0: 0,
      refillInventory_id1: 0,
      refillInventory_id2: 0,
      refillInventory_id3: 0,
      refillamountAdded: 0,
      //organism state(s)

      formulation_organism: "",
      organism_barcode: "",
      product_type: "",
      amount_added: "",
      concentration: 0,
      final_concentration: 0,

      scrap_amount: 0,
      final_amount_added: 0,
      total_cfu: 0,
      is_amount_added_exceeding_qty: false,
      is_prime_filler_amount_added_exceeding_qty: false,
      is_organism_already_added: false,
      formula_type : "pro",
      //total info state(s)
      total_amount_added: 0,
      total_final_concentration: 0,
      total_final_amount_added: 0,
      inventory_options: [],
      inventory_options_prime: [],
      reqd_inventory_fields_json: [],
      converted_reqd_inventory_fields_json: [],
      inventory_options_field_prime:[],
      form_errors: {
        sample_id: "",
        formula_id: "",
        number_of_months: "",
        prime_filler_inventory_id: ""
      }
    };
  };

  state = this.getInitState();

  change = (value) => {

    var months = value === "powder" ? 3 : Number(this.state.number_of_months);

    formulation_factor = months / 3;

    this.setState({
      number_of_months: months,
      formulation_factor: months / 3,
    });

    this.setState({
      flourish_capsule_size: value
    })

    if (this.state.is_prime_added == true) {
      this.setState({
        prime_capsule_size: value
      })
    }
    else {
      this.setState({
        prime_capsule_size: ""
      })
    }


  }

  handlerProductType = (value,reformulation_request_source) => {
      var numberOfMonths = 3;
    if(value  == "Flore International (6 months)"){
      numberOfMonths = 6;
    }
    
      this.setState({
        product_type: value,
        number_of_months: numberOfMonths,
        formulation_factor: numberOfMonths/3,
      });
      
 var conditionCheck = false;
 var months_number = 0;
 
 var checkFloreBasic = value.includes('Flore Basic');
 
if(product_type_six_twelve_validation.indexOf(value)> -1){      
  months_number = probiotic_product_type.indexOf(value) !== -1 ? six_twelve_month_wise_validation.probiotics : six_twelve_month_wise_validation.prebiotics;
  conditionCheck = true;
}else if(validation_product_type.indexOf(value) !== -1 || (product_type_probiotics_validation.indexOf(value) !== -1)){

   months_number = (product_type_probiotics_validation.indexOf(value) !== -1)?
                    month_wise_validation.probiotics:
                    month_wise_validation.prebiotics;
   conditionCheck = true;  
    // 3 month pre / probiotics validation
    if(value.includes('(3 months)')){
    months_number = product_type_3_month.indexOf(value)>-1?month_wise_validation.probiotics_3:
    month_wise_validation.prebiotics_3
    }

    value.includes('Flore Clinical Upgrade (2 months) / prebiotics') && (months_number = month_wise_validation.prebiotics_3);
    value.includes('Flore Clinical Upgrade (3 months) / probiotics') && (months_number = month_wise_validation.probiotics_3);
    value.includes('Flore Clinical Upgrade (4 months) / prebiotics') && (months_number = month_wise_validation.prebiotics);
    value.includes('Flore Clinical Upgrade (5 months) / probiotics') && (months_number = month_wise_validation.probiotics);

 }

 var probiotics_condition  = value.includes("probiotics")
 
 
 if(checkFloreBasic){
  probiotics_condition = checkFloreBasic;
  months_number = 3;
  }

if(conditionCheck || checkFloreBasic){
 this.setState({
   is_prime_added:probiotics_condition,
   number_of_months:  months_number,
   is_prime_added_disabled:!probiotics_condition ,
   formulation_factor : probiotics_condition?(months_number/3).toFixed(3): (months_number*30).toFixed(3),
   flourish_capsule_size: probiotics_condition?this.state.flourish_capsule_size:"powder",
   is_number_of_month_disabled:!probiotics_condition
 })
}

 
};


  handlerPayementType = (value) => { this.setState({ payment_type: value })
  }

  handlerAssign = (sampleData) => { this.assignStateValue(sampleData) }

  changeStructureFunction = (value) => {

    if (this.state.organisms.length === 0 && this.state.calculationTop === 0) {
      this.fetchData("pre,pro,for");
    } else if (type === "PRE" || type === "PRO") { this.fetchData("pre,pro"); }
    else {
      this.fetchData("for");
    }
    this.onInputChange(value, { name: this.state.organism_barcode, value: this.state.inventory_id })
  }

  componentDidMount() {
    document.title = Config.formulations.title.createRefill;
    let loginSucess = localStorage.getItem('login_success');
    if (loginSucess == 1) {
      this.getUserPermission();
    }
    this.is_mounted = true;
    this.fetchDataPrime();
    this.fetchData("pre,pro,for");
  }

  fetchDataPrime = () => {
    let inventory_options_prime = [];

    customPrint(
      Config.formulations.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    // API call to get the inventory options
    axios
      .get(baseURL + "/inventory/inventoryOptions?type=pre,pro,prm", getHeaders())
      .then(response => {
        customPrint(
          Config.formulations.section_title.createRefill,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const reqd_inventory_fields_json = response.data;
        const converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(
          reqd_inventory_fields_json
        );

        prime_json = converted_reqd_inventory_fields_json;
        reqd_inventory_fields_json.forEach(organism => {
          inventory_options_prime.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            amount_added: organism.qty
          });
        });
        // inventory_options=BlankData.concat(inventory_options);

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options_prime,
            is_page_loading: false,

          });
        }
      })
      .catch(error => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  }

  fetchData = (type, userInputArr = []) => {
    let inventory_options = [];

    customPrint(
      Config.formulations.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    // API call to get the inventory options
    axios
      .get(baseURL + `/inventory/inventoryOptions?type=${type}`, getHeaders())
      .then(response => {
        customPrint(
          Config.formulations.section_title.createRefill,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const reqd_inventory_fields_json = response.data;
        const converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(
          reqd_inventory_fields_json
        );

        var formulation_organism = [...this.state.formulation_organism];
        if(formulation_organism.length>0){
        //  formulation_organism = formulation_organism.map((item)=>{item.background_color = ""});
         formulation_organism =  formulation_organism.map((item)=>{
          // item.background_color = ""
          reqd_inventory_fields_json.forEach(organism => {
            if(item.inventory.id == organism.id )
            {
              if(organism.qty >= item.amount_added){
                  item.background_color = "green";
              }
              else if (organism.qty < item.amount_added) 
              {
                item.background_color = "yellow"
              }
              else {
                item.background_color = "red";
                }
              }
            });
        });
      }
        reqd_inventory_fields_json.forEach(organism => {
          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            amount_added: organism.qty
          });
        });

        var options = inventory_options.map(i => i.text)
        this.userInputArr.map((data)=>{
            var index  = options.indexOf(data);
            inventory_options.splice(index,1);
            options.splice(index,1);
          })

        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
            is_page_loading: false,
          });
        }
      })
      .catch(error => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  }

  fetchDataForLength = (type) => {
    let inventory_options = [];

    customPrint(
      Config.formulations.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    // API call to get the inventory options
    axios
      .get(baseURL + `/inventory/inventoryOptions?type=${type}`, getHeaders())
      .then(response => {
        customPrint(
          Config.formulations.section_title.createRefill,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const reqd_inventory_fields_json = response.data;
        const converted_reqd_inventory_fields_json = convertReqdInventoryOptionsJSON(
          reqd_inventory_fields_json
        );
        
        // Add organisms to inventory_options.
        reqd_inventory_fields_json.forEach(organism => {

          inventory_options.push({
            key: organism.id,
            value: organism.id,
            active: true,
            text: organism.organism_barcode,
            icon: "dna",
            amount_added: organism.qty
          });
        });
        if (this.is_mounted) {
          this.setState({
            reqd_inventory_fields_json,
            converted_reqd_inventory_fields_json,
            inventory_options,
            is_page_loading: false,

          });
        }
      })
      .catch(error => {
        alert("Fetching Inventory Options | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  }

  changePrimeAddition = () => {
    let is_prime_added = this.state.is_prime_added === true ? false : true;

    // Changes the 'prime_filler_inventory_id' to null
    // so fetching the prime data, usage of prime can be detected.
    let form_errors = { ...this.state.form_errors };
    if (this.state.is_prime_added == false && this.state.prime_filler_inventory_id == null) {
      form_errors.prime_filler_inventory_id = 'Please select an organism';
      this.setState({ form_errors });
    }
    else {

      form_errors.prime_filler_inventory_id = '';
      this.setState({ form_errors });
    }

    let prime_filler_inventory_id = is_prime_added
      ? this.state.prime_filler_inventory_id
      : null;
    let prime_flourish_amount_added = is_prime_added ? 1.2 : 0;
    let prime_filler_amount_added = is_prime_added ? 4.8 : 0;
    let prime_total_amount_added = is_prime_added ? 6 : 0;
    let prime_filler_scrap_amount = 0;
    this.setState({
      is_prime_added,
      prime_filler_inventory_id,
      prime_filler_scrap_amount,
      prime_flourish_amount_added,
      prime_filler_amount_added,
      prime_total_amount_added
    });
  };

  onInputChange = (e, { name, value }) => {
    let form_errors = { ...this.state.form_errors };
    let refillInventoryChange, refillAmountAdded;

    switch (name) {
      // Validation check.
      // Changes the 'prime_total_amount_added' based on the change in 'prime_flourish_amount_added'.

      case "prime_filler_inventory_id": {
        let form_errors = { ...this.state.form_errors };

        prime_section_arr.id = value;
        form_errors.prime_filler_inventory_id = "";
        let qty = prime_json[value].qty;
        let scrap_factor = getScrap(prime_json, value);

        if (value != null) {
          var demo_qty = qty;

          let scrap_amount = (Number(getScrapAmount(scrap_factor, this.state.prime_filler_amount_added))).toFixed(2);
          let florish_section_value = 0;
          this.state.organisms &&
            this.state.organisms.map((data) => {
              florish_section_value += data.inventory_id === value ? Number(data.final_amount_added) : 0;
              florish_section_value += data.inventory_id === value ? Number(data.scrap_amount) : 0;
            });


          this.state.formulation_organism && this.state.formulation_organism.map((data) => {
              florish_section_value += data.inventory.id === value ? Number(data.final_amount_added) : 0;
              florish_section_value += data.inventory_id === value ? Number(data.scrap_amount) : 0;
            });
        
          var total_amount =
            Number(this.state.prime_filler_amount_added) +
            Number(scrap_amount) +
            Number(florish_section_value);

          if (total_amount > qty && total_amount > demo_qty) {
            demoQty = ((qty-florish_section_value)<=0)?0:qty-florish_section_value;
            scrap_amount = 0;
            qty = demoQty;
            if(demoQty >0){
            scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2);
            qty -= scrap_amount;
          }
            this.setState({
              is_prime_filler_amount_added_exceeding_qty: true,
              check: demoQty
            });
            this.setState({
              form_errors,
              prime_filler_amount_added: (qty).toFixed(2),
              prime_filler_inventory_id: value,
              prime_filler_scrap_amount: scrap_amount,
              prime_total_amount_added: this.state.prime_flourish_amount_added + qty,
            });

          }
        console.log(total_amount);
        }
        else {
          this.setState({
            form_errors,
            prime_filler_amount_added: this.state.prime_filler_amount_added,
            prime_filler_inventory_id: value,
            prime_filler_scrap_amount: (Number(getScrapAmount(scrap_factor, this.state.prime_filler_amount_added))).toFixed(2),
            prime_total_amount_added: this.state.prime_flourish_amount_added + this.state.prime_filler_amount_added,
          });

        }
        if (this.state.inventory_id !== "" && this.state.inventory_id == value) {
          this.setState({ amount_added: 0 });
        }
        this.setState({
          form_errors
        })

        break;
      }

      case "prime_flourish_amount_added": {
        digitsAfterPrimeFlourish();

        this.setState({
          prime_total_amount_added: getPrimeTotalAmountAdded(
            value,
            this.state.prime_filler_amount_added
          ),
        });
        break;
      }


      case "prime_filler_amount_added": {
        //if the prime filler organism is not added yet, return.
        prime_filler_amount_added();
        if (this.state.inventory_id !== "" && this.state.inventory_id == this.state.prime_filler_inventory_id) {
          this.setState({ amount_added: 0 });
        }
        if (this.state.prime_filler_inventory_id === null) {
          alert("Please enter Prime Filler Organism first");
          return;
        }
        if (
          prime_json[this.state.prime_filler_inventory_id] !== undefined &&
          this.state.prime_filler_inventory_id !== undefined
        ) {

          // quantity
          let qty = prime_json[this.state.prime_filler_inventory_id].qty;
          var demo_qty = qty;

          //   count scrap amount of prime section
          let scrap_factor = getScrap(prime_json, this.state.prime_filler_inventory_id);
          let scrap_amount = (Number(getScrapAmount(scrap_factor, value))).toFixed(2);


          //   add florish section value
          let florish_section_value = 0;
          this.state.organisms &&
            this.state.organisms.map((data) => {
              if(data.inventory_id === this.state.prime_filler_inventory_id){
                  florish_section_value +=  (Number(data.final_amount_added)+Number(data.scrap_amount));
              }
            });
         this.state.formulation_organism &&
            this.state.formulation_organism.map((data) => {
              if(data.inventory_id === this.state.prime_filler_inventory_id){
                florish_section_value += (Number(data.amount_added) + Number(data.scrap_amount));
              }
            });

            if (qty > 0) {
            var total_amount =
              Number(value) +
              Number(scrap_amount) +
              florish_section_value;
            console.log(total_amount);
            if (total_amount > qty && total_amount > demo_qty) {
              /*
              Note:-  when user input excided then pop show and
                          then florish amount deduct and after 
                          that find the scrap amount
                          */
              qty  = (qty- florish_section_value);
              var demoQty = qty;
              scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2);
              qty -= scrap_amount;

              this.setState({ is_prime_filler_amount_added_exceeding_qty: true, check: demoQty });
              value = (qty).toFixed(2);
            }

            if (this.state.inventory_id !== "" && this.state.inventory_id == this.state.prime_filler_inventory_id) {
              this.setState({ amount_added: 0 });
            }

            this.setState({
              prime_total_amount_added: getPrimeTotalAmountAdded(
                this.state.prime_flourish_amount_added,
                value
              ),
              prime_filler_scrap_amount: scrap_amount,
            });
          }
          else {
            this.setState({ is_prime_filler_amount_added_exceeding_qty: true, check: "0.00" });
            value = "0.00";
          }

        } else {
          alert("Error in Prime Filler Inventory id");
        }

        break;
      }
      // Handles the change in Number of Months.
      case "number_of_months": {

        if (!isNaN(value)) {
          form_errors.number_of_months =
            value > 0 ? "" : "Number of Months can not be negative";
            let formulation_factor = 0;
            if(validation_product_type.indexOf(this.state.product_type) !== -1){
              formulation_factor = getFormulationFactorForClinicalFormula(value);
            }
            else{
              formulation_factor = getFormulationFactor(value);
            }
            

          let final_amount_added = getFinalAmountAdded(
            formulation_factor,
            this.state.amount_added
          );

          let total_final_amount_added = getTotalFinalAmountAdded(
            formulation_factor,
            this.state.total_amount_added
          );

          let total_cfu = getTotalCFU(
            this.state.concentration,
            final_amount_added
          );

          // change all prev organism records:
          let organisms = [...this.state.organisms];

          organisms.forEach(organism => {
            let new_final_amount_added = getFinalAmountAdded(
              formulation_factor,
              organism.amount_added
            );

            let new_total_cfu = getTotalCFU(
              organism.concentration,
              new_final_amount_added
            );

            organism.final_amount_added = new_final_amount_added;
            organism.total_cfu = new_total_cfu;
          });

          this.setState({
            organisms,
            total_final_amount_added,
            formulation_factor,
            final_amount_added,
            total_cfu,
            form_errors
          });
        }
        break;
      }
      // Handles the change in Organism.
      case "inventory_id": {

        this.state.inventory_id = value;
        let res;
        this.state.inventory_options.map(result => {
          if (result.key == 0) {
            if (result.key === this.state.inventory_id) {

              res = 0;
            }
            else if (result.key !== this.state.inventory_id && result.key == 0 && result.key === value) {

              res = 0;
            }
            else {
              res = 1;
            }
          }
        })

        if (res !== 0) {

          let concentration = this.state.converted_reqd_inventory_fields_json[ value ].concentration;
          let scrap_factor = this.state.converted_reqd_inventory_fields_json[ value ].scrap_factor;

          let scrap_amount = getScrapAmount(
            scrap_factor,
            this.state.amount_added
          );

          let total_cfu = getTotalCFU(
            concentration,
            this.state.final_amount_added
          );

          let final_concentration = getFinalConcentration(
            concentration,
            this.state.amount_added,
            this.state.total_amount_added
          );

          let total_final_concentration = getTotalFinalConcentration(
            this.state.total_final_concentration,
            final_concentration,
            this.state.final_concentration
          );

          this.setState({
            concentration,
            scrap_amount,
            total_cfu,
            final_concentration,
            total_final_concentration
          });
        }

        break;
      }
      case "prime_filler_inventory_id":
        {
          let form_errors = { ...this.state.form_errors };
          if (value != null) {
            form_errors.prime_filler_inventory_id = '';
            this.setState({ form_errors });
          }
          break;
        }
      // Handles the change in Organism amount_added.
      case "amount_added": {
        digitsAfterPointOrganism();
        // If the organism is not added yet, returns an alert.

        if (this.state.inventory_id === 0) { alert("Please enter the Organism"); return; }

        this.setState({ Check_formula_value: value });

        let qty = this.state.converted_reqd_inventory_fields_json[this.state.inventory_id].qty;
        var demo_qty = qty;
        /* Task Improve inventory management - part 2 Start */

        let prime_Section_value = 0;
        let prime_section_scrap_amount = 0;

        if (
          (Object.keys(prime_section_arr).length !== 0 &&
            this.state.inventory_id === prime_section_arr["id"]) &&
          this.state.is_prime_added
        ) {
          prime_Section_value = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_amount_added) : 0;

          prime_section_scrap_amount = this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;

          let x = value;
          if (x + Number(prime_Section_value) + Number(prime_section_scrap_amount) < qty) {
            qty = qty - (Number(prime_Section_value) + Number(prime_section_scrap_amount)).toFixed(2);

          } else {
            qty -= ((Number(prime_Section_value) + Number(prime_section_scrap_amount))).toFixed(2);
          }
        }


        prime_Section_value += prime_section_scrap_amount;
        /* Task:- Improve inventory management - part 2 End */
        var x = value * this.state.formulation_factor;
        let scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, this.state.inventory_id);
        let scrap_amount = (Number(getScrapAmount(scrap_factor, x))).toFixed(2);

        x += Number(scrap_amount) + Number(prime_Section_value);

        if (x > qty && x > demo_qty) {
          /* Note:-  when user input excided then pop show and then prime section amount deduct and after  that find the scrap amount*/
          var error_qty = 0;
          if (qty > 0) {
            error_qty = qty;
            qty = qty / this.state.formulation_factor;
            scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2);
            qty -= scrap_amount;
            scrap_amount = scrap_amount * this.state.formulation_factor;
          }

          value = (Number(qty)).toFixed(2);
          this.setState({
            is_amount_added_exceeding_qty: true,
            check: (Number(error_qty)).toFixed(2),
            check_insert: value,
          });
        }
        // final_concentration
        let final_concentration = getFinalConcentration(
          this.state.concentration,
          value,
          total_amount_added
        );

        //all dependencies get changed
        let final_amount_added = getFinalAmountAdded(
          this.state.formulation_check,
          value
        );
        this.setState({
          amount_added: value,
          scrap_amount,
          final_concentration,
          final_amount_added,
        });
        break;
      }
      default:
      //do nothing.
    } //end switch-case
    //Main Condition|

    this.setState({ [name]: value });
  };
  /**
   * Handles the outside click for the Less Quantity Alert Modal.
   */
  onClickOutside = () => {
    this.setState({
      is_amount_added_exceeding_qty: false,
      is_prime_filler_amount_added_exceeding_qty: false,
      is_organism_already_added: false
    });
  };
  getUserPermission = () => {

    let Role = getUserRole();
    let login = localStorage.getItem('login_success');


    if (login == 1) {
      axios
        .get(baseURL + "/users/PermissionDetails", getHeaders())
        .then(response => {
          let permissionData = null;

          permissionData = simplifyModulePermissionData(response.data);
          permissionData = permissionData.filter(data => {
            if (data.role_name === Role) {
              return data;
            }

          })


          let createRefillFormulation = 0;

          let disabledPermission
          let createRefillpermission = permissionData.forEach(result => {


            if (result.module_name == "Create Refill" && result.edit_permission === 1) {
              createRefillFormulation = 1;
              disabledPermission = false;
            }

          })

          if (createRefillFormulation == 0) {
            createRefillpermission = 0;
            disabledPermission = true;
          }
          else {

            createRefillpermission = 1;
          }

          this.setState({

            disabledPermission,
          });


        })
        .catch(error => {
          console.log("Fetching Display Grid | Error: ", error);
        });
    }
  }
  // Handles submit click.
  onSubmitClick = () => {

    // Validation check.
    if (isFormValid(this.state)) {

      if (this.state.sample_id == "") {
        this.state.sample_id = selectedSample ? selectedSample : this.state.sample_id;
      }
      if (this.state.formula_id == "") {
        this.state.formula_id = selectedFormula ? selectedFormula : this.state.formula_id;
      }
      //check before sumitting irf inventory has that taht quantitiy or not
      let dbAmount;
      this.state.inventory_options.map(result => {
        if (result.key === this.state.inventory_id) {
          if (result.amount_added == null) {
            dbAmount = 0;
          }
          else {
            dbAmount = result.amount_added;
          }
        }
      })


      if (dbAmount < this.state.amount_added) {
        alert("Not enough quantity in inventory.So please choose less than or equal to " + dbAmount);
        return false;
      }


      this.setState({is_saving_successful: false, is_saving_failed: false });

      let res = _.pick(this.state, [
        "sample_id",
        "formula_id",
        "product_type",
        "date_created",
        "number_of_months",
        "formulation_factor",
        "admin_notes",
        "prime_filler_scrap_amount",
        "prime_filler_inventory_id",
        "prime_flourish_amount_added",
        "prime_filler_amount_added",
        "prime_total_amount_added",
        "email_sent",
        "flourish_capsule_size",
        "organisms",
        "payment_type",
        "total_amount_added",
        "total_final_concentration",
        "total_final_amount_added",
        "formula_type"
      ]);
      var existingTotalAmount = this.state.existingTotalAmount;
        var organismInputArr = [];
        (new Set(this.userInputArr)).forEach((item)=>organismInputArr.push(item)); 
        

      var sample_type = 'refill';

      if((existingTotalAmount + (existingTotalAmount/2))<=this.state.total_amount_added  || (this.selectedOrganismArray.length !== organismInputArr.length)||  type_of_sample){
        sample_type = "reformulation"; 
      }
      else{
        var selectedOrganismArray =  this.selectedOrganismArray.map((item) => { var arr = item.split("_"); return arr[0]+"_"+arr[1] } );
        var userInputArr = this.userInputArr.map((item) => { var arr = item.split("_"); return arr[0]+"_"+arr[1] } );
      
      var x   = selectedOrganismArray.filter((item)=> userInputArr.indexOf(item) == -1);
      x = [...userInputArr.filter((item)=> selectedOrganismArray.indexOf(item) == -1) , ...x];
      if(x.length>0){
        sample_type = "reformulation";
      }
      }

      var result = window.confirm(sample_type);

      if (!result){ return; }

      res.sample_type = sample_type;
      res.product_type = Product_Type[res.product_type];
      res.user_id = getSessionStoredJsonItem("user").user_id;
      res.date_created = getDateString(this.state.date_created);
      res.prime_capsule_size = this.state.is_prime_added ? this.state.prime_capsule_size : ""
      res.total_final_concentration = this.state.gain;
      this.setState({ is_waiting_for_response: true });
      // API call to save the formulation data
      if (Clinical_Prebiotic.includes(res.product_type)) {
        this.setState({formula_type:"pre"});
        res.formula_type = "pre";
      }
      customPrint(
        Config.formulations.section_title.create,
        PRINT_CATEGORIES.REQUEST,
        baseURL + "/formulations/CreateRefill",
        res
      );

      if (this.state.formulation_organism.length > 0) {
        this.state.formulation_organism.forEach(organismData => {
          organismData.final_amount_added = getFinalAmountAdded(
            this.state.formulation_factor,
            organismData.amount_added
          );

          res.organisms.push({
            inventory_id: organismData.inventory_id,
            organism_barcode: organismData.inventory.organism_barcode,
            amount_added: organismData.amount_added || 0,

            concentration: organismData.inventory.concentration || 0,
            final_concentration: organismData.final_concentration || 0,
            scrap_amount: organismData.scrap_amount || 0,
            final_amount_added: organismData.final_amount_added || 0,
            total_cfu: organismData.final_amount_added * organismData.inventory.concentration
          });
        });
      }
      let return_flag = false;
      res.organisms.forEach((item) => {
        if (item.amount_added <= 0) {
          return_flag = true;
          alert(`Organisms ${item.organism_barcode} Amount Added Value Can't be blank`);
          return;
        }
      });
      if (return_flag !== false) {
        this.setState({ is_page_loading: false });
        return;
      }
      console.log(res);
      // return;
      //  Adds the new 'organism'.
      axios
        .post(baseURL + "/formulations/CreateRefill", res, getHeaders())
        .then(response => {

          customPrint(
            Config.formulations.section_title.create,
            PRINT_CATEGORIES.RESPONSE,
            response
          );

          let init_state = this.getInitState();
          init_state["inventory_options"] = this.state.inventory_options;
          init_state[
            "converted_reqd_inventory_fields_json"
          ] = this.state.converted_reqd_inventory_fields_json;
          init_state[
            "reqd_inventory_fields_json"
          ] = this.state.reqd_inventory_fields_json;

          init_state["is_saving_successful"] = response.data.success;
          init_state["is_saving_failed"] = !response.data.success;
          init_state["response_message"] = response.data.message;
          init_state["is_page_loading"] = false;
          init_state["is_waiting_for_response"] = false;
          init_state["is_message_visible"] = true;
          init_state["flourish_capsule_size"] = this.state.flourish_capsule_size;
          init_state["prime_capsule_size"] = this.state.prime_capsule_size;
          init_state["product_type"] = this.state.product_type;
          this.setState(init_state);

          focusFirstElement();
          window.scrollTo(0, 0);
          if (response.data.success === true) {
            const interval = setInterval(() => {
              window.location.reload(false);
            }
              , 2000);
          }
        })
        .catch(error => {
          alert("Create Refill | Error: " + error);
          this.setState({ is_page_loading: false });
        });

      this.setState({ is_page_loading: false });
    } else {
      alert(
        "Please remove the errors shown in red font prior to submiting the form."
      );
    }
  };

  // Handles cancel click.
  onCancelClick = () => {
    let init_state = this.getInitState();
    init_state["is_page_loading"] = false;
    init_state["inventory_options"] = this.state.inventory_options;
    init_state[
      "converted_reqd_inventory_fields_json"
    ] = this.state.converted_reqd_inventory_fields_json;
    init_state[
      "reqd_inventory_fields_json"
    ] = this.state.reqd_inventory_fields_json;

    this.setState(init_state);
  };

  // Hides the SUCCESS/FAILURE message
  hideMessage = () => {
    this.setState({ is_message_visible: false });
  };

  // Handles the date change.
  onDateChange = (name, date) => {
    if (name === Config.formulations.date_created) {
      this.setState({ date_created: date });
    } else if (name === Config.formulations.date_made) {
      this.setState({ date_made: date });
    } else {
    }
  };

  /**
   * Handles the delete for added organism.
   *
   * @param {String} organism_barcode
   */
  onDeleteOrganismClick = (organism_barcode,list_of) => {

    if (document.activeElement.tagName === "INPUT") {
      return;
    }
    var formulation_organism = [...this.state.formulation_organism];
    var organisms = [...this.state.organisms];
    this.userInputArr = this.userInputArr.filter((item)=> item !== organism_barcode);
    var type = this.userInputArr.length !==0 ? organism_barcode.split("_")[0]:"";
    this.fetchIngredientList(type);
   
    if(list_of == "Refill_organism"){
      formulation_organism = formulation_organism.filter((a) => a.inventory.organism_barcode !== organism_barcode);
    }
    else{
      organisms = organisms.filter((a) => a.organism_barcode !== organism_barcode);
    }
   
    this.calculatevalue(organisms,formulation_organism);
  
  };

  fetchIngredientList = (type)=>{
    if (this.userInputArr.length === 0) {
      this.fetchData("pre,pro,for");
    } else if (type === "FOR") {
      this.fetchData("for",this.userInputArr);
    } else {
      this.fetchData("pre,pro",this.userInputArr);
    }
  }

  /**
   * Handles addition to the organism.
   */
  onAddOrganismClick = () => {


    if (this.state.amount_added == 0 || this.state.inventory_id === 0 || this.state.amount_added === "") {
      alert("Please select both the Ingredient Barcode and the Amount added!");
      return;
    }

    let organisms = [...this.state.organisms];
    let total_final_concentration = 0;

    var total_amount_added = 0;
    var total_cfu = 0;

    // changes all of previous organisms' "final concentration"
    organisms.forEach((organism) => {
      let final_concentration = getFinalConcentration(
        organism.concentration,
        organism.amount_added,
        this.state.total_amount_added
      );

      organism.final_concentration = final_concentration;
      total_final_concentration += final_concentration;
    });
    // Adds the new 'organism'.
    organisms.push({
      inventory_id: this.state.inventory_id,
      organism_barcode: this.state.organism_barcode,
      amount_added: this.state.amount_added,
      concentration: this.state.concentration,
      final_concentration: this.state.final_concentration,
      scrap_amount: this.state.scrap_amount,
      final_amount_added:
        this.state.formulation_factor * this.state.final_amount_added,
      total_cfu:
        this.state.amount_added * this.state.concentration <= 0
          ? 0
          : this.state.amount_added * this.state.concentration,
      total_cfu_api: this.state.amount_added * this.state.concentration <= 0
        ? 0
        : this.state.amount_added * this.state.concentration,

    });

    organisms && organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    var formulation_organism = this.state.formulation_organism;

    formulation_organism && formulation_organism.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    var factor = formulation_factor !== 0 ? formulation_factor : this.state.formulation_factor;
    this.setState({
      organisms,
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added)
        ? 0
        : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
      Check_formula_value: null
    });


    // Removes the new organism from the inventory_options list.

    for (var i = 0; i < this.state.inventory_options.length; i++) {
      if (this.state.inventory_options[i]["key"] == this.state.inventory_id) {
        this.state.inventory_options[i]["active"] = false;
      }
    }

    let { organism_barcode } = this.state;
    this.userInputArr = [...this.userInputArr, organism_barcode];
    type = this.state.organism_barcode.split("_")[0];
    if (type === "FOR") {
      this.fetchData("for", this.userInputArr);
    } else if (type === "PRE" || type === "PRO") {
      this.fetchData("pre,pro", this.userInputArr);
    }

    // Resets the organism state values.
    this.setState(this.resetOrganismState());
  };

  resetOrganismState = () => {
    return {
      organism_barcode: "",
      inventory_id: 0,
      amount_added: "",
      concentration: 0,
      final_concentration: 0,
      scrap_amount: 0,
      final_amount_added: 0,
      total_cfu: 0
    };
  };

  flore_probiotics = (value) => {
    let powder = "powder"
    if(validation_product_type.indexOf(this.state.product_type) == -1){
      if (value === "powder") {
        this.setState({
          flourish_capsule_size: powder,
          prime_capsule_size: powder
        })
      }
      else {
        this.setState({
          flourish_capsule_size: 0,
          prime_capsule_size: 0
        })
      }
    }
    
  }

  // set pop_up value
  pop_up_open = () => { this.setState({ is_popUp_disabled: false }) }

  Modal_return_value = (value) => {
    if (value) {
      setTimeout(() => {

        if (total_amount_added !== Number(this.state.total_final_amount_added) && warning === "") {

          warning = "quantitiy_warning";
          popUp_description = "Warning! The Total Amount Added  for the formula you're about to create is different from what is expected! Do you still want to proceed?";

          this.setState({ is_popUp_disabled: true });
          return;
        }
        else {
          this.onSubmitClick(submit_event);
        }
        return;
      }, 1000)
    }
    else {
      warning = "";
      return;
    }
  }

  confirm_value = (event) => {
    submit_event = event;
    if (InQueueSample == selectedSample) {
      alert('Already exists in formulation queue page either take another sg number or make formulation of this sgnumber in formulation page')

      return false;
    }
    if (this.state.prime_filler_inventory_id == null && this.state.is_prime_added == true) {
      alert("Prime is added, please select a filler!")
      return false;
    }
    if (this.state.organisms.length == 0 && this.state.formulation_organism.length == 0) {
      alert("Please select at least one organism barcode from flourish section before creating formula ")
      return;
    }
    let is_valid = true;

    if (
      (this.state.prime_filler_inventory_id == null &&
        this.state.is_prime_added == true) ||
      (this.state.prime_filler_inventory_id !== null &&
        (this.state.prime_filler_amount_added === "" ||
          Number(this.state.prime_filler_amount_added) === 0))
    ) {
      let x =
        this.state.prime_filler_amount_added === "" ||
          Number(this.state.prime_filler_amount_added) === 0
          ? "Prime Filler Amount Added Can't be blank!"
          : "Prime is added, please select a filler!";
      alert(x);
      return;
    }
    if (this.state.is_prime_added == true && this.state.prime_filler_inventory_id == " ") {
      alert("please Insert Inventory id");
      return;
    }
var message = "";
    if (this.state.formulation_organism) {
      this.state.formulation_organism.forEach(details => {
          if(details.background_color == "yellow"){
            message = "One of ingredient amount is not available";
            is_valid = false;            
            return;
          }
          if(!details.background_color){
            is_valid = false;            
            message = "One of ingredient is not available now";
            return;
          }
      })
      if (!is_valid) {
        alert(message);
        return false;
      }

    }

    if (this.state.amount_added === 0 && this.state.inventory_id !== 0) {

      this.setState({ amount_added: 0 });
      alert(
        "The last Item has not been added to the Ingredients. Hence, the amount_added is set to 0. However, if you wish to add it to the Organisms, please click on insert before clicking on Submit."
      );
      return;
    }

    if(validation_product_type.indexOf(this.state.product_type) == -1
    && product_type_probiotics_validation.indexOf(this.state.product_type) == -1 ){
    
    if ([3, 6].indexOf(Number(this.state.number_of_months)) === -1) {
      popUp_description = "Warning! The number of months for the formula you're about to create is not 3 or 6 months? Do you still want to proceed?";

      this.setState({
        is_popUp_disabled: true,
        popUp_description: "this is working "
      });
      return;
    }

    else {
      if (total_amount_added !== Number(this.state.total_final_amount_added)) {
        warning = "quantitiy_warning";
        popUp_description = "Warning! The Total Amount Added  for the formula you're about to create is different from what is expected! Do you still want to proceed?";

        this.setState({
          is_popUp_disabled: true,
          popUp_description: "this is workings"
        });
        return;
      }
      else {
        this.onSubmitClick(event);
      }
    }
  }
  else{
    this.onSubmitClick(event);
  }
  }



  loader_on_select_sample = (value) => {
    this.setState({ btn_disable: value });
  }

  manual_add_organism = (value, inventory_id, barcode) => {
    if (inventory_id == undefined) {
      alert("Please enter the Organism");
      return;
    }
    
    var inventory_info = this.state.converted_reqd_inventory_fields_json[inventory_id];

    var qty = inventory_info.qty;
    let demo_qty = qty;
    let scrap_factor = inventory_info.scrap_factor;

    let prime_Section_value = 0;
    var formulation_organism  = [...this.state.formulation_organism];
    var organisms  = [...this.state.organisms];
    var organism_value = 0;
    if (
      (Object.keys(prime_section_arr).length !== 0 &&
        inventory_id === prime_section_arr["id"]) &&
      this.state.is_prime_added
    ) {
      prime_Section_value += this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_amount_added) : 0;
      prime_Section_value += this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;
    }

    if(value !== undefined && value !== 0 ){
    organisms && organisms.map((ele) => {
      if (ele.inventory_id == inventory_id) {
        ele.amount_added = value;
        ele.final_amount_added = value * this.state.formulation_factor;
        ele.final_concentration = ele.amount_added * ele.concentration;
        ele.total_cfu = value * ele.concentration;
        ele.scrap_amount =(Number(getScrapAmount(scrap_factor, ele.final_amount_added))).toFixed(2);
        organism_value += (Number(ele.final_amount_added) + Number(ele.scrap_amount))
       }
    })
    }

    let scrap_amount = 0;
    var compare_value =(Number(prime_Section_value) + organism_value);

    if (  compare_value > qty && compare_value > demo_qty) {
      qty = ((qty-prime_Section_value) / this.state.formulation_factor);
      var demoQty = qty;
      scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2)
      qty -= scrap_amount;
      scrap_amount = (Number(scrap_amount * this.state.formulation_factor)).toFixed(2)
      value = (Number(qty)).toFixed(2);
      this.setState({
        is_amount_added_exceeding_qty: true,
        check: demoQty
      })

    }
    organisms && organisms.map((ele) => {
      if (ele.inventory_id == inventory_id) {
        ele.amount_added = value;
        ele.final_amount_added = value * this.state.formulation_factor;
        ele.final_concentration = ele.amount_added * ele.concentration;
        ele.total_cfu = value * ele.concentration;
        ele.scrap_amount =(Number(getScrapAmount(scrap_factor, ele.final_amount_added))).toFixed(2);
      }
    })
    this.userInputArr = [...this.userInputArr, barcode];
    
   this.calculatevalue(organisms,formulation_organism);

  };

  
  onChangeOf_organism = (value, inventory_id, barcode,type) => {
    if (inventory_id == undefined) { alert("Please enter the Organism"); return; }
   
    var inventory_info = this.state.converted_reqd_inventory_fields_json[inventory_id];
    var qty = inventory_info.qty;
    let demo_qty = qty;
    let scrap_factor = inventory_info.scrap_factor;
    
    let prime_Section_value = 0;
    var formulation_organism  = [...this.state.formulation_organism];
        var formulation_organism_value = 0;
    if (
      (Object.keys(prime_section_arr).length !== 0 &&
        inventory_id === prime_section_arr["id"]) &&
      this.state.is_prime_added
    ) {
      prime_Section_value += this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_amount_added) : 0;
      prime_Section_value += this.state.prime_filler_amount_added !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;
    }

    if(value !== undefined && value !== 0 ){
    formulation_organism && formulation_organism.map((ele) => {
      if (ele.inventory_id == inventory_id) {
        ele.amount_added = value;
        ele.final_amount_added = value * this.state.formulation_factor;
        ele.final_concentration = ele.amount_added * ele.concentration;
        ele.total_cfu = value * ele.concentration;
        ele.scrap_amount =(Number(getScrapAmount(scrap_factor, ele.final_amount_added))).toFixed(2);
        formulation_organism_value += ele.final_amount_added;
        formulation_organism_value += Number(ele.scrap_amount);
      }
    })
    }


    var compare_value = 0;
    let scrap_amount = (Number(getScrapAmount(scrap_factor, compare_value))).toFixed(2);

    compare_value += Number(scrap_amount) + Number(prime_Section_value)+formulation_organism_value;
       if (  compare_value > qty && compare_value > demo_qty) {
      qty = ((qty-prime_Section_value) / this.state.formulation_factor);
      var demoQty = qty;
      scrap_amount = (Number(getScrapAmount(scrap_factor, qty))).toFixed(2)
      qty -= scrap_amount;
      scrap_amount = (Number(scrap_amount * this.state.formulation_factor)).toFixed(2)
      value = (Number(qty)).toFixed(2);
      this.setState({
        is_amount_added_exceeding_qty: true,
        check: demoQty
      })
      }
   

   
   formulation_organism && formulation_organism.map((ele) => {
      if (ele.inventory_id == inventory_id) {
        ele.amount_added = value;
        ele.final_amount_added = value * this.state.formulation_factor;
        ele.final_concentration = ele.amount_added * ele.inventory.concentration;
        ele.total_cfu = value * ele.inventory.concentration;
        ele.scrap_amount =(Number(getScrapAmount(scrap_factor, ele.final_amount_added))).toFixed(2);
       }
    })

    this.userInputArr = [...this.userInputArr, barcode];
    var organisms  = [...this.state.organisms];
    this.calculatevalue(organisms,formulation_organism);
   
  };
  
  calculatevalue = (organisms,formulation_organism) => {
   var total_amount_added = 0;
   var total_amount_added = 0;
    var total_cfu = 0;

    organisms.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });
    
    formulation_organism.map((items) => {
      total_amount_added += Number(items.amount_added);
      total_cfu += Number(items.total_cfu);
    });

    var factor =
      formulation_factor !== 0
        ? formulation_factor
        : this.state.formulation_factor;
    
        this.setState({
      total_final_concentration: total_cfu,
      total_amount_added: total_amount_added,
      gain: isNaN(total_cfu / total_amount_added)
        ? 0
        : total_cfu / total_amount_added,
      total_final_amount_added: factor * total_amount_added,
      formulation_factor: factor,
      formulation_organism,
      organisms
    });
  };

  isPageLoading = (value) => this.setState({ is_page_loading: value })
 
  getTagDataFromParent = (msg, value) => {
    if (!msg) {
      toast.warning("Not Available")
      return;
    }
    var flag = false;
    if (this.userInputArr.indexOf(value.organism_barcode) > -1) {
      flag = true;
    }
    if (flag) {
      toast.info("Ingredient already exist");
      return;
    }

    if (this.state.converted_reqd_inventory_fields_json[value.inventory_id] === undefined) {
      toast.info("The ingredient is not allowed to add as you already selected other type of ingredients in this formula.");
      return
    }
    var formulation_organism = [...this.state.formulation_organism];

    /* Task Improve inventory management - part 2 Start */
     let prime_Section_value = 0;
     if (
       (Object.keys(prime_section_arr).length !== 0 &&
         value.inventory_id === prime_section_arr["id"])
       && this.state.is_prime_added
     ) {
       prime_Section_value = this.state.prime_filler_amount_added !== "" ? (this.state.prime_filler_amount_added * 1) : 0;
       prime_Section_value += this.state.prime_filler_scrap_amount !== "" ? Number(this.state.prime_filler_scrap_amount) : 0;
     }
     
     /* Task:- Improve inventory management - part 2 End */
 
     let scrap_factor = getScrap(this.state.converted_reqd_inventory_fields_json, value.inventory_id);
 
     var x = value.amount_added
     let scrap_amount = getScrapAmount(scrap_factor, x);
     x += Number(scrap_amount) + Number(prime_Section_value);
     // when user qty excided to  required qty
     var qty  = this.state.converted_reqd_inventory_fields_json[value.inventory_id].qty;
  
     if (x > qty) {
      toast.error("Not enought quantity avaliable");
      return;
     }

     let selected_tags_arr = [];
     if (Object.keys(value.tags).length > 0) {
       value.tags.map((item) => {
         selected_tags_arr.push({
           label: item.tag_name,
           value: item.tag_name,
           id: item.tag_id,
         })
       })
     }
 
     let organisms = [...this.state.organisms];
     organisms.push({
       inventory_id: value.inventory_id,
       organism_barcode: value.organism_barcode,
       amount_added: value.amount_added,
       concentration: value.concentration,
       final_concentration: value.final_concentration,
       final_amount_added: this.state.formulation_factor * value.amount_added,
       total_cfu: value.amount_added * value.concentration,
       scrap_amount: (value.amount_added == 0) ? 0 : scrap_amount,
       tags: (selected_tags_arr.length > 0) ? selected_tags_arr : [],
       selected_tags: (selected_tags_arr.length > 0) ? selected_tags_arr : [],
       total_final_amount_add_calculation: value.amount_added,
       nonSelectedTag: this.state.multiSelectData,
     });
 
     this.calculatevalue(organisms,formulation_organism);
 
     toast.success("Ingredient added");
    type = value.organism_barcode.split("_")[0];
    this.userInputArr.push(value.organism_barcode);
     if (type === "FOR") {
       this.fetchData("for", this.userInputArr);
     } else if (type === "PRE" || type === "PRO") {
       this.fetchData("pre,pro", this.userInputArr);
     }
  }


  render() {

    var x = (this.state.flourish_capsule_size === "powder") ? 83 : 45
    total_amount_added = Number(this.state.formulation_factor) * Number(x);
    return (
      <React.Fragment>
        {this.state.is_page_loading && <CustomLoader />}

        <CustomDimmer
          active={this.state.is_prime_filler_amount_added_exceeding_qty}
          title="Not Enough Quantity!"
          pre_content="Organism Inventory only has ("
          content={this.state.prime_filler_amount_added}
          post_content=" g) of this Prime Filler Organism."
          onClickOutside={this.onClickOutside}
        />

        <CustomDimmer
          active={this.state.is_amount_added_exceeding_qty}
          title="Not Enough Quantity!"
          pre_content="Organism Inventory only has ("
          content={this.state.check}
          post_content=" g) of this Organism."
          onClickOutside={this.onClickOutside}
        />


        {this.state.is_popUp_disabled && (
          <ShowModal
            message={warning}
            Description={popUp_description}
            Modal_return_value={this.Modal_return_value}
            pop_up_open={this.pop_up_open}
          />
        )}



        {this.state.is_saving_successful && (
          <SuccessMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Formulations"
            message={this.state.response_message}
          />
        )}

        {this.state.is_saving_failed && (
          <ErrorMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="Formulations"
            message={this.state.response_message}
          />
        )}
        <br />
        <Message id="create_fomulation_message">
          <Header textAlign="center">
            {Config.formulations.section_title.createRefill}
          </Header>
        </Message>

        <Segment compact className="transparent">
          {this.state.is_waiting_for_response && (
            <CustomLoader loading_text="Saving Formulation Data" />
          )}
          <Form>
            <FormulationInfoRefill
              id="formulation_info_refill"
              sample_id={sample_Id}
              formula_id={this.state.formula_id}
              date_created={this.state.date_created}
              number_of_months={this.state.number_of_months}
              formulation_factor={this.state.formulation_factor}
              admin_notes={this.state.admin_notes}
              form_errors={this.state.form_errors}
              disabledPermission={this.state.disabledPermission}
              onDateChange={this.onDateChange}
              onChange={this.onInputChange}
              product_type={this.state.product_type}
              handlerProductType={this.handlerProductType}
              handlerPayementType={this.handlerPayementType}
              handlerAssign={this.handlerAssign}
              flore_probiotics={this.flore_probiotics}
              loader_on_select_sample={this.loader_on_select_sample}
              payementOptions={payementOptions}
              payment_type={this.state.payment_type}
              isPageLoading = {this.isPageLoading}
              getTagDataFromParent = {this.getTagDataFromParent}
            />

            {/* . */}
            {/* Prime */}
            {/* . */}

            <PrimeRefill
              prime_filler_inventory_id={this.state.prime_filler_inventory_id}
              prime_flourish_amount_added={
                this.state.prime_flourish_amount_added
              }
              is_prime_added_disabled = {this.state.is_prime_added_disabled}
              prime_filler_amount_added={this.state.prime_filler_amount_added}
              prime_total_amount_added={this.state.prime_total_amount_added}
              // prime_capsule_size={this.state.prime_capsule_size}
              is_prime_added={this.state.is_prime_added}
              disabledPermission={this.state.disabledPermission}
              form_errors={this.state.form_errors}
              //methods
              onChange={this.onInputChange}
              changePrimeAddition={this.changePrimeAddition}
              //json
              inventory_options={this.state.inventory_options_prime}
              selectCapsuleSizeFunction={this.change}
              prime_capsule_size={this.state.prime_capsule_size}
              options={options}
              prime_filler_scrap_amount={this.state.prime_filler_scrap_amount}
              
            />

            {/* . */}
            {/* Flourish */}
            {/* . */}
            <Flourish
              number_of_months={this.state.number_of_months}
              formulation_factor={this.state.formulation_factor}
              //organisms related params
              organisms={this.state.organisms}
              organism_barcode={this.state.organism_barcode}
              inventory_id={this.state.inventory_id}
              amount_added={this.state.amount_added}
              concentration={this.state.concentration}
              final_concentration={this.state.final_concentration}
              scrap_amount={this.state.scrap_amount}
              final_amount_added={this.state.final_amount_added}
              total_cfu={this.state.total_cfu}
              disabledPermission={this.state.disabledPermission}
              refillAmountAdded={this.state.refillAmountAdded}
              refillInventory_id={this.state.refillInventory_id}
              refillInventory_id0={this.state.refillInventory_id0}
              refillInventory_id1={this.state.refillInventory_id1}
              refillInventory_id2={this.state.refillInventory_id2}
              refillInventory_id3={this.state.refillInventory_id3}
              refillamountAdded={this.props.refillamountAdded}
              //methods
              onChange={this.onInputChange}
              onAddOrganismClick={this.onAddOrganismClick}
              onDeleteOrganismClick={this.onDeleteOrganismClick}
              manual_add_organism={this.manual_add_organism}
              //json
              inventory_options={this.state.inventory_options}
              available_inventory_json={this.state.available_inventory_json}
              formulation_organism={this.state.formulation_organism}
              sampleData={sampleData}
              selectCapsuleSizeFunction={this.change}
              flourish_capsule_size={this.state.flourish_capsule_size}
              options={options}
              changeStructureFunction={this.changeStructureFunction}
              onChangeOf_organism={this.onChangeOf_organism}
              multiSelectData={this.state.multiSelectData}
              onSelect={this.onSelect}
              multiselectRef={this.multiselectRef}
              changeTagOnRefillOrganism={this.changeTagOnRefillOrganism}
              changeTagOnManualWayOrganism={this.changeTagOnManualWayOrganism}
              is_number_of_month_disabled= {this.state.is_number_of_month_disabled}
            />
            <TotalRefillInfo
              total_amount_added={this.state.total_amount_added}
              total_final_concentration={this.state.total_final_concentration}
              total_final_amount_added={this.state.total_final_amount_added}
              disabledPermission={this.state.disabledPermission}
              gain={this.state.gain}
// 
              btn_disable={this.state.btn_disable}
              // btn_disable={false}
              //methods
              onSubmitClick={this.confirm_value}
              // onSubmitClick={this.onSubmitClick}
              onCancelClick={this.onCancelClick}
            />
            <Grid columns="equal" />
          </Form>
        </Segment>
      </React.Fragment>
    );
  }
}

export default CreateRefill;
