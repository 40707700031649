export function getFormulationFactor(number_of_months) {
  return Number((number_of_months / 3).toFixed(3));
}
export function getFormulationFactorForClinicalFormula(number_of_months) {
  return Number((number_of_months * 30).toFixed(3));
}
// export function getFormulationFactorForClinicalFormulaFor12Months(number_of_months,value) {
//   return Number((number_of_months / value).toFixed(3));
// }

export function getFinalAmountAdded(formulation_factor, amount_added) {


  return Number((formulation_factor * Number(amount_added)).toFixed(3));
}

export function getTotalCFU(concentration, final_amount_added) {
  return (concentration ===undefined || final_amount_added === undefined)
  ?0
  : Number(Number(concentration) * final_amount_added);

}

export function getFinalConcentration(
  concentration,
  amount_added,
  total_amount_added
) {
  if (total_amount_added === 0) return 0;

  return Number(
    Number(Number(concentration) * Number(amount_added)) / total_amount_added
  );
}

export function getPrimeTotalAmountAdded(
  prime_flourish_amount_added,
  prime_filler_amount_added
) {
  return Number(
    Number(prime_flourish_amount_added) + Number(prime_filler_amount_added)
  );
}

export function getTotalFinalAmountAdded(
  formulation_factor,
  total_amount_added
) {
  if(formulation_factor !== undefined || total_amount_added !== undefined)
  return Number(formulation_factor * Number(total_amount_added));


}

export function getScrapAmount(scrap_factor, amount_added) {
      if(scrap_factor !== undefined || amount_added !== undefined)
        return Number((Number(scrap_factor) * Number(amount_added)) / 100).toFixed(3);
}

export function getScrap(scrap_factor, id) {
      if(scrap_factor[id] !== undefined){
        if (scrap_factor !==undefined || id !== undefined)
        return scrap_factor[id].scrap_factor;
      }
}

export function getTotalFinalConcentration(
  old_total_final_concentration,
  new_final_concentration,
  old_final_concentration
) {
  return Number(
    Number(old_total_final_concentration) +
    Number(new_final_concentration) -
    Number(old_final_concentration)
  );
}

export function getTotalAmountAdded(
  old_total_amount_added,
  new_amount_added,
  old_amount_added
) {
  return Number(
    Number(old_total_amount_added) +
    Number(new_amount_added) -
    Number(old_amount_added)
  );
}
