/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react'
import labelImg from '../../../images/9-right-screen.jpg'
import { Form, Input, TextArea, Checkbox } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders,transformYeld,validateYield } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { loadImage } from '../../../helper'


class WeightofPrimeIngredients extends Component {
    state = {organisms:[],performed_by_pin:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:"",formErrors: {performed_by_pin: false,confirmed_by_pin:false}}
    
    componentDidMount(){
        let prime_ingredients=[]
        let select_row = this.props.selected_row_value;
        prime_ingredients.push({ingredient:"Flurish Mix",traget_amount:select_row.prime_flourish_amount_added,scrap_amount:0.04,required_yield :this.calculateRequiredYeild(select_row.prime_flourish_amount_added)})
        if(select_row.inventory_barcode.length>0){
            prime_ingredients.push({ingredient:select_row.inventory_barcode[0].organism_barcode,traget_amount:select_row.prime_filler_amount_added,scrap_amount:select_row.prime_filler_scrap_amount,required_yield: this.calculateRequiredYeild(select_row.prime_filler_amount_added)})
        }          
        
        this.setState({organisms:prime_ingredients});                 
        this.getCurrentStep('weight_of_prime_ingredients');
    }
    getCurrentStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;
            if(result.success === true && result.current_step !== null){
                let current_step = result.current_step;
                let step_json = JSON.parse(current_step.step_json);             
                this.setState({step_current : current_step});
                this.onInputChange(0)(null,{name:"performed_by_pin","value":current_step.performed_by});
                this.onInputChange(0)(null,{name:"confirmed_by_pin","value":current_step.confirmed_by});             
                this.onInputChange(0)(null,{name:"operator_notes","value":step_json.operator_notes});
                this.setState({organisms:step_json.organisms});
                 
            }else{
                
                this.state.organisms.map((org,i)=>{
                   this.state.organisms[i].yield = "";
                   this.state.organisms[i].barcode = "";
                   this.state.organisms[i].measured = "";                    
                   this.state.organisms[i].primary_operator = "";
                   this.state.organisms[i].secondary_signoff = "";                    
                   this.state.organisms[i].primary_operator_name = "";
                   this.state.organisms[i].secondary_signoff_name = "";                    
                   this.state.organisms[i].foreign_inclusion_check = "No";
                   this.state.organisms[i].formErrors = {barcode:false,measured:false,primary_operator:false,secondary_signoff:false};
                })
                this.setState({organisms:this.state.organisms});
                //this.getStep('gathering_of_flourish_ingredients');
                
            }
            
            
        })
    } 
    
    getStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;
            if(result.success === true && result.current_step !== null){
                let current_step = result.current_step;
                let step_json = JSON.parse(current_step.step_json);             
                step_json.organisms.map((org,i)=>{
                    this.state.organisms[i].barcode = org.barcode;
                })  
            }
        })
    }


    calculateRequiredYeild(traget_amount){
        return ((1-(traget_amount/(traget_amount-0.1)))*100).toFixed(2)
    }

    calculateMeasured(traget_amount,measured){
        return ((measured/traget_amount)*100).toFixed(2);
    }

    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('weight_of_mixed_flourish_ingredients');        
    };

    handleNextClick = () => {     
        // Call the function passed from the parent
        this.props.onNextClick('addition_of_prime_ingredients')        
    };
    handlePauseClick = ()=>{
        this.props.onNextClick('pause');
    }
    handleQuitClick = ()=>{
        this.props.onNextClick('quit');
    }
    onInputChange = (i)=>(e, { name, value }) => {      
        console.log(name); 
        switch (name) {         
            case "performed_by_pin":
                if (value.length >= 3) {
                    if (this.state.confirmed_by_pin !== value) {
                        this.getPerformUser(value, "performer");
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ performed_by: "", performed_by_name: "" });
                }
                break;
            case "confirmed_by_pin":
                if (value.length >= 3) {
                    if (this.state.performed_by_pin !== value) {
                        this.getPerformUser(value, "confirmer");
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ confirmed_by: "", confirmed_by_name: "" });
                }
                break;
            case "primary_operator":
                this.state.organisms[i].primary_operator = value;        
                if (value.length >= 3) {
                    if (this.state.organisms[i].secondary_signoff !== value) {
                        this.getPerformUser(value, "primary_operator", i);
                    } else {
                        toast.error("Please enter another pin");
                    }
                } else {
                    this.state.organisms[i].primary_operator_name = "";
                    this.setState({ operator_name: "" });
                }
                break;            
            case "secondary_signoff":
                this.state.organisms[i].secondary_signoff = value;        
                if (value.length >= 3) {
                    if (this.state.organisms[i].primary_operator !== value) {
                        this.getPerformUser(value, "secondary_signoff", i);
                    } else {
                        toast.error("Please enter another pin");
                    }
                } else {
                        this.state.organisms[i].secondary_signoff_name = "";
                    this.setState({ operator_name: "" });
                }
                break;
            case 'barcode':
                this.state.organisms[i].barcode = value;
                break;

            case 'measured':
                this.state.organisms[i].measured = value;
                this.state.organisms[i].yield =  this.calculateMeasured(this.state.organisms[i].traget_amount,value);
                break;
        
            default:
                break;
        }
            
      this.setState({ [name]: value });
      
    };

    onInputBlur = (i)=>(e) =>{        
        if(!validateYield(this.state.organisms[i].measured,this.state.organisms[i].traget_amount)){
            
            this.state.organisms[i].yield = "";            
            this.state.organisms[i].measured = "" ;
            this.setState({organisms: this.state.organisms})
        }

    }

    handleChange=(i)=>(e, { name, value }) => {
        console.log(name);
        console.log(value);
        this.state.organisms[i].foreign_inclusion_check = value==='No'?'Yes':'No';        
        this.setState({ [name]: value === 'No'?'Yes':'No' });
    }

    getPerformUser = (pin, user_type, index = 0) => {
        if (!pin) {
          toast.error("Please fill pin number.");
          return;
        }
        axios
          .post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
          .then((res) => {
            if (res.data.success === true ) {
                let user = res.data.user;
                switch (user_type) {
                  case "performer":
                      this.setState(prevState => ({
                          performed_by: user?user.id:"",
                          performed_by_name: user?user.full_name:"",
                          formErrors: {
                              ...prevState.formErrors,
                              performed_by_pin:user?false:true
                          }
                      }));                    
                    break;
                  case "confirmer":                
                    this.setState(prevState=>({
                      confirmed_by: user?user.id:"",
                      confirmed_by_name: user?user.full_name:"",
                      formErrors:{
                          ...prevState.formErrors,
                          performed_by_pin:user?false:true
                      }
                    }));
                    break;
                  case "primary_operator":                
                    this.state.organisms[index].primary_operator_name = user?user.full_name:"";
                    this.state.organisms[index].formErrors.primary_operator =user?false:true 
                    this.setState({ organisms: this.state.organisms });
                    break;
                  case "secondary_signoff":
                    this.state.organisms[index].secondary_signoff_name = user?user.full_name:"";
                    this.state.organisms[index].formErrors.secondary_signoff = user?false:true
                    this.setState({ organisms: this.state.organisms });
                    break;    
                  default:
                    break;
                }    
                //this.setState({user_full_name:user.full_name});
              }
          });
    };

    handleSubmit = ()=>{        
        let input_field = false;
        const organisms = []
        this.state.organisms.map((value)=>{
            if(value.measured === "" || value.primary_operator === "" || value.secondary_signoff === "" || value.primary_operator_name === "" || value.secondary_signoff_name === ""|| value.barcode === "" ){
                input_field = true;
                value.formErrors.primary_operator = value.primary_operator_name?false:true;
                value.formErrors.secondary_signoff = value.secondary_signoff_name?false:true;
                value.formErrors.measured = value.measured?false:true; 
                value.formErrors.barcode = value.barcode?false:true; 
            }
            organisms.push(value);    
        })

        this.setState((prevState) => ({            
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                performed_by_pin: !this.state.performed_by_name?true:false,
                confirmed_by_pin: !this.state.confirmed_by_name?true:false,
                organisms:organisms
            }
        }));
        
        if(input_field){
            toast.error("Please complete all required field(s) ");
            return;
        }

        if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
            toast.error("PIN is missing");
            return;
        }
        
        if ((!this.state.performed_by_name && this.state.performed_by_pin) || 
            (!this.state.confirmed_by_name && this.state.confirmed_by_pin) ) {
            toast.error("PIN is incorrect");
            return;
        }
        let step_json = {operator_notes:this.state.operator_notes}       
        step_json.organisms = this.state.organisms; 
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:13,
            step_json:JSON.stringify(step_json),
            step_name:'weight_of_prime_ingredients',
            step_next:'addition_of_prime_ingredients',
            performed_by:this.state.performed_by_pin,
            confirmed_by:this.state.confirmed_by_pin
        }       
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onNextStep();
          }else{
            toast.error("Step has not saved");
          }
        })
    }
    render() {
        console.log(this.props.selected_row_value);
        console.log(this.state);
        return (
            <>
            <Form onSubmit={this.handleSubmit}>    
                <div className='cs-box'>
                    <div className='cs-body'>
                        
                        <h3>Weighing of Prime Ingredients</h3>
                        <div className='mt-2 row'>
                            <div className='col-md-6 border-r'>
                                <ul className='cs-list'>
                                    <li>Weigh Flourish probiotics: </li>
                                    <li>Ensure hood sash is below "Sash Height" level.</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <div className='mb-2'>
                                    <img className='mxw-100' src={loadImage("step13/1.jpg")} alt='img' />
                                </div>
                            </div>
                        </div>
                        <div className='row border-b'>
                            <div className='col-md-6 border-r'>
                                <ul className='cs-list'>
                                    <li>Plug in scale, place empty weight boat on the scale, and zero the scale with empty weigh boat.</li>
                                    <li>In a clean weigh boat, pour the amount of probiotic requested in the formulation to within +/- 0.1g.</li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <div className='d-flex justify-content-between'>
                                    <div className='mb-2 mxw-50'>
                                        <img className='mxw-100' src={loadImage("step13/2.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2 mxw-50'>
                                        <img className='mxw-100' src={loadImage("step13/3.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row border-b'>
                            <div className='col-12'>
                                <ul className='cs-list'>
                                    <li>Making Prime:</li>
                                    <li>Weigh 1.2g of Flourish with 4.8g of filler as called for in recipe from <a href="https://formulation.flore.com/" target='_blank'>formulation.flore.com</a></li>
                                    <li>Combine in single weigh boat and stir with clean plastic spoon until thoroughly mixed.</li>
                                    <li>Visually inspect weight out material for possible contamination or inclusions of foreign material</li>
                                    <li>Perform primary and secondary signoffs to confirm quantities and accurate and within acceptable range</li>
                                </ul>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div className='custom-table custom-header-table weighing-ingredients-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th>Ingredient Barcode</th>
                                            <th>Target Amount(g)</th>
                                            <th>Scrap Amount(g)</th>
                                            <th>Required Yield </th>
                                            <th>Scan of Barcode</th>
                                            <th>Measured</th>
                                            <th>Yield (%)</th>
                                            <th>Foreign <br/>Inclusion Check</th>
                                            <th>Primary <br/>Operator</th>
                                            <th>Secondary <br/>Signoff</th>
                                        </tr>
                                        {
                                            this.state.organisms && this.state.organisms.map((org,i)=>{                                               
                                            return( 
                                                <tr>
                                                    <td>{org.ingredient}</td>
                                                    <td>{org.traget_amount}</td>
                                                    <td>{org.scrap_amount}</td>
                                                    <td className='text-nowrap text-green'>{org.required_yield?transformYeld(org.required_yield):org.required_yield}</td>
                                                    <td><Form.Field required fluid="true">
                                                            <Input
                                                            error={org.formErrors ? org.formErrors.barcode : false}
                                                            id={`barcode_${i}`}
                                                            name="barcode"
                                                            onChange={this.onInputChange(i)}            
                                                            value={org.barcode?org.barcode:""}  
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </td>
                                                    <td>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                            error={org.formErrors ? org.formErrors.measured : false}
                                                            id={`measured_${i}`}
                                                            name="measured"
                                                            onChange={this.onInputChange(i)}            
                                                            onBlur={this.onInputBlur(i)}            
                                                            value={org.measured?org.measured:""}  
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </td>
                                                    <td className='text-nowrap text-green'>{org.yield}</td>
                                                    <td>
                                                        {/* <Form.Field required fluid="true">                    
                                                            <Input
                                                            id={`foreign_inclusion_check_${i}`}
                                                            name="foreign_inclusion_check"
                                                            onChange={this.onInputChange(i)}      
                                                            value={org.foreign_inclusion_check?org.foreign_inclusion_check:""} 
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>  */}   

                                                        <Checkbox
                                                            toggle                                                            
                                                            id={`foreign_inclusion_check_${i}`}
                                                            name={`foreign_inclusion_check_${i}`}
                                                            value={org.foreign_inclusion_check}
                                                            checked={org.foreign_inclusion_check === 'Yes'}
                                                            onChange={this.handleChange(i)}
                                                        />                                                       
                                                    </td>
                                                    <td>
                                                        <Form.Field required fluid="true">                    
                                                            <Input
                                                            error ={org.formErrors?org.formErrors.primary_operator:false}
                                                            id={`primary_operator_${i}`}
                                                            name="primary_operator"
                                                            onChange={this.onInputChange(i)}      
                                                            value={org.primary_operator?org.primary_operator:""} 
                                                            type="password"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                        {this.state.organisms[i].primary_operator_name && (
                                                            <div className="ui input">
                                                                {
                                                                this.state.organisms[i]
                                                                    .primary_operator_name
                                                                }
                                                            </div>
                                                        )} 
                                                    </td>
                                                    <td>
                                                    <Form.Field required fluid="true">                    
                                                            <Input
                                                            error ={org.formErrors?org.formErrors.secondary_signoff:false}
                                                            id={`secondary_signoff_${i}`}
                                                            name="secondary_signoff"
                                                            onChange={this.onInputChange(i)}  
                                                            value={org.secondary_signoff?org.secondary_signoff:""}
                                                            type="password"
                                                            className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field> 
                                                        {
                                                        this.state.organisms[i].secondary_signoff_name &&(
                                                            <div className="ui input">{this.state.organisms[i].secondary_signoff_name}</div>
                                                        )  
                                                    }
                                                    </td>
                                                </tr>
                                            )})
                                        }
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0'>Operator Notes:</h4></div>
                            <div>
                                <Form.Field required fluid="true">                    
                                    <TextArea
                                    id="operator_notes"
                                    name="operator_notes"
                                    onChange={this.onInputChange(0)}
                                    value={this.state.operator_notes}                                        
                                    placeholder="Operator Notes"
                                    className="notes white-textarea text-black"
                                    />
                                </Form.Field>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0 text-center mb-1'>Statement Of Deviation Requirements:</h4></div>
                            <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                        </div>
                        <div className='text-center mt-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            error={this.state.formErrors.performed_by_pin}
                                            id="performed_by_pin"
                                            name="performed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.performed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-2 mb-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            error={this.state.formErrors.confirmed_by_pin}
                                            id="confirmed_by_pin"
                                            name="confirmed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.confirmed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                        <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                        <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                        <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                        { this.props.step_name === 'weight_of_mixed_flourish_ingredients' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                        { this.props.step_name !== "weight_of_mixed_flourish_ingredients" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}                        
                        </div>
                    </div>
                </div>
            </Form>
            </>
        )
    }
}
export default WeightofPrimeIngredients;
