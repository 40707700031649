import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import _ from "lodash";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { Message, Header, Segment, Grid, Popup, Form, Button, Input, Icon, Dropdown, Radio } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Multiselect } from 'multiselect-react-dropdown';
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import "./inventory.css";
import {
    onGridReady, getHeaders, increaseDateYear, getSessionStoredJsonItem, focusFirstElement,formatDate,
    onPageSizeChanged, resetSort, resetFilter, amountPerServing, digitsAfterPointAddIngredient, notEnterZeroBefore, checkValueExistOrNotInMultiVFun, multi_v_availablity, isAvailable_lower
} from "../../helper";
import { baseURL, portalURL, page_size_options, } from "../../../configuration/constants";
import CustomLoader from "../../custom_elements/CustomLoader";
import CustomConfirm from "../../custom_elements/CustomConfirm";
import Config from "../../../configuration/config";
import CustomDivider from "../../custom_elements/CustomDivider";
import TotalUsageInfo from "./total-usage-info";
import { Custom_DropDown, Quality_assurance_DropDown } from "../DropDown";
import MultiVAvailablity from "../multi_v_availablity";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const isAvailable = [
    { value: "All" },
    { value: "Not Available" },
    { value: "Available" },
    { value: "Quarantined" },
    { value: "Sample" }
];
const isAvailable_Value = [
    { value: "All", data: "all" },
    { value: "Not Available", data: 0 },
    { value: "Available", data: 1 },
    { value: "Quarantined", data: 2 },
    { value: "Sample", data: 3 }
];


const multi_v = isAvailable_lower;
const multi_v_css = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
}
const isAvailable_Value_lower = [
    { value: "Not Available", data: 0 },
    { value: "Available", data: 1 },
    { value: "Quarantined", data: 2 },
    { value: "Sample", data: 3 }
];

const options = [
    { value: "No", data: 0 },
    { value: "Yes", data: 1 }
];

const options_quality_assurance = [
    { data: "", value: "--Please Choose an option--" },
    { data: 0, value: "No",text:"no" },
    { data: 1, value: "Yes",text:"yes" }
];


const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
};


let api, column_api;
let cross;
let z;
let split;
let elemAmountPergram;
let elemServingSize;
let typeParams = "";
let lowerCase = "";
let pdf_name = [];
let checkboxField = [];

class InventoryManagement extends Component {
    is_mounted = false;
    instanceArr = [];
    constructor(props) {
        super(props);
        this.multiselectRef = React.createRef();
        this.state = {
            multi_v:"available",
            ingredient_type:"",
            multi_v_gram:"",
            AllergeanSubmitBtnMsg: "",
            allergeanList: [],
            showAllergeanSelectBox: false,
            is_available_option_value: "",
            avialble_inventory: "",
            days_untill_run_out: "",
            remaining_amount: "",
            deleteMsg: "",
            replacement_barcode: "",
            reform_array: [],
            replacement_barcode_id: "",
            refilCreation: [],
            downloadDocumentName: "",
            documentExistingName: [],
            fileItems: [],
            showUploadDocuments: false,
            files: [],
            attachment_arr: [],
            // is_prime_added: false,
            avg: 0,
            percentage: 0,
            mode: [],
            unit: "g",
            is_page_loading: true,
            is_page_loading_csv: false,
            showP: false,
            newScientificName: "",
            newCommonName: "",
            defaultColDef: {
                resizable: true,
                sortable: true,
                enableCellChangeFlash: true,
            },
            is_special_button_disabled: true,
            rowSelection: "multiple",
            is_confirm_open: false,
            user_id: "",
            samples: [],
            showSupplement: false,
            showStore: false,
            organism_barcode: "",
            tax_id: "",
            real_name: "",
            reorder_point: "",
            name: "",
            qty: "",
            id: "",
            empty: [],
            multiSelectData: [],
            allergeanData: [],
            allergean: [{}],
            dataDown: [],
            inventory: [],
            benefit: [],
            all: [],
            manufacture: "",
            manufacture_lot: "",
            concentration: "",
            quantity: "",
            receive_date: "",
            open_date: "",
            expiration_date: "",
            storage_location: "",
            shelf: "",
            is_available: 1,
            scrap_factor: "",
            notes: "",
            created_at: "",
            updated_at: "",
            email_sent: 1,
            email_value: "",
            serving_size: "",
            amount_per_gram: "",
            supplement_id: "",
            supplements1: [],
            supplements2: [],
            supplements: [],
            full_name: "",
            in_house_sample_taken: "",
            third_party_sample_taken: "",
            in_house_sample_amount: "",
            in_house_sample_date: "",
            third_party_sample_date: "",
            quarantine_release_date: "",
            disposal_date: "",
            in_house_testing_complete: 0,
            third_party_testing_complete: 0,
            deviation_form_complete: 0,
            columnDefs: [
                {
                    headerName: "Ingredient Barcode",
                    field: "organism_barcode",
                    filter: "agTextColumnFilter",
                    width: 180,
                    cellRenderer: (params) => {
                        return (
                            `
                            <div>
                            ${params.value}
                            </div>
                            `
                        )
                    }
                },
                {
                    headerName: "Manufacturer",
                    field: "manufacture",
                    filter: "agTextColumnFilter",
                    width: 160,
                },
                {
                    headerName: "Display Name",
                    field: "real_name",
                    filter: "agTextColumnFilter",
                    width: 300,
                    cellRenderer: (params) => {
                        return (params.data.organism_barcode.split("_")[0] === "PRE") ? params.data.name : params.value;
                    }
                },
                {
                    headerName: "Amount",
                    field: "qty",
                    width: 130,
                    cellRenderer: (params) => {
                        return (
                            `<div>${Math.floor(params.value * 1000) / 1000} </div>`
                        )
                    }
                },
                {
                    headerName: "Days til Run Out",
                    field: "days_untill_run_out",
                    width: 130,
                    sortable: true,
                    cellRenderer: (params) => {
                        if (params.value == Infinity) {
                            return (
                                `<div>NA</div>`
                            )
                        }
                        else {
                            return (
                                `<div>${params.value}</div>`
                            )
                        }
                    }

                },
                {
                    headerName: "Expiration Date",
                    field: "expiration_date",
                    width: 190,
                    cellRenderer: (params) => {
                        return (
                            `
                <div>
                ${params.value.split(" ")[0]}
                            </div>
                `
                        )
                    }
                }
            ],

        };
    }

    componentWillUnmount() {
        this.is_mounted = false;
    }
    componentDidMount = () => {
        this.is_mounted = true;
        document.title = Config.inventory.title.display;
        this.getSamples();
        this.fetchBenefitData()
        this.fetchAllergeanData();
    }

    reformulation = () => {
        if (split === "PRE" || split === "PRO") {
            let tempArray = [];
            let obj = {};
            axios
                .get(baseURL + "/get-all-inventory/" + this.state.id, getHeaders())
                .then(res => {
                    let data = res.data.data;
                    var result = Object.keys(data).map(function (key) {
                        return [Number(key), data[key]];
                    });
                    for (var i = 0; i < result.length; i++) {
                        obj = {
                            "key": result[i][0],
                            "value": result[i][1],
                            "active": true,
                            "icon": "dna",
                            "text": result[i][1]
                        }
                        tempArray.push(obj)
                    }
                    this.setState({
                        reform_array: tempArray
                    })
                })
        }
        else {
            this.setState({
                reform_array: []
            })
            return false;
        }

    }

    getSamples = () => {
        axios
            .get(baseURL + "/inventory/list/all", getHeaders())
            .then(res => {
                let data = res.data;
                this.setState({
                    samples: data,
                    is_page_loading: false,
                    
                })
            })

    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });

        if (name === "name" && split === "FOR" || split === "PRO" || split === "PRM") {
            document.addEventListener("DOMContentLoaded", function (event) {
                document.getElementById("commons").disabled = true;
            });
        }
    }


    onDateChange = (name, date) => {
        if (name === Config.inventory.receive_date) {
            this.setState({ receive_date: date });
            if (date) {
                this.setState({ expiration_date: increaseDateYear(date) });
            }
        } else if (name === Config.inventory.open_date) {
            this.setState({ open_date: date });
        } else if (name === Config.inventory.expiration_date) {
            this.setState({ expiration_date: date });
        }
        else {
            alert("Some Date Error Enterred!");
        }
    };

    fetchAllergeanData = () => {
        axios.get(baseURL + "/allergens", getHeaders())
            .then((result) => {
                let allergeanArray = [];
                if (result.data.data.length > 0) {
                    let x = result.data.data;
                    x && x.map((elem, index) => {
                        let obj = { label: elem.allergen, name: elem.allergen, id: elem.id }
                        allergeanArray.push(obj);
                    })

                    this.setState({ allergeanData: allergeanArray, allergeanList: [] })
                }
                
            })
            .catch((err) => {
                toast.error("Something Goes Wrong");
            })
    }

    onRowClicked = (value) => {
        console.log(value);
        checkboxField  =[];

        var querSelector = document.querySelectorAll("input[type=checkbox]");
       
        querSelector[0].checked = false;
        querSelector[1].checked = false;
        querSelector[2].checked = false;
        
        var splitValue = value.data.organism_barcode.split("_")[0];
        this.state.allergean = [];

   checkboxField = [];
    if(value.data.deviation_form_complete){
        checkboxField.push("deviation_form_complete");
        document.getElementById("deviation_form_complete").checked = true;
    }
    if(value.data.third_party_testing_complete){
        document.getElementById("third_party_testing_complete").checked = true;
        checkboxField.push("third_party_testing_complete")
        
    }
    if(value.data.in_house_testing_complete){
        document.getElementById("in_house_testing_complete").checked = true;
        checkboxField.push("in_house_testing_complete")
    }
   
    var in_house_sample_taken ;
    if(value.data.in_house_sample_taken !== "") 
    { in_house_sample_taken  = options_quality_assurance.filter((data)=>{ return data.text == value.data.in_house_sample_taken })[0].value}
    else{in_house_sample_taken  = ""}


    var third_party_sample_taken ;
    if(value.data.third_party_sample_taken !== "") 
    { third_party_sample_taken  = options_quality_assurance.filter((data)=>{ return data.text == value.data.third_party_sample_taken })[0].value}
    else{third_party_sample_taken  = ""}

        this.setState({ allergeanList: [],
            open_date:(value.data.open_date == "1969-12-31 00:00:00" || value.data.open_date == null)?"": new Date(value.data.open_date),
            receive_date:(value.data.receive_date == "1969-12-31 00:00:00" || value.data.receive_date == null)?"": new Date(value.data.receive_date),

        in_house_sample_taken: in_house_sample_taken,
        third_party_sample_taken: third_party_sample_taken,
        in_house_sample_amount:  (value.data.in_house_sample_amount == null ) ?"":value.data.in_house_sample_amount,
        third_party_sample_amount:(value.data.third_party_sample_amount == null ) ?"":value.data.third_party_sample_amount,
        in_house_sample_date: (value.data.in_house_sample_date == null || value.data.in_house_sample_date == "")?"":new Date(value.data.in_house_sample_date),
        third_party_sample_date:
             (value.data.third_party_sample_date == null || value.data.third_party_sample_date == ""
            )?"":new Date(value.data.third_party_sample_date),
        quarantine_release_date: (value.data.quarantine_release_date == null ||
            value.data.quarantine_release_date == ""
            )?"":new Date(value.data.quarantine_release_date),
        disposal_date:(value.data.disposal_date == null
            || value.data.disposal_date ==""
            )?"": new Date(value.data.disposal_date),
        in_house_testing_complete: value.data.in_house_testing_complete,
        third_party_testing_complete: value.data.third_party_testing_complete,
        deviation_form_complete: value.data.deviation_form_complete
     });
       split = value.data.organism_barcode.split('_')[0]
        let x = value.data.created_at.split(" ")[0].replace(/-/g, '/');
        let y = x.split("/");
        let created = y[1] + "/" + y[2] + "/" + y[0];
        let createdDate = created + " " + value.data.created_at.split(" ")[1];

        let a = value.data.updated_at.split(" ")[0].replace(/-/g, '/');
        let b = a.split("/")
        let upDated = b[1] + "/" + b[2] + "/" + b[0];
        let upDatedDate = upDated + " " + value.data.updated_at.split(" ")[1];
        let dev = value.data.user === null || value.data.user.full_name === "" ? "" : value.data.user.full_name;

        var is_available = isAvailable_Value.find((availble) => availble.data == value.data.is_available);
        this.setState({
            is_available: is_available.data,
            is_available_option_value: is_available.value
        });


        var is_availables = options.find((availble) => availble.data == value.data.email_sent);

        this.setState({ email_value: is_availables.value, email_sent: is_availables.data });



        this.setState({
            ingredient_type:value.data.ingredient_type,
            multi_v_gram:value.data.multi_v_gram,
            multi_v:value.data.multi_v,
            showAllergeanSelectBox: (splitValue == "PRE") ? true : false,
            replacement_barcode: value.data.replacement_barcode,
            documentExistingName:[],
            replacement_barcode_id: value.data.replacement_barcode_id,
            showP: true,
            showUploadDocuments: true,
            is_special_button_disabled: false,
            id: value.data.id,
            user_id: value.data.user_id,
            organism_barcode: value.data.organism_barcode,
            tax_id: value.data.tax_id === null || value.data.tax_id === "NA" ? "NA" : value.data.tax_id,
            real_name: value.data.real_name === null ? "" : value.data.real_name,
            name: value.data.name === null ? "" : value.data.name,
            qty: Math.floor(value.data.qty * 1000) / 1000,
            manufacture: value.data.manufacture,
            manufacture_lot: value.data.manufacture_lot,
            concentration: Math.floor(value.data.concentration * 1000) / 1000,
            notes: value.data.notes,
            reorder_point: Math.floor(value.data.reorder_point * 1000) / 1000,
            scrap_factor: Math.floor(value.data.scrap_factor * 1000) / 1000,
            storage_location: value.data.storage_location,
            shelf: value.data.shelf,
            created_at: createdDate,
            updated_at: upDatedDate,
            // open_date: OpenData,

            // receive_date: RecieveDate,
            expiration_date: new Date(value.data.expiration_date),
            full_name: dev,
            newCommonName: value.data.name === null ? "" : value.data.name,
            newScientificName: value.data.real_name === null ? "" : value.data.real_name
        })

        let typeParams = "";
        if (value.data.organism_barcode !== undefined) {
            let valueType = value.data.organism_barcode;
            typeParams = valueType.split("_", 1);

            if (typeParams == "PRM") {
                this.setState({
                    showStore: false
                })
            }
            else {
                this.setState({
                    showStore: true
                })
            }
        }
        if (typeParams == "PRE") {
            this.setState({
                showSupplement: true
            })
        }
        else {
            this.setState({
                showSupplement: false
            })
        }

        let tempArray = [];
        axios
            .get(baseURL + "/supplements/" + value.data.id, getHeaders())
            .then(response => {
                z = response.data;
                if (z[0].length > 0) {


                    z && z[0] && z[0].map((elem, index) => {
                        elemServingSize = elem.serving_size;
                        let obj = {};
                        obj = {
                            "id": elem.supplement_id,
                            "supplement": elem.supplement,
                            "quantity": elem.quantity,
                            "unit": elem.unit,
                            "amount_per_gram": elem.amount_per_gram,
                            "serving_size": elem.serving_size
                        }
                        tempArray.push(obj);
                    })
                    this.setState({
                        dataDown: tempArray,
                        supplements1: tempArray,
                        serving_size: elemServingSize
                    })
                }
            })

        this.fetchExistingBenefits()
        this.fetchExistingSupplement()
        this.fetchModeData()
        this.fetchExistingDocument()
        this.reformulation()
        this.fetchExistingAllergean();
    }
    fetchExistingDocument = () => {
        let documentArray = [];
        axios
            .get(baseURL + "/inventory/fetchDocuments/" + this.state.id, getHeaders())
            .then(res => {
                if (res.data.length === 0) {
                    this.setState({
                        documentExistingName: []
                    })
                }
                else {
                    let fetchDataDocuments = res.data;
                    fetchDataDocuments && fetchDataDocuments.map((elem, index) => {
                        let obj = {};
                        obj = {
                            "name": elem.name,
                            "inventory_id": elem.id,
                            "base64_data": elem.base64_data,
                            "type": "application/pdf",
                        }
                        documentArray.push(obj);
                        this.setState({
                            documentExistingName: documentArray
                        })
                    })
                }

            })
    }
    fetchExistingAllergean = () => {

        if (this.state.showAllergeanSelectBox) {
            let emptyArray = [];
            // this.multiselectRef.current.state.options = this.state.allergeanData;

            axios
                .get(baseURL + "/inventory_allergens/" + this.state.id, getHeaders())
                .then(response => {
                    let x = response.data.data;
                    if (x.length > 0) {

                        x && x.map((elem, index) => {
                            let obj = { label: elem.allergen.allergen, name: elem.allergen.allergen, id: elem.allergen.allergen_id }
                            emptyArray.push(obj);
                        })
                        this.onSelectAllergen(emptyArray);

                    }
                }).catch(err => toast.error("something goes wrong"))

        }
    }


    fetchExistingBenefits = () => {
        let emptyArray = [];
        axios
            .get(baseURL + "/benefits/" + this.state.id, getHeaders())
            .then(response => {
                let x = response.data.data;
                if (x.length > 0) {

                    x && x.map((elem, index) => {
                        let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id }
                        emptyArray.push(obj);
                    })
                    this.setState({
                        empty: emptyArray,
                        id: this.state.id,
                        organism_barcode: this.state.organism_barcode
                    })
                    this.onSelect(emptyArray, {})
                }
            })

    }

    fetchExistingSupplement = () => {
        axios
            .get(baseURL + "/supplements/existing/" + this.state.id, getHeaders())
            .then(res => {
                let tempArray = [];
                tempArray.push(...res.data);
                this.onInputChangeSupplement(tempArray, this.state.supplement);
                this.setState({
                    all: tempArray
                })
            })
    }

    fetchModeData = () => {
        axios
            .get(baseURL + "/invetory_info/" + this.state.id, getHeaders())
            .then(res => {
                this.setState({
                    avg: res.data.data.avg,
                    mode: res.data.data.mode,
                    percentage: res.data.data.percentage,
                    days_untill_run_out: res.data.data.days_untill_run_out,
                    remaining_amount: res.data.data.remaining_amount,

                })
            })
    }

    fetchBenefitData = () => {

        axios
            .get(baseURL + "/benefits", getHeaders())
            .then(response => {
                if (response.data.data.length > 0) {
                    let x = response.data.data;
                    let emptyArray = [];

                    x && x.map((elem, index) => {
                        let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id }
                        emptyArray.push(obj);
                    })
                    this.setState({
                        multiSelectData: emptyArray
                    })
                }
            })
    }


    onSelect = (selectedList, selectedItem) => {
        if (selectedList && selectedList.length) {
            this.setState({
                benefit: selectedList
            })
        }
        else {
            this.setState({
                benefit: []
            })
        }
    }
    onSelectAllergen = (allergean) => {
        this.setState({ allergeanList: allergean })
    }
    onRemove = (value) => {
        this.setState({
            benefit: value
        })
    }
    onRemoveAllergean = (value) => {
        this.setState({
            allergeanList: value
        })
    }

    onSubmitClick = () => {

        
        let data = {
            benefit: this.state.benefit,
            organism_barcode: this.state.organism_barcode,
        }
        axios
            .put(baseURL + "/benefits/" + this.state.id, data, getHeaders())
            .then(response => {
                toast.success("Benefit Update Successfully.");
                window.location.reload(false);
            })
        this.setState({
            benefit: []
        })

    }

    onInputChangeServingSize = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
        let tempArray = [];
        let obj = {};
        let tempArrayFromTable = [];
        this.state.inventory && this.state.inventory.map((elem, index) => {
            cross = elem.quantity / value;
            obj = {
                "serving_size": value,
                "id": elem.id,
                "supplement": elem.supplement,
                "quantity": elem.quantity,
                "unit": elem.unit,
                "amount_per_gram": cross
            }
            tempArray.push(obj);

        })
        this.setState({
            supplements2: tempArray
        })

        this.state.dataDown.map((elem, index) => {
            let cross = elem.quantity / value;
            let obj = {};
            obj = {
                "id": elem.id,
                "supplement": elem.supplement,
                "quantity": elem.quantity,
                "unit": elem.unit,
                "amount_per_gram": cross,
                "serving_size": value
            }
            tempArrayFromTable.push(obj);
        })

        let a = this.state.quantity;
        let b = value;
        let c = a / b;

        this.setState({
            supplements1: tempArrayFromTable,
            amount_per_gram: c
        })
        digitsAfterPointAddIngredient()
    };

    onAddOrganismClick = () => {
        if (this.state.quantity === "" || this.state.supplement_id === "" || this.state.quantity === 0
            || this.state.supplement_id === 0 || this.state.supplement === "" || this.state.serving_size === "" || this.state.serving_size === undefined) {
            alert("Please select supplement, Amount Per Serving and Serving Size");
            return;
        }
        let tempArray = this.state.inventory;

        let obj = {};
        obj = {
            "id": this.state.supplement_id,
            "supplement": this.state.supplement,
            "quantity": this.state.quantity,
            "unit": this.state.unit,
            "amount_per_gram": this.state.amount_per_gram,
            "serving_size": this.state.serving_size
        }
        tempArray.push(obj);

        var SelectedSupplement = this.state.supplement;
        let temps = this.state.all;
        temps && temps[0] && temps[0].filter((elem, index) => {

            if (elem.supplement === SelectedSupplement) {
                temps[0].splice(index, 1);

            }
        })
        this.setState({
            all: temps,
            inventory: tempArray,
            supplements2: tempArray
        })
        this.setState(this.resetOrganismState());
    }

    resetOrganismState = () => {
        return {
            quantity: 0,
            supplement_id: 0,
            supplement: "",
            amount_per_gram: ""
        };
    };

    onInputChangeSupplement = (array, value) => {
        this.setState({
            supplement: value
        })
        let tempArray = array
        tempArray[0].filter((elem) => {
            if (elem.supplement === value)
                if (elem.unit === "no unit") {
                    this.setState({
                        supplement_id: elem.id,
                        unit: ""
                    })
                }
                else {
                    this.setState({
                        supplement_id: elem.id,
                        unit: elem.unit
                    })
                }
        })
    }

    onInputAmountChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
        let a = value;
        let b = this.state.serving_size;
        let c = a / b;
        this.setState({
            amount_per_gram: c
        })
        amountPerServing()
    }

    onSubmitsupplement = () => {

        let data = {
            supplements: this.state.supplements1.concat(this.state.supplements2),
            organism_barcode: this.state.organism_barcode
        }
        axios
            .put(baseURL + "/supplements/" + this.state.id, data, getHeaders())
            .then(response => {
                toast.success("Supplement has been updated Successfully.");
                this.setState({
                    quantity: "",
                    supplement: "",
                    dataDown: [],
                    inventory: []
                })
                window.location.reload(false);
            })
    }


    upper = (index, value) => {
        let data = {
            "inevtory_document_id": value.inventory_id
        }
        var confirm = window.confirm("Are you sure you want to Delete?");
        if (confirm === true) {
            axios
                .post(baseURL + "/inventory/delete-documents", data, getHeaders())
                .then(res => {
                    let secondTemp = this.state.documentExistingName;
                    secondTemp.splice(index, 1);
                    this.setState({
                        documentExistingName: secondTemp
                    })
                })
        }
        else {
            return false;
        }

    }

    upper_download = (index, value) => {
        this.setState({
            is_page_loading: true,
            downloadDocumentName: value.name
        })
        let data = {
            "inevtory_document_id": value.inventory_id
        }
        axios
            .post(baseURL + "/inventory/download-documents", data, getHeaders())
            .then(res => {
                if (res.data !== "") {
                    this.setState({
                        is_page_loading: false
                    })
                    var dlnk = document.getElementById('dwnldLnk');
                    dlnk.href = 'data:application/octet-stream;base64,' + res.data;
                    dlnk.click();
                }

            })
    }


    handleSubmit = (event) => {
        let data = {
            tax_id: this.state.tax_id,
            token: Config.token
        }
        axios
            .post(portalURL + "/getmicrobnames", data)
            .then(response => {
                if (response.data.data.microbe_name === "") {
                    this.setState({
                        newScientificName: response.data.data.microbe_name,
                        newCommonName: response.data.data.microbe_name
                    })
                    toast.info("Tax ID doesn't exists");
                    return;
                }
                else {
                    this.setState({
                        newScientificName: response.data.data.microbe_name,
                        newCommonName: response.data.data.microbe_name
                    })
                }
            })
    }

    updateSubmitClick = () => {
        const {multi_v,ingredient_type,multi_v_gram}=this.state;

        var datass = checkValueExistOrNotInMultiVFun(multi_v,ingredient_type,multi_v_gram)
        if(!datass.unavailable && datass.checked){
            alert(datass.info);
            return;
        }
        
        let { manufacture, manufacture_lot, concentration } = this.state;
        if (manufacture === "" || !manufacture_lot === "" || concentration === "") {
            toast.info("Please fill all the mandatory information.");
            return;
        }
        let res = _.pick(this.state, [
            "replacement_barcode",
            "multi_v",
            "ingredient_type",
            "multi_v_gram",
            "replacement_barcode_id",
            "receive_date",
            "open_date",
            "expiration_date",
            "email_sent",
            "is_available",
            "concentration",
            "manufacture",
            "manufacture_lot",
            "qty",
            "reorder_point",
            "storage_location",
            "shelf",
            "scrap_factor",
            "notes",
            "name",
            "real_name",
            "organism_barcode",
            "tax_id",
            "in_house_sample_taken",
            "third_party_sample_taken",
            "in_house_sample_amount",
            "in_house_sample_date",
            "third_party_sample_date",
            "third_party_sample_amount",
            "quarantine_release_date",
            "disposal_date",
            "in_house_testing_complete",
            "third_party_testing_complete",
            "deviation_form_complete",
            "id"
        ]);

            this.setState({ AllergeanSubmitBtnMsg: "Loading..." })
            var allergeanIdArray = [];
            this.state.allergeanList && this.state.allergeanList.map((arr) => {
                allergeanIdArray.push(arr.id);
            })

            axios.put(baseURL + "/update_inventory_allergen", {
                "allergens": allergeanIdArray,
                "organism_barcode": this.state.organism_barcode
            }, getHeaders()

            ).then((data) => {
                if (data.data.success) {
                }

            })
                .catch(err => {
                    this.setState({ AllergeanSubmitBtnMsg: "" });
                    toast.error("Data Not Submited! Please Try Again");
                })

        let supplement_data = {
            supplements: this.state.supplements1.concat(this.state.supplements2),
            organism_barcode: this.state.organism_barcode
        }
        axios
            .put(baseURL + "/supplements/" + this.state.id, supplement_data, getHeaders())
            .then(response => {
                // toast.success("Supplement has been updated Successfully.");
                this.setState({
                    quantity: "",
                    supplement: "",
                    dataDown: [],
                    inventory: []
                })
            })
            .catch((err)=>console.log(err))

        let data = {
            benefit: this.state.benefit,
            organism_barcode: this.state.organism_barcode,
        }
        axios
            .put(baseURL + "/benefits/" + this.state.id, data, getHeaders())
            .then(response => {
                // toast.success("Benefit Update Successfully.");
                // window.location.reload(false);
            })
        this.setState({
            benefit: []
        })

        let x, y, z;
        res.third_party_sample_taken  = res.third_party_sample_taken.toLowerCase();
        res.in_house_sample_taken  = res.in_house_sample_taken.toLowerCase();
        
        if (this.state.open_date === "") {
            x = "1969-12-31 00:00:00";
        }
        else {
            let first = this.state.open_date;
            x = first.getFullYear() + "-" + Number(first.getMonth() + 1) + "-" + first.getDate() + " " + "00" + ":" + "00" + ":" + "00";
        }

        if (this.state.receive_date === "") {
            y = "1969-12-31 00:00:00";
        }
        else {
            let second = this.state.receive_date;
            y = second.getFullYear() + "-" + Number(second.getMonth() + 1) + "-" + second.getDate() + " " + "00" + ":" + "00" + ":" + "00";
        }

        if (this.state.expiration_date === "") {
            z = "1969-12-31 00:00:00";
        }
        else {

            let third = this.state.expiration_date;
            z = third.getFullYear() + "-" + Number(third.getMonth() + 1) + "-" + third.getDate() + " " + "00" + ":" + "00" + ":" + "00";
        }
        res.open_date = x;
        res.user_id = getSessionStoredJsonItem("user").user_id;
        res.receive_date = y;
        res.expiration_date = z;

        res.receive_date = formatDate(new Date(res.receive_date));
        res.open_date = formatDate(new Date(res.open_date))
        res.disposal_date = (res.disposal_date !== "")?formatDate(res.disposal_date):"";
        res.quarantine_release_date = (res.quarantine_release_date !== "")?formatDate(res.quarantine_release_date):"";
        res.in_house_sample_date = (res.in_house_sample_date !== "")?formatDate(res.in_house_sample_date):"";
        res.third_party_sample_date = (res.third_party_sample_date !== "")?formatDate(res.third_party_sample_date):"";
            
        this.setState({ is_waiting_for_response: true });
        axios
            .put(baseURL + "/inventory/" + res.id, res, getHeaders())
            .then(response => {
                toast.success("Successfully updated the inventory");
                focusFirstElement();
                
                setInterval(() => {
                    window.location.reload(false);
                }, 1000);
            })
            .catch(error => {
            });
    }

    createAll = (value) => {
        var is_available = isAvailable_Value_lower.find((availble) => availble.value == value);
        this.setState({ is_available: is_available.data, is_available_option_value: is_available.value });
    }

    creatEmail = (value) => {
        var is_available = options.find((availble) => availble.value == value);
        this.setState({ email_sent: is_available.data, email_value: is_available.value });

    }

    createReform = (e, { name, value, key }) => {
        this.setState({
            replacement_barcode: (value !== "")?value:null,
            replacement_barcode_id: (key !== "")?key:null,
        })

    }

    onDeleteClick = () => {
        let selectedData = api.getSelectedRows();
        selectedData.map(selectedRow => {
            this.setState({
                deleteMsg: "Are you sure you want to delete Ingredient Barcode- " + selectedRow.organism_barcode
            })
        })
        this.setState({ is_confirm_open: true });
    }

    onCancel = () => {
        this.setState({ is_confirm_open: false });
    };
    onConfirm = () => {
        this.setState({ is_confirm_open: false });
        let selectedData = api.getSelectedRows();
        let ids = [];
        ids = selectedData.map(record => record.organism_barcode);
        ids.filter((elem => {
            typeParams = elem.split("_", 1);
        }))
        for (let i = 0; i < typeParams.length; i++) {
            let axis = typeParams[i];
            lowerCase = axis.toLowerCase() + "_display";
        }
        axios
            .get(
                baseURL +
                "/delete_formula/" +
                ids.toString() + "?type=" + lowerCase,
                getHeaders()
            )
            .then(response => {
                alert(response.data.message);
                setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);
            })
            .catch(error => {
                alert("Deleting from Display Grid | Error: ", error);
            });
        api.updateRowData({ remove: selectedData });
    };

    delete = (index, value) => {
        let secondTemp = this.state.dataDown;
        secondTemp.splice(index, 1);
        this.setState({
            dataDown: secondTemp,
            supplements1: secondTemp
        })
        this.state.all[0].push({
            supplement: value.supplement,
            id: value.id,
            unit: value.unit,
            quantity: value.quantity,
            serving_size: value.serving_size,
            amount_per_gram: value.amount_per_gram
        })
    }

    exportCSV = () => {
        this.setState({
            is_page_loading_csv: true
        })
        axios
            .get(baseURL + "/generate_csv_inventory", getHeaders())
            .then(response => {
                if (response.data.success === true) {
                    window.open(response.data.url);
                    this.setState({
                        is_page_loading_csv: false
                    })

                }
            })
            .catch(error => {

            });
    }

    changePrimeAddition = (values) => {
        var is_available_value = isAvailable_Value.find((availble) => availble.value === values);
        this.setState({ is_page_loading: true });

        axios
            .get(baseURL + `/inventory/list/${is_available_value.data}`, getHeaders())
            .then(res => {
                this.setState({
                    samples: res.data,
                    is_page_loading: false,
                    showSupplement: false,
                    showStore: false,
                    showP: false,
                    showUploadDocuments: false,
                    avialble_inventory: is_available_value.value
                })
            })
    }

    onupdatefiles = (fileItems) => {
        let copyArray = this.state.fileItems;
        copyArray.push(fileItems)
        this.setState({
            fileItems: copyArray
        })

        const scope = this;

        let attachment_arr_temp = this.state.attachment_arr;
        attachment_arr_temp = [];

        fileItems.map((elem) => {

            let mediaTypePdf = elem.file.type.split("/")[1];
            let mediaSize = elem.file.size;
            let mediaType = elem.file.type;
            let mediaName = elem.file.name;
            var selectedFile = elem.file;

            if (selectedFile && mediaType.length !== 0) {

                var fileToLoad = selectedFile;
                var fileReader = new FileReader();
                var base64;

                fileReader.readAsDataURL(fileToLoad);
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let res = base64.slice(28);
                    if (["pdf", "csv"].indexOf(mediaTypePdf) > -1 && mediaSize <= 5242880) {
                        let obj = {};
                        obj = {
                            "base64Media": res,
                            "mediaName": mediaName,
                            "type": mediaType,
                            "size": mediaSize
                        }
                        attachment_arr_temp.push(obj);
                        const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                        scope.setState({
                            attachment_arr: final_array
                        })
                    }
                };
            }
            else {
                toast.info("Check Upload Format Type")
            }
        })

    }


    handleRemove = () => {
        const scope = this;
        this.setState({
            attachment_arr: []
        })
        let attachment_arr_temp = this.state.attachment_arr;
        if (this.pond) {
            const remainingAttachments = this.pond.getFiles().map((file) => file.source);
            remainingAttachments.map((elem, index) => {
                let mediaSize = elem.size;
                let mediaType = elem.type;
                let mediaName = elem.name;

                const pdf_index = pdf_name.indexOf(mediaName);
                if (pdf_index > -1) {
                    pdf_name.splice(pdf_index, 1); // 2nd parameter means remove one item only
                }
                var selectedFile = remainingAttachments[0];
                if (selectedFile) {
                    var fileToLoad = selectedFile;
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        let res = base64.slice(28);
                        if (mediaSize <= 5242880) {
                            let obj = {};
                            obj = {
                                "base64Media": res,
                                "mediaName": mediaName,
                                "type": mediaType
                            }
                            attachment_arr_temp.push(obj);
                            const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                            scope.setState({
                                attachment_arr: final_array
                            })
                        }
                    };

                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
    }

    onSubmitPdf = () => {

        if (this.state.attachment_arr.length == 0) {
            return toast.info("Please Upload a Document")
        }
        let res = {
            attachment_arr: this.state.attachment_arr,
            inventory_id: this.state.id
        }
        this.setState({
            is_page_loading: true
        })
        axios
            .post(baseURL + "/upload-documents", res, getHeaders())
            .then(response => {
                toast.success("Successfully updated the Document");
                window.location.reload(false)
                this.setState({
                    is_page_loading: false
                })
            })

    }

    onMouseEnter = () => {
        $("#toggle").css("display", "block");
    }
    onMouseLeave = () => {
        $("#toggle").css("display", "none");
    }

    onMouseEnterCommonName = () => {
        $("#toggle-real").css("display", "block");
    }
    onMouseLeaveCommonName = () => {
        $("#toggle-real").css("display", "none");
    }

    AnswerKeyPressMonth = (e) => {
        notEnterZeroBefore(e)
    }
    quality_assurance_change = (name, data) => {
            if(data == "--Please Choose an option--")
            {
                this.setState({ [name]: "" })                
            }
            else{

                this.setState({ [name]: data })
            }
    }

    callFile = (file) => {
        console.log(file);
    }

    errorFiles = (err) => {
        console.log(err);
    }
    inputBoxChange = (e) => {
        if (checkboxField.indexOf(e.target.name) == -1) {
            this.setState({ [e.target.name]: 1 });
            checkboxField.push(e.target.name);
        }
        else {
            this.setState({ [e.target.name]: 0 });
            var checkbox = checkboxField.filter((item) => item !== e.target.name);
            checkboxField = checkbox;
        }
    }


    multi_v_availablity_fn = (type,value)=>{
        var multi_value = multi_v_availablity(type,value,this.state.multi_v);
        this.setState(multi_value);
    }
    
    

    render() {

        return (
            <>
                <CustomConfirm
                    is_confirm_open={this.state.is_confirm_open}
                    content={this.state.deleteMsg}
                    header="Delete selected Record(s)"
                    cancel_button_text="Eh! Never Mind."
                    confirm_button_text="DELETE"
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}
                />
                {this.state.is_page_loading && <CustomLoader />}
                <Message>
                    <Header as="h3" textAlign="center">
                        Inventory
                    </Header>
                </Message>
                <Segment
                    compact
                    raised
                    padded
                    style={{ display: "block" }}
                    className="transparent"
                >
                    <Grid>
                        <div className="filter-flex align-items-end" >
                            <div className="col-filter">

                                {/* <Segment style={{ width: "144%" }}> */}
                                <Custom_DropDown
                                    label={"Is Available"}
                                    content={"Is available."}
                                    onChange={this.changePrimeAddition}
                                    isAvailable={isAvailable}
                                    is_available={"is_available"}
                                    classNames={"is-available"}
                                    dropDownValue={this.state.avialble_inventory}
                                    inventory={inventory}
                                />
                            </div>

                            <div className="col-filter">

                                <Dropdown
                                    id="page-size"
                                    placeholder="Page Size"
                                    selection
                                    options={page_size_options}
                                    onChange={newPageSize => onPageSizeChanged(newPageSize, api)}
                                // style={{ position: "absolute", left: "47px", width: "166px" }}
                                />
                            </div>
                            <div className="col-filter">

                                <Button
                                    basic
                                    icon
                                    onClick={() => resetSort(api)}
                                    labelPosition="left"
                                // style={{ position: "absolute", left: "51px" }}
                                >
                                    <Icon name="sort alphabet up" />
                                    Reset Sort(s)
                                </Button>
                            </div>
                            <div className="col-filter">
                                <Button
                                    basic
                                    onClick={() => resetFilter(api)}
                                    icon
                                    labelPosition="left"
                                >
                                    Reset Filter(s)
                                    <Icon name="filter" />
                                </Button>
                            </div>
                            <div className="col-filter">
                                <Button
                                    basic
                                    onClick={this.exportCSV}
                                    icon
                                    labelPosition="left"
                                >
                                    Export as CSV
                                    <Icon name="filter" />
                                </Button>
                            </div>
                            <div className="col-filter">
                                <Button
                                    basic
                                    negative
                                    onClick={this.onDeleteClick}
                                    icon
                                    labelPosition="right"
                                    // style={{ position: "absolute", right: "0" }}
                                    disabled={this.state.is_special_button_disabled}
                                >
                                    Delete Record(s)
                                    <Icon name="trash" />
                                </Button>
                            </div>
                        </div>

                        <Grid.Row>
                            <div
                                id="myGrid"
                                ag-grid="gridOptions"
                                style={{ width: "100%" }}
                                className="ag-theme-balham"
                            >
                                <AgGridReact
                                    rowHeight="30"
                                    rowSelection={this.state.rowSelection}
                                    onRowClicked={this.onRowClicked}
                                    onGridReady={params => {

                                        api = params.api;
                                        api.showLoadingOverlay();
                                        column_api = params.column_api;
                                        let initial_sort = [{ colId: "id", sort: "desc" }]
                                        onGridReady(
                                            params,
                                            api,
                                            column_api,
                                            initial_sort
                                        );
                                    }}
                                    onCellValueChanged={this.cellValueChaged}
                                    animateRows={true}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationNumberFormatter={params => {
                                        return "[" + params.value.toLocaleString() + "]";
                                    }}
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    floatingFilter={true}
                                    rowData={this.state.samples.reverse()}
                                />
                            </div>

                        </Grid.Row>
                        <Grid.Row>
                            <Segment style={{ display: this.state.showP ? 'block' : 'none' }}>
                                <Grid>
                                    <CustomDivider
                                        h_size="h3"
                                        title="Ingredient Info"
                                        icon_name="info"
                                    />
                                    <Grid.Row className="block">
                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true">
                                                <Popup
                                                    trigger={<label>Ingredient Barcode</label>}
                                                    content="Enter the Ingredient Barcode."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="organism_barcode"
                                                    fluid
                                                    disabled={true}
                                                    placeholder="Ingredient Barcode"
                                                    value={this.state.organism_barcode}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Tax ID</label>}
                                                    content="Enter the Tax Id."
                                                />

                                                <Input
                                                    style={inventory}
                                                    name="tax_id"
                                                    fluid
                                                    placeholder="Tax Id"
                                                    value={split === "PRO" || split === "PRE" ? this.state.tax_id : "NA"}
                                                    onChange={this.onInputChange}
                                                    onBlur={this.handleSubmit}
                                                    disabled={split === "PRO" ? false : true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={3} >
                                            <Form.Field required fluid="true" onMouseLeave={this.onMouseLeave}>
                                                <Popup
                                                    trigger={<label>Display Name</label>}
                                                    content="Enter the Display name."
                                                />
                                                <div id="toggle" className="tooltip-name">

                                                    {(split === "PRE") ?
                                                        this.state.name : this.state.real_name}
                                                </div>
                                                <Input
                                                    style={inventory}
                                                    name={(split === "PRE") ? "name" : "real_name"}
                                                    fluid
                                                    placeholder="Display Name"
                                                    className="tool-tip custom-tooltip"
                                                    onChange={this.onInputChange}
                                                    value={(split === "PRE") ? this.state.name : this.state.real_name}
                                                    onMouseEnter={this.onMouseEnter}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" onMouseLeave={this.onMouseLeaveCommonName}>
                                                <Popup
                                                    trigger={<label>Internal Name</label>}
                                                    content="Enter the Internal Name."
                                                />
                                                <div id="toggle-real" className="tooltip-name">
                                                    {split === "PRM" ? this.state.newCommonName :
                                                        (split === "FOR" ? this.state.newCommonName :
                                                            (split === "PRE" ? this.state.real_name :
                                                                (split === "PRO" ? this.state.newCommonName : "NA")))}
                                                </div>
                                                <Input
                                                    style={inventory}
                                                    name={(split !== "PRE") ? "name" : "real_name"}
                                                    fluid
                                                    placeholder="Internal Name"
                                                    className="commons"
                                                    value={split === "PRM" ? this.state.newCommonName :
                                                        (split === "FOR" ? this.state.newCommonName :
                                                            (split === "PRE" ? this.state.real_name :
                                                                (split === "PRO" ? this.state.newCommonName :
                                                                    "NA")))}
                                                    onMouseEnter={this.onMouseEnterCommonName}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>


                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Concentration (B CFU/g)</label>}
                                                    content="Enter the concentration."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="concentration"
                                                    fluid
                                                    placeholder="Concentration"
                                                    value={this.state.concentration}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row className="block" >
                                        <Grid.Column width={3}>

                                            <Custom_DropDown
                                                label={"Is Available"}
                                                content={"Is available."}
                                                onChange={this.createAll}
                                                isAvailable={isAvailable}
                                                is_available={"is_available"}
                                                classNames={"is-available"}
                                                dropDownValue={this.state.is_available_option_value}
                                                inventory={inventory}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Quantity (g)</label>}
                                                    content="Enter the Quantity."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="qty"
                                                    fluid
                                                    placeholder="Quantity"
                                                    value={this.state.qty}
                                                    onChange={this.onInputChange}
                                                    id="answer_key_press"
                                                    onKeyPress={this.AnswerKeyPressMonth}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Reorder Point (g)</label>}
                                                    content="Enter the Reorder Point."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="reorder_point"
                                                    fluid
                                                    placeholder="Reorder Point"
                                                    value={this.state.reorder_point === null ? "" : this.state.reorder_point}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>


                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Scrap Factor</label>}
                                                    content="Enter the Scrap Factor."
                                                />
                                                <Input
                                                    style={inventory}
                                                    value={this.state.scrap_factor}
                                                    name="scrap_factor"
                                                    fluid
                                                    placeholder="Scrap Factor"
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Replacement Barcode</label>}
                                                    content="Select replacement barcode"
                                                />
                                                <Dropdown
                                                    id="inventory_id"
                                                    name="inventory_id"
                                                    style={{ opacity: 1, backgroundColor: "#b8sfcff" }}
                                                    fluid
                                                    search
                                                    clearable
                                                    selection
                                                    onChange={(event, data) => {
                                                        let key = ""
                                                        data.options.forEach(inventory => {
                                                            if (inventory.value === data.value) {
                                                                key = inventory.key;
                                                            }
                                                        });
                                                        this.createReform(event, {
                                                            name: data.name,
                                                            value: data.value,
                                                            key: key
                                                        });
                                                    }}
                                                    disabled={split === "FOR" || split === "PRM" ? true : false}
                                                    value={(this.state.replacement_barcode)?this.state.replacement_barcode:""}
                                                    options={
                                                        this.state.reform_array.filter((instance, index) => {
                                                            if (this.state.reform_array[index]['active'] !== false) {
                                                                this.instanceArr.push({
                                                                    key: instance.key,
                                                                    value: instance.key,
                                                                    active: instance.active,
                                                                    text: instance.text,
                                                                    icon: "dna"
                                                                })
                                                                return this.instanceArr;
                                                            }
                                                        })

                                                    }

                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={15} style={{ marginTop: "16px" }}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Notes</label>}
                                                    content="Enter the Notes."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="notes"
                                                    value={this.state.notes === null ? "" : this.state.notes}
                                                    onChange={this.onInputChange}
                                                    fluid
                                                    placeholder="Add Notes here"
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>


                                    <div style={{ fontWeight: "bold", paddingTop: "15px" }}>Manufacturer Info</div>
                                    <Grid.Row className="block">
                                        <Grid.Column width={4}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Manufacturer</label>}
                                                    content="Enter the Manufacturer."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="manufacture"
                                                    fluid
                                                    placeholder="Manufacturer"
                                                    value={this.state.manufacture}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Manufacturer Lot</label>}
                                                    content="Enter the Manufacturer Lot."
                                                />
                                                <Input
                                                    style={inventory}
                                                    fluid
                                                    name="manufacture_lot"
                                                    placeholder="Manufacture Lot"
                                                    value={this.state.manufacture_lot}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                     <Grid.Column width={4}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Expiration Date</label>}
                                                    content="Enter the Expiration Date."
                                                />
                                                <DatePicker
                                                    autoComplete="off"
                                                    selected={this.state.expiration_date}
                                                    placeholderText="Select date"
                                                    todayButton={"Current Date"}
                                                    className="date-picker"
                                                    dateFormat="MM/dd/yyyy"
                                                    isClearable={true}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    openToDate={new Date(Config.default_calendar_date)}
                                                    onChange={date => {
                                                        this.onDateChange(Config.inventory.expiration_date, date);
                                                    }}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                   
                                    </Grid.Row> 

<Grid.Row>
<Grid.Column>

<Grid>
    <Grid.Column width={4}>
    <div>Multi-V Availability</div>
    <Radio
    toggle
    className="allergen-radio-btn"
    onClick={()=>this.multi_v_availablity_fn('radio',this.state.multi_v)}
    checked={(this.state.multi_v == "available")?true:false}
    />
    
    </Grid.Column>
    {(this.state.multi_v == "available")&& (
    <>
    <Grid.Column  width={4}>
          <Custom_DropDown
                label={"Ingredient Type"}
                content={"Ingredient type."}
                onChange={(e)=>this.multi_v_availablity_fn("ingredient_type",e)}
                isAvailable={multi_v}
                is_available={"is_available"}
                classNames={"is-available"}                
                dropDownValue={this.state.ingredient_type}
                inventory={multi_v_css}
            />
    </Grid.Column>
    <Grid.Column  width={4}>
            <span>Price per gram </span>
            <Input
            name="price_per_gram"
            type="number"
            fluid
            value={this.state.multi_v_gram}
            placeholder="Price per gram"
            onChange={(e)=>{ this.multi_v_availablity_fn('multi_v_gram',e.target.value) }}
        />
    </Grid.Column></>)}
    

</Grid>
                                        
                                        </Grid.Column>
</Grid.Row>

                                    <div style={{ fontWeight: "bold", paddingTop: "15px" }}>Storage Info</div>
                                    <Grid.Row className="block" >
                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true">
                                                <Popup
                                                    trigger={<label>Storage Location</label>}
                                                    content="Enter the Storage Location."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="storage_location"
                                                    fluid
                                                    placeholder="Storage Locationt"
                                                    value={this.state.storage_location === null ? "" : this.state.storage_location}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Shelf</label>}
                                                    content="Enter the Shelf."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="shelf"
                                                    fluid
                                                    placeholder="Shelf"
                                                    value={this.state.shelf === null ? "" : this.state.shelf}
                                                    onChange={this.onInputChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                    </Grid.Row>

                                    <div style={{ fontWeight: "bold", paddingTop: "15px" }}>Logs</div>
                                    <Grid.Row className="block" >
                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Created At</label>}
                                                    content="Created At."
                                                />
                                                <Input
                                                    style={inventory}
                                                    disabled
                                                    fluid
                                                    value={this.state.created_at}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Last Updated At</label>}
                                                    content="Enter the Last Updated At."
                                                />
                                                <Input
                                                    style={inventory}
                                                    disabled
                                                    fluid
                                                    value={this.state.updated_at}
                                                    placeholderText="Click here to select date"
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Username</label>}
                                                    content="Enter the Username."
                                                />
                                                <Input
                                                    style={inventory}
                                                    name="inventory_id"
                                                    fluid
                                                    placeholder="Username"
                                                    value={this.state.full_name}
                                                    disabled
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <Form.Field required fluid="true" >
                                                {/* <Popup
                                                    trigger={<label>Notification Sent?</label>}
                                                    content="Enter the Notification Sent."
                                                /> */}

                                                <Custom_DropDown
                                                    label={"Notification Sent"}
                                                    content={"Enter the Notification Sent."}
                                                    onChange={this.creatEmail}
                                                    isAvailable={options}
                                                    is_available={"is_available"}
                                                    classNames={"is-available"}
                                                    dropDownValue={this.state.email_value}
                                                    inventory={inventory}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </Segment>

                        </Grid.Row>
                        <Grid.Row>
                                <Grid.Column width={8} style={{ height: "100%",display:this.state.showUploadDocuments  ? 'block' : 'none'}}>
                                    <Segment className="transparent" style={{ width: "100%" }}>
                                        <CustomDivider
                                            h_size="h3"
                                            title="Quality Assurance"
                                            icon_name="hourglass half"
                                        />

                                        <div className="filter-flex align-items-end w-100">

                                            <div className="col-filter" style={{ width: "50%" }}>
                                                <div>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Receive Date</label>}
                                                            content="Enter the Receive Date."
                                                        />
                                                        <DatePicker
                                                            autoComplete="off"
                                                            selected={this.state.receive_date}
                                                            placeholderText="Select date"
                                                            todayButton={"Current Date"}
                                                            className="date-picker"
                                                            dateFormat="MM/dd/yyyy"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            openToDate={new Date()}
                                                        onChange={date => {
                                                            this.onDateChange(Config.inventory.receive_date, date);
                                                        }}
                                                        />
                                                    </Form.Field>
                                                </div>
                                            </div>
                                            
                                            <div className="col-filter" style={{ width: "50%" }}>
                                                <div >
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Open Date</label>}
                                                            content="Enter the Open Date."
                                                        />

                                                        <DatePicker
                                                            autoComplete="off"
                                                            selected={this.state.open_date}
                                                            placeholderText="Select date"
                                                            todayButton={"Current Date"}
                                                            dateFormat="MM/dd/yyyy"
                                                            // isClearable={true}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            className="date-picker"
                                                            openToDate={new Date()}
                                                            onChange={date => {
                                                                this.onDateChange(Config.inventory.open_date, date);
                                                            }}
                                                        />
                                                    </Form.Field>
                                                </div>
                                            </div>


                                        </div>
                                        <br />
                                        <div className="filter-flex align-items-end w-100">
                                            <div className="col-filter" style={{ width: "50%" }}>

                                                {/* In-house sample taken? */}
                                                <Form.Field required fluid="true" >
                                                    <Quality_assurance_DropDown
                                                        label={"In-house sample taken?"}
                                                        content={"In-house sample taken?"}
                                                        onChange={this.quality_assurance_change}
                                                        isAvailable={options_quality_assurance}
                                                        
                                                        is_available={"in_house_sample_taken"}
                                                        classNames={"is-available field-ui"}
                                                        dropDownValue={this.state.in_house_sample_taken}
                                                        inventory={inventory}
                                                    />

                                                </Form.Field>
                                            </div>

                                            <div className="col-filter" style={{ width: "50%" }}>
                                                {/* 3rd-party sample taken */}
                                                <Form.Field required fluid="true" >
                                                    <Quality_assurance_DropDown
                                                        label={"3rd-party sample taken"}
                                                        content={"3rd-party sample taken?"}
                                                        onChange={this.quality_assurance_change}
                                                        isAvailable={options_quality_assurance}
                                                        is_available={"third_party_sample_taken"}
                                                        classNames={"is-available field-ui"}
                                                        dropDownValue={this.state.third_party_sample_taken}
                                                        inventory={inventory}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>


                                        <div className="filter-flex align-items-end w-100">
                                            <div className="col-filter" style={{ width: "50%" }}>
                                                {/* In house data sample */}
                                                <Form.Field required fluid="true" >
                                                    <Popup
                                                        trigger={<label>In-house Date Sampled</label>}
                                                        content="In-house Date Sampled."
                                                    />
                                                    <DatePicker
                                                        autoComplete="off"
                                                        selected={this.state.in_house_sample_date}
                                                        placeholderText="In-house Date Sampled"
                                                        todayButton={"Current Date"}
                                                        name="in_house_sample_date"
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={true}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="date-picker"
                                                        openToDate={new Date()}
                                                        onChange={(date) => {
                                                            if (date <= new Date()) {
                                                                this.setState({ in_house_sample_date: date })
                                                            }
                                                            else {
                                                                toast.error("Please select valid date")
                                                            }
                                                        }
                                                        }
                                                    />
                                                </Form.Field>
                                            </div>

                                            <div className="col-filter" style={{ width: "50%" }}>

                                                <Form.Field required fluid="true" >
                                                    <Popup
                                                        trigger={<label>3rd-party Date Sampled</label>}
                                                        content="3rd-party Date Sampled."
                                                    />
                                                    <DatePicker
                                                        autoComplete="off"
                                                        selected={this.state.third_party_sample_date}
                                                        placeholderText="3rd-party Date Sampled"
                                                        todayButton={"Current Date"}
                                                        name="third_party_sample_date"
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={true}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="date-picker"
                                                        openToDate={new Date()}
                                                        onChange={(date) => {
                                                            if (date <= new Date()) {
                                                                this.setState({ third_party_sample_date: date })
                                                            }
                                                            else {
                                                                toast.error("Please select valid date")
                                                            }
                                                        }
                                                        }
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>

                                        <div className="filter-flex align-items-end w-100">
                                            <div className="col-filter" style={{ width: "50%" }}>
                                                <Form.Field required fluid="true" >
                                                    <Popup
                                                        trigger={<label>In-house Amount of sample</label>}
                                                        content="In-house Amount of sample."
                                                    /><br />
                                                    <Input
                                                        style={{ opacity: 1, width: "99%" }}
                                                        icon="weight"
                                                        iconPosition="left"
                                                        type="number"
                                                        label={{ content: "g" }}
                                                        name="in_house_sample_amount"
                                                        onChange={(e) => {
                                                            if(parseInt(e.target.value)>0 || e.target.value == "" )
                                                            {
                                                                this.quality_assurance_change(e.target.name, e.target.value)}
                                                            }
                                                            }
                                                        labelPosition="right"
                                                        value={this.state.in_house_sample_amount}
                                                    />
                                                </Form.Field>
                                            </div>

                                            

                                            <div className="col-filter" style={{ width: "50%" }}>
                                                <Form.Field required fluid="true" >
                                                    <Popup
                                                        trigger={<label>3rd-Party Amount of sample</label>}
                                                        content="3rd-Party Amount of sample."
                                                    /><br />
                                                    <Input
                                                        style={{ opacity: 1, width: "99%" }}
                                                        icon="weight"
                                                        type="number"
                                                        iconPosition="left"
                                                        name="third_party_sample_amount"
                                                        onChange={(e) =>{  
                                                        if(parseInt(e.target.value)>0 || e.target.value == "" )
                                                        {
                                                            this.quality_assurance_change(e.target.name, e.target.value)}
                                                        }}
                                                        label={{ content: "g" }}                                                        labelPosition="right"
                                                        value={this.state.third_party_sample_amount}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>

                                        <div className="filter-flex align-items-end w-100">
                                            <div className="col-filter" >
                                                <input
                                                 type="checkbox"
                                                 id="in_house_testing_complete" name="in_house_testing_complete"
                                                 value={this.state.in_house_testing_complete}
                                                 onChange={(e) => this.inputBoxChange(e)} />
                                                &nbsp;<label>In-house testing complete</label><br />
                                                <input type="checkbox" id="third_party_testing_complete"
                                                name="third_party_testing_complete"
                                                value={this.state.third_party_testing_complete} onChange={(e) => this.inputBoxChange(e)} />
                                                &nbsp;<label>3rd party testing complete</label> <br />
                                                
                                                

                                                <input type="checkbox" id="deviation_form_complete"
                                                name="deviation_form_complete" value={this.state.deviation_form_complete}
                                                onChange={(e) => this.inputBoxChange(e)} />
                                                &nbsp;<label>Deviation from complete (if applicable)</label>
                                            </div>
                                        </div>

                                        <div className="filter-flex align-items-end w-100">
                                            <div className="col-filter" style={{ width: "50%" }}>
                                                <Form.Field required fluid="true" >
                                                    <Popup
                                                        trigger={<label>Release from quarantine date</label>}
                                                        content="Enter the Receive Date."
                                                    />
                                                    <DatePicker
                                                        autoComplete="off"
                                                        selected={this.state.quarantine_release_date}
                                                        placeholderText="Release from Quarantine Date"
                                                        todayButton={"Current Date"}
                                                        className="date-picker"
                                                        dateFormat="MM/dd/yyyy"
                                                        // isClearable={true}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        openToDate={new Date()}
                                                        onChange={(date) => {
                                                            if (date <= new Date()) {
                                                                this.setState({ quarantine_release_date: date })
                                                            }
                                                            else {
                                                                toast.error("Please select valid date")
                                                            }
                                                        }}
                                                    />
                                                </Form.Field>
                                            </div>
                                            <div className="col-filter" style={{ width: "50%" }}>
                                                <Form.Field required fluid="true" >
                                                    <Popup
                                                        trigger={<label>Disposal Date</label>}
                                                        content="Enter the Disposal Date."
                                                    />
                                                    <DatePicker
                                                        autoComplete="off"
                                                        selected={this.state.disposal_date}
                                                        placeholderText="Disposal date"
                                                        todayButton={"Current Date"}
                                                        dateFormat="MM/dd/yyyy"
                                                        // isClearable={true}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        className="date-picker"
                                                        openToDate={new Date()}
                                                        onChange={(date) => {
                                                            console.log(date);
                                                            if (date <= new Date()) {
                                                                this.setState({ disposal_date: date })
                                                            }
                                                            else {
                                                                toast.error("Please select valid date")
                                                            }
                                                        }}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>






                                    </Segment>
                                </Grid.Column>
                                <Grid.Column  width={8} >
                                    <Segment className="transparent" style={{ height: "100%",display:this.state.showUploadDocuments  ? 'block' : 'none', width: "100%" }}>
                                        <CustomDivider
                                            h_size="h3"
                                            title="Upload Documents"
                                            icon_name="upload"
                                        />
                                    <div className="inventory_upload_document">
                                        {this.state.documentExistingName &&
                                            this.state.documentExistingName.map((elem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Grid columns="equal">
                                                            <Grid.Row className="block" >
                                                                <Grid.Column width={12}>
                                                                    <Input
                                                                        className="drag upload-documents-input"
                                                                        value={elem.name}
                                                                    >
                                                                    </Input>
                                                                </Grid.Column>
                                                                <Grid.Column width={2}>
                                                                    <Form.Field fluid="true">
                                                                        <Popup
                                                                            trigger={
                                                                                <Button
                                                                                    icon="delete"
                                                                                    negative
                                                                                    circular
                                                                                    onClick={() => this.upper(index, elem)}
                                                                                />
                                                                            }
                                                                            content="Delete this Document."
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                                <Grid.Column width={2}>

                                                                    <Form.Field fluid="true">
                                                                        <a id='dwnldLnk' download={this.state.downloadDocumentName} style={{ display: "none" }} />
                                                                        <Popup
                                                                            trigger={
                                                                                <Button
                                                                                    href="#"
                                                                                    icon="download"
                                                                                    circular
                                                                                    id="download"
                                                                                    onClick={() => this.upper_download(index, elem)}

                                                                                />
                                                                            }
                                                                            content="Downlaod this Document."
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                        <Grid columns="equal">
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <FilePond
                                                        acceptedFileTypes={['application/pdf', 'application/vnd.ms-excel', 'text/csv', 'application/csv']}
                                                        ref={ref => (this.pond = ref)}
                                                        allowMultiple={true}
                                                        allowReorder={true}
                                                        allowRemove={true}
                                                        maxFiles={10 - this.state.documentExistingName.length}
                                                        allowFileSizeValidation={true}
                                                        maxFileSize="5MB"
                                                        onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                                                        onremovefile={this.handleRemove}
                                                        checkValidity={true}
                                                        dropValidation
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column style={{ marginBottom: "10px" }}>
                                                    <Grid textAlign="center">
                                                        <Button.Group>
                                                            <Popup
                                                                trigger={
                                                                    <Button onClick={this.onSubmitPdf} positive>
                                                                        Update Documnet
                                                                    </Button>
                                                                }
                                                                content="Update Document."
                                                            />
                                                        </Button.Group>
                                                    </Grid>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        

                        <Grid.Row style={{ display: this.state.showUploadDocuments ? 'block' : 'none', width: "100%" }}>
                            <TotalUsageInfo
                                days_untill_run_out={this.state.days_untill_run_out}
                                remaining_amount={this.state.remaining_amount}
                                avg={this.state.avg}
                                mode={this.state.mode}
                                percentage={this.state.percentage}
                            />
                        <br/>
                    </Grid.Row>


                        <Segment className="transparent" style={{ display: this.state.showSupplement ? 'block' : 'none' }}>
                            <CustomDivider
                                h_size="h3"
                                title="Supplements"
                                icon_name="cart plus"
                            />

                            <Grid.Row className="block">
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label style={{ display: "-webkit-box" }}>Serving Size</label>}
                                        />
                                        <Input
                                            id="NumericTextBox"
                                            type="number"
                                            style={{ opacity: 1, marginBottom: "20px" }}
                                            name="serving_size"
                                            iconPosition="left"
                                            placeholder="Serving Size"
                                            onChange={this.onInputChangeServingSize}
                                            value={this.state.serving_size}
                                        />

                                    </Form.Field>
                                </Grid.Column>

                            </Grid.Row>

                            <Grid columns="equal">
                                {this.state.dataDown &&
                                    this.state.dataDown.map((elem, index) => {
                                        elemAmountPergram = elem.quantity / this.state.serving_size;
                                        return (
                                            <React.Fragment key={index}>
                                                <Grid.Row className="block">
                                                    <Grid.Column width={5}>
                                                        <Form.Field required fluid="true">
                                                            <Popup
                                                                trigger={<label>Supplements</label>}
                                                                content="Enter the Supplements."
                                                            />
                                                            <Input
                                                                value={elem.supplement}
                                                                disabled
                                                                style={{ opacity: 1, display: "flex", backgroundColor: "rgb(184, 252, 255)" }}
                                                            >
                                                            </Input>
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column width={5}>
                                                        <Form.Field required fluid="true" >
                                                            <Popup
                                                                trigger={<label>Amount Per Servings</label>}
                                                                content="Amount Per Serving."
                                                            />
                                                            <Input
                                                                style={{ opacity: 1 }}
                                                                icon="weight"
                                                                iconPosition="left"
                                                                label={elem.unit === "no unit" ? "" : elem.unit}
                                                                labelPosition="right"
                                                                value={elem.quantity}
                                                                disabled
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column width={5}>
                                                        <Form.Field required fluid="true">
                                                            <Popup
                                                                trigger={<label>Amount Per Grams</label>}
                                                                content="Amount Per Grams."
                                                            />

                                                            <Input
                                                                style={{ opacity: 1 }}
                                                                disabled
                                                                placeholder="Amount Per Gm"
                                                                label={elem.unit === "no unit" ? "" : elem.unit}
                                                                labelPosition="right"
                                                                value={parseFloat(elemAmountPergram).toFixed(3)}

                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column>
                                                        <Form.Field fluid="true">
                                                            <label style={{ visibility: "hidden" }}>.</label>
                                                            <Popup
                                                                trigger={
                                                                    <Button
                                                                        icon="delete"
                                                                        negative
                                                                        circular
                                                                        onClick={() => this.delete(index, elem)}
                                                                    />
                                                                }
                                                                content="Delete this Supplement."
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </React.Fragment>
                                        )
                                    })
                                }


                                {this.state.inventory &&
                                    this.state.inventory.map((elem, index) => {
                                        elemAmountPergram = elem.quantity / this.state.serving_size;
                                        return (
                                            <React.Fragment key={index}>
                                                <Grid.Row className="block">
                                                    <Grid.Column width={5}>
                                                        <Form.Field required fluid="true">
                                                            <Popup
                                                                trigger={<label>Supplements</label>}
                                                                content="Enter the Supplements."
                                                            />
                                                            <Input
                                                                value={elem.supplement}
                                                                disabled
                                                                style={{ opacity: 1, display: "flex", backgroundColor: "rgb(184, 252, 255)" }}
                                                            >
                                                            </Input>
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column width={5}>
                                                        <Form.Field required fluid="true">
                                                            <Popup
                                                                trigger={<label>Amount Per Serving</label>}
                                                                content="Enter the amount added."
                                                            />

                                                            <Input
                                                                id="quantity"
                                                                style={{ opacity: 1 }}
                                                                icon="weight"
                                                                iconPosition="left"
                                                                label={elem.unit === "no unit" ? "" : elem.unit}
                                                                labelPosition="right"
                                                                value={elem.quantity}
                                                                disabled
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column width={5}>
                                                        <Form.Field required fluid="true">
                                                            <Popup
                                                                trigger={<label>Amount Per Gram</label>}
                                                                content="Enter the amount added."
                                                            />
                                                            <Input
                                                                style={{ opacity: 1 }}
                                                                disabled
                                                                placeholder="Amount Per Gm"
                                                                label={elem.unit === "no unit" ? "" : elem.unit}
                                                                labelPosition="right"
                                                                onChange={this.onInputChange}
                                                                value={parseFloat(elemAmountPergram).toFixed(3)}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>


                                                    <Grid.Column>
                                                        <Form.Field fluid="true">
                                                            <label style={{ visibility: "hidden" }}>.</label>
                                                            <Popup
                                                                trigger={
                                                                    <Button
                                                                        icon="delete"
                                                                        negative
                                                                        circular
                                                                        onClick={() => this.deleteDown(index, elem)}
                                                                    />
                                                                }
                                                                content="Delete this Supplement."
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </React.Fragment>
                                        )
                                    })
                                }

                                <CustomDivider
                                    h_size="h3"
                                    title="Add Supplements"
                                    icon_name="plus"
                                />
                                <Grid.Row className="block">
                                    <Grid.Column width={5}>
                                        <Form.Field required fluid="true">
                                            <Popup
                                                trigger={<label>Supplements</label>}
                                                content="Supplements."
                                            />
                                            <input list="supplement"
                                                style={{
                                                    opacity: 1, backgroundColor: "#b8sfcff", width: "90%", padding: "8px 0", display: "block",
                                                    borderRadius: "4px solid Gray"
                                                }}

                                                value={this.state.supplement}
                                                onChange={(e) => this.onInputChangeSupplement(this.state.all, e.target.value)}
                                            ></input>
                                            <datalist
                                                id="supplement"
                                            >
                                                {
                                                    this.state.all && this.state.all[0] && this.state.all[0].map((elem, index) => {
                                                        return (
                                                            <option key={index}>
                                                                {elem.supplement}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </datalist>
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={5}>
                                        <Form.Field required fluid="true">
                                            <Popup
                                                trigger={<label>Amount Per Serving</label>}
                                                content="Enter the amount added."
                                            />
                                            <Input
                                                id="NumericTextBoxAmountPerServing"
                                                type="number"
                                                name="quantity"
                                                icon="weight"
                                                iconPosition="left"
                                                label={this.state.unit}
                                                labelPosition="right"
                                                placeholder="Amount Added"
                                                onChange={this.onInputAmountChange}
                                                value={this.state.quantity}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Form.Field required fluid="true" >
                                            <Popup
                                                trigger={<label>Amount Per Gram</label>}
                                                content="Enter the amount added."
                                            />
                                            <Input
                                                type="number"
                                                name="amount_per_gram"
                                                icon="weight"
                                                iconPosition="left"
                                                label={this.state.unit}
                                                labelPosition="right"
                                                placeholder="Amount Per Gm"
                                                onChange={this.onInputChange}
                                                value={parseFloat(this.state.amount_per_gram).toFixed(3)}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>


                                <Grid.Row>
                                    <Grid.Column style={{ textAlign: "center" }}>
                                        <Popup
                                            trigger={
                                                <Button
                                                    animated="vertical"
                                                    className="small"
                                                    color="blue"
                                                    onClick={this.onAddOrganismClick}
                                                >
                                                    <Button.Content visible>
                                                        <Icon name="plus circle" />
                                                        Insert
                                                    </Button.Content>
                                                    <Button.Content hidden>
                                                        More&nbsp;
                                                        <Icon name="arrow right" />
                                                    </Button.Content>
                                                </Button>
                                            }
                                            content="Add more Supplements(s)."
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Segment>
                        {this.state.showAllergeanSelectBox &&

                            <Segment className="transparent" style={{ width: "100%" }}>
                                <Grid columns="equal">
                                    <CustomDivider
                                        h_size="h3"
                                        title="Allergens"
                                        icon_name="cart plus"
                                    />

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Grid.Column >
                                                <Form.Field>
                                                    <Popup
                                                        trigger={<label>Add Allergens</label>}
                                                        content="Allergens."
                                                    />
                                                    <Multiselect
                                                        options={this.state.allergeanData}
                                                        onSelect={this.onSelectAllergen}
                                                        selectedValues={this.state.allergeanList}
                                                        onRemove={this.onRemoveAllergean}
                                                        ref={this.multiselectRef}
                                                        displayValue="name"
                                                        placeholder={this.state.allergeanList.length > 0 ? "" : "Select Allergens"}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        }

                        <Segment className="transparent" style={{ width: "100%", display: this.state.showStore ? 'block' : 'none' }}>
                            <Grid columns="equal">
                                <CustomDivider
                                    h_size="h3"
                                    title="Benefits"
                                    icon_name="cart plus"
                                />
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Popup
                                                trigger={<label>Benefits</label>}
                                                content="Benefits."
                                            />
                                            <Multiselect
                                                options={this.state.multiSelectData}
                                                onSelect={this.onSelect}
                                                selectedValues={this.state.benefit}
                                                onRemove={this.onRemove}
                                                displayValue="name"
                                                placeholder={this.state.benefit.length > 0 ? "" : "Select Tags"}
                                                disable = {true}
                                                disablePreSelectedValues = {true}
                                                style={{
                                                    chips: {
                                                      opacity:1
                                                    },
                                                    multiselectContainer: {
                                                        opacity:1 
                                                    }
                                                }}

                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                </Grid.Row>

                            </Grid>
                        </Segment>
                        <Grid.Row  style={{ display: this.state.showP ? 'block' : 'none' }}>
                                            <Grid.Column style={{ marginBottom: "10px" }}>
                                                <Grid textAlign="center">
                                                    <Button.Group>
                                                        <Popup
                                                            trigger={
                                                                <Button onClick={this.updateSubmitClick} positive>
                                                                    Submit
                                                                </Button>
                                                            }
                                                            content="Update Inventory."
                                                        />
                                                    </Button.Group>
                                                </Grid>
                                            </Grid.Column>

                            </Grid.Row>

                    </Grid>

                       
                </Segment>

            </>
        );
    }
}

export default InventoryManagement;
