import React, { Component } from "react";
import "./flore.css";
import axios from "axios";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { baseURL } from "../../../configuration/constants";
import { getHeaders } from "../../helper";
import { toast } from 'react-toastify';
export { response_from_delete_api }

var select_deselect_tag = [];
var temp_selected_tag = [];
var selected_tag_ids_array = [];
var delete_array = [];
let response_from_delete_api = [];
export const funciton_alert = (a, callback) => {
	let tags = [];
	if (delete_array.length == 0) {
		toast.info("Please select at least one tag");
		return;
	}
	delete_array.forEach(elem => {
		let tagIds = elem.split(' ');
		tags.push(tagIds[0]);
	})
	let data = { tags: tags }
	axios
		.post(baseURL + "/formulation-tags-delete", data, getHeaders())
		.then(res => {
			if (res.data.success) {
				setTimeout(() => {
					response_from_delete_api = [];
				}, 4000);
				response_from_delete_api = res.data.data;
				callback();
			}
		})
}
class FloreTagRenderer extends Component {
	constructor(props) {
		super(props);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleCloseTooltip = this.handleCloseTooltip.bind(this);
		this.state = {
			tagList: [],
			cpyBtnText: "Copy Tags",
			tag_ids: [],
			current: 0
		};
	}

	componentDidMount = () => {
		this.handleTagData();
	};
	handleCopy = () => {
		this.setState({ cpyBtnText: "Tags Copied!" });
		navigator.clipboard.writeText(this.state.tagList.join(", "));
	};
	handleCloseTooltip = () => {
		this.setState({ cpyBtnText: "Copy Tags" });
	};
	changeTagSelection = (value) => {
		var demo = [];
		var selected_tag = [...this.state.selected_tag_ids_array]
		var values = value[0].split(",");
		var temp_selected_array = values[values.length - 1];

		if (selected_tag.indexOf(`${values[0]} ${values[1]}`) > -1) {
			var x = selected_tag_ids_array.filter(item => item !== `${values[0]} ${values[1]}`);
			selected_tag_ids_array = x;
			this.setState({ selected_tag_ids_array });
			this.props.collect_changes({
				action: "deSelection_tag",
				data: [`${values[0]} ${values[1]}`],
				message: "Are you sure you want to undo your previous action? "
			})

			this.props.on_select_deselect(selected_tag_ids_array);
		}

		else {
			select_deselect_tag.map((item) => {
				if (item.id == values[0] && item.inventory_id == values[1] || item.tag_name == temp_selected_array) {
					if (selected_tag_ids_array.indexOf(`${item.id} ${item.inventory_id}`) === -1) {
						demo.push(`${item.id} ${item.inventory_id}`);
						selected_tag_ids_array.push(`${item.id} ${item.inventory_id}`);
					}
				}
			})

			this.props.collect_changes({
				action: "selection_tag",
				data: demo,
				message: "Are you sure you want to undo your previous action? "
			})
			this.props.on_select_deselect(selected_tag_ids_array);
		}
	};

	handleTagData = () => {
		let tagList = [];
		this.props.data.value.forEach((element) => {
			tagList.push(element);
			if (select_deselect_tag.indexOf(`${element.id} ${element.inventory_id}`) === -1) {
				select_deselect_tag.push({
					id: `${element.id}`,
					inventory_id: `${element.inventory_id}`,
					tag_name: `${element.tag_name}`
				});
			}

		});
		selected_tag_ids_array = this.props.selected_ids;
		delete_array = selected_tag_ids_array;
		this.setState({ selected_tag_ids_array });
		this.setState({ tagList: tagList });
	};

	render() {
		let tagWholeList = this.state.tagList.map((tag, index) => {
			return (
				<ToggleButton
					selected={this.state.selected_tag_ids_array.includes(`${tag.id} ${tag.inventory_id}`) ? true : false}
					value={`${tag.id},${tag.inventory_id},${tag.tag_name}`}
					key={tag.id}>
					{tag.tag_name}
				</ToggleButton>
			);
		});
		return (
			<div style={{ height: 300 }}>
				<div className="btn-grp-prime">
					<ToggleButtonGroup
						onFocus={this.onFocus}
						value={this.state.current}
						size="small"
						onChange={(event, new_tag_id) => {
							this.changeTagSelection(new_tag_id)
						}} >
						{tagWholeList}
					</ToggleButtonGroup>
				</div>
			</div >
		);
	}
}

export default FloreTagRenderer;
