//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: StartFormulation                                    //
// SubSubSubModule: SummaryInfo                                      //
// Tasks: 1) Receives and displays SummaryInfo                       //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import { Input, Grid, Form, Popup } from "semantic-ui-react";
import Config from "../../../configuration/config";
import DatePicker from "react-datepicker";
import CustomDivider from "../../custom_elements/CustomDivider";
import { getDateString } from "../../helper";
import React from "react";

const SummaryInfo = props => {
  return (
    <React.Fragment>
      <CustomDivider h_size="h3" title="Summary" icon_name="info circle" />
      <Grid.Row>
        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup trigger={<label>Sample ID </label>} content="Sample ID." />
            <Input
              className="full_opacity"
              disabled
              value={props.sample_id}
              icon="hashtag"
              iconPosition="left"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup trigger={<label>Formula ID </label>} content="Formula ID." />
            <Input
              className="full_opacity"
              disabled
              value={props.formula_id}
              icon="hashtag"
              iconPosition="left"
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup
              trigger={<label>Number of Months</label>}
              content="Number of Months."
            />

            <Input
              className="full_opacity"
              disabled
              name="number_of_months"
              value={props.number_of_months}
              icon="calendar alternate outline"
              iconPosition="left"
              placeholder="Number of Months"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup
              trigger={<label>Enter the Date Made</label>}
              content="Date Made"
            />

            <DatePicker
              // selected={props.date_made ? true : false}
              placeholderText="Click to select a date"
              todayButton={"Current Date"}
              dateFormat="MM/dd/yyyy"
              id="date_made"
              name="date_made"
              disabled={(!props.permission)}
              value={props.date_made}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              clearable={true}
              openToDate={new Date(Config.default_calendar_date)}
              onChange={date => {
                props.onModalValueChange_Date(null, {
                  name: "date_made",
                  value: getDateString(date)
                });
              }}
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup
              trigger={<label>Date Created</label>}
              content="Date Created"
            />

            <Input
              id="date_created"
              name="date_created"
              className="full_opacity"
              value={props.date_created}
              disabled
              style={{ color: "red", opacity: 1 }}
              icon="warning sign"
              iconPosition="left"
              placeholder="Formulation Factor"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup
              trigger={<label>Formulation Factor</label>}
              content="Formulation Factor."
            />

            <Input
              name="formulation_factor"
              className="full_opacity"
              value={props.formulation_factor}
              disabled
              style={{ color: "red", opacity: 1 }}
              icon="warning sign"
              iconPosition="left"
              placeholder="Formulation Factor"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      {props.page_type &&(
        <Grid.Row>
        <Grid.Column>
          <Form.Field required fluid="true">
            <Popup trigger={<label>Pin </label>} content="Pin" />
            <Input
              className="full_opacity"
              id="pin"
              name="pin"
              onChange={(e)=>props.onChange(e)}              
              value={props.pin}
              icon="hashtag"
              iconPosition="left"
              type="password"
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column>
          <Form.Field fluid="true">
            <Popup trigger={<label>User Name </label>} content="Pin" />
            <Input
              id="full_name"              
              className="full_opacity positive"
              value={props.user_name}
              disabled
              style={{ color: "red", opacity: 1 }}
              iconPosition="left"             
              placeholder="Username"
              icon="check circle"
            />           
          </Form.Field>
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>
    )}
      
    </React.Fragment>
  );
};

export default SummaryInfo;
