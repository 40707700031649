
import React, { Component } from "react";
import Config from "../../configuration/config";

import {
    Header,
    Message,
    Grid,
    Segment,
    Form
} from "semantic-ui-react";

import CreatePrebiotic from "./CreatePrebiotic";
import ExistPrebiotic from "./ExistingPrebioticTable";
import CreateProbiotic from "./CreateProbiotic";
import ExistProbiotic from "./ExistingProbioticTable";
import PremadeFlourishFormulla from "./PremadeFlourishFormula";
import PremadePrimeFormulla from "./PremadePrimeFormula";
import ExistFlourish from "./ExistingFlourish";
import ExistPrime from "./ExistingPrimeTable";

import {
    getHeaders
} from "../helper";
import {
    baseURL
} from "../../configuration/constants";
import axios from "axios";

const options = [
    { value: "Select" },
    { value: "Create Prebiotic" },
    { value: "Create Probiotic" },
    { value: "Create Premade Prime Formula" },
    { value: "Create Premade Flourish Formula" }
];


const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
};
const isAvailable = [
    { value: "Not Available" },
    { value: "Available" },
    { value: "Quarantined" },
    { value: "Sample"}
];
const isAvailable_Value = [
    { value: "Not Available", data: 0 },
    { value: "Available", data: 1 },
    { value: "Quarantined", data: 2 },
    { value: "Sample", data: 3 }
];

const optionsPrebiotic = [
    { value: "Select" },
    { value: "New Prebiotic" },
    { value: "Existing Prebiotic" }
];

const optionsProbiotic = [
    { value: "Select" },
    { value: "New Probiotic" },
    { value: "Existing Probiotic" }
];

const optionsPrime = [
    { value: "Select" },
    { value: "New Premade Prime" },
    { value: "Existing Premade Prime" }
];

const optionsFlourish = [
    { value: "Select" },
    { value: "New Premade Flourish" },
    { value: "Existing Premade Flourish" }
];


class CreateNewInventory extends Component {
    state = {
        benefits: "",
        samples: [],
        initialValue: "",
        showPrebiotic: false,
        showProbiotic: false,
        showPrime: false,
        showFlourish: false,

        showPrebioticForm: false,
        showProbioticForm: false,
        showPrimeForm: false,
        showFlourishForm: false,

        showPrebioticExisting: false,
        showProbioticExisting: false,
        showPrimeExisting: false,
        showFlourishExisting: false,
        selectedValue: ""
    }

    componentDidMount = () => {
        document.title = Config.Newinventory.section_title.display;
    }

    createAll = (e) => {
        this.setState({
            initialValue: e.target.value
        })
        if (e.target.value === "Create Prebiotic") {
            this.setState({
                showPrebiotic: true,
                showProbiotic: false,
                showPrime: false,
                showFlourish: false,

                showPrebioticForm: false,
                showProbioticForm: false,
                showPrimeForm: false,
                showFlourishForm: false,

                showPrebioticExisting: false,
                showProbioticExisting: false,
                showPrimeExisting: false,
                showFlourishExisting: false,
            })

        }
        if (e.target.value === "Create Probiotic") {
            this.setState({
                showPrebiotic: false,
                showProbiotic: true,
                showPrime: false,
                showFlourish: false,

                showPrebioticForm: false,
                showProbioticForm: false,
                showPrimeForm: false,
                showFlourishForm: false,

                showPrebioticExisting: false,
                showProbioticExisting: false,
                showPrimeExisting: false,
                showFlourishExisting: false,
            })
        }
        if (e.target.value === "Create Premade Prime Formula") {
            this.setState({
                showPrebiotic: false,
                showProbiotic: false,
                showPrime: true,
                showFlourish: false,

                showPrebioticForm: false,
                showProbioticForm: false,
                showPrimeForm: false,
                showFlourishForm: false,

                showPrebioticExisting: false,
                showProbioticExisting: false,
                showPrimeExisting: false,
                showFlourishExisting: false,
            })
        }
        if (e.target.value === "Create Premade Flourish Formula") {
            this.setState({
                showPrebiotic: false,
                showProbiotic: false,
                showPrime: false,
                showFlourish: true,

                showPrebioticForm: false,
                showProbioticForm: false,
                showPrimeForm: false,
                showFlourishForm: false,

                showPrebioticExisting: false,
                showProbioticExisting: false,
                showPrimeExisting: false,
                showFlourishExisting: false,
            })
        }
    }
    existing = (e) => {
        this.setState({
            selectedValue: e.target.value
        })
        if (e.target.value === "New Prebiotic") {
            this.setState({
                showPrebioticExisting: false,
                showPrebioticForm: true,
            })
        }
        if (e.target.value === "Existing Prebiotic") {
            axios
                .get(baseURL + "/barcode_Type_Based?type=pre", getHeaders())
                .then(res => {
                    let data = res.data;
                    return data;
                })
                .then(samples => {
                    this.setState({
                        samples: samples
                    });
                })
            this.setState({
                showPrebioticExisting: true,
                showPrebioticForm: false,
            })
        }

        if (e.target.value === "New Probiotic") {
            this.setState({
                showProbioticExisting: false,
                showProbioticForm: true,
            })
        }
        if (e.target.value === "Existing Probiotic") {
            this.setState({
                showProbioticExisting: true,
                showProbioticForm: false,
            })
        }

        if (e.target.value === "New Premade Flourish") {
            this.setState({
                showFlourishExisting: false,
                showFlourishForm: true,
            })
        }
        if (e.target.value === "Existing Premade Flourish") {
            axios
                .get(baseURL + "/barcode_Type_Based?type=for", getHeaders())
                .then(res => {
                    let data = res.data;
                    return data;
                })
                .then(samples => {
                    this.setState({
                        samples: samples
                    });
                })
            this.setState({
                showFlourishExisting: true,
                showFlourishForm: false,
            })
        }

        if (e.target.value === "New Premade Prime") {
            this.setState({
                showPrimeExisting: false,
                showPrimeForm: true,
            })
        }
        if (e.target.value === "Existing Premade Prime") {
            this.setState({
                showPrimeExisting: true,
                showPrimeForm: false,
            })
            axios
                .get(baseURL + "/barcode_Type_Based?type=prm", getHeaders())
                .then(res => {
                    let data = res.data;
                    return data;
                })
                .then(samples => {
                    this.setState({
                        samples: samples
                    });
                })
        }
    }

    blur = () => {
        this.setState({
            selectedValue: "select"
        })
    }

    render() {
        const { initialValue, showPrebiotic, selectedValue, showProbiotic, showPrime, showFlourish, samples,
            showPrebioticForm, showPrebioticExisting, showProbioticForm, showProbioticExisting, benefits,
            showFlourishForm, showFlourishExisting, showPrimeForm, showPrimeExisting } = this.state;
        return (
            <React.Fragment>
                <br />
                <Message>
                    <Header as="h2" textAlign="center">
                        {Config.Newinventory.section_title.create}
                    </Header>
                </Message>

                <Segment className="transparent">
                    <Grid.Row>
                        <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column>
                                    <label style={{ fontWeight: "bold", fontSize: "15px" }}>Select Type</label>
                                    <Form.Field fluid="true" style={{ marginTop: "7px" }}>
                                        <select onChange={this.createAll}
                                            style={{ height: "34px", border: "1px solid gainsboro" }}
                                            value={initialValue}
                                            onClick={this.blur}
                                        >
                                            {
                                                options && options.map((elem, i) => {
                                                    return (
                                                        <option key={i}>
                                                            {elem.value}
                                                        </option>

                                                    )
                                                })
                                            }
                                        </select>

                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column style={{ display: showPrebiotic ? 'block' : 'none' }}>
                                    <label style={{ fontWeight: "bold", fontSize: "14px" }}> Select Prebiotic Type</label>
                                    <Form.Field fluid="true" style={{ marginTop: "7px" }}>
                                        <select onChange={this.existing}
                                            value={selectedValue}
                                            style={{ height: "34px", border: "1px solid gainsboro", width: "64%" }}
                                        >
                                            {
                                                optionsPrebiotic && optionsPrebiotic.map((elem, i) => {
                                                    return (
                                                        <option key={i}>
                                                            {elem.value}
                                                        </option>

                                                    )
                                                })
                                            }
                                        </select>

                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column style={{ display: showProbiotic ? 'block' : 'none' }}>
                                    <label style={{ fontWeight: "bold", fontSize: "15px" }}> Select Probiotic Type</label>
                                    <Form.Field fluid="true" style={{ marginTop: "7px" }}>
                                        <select onChange={this.existing}
                                            value={selectedValue}
                                            style={{ height: "34px", border: "1px solid gainsboro", width: "64%" }}
                                        >
                                            {
                                                optionsProbiotic && optionsProbiotic.map((elem, i) => {
                                                    return (
                                                        <option key={i}>
                                                            {elem.value}
                                                        </option>

                                                    )
                                                })
                                            }
                                        </select>

                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column style={{ display: showPrime ? 'block' : 'none' }}>
                                    <label style={{ fontWeight: "bold", fontSize: "15px" }}> Select the Premade Prime Type</label>
                                    <Form.Field fluid="true" style={{ marginTop: "7px" }}>
                                        <select onChange={this.existing}
                                            value={selectedValue}
                                            style={{ height: "34px", border: "1px solid gainsboro", width: "64%" }}
                                        >
                                            {
                                                optionsPrime && optionsPrime.map((elem, i) => {
                                                    return (
                                                        <option key={i}>
                                                            {elem.value}
                                                        </option>

                                                    )
                                                })
                                            }
                                        </select>

                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column style={{ display: showFlourish ? 'block' : 'none' }}>
                                    <label style={{ fontWeight: "bold", fontSize: "15px" }}> Select the Premade Flourish Type</label>
                                    <Form.Field fluid="true" style={{ marginTop: "7px" }}>
                                        <select onChange={this.existing}
                                            value={selectedValue}
                                            style={{ height: "34px", border: "1px solid gainsboro", width: "64%" }}
                                        >
                                            {
                                                optionsFlourish && optionsFlourish.map((elem, i) => {
                                                    return (
                                                        <option key={i}>
                                                            {elem.value}
                                                        </option>

                                                    )
                                                })
                                            }
                                        </select>

                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                        </Grid>

                    </Grid.Row>
                </Segment>

                <div style={{ display: showPrebioticForm ? 'block' : 'none' }}>
                    <CreatePrebiotic
                        benefits={benefits}
                        isAvailable_Value={isAvailable_Value}
                        inventory={inventory}
                        isAvailable={isAvailable}
                    />
                </div>
                <div style={{ display: showPrebioticExisting ? 'block' : 'none' }}>
                    <ExistPrebiotic
                        samples={samples}
                        isAvailable_Value={isAvailable_Value}
                        inventory={inventory}
                        isAvailable={isAvailable}
                    />
                </div>
                <div style={{ display: showProbioticForm ? 'block' : 'none' }}>
                    <CreateProbiotic
                        isAvailable_Value={isAvailable_Value}
                        inventory={inventory}
                        isAvailable={isAvailable}
                    />
                </div>
                <div style={{ display: showProbioticExisting ? 'block' : 'none' }}>
                    <ExistProbiotic
                        samples={samples}
                        inventory={inventory}
                        isAvailable={isAvailable}
                    />
                </div>
                <div style={{ display: showPrimeForm ? 'block' : 'none' }}>
                    <PremadePrimeFormulla />
                </div>
                <div style={{ display: showPrimeExisting ? 'block' : 'none' }}>
                    <ExistPrime samples={samples} />
                </div>


                <div style={{ display: showFlourishForm ? 'block' : 'none' }}>
                    <PremadeFlourishFormulla />
                </div>
                <div style={{ display: showFlourishExisting ? 'block' : 'none' }}>
                    <ExistFlourish samples={samples} />
                </div>


            </React.Fragment>
        );
    }
}

export default CreateNewInventory;