
import CustomDivider from "../../custom_elements/CustomDivider";
import Organisms from "./organisms";

import React, { Component } from "react";
import { Form, Input, Grid, Popup, Button, Segment } from "semantic-ui-react";

class FlourishFormulation extends Component {
  state = {};

  handleChange = (e) => {
    this.props.selectCapsuleSizeFunction(e.target.value)
  }


  render() {
    return (
      <React.Fragment>
        <Segment className="transparent">
          <Grid columns="equal">
            <CustomDivider h_size="h3" icon_name="flask" title="Flourish" />
            <Grid.Row>
              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Capsule Size</label>}
                    content="Enter the Capsule Size."
                  />
                  <select onChange={this.handleChange}
                    disabled={this.props.is_number_of_month_disabled}
                    value={this.props.flourish_capsule_size}>
                    {
                      this.props.options && this.props.options.map((elem, i) => {
                        return (
                          <option key={i}>
                            {elem.value}
                          </option>
                        )
                      })
                    }
                  </select>

                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Number of Months (Ref.)</label>}
                    content="Reference to the Number of Months."
                  />
                  <Input
                    name="number_of_months"
                    disabled
                    className="full_opacity"
                    onChange={this.props.onChange}
                    value={this.props.number_of_months}
                    icon="calendar alternate outline"
                    iconPosition="left"
                    placeholder="Number of Months"
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Formulation Factor (Ref.)</label>}
                    content="Enter the Formulation Factor."
                  />
                  <Input
                    name="formulation_factor"
                    disabled
                    className="full_opacity"
                    onChange={this.props.onChange}
                    value={this.props.formulation_factor}
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Formulation Factor"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Button style={{
              cursor: "pointer",
              marginBottom: "1%",
              marginLeft: "1rem",
              display: !this.props.formualtion_ai ? "block" : "none"
            }}
              onClick={this.props.predict_ingredient}>
              Predict Ingredients
            </Button>
            <Grid.Row>
              <Grid.Column>
                <Organisms
                  show_dosage_btn={this.props.show_dosage_btn}
                  organisms={this.props.organisms}
                  organism_barcode={this.props.organism_barcode}
                  inventory_id={this.props.inventory_id}
                  amount_added={this.props.amount_added}
                  concentration={this.props.concentration}
                  final_concentration={this.props.final_concentration}
                  scrap_amount={this.props.scrap_amount}
                  final_amount_added={this.props.final_amount_added}
                  total_cfu={this.props.total_cfu}
                  disabledPermission={this.props.disabledPermission}
                  tags={this.props.tags}
                  multiSelectData={this.props.multiSelectData}
                  //methods
                  onChange={this.props.onChange}
                  onChangeDataUp={this.props.onChangeDataUp}
                  onAddOrganismClick={this.props.onAddOrganismClick}
                  onDeleteOrganismClick={this.props.onDeleteOrganismClick}
                  changeStructureFunction={this.props.changeStructureFunction}
                  onSelect={this.props.onSelect}
                  multiselectRef={this.props.multiselectRef}

                  //json
                  inventory_options={this.props.inventory_options}
                  available_inventory_json={this.props.available_inventory_json}
                  onSelectAfterInsert={this.props.onSelectAfterInsert}
                  predict_dosage={this.props.predictDosage}
                  formualtion_ai={this.props.formualtion_ai}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </React.Fragment>
    );
  }
}

export default FlourishFormulation;
