//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: StartFormulation                                    //
// SubSubSubModule: FlourishOrganism                                 //
// Tasks: 1) Receives and displays FlourishOrganism                  //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React from "react";
import { Table, Icon, Popup } from "semantic-ui-react";

let colour;

const FlourishOrganism = (props) => {
  if (props.sortQuantity < 0) {
    colour = "red";
  }
  if (props.sortQuantity >= 0) {
    colour = "green";
  }

  return (
    <Table.Row>
      <Table.Cell width={4}>
        <Icon name="dna" />
        {props.organism_barcode}
        &nbsp;
        <div className="alg-info">
        {props.allergen.length>0 &&<Icon name="info"/>}
        
        <div className="alg-info-box">
                    <ul>
                    {props.allergen &&
          props.allergen.map((allergenName) => {
            return (<li><span className="alg_icon"><img src={allergenName.image} style={{width: "30px", height: "auto"}} alt="" srcset="" /> </span>
            <span className="alg_text">{allergenName.allergen}</span></li>);
                    })}
                    </ul>
                  </div>
        </div>
</Table.Cell>
      <Table.Cell width={3}>{props.final_amount_added.toFixed(1)} g</Table.Cell>
      <Table.Cell
        width={4}
        style={{ color: colour }}
      >
        {props.sortQuantity} g
      </Table.Cell>
      <Table.Cell width={4}>{props.sortLot} g</Table.Cell>
    </Table.Row>
  );
};

export default FlourishOrganism;
