import  axios  from 'axios';
import { baseURL } from "../configuration/constants";
import {  getHeaders } from "./helper";

export function postRequest(url,params){
return new Promise((resolve, reject) => {
        axios
        .post(baseURL + url, params, getHeaders())
        .then((res) => { resolve(res);  })
        .catch((err)=> reject(err))

    });
}