import React from 'react';

import { Grid } from "semantic-ui-react";

export default (props) => {
  return (
    <section style={{ display: "flex", justifyContent: "center" }}>
      <Grid>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <section className="pdf-body">
                {props.children}
              </section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid>

    </section>

  )
}