//-------------------------------------------------------------------//
// Module: ColumnDefs                                                //
// SubModule: FormulationColumnDefs                                  //
// Tasks: 1) Returns an Array of Column Defs                         //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import { getSessionStoredJsonItem } from "../helper";

export function getFormulationsColumnDefsArray(
  converted_user_options_json,
  converted_user_name_options_json,
  all_user_ids,
  all_user_name,
  dateComparator
) {
  let role_name = getSessionStoredJsonItem("user").role_name;
  let is_admin = role_name === "admin";
  let is_manager = role_name === "manager";
  let editablePermission = localStorage.getItem("allFormulation");

  let is_chrome = sessionStorage.getItem("is_chrome") === true;

  return [
    {
      headerName: "id",
      field: "organism_id",
      hide: true
    },
    {
      headerName: "inventory_id",
      field: "inventory_id",
      hide: true
    },
    {
      headerName: "organisms_id",
      field: "organisms_id",
      hide: true
    },
    // {
    //   headerName: "user_Id",
    //   field: "user_Id",
    //   hide: true,

    // },


    //formulation info
    {
      headerName: "Formulation Info",
      children: [
        {
          headerName: "Sample ID",
          field: "sample_id",
          filter: "agTextColumnFilter",
          pinned: "left",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          // editable: editablePermission ? true : false,
          editable: false,
          width: 145
        },

        {
          headerName: "Formula ID",
          field: "formula_id",
          filter: "agTextColumnFilter",
          pinned: "left",
          editable: false,
          width: 130
        },
        {
          headerName: "Product Type",
          field: "product_type",
          editable: false,
          pinned: "left",
          filter: true,
          width: 120,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: ["empty","flore", "flore_tots","asu_study","test_only","upgraded_test_only","monthly_flore","monthly_tots"]
          },
          refData: { "empty":"Empty","flore": "Flore", "flore_tots": "Flore tots","asu_study":"asu study","test_only": "test only"
        ,"upgraded_test_only" : "Upgraded test only","monthly_flore" : "Monthly flore","monthly_tots" : "Monthly tots"},
          width: 135
        },
        {
          headerName: "Is Completed?",
          field: "is_completed",
          filter: false,
          pinned: "left",
          editable: false,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: [0, 1]
          },
          refData: { 0: "No", 1: "Yes" },
          width: 135
        },
        {
          headerName: "Number of Months",
          field: "number_of_months",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 150
        },
        {
          headerName: "Admin Notes",
          field: "admin_notes",
          editable: false,
          filter: "agTextColumnFilter",
          width: 150
        },
        {
          headerName: "Operator Notes",
          field: "operator_notes",
          editable: false,
          filter: "agTextColumnFilter",
          width: 150
        },

        {
          headerName: "Is Prime Added?",
          field: "is_prime",
          editable: false,
          filter: "agTextColumnFilter",
          width: 150
        },
        {
          headerName: "Dates",
          children: [
            {
              headerName: "Date Created",
              field: "date_created",
              filter: is_chrome ? "agDateColumnFilter" : false,
              cellFilter: 'date:"yyyy-mm-dd"',
              editable: false,
              comparator: dateComparator,
              width: 110
            },
            {
              headerName: "Date Made",
              field: "date_made",
              filter: is_chrome ? "agDateColumnFilter" : false,
              editable: false,
              comparator: dateComparator,
              cellFilter: 'date:"yyyy-mm-dd"',
              width: 110
            },
            {
              headerName: "Types",
              field: "formulation_type",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 120
            },
            {
              headerName: "Payment",
              field: "payment_type",
              editable: editablePermission ? true : false,
              filter: false,
              width: 120,
              cellEditor: "agSelectCellEditor",
              cellEditorParams: {
                values: ["empty","free", "paid"]
              },
              refData: { "empty":"-","free": "Free", "paid": "Paid"},
              width: 135
            },

          ]
        },
        {
          headerName: "Prime",
          children: [
            {
              headerName: "Flourish Amount Added",
              field: "prime_flourish_amount_added",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 180
            },
            {
              headerName: "Filler",
              field: "prime_filler_barcode",
              // type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 130
            },
            {
              headerName: "Filler Amount Added",
              field: "prime_filler_amount_added",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 160
            },
            {
              headerName: "Total Amount Added",
              field: "prime_total_amount_added",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 160
            },

            {
              headerName: "QC Weight",
              field: "prime_fill_qc_weight",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 150
            },
            {
              headerName: "Capsule Size",
              field: "prime_capsule_size",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 120
            },
            {
              headerName: "Total Prime Capsules Made",
              field: "prime_total_capsules_made",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 190
            },
            {
              headerName: "Prime Powder Remaining",
              field: "prime_powder_remaining",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 185
            }
          ]
        },
        {
          headerName: "Flourish",
          children: [
            {
              headerName: "Capsule Size",
              field: "flourish_capsule_size",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 120
            },
            {
              headerName: "QC Weight",
              field: "flourish_fill_qc_weight",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 130
            },
            {
              headerName: "Total Flourish Capsules Made",
              field: "flourish_total_capsules_made",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 210
            },
            {
              headerName: "Flourish Powder Remaining",
              field: "flourish_powder_remaining",
              type: "numericColumn",
              editable: false,
              filter: "agNumberColumnFilter",
              width: 190
            }
          ]
        },

        // {
        //   headerName: "Formulation Factor",
        //   field: "formulation_factor",
        //   type: "numericColumn",
        //   editable: false,
        //   filter: "agNumberColumnFilter",
        //   width: 150
        // },
        // {
        //   headerName: "Lot",
        //   field: "lot",
        //   type: "numericColumn",
        //   editable: editablePermission?true:false,
        //   filter: "agNumberColumnFilter",
        //   width: 70
        // }
      ]
    },

    //dates


    //prime


    //flourish


    //organism info
    {
      headerName: "Ingredient Info",
      children: [
        {
          headerName: "Ingredient Barcode",
          field: "organism_barcode",
          editable: false,
          filter: "agTextColumnFilter",
          pinned: "right",
          width: 130
        },
        // {
        //   headerName: "Amount Added",
        //   field: "amount_added",
        //   type: "numericColumn",
        //   editable: false,
        //   filter: "agNumberColumnFilter",
        //   width: 140
        // },
        {
          headerName: "Final Amount Added",
          field: "final_amount_added",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 155
        },
        {
          headerName: "Scrap Amount",
          field: "scrap_amount",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 140,
          cellRenderer:(params) =>{
            return(
              `
              <div>
              ${parseFloat(params.value).toFixed(3)}
              </div>
              `
            )
          }
        },
        {
          headerName: "Final Total CFU",
          field: "total_cfu",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 130
        },
        // {
        //   headerName: "Concentration",
        //   field: "concentration",
        //   type: "numericColumn",
        //   editable: false,
        //   filter: "agNumberColumnFilter",
        //   width: 150
        // },
        // {
        //   headerName: "Final Concentration",
        //   field: "final_concentration",
        //   type: "numericColumn",
        //   editable: false,
        //   filter: "agNumberColumnFilter",
        //   width: 150,
        //   cellRenderer:(params) =>{
        //     return(
        //       `
        //       <div>
        //       ${parseFloat(params.value).toFixed(3)}
        //       </div>
        //       `
        //     )
        //   }
        // }
      ]
    },
    {
      headerName: "Total Info",
      children: [
        {
          headerName: "Total Final Concentration",
          field: "total_final_concentration",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 180,
          cellRenderer:(params) =>{
            return(
              `
              <div>
              ${parseFloat(params.value).toFixed(3)}
              </div>
              `
            )
          }
        },
        // {
        //   headerName: "Total Amount Added",
        //   field: "total_amount_added",
        //   type: "numericColumn",
        //   editable: false,
        //   filter: "agNumberColumnFilter",
        //   width: 155
        // },
        {
          headerName: "Total Final Amount Added",
          field: "total_final_amount_added",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 190,
          cellRenderer:(params) =>{
            return(
              `
              <div>
              ${parseFloat(params.value).toFixed(3)}
              </div>
              `
            )
          }
        }
      ]
    },
    {
      headerName: "Logs",
      children: [
        {
          headerName: "Created At",
          field: "created_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 150
        },
        {
          headerName: "Last Updated At",
          field: "updated_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 150
        },
        {
          headerName: "Username",
          field: "full_name",
          filter: "agTextColumnFilter",
          editable: false,
          width: 120,
        },
        // {
        //   headerName: "Deleted At",
        //   field: "deleted_at",
        //   filter: is_chrome ? "agDateColumnFilter" : false,
        //   editable: false,
        //   comparator: dateComparator,
        //   width: 150
        // }
      ]
    },
  ];
}

function extractValues(mappings) {

  return Object.keys(mappings);
}

function lookupValue(mappings, key) {

  return mappings[key];
}

function lookupKey(mappings, name) {

  for (var key in mappings) {
    if (mappings.hasOwnProperty(key)) {
      if (name === mappings[key]) {

        return key;
      }
    }
  }
}

