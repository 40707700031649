import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
class TagColumnRenderer extends Component {
	constructor(props) {
		super(props);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleCloseTooltip = this.handleCloseTooltip.bind(this);
		this.state = {
			tagList: [],
			cpyBtnText: "Copy Tags",
		};
	}

	componentDidMount = () => {
		if (this.props.modulefield === "tag.tag_name") {
			this.handleFormulationTag()
		}
		else {
			this.handleTagData();
		}

	};

	handleCopy = () => {
		this.setState({ cpyBtnText: "Tags Copied!" });
		if (this.props.modulefield === "tag.tag_name") {
			navigator.clipboard.writeText(this.state.tagList);
		}
		else {
			navigator.clipboard.writeText(this.state.tagList.join(", "));
		}

	};
	handleCloseTooltip = () => {
		this.setState({ cpyBtnText: "Copy Tags" });
	};
	handleTagData = () => {
		let tagList = [];
		this.props.data.value.forEach((element) => {
			tagList.push(element.tag_name);
		});
		this.setState({ tagList: tagList });
	};
	handleFormulationTag = () => {
		this.setState({ tagList: this.props.data.value });
	}
	render() {
		let tagWholeList;
		if (this.props.modulefield !== "tag.tag_name") {
			tagWholeList = this.state.tagList.map((tag) => {
				return <li>{tag}</li>;
			});
		}

		return (
			<Tooltip
				onClose={this.handleCloseTooltip}
				title={
					<>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<button
								onClick={this.handleCopy}
								style={{
									cursor: "pointer",
									marginTop: "5px",
									padding: "5px",
								}}>
								{this.state.cpyBtnText}
							</button>

							{this.props.modulefield !== "tag.tag_name" ?

								<ul
									style={{
										listStylePosition: "inside",
										paddingLeft: 0,
									}}>
									{tagWholeList}
								</ul>
								:
								<ul
									style={{
										listStylePosition: "inside",
										paddingLeft: 0,
									}}>
									{this.state.tagList}
								</ul>
							}

						</div>
					</>
				}
				placement="left"
				arrow
				interactive>
				{this.props.modulefield !== "tag.tag_name" ?
					<span>{this.state.tagList.join(",").slice(0, 20)}...</span>
					:
					<span>{this.state.tagList}</span>
				}


			</Tooltip>
		);
	}
}

export default TagColumnRenderer;
