import React, { Component } from "react";
import { Message, Segment, Header, Grid, Accordion, Icon } from "semantic-ui-react";
import "../formulation_rationale/HealthSummary/index.css";

class ReformulationForm extends Component {
  constructor(props) { super(props); }

  render() {
    return (
      <>
        <Message
          className="formulation-rationale__health-survey__header"
          style={{ marginBottom: "0" }}
        >
          <Header as="h2" textAlign="center">
            Reformulation Info
          </Header>
        </Message>
        {/* when nothing is available */}

        {(this.props.reformulation_logs_exists == null && Object.keys(this.props.reformulation_logs).length === 0) && <Segment style={{ marginTop: "0", marginBottom: "0" }}
          className=
          {
            (this.props.component == "all_formulation") ?
              "formulation-rationale__health-survey__content mnh-auto"
              : (this.props.component == "reformulation")
                ? "formulation-rationale__health-survey " :
                "formulation-rationale__health-survey__content"
          }

        ></Segment>}


        {/* when reformaulation log available */}

        {(this.props.reformulation_logs_exists && this.props.reformulation_logs.length !== 0) &&
          <Segment
            className=
            {
              (this.props.component == "all_formulation") ?
                "formulation-rationale__health-survey__content mnh-auto"
                : (this.props.component == "reformulation") ? 
                "formulation-rationale__health-survey"
                 : "formulation-rationale__health-survey__content"
            }
            style={{
              marginTop: "0", marginBottom: "0",
            }}
          >
            <div style={{
              overflow: 'scroll-x',
              width: "100%"
            }}>
              <table style={{ width: '100%' }} className="ui celled structured table">
                <thead style={{ fontWeight: 'bold' }} >
                  <tr style={{ textAlign: "center" }}>
                    <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Organism Barcode</th>
                    <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Required Quantity</th>
                    <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Available Quantity </th>
                    <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Is Available?</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.reformulation_logs && this.props.reformulation_logs.map((data, index) => {
                    return ([
                      <tr style={{ textAlign: "center" }} key={index}>
                        <td className="center aligned" style={{ padding: "7px" }}>{data.organism_barcode}</td>
                        <td className="center aligned" style={{ padding: "7px" }}>{data.required_qty}</td>
                        <td className="center aligned" style={{ padding: "7px" }}>{data.available_qty}</td>
                        <td className="center aligned" style={{ padding: "7px" }}>{(data.is_available === 1) ? "Yes" : "No"}</td>
                      </tr>
                    ]
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Segment>
        }

        {/* when reformaulation form available */}
        {((this.props.reformulation_logs_exists !== null 
        && !this.props.reformulation_logs_exists) 
        && this.props.reformulation_logs.length !== 0) && (
          <Segment style={{ marginTop: "0", marginBottom: "0", maxHeight: ((this.props.component == "all_formulation") ? "250px" : "") }}
            className=
            {
              (this.props.component == "all_formulation") ?
                "formulation-rationale__health-survey__content mnh-auto"
                : (this.props.component == "reformulation")
                  ? "formulation-rationale__health-survey" :
                  "formulation-rationale__health-survey__content"
            }
          >
            <Grid>
              <Grid.Row className="block">

                <Grid.Column width={this.props.component == "all_formulation" ? 10 : 12}>
                  <b>Formula Id: </b>&nbsp;&nbsp;{this.props.reformulation_logs.formula_id}<br />
                  <b>Sample Id: </b>&nbsp;&nbsp;{this.props.reformulation_logs.sample_id}<br />
                  <b>No. of Months: </b>&nbsp;&nbsp;{this.props.reformulation_logs.no_of_months}<br />
                  {
                    this.props.reformulation_logs.reasons !== null &&
                    <div>
                      <b>Reason:</b> <br />
                      <ul >
                        {this.props.reformulation_logs.reasons.map((item) => {
                          return <li> {item} </li>
                        })}
                      </ul>
                    </div>
                  }
                </Grid.Column>
                {this.props.reformulation_logs.notes && <Grid.Column width={this.props.component == "all_formulation" ? 6 : 16} style={{ wordBreak: "break-word" }}>
                  <b>Notes: </b>&nbsp;&nbsp;{this.props.reformulation_logs.notes}
                </Grid.Column>}
              </Grid.Row>
            </Grid>
          </Segment>
        )
        }



      </>
    );
  }
}
export default ReformulationForm