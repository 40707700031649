//-------------------------------------------------------------------//
// Module: CustomDimmer                                              //
// Tasks: Display Semantic-UI specified Dimmer                       //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import { Dimmer, Icon, Header } from "semantic-ui-react";

class CustomDimmer extends Component {
  state = {};
  render() {
    return (
      <Dimmer
        page
        active={this.props.active}
        onClickOutside={this.props.onClickOutside}
      >
        <Header as="h2" icon inverted>
          <Icon name="frown" />
          {this.props.title}
          <Header.Subheader>
            {this.props.pre_content}
            <span style={{ color: "yellow", fontSize: "larger" }}>
              {this.props.content}
            </span>
            {this.props.post_content}
          </Header.Subheader>
        </Header>
      </Dimmer>
    );
  }
}

export default CustomDimmer;
