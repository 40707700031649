
import React, { Component } from "react";
import {
    Grid,
    Segment,
    Form,
    Input,
    Popup,
    Button
} from "semantic-ui-react";

const labelModalStyles = {
    fontSize: '13px',
};

const headingTitleStyles = {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '9px'
};

const selectModalStyles = {
    opacity: '0.6',
    width: '100%',
    padding: '5px 0',
    marginTop: '6px',
    display: 'inherit',
    border: '1px solid darkgray'
};

class FlourishPrimeInfo extends Component {

    handleChange = (e) => {
        this.props.selectCapsuleSizeFunction(e.target.value)
    }

    render() {
        return (
            <Segment>
                <Grid>
                    <div style={headingTitleStyles}>Total Info</div>
                    <Grid.Row className="block">
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true">
                                <Popup
                                    trigger={<label style={labelModalStyles}>Total Final Concentration</label>}
                                    content="Enter the Total Final Concentration."
                                />
                                <Input
                                    name="total_final_concentration"
                                    fluid
                                    placeholder="Total Final Concentration"
                                    value={this.props.total_final_concentration}
                                    disabled={true}
                                />
                            </Form.Field>
                        </Grid.Column>


                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Total Final Amount Added</label>}
                                    content="Enter the Total Final Amount Added."
                                />
                                <Input
                                    name="total_final_amount_added"
                                    fluid
                                    placeholder="Total Final Amount Added"
                                    value={this.props.total_final_amount_added === "" ? "" : Math.floor(this.props.total_final_amount_added * 1000) / 1000}
                                    disabled={true}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Formula Successful</label>}
                                    content="Formula Successful."
                                />
                                <Input style={{ font: "black" }}
                                    name="Success Information"
                                    placeholder="Formula Successful"
                                    fluid
                                    value={this.props.success === 1 ? "Yes" : ""}
                                    disabled={true}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <div style={headingTitleStyles}>Flourish</div>
                    <Grid.Row className="block" >
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true">
                                <Popup
                                    trigger={<label style={labelModalStyles}>QC Weight</label>}
                                    content="Enter the Qc Weight."
                                />
                                <Input
                                    name="flourish_fill_qc_weight"
                                    fluid
                                    placeholder="Fill Qc Weight"
                                    value={this.props.flourish_fill_qc_weight}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                    pattern="^\d*(\.\d{0,3})?$"
                                />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Capsule Size</label>}
                                    content="Enter the Capsule Size."
                                />
                                <select
                                    style={selectModalStyles}
                                    value={this.props.flourish_capsule_size}
                                    onChange={this.handleChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                >
                                    {
                                        this.props.options && this.props.options.map((elem, i) => {
                                            return (
                                                <option key={i}>
                                                    {elem.value}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Total Flourish Capsule Made</label>}
                                    content="Enter the Total Flourish Capsule Made."
                                />
                                <Input
                                    name="flourish_total_capsules_made"
                                    fluid
                                    placeholder="Total Flourish Capsule Made"
                                    value={this.props.flourish_total_capsules_made}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                    pattern="^\d*(\.\d{0,3})?$"
                                />
                            </Form.Field>
                        </Grid.Column>
                        <br />
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Flourish Powder Remaining</label>}
                                    content="Enter the Flourish Powder Remaining."
                                />
                                <Input
                                    name="flourish_powder_remaining"
                                    fluid
                                    placeholder="Flourish Powder Remaining"
                                    value={this.props.disabledPermissionOnEdit || this.props.flourish_powder_remaining}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.is_completed === "Yes" ? true : false}
                                    pattern="^\d*(\.\d{0,3})?$"
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <div style={headingTitleStyles}>Prime</div>
                    <Grid.Row className="block">
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Flourish Amount Added</label>}
                                    content="Enter the Flourish Amount Added."
                                />
                                <Input
                                    name="prime_flourish_amount_added"
                                    fluid
                                    placeholder="Flourish Amount Added"
                                    value={Math.floor(this.props.prime_flourish_amount_added * 1000) / 1000}
                                    disabled={true}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Filler</label>}
                                    content="Enter the Filler."
                                />
                                <Input
                                    name="filler_organism_barcode"
                                    fluid
                                    placeholder="Filler"
                                    value={this.props.filler_organism_barcode}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Filler Amount Added</label>}
                                    content="Enter the Filler Amount Added."
                                />
                                <Input
                                    name="prime_fill_prime_filler_amount_addedqc_weight"
                                    fluid
                                    placeholder="Filler Amount Added"
                                    value={Math.floor(this.props.prime_filler_amount_added * 1000) / 1000}
                                    disabled={true}
                                />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Total Amount Added</label>}
                                    content="Enter the Total Amount Added."
                                />
                                <Input
                                    name="prime_total_amount_added"
                                    fluid
                                    placeholder="Total Amount Added"
                                    value={Math.floor(this.props.prime_total_amount_added * 1000) / 1000}
                                    disabled={true}
                                />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4} style={{ paddingTop: "20px" }}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>QC Weight</label>}
                                    content="Enter the Fill Qc Weight."
                                />
                                <Input
                                    name="prime_fill_qc_weight"
                                    fluid
                                    placeholder="Fill Qc Weight"
                                    value={this.props.prime_fill_qc_weight}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                    pattern="^\d*(\.\d{0,3})?$"
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4} style={{ paddingTop: "20px" }}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Capsule Size</label>}
                                    content="Enter the Capsule Size."
                                />
                                <select
                                    style={selectModalStyles}
                                    value={this.props.prime_capsule_size}
                                    onChange={this.handleChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                >
                                    {
                                        this.props.options && this.props.options.map((elem, i) => {
                                            return (
                                                <option key={i}>
                                                    {elem.value}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4} style={{ paddingTop: "20px" }}>
                            <Form.Field required fluid="true">
                                <Popup
                                    trigger={<label style={labelModalStyles}>Total Prime Capsule Made</label>}
                                    content="Enter the Total Prime Capsule Made."
                                />
                                <Input
                                    name="prime_total_capsules_made"
                                    fluid
                                    placeholder="Total Prime Capsule Made"
                                    value={this.props.prime_total_capsules_made}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                    pattern="^\d*(\.\d{0,3})?$"
                                />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4} style={{ paddingTop: "20px" }}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Prime Powder Remaining</label>}
                                    content="Enter the Prime Powder Remaining."
                                />
                                <Input
                                    name="prime_powder_remaining"
                                    fluid
                                    placeholder="Prime Powder Remaining"
                                    value={this.props.prime_powder_remaining}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                    pattern="^\d*(\.\d{0,3})?$"
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Grid>
                                <Button.Group style={{ marginBottom: "12px" }}>
                                    <Popup
                                        trigger={
                                            <Button positive onClick={this.props.onSubmitsupplementBelow}>
                                                Submit
                                            </Button>
                                        }
                                        content="Submit the Data to the Database."
                                    />
                                </Button.Group>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}

export default FlourishPrimeInfo;
