//-------------------------------------------------------------------//
// Module: RoutingInfo                                               //
// Tasks: 1) Handles all the header clicks + Redirects               //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import CreateUser from "./user_mod/CreateUser";
import Login from "./login_mod/Login";
import CreateInventory from "./inventory_mod/createInventory/CreateInventory";
import FormulationRationale from "./formulation_rationale";
import CreateRefill from "./formulation_mod/CreateRefill";
import MainDisplayGrid from "./MainDisplayGrid";
import NotAuthorised from "./NotAuthorised";
import ProtectedRoute from "./ProtectedRoute";
import _404 from "./_404";
import CreateNewInventory from "./inventory_mod/CreateNewInventory";
import InventoryManagement from "./inventory_mod/InventoryManagement/InventoryManagement";
import SampleFormullaIdTable from "./inventory_mod/NewFormulation/SampleFormullaIdTable";
import CreateSupplementFacts from "./inventory_mod/supplement/CreateSupplementFacts";
import CreateAi from "./inventory_mod/ai/create-ai";
import MonthlyUsageInfo from "./inventory_mod/Monthly Usage Info/monthlyUsageinfo"
import CreateAiDashBoard from "./inventory_mod/ai/create-ai-dashboard";
import Pdf from "./formulation_mod/pdf";
import CreateGroup from "./user_mod/CreateGroup"
import ResetPassword from "./login_mod/ResetPassword";
import GetLogApi from "./log/get-logs";
import GetLogsTop from "./log/get-logs-top";
import CreateReformulation from "./formulation_mod/CreateReformulation";
import ManufactureQueNew from "../components/new_manufacture_queue/newManufactureQueue"
import ManageAllergens from "../components/inventory_mod/ai/allergens";
import MmrBpr from "./mmr_bpr/MmrBpr";
import React from "react";
import { Switch, Route } from "react-router-dom";
import FormulationAi from "./formulation_ai";
import QuantityAssurance from "./Quality Assurance/app";

class RoutingInfo extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path="/" render={() => <Login />} />
        <ProtectedRoute
          path="/Create_User"
          component={CreateUser}
          module_name="users"
        />
        <ProtectedRoute
          path="/display_users"
          component={MainDisplayGrid}
          key="users"
          module_name="users"
        />
        <ProtectedRoute
          path="/create_new_inventory"
          component={CreateNewInventory}
        />
        <ProtectedRoute
          path="/manage_supplement_facts"
          component={CreateSupplementFacts}
        />
        <ProtectedRoute
          path="/create_group"
          component={CreateGroup}
          key="users"
          module_name="users"
        />
        <ProtectedRoute
          component={CreateInventory}
          path="/create_inventory"
          module_name="inventory"
        />
        <ProtectedRoute
          component={InventoryManagement}
          path="/inventory_management"
        />
        <ProtectedRoute
          component={CreateAi}
          path="/create_flore_ai_success_marker"
        />
        <ProtectedRoute
          component={MonthlyUsageInfo}
          path="/monthly_usage_info"
        />
        <ProtectedRoute
          component={CreateAiDashBoard}
          path="/create_flore_ai_dashboard"
        />
        <ProtectedRoute
          component={ManageAllergens}
          path="/manage_allergens"
        />

        <ProtectedRoute
          path="/inventory"
          component={MainDisplayGrid}
          module_name="inventory"
          key="inventory"
        />
        <ProtectedRoute
          path="/create_formulation"
          module_name="formulations"
          component={FormulationRationale}
        />

        <ProtectedRoute
          path="/create_refill"
          module_name="formulations"
          component={CreateRefill}
        />
        <ProtectedRoute
          path="/formulation_ai"
          module_name="formulations"
          component={FormulationAi}
        />
        <ProtectedRoute
          path="/create_reformulation"
          module_name="formulations"
          component={CreateReformulation}
        />
        <ProtectedRoute
          path="/quality_assurance"
          module_name="formulations"
          component={QuantityAssurance}
        />
        <ProtectedRoute
          path="/display_formulationsOperator"
          component={ManufactureQueNew}
          key="formulations"
          module_name="formulations"
        />
        <ProtectedRoute
          path="/mmr_bpr"
          component={MmrBpr}
          key="formulations"
          module_name="formulations"
        />
        <ProtectedRoute
          path="/new_display_formulations"
          component={SampleFormullaIdTable}
          key="formulations"
          module_name="formulations"
        />
        {/* <ProtectedRoute
          path="/display_formulations"
          component={MainDisplayGrid}
          key="formulations"
          module_name="formulations"
        /> */}
        <ProtectedRoute
          path="/display_formulationsAdmin"
          component={MainDisplayGrid}
          key="formulationsAdmin"
          module_name="formulationsAdmin"
        />
        {/* <ProtectedRoute
          path="/display_formulationsOperator"
          component={MainDisplayGrid}
          key="formulationsOperator"
          module_name="formulationsOperator"
        /> */}
        <ProtectedRoute
          path="/Prebiotics_and_Probiotics_Management"
          module_name="formulations_organism"
          component={MainDisplayGrid}
        />
        <ProtectedRoute
          path="/Group_Management"
          module_name="userPermission"
          component={MainDisplayGrid}
        />
        <ProtectedRoute
          path="/get-logs-api"
          component={GetLogApi}
        />
        <ProtectedRoute
          path="/logs"
          component={GetLogsTop}
        />
        <ProtectedRoute
          path="/NotAuthorised"
          component={NotAuthorised}
        />
        <ProtectedRoute
          path="/pdf"
          component={Pdf}
        />
        {/* @TODO: Uncomment it when the ResetPassword is done. */}
        {/* <Route path="/reset_password/:token" component={ResetPassword} /> */}

        <Route path="/reset_password/:token" component={ResetPassword} />
        <Route path="*" component={_404} />
      </Switch>
    );
  }
}

export default RoutingInfo;
