import { baseURL } from "../../configuration/constants";
import axios from "axios";
import { getHeaders, getSessionStoredJsonItem, simplifyModulePermissionData } from "../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function getModulePermissionDefsArray(

  all_role_ids
) {
  let checkBoxperm = "";

  let role_name = getSessionStoredJsonItem("user").role_name;
  let permissionData = null;
  permissionData = simplifyModulePermissionData(all_role_ids);
  permissionData = permissionData.filter(data => {
    if (data.role_name === role_name) {
      return data;
    }

  })

  permissionData.forEach(details => {

    if (role_name == "admin") {

      checkBoxperm = true;
      return checkBoxperm;
    }
    else if (details.edit_permission == 1) {

      return checkBoxperm = true;

    }

  })



  return [
    {
      headerName: "Permission Id",
      field: "permissionId",
      hide: true
    },
    {
      headerName: "Module_ name",
      field: "module_name",
      // editable: checkBoxperm?true:false,
      filter: "agTextColumnFilter",
      pinned: "left",
      width: 300
      // checkboxSelection: false,
      // headerCheckboxSelection: true,
      // headerCheckboxSelectionFilteredOnly: true
    },
    {
      headerName: "View",
      field: "view_permission",
      filter: "agTextColumnFilter",
      width: 250,
      // editable: checkBoxperm?true:false,
      cellRenderer: params => {
        var input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = checkBoxperm ? false : true


        input.addEventListener('click', function (event) {
          if (input.checked === false) {
            params.value = 0;
          }
          else if (input.checked === true) {
            params.value = 1;
          }
          params.data.view_permission = params.value;
          let updateData = updatePermissiondb(params);
        });

        if (params.data.module_name == "Create Group") {

          return "-";
        }
        else {
          input.disabled = checkBoxperm ? false : true
          return input;
        }
      },

    },
    {
      headerName: "Edit",
      field: "edit_permission",
      filter: "agTextColumnFilter",
      width: 250,
      //editable: checkBoxperm?true:false,
      cellRenderer: params => {
        var input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = checkBoxperm ? false : true


        input.addEventListener('click', function (event) {
          if (input.checked === false) {
            params.value = 0;
          }
          else if (input.checked === true) {
            params.value = 1;
          }
          params.data.edit_permission = params.value;
          let updateData = updatePermissiondb(params);
        });

        return input;
      },
    }
    , {
      headerName: "Delete",
      field: "delete_permission",
      filter: "agTextColumnFilter",
      width: 250,
      //editable: checkBoxperm?true:false,
      cellRenderer: params => {

        var input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;

        input.addEventListener('click', function (event) {
          if (input.checked === false) {
            params.value = 0;
          }
          else if (input.checked === true) {
            params.value = 1;
          }
          params.data.delete_permission = params.value;
          let updateData = updatePermissiondb(params);
        });
        if (params.data.module_name == "All Formulations" || params.data.module_name == "Manufacturing Queue" || params.data.module_name == "User Management" || params.data.module_name == "Display Inventory" || params.data.module_name === "Group Management"
          || params.data.module_name == "Manage Supplement Facts") {

          input.disabled = checkBoxperm ? false : true

          return input;
        }
        else {

          return "-";
        }

      },
    }
  ];
}
function updatePermissiondb(permissionData) {
  let res = permissionData.data;
  let id = res.permissionId;

  axios
    .put(
      baseURL +
      "/users/updatePermissionDetails/" +
      id,
      res,
      getHeaders(),
    )
    .then(response => {
      toast.success("Permission updated");
      if (!response.data.success) {
        alert("Updating failed! Please contact Admin.");

      }

    })
    .catch(error => {
      alert("Updating from Display Grid | Error: ", error);
    });

}
