import React, { Component } from "react";
import { Message, Segment, Header, Accordion, Icon } from "semantic-ui-react";
import axios from "axios";
import { portalURL } from "../../../configuration/constants";
import Config from '../../../configuration/config';
import "./index.css";
class HealthSummary extends Component {
  state = {
    survey: [],
    activeIndexs: [0]
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndexs } = this.state;

    const newIndex = [];

    const currentIndexPosition = activeIndexs.indexOf(index);
    var x = false;
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
      x = true;
    }
    if (!x) {
      newIndex.push(index);
    }
    this.setState({ activeIndexs: newIndex });
  };
  componentDidUpdate(prevProps) {
    let selectedSample = this.props.selectedSample;
    let previousSelectedSampleSGNumber = prevProps.selectedSample.sg_number;
    let selectedSampleSGNumber = selectedSample.sg_number;
    let url;

    if (previousSelectedSampleSGNumber !== selectedSampleSGNumber) {
      this.setState({
        is_page_loading: true,
      })


      url = `${portalURL}/getHealthDietSurvey`;

      let payload = {
        sg_number: selectedSampleSGNumber,
        token: Config.token
      };
      axios.post(url, payload).then(res => {
        if(res.data.data.length>0) this.props.getAllergenInfo_healthSurvey(res.data.allergy_info);
        if (res.data.data == [] || res.data.data == "") {
          this.setState({
            survey: []
          })
        }
        else {
          let surveyData = res.data.data;
          if (surveyData.length > 0) {
            this.setState(
              {
                survey: surveyData,
                is_page_loading: false,
              },
              );
          }
        }
      });
    }
  }

  generateSurveyText = (activeIndexs) => {
    return (
      <div>
        {this.state.survey.length > 0 && this.state.survey.map((item, index) => {
          return <Accordion>
            <Accordion.Title
              active={activeIndexs.includes(0)}
              index={index}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              {item.sg_number}
            </Accordion.Title>
            <Accordion.Content active={activeIndexs.includes(index)}>
              <ol style={{ marginTop: "-3px" }}>

                {item.survey_data.map((item, index) => (
                  <li
                    key={index}
                    className="formulation-rationale__health-survey__item"
                  >
                    <b>{item.headings[0].heading}</b>
                    {item.family === "open_ended" ? (
                      <p>{item.answers}</p>
                    ) : (item.family === "matrix" ? (
                      <div>
                        {item.answers.map((answer, index) => (
                          <div key={index}>
                            <p>{answer.choicesVal}</p>
                          </div>
                        ))}
                      </div>
                    ) : (<div> {item.answers.map((answer, index) => (<div key={index}> <p>{answer.text}</p> </div>))}
                    </div>))}
                  </li>
                ))}
              </ol>
            </Accordion.Content>
          </Accordion>
        })}

      </div>
    );
  };

  render() {
    const { activeIndexs } = this.state;
    let surveyText = this.generateSurveyText(activeIndexs);
    return (
      <>
        <Message
          className="formulation-rationale__health-survey__header"
          style={{ marginBottom: "0" }}
        >
          <Header as="h2" textAlign="center">
            Health Survey
          </Header>
        </Message>
        <Segment
          style={{
            marginTop: "0",
            marginBottom: "0",
            height: this.props.h_d_survey_height
          }}
          className="formulation-rationale__health-survey__content"
        >
          {surveyText}
        </Segment>
      </>
    );
  }
}

export default HealthSummary;
