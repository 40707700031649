import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html,Value) => {
    savePDF(html, { 
      // paperSize: 'Letter',
      fileName: Value + "_label.pdf",
      margin: 1
    })
  }
}

const Doc = new DocService();
export default Doc;