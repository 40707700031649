//----------------------------------------------------------------------//
// Module: Constants                                                    //
// Tasks: 1) Contains Constants                                         //
// Author: Yash P Shah                                                  //
//----------------------------------------------------------------------//






export const EMAIL_REGEX = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const ORGANISM_BARCODE_REGEX = RegExp(/^(PRO|FOR|PRM)_\d{5}_\d{3}$/);

export const SAMPLE_ID_REGEX = RegExp(/^SG\d{6}-\d{2}$/);

export const FORMULA_ID_REGEX = RegExp(/^fmrx_\d{6}_\d{3}$/);

export const page_size_options = [
  { key: 1, text: "10", value: 1 },
  { key: 2, text: "50", value: 2 },
  { key: 3, text: "100", value: 3 },
  { key: 4, text: "500", value: 4 },

];

export const PRINT_CATEGORIES = {
  REQUEST: "Request URL",
  RESPONSE: "Response"
};

export const PRINT_ICONS = {
  REQUEST: "--->   ",
  RESPONSE: "<---   "
};


const applicationURL = document.URL;

const formulationDevURL  = "https://api.formulation-dev.sungenomics.com/api";
const formulationProdURL = "https://api.formulation.sungenomics.com/api";


const portalDevURL = "https://admin-dev.sungenomics.com/wecontrol/apis";
const portalProdURL = "https://admin.sungenomics.com/wecontrol/apis";

const portalDevReportURL = "https://admin-dev.sungenomics.com/wecontrol/reports/generate_report/";
const portalProdReportURL = "https://admin.sungenomics.com/wecontrol/reports/generate_report/";

const devFormulationReport = "https://admin-dev.sungenomics.com/wecontrol/reports/generate_report_formulation";
const portalFormulationReport = "https://admin.sungenomics.com/wecontrol/reports/generate_report_formulation";

const portalDevReportFormulationURL = "https://api.formulation-dev.sungenomics.com/api";
const portalProdReportFormulationURL = "https://api.formulation.sungenomics.com/api";

let baseURL;
let portalURL;
let portalDev;
let FormulationPortalDev;
let reportFormulationUrl;

if (applicationURL.includes("formulation-dev.sungenomics.com")
 || applicationURL.includes("formulation-dev.flore.com")) {  
  baseURL = formulationDevURL;
  portalURL = portalDevURL;
  portalDev = portalDevReportURL;
  FormulationPortalDev = portalDevReportFormulationURL;
  reportFormulationUrl = devFormulationReport;

} else if (applicationURL.includes("formulation.sungenomics.com") || applicationURL.includes("formulation.flore.com")) {
// } else if (true) {
  baseURL = formulationProdURL;
  portalURL = portalProdURL;
  portalDev = portalProdReportURL;
  FormulationPortalDev = portalProdReportFormulationURL;
  reportFormulationUrl = portalFormulationReport;

} else {
  baseURL = formulationDevURL;
  portalURL = portalDevURL;
  portalDev = portalDevReportURL;
  FormulationPortalDev = portalDevReportFormulationURL;
  reportFormulationUrl = devFormulationReport;
}


export { baseURL };
export { portalURL };
export { portalDev };
export { FormulationPortalDev };
export { reportFormulationUrl };
