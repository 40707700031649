
import Config from "../../configuration/config";
import CustomLoader from "../custom_elements/CustomLoader";
import SuccessMessage from "../custom_elements/SuccessMessage";
import ErrorMessage from "../custom_elements/ErrorMessage";
import RoleTable from "./CreateRoleTable";
import {
    focusFirstElement,
    customPrint,
    isFormValid,
    getSessionStoredJsonItem,
    getHeaders
} from "../helper";
import {
    baseURL,
    PRINT_CATEGORIES
} from "../../configuration/constants";

import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import {
    Form,
    Popup,
    Segment,
    Button,
    Header,
    Message,
    Input,
    Grid,
    Label
} from "semantic-ui-react";

class CreateGroup extends Component {
    is_mounted = true;

    componentWillUnmount() {
        this.is_mounted = false;
    }

    getInitState = () => {
        return {
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            is_message_visible: true,
            response_message: "",
            is_page_loading: true,
            disabledPermission: false,
            role_name: "",
            user_id: getSessionStoredJsonItem("user").user_id,
            role_name_message: "",
            role_id: "",

            form_errors: {
                role_name: ""
            }
        };
    };

    state = this.getInitState();

    componentDidMount = () => {
        this.is_mounted = true;
        document.title = Config.group.title.display;
        focusFirstElement();

        this.setState({
            is_page_loading: false
        });
    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    //handles click on input button
    onSubmitClick = event => {
        if (isFormValid(this.state)) {
            this.setState({
                is_saving_successful: false,
                is_saving_failed: false
            });
            let res = _.pick(this.state, ["role_name", "user_id"]);
            this.setState({
                is_waiting_for_response: true,
                disabledPermission: true
            });

            customPrint(Config.group.title.create, PRINT_CATEGORIES.REQUEST, baseURL + "/users/createRole", res);

            axios
                .post(baseURL + "/users/createRole", res, getHeaders())
                .then(response => {
                    customPrint(Config.group.title.create, PRINT_CATEGORIES.RESPONSE, response);
                    this.setState({
                        is_saving_successful: response.data.success,
                        is_saving_failed: !response.data.success,
                        response_message: response.data.message,
                        is_waiting_for_response: false,
                        is_message_visible: true,
                        role_name_message: response.data.role_name,
                        role_id: response.data.role_id
                    });
                    focusFirstElement();
                })
                .catch(error => {
                    if (error) {
                        var x = document.getElementById('roleTable')
                        x.style.display = "none";
                    }
                })
        }

    };

    // handles cancel click
    onCancelClick = () => {
        let init_state = this.getInitState();
        init_state["is_page_loading"] = false;
        this.setState(init_state);
        var x = document.getElementById('roleTable')
        x.style.display = "none";
    };

    //hides the SUCCESS/FAILURE message
    hideMessage = () => {
        this.setState({ is_message_visible: false });
    };


    render() {
        if (this.state.is_saving_successful === true) {
            setTimeout(function () {
                var x = document.getElementById('roleTable')
                x.style.display = "block";
            }, 1000);

        }
        if (this.state.is_saving_failed === true) {
            var x = document.getElementById('roleTable')
            x.style.display = "none";
        }

        return (

            <React.Fragment>
                {this.state.is_page_loading && <CustomLoader />}

                {this.state.is_saving_successful && (
                    <SuccessMessage
                        is_message_visible={this.state.is_message_visible}
                        hideMessage={this.hideMessage}
                        module_name="User"
                        message={this.state.response_message}
                    />
                )}

                {this.state.is_saving_failed && (
                    <ErrorMessage
                        is_message_visible={this.state.is_message_visible}
                        hideMessage={this.hideMessage}
                        module_name="User"
                        message={this.state.role_name_message}
                    />
                )}

                <br />

                <Grid columns={3}>
                    <Grid.Row>
                        <Grid.Column />
                        <Grid.Column>
                            <Message>
                                <Header as="h2" textAlign="center">
                                    {Config.group.title.create}
                                </Header>
                            </Message>
                        </Grid.Column>
                        <Grid.Column />
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: "0px" }}>
                        <Grid.Column />
                        <Grid.Column>
                            <Segment className="transparent">
                                {this.state.is_waiting_for_response && (
                                    <CustomLoader loading_text="Saving User Data" />
                                )}
                                <Form id="createUserForm">
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Group Name</label>}
                                            content="Enter the Group name of the User you want to enter."
                                        />

                                        <Input
                                            id="role_name"
                                            name="role_name"
                                            onChange={this.onInputChange}
                                            value={this.state.role_name}
                                            icon="address card"
                                            iconPosition="left"
                                            placeholder="Enter Group Name"
                                            disabled={this.state.disabledPermission}
                                        />
                                        {this.state.form_errors.role_name && (
                                            <Label basic color="red" pointing>
                                                {this.state.form_errors.role_name}
                                            </Label>

                                        )}
                                    </Form.Field>

                                    <br />
                                    <br />

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Grid textAlign="center">
                                                <Button.Group>
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                content="Submit"
                                                                icon="check"
                                                                labelPosition="left"
                                                                positive
                                                                disabled={this.state.disabledPermission}
                                                                onClick={this.onSubmitClick}
                                                            />
                                                        }
                                                        content="Enter the Group Name."
                                                    />

                                                    <Button.Or />
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                content="Cancel"
                                                                icon="ban"
                                                                labelPosition="right"
                                                                negative
                                                                onClick={this.onCancelClick}
                                                            />
                                                        }
                                                        content="Discard the Group Name."
                                                    />
                                                </Button.Group>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row />
                                    <Grid.Row />
                                    <br />
                                    <br />
                                </Form>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column />
                    </Grid.Row>
                </Grid>
                <div style={{ display: "none" }} id="roleTable">
                    <RoleTable role={this.state.role_id} />
                </div>
            </React.Fragment>
        );
    }
}

export default CreateGroup;