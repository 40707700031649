import React, { Component } from "react";
import { Message, Segment, Header } from "semantic-ui-react";
import "./index.css";
import axios from "axios";
import { portalURL, baseURL } from "../../../configuration/constants";
import CustomLoader from "../../custom_elements/CustomLoader";
import config from "../../../configuration/config";
import { getHeaders } from "../../helper";

var profile_notes = [];
var notes = [];
var reformulation_details = [];



class CustomerProfileNotes extends Component {
  state = {
    selectedSample: null,
    survey: null
  };
  componentDidUpdate() {
    let { selectedSample } = this.props;
    let sampleInState = this.state.selectedSample;
    let url;
    var sg_number_list = [];

    if (Object.keys(selectedSample).length !== 0) {
      selectedSample.timepoint_list.map((list_sg_number) => {
        sg_number_list.push(list_sg_number.sg_number);
      })
    }

    if (sg_number_list.length>0 && selectedSample !== sampleInState) {

      this.setState({ is_page_loading: true });
      url = `${baseURL}/formulations/getAllNotes?sg_number=${sg_number_list}`;

      !this._isMounted && this.setState(
        {
          selectedSample: selectedSample
        },
        () => {
          axios
            .get(url, getHeaders())
            .then(res => {
              profile_notes = res.data.customer_notes;
              notes = res.data.notes
              reformulation_details = res.data.reformulation_details
              var height = (res.data.reformulation_details.length > 0 && !this.props.valueEdit) ? "59.9rem" : "500px"
              this.props.change_H_D_survey(height);
              this.setState({ is_page_loading: false })
            })

            .catch(() => { this.setState({ is_page_loading: false }) });
        }
      );
    }
  }

  render() {

    return (
      <>
        {this.state.is_page_loading && <CustomLoader />}
        <Message
          className="formulation-rationale__health-goal-call-summary__header"
          style={{ marginBottom: "0", marginTop: "0" }}
        >
          <Header as="h2" textAlign="center">
            Notes
          </Header>
        </Message>
        <Segment
          style={{ marginTop: "0", marginBottom: "0" }}
          className={"formulation-rationale__health-goal-call-summary__content"}
        >
          {profile_notes.length > 0 && <h4>Customer Profile Notes</h4>}
          {profile_notes && profile_notes.map((notes) => {
            return <div>
              <span> <b>Date: &nbsp;{notes.date}</b> </span><br />
              <span><b> Time:&nbsp;{notes['time']}</b> </span> <br />
              <b>Customer Notes:- </b> {(notes.notes !== undefined) ? <div dangerouslySetInnerHTML={{ __html: notes.notes }} /> : "--"}
              <br />
            </div>
          })
          }
          {(notes.length > 0 && profile_notes.length > 0) && <hr />}
          {notes && notes.map((all_notes) => {
            return <div style={{
              display:
                ((all_notes['admin_notes'] === null) &&
                  (all_notes['operator_notes'] === null) &&
                  (all_notes['success_notes'] === null) &&
                  (all_notes['reformulation_notes'] === null)) ? "none" : "block"

            }}>
              <span style={{ display: (all_notes['formula_id'] !== null) ? "block" : "none" }}><b>Fmrx:</b> &nbsp;{all_notes['formula_id']} </span>
              <span style={{ display: (all_notes['date'] !== null) ? "block" : "none" }} ><b>Date:</b> &nbsp;{all_notes['date']}  </span>
              <span style={{ display: (all_notes['time'] !== null) ? "block" : "none" }} ><b>Time:</b> &nbsp;{all_notes['time']}  </span>
              <span style={{ display: (all_notes['type'] !== null) ? "block" : "none" }} ><b>Type:</b> &nbsp;{all_notes['type']}  </span>
              <span style={{ display: (all_notes['admin_notes'] !== null) ? "block" : "none" }}  ><b>Admin Notes:</b> &nbsp;{all_notes['admin_notes']} <br />  </span>
              <span style={{ display: (all_notes['operator_notes'] !== null) ? "block" : "none" }}  ><b>Operator Notes:</b> &nbsp;{all_notes['operator_notes']}<br />  </span>
              <span style={{ display: (all_notes['success_notes'] !== null) ? "block" : "none" }}  ><b>Success Notes:</b> &nbsp;{all_notes['success_notes']}<br />  </span>
              <span style={{ display: (all_notes['reformulation_notes'] !== null) ? "block" : "none" }}  ><b>Reformulation Notes:</b>{all_notes['reformulation_notes']}<br />  </span>
              <br />
            </div>
          })
          }
          <div>


          </div>
        </Segment>

        {(reformulation_details.length > 0) && (
          <div>
            <br />
            <Message
              className="formulation-rationale__health-goal-call-summary__header"
              style={{ marginBottom: "0", marginTop: "0", }}
            >
              <Header as="h2" textAlign="center">
                Reformulation info
              </Header>
            </Message>

            <Segment
              className="formulation_info"
              style={{
                marginTop: "0", marginBottom: "0", overflow: "auto",
                wordBreak: "break-word", maxHeight: "260px", minHeight: "260px"
              }} >
              {reformulation_details && reformulation_details.map((reformulation_notes, index) => {

                if (reformulation_notes.type == "info" && reformulation_notes.notes !== null) {
                  return <>
                    <b>Formula Id: </b>&nbsp;&nbsp;{reformulation_notes.formula_id}<br />
                    <b>Sample Id: </b>&nbsp;&nbsp;{reformulation_notes.sample_id}<br />
                    <b>No. of Months: </b>&nbsp;&nbsp;{reformulation_notes.no_of_months}<br />
                    {(reformulation_notes.reasons !== null && reformulation_notes.reasons.length > 0) && <b>Reason: &nbsp;&nbsp; </b>}
                    <ul>
                      {reformulation_notes.reasons !== null && reformulation_notes.reasons.map((item) => {
                        return <li style={{ listStyleType: "bullet" }}> {item} </li>
                      })}
                    </ul>
                    {(reformulation_notes.notes !== null) && (<><b>Notes: </b>&nbsp;&nbsp;{reformulation_notes.notes}</>)}
                    <br />
                    <hr />
                  </>
                }
                if (reformulation_notes.type == "logs" && reformulation_details > 0) {
                  return <div style={{
                    overflow: 'scroll-x',
                    width: "100%"
                  }}>
                    <table style={{ width: '100%' }} className="ui celled structured table">
                      <thead style={{ fontWeight: 'bold' }} >
                        <tr style={{ textAlign: "center" }}>
                          <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Organism Barcode</th>
                          <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Required Quantity</th>
                          <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Available Quantity </th>
                          <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Is Available?</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reformulation_details && reformulation_details.map((data, index) => {
                          return <tr style={{ textAlign: "center", display: (data.type == "logs") ? "revert" : "none" }} key={index}>
                            <td className="center aligned" style={{ padding: "7px" }}>{data.organism_barcode}</td>
                            <td className="center aligned" style={{ padding: "7px" }}>{data.required_qty}</td>
                            <td className="center aligned" style={{ padding: "7px" }}>{data.available_qty}</td>
                            <td className="center aligned" style={{ padding: "7px" }}>{(data.is_available === 1) ? "Yes" : "No"}</td>
                          </tr>

                        })}
                      </tbody>
                    </table>
                  </div>
                }
              })}
            </Segment>
          </div>
        )}

      </>
    );
  }

}

export default CustomerProfileNotes;
