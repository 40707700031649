import React, { Component } from "react";
import { Popup, Form } from "semantic-ui-react";

export class Custom_DropDown extends Component {
    render() {
        return (
            <Form.Field required fluid="true" >
                <Popup
                    trigger={<label>{this.props.label}</label>}
                    content={this.props.content}
                />
                <select
                    value={this.props.dropDownValue}
                    name={this.props.is_available}
                    className={this.props.classNames}
                    onChange={(e) =>{this.props.onChange(e.target.value)}}
                >
                    {
                        this.props.isAvailable && this.props.isAvailable.map((elem, i) => {
                            return (
                                <option key={i} selected={elem.disabled}>
                                    {elem.value}
                                </option>

                            )
                        })
                    }
                </select>
            </Form.Field>

        )
    }
}

export class Quality_assurance_DropDown extends Component {
    render() {
        return (
            <Form.Field required fluid="true" >
                <Popup
                    trigger={<label>{this.props.label}</label>}
                    content={this.props.content}
                />
                <select
                    value={this.props.dropDownValue}
                    name={this.props.is_available}
                    className={this.props.classNames}
                    placeholder={"In-house sample taken"}
                    onChange={(e) =>{this.props.onChange(e.target.name,e.target.value)}}
                >
                    {
                        this.props.isAvailable && this.props.isAvailable.map((elem, i) => {
                            return (
                                <option key={i}>
                                    {elem.value}
                                </option>

                            )
                        })
                    }
                </select>
            </Form.Field>

        )
    }
}

export class Inventory_custom_drop_down extends Component {

    render() {
        return (

            <Form.Field required fluid="true" >
                <Popup
                    trigger={<label>{this.props.label}</label>}
                    content={this.props.content}
                />
                <select
                    value={this.props.dropDownValue}
                    name={this.props.is_available}
                    className={this.props.classNames}
                    onChange={(e) => this.props.onChange(e.target.value)}
                >
                    {
                        this.props.isAvailable && this.props.isAvailable.map((elem, i) => {
                            return (
                                <option key={i}>
                                    {elem.value}
                                </option>

                            )
                        })
                    }
                </select>
            </Form.Field>


            // <Form.Field fluid="true">
            //                 <Popup
            //                     trigger={<label>Is Available?</label>}
            //                     content="Is Organism available ?"
            //                 />
            //                 <Radio
            //                     toggle
            //                     onClick={this.changeAvailablity}
            //                     checked={is_available === 1}
            //                 />
            //             </Form.Field>
        )
    }
}