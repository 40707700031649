import React, { Component } from "react";
import { Form, Input } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class OperatorMmrReview extends Component {
  state = { pin: "", user: "",pinError:false };

  componentDidMount() {
    this.getCurrentStep("operator_mmr_review");
  }

  getCurrentStep = (step_name) => {
    let req = {
      formula_id: this.props.selected_row_value.formula_id,
      step_name: step_name,
    };
    axios.post(baseURL + "/get-current-step", req, getHeaders()).then((res) => {
      let result = res.data;
      if (result.success === true && result.current_step !== null) {
        let current_step = result.current_step;
        current_step.step_json = JSON.parse(current_step.step_json);
        this.setState({ step_current: current_step });
        this.onInputChange(null, {
          name: "pin",
          value: current_step.performed_by,
        });
      }
    });
  };
  onInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    if (value.length >= 3) {
      axios
        .post(baseURL + "/users/getUserByPin", { pin: value }, getHeaders())
        .then((res) => {
          if (res.data.success === true) {
            let user = res.data.user;
            this.setState({                        
              user: user?user:"",
              pinError:user?false:true
          });
            //this.setState({user_full_name:user.full_name});
          } 
        });
    } else {
      this.setState({                        
          user:"",
          pinError:true
      }); 
    }
  };

  handleSubmit = () => {
    const { pin,user} = this.state;
    this.setState((prevState) => ({  
        pinError: user?false:true,
    }));


    if (!this.state.pin) {
        toast.error("PIN is missing");
        return;
    }
    
    if (Object.keys(this.state.user).length == 0) {
        toast.error("PIN is incorrect");
        return;
    }

    let param = {
      sample_id: this.props.selected_row_value.sample_id,
      formula_id: this.props.selected_row_value.formula_id,
      step: 6,
      step_json: "",
      step_name: "operator_mmr_review",
      step_next: "processing",
      performed_by: pin,
    };
    axios
      .post(baseURL + "/mmr-formulations", param, getHeaders())
      .then((res) => {
        let result = res.data;
        if (result.success === true) {
          toast.success("Successfully saved Step");
          this.props.onNextStep();
        } else {
          toast.error("Step has not saved");
        }
      });
  };
  handleClick = () => {
    // Call the function passed from the parent
    this.props.onPrevClick("label_generation");
  };
  handlePauseClick = () => {
    this.props.onNextClick("pause");
  };
  handleQuitClick = () => {
    this.props.onNextClick("quit");
  };

  handleNextClick = () => {
    // Call the function passed from the parent
    this.props.onNextClick("processing");
  };

  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <div className="cs-box">
            <div className="cs-body">
              <h3>Operator MMR Review</h3>
              <ul className="cs-list">
                <li>Batch Record Generation</li>
                <li>
                  Batch Records are automatically generated upon formulation
                  submission to satisfy 21 CFR 111.260
                </li>
                <li>
                  Batch records are broken into a digital component part and a
                  physical component part
                </li>
                <li>
                  The digital component part includes the facsimile of the MMR
                  and the results of any processing steps
                </li>
                <li>
                  The physical component part includes signoffs and
                  verifications of processing steps
                </li>
                <li>As a whole batch records include</li>
                <li>The lot identifier</li>
                <li>
                  Reference to the equipment used in the manufacturing via
                  operator information
                </li>
                <li>Reference to equipment logs via operator information</li>
                <li>Components used within the formulation</li>
                <li>Identity and weight of each component used</li>
                <li>Yield of formulation</li>
                <li>Finished product specifications</li>
                <li>Date which each step was performed</li>
                <li>Initials of performing employee</li>
                <li>Initials of verifying employee</li>
                <li>Label information</li>
                <li>Quality review and signoff</li>
                <li>Rework summary</li>
                <li>Disposition decisions</li>
                <li>
                  Digital sections of the batch record are stored on Sun
                  Genomics' AWS server with secure access
                </li>
                <li>
                  Physical sections of the batch record are stored in paper copy
                  in the formulation QC logbook and maintained according to{" "}
                  <em>SOP_012 Document Control</em>
                </li>
                <li>
                  Quality signoff is conducted on a per day basis on{" "}
                  <em>FF_016 Batch Record and QC Checklist</em>
                </li>
              </ul>
              <div className="text-center mt-5 mb-5">
                <div className="d-inline-flex">
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1">Performed By: </h4>
                    </div>
                    <div className="m-0 pe-1">
                      <h4 className="m-0">
                        <u>{this.state.user.full_name}</u>
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1"> PIN</h4>
                    </div>
                    <div className="ui input">
                      <Form.Field required fluid="true">
                        <Input
                          error={this.state.pinError}
                          id="pin"
                          name="pin"
                          onChange={this.onInputChange}
                          value={this.state.pin}
                          placeholder="Enter Your PIN"
                          type="password"
                          className="full_opacity"
                          autoComplete="new-password"
                        />
                      </Form.Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cs-footer text-center">
              <hr />
              <div className="text-center mt-2">
                <button
                  type="button"
                  onClick={this.handleClick}
                  className="gray-btn"
                >
                  Prev
                </button>
                <button
                  type="button"
                  onClick={this.handlePauseClick}
                  className="ui icon secondary labeled button pause-btn ps-1-5em ms-2"
                >
                  Pause
                </button>
                <button
                  type="button"
                  onClick={this.handleQuitClick}
                  className="ui icon secondary labeled button quit-btn ps-1-5em ms-2"
                >
                  Quit
                </button>
                {this.props.step_name === "label_generation" && (
                  <button type="submit" className="theme-btn ms-2">
                    Next
                  </button>
                )}
                {this.props.step_name !== "label_generation" && (
                  <button
                    type="button"
                    onClick={this.handleNextClick}
                    className="theme-btn ms-2"
                  >
                    {" "}
                    Next{" "}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default OperatorMmrReview;
