import React, { Component } from "react";

import { Message, Segment, Header, Grid } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";

import { onGridReady } from "../../helper";
import "./index.css";

let api, column_api;

class SuccessIngredient extends Component {
    state = {
        columnDefs: [
            {
                headerName: "Ingredient Barcode",
                field: "organism_barcode",
                width: 170
            },
            {
                headerName: "Lot Wise Success Rate",
                field: "lot_wise_success_rate",
                width: 180,
                filter: "agTextColumnFilter",
                sortable: true,
                cellRenderer: (params) => {
                    return (
                        `
                        <div>
                        ${parseFloat(params.value).toFixed(2)} %
                        </div>
                        `
                    )
                }
            },
            {
                headerName: "Ingredient Wise Success Rate",
                field: "ingredient_wise_success_rate",
                width: 240,
                filter: "agTextColumnFilter",
                sortable: true,
                cellRenderer: (params) => {
                    return (
                        `
                        <div>
                        ${parseFloat(params.value).toFixed(2)} %
                        </div>
                        `
                    )
                }
            },
        ]

    };
    render() {
        const { all_inventory,serach_tag_name } = this.props;
        const { columnDefs } = this.state;
        return (
            <div>
                <Message style={{ position: "absolute", top: "-49px", width: "71%" }}>
                    <Header as="h3" textAlign="center">
                        Successful Ingredient for {serach_tag_name}
                    </Header>
                </Message>
                <Segment
                    compact
                    raised
                    padded
                    style={{ marginTop: "0", width: "636px" }}
                >
                    <Grid.Row>
                        <Grid.Column>
                            <div
                                id="myGrid"
                                className="ag-theme-balham"
                                ag-grid="gridOptions"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                }}
                            >

                                <AgGridReact
                                    rowHeight="30"
                                    rowSelection="multiple"
                                    onGridReady={params => {
                                        api = params.api;
                                        api.showLoadingOverlay();
                                        column_api = params.column_api;
                                        let initial_sort = [{ colId: "id", sort: "desc" }]
                                        onGridReady(
                                            params,
                                            api,
                                            column_api,
                                            initial_sort
                                        );
                                    }}
                                    animateRows={true}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationNumberFormatter={params => {
                                        return "[" + params.value.toLocaleString() + "]";
                                    }}
                                    onSelectionChanged={params =>
                                        this.onSelectionChanged(params)
                                    }
                                    columnDefs={columnDefs}
                                    floatingFilter={true}
                                    rowData={all_inventory}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                </Segment>
            </div>

        )
    }
}

export default SuccessIngredient;