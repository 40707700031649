import React, { Component } from "react";
import axios from "axios";

import { onGridReady, getHeaders } from "../../helper";
import { baseURL } from "../../../configuration/constants";
import CustomConfirm from "../../custom_elements/CustomConfirm";

import { Message, Header, Segment, Grid, Button, Icon } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let api, column_api;

class SupplementTable extends Component {
    is_mounted = true;
    constructor(props) {
        super(props);
        this.state = {
            is_confirm_open: false,
            getDeleteId: "",
            deleteMsg: "",
            samples: [],
            columnDefs: [
                {
                    headerName: "Supplement Name",
                    field: "supplement",
                    filter: "agTextColumnFilter",
                    pinned: "left",
                    width: 170,
                    sizeColumnToFit: true,
                    sortable: true,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    editable: true,
                    cellRenderer: (params) => {
                        return (
                            `
                            <div>
                            ${params.value}
                            </div>
                            `
                        )
                    }
                },
                {
                    headerName: "DV for adults (4 year or more)",
                    field: "dv_for_adult",
                    width: 230,
                    pinned: "left",
                    editable: true,
                    headerTooltip: '4 year or more',
                },
                {
                    headerName: "DV for children (from 1 to less than 4 year)",
                    field: "dv_for_children",
                    width: 320,
                    pinned: "left",
                    editable: true,
                },
                {
                    headerName: "DV for infants (less than 1 year)",
                    field: "dv_for_infants",
                    width: 230,
                    pinned: "left",
                    editable: true,
                },
                {
                    headerName: "Unit",
                    field: "unit",
                    width: 130,
                    pinned: "left",
                    editable: true,
                    cellEditor: "agSelectCellEditor",
                    cellEditorParams: {
                        values: ["no unit", "mcg", "mg", "g"]
                    },
                    refData: { "no unit": "no unit", "mcg": "mcg", "mg": "mg", "g": "g" }
                }

            ]
        };
    }

    componentDidMount = () => {
        this.is_mounted = true;
        this.getSamples();
    }

    getSamples = () => {
        axios
            .get(baseURL + "/supplements/all/null", getHeaders())
            .then(res => {
                let data = res.data;
                return data;
            })
            .then(samples => {
                if (samples.length > 0) {
                    samples.map((elem, index) => {
                        this.setState({
                            samples: elem
                        });
                    })
                }
            });
    }

    onDeleteClick = () => {
        let selectedData = api.getSelectedRows();
        selectedData.map(selectedRow => {
            this.setState({
                deleteMsg: "Are you sure you want to delete supplement- " + selectedRow.supplement
            })
        })
        this.setState({ is_confirm_open: true });
    }

    onCancel = () => {
        this.setState({ is_confirm_open: false });
    };

    onConfirm = () => {
        this.setState({ is_confirm_open: false });
        let selectedData = api.getSelectedRows();
        axios
            .get(baseURL + "/delete-supplement/" + this.state.getDeleteId, getHeaders())
            .then(res => {
                if (res.data.success != false) {
                    api.updateRowData({ remove: selectedData });
                }
                toast.success(res.data.message);
            })

    }

    sortOrganism = (value) => {
        this.setState({
            getDeleteId: value.data.id
        })
    }
    cellValueChaged = (value) => {
        if (this.props.disabledPermission) {
            toast.info("you're not authorized to edit");
            return false;
        }
        else {
            let data = {
                supplement: value.data.supplement,
                dv_for_adult: value.data.dv_for_adult,
                dv_for_children: value.data.dv_for_children,
                dv_for_infants: value.data.dv_for_infants,
                unit: value.data.unit
            }
            axios
                .put(baseURL + "/update-supplement/" + value.data.id, data, getHeaders())
                .then(res => {
                    toast.success("Supplement has been updated successfully");
                })
        }
    }

    render() {
        const { is_confirm_open, deleteMsg, samples, columnDefs } = this.state;
        const { disabledPermission, SupplementFacts } = this.props;
        return (
            <>
                <CustomConfirm
                    is_confirm_open={is_confirm_open}
                    content={deleteMsg}
                    header="Delete selected Record(s)"
                    cancel_button_text="Eh! Never Mind."
                    confirm_button_text="DELETE"
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}
                />
                <Message style={{ marginBottom: "0", marginTop: "10px" }}>
                    <Header as="h3" textAlign="center">
                        Manage Supplement Facts
                    </Header>
                </Message>
                <Segment
                    compact
                    raised
                    padded
                    style={{ display: "block", marginTop: "0" }}
                >
                    <Grid columns="equal">
                        {(SupplementFacts == 1) && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Button
                                        basic
                                        negative
                                        onClick={this.onDeleteClick}
                                        icon
                                        labelPosition="right"
                                        disabled={disabledPermission}
                                    >
                                        Delete
                                        <Icon name="trash" />
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>

                        )}
                        <Grid.Row>
                            <Grid.Column>

                                <div
                                    id="myGrid"
                                    className="ag-theme-balham"
                                    ag-grid="gridOptions"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    <AgGridReact
                                        rowHeight="30"
                                        rowSelection="single"
                                        onGridReady={params => {
                                            api = params.api;
                                            api.showLoadingOverlay();
                                            column_api = params.column_api;
                                            onGridReady(
                                                params,
                                                api,
                                                column_api,
                                                this.props.initial_sort
                                            );
                                        }}
                                        onRowClicked={this.sortOrganism}
                                        onCellValueChanged={this.cellValueChaged}
                                        animateRows={true}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationNumberFormatter={params => {
                                            return "[" + params.value.toLocaleString() + "]";
                                        }}
                                        columnDefs={columnDefs}
                                        floatingFilter={true}
                                        rowData={samples}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </>
        );
    }
}

export default SupplementTable;
