//-------------------------------------------------------------------//
// Module: SuccessMessage                                            //
// Tasks: Display Semantic-UI specified Message                      //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import { Grid, Message } from "semantic-ui-react";

class SuccessMessage extends Component {
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {this.props.is_message_visible && (
              <Message
                onDismiss={this.props.hideMessage}
                success
                header={"Success!"}
                content={this.props.message}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default SuccessMessage;
