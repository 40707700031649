
import React, { Component } from "react";
import $ from 'jquery';
import axios from "axios";
import _ from "lodash";

import { baseURL } from "../../configuration/constants";
import CustomLoader from "../custom_elements/CustomLoader";
import CustomDivider from "../custom_elements/CustomDivider";
import { getHeaders, getSessionStoredJsonItem, focusFirstElement, digitsAfterPointPrime, digitsAfterPoint, checkValueExistOrNotInMultiVFun, multi_v_availablity } from "../helper";
import Add from "./add";
import PrimeFlourish from "./addPrime";
import { getFinalConcentration } from "../formulation_mod/Formulas";
import {
    Form,
    Popup,
    Segment,
    Button,
    Input,
    Grid,
    TextArea
} from "semantic-ui-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Multiselect } from 'multiselect-react-dropdown';
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const options = [
    { value: 0 },
    { value: 1 },
    { value: "Powder" }
];



class PremadeFlourishFormulla extends Component {
    getInitState = () => {
        return {
            files: [],
            attachment_arr: [],
            multiSelectData: [],
            benefit: [],
            is_submit: false,
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            // is_page_loading: true,
            is_prime_added: true,
            prime_filler_inventory_id: null,
            prime_total_amount_added: 6,
            prime_flourish_amount_added: 1.2,
            prime_filler_amount_added: 4.8,
            inventory_options: [],
            prime_capsule_size: 0,
            flourish_capsule_size: 0,
            number_of_months: 3,
            formulation_factor: 1,
            quantity: "",
            total_final_concentration: 0,
            concentration: 0,
            reorder_point: 0,
            storage_location: "",
            shelf: 0,
            admin_notes: "",
            organism: [],
            total_amount_added: 0,
            total_final_amount_added: 0,
            form_errors: {
                prime_filler_inventory_id: "",
            }
        };
    };

    state = this.getInitState();

    constructor(props) {
        super(props);
        this.multiselectRef = React.createRef();
    }

    componentDidMount = () => {
        let inventory_options_prime = [];
        axios
            .get(baseURL + "/inventory/inventoryOptions?type=pre,pro", getHeaders())
            .then((res => {
                let tempArray = this.state.inventory_options;
                tempArray.push(...res.data);
                tempArray.forEach(organism => {
                    inventory_options_prime.push({
                        key: organism.id,
                        value: organism.id,
                        active: true,
                        text: organism.organism_barcode,
                        icon: "dna"
                    });
                });
                this.setState({
                    inventory_options: inventory_options_prime
                })
            }))


        this.fetchBenefit()
    }

    fetchBenefit = () => {
        axios
            .get(baseURL + "/benefits", getHeaders())
            .then(response => {
                let x = response.data.data;
                let emptyArray = [];
                x && x.map((elem, index) => {
                    let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id }
                    emptyArray.push(obj);
                })
                this.setState({
                    multiSelectData: emptyArray
                })
            })
    }

    onSelect = (selectedList, selectedItem) => {
        if (selectedList && selectedList.length) {
            this.setState({
                benefit: selectedList
            })
        }
        else {
            this.setState({
                benefit: []
            })
        }
    }

    change = (value) => {
        this.setState({
            prime_capsule_size: value,
            flourish_capsule_size: value
        })
    }

    AnswerKeyPress = (e) => {
        $("#amount_added").on("input", function () {
            if (/^0/.test(this.value)) {
                this.value = this.value.replace(/^0/, "")
            }
        });

        let code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    isSubmitHandler = (value) => {
        this.setState({
            is_submit: value
        })
    }

    organism = (value) => {
        this.setState({
            organism: value
        })
    }
    total_amount_added = (value) => {
        this.setState({
            total_amount_added: value
        })
    }
    total_final_amount_added = (value) => {
        this.setState({
            total_final_amount_added: value
        })
    }
    total_final_concentration = (value) => {
        this.setState({
            total_final_concentration: value
            // concentration: value
        })
    }
    number_of_months = (value) => {
        this.setState({
            number_of_months: value
        })
    }

    formulation_factor = (value) => {
        this.setState({
            formulation_factor: value
        })
    }

    onSubmitClick = () => {
        let { organism, quantity } = this.state;
        
        
        if (!organism || !quantity) {
            toast.info("Please fill all the mandatory information.");
            return;
        }
        if (this.state.prime_filler_inventory_id == null && this.state.is_prime_added == true) {
            toast.info("Prime is added, please select a filler!")
            return;
        }
        this.setState({
            is_saving_successful: false,
            is_saving_failed: false,
            is_submit: true
        });
        let res = _.pick(this.state, [
            "organism",
            "reorder_point",

            "storage_location",
            "shelf",
            "admin_notes",
            "total_amount_added",
            "total_final_concentration",
            "total_final_amount_added",
            "prime_total_amount_added",
            "prime_flourish_amount_added",
            "prime_filler_amount_added",
            "quantity",
            "concentration",
            "prime_filler_inventory_id",
            "flourish_capsule_size",
            "number_of_months",
            "formulation_factor",
            "benefit",
            "attachment_arr"
        ]);

        res.organism.map((organism, index) => {
            let final_concentration = getFinalConcentration(organism.final_concentration, organism.amount_added, this.state.total_amount_added);
            res.organism[index].final_concentration = final_concentration;
        });
        res.total_final_amount_added = this.state.total_final_amount_added;
        res.user_id = getSessionStoredJsonItem("user").user_id;
        res.prime_capsule_size = this.state.is_prime_added ? this.state.prime_capsule_size : ""
        this.setState({ is_waiting_for_response: true });
        axios
            .post(baseURL + "/add_for_prm?type=for", res, getHeaders())
            .then(response => {
                toast.success("Inventory Added Successfully" + "  Barcode is  " + response.data.barcode);
                let init_state = this.getInitState();
                init_state["is_page_loading"] = false;
                init_state["is_waiting_for_response"] = false;
                this.setState(init_state);
                focusFirstElement();
                this.setState({
                    benefit: []
                })
                this.fetchBenefit()
                setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);
            })
            .catch(error => {
                toast.error("Invalid Data");
                this.setState({ is_page_loading: false });
            });
        this.multiselectRef.current.resetSelectedValues();

    }

    changePrimeAddition = () => {
        let is_prime_added = this.state.is_prime_added === true ? false : true;
        let form_errors = { ...this.state.form_errors };
        if (this.state.is_prime_added == false && this.state.prime_filler_inventory_id == null) {
            form_errors.prime_filler_inventory_id = 'Please select an organism';
            this.setState({ form_errors });
        }
        else {
            form_errors.prime_filler_inventory_id = '';
            this.setState({ form_errors });
        }
        let prime_filler_inventory_id = is_prime_added
            ? this.state.prime_filler_inventory_id
            : null;
        let prime_total_amount_added = is_prime_added ? 6 : 0;
        let prime_filler_amount_added = is_prime_added ? 4.8 : 0;
        let prime_flourish_amount_added = is_prime_added ? 1.2 : 0;

        this.setState({
            is_prime_added,
            prime_filler_inventory_id,
            prime_total_amount_added,
            prime_filler_amount_added,
            prime_flourish_amount_added
        });
    }
    onInputChangePrime = (e, { name, value }) => {
        if (this.state.prime_filler_inventory_id === null) {
            alert("Please enter Prime Filler Organism first");
            return;
        }
        this.setState({
            prime_filler_amount_added: value,
            prime_total_amount_added: Number(value) + Number(this.state.prime_flourish_amount_added)
        })
        digitsAfterPointPrime()
    }

    onInputChange_Primes = (e, { name, value }) => {
        this.setState({
            prime_flourish_amount_added: value,
            prime_total_amount_added: Number(value) + Number(this.state.prime_filler_amount_added)
        })
        digitsAfterPoint()
    }

    handleInventory = (e, { name, value }) => {
        switch (name) {
            case "prime_filler_inventory_id":
                {
                    let form_errors = { ...this.state.form_errors };
                    if (value != null) {
                        form_errors.prime_filler_inventory_id = '';
                        this.setState({ form_errors });
                    }
                    break;
                }
            default:
        }
        this.setState({
            [name]: value
        });
    }

    onCancelClick = () => {
        let init_state = this.getInitState();
        init_state["is_page_loading"] = false;
        this.setState(init_state);
    }

    onRemove = (value) => {
        this.setState({
            benefit: value
        })
    }

    onupdatefiles = (fileItems) => {
        const scope = this;

        let attachment_arr_temp = this.state.attachment_arr;
        attachment_arr_temp = [];
        fileItems.map((elem, index) => {
            let mediaTypePdf = elem.file.type.split("/")[1];
            let mediaSize = elem.file.size;
            let mediaType = elem.file.type;
            let mediaName = elem.file.name;
            var selectedFile = elem.file;
            if (selectedFile) {
                var fileToLoad = selectedFile;
                var fileReader = new FileReader();
                var base64;
                fileReader.readAsDataURL(fileToLoad);
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let res = base64.slice(28);
                    if (mediaTypePdf === "pdf" && mediaSize <= 5242880) {
                        let obj = {};
                        obj = {
                            "base64Media": res,
                            "mediaName": mediaName,
                            "type": mediaType,
                            "size": mediaSize
                        }
                        attachment_arr_temp.push(obj);
                        const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                        scope.setState({
                            attachment_arr: final_array
                        })
                    }
                };
            }
        })
    }
    handleRemove = () => {
        const scope = this;
        this.setState({
            attachment_arr: []
        })
        let attachment_arr_temp = this.state.attachment_arr;
        if (this.pond) {
            const remainingAttachments = this.pond.getFiles().map((file) => file.source);
            remainingAttachments.map((elem, index) => {
                let mediaSize = elem.size;
                let mediaType = elem.type;
                let mediaName = elem.name;
                var selectedFile = remainingAttachments[0];
                if (selectedFile) {
                    var fileToLoad = selectedFile;
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        let res = base64.slice(28);
                        if (mediaSize <= 5242880) {
                            let obj = {};
                            obj = {
                                "base64Media": res,
                                "mediaName": mediaName,
                                "type": mediaType
                            }
                            attachment_arr_temp.push(obj);
                            const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                            scope.setState({
                                attachment_arr: final_array
                            })
                        }
                    };

                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <br />
                <Grid.Row />
                <Segment className="transparent">
                    {this.state.is_waiting_for_response && (
                        <CustomLoader loading_text="Saving Inventory Data" />
                    )}
                    <Form>
                        <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column>
                                    <PrimeFlourish
                                        changePrimeAddition={this.changePrimeAddition}
                                        handleInventory={this.handleInventory}
                                        onChange={this.onInputChangePrime}
                                        onChanges={this.onInputChange_Primes}
                                        selectCapsuleSizeFunction={this.change}

                                        is_prime_added={this.state.is_prime_added}
                                        prime_total_amount_added={this.state.prime_total_amount_added}
                                        prime_flourish_amount_added={
                                            this.state.prime_flourish_amount_added
                                        }
                                        prime_filler_amount_added={this.state.prime_filler_amount_added}
                                        inventory_options={this.state.inventory_options}
                                        prime_capsule_size={this.state.prime_capsule_size}
                                        prime_filler_inventory_id={this.state.prime_filler_inventory_id}
                                        options={options} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Add
                                        organism={this.organism}
                                        total_amount_added={this.total_amount_added}
                                        total_final_amount_added={this.total_final_amount_added}
                                        isSubmitHandler={this.isSubmitHandler}
                                        is_submit={this.state.is_submit}
                                        total_final_concentration={this.total_final_concentration}
                                        number_of_months={this.number_of_months}
                                        formulation_factor={this.formulation_factor}
                                        selectCapsuleSizeFunction={this.change}
                                        flourish_capsule_size={this.state.flourish_capsule_size}
                                        options={options}
                                        reorder_point={this.state.reorder_point}
                                        quantity={this.state.quantity}
                                        onInputChange={this.onInputChange}
                                        AnswerKeyPress={this.AnswerKeyPress}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Popup
                                        trigger={<label>Notes</label>}
                                        content="Enter notes."
                                    />
                                    <TextArea
                                        name="admin_notes"
                                        onChange={this.onInputChange}
                                        value={this.state.admin_notes}
                                        placeholder="Add Notes here..."
                                        style={{ minHeight: 100 }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        
                           {/*  <CustomDivider
                                h_size="h3"
                                title="Benefits"
                                icon_name="cart plus"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <Popup
                                            trigger={<label>Benefits</label>}
                                            content="Benefits."
                                            icon_name="warehouse"
                                        />
                                        <Multiselect
                                            options={this.state.multiSelectData}
                                            onSelect={this.onSelect}
                                            onRemove={this.onRemove}
                                            displayValue="name"
                                            ref={this.multiselectRef}
                                            placeholder="Select Benefits"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row> */}

                            <CustomDivider
                                h_size="h3"
                                title="Storage Information"
                                icon_name="dropbox"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Storage Location</label>}
                                            content="Enter the Organism Storage Location (Fridge Number)."
                                        />
                                        <Input
                                            placeholder="Storage Location (Fridge Number)"
                                            icon="boxes"
                                            iconPosition="left"
                                            name="storage_location"
                                            onChange={this.onInputChange}
                                            value={this.state.storage_location}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Shelf</label>}
                                            content="Enter the Organism Shelf Number."
                                        />
                                        <Input
                                            placeholder="Shelf"
                                            icon="box"
                                            iconPosition="left"
                                            name="shelf"
                                            onChange={this.onInputChange}
                                            value={this.state.shelf}
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <CustomDivider
                                h_size="h3"
                                title="Upload Documents"
                                icon_name="upload"
                            />
                            <Grid.Column>
                                <FilePond
                                    acceptedFileTypes={['application/pdf']}
                                    ref={ref => (this.pond = ref)}
                                    allowMultiple={true}
                                    allowReorder={true}
                                    allowRemove={true}
                                    maxFiles={10}
                                    allowFileSizeValidation={true}
                                    maxFileSize="5MB"
                                    onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                                    onremovefile={this.handleRemove}
                                    checkValidity={true}
                                    dropValidation
                                />
                            </Grid.Column>
                            <Grid.Row />
                            <Grid.Row />

                            <Grid.Row>
                                <Grid.Column>
                                    <Grid textAlign="center">
                                        <Button.Group>
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onSubmitClick} positive>
                                                        Submit
                                                    </Button>
                                                }
                                                content="Submit the Organism Information to the Database."
                                            />
                                            <Button.Or />
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onCancelClick}>Cancel</Button>
                                                }
                                                content="Discard the Organism Informtaion."
                                            />
                                        </Button.Group>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Form>
                </Segment>
            </React.Fragment>
        );
    }
}

export default PremadeFlourishFormulla;