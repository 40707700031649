//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: Flourish                                            //
// SubSubSubModule: Organisms                                        //
// SubSubSubSubModule: Organism                                      //
// Tasks: 1) Receives and displays Individual Organism               //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import {
  Grid,
  Segment,
  Form,
  Input,
  Button,
  Popup
} from "semantic-ui-react";
import Select from "react-select";

import $ from 'jquery';

class RefillOrganism extends Component {

  AnswerKeyPress = () => {
    $("#amount_added").on("input", function () {
      if (/^0/.test(this.value)) {
        this.value = this.value.replace(/^0/, "")
      }
    });
  }
  changeTags = (tag, organism_id) => {
    this.props.changeTagOnRefillOrganism(tag, organism_id);
  }
  render() {


    let amountAddedstyle = localStorage.getItem('rowSelectValue');
    let inventoryExists = "";
    if (amountAddedstyle == 0) {
      this.props.inventory_options.map(result => {

        if (inventoryExists != 1) {
          if (result.text === this.props.organism.inventory.organism_barcode) {

            inventoryExists = 1;
            return inventoryExists;

          }
          else {
            inventoryExists = 0;
          }
        }
      })

      let organismIndex = this.props.inventory_options.findIndex(element => element.key == this.props.organism.inventory.id);
      if (organismIndex >= 0) {
        this.props.inventory_options[organismIndex].active = false
      }
    }

    // console.log(this.props.organism);
    return (


      <Segment style={{ width: "77rem" }}>
        <Grid>


          <Grid.Row className="Refillblock" >
            <Grid.Column width={5}>
              <Form.Field required fluid="true">
                <Popup
                  trigger={<label>Ingredient Barcode</label>}
                  content="Enter the Ingredient Barcode."
                />
                <Input
                  id={"refillInventory_id" + this.props.organism.inventory_id}
                  name={"refillInventory_id" + this.props.index}
                  style={{
                    opacity: 1, backgroundColor:
                      this.props.inventory_options.find(
                        element => element.key == this.props.organism.inventory_id)
                        ? (this.props.inventory_options.find(element =>
                          element.key == this.props.organism.inventory_id && element.amount_added < this.props.organism.amount_added)
                          ? "orange" : "Green") : "red"
                  }}
                  disabled
                  value={this.props.organism.inventory.organism_barcode}

                  fluid
                  search
                  selection

                />

              </Form.Field>
            </Grid.Column>
            <Grid.Column width={4}>
              <Form.Field required fluid="true" >
                <Popup
                  trigger={<label>Amount Added</label>}
                  content="Enter the amount added."
                />
                <Input
                  className="refillInput"
                  id={"amount_background" + this.props.organism.inventory_id}
                  type="number"
                  style={{
                    opacity: 1, width: "80%"
                  }}
                  disabled={
                    this.props.inventory_options.find(
                      element => element.key == this.props.organism.inventory_id)
                      ? (this.props.inventory_options.find(element =>
                        element.key == this.props.organism.inventory_id && element.amount_added < this.props.organism.amount_added)
                        ? false : false) : true
                  }
                  name={"amount_background" + this.props.organism.inventory_id}
                  onChange={(e) => this.props.onChangeOf_organism(
                    Number(e.target.value),
                    this.props.organism.inventory_id,
                    this.props.organism.inventory.organism_barcode)}
                  onLoad={
                    setTimeout(() => {
                      if (document.getElementById("amount_background" + (this.props.organism.inventory_id)) && this.props.inventory_options.find(element => element.key == this.props.organism.inventory_id && element.amount_added >= this.props.organism.amount_added)) {

                        if (document.getElementById("amount_background" + (this.props.organism.inventory_id))) {
                          (document.getElementById("amount_background" + (this.props.organism.inventory_id)).style.backgroundColor = "green")
                        }

                      }

                      else if (document.getElementById("amount_background" + (this.props.organism.inventory_id)) && this.props.inventory_options.find(element => element.key == this.props.organism.inventory_id && element.amount_added < this.props.organism.amount_added)) {

                        if (document.getElementById("amount_background" + (this.props.organism.inventory_id))) {

                          (document.getElementById("amount_background" + (this.props.organism.inventory_id)).style.backgroundColor = "orange")
                        }
                      }
                      else {
                        if (document.getElementById("amount_background" + (this.props.organism.inventory_id))) {
                          (document.getElementById("amount_background" + (this.props.organism.inventory_id)).style.backgroundColor = "red")
                        }
                      }
                    }, 1000)
                  }


                  value={this.props.organism.amount_added}
                  onClick={this.handleClick}
                  onKeyPress={this.AnswerKeyPress}
                  icon="weight"
                  iconPosition="left"
                  label={{ content: "g" }}
                  labelPosition="right"
                  placeholder="Amount Added"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={3}>
              <Form.Field fluid="true">
                <Popup
                  trigger={<label>Concentration</label>}
                  content="Enter the Concentration."
                />
                <Input
                  id="concentration"
                  name="concentration"
                  style={{ opacity: 1, width: "80%", backgroundColor: "red" }}
                  // onChange={this.props.onChange}
                  value={
                    this.props.organism.inventory.concentration
                      ? this.props.organism.inventory.concentration
                      : ""
                  }
                  className="full_opacity"
                  disabled
                  icon="warning sign"
                  iconPosition="left"
                  placeholder="Concentration"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={3}>
              <Form.Field fluid="true">
                <Popup
                  trigger={<label>Total CFU</label>}
                  content="Enter Total CFU."
                />
                <Input
                  id="final_concentration"
                  name="final_concentration"
                  style={{ opacity: 1, width: "80%", backgroundColor: "red" }}
                  value={this.props.organism.total_cfu ? this.props.organism.total_cfu : ""}
                  className="full_opacity"
                  disabled
                  icon="warning sign"
                  iconPosition="left"
                  placeholder="Final Concentration"
                />
              </Form.Field>
            </Grid.Column>
            <br />
            <Grid.Column width={15}>
              <Form.Field fluid="true">
                <Popup
                  trigger={<label>Tags</label>}
                  content="Multiple Select Tags."
                />
                <Select
                  options={this.props.multiSelectData.filter(x => !this.props.organism.tags.find(y => (y.label == x.label)))}
                  value={this.props.organism.tags}
                  onChange={(e) => { this.changeTags(e, this.props.organism.id) }}
                  isMulti={true}
                />

              </Form.Field>
            </Grid.Column>
            <Grid.Column width={1} style={{ textAlign: "right" }}>
              <Form.Field fluid="true">
                <label style={{ visibility: "hidden" }}>.</label>
                <Popup
                  trigger={
                    <Button
                      onClick={() => {
                        const r = window.confirm("Do you really want to delete this organism?");
                        if (r == true) {
                          let organismIndex = this.props.inventory_options.findIndex(element => element.key == this.props.organism.inventory.id);
                          let zero = this.props.organism.amount_added * this.props.organism.inventory.concentration;
                          if (organismIndex >= 0) {
                            this.props.inventory_options[organismIndex].active = true

                          }
                          this.props.onDeleteRefillOrganismClick(
                            this.props.organism.inventory.organism_barcode,
                            this.props.organism.id,
                            this.props.organism.inventory.id,
                            this.props.organism.amount_added,
                            this.props.organism.final_concentration,
                            zero
                          )
                        } else {
                          return false;
                        }
                      }}
                      icon="delete"
                      disabled={this.props.is_disabled}
                      negative
                      circular
                    />
                  }
                  content="Delete this Organism."
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default RefillOrganism;
