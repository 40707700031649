
import React, { Component } from "react";

import { Segment, Grid } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import { onGridReady } from "../../helper";
import TagColumnRenderer from "./tagColumnRenderer";

let api, column_api;

class IngredientBarcodeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            samples: [],
            defaultColDef: {
                resizable: true,
                sortable: true,
                enableCellChangeFlash: true,
            },
            columnDefs: [
                {
                    headerName: "Ingredient Barcode",
                    field: "organism_barcode",
                    filter: "agTextColumnFilter",
                    width: 182
                },
                {
                    headerName: "Final Amount Added",
                    field: "final_amount_added",
                    width: 182,
                    filter: "agTextColumnFilter",
                    cellRenderer: (params) => {
                        return (
                            `
                            <div>
                            ${Math.floor(params.value * 1000) / 1000}
                            </div>
                            `
                        )
                    }
                },
                {
                    headerName: "Scrap Amount",
                    field: "scrap_amount",
                    filter: "agTextColumnFilter",
                    width: 182,
                    cellRenderer: (params) => {
                        return (
                            `
                          <div>
                          ${Math.floor(params.value * 1000) / 1000}
                          </div>
                          `
                        )
                    }
                },
                {
                    headerName: "Final Total CFU",
                    field: "total_cfu",
                    width: 182,
                    filter: "agTextColumnFilter",
                    cellRenderer: (params) => {
                        return (
                            `
                          <div>
                          ${Math.floor(params.value * 1000) / 1000}
                          </div>
                          `
                        )
                    }
                },
                // {
                //     headerName: "Total Final Concentration",
                //     field: "final_concentration",
                //     width: 190,
                //     filter: "agTextColumnFilter",
                //     cellRenderer: (params) => {
                //         return (
                //             `
                //           <div>
                //           ${Math.floor(params.value * 1000) / 1000}
                //           </div>
                //           `
                //         )
                //     }
                // },
                {
                    headerName: "Tags",
                    field: "inventory_tags",
                    width: 290,
                    cellRendererFramework: function (params) {
                        return <TagColumnRenderer
                            data={params}
                        />
                    },
                },
            ],

        };
    }


    render() {
        return (
            <>
                <Segment
                    compact
                    raised
                    padded
                    style={{ display: "block", marginTop: "0" }}
                >
                    <Grid columns="equal">
                        <Grid.Row>
                            <Grid.Column>
                                <div
                                    id="myGrid"
                                    className="ag-theme-balham"
                                    ag-grid="gridOptions"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    <AgGridReact
                                        rowHeight="30"
                                        rowSelection="multiple"
                                        onRowClicked={this.onRowClicked}
                                        onGridReady={params => {
                                            api = params.api;
                                            api.showLoadingOverlay();
                                            column_api = params.column_api;
                                            let initial_sort = [{ colId: "id", sort: "desc" }]
                                            onGridReady(
                                                params,
                                                api,
                                                column_api,
                                                initial_sort
                                            );
                                        }}
                                        animateRows={true}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationNumberFormatter={params => {
                                            return "[" + params.value.toLocaleString() + "]";
                                        }}
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        floatingFilter={true}
                                        rowData={this.props.samplesInventory}
                                        enableCellTextSelection={true}
                                        ensureDomOrder={true}
                                    />
                                </div>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </Segment>
            </>
        );
    }
}

export default IngredientBarcodeTable;
