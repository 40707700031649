//-------------------------------------------------------------------//
// Module: ProtectedRoute                                            //
// Tasks: 1) Checks if the user is authenticated & authorized        //
//            before entering into sensitive components              //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import Auth from "../authentication/Auth";

import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (Auth.isAuthenticated() && Auth.isAuthorized(rest)) {
          return <Component {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
