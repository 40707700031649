import React, { Component } from 'react'
import { Form, Input } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class StepQuit extends Component {
    state = {performed_by_pin:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:"",formErrors: {performed_by_pin: false,confirmed_by_pin:false}}

    onInputChange = (i)=>(e, { name, value }) => {              
        switch (name) {         
            case 'performed_by_pin':
                if( value.length >= 3){                    
                    if(this.state.confirmed_by_pin !== value){
                        this.getPerformUser(value, 'performer');
                    }else{
                        toast.error("Please enter the another pin");
                    }
                }else{
                    this.setState({performed_by:"",performed_by_name:""});
                }
                break;
            case 'confirmed_by_pin':
                if( value.length >= 3){
                    if(this.state.performed_by_pin !== value){
                        this.getPerformUser(value, 'confirmer');
                    }else{
                        toast.error("Please enter the another pin");
                    }                    
                }else{
                    this.setState({confirmed_by:"",confirmed_by_name:""});
                }
                break;
            default:
                break;
        }
            
      this.setState({ [name]: value });
      
    };
    getPerformUser = (pin,user_type)=>{
          
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
       
        axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
            if(res.data.success === true ){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState(prevState => ({
                        performed_by: user?user.id:"",
                        performed_by_name: user?user.full_name:"",
                        formErrors: {
                            ...prevState.formErrors,
                            performed_by_pin:user?false:true
                        }
                    }));
                }else{
                    this.setState(prevState=>({
                        confirmed_by: user?user.id:"",
                        confirmed_by_name: user?user.full_name:"",
                        formErrors:{
                            ...prevState.formErrors,
                            confirmed_by_pin:user?false:true
                        }
                    }));
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }
        })
    }

    handleSubmit = ()=>{        
        this.setState((prevState) => ({            
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                performed_by_pin: !this.state.performed_by_name?true:false,
                confirmed_by_pin: !this.state.confirmed_by_name?true:false,
            }
        }));
        
        if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
            toast.error("PIN is missing");
            return;
        }
        
        if ((!this.state.performed_by_name && this.state.performed_by_pin) || 
            (!this.state.confirmed_by_name && this.state.confirmed_by_pin) ) {
            toast.error("PIN is incorrect");
            return;
        }

        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:0,
            step_json:"",
            step_name:'quit',
            step_next:'',
            status:0,
            performed_by:this.state.performed_by_pin,
            confirmed_by:this.state.confirmed_by_pin
        }       
        axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onCloseModal();
          }else{
            toast.error("Step has not saved");
          }
        })
    }

    handleCancel=()=>{
        this.props.onCloseModal();
    }

    render() {
        console.log(this.state);
        const  {formErrors} = this.state;
        return (
            <>
             <Form onSubmit={this.handleSubmit}>
                <div className='cs-box'>
                    <div className='cs-body'>
                        <h3>Batch Abandonment</h3>
                        <div className='mt-2'>
                            <ul className='cs-list'>
                                <li>If an operator must halt production of product due to unacceptable process deviation, customer request, or other reasons requested by Management/Quality. </li>
                                <li>Operator records reason for abandonment and disposal method of unfinished product.</li>
                                <li>Operator, date, and time information is recorded via PIN.</li>
                                <li>Quality confirms reason and disposal method via secondary signoff via PIN.</li>
                                <li>Abandoned BPR is reviewed within the BPR Approval queue and maintained within S3 bucket.</li>
                                <li>MMR remains in queue, unless Management/Quality pulls MMR from production queue. Future BPR will have lot incremented to reflect abandoned lot (i.e. fmrx 12345_001 -&gt; fmrx_12345_002).</li>
                            </ul>
                        </div>
                        <div className='text-center mt-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            error={formErrors.performed_by_pin}
                                            id="performed_by_pin"
                                            name="performed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.performed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-2 mb-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            error={formErrors.confirmed_by_pin} 
                                            id="confirmed_by_pin"
                                            name="confirmed_by_pin"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.confirmed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                            <button type='button' onClick={this.handleCancel} className='ui icon secondary labeled button ps-1-5em'>Cancel</button>
                            <button type='submit' className='ui icon secondary labeled button quit-btn ps-1-5em ms-2' >Quit</button>
                        </div>
                    </div>
                </div>
                </Form>
            </>
        )
    }
}
export default StepQuit;