
import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import $ from 'jquery';

import CustomLoader from "../custom_elements/CustomLoader";
import CustomDivider from "../custom_elements/CustomDivider";
import { baseURL } from "../../configuration/constants";
import { onGridReady, getHeaders, focusFirstElement, getSessionStoredJsonItem, multi_v_availablity, checkValueExistOrNotInMultiVFun } from "../helper";
import Subs from "./sub2";
import ExistPrmTypeSection from "./ExistingPrm";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
    Grid,
    Form,
    Popup,
    Segment,
    Button,
    Input,
    TextArea
} from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

let api, column_api;
let Globalcolour = [];
let deleteTotal = 1;
let deleteFinalAmount = 0;
let ng = "";
let down = 0;

const options = [
    { value: 0 },
    { value: 1 },
    { value: "Powder" }
];

class ExistPrime extends Component {
    getInitState = () => {
        return {

            files: [],
            attachment_arr: [],
            delete_total_cfu: 0,
            FinalConcetration: 0,
            topOrganism: [],
            bottomOrganism: [],
            flourish_capsule_size: 0,
            number_of_months: 3,
            formulation_factor: 1,
            all: [
                {
                    "organism_barcode": "",
                    "qty": "",
                    "scrap_amount": "",
                    "concentration": "",
                    "id": ""
                }
            ],
            inventory_id: 0,
            is_prime_filler_amount_added_exceeding_qty: false,
            qty: "",
            scrap_factor: 0,
            scrap_amount: "",
            tableConcentration: 0,
            inventory: [],
            tableAmount: 0,
            tableFinalAmount: 0,
            is_submit: false,
            quantity: "",
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            is_page_loading: true,
            reorder_point: 0,
            storage_location: "",
            shelf: 0,
            admin_notes: "",
            concentration: 0,
            organism: [],
            total_amount_added: 0,
            total_final_concentration: 0,
            total_final_amount_added: 0,
            dataDown: [],
            columnDefs: [
                {
                    headerName: "Ingredient Code",
                    field: "organism_barcode",
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    sortable: true,
                    width: 140
                },
                {
                    headerName: "Concentration",
                    field: "concentration",
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    sortable: true,
                    width: 130
                },

                {
                    headerName: "Quantity",
                    field: "qty",
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    width: 125,
                },
                {
                    headerName: "Manufacturer",
                    field: "manufacture",
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    sortable: true,
                    width: 125
                },
                {
                    headerName: "Expiration Date",
                    field: "expiration_date",
                    sizeColumnToFit: true,
                    filter: "agTextColumnFilter",
                    width: 155
                },
                {
                    headerName: "Data Storage",
                    field: "storage_location",
                    filter: "agTextColumnFilter",
                    width: 130,
                    sortable: true
                },
                {
                    headerName: "Shelf",
                    field: "shelf",
                    filter: "agTextColumnFilter",
                    width: 130,
                    sortable: true
                },
                {
                    headerName: "Display Name",
                    field: "name",
                    filter: "agTextColumnFilter",
                    width: 180,
                    sortable: true
                },
            ],
            organism_barcode: "",
            existing_total_amount_added: 0,
            existing_final_amount_added: 0,
            existing_total_cfu: 0,
            AddOrgansimAmount: 0,
            amount_added: ""
        };
    };

    state = this.getInitState();

    tableData = (value, index) => {
        ng = value;
        console.log(value);
        this.setState({
            organism_barcode: value.organism_barcode
        })
        let tempArray = [];

        let x = value.formulation_data;
        x.filter((elem) => {
            let y = elem.organisms;
            y.filter((organism) => {
                let obj = {};
                if (organism.inventory != null && organism.inventory != undefined) {
                    obj = {
                        "amount_added": organism.amount_added,
                        "final_amount_added": organism.amount_added,
                        "organism_barcode": organism.inventory.organism_barcode,
                        "total_cfu": organism.inventory.concentration * organism.amount_added,
                        "final_concentration": organism.final_concentration,
                        "inventory_id": organism.inventory_id,
                        "scrap_amount": organism.scrap_amount,
                        "qty": organism.inventory.qty,
                        "concentration": organism.inventory.concentration * organism.amount_added,
                        "is_available": organism.inventory.is_available,
                        "concentration_visible": organism.inventory.concentration
                    }
                    tempArray.push(obj);
                    const result = tempArray.reduce((acc, value) => ({
                        amount_added: parseFloat(acc.amount_added) + parseFloat(value.amount_added),
                    }));

                    const concentration = tempArray.reduce((acc, value) => ({
                        concentration: acc.concentration + value.concentration,
                    }));

                    let total_final_amount_added = this.state.formulation_factor * result.amount_added;
                    let total_cfu = concentration.concentration + parseFloat(down);

                    this.setState({
                        existing_total_amount_added: result.amount_added,
                        existing_final_amount_added: total_final_amount_added,
                        existing_total_cfu: concentration.concentration,
                        tableAmount: result.amount_added + parseFloat(this.state.total_amount_added),
                        tableFinalAmount: total_final_amount_added,
                        tableConcentration: total_cfu / result.amount_added,
                        delete_total_cfu: concentration.concentration,
                        dataDown: tempArray,
                        topOrganism: tempArray,
                        
                    })
                }
            })
        })
        this.setState({showStore: true})
    }


    componentDidMount = () => {
        this.fetchData();
    }

    fetchData = () => {
        axios
            .get(baseURL + "/inventory/inventoryOptions?type=pre,pro", getHeaders())
            .then((res => {
                let tempArray = [];
                tempArray.push(...res.data);

                this.onInputChangeSub(tempArray, this.state.organism_barcode);
                this.setState({
                    all: tempArray
                })
            }))
    }

    AnswerKeyPress = (e) => {
        let code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    change = (value) => {
        this.setState({
            flourish_capsule_size: value
        })
    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    onInputChangeSub = (array, value) => {
        this.setState({
            organism_barcode: value
        })
        let concentration;
        let id;
        let organism_barcode;
        let scrap_amount;
        let qty;
        let scrap_factor;
        let tempArray = array
        tempArray.filter((elem) => {
            if (elem.organism_barcode === value) {
                concentration = elem.concentration;
                id = elem.id
                organism_barcode = elem.organism_barcode
                scrap_amount = elem.scrap_factor
                qty = elem.qty
                scrap_factor = elem.scrap_factor
                this.setState({
                    concentration: concentration,
                    inventory_id: id,
                    organism_barcode: organism_barcode,
                    scrap_amount: scrap_amount,
                    qty: qty,
                    scrap_factor: scrap_factor
                })
            }
        })
    }

    monthChanges = (e, { name, value }) => {
        let formulation = Number((value / 3).toFixed(3));
        let b = Number(deleteFinalAmount) + Number(this.state.existing_final_amount_added)
        let a = b * formulation
        this.setState({
            [name]: value,
            formulation_factor: formulation,
            number_of_months: value,
            tableFinalAmount: a,
            AddOrgansimAmount: a
        })

        let tempArray = [];
        let x = ng.formulation_data
        x.filter((elem) => {
            let y = elem.organisms;
            y.filter((organism) => {
                let obj = {}

                if (organism.inventory != null && organism.inventory != undefined) {

                    let x = organism.inventory.concentration * organism.amount_added;
                    obj = {
                        "amount_added": organism.amount_added,
                        "final_amount_added": formulation * organism.amount_added,
                        "organism_barcode": organism.inventory.organism_barcode,
                        "total_cfu": x * formulation,
                        "final_concentration": organism.final_concentration,
                        "inventory_id": organism.inventory_id,
                        "scrap_amount": organism.scrap_amount,
                        "qty": organism.inventory.qty,
                        "is_available": organism.inventory.is_available
                    }
                    tempArray.push(obj);
                    this.setState({
                        topOrganism: tempArray,
                        bottomOrganism: [],
                        tableFinalAmount: this.state.tableAmount * formulation
                    })
                }
            })
        })

    };

    onInputChanges = (e, { name, value }) => {
        if (this.state.inventory_id === 0) {
            alert("Please select both the Ingredient Barcode and the Amount added!");
            return;
        }
        let AmountAfterFormulation = this.state.formulation_factor * value;
        this.setState({
            tableFinalAmount: value,
            tableAmount: value,
            [name]: value,
            AddOrgansimAmount: AmountAfterFormulation,
            tableConcentration: this.state.concentration * value,
            FinalConcetration: this.state.concentration * value
        })

        if (value > this.state.qty) {
            this.setState({
                amount_added: this.state.qty,
                is_prime_filler_amount_added_exceeding_qty: true
            })
        }
        $('#NumericTextBox_prm').keypress(function (event) {
            if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
            if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {
                event.preventDefault();
            }
        });
    };


    onAddOrganismClick = () => {
        if (this.state.amount_added == 0 || this.state.inventory_id === 0 || this.state.amount_added === "") {
            alert("Please select both the Ingredient Barcode and the Amount added!");
            return;
        }
        let tempArray = this.state.inventory;
        let obj = {};
        obj = {
            "amount_added": Number(this.state.amount_added),
            "final_amount_added": Number(this.state.AddOrgansimAmount),
            "organism_barcode": this.state.organism_barcode,
            "total_cfu": this.state.concentration * this.state.amount_added,
            "final_concentration": this.state.concentration,
            "inventory_id": this.state.inventory_id,
            "concentration": this.state.concentration * this.state.amount_added,
            "scrap_amount": Number((Number(this.state.scrap_factor) * Number(this.state.amount_added)) / 100).toFixed(3)
        }
        tempArray.push(obj);

        const result = tempArray.reduce((acc, value) => ({
            amount_added: parseFloat(acc.amount_added) + parseFloat(value.amount_added),
        }));
        deleteTotal = result.amount_added;

        // const resultFinalAmount = tempArray.reduce((acc, value) => ({
        //     final_amount_added: acc.final_amount_added + value.final_amount_added,
        // }));
        // deleteFinalAmount = resultFinalAmount.final_amount_added;

        const concentration = tempArray.reduce((acc, value) => ({
            concentration: acc.concentration + value.concentration,
        }));
        down = concentration.concentration;


        let b = this.state.existing_total_amount_added + Number(deleteTotal);
        var SelectedOrganismBarcode = this.state.organism_barcode;
        var temp = this.state.all
        temp.filter((elem, index) => {
            if (elem.organism_barcode === SelectedOrganismBarcode) {
                temp.splice(index, 1);
            }
        })
        let total_cfu = down + this.state.existing_total_cfu;
        let amount_added_table_insert = this.state.existing_total_amount_added + parseFloat(deleteTotal)
        this.setState({
            tableConcentration: total_cfu / amount_added_table_insert,
            // total_final_concentration: Total_CFU.total_cfu,
            tableFinalAmount: b * this.state.formulation_factor,
            tableAmount: this.state.existing_total_amount_added + parseFloat(deleteTotal),
            total_amount_added: result.amount_added,
            all: temp,
            inventory: tempArray,
            bottomOrganism: tempArray,
            delete_total_cfu: concentration.concentration + this.state.existing_total_cfu
        })
        this.setState(this.resetOrganismState());
    }

    resetOrganismState = () => {
        return {
            FinalConcetration: 0,
            organism_barcode: "",
            inventory_id: 0,
            amount_added: "",
            concentration: 0,
            total_final_concentration: 0,
            scrap_amount: 0,
            final_amount_added: 0,
        };
    };

    onSubmitClick = () => {

        if (Globalcolour.includes("yellow")) {
            toast.warn("Added organism is not available any more. Hence, either remove organism or replace it with other oraganism before clicking on Submit .");
            return;
        }
        if (Globalcolour.includes("red")) {
            toast.warn("Added organism is not available any more. Hence, either remove organism or replace it with other oraganism before clicking on Submit .");
            return;
        }
        let { quantity, organism } = this.state;
        if (!quantity || !organism) {
            toast.info("Please fill all the mandatory information.");
            return;
        }
        this.setState({
            is_saving_successful: false,
            is_saving_failed: false,
            is_submit: true,
        });
        let res = _.pick(this.state, [
            "organism",
            "reorder_point",
            "storage_location",
            "shelf",
            "admin_notes",
            "total_amount_added",
            "total_final_concentration",
            "total_final_amount_added",
            "quantity",

            "qty_type",
            "concentration",
            "organism_barcode",
            "number_of_months",
            "attachment_arr"
        ]);
        res.organism = this.state.topOrganism.concat(this.state.bottomOrganism);
        res.total_amount_added = this.state.tableAmount;
        res.total_final_amount_added = this.state.tableFinalAmount;
        res.total_final_concentration = this.state.tableConcentration;
        // res.concentration = this.state.tableConcentration;
        res.flourish_capsule_size = this.state.flourish_capsule_size;

        res.user_id = getSessionStoredJsonItem("user").user_id;
        this.setState({ is_waiting_for_response: true });

        axios
            .post(baseURL + "/add_for_prm?type=prm", res, getHeaders())
            .then(response => {
                toast.success("Inventory Added Successfully" + "  Barcode is  " + response.data.barcode);
                let init_state = this.getInitState();
                init_state["is_page_loading"] = false;
                init_state["is_waiting_for_response"] = false;
                window.location.reload();
                this.setState(init_state);
                focusFirstElement();
            })
    }

    delete = (index, elem) => {
        let reducedTableAmount = this.state.tableAmount - elem.amount_added;
        let reducedTableFinalAmount = this.state.tableFinalAmount - this.state.formulation_factor * elem.amount_added;
        let delete_total_cfu = this.state.delete_total_cfu - elem.concentration;
        let reducedTableConcentration = delete_total_cfu / reducedTableAmount;
        let tempArray = this.state.dataDown;
        tempArray.splice(index, 1);
        if (reducedTableAmount === 0) {
            this.setState({
                topOrganism: tempArray,
                tableFinalAmount: reducedTableFinalAmount,
                existing_total_amount_added: reducedTableAmount,
                dataDown: tempArray,
                tableAmount: reducedTableAmount,
                tableConcentration: 0,
                delete_total_cfu: delete_total_cfu,
                existing_total_cfu: delete_total_cfu,
                existing_final_amount_added: reducedTableFinalAmount
            })
        }
        else {
            this.setState({
                topOrganism: tempArray,
                tableFinalAmount: reducedTableFinalAmount,
                dataDown: tempArray,
                existing_total_amount_added: reducedTableAmount,
                tableAmount: reducedTableAmount,
                tableConcentration: reducedTableConcentration,
                existing_total_cfu: delete_total_cfu,
                delete_total_cfu: delete_total_cfu,
                existing_final_amount_added: reducedTableFinalAmount
            })
        }


        if (this.state.dataDown.length == 0) {
            this.setState({
                existing_total_amount_added: 0,
                existing_final_amount_added: 0,
                existing_total_cfu: 0
            })
        }
    }

    deleteDown = (index, elem) => {
        let findScrapAmount = Number(elem.scrap_amount) * 100;
        let scrap_amount = findScrapAmount / elem.amount_added
        let roc = scrap_amount / 100;
        this.state.all.push({
            organism_barcode: elem.organism_barcode,
            concentration: elem.final_concentration,
            id: elem.inventory_id,
            scrap_factor: roc
        })
        let reducedTableAmount = this.state.tableAmount - elem.amount_added;
        let reducedTableFinalAmount = this.state.tableFinalAmount - this.state.formulation_factor * elem.amount_added;
        let delete_total_cfu = this.state.delete_total_cfu - elem.total_cfu;
        let reducedTableConcentration = delete_total_cfu / reducedTableAmount;
        let secondTemp = this.state.inventory;
        secondTemp.splice(index, 1);
        if (reducedTableAmount === 0) {
            this.setState({
                bottomOrganism: secondTemp,
                tableFinalAmount: reducedTableFinalAmount,
                tableAmount: reducedTableAmount,
                tableConcentration: 0,
                inventory: secondTemp,
                delete_total_cfu: delete_total_cfu
            })
        }
        else {
            this.setState({
                bottomOrganism: secondTemp,
                tableFinalAmount: reducedTableFinalAmount,
                tableAmount: reducedTableAmount,
                tableConcentration: reducedTableConcentration,
                inventory: secondTemp,
                delete_total_cfu: delete_total_cfu
            })
        }

    }

    onClickOutside = () => {
        this.setState({
            is_prime_filler_amount_added_exceeding_qty: false,
        });
    }

    refreshHandlers = (value) => {
        let tempArray = [];
        if (value && value.length) {
            value.map((elem, index) => {
                if (elem.organism_barcode === this.state.organism_barcode) {
                    let x = elem.formulation_data;
                    x.filter((elem) => {
                        let y = elem.organisms;
                        y.filter((organism) => {
                            let obj = {};
                            if (organism.inventory != null && organism.inventory != undefined) {

                                obj = {
                                    "amount_added": organism.amount_added,
                                    "final_amount_added": organism.amount_added,
                                    "organism_barcode": organism.inventory.organism_barcode,
                                    "total_cfu": organism.final_concentration * organism.amount_added,
                                    "final_concentration": organism.final_concentration,
                                    "inventory_id": organism.inventory_id,
                                    "scrap_amount": organism.scrap_amount,
                                    "qty": organism.inventory.qty,
                                    "is_available": organism.inventory.is_available
                                }
                                tempArray.push(obj);
                            }
                        })
                    })
                }
            })
            this.setState({
                dataDown: tempArray
            })
        }
    }

    onupdatefiles = (fileItems) => {
        const scope = this;

        let attachment_arr_temp = this.state.attachment_arr;
        attachment_arr_temp = [];
        fileItems.map((elem, index) => {
            let mediaTypePdf = elem.file.type.split("/")[1];
            let mediaSize = elem.file.size;
            let mediaType = elem.file.type;
            let mediaName = elem.file.name;
            var selectedFile = elem.file;
            if (selectedFile) {
                var fileToLoad = selectedFile;
                var fileReader = new FileReader();
                var base64;
                fileReader.readAsDataURL(fileToLoad);
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let res = base64.slice(28);
                    if (mediaTypePdf === "pdf" && mediaSize <= 5242880) {
                        let obj = {};
                        obj = {
                            "base64Media": res,
                            "mediaName": mediaName,
                            "type": mediaType,
                            "size": mediaSize
                        }
                        attachment_arr_temp.push(obj);
                        const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                        scope.setState({
                            attachment_arr: final_array
                        })
                    }
                };
            }
        })
    }


    handleRemove = () => {
        const scope = this;
        this.setState({
            attachment_arr: []
        })
        let attachment_arr_temp = this.state.attachment_arr;
        if (this.pond) {
            const remainingAttachments = this.pond.getFiles().map((file) => file.source);
            remainingAttachments.map((elem, index) => {
                let mediaSize = elem.size;
                let mediaType = elem.type;
                let mediaName = elem.name;
                var selectedFile = remainingAttachments[0];
                if (selectedFile) {
                    var fileToLoad = selectedFile;
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        let res = base64.slice(28);
                        if (mediaSize <= 5242880) {
                            let obj = {};
                            obj = {
                                "base64Media": res,
                                "mediaName": mediaName,
                                "type": mediaType
                            }
                            attachment_arr_temp.push(obj);
                            const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                            scope.setState({
                                attachment_arr: final_array
                            })
                        }
                    };

                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
    }

    render() {

        return (
            <React.Fragment>
                <br />
                <Segment className="transparent">

                    <Grid.Row>
                        <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column>
                                    <div
                                        id="myGrid"
                                        className="ag-theme-balham"
                                        ag-grid="gridOptions"
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <AgGridReact
                                            rowHeight="30"
                                            rowSelection="single"
                                            onRowClicked={e => {
                                                let sample = e.data;
                                                this.tableData(sample)
                                            }}
                                            onGridReady={params => {
                                                api = params.api;
                                                api.showLoadingOverlay();
                                                api.sizeColumnsToFit();
                                                column_api = params.column_api;
                                                onGridReady(
                                                    params,
                                                    api,
                                                    column_api,
                                                    this.props.initial_sort
                                                );
                                            }}
                                            animateRows={true}
                                            pagination={true}
                                            paginationPageSize={10}
                                            paginationNumberFormatter={params => {
                                                return "[" + params.value.toLocaleString() + "]";
                                            }}
                                            columnDefs={this.state.columnDefs}
                                            floatingFilter={true}
                                            rowData={this.props.samples}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Row>
                    <Grid.Row />
                </Segment>

                <Segment className="transparent" style={{ display: this.state.showStore ? 'block' : 'none', marginTop: "70px" }}>
                    {this.state.is_waiting_for_response && (
                        <CustomLoader loading_text="Saving Inventory Data" />
                    )}
                    <Form>
                        <br />
                        <Grid columns="equal">
                            <CustomDivider
                                h_size="h3"
                                title="Contained Ingredients"
                                icon_name="dna"
                            />
                            <Grid.Column>
                                <ExistPrmTypeSection
                                    formulation_factor={this.state.formulation_factor}
                                    number_of_months={this.state.number_of_months}
                                    AnswerKeyPress={this.AnswerKeyPress}
                                    quantity={this.state.quantity}
                                    reorder_point={this.state.reorder_point}
                                    options={options}
                                    selectCapsuleSizeFunction={this.change}
                                    flourish_capsule_size={this.state.flourish_capsule_size}

                                    onInputChange={this.onInputChange}
                                    monthChanges={this.monthChanges} />
                            </Grid.Column>
                            {this.state.dataDown &&
                                this.state.dataDown.map((elem, index) => {
                                    let colour;
                                    if (index === 0) {
                                        Globalcolour = [];
                                    }
                                    if (elem.is_available == 0) {
                                        colour = "red"
                                        Globalcolour.push(colour);
                                    }
                                    else if (elem.is_available == 0 && elem.final_amount_added == 0) {
                                        colour = "red"
                                        Globalcolour.push(colour);
                                    }
                                    else if (elem.is_available == 0 && elem.final_amount_added < elem.qty) {
                                        colour = "red"
                                        Globalcolour.push(colour);
                                    }
                                    else if (elem.qty == 0) {
                                        colour = "red"
                                        Globalcolour.push(colour);
                                    }
                                    else if (elem.is_available == 1 && elem.amount_added > elem.qty) {
                                        colour = "yellow"
                                        Globalcolour.push(colour);
                                    }
                                    else if (elem.is_available == 1 && elem.amount_added <= elem.qty) {
                                        colour = "green"
                                        Globalcolour.push(colour);
                                    }

                                    return (
                                        <React.Fragment key={index}>
                                            <Grid.Row className="block">
                                                <Grid.Column width={5}>
                                                    <Form.Field required fluid="true">
                                                        <Popup
                                                            trigger={<label>Ingredient Barcode</label>}
                                                            content="Enter the Ingredient Barcode."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1, backgroundColor: colour }}
                                                            value={elem.organism_barcode}
                                                            id="barcode"
                                                            disabled
                                                        >
                                                        </Input>
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={4}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Added</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1, width: "80%", }}
                                                            icon="weight"
                                                            iconPosition="left"
                                                            label={{ content: "g" }}
                                                            labelPosition="right"
                                                            value={Math.floor(elem.amount_added * 1000) / 1000}
                                                            disabled
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    <Form.Field fluid="true">
                                                        <Popup
                                                            trigger={<label>Concentration</label>}
                                                            content="Enter the Concentration."
                                                        />
                                                        <Input
                                                            className="full_opacity"
                                                            name="concentration"
                                                            disabled
                                                            icon="warning sign"
                                                            iconPosition="left"
                                                            placeholder="Concentration"
                                                            value={Math.floor(elem.concentration_visible * 1000) / 1000}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    <Form.Field fluid="true">
                                                        <Popup
                                                            trigger={<label>Total CFU</label>}
                                                            content="Enter the Final Concentration."
                                                        />
                                                        <Input
                                                            className="full_opacity"
                                                            disabled
                                                            icon="warning sign"
                                                            iconPosition="left"
                                                            placeholder="Final Concentration"
                                                            value={Math.floor(elem.concentration * 1000) / 1000}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={1} style={{ textAlign: "right" }}>
                                                    <Form.Field fluid="true">
                                                        <label style={{ visibility: "hidden" }}>.</label>
                                                        <Popup
                                                            trigger={
                                                                <Button
                                                                    icon="delete"
                                                                    negative
                                                                    circular
                                                                    onClick={() => this.delete(index, elem)}
                                                                />
                                                            }
                                                            content="Delete this Organism."
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    )
                                })
                            }

                            {this.state.inventory &&
                                this.state.inventory.map((elem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid.Row className="block">
                                                <Grid.Column width={11}></Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row className="block" >
                                                <Grid.Column width={5}>
                                                    <Form.Field required fluid="true">
                                                        <Popup
                                                            trigger={<label>Ingredient Barcode</label>}
                                                            content="Enter the Ingredient Barcode."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1, backgroundColor: "rgb(184, 252, 255)" }}
                                                            value={elem.organism_barcode}
                                                            disabled
                                                        >
                                                        </Input>
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={4}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Added</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1, width: "80%", }}
                                                            icon="weight"
                                                            iconPosition="left"
                                                            label={{ content: "g" }}
                                                            labelPosition="right"
                                                            value={Math.floor(elem.amount_added * 1000) / 1000}
                                                            disabled
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    <Form.Field fluid="true">
                                                        <Popup
                                                            trigger={<label>Concentration</label>}
                                                            content="Enter the Concentration."
                                                        />
                                                        <Input
                                                            className="full_opacity"
                                                            name="concentration"
                                                            disabled
                                                            icon="warning sign"
                                                            iconPosition="left"
                                                            placeholder="Concentration"
                                                            value={Math.floor(elem.final_concentration * 1000) / 1000}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    <Form.Field fluid="true">
                                                        <Popup
                                                            trigger={<label>Total CFU</label>}
                                                            content="Enter the Final Concentration."
                                                        />
                                                        <Input
                                                            className="full_opacity"
                                                            disabled
                                                            icon="warning sign"
                                                            iconPosition="left"
                                                            placeholder="Final Concentration"
                                                            value={Math.floor(elem.concentration * 1000) / 1000}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={1} style={{ textAlign: "right" }}>
                                                    <Form.Field fluid="true">
                                                        <label style={{ visibility: "hidden" }}>.</label>
                                                        <Popup
                                                            trigger={
                                                                <Button
                                                                    icon="delete"
                                                                    negative
                                                                    circular
                                                                    onClick={() => this.deleteDown(index, elem)}
                                                                />
                                                            }
                                                            content="Delete this Organism."
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    )
                                })
                            }

                            <Grid.Row>
                                <Grid.Column>
                                    <CustomDivider
                                        h_size="h3"
                                        title="New Ingredients"
                                        icon_name="plus"
                                    />
                                    <Subs
                                        onInputChanges={this.onInputChanges}
                                        onInputChangeSub={this.onInputChangeSub}
                                        all={this.state.all}
                                        amount_added={this.state.amount_added}
                                        concentration={this.state.concentration}
                                        inventory={this.state.inventory}
                                        onAddOrganismClick={this.onAddOrganismClick}
                                        onClickOutside={this.onClickOutside}
                                        qty={this.state.qty}
                                        is_prime_filler_amount_added_exceeding_qty={this.state.is_prime_filler_amount_added_exceeding_qty}
                                        fetchDataSub={this.fetchData}
                                        refreshHandlers={this.refreshHandlers}
                                        tableAmount={this.state.tableAmount}
                                        organism_barcode={this.state.organism_barcode}
                                        tableConcentration={this.state.tableConcentration}
                                        tableFinalAmount={this.state.tableFinalAmount}
                                        FinalConcetration={this.state.FinalConcetration}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row />
                            <Grid.Row>
                                <Grid.Column>
                                    <Popup
                                        trigger={<label>Notes</label>}
                                        content="Enter notes."
                                    />
                                    <TextArea
                                        name="admin_notes"
                                        onChange={this.onInputChange}
                                        value={this.state.admin_notes}
                                        placeholder="Add Notes here..."
                                        style={{ minHeight: 100 }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />

                            <CustomDivider
                                h_size="h3"
                                title="Storage Information"
                                icon_name="dropbox"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Storage Location</label>}
                                            content="Enter the Organism Storage Location (Fridge Number)."
                                        />
                                        <Input
                                            placeholder="Storage Location (Fridge Number)"
                                            icon="boxes"
                                            iconPosition="left"
                                            name="storage_location"
                                            onChange={this.onInputChange}
                                            value={this.state.storage_location}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Shelf</label>}
                                            content="Enter the Organism Shelf Number."
                                        />
                                        <Input
                                            placeholder="Shelf"
                                            icon="box"
                                            iconPosition="left"
                                            name="shelf"
                                            onChange={this.onInputChange}
                                            value={this.state.shelf}
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <CustomDivider
                                h_size="h3"
                                title="Upload Documents"
                                icon_name="upload"
                            />
                            <Grid.Column>
                                <FilePond
                                    acceptedFileTypes={['application/pdf']}
                                    ref={ref => (this.pond = ref)}
                                    allowMultiple={true}
                                    allowReorder={true}
                                    allowRemove={true}
                                    maxFiles={10}
                                    allowFileSizeValidation={true}
                                    maxFileSize="5MB"
                                    onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                                    onremovefile={this.handleRemove}
                                    checkValidity={true}
                                    dropValidation
                                />
                            </Grid.Column>
                            <Grid.Row />
                            <Grid.Row />

                            <Grid.Row>
                                <Grid.Column>
                                    <Grid textAlign="center">
                                        <Button.Group>
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onSubmitClick} positive>
                                                        Submit
                                                    </Button>
                                                }
                                                content="Submit the Organism Information to the Database."
                                            />
                                            <Button.Or />
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onCancelClick}>Cancel</Button>
                                                }
                                                content="Discard the Organism Informtaion."
                                            />
                                        </Button.Group>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Form>

                </Segment>
            </React.Fragment>
        );
    }
}

export default ExistPrime;