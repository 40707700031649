
import React, { Component } from "react";

import { Segment, Grid, Popup, Form,Input} from "semantic-ui-react";

import "./inventory.css";
import CustomDivider from "../../custom_elements/CustomDivider";

const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
};

class TotalUsageInfo extends Component {

    render() {
        const { days_untill_run_out, remaining_amount, avg, mode, percentage} = this.props;
        return (
            <>
                <Grid.Row>
                    <Grid.Column>
                        <Segment className="transparent">
                            <CustomDivider
                                h_size="h3"
                                title="Total Usage Info"
                                icon_name="info"
                            />
                            <Grid columns="equal">
                                <div style={{ fontWeight: "bold", paddingTop: "15px" }}>Total Usage Info </div>

                                <Grid.Row className="block" >
                                    <Grid.Column width={3}>
                                        <Form.Field required fluid="true">
                                            <Popup
                                                trigger={<label>Days Until Run Out</label>}
                                                content="Estimated # of days until quantity (g) reaches 0"
                                            />
                                            <Input
                                                style={inventory}
                                                fluid
                                                value={days_untill_run_out === "NA" ? "NA" : parseFloat(days_untill_run_out)}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Form.Field required fluid="true" >
                                            <Popup
                                                trigger={<label>Amount Remaining (g)</label>}
                                                content="Sum of quantity (g) of all lots of the ingredient"
                                            />
                                            <Input
                                            style={inventory}
                                                fluid
                                                placeholder="Amount Remaining"
                                                value={remaining_amount === "NA" ? "NA" : parseFloat(remaining_amount)}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Form.Field required fluid="true" >
                                            <Popup
                                                trigger={<label>Average (g)</label>}
                                                content=" Amount (g) of ingredient used in each formula on average"
                                            />
                                            <Input
                                                style={inventory}
                                                fluid
                                                placeholder="Average (g)"
                                                value={avg === "NA" ? "NA" : parseFloat(avg)}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <div style={{ marginTop: "75px" }}></div>

                                    <Grid.Column width={3}>
                                        <Form.Field required fluid="true" >
                                            <Popup
                                                trigger={<label>Mode (g)</label>}
                                                content="Amount (g) of ingredient used in each formula most frequently"
                                            />
                                            <Input
                                                style={inventory}
                                                fluid
                                                placeholder="Mode"
                                                value={mode === "" ? "NA" : mode}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Form.Field required fluid="true" >
                                            <Popup
                                                trigger={<label>Percent (%)</label>}
                                                content="Percent of formulas the ingredient appears in"
                                            />
                                            <Input
                                                style={inventory}
                                                fluid
                                                placeholder="Percentage"
                                                value={percentage === "NA" ? "NA" : parseFloat(percentage)}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>

            </>
        );
    }
}

export default TotalUsageInfo;
