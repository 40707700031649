//------------------------------------------------------------------//
//Module: Home                                                      //
//Tasks: 1) Redirects to RoutingInfo                                //
//       2) Redirects to NavBar                                     //
//Author: Yash P Shah                                               //
//------------------------------------------------------------------//

import NavBar from "./header/NavBar.jsx";
import RoutingInfo from "./RoutingInfo";

import React, { Component } from "react";
import { Container } from "semantic-ui-react";

class Home extends Component {
 
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="mainArea">
          <Container>
            <RoutingInfo />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
