import React, { Component } from 'react';
import { Header, Message, Segment } from 'semantic-ui-react';

export class InfluencedMicrobes extends Component {
    render() {
        return (<>
            <div className="influenced-box" style={{ marginTop: "7px" }}>
                <Message style={{ marginBottom: "0px" }}>
                    <Header as="h2" textAlign="center"> Influenced Microbes </Header>
                </Message>
                <Segment style={{ height: "16rem", overflowY: "scroll", marginTop: "0px" }} className="mircobes_segment" >
                    <table>
                        <thead>
                            {(this.props.influenced_microbes_heading.length > 0) && (
                                <tr style={{ backgroundColor: "#fff", borderCollapse: "collapse" }}>
                                    <th> Microbes </th>
                                    {this.props.influenced_microbes_heading && this.props.influenced_microbes_heading.map((heading) => {
                                        return <th>{heading}</th>
                                    })}
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {this.props.influenced_microbes && this.props.influenced_microbes.map((microbies) => {
                                return <tr>
                                    <td >{microbies.microbes}</td>
                                    {microbies.t1_abundances && <td >{microbies.t1_abundances}</td>}
                                    {microbies.t2_abundances && <td> {microbies.t2_abundances}</td>}
                                    {microbies.trends && <td>{microbies.trends && microbies.trends}</td>}
                                </tr>
                            })}
                        </tbody>

                    </table>

                </Segment>
            </div>
        </>)
    }
}