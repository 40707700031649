import CustomLoader from "../../../custom_elements/CustomLoader";
import React, { Component } from "react";
import { Button, Icon, Modal, Segment, Grid, Form, Popup, Input, TextArea } from "semantic-ui-react";
import CustomDivider from "../../../custom_elements/CustomDivider";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseURL } from "../../../../configuration/constants";


import { getHeaders } from "../../../helper";
import ShowModal from "../../../Pop Up/Modal";

var values = [];
var currentindex = 3;
var is_disabled_value = 0;
var disabled_submit_button = false;

let warning = '';
let popUp_description="";
class StartFormulationModals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sample_id: " ",
      formula_id: " ",
      formulation_id: " ",
      formula_type:" ",
      otherValue: '',
      is_disabled: true,
      reason_for_reformulation: [],
      number_of_months: "",
      number_of_months_others: "",
      notes: "",
      is_popUp_disabled:false,
      disabled_submit_button: false,
      qc_out_of_spec:0
    }
  }



  submitform = () => {
    var all_reson = [];
    all_reson = values;
    console.log(values);
    if (this.state.number_of_months_others === "" && (this.state.number_of_months == "" || this.state.number_of_months == undefined)) {
      alert("Please Select Number of Months");
      return;
    }

    if (this.state.otherValue !== '') {
      all_reson.push(this.state.otherValue);
    }


    if (all_reson.length == 0 && this.props.is_disabled) {
      alert("please Select At least one reason");
      return;
    }
    this.setState({ disabled_submit_button: true });

    var reformulationData = {
      "formulation_id": this.props.formulation_id,
      "sample_id": this.props.sample_id,
      "formula_id": this.props.formula_id,
      "formula_type":this.props.formula_type,
      "no_of_months": this.state.number_of_months ? this.state.number_of_months : this.state.number_of_months_others,
      "notes": this.state.notes,
      "type": (this.props.is_disabled) ? "all_formulations" : "manufacturing_queue"
    };
    
    reformulationData.type = (this.props.callFrom == "refill") && 'refill';

    reformulationData.manufacturing_queue =  reformulationData.type === "manufacturing_queue" && this.props.manufactring_organisms 

    if (this.props.is_disabled) {
      reformulationData["reasons"] = all_reson;
    }

    axios.post(baseURL + `/save-reformulation-complaint`, reformulationData, getHeaders())
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => { window.location.reload(); }, 2000)
      })
      .catch(err => {
        this.setState({ disabled_submit_button: false });
        toast.error(err);
      })
  }

  deSelect_other_months = () => {
    const checkboxes = document.querySelector(`input[name=number_of_months_others]`);
    checkboxes.checked = false;
    this.setState({ is_disabled: true, number_of_months_others: "" })

    var number_of_months = document.getElementsByName('number_of_months');

    let chosen;
    let len = number_of_months.length;
    for (var i = 0; i < len; i++) {
      if (number_of_months[i].checked) {
        currentindex = (this.props.is_disabled) ? i : i + 1;
        chosen = parseInt(number_of_months[i].value)
        this.setState({ number_of_months: chosen })
      }
    }
    currentindex = chosen;
  }
  unselectCheckbox = (e) => {
    this.setState({ otherValue: e.target.value })
  }

  getSelectedCheckboxValues = (e) => {
    currentindex = '';
    const checkboxes = document.querySelectorAll(`input[name=number_of_months]`);
    this.setState({ is_disabled: false, number_of_months: "" })
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    if (e.target.value == '') {
      alert("please Select value")
    }
  }
  reason_reformulation = (e) => {
    const checkboxes = document.querySelectorAll(`input[name=reason_for_reformulation]:checked`);
    var reason_values = [];
    checkboxes.forEach((checkbox) => {
      reason_values.push(checkbox.value);
    });
    values = reason_values;
  }

  componentDidMount = () => {
    currentindex = (this.props.is_disabled) ? "" : currentindex;
    // values=["Ingredient with insufficient quantity"]
    this.setState({
      sample_id: this.props.sample_id,
      formula_id: this.props.formula_id,
      formulation_id: this.props.formulation_id,
      formula_type:this.props.formula_type,
      number_of_months: currentindex
    });
  }
  onCloseModal = () => {
    currentindex = (this.props.is_disabled) ? "" : currentindex;
    this.setState({
      otherValue: '',
      is_disabled: true,
      reason_for_reformulation: [],
      number_of_months: (this.props.is_disabled) ? "" : currentindex,
      number_of_months_others: "",
      notes: "",
    })
    values = [];
  }


  number_of_months_others = (e) => {
    if (e.target.value < 0) {
      alert("Please Select Positive Value");
      return;
    }
    this.setState({ number_of_months_others: e.target.value })

  }
  Modal_return_value = (value, message) => {
    if (value) {
      setTimeout(() => {
        if (
          (this.state.flourish_fill_qc_weight < 0.38 || this.state.flourish_fill_qc_weight > 0.5) &&
          warning === ""
        ) {
          warning = "quantitiy_warning";
          popUp_description =
            "Warning! The Total Amount Added  for the formula you're about to create is different from what is expected! Do you still want to proceed?";

          this.setState({ is_popUp_disabled: true });
          return;
        } else {
          this.next();
        }
        return;
      }, 500);
    } else {
      warning = "";
      return;
    }
  };
  // set pop_up value
  pop_up_open = () => {
    this.setState({ is_popUp_disabled: false });
  };
  
  render() {

    let { sample_id, formula_id, formulation_id, is_disabled,formula_type } = this.props;
    // console.log(sample_id, formula_id, formulation_id);

    is_disabled_value = (!is_disabled) ? 4 : 5;
    var reason_of_reformulation1 = [
      "Brain fog", "Gained weight", "Hard stools", "Insomnia", "Lost weight", "Need higher CFU", "Need lower CFU",
      "New diagnosis of chronic condition", "No effect/not relieving issues", "No fillers - only probiotics",
      "No histamine producing strains", "No pill - need powder", "No powder - need pill", "No prebiotics",
      "Pill found in stool", "Soft stools"]

    var reason_of_reformulation2 = ["Vomiting", "Worse acne/skin issues", "Worse bloating", "Worse constipation", "Worse diarrhea",
      "Worse esophageal spasms", "Worse food sensitivities/allergies", "Worse gassiness", "Worse GERD/acid reflux",
      "Worse headaches/migraines", "Worse mood/anxiety/depression", "Worse nausea", "Worse stomach/abdominal pain and cramps",
      "Worse thyroid/hormone issues", "Switch Prebiotic or Filler","Out of spec"]

    var number_of_months = [1, 2, 3, 4, 5, 6];
      
   return (
      <React.Fragment>
           {this.state.is_popUp_disabled && (
          <ShowModal
            message={warning}
            Description={popUp_description}
            Modal_return_value={this.Modal_return_value}
            pop_up_open={this.pop_up_open}
            modal_style={"500px"}
          />
        )}
        <Modal
          style={{ width: "693px" }}
          onClose={this.onCloseModal}
          trigger={

            <Button
              style={{ fontWeight: "initial" }}
              className="sr-button"
              secondary
              onClick={this.onOpenModalClick}
              icon
              labelPosition="right"
              disabled={this.props.is_special_button_disabled}

            >
              Submit Reformulation
              <Icon name="chevron circle right" />
            </Button>
          }
        >

          {this.props.is_waiting_for_response && (
            <CustomLoader loading_text="Loading....." />
          )}
          <Modal.Header className="modal_header">
            Reformulation Request
          </Modal.Header>
          <Modal.Description>
            <Segment>
              <Grid >

                <CustomDivider h_size="h3" title={`Requests for Reformulation of probiotics`} icon_name="info circle" />
                <Grid.Row>
                  <Grid.Column >
                    <span className="require_fields">*</span> Required
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns="equal" >
                <Grid.Row>
                  <Grid.Column >
                    <Form.Field required fluid="true">
                      <Popup trigger={<label>1. SG Number &nbsp;&nbsp;
                        <span className="require_fields">*</span></label>} content="SG Number" /><br /><br />
                      <Input
                        className="full_opacity"
                        disabled
                        value={sample_id}
                        style={{ width: "100%" }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column >
                    <Form.Field required fluid="true">
                      <Popup trigger={<label>2. fmrx &nbsp;&nbsp;
                        <span className="require_fields">*</span> </label>} content="Formula Id " /><br /><br />
                      <Input
                        className="full_opacity"
                        style={{ width: "100%" }}
                        disabled
                        value={formula_id}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column >
                    <Form.Field required fluid="true">
                      <Popup trigger={<label>3. Number of Months &nbsp;&nbsp;
                        <span className="require_fields">*</span> </label>} content="How many months do they need? " /><br /><br />
                      <div class="ui grid mt-5">
                        {number_of_months.map((index) => {
                          return <div>
                            <div class="column">
                              <input
                                style={{ margin: "0" }}
                                onChange={this.deSelect_other_months}
                                type="radio" name="number_of_months"
                                id={index}
                                checked={(index == currentindex) ? true : false}
                                value={index}
                              />
                              <label className="cs-checkbox-label" for={index} >{index}</label></div>
                          </div>

                        })}
                      </div>


                      <div class="ui grid">
                        <div class="column">
                          <input style={{ margin: "0" }}
                           type="radio" name="number_of_months_others"
                            onClick={this.getSelectedCheckboxValues} id="other" value={1} />&nbsp;&nbsp;
                          <Input className="full_opacity"
                            placeholder='Other'
                            type="number"
                            value={this.state.number_of_months_others}
                            disabled={this.state.is_disabled}
                            onChange={this.number_of_months_others}
                            style={{ width: "50%" }} /><br /><br />
                        </div>
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {(this.props.is_disabled && this.props.call_from == "refill")&& (
                  <Grid.Row>
                    <Grid.Column >
                      <Form.Field required fluid="true">
                        <Popup trigger={<label>4. Reason for Reformulation &nbsp;&nbsp;
                          <span className="require_fields">*</span>
                        </label>} content="Reason for Reformulation" /><br /><br />
                        
                        <Grid.Row className="ui grid equal width">
                          <Grid.Column >
                             <div>
                                  <input style={{ margin: "0" }} disabled
                                   type="checkbox" name="reason_for_reformulation" checked />
                                <label className="cs-checkbox-label">{values = ['Ingredient with insufficient quantity']}</label><br /><br />
                              </div>

                         </Grid.Column>
                        
                        </Grid.Row>
                        </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {(this.props.is_disabled && !this.props.call_from )&& (
                  <Grid.Row>
                    <Grid.Column >
                      <Form.Field required fluid="true">
                        <Popup trigger={<label>4. Reason for Reformulation &nbsp;&nbsp;
                          <span className="require_fields">*</span>
                        </label>} content="Reason for Reformulation" /><br /><br />

                        <Grid.Row className="ui grid equal width">
                          <Grid.Column >
                            {reason_of_reformulation1.map((index) => {

                              return <div>
                                <input style={{ margin: "0" }} onClick={this.reason_reformulation} type="checkbox" name="reason_for_reformulation" id={index} value={index} />
                                <label className="cs-checkbox-label" for={index} >{index}</label><br /><br /></div>

                            })}
                          </Grid.Column>
                          <Grid.Column >

                            {reason_of_reformulation2.map((index) => {

                              return <div>
                                <input style={{ margin: "0" }} type="checkbox" onClick={this.reason_reformulation} name="reason_for_reformulation" id={index} value={index} />
                                <label className="cs-checkbox-label" for={index} >{index}</label><br /><br /></div>

                            })}
                          </Grid.Column>

                        </Grid.Row>
                        &nbsp;&nbsp;<Input
                          className="full_opacity"
                          placeholder='other'
                          value={this.state.otherValue}
                          onChange={this.unselectCheckbox}
                          style={{ width: "50%" }}
                        /><br /><br />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                )} 
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field required fluid="true">
                      <Popup
                        trigger={<label>{is_disabled_value}. Notes </label>}
                        content="Others."
                      /><br />
                      <TextArea
                        id="others"
                        name="others"
                        className="full_opacity"
                        placeholder='Enter your Note'
                        value={this.state.notes}
                        onChange={(e) => { this.setState({ notes: e.target.value }) }}
                        style={{ minHeight: "100px", width: "100%" }}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>



            </Segment>
          </Modal.Description>

          <Modal.Actions>
            <Grid textAlign="center">
              {/* <Grid.Row /> */}
              <Grid.Row>
                <Grid.Column>

                  <Button.Group>

                    <Popup
                      trigger={
                        <Button
                          content="Submit Reformulation"
                          icon="check"
                          labelPosition="left"
                          positive
                          disabled={this.state.disabled_submit_button}
                          onClick={this.submitform}
                        />
                      }
                      content="Update the Formulation Information."
                    />


                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row />
            </Grid>
          </Modal.Actions>

        </Modal>

      </React.Fragment>
    )
  }

}
export default StartFormulationModals;