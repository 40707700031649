import React, { Component, Fragment } from "react";
import axios from "axios";

import { Message, Segment, Header, Grid, Button, Dropdown, Icon } from "semantic-ui-react";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import Modal from 'react-modal';
import { AgGridReact } from "ag-grid-react";

import { getHeaders, onPageSizeChanged, resetSort, resetFilter } from "../helper";
import { baseURL, page_size_options } from "../../configuration/constants";
import "./index.css";
import CustomLoader from "../custom_elements/CustomLoader";
import { onGridReady } from "../helper";

let api, column_api;

const ModalStyles = {
    content: {
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -3%)',
        border: 'none',
        background: 'none',
        padding: '0',
        top: '100px'
    }
};


class GetLogsTop extends Component {
    state = {
        openModal: false,
        oldCode: "",
        newCode: "",
        logsData: [],
        is_page_loading: true,
        new_date_made: "",
        new_flourish_fill_qc_weight: "",
        new_flourish_poeted: "",
        new_operator_wder_remaining: "",
        new_is_complnotes: "",
        new_prime_fill_qc_weight: "",
        new_prime_powder_remaining: "",
        new_prime_total_capsules_made: "",
        columnDefs: [
            {
                headerName: "ID",
                field: "formula_id",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true
            },
            {
                headerName: "Type",
                field: "title",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true
            },
            {
                headerName: "SG Number",
                field: "sample_id",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                cellRenderer: (params) => {
                    if (params.value === "") {
                        return (
                            `
                      <div>
                        -
                      </div>
                      `
                        )
                    }
                    else {
                        return (
                            `
                              <div>
                              ${params.value}
                              </div>
                              `
                        )
                    }
                }
            },
            {
                headerName: "Created At",
                field: "created_at",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true
            },
            {
                headerName: "Username",
                field: "user_name",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true
            },
            {
                headerName: "Compare Updated Values",
                field: "type",
                cellRendererFramework: function (params) {
                    if (params.value === "update") {
                        return (
                            <Button style={{ background: "none" }}>
                                <i className="fa fa-bandcamp fa-1x" aria-hidden="true"></i>
                            </Button>
                        )
                    }
                    else {
                        return false;
                    }


                },
                cellStyle: function (params) {
                    return { fontWeight: '100' };
                }
            }
        ],
    };

    componentDidMount = () => {
        axios
            .get(baseURL + "/get-logs", getHeaders())
            .then(response => {
                this.setState({
                    logsData: response.data,
                    is_page_loading: false
                })
            })
            .catch(error => {
                alert("Fetching Display Grid | Error: ", error);
            });
    }

    RowClcked = (value) => {
        if (value.data.type === "update" && value.colDef.headerName === "Compare Updated Values") {
            let val = value.data.new_json;
            let valueOldJson = value.data.old_json;
            this.setState({
                openModal: true,
                newCode: val,
                oldCode: valueOldJson,
            })
        }
    }
    closeModal = () => {
        this.setState({
            openModal: false,
        })
    }

   

    render() {
        return (
            <Fragment>
                {this.state.is_page_loading && <CustomLoader />}
                <Segment>
                    <Message>
                        <Header as="h3" textAlign="center">
                            Logs
                        </Header>
                    </Message>
                    <Grid columns="equal">
                        <Grid.Row>
                            <Grid.Column>
                                <Dropdown
                                    id="page-size"
                                    placeholder="Page Size"
                                    selection
                                    options={page_size_options}
                                    onChange={newPageSize => onPageSizeChanged(newPageSize, api)}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Button
                                    basic
                                    icon
                                    onClick={() => resetSort(api)}
                                    labelPosition="left"
                                >
                                    <Icon name="sort alphabet up" />
                                    Reset Sort(s)
                                </Button>
                            </Grid.Column>
                            <Grid.Column>
                                <Button
                                    basic
                                    onClick={() => resetFilter(api)}
                                    icon
                                    labelPosition="left"
                                >
                                    Reset Filter(s)
                                    <Icon name="filter" />
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment
                                compact
                                raised
                                padded
                                style={{ display: "block", marginTop: "10px" }}>
                                <Grid columns="equal">
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div
                                                id="myGrid"
                                                className="ag-theme-balham"
                                                ag-grid="gridOptions"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                }}>
                                                <AgGridReact
                                                    rowHeight="30"
                                                    rowSelection="single"
                                                    onCellClicked={this.RowClcked}
                                                    onGridReady={(params) => {
                                                        api = params.api;
                                                        api.showLoadingOverlay();
                                                        api.sizeColumnsToFit();
                                                        column_api =
                                                            params.column_api;
                                                        onGridReady(
                                                            params,
                                                            api,
                                                            column_api,
                                                            this.props
                                                                .initial_sort,
                                                        );
                                                    }}
                                                    animateRows={true}
                                                    pagination={true}
                                                    paginationPageSize={50}
                                                    // getRowStyle={this.ankit}
                                                    paginationNumberFormatter={(
                                                        params,
                                                    ) => {
                                                        return (
                                                            "[" +
                                                            params.value.toLocaleString() +
                                                            "]"
                                                        );
                                                    }}
                                                    columnDefs={
                                                        this.state.columnDefs
                                                    }
                                                    floatingFilter={true}
                                                    rowData={this.state.logsData}
                                                    ensureDomOrder={true}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Segment>

                <Modal
                    isOpen={this.state.openModal}
                    onRequestClose={this.closeModal}
                    style={ModalStyles}
                >
                    <ReactDiffViewer
                        oldValue={JSON.stringify(this.state.oldCode).split(",").join(",\n")}
                        newValue={JSON.stringify(this.state.newCode).split(",").join(",\n")}
                        compareMethod={DiffMethod.LINES}
                        rightTitle="Updated JSON"
                        leftTitle="Old JSON "
                        extraLinesSurroundingDiff={1}
                        disableWordDiff={true}
                    />
                </Modal>
                <br />

            </Fragment>
        )
    }
}

export default GetLogsTop;