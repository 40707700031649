//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: StartFormulation                                    //
// SubSubSubModule: PrimeInfo                                        //
// Tasks: 1) Receives and displays PrimeInfo                         //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import CustomDivider from "../../custom_elements/CustomDivider";

import React from "react";
import { Table, Icon, Input, Grid, Form, Popup } from "semantic-ui-react";

const PrimeInfo = props => {
  return (
    <React.Fragment>
      <CustomDivider h_size="h3" title="Prime" icon_name="flask" />

      <Grid.Row>
        <Grid.Column>
          <Table
            celled
            definition
            id={props.prime_filler_inventory_id === null ? "prime_table" : ""}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={8} />
                <Table.HeaderCell width={4}>Amount added</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell width={8}>
                  <Icon name="dna" />
                  Flourish
                </Table.Cell>
                <Table.Cell width={4}>
                  {props.prime_flourish_amount_added.toFixed(1) || 0}
                </Table.Cell>
                <Table.Cell positive width={4}> 
                  g
                </Table.Cell>
              </Table.Row>
              <Table.Row>

              <Table.Cell width={8}>
                <Icon name="dna" />
                Filler:{" "}
                { props.converted_inventory_options_json }
              </Table.Cell>

                <Table.Cell width={4}>
                  {/* <Label ribbon>gm</Label> */}
                  {Math.floor(props.prime_filler_amount_added * 1000)/1000 || 0}
                </Table.Cell>
                <Table.Cell positive width={4}>
                  g
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell positive width={8}>
                  <Icon name="check circle" />
                  Totals
                </Table.Cell>
                <Table.Cell positive width={4}>
                  {/* <Label ribbon>gm</Label> */}
                  {props.prime_total_amount_added.toFixed(1) || 0}
                </Table.Cell>
                <Table.Cell positive width={4}>
                  g
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row

        id={props.prime_filler_inventory_id === null ? "prime_inputs" : ""}
      >
        {props.prime_filler_inventory_id === null &&
          <Grid.Column>
            <Form.Field fluid="true">
              <Popup
                trigger={<label>QC Weight</label>}
                content="Enter the QC Weight."
              />
              <Input
                id="prime_fill_qc_weight"
                name="prime_fill_qc_weight"
                onChange={props.onModalValueChange}
                value={props.prime_fill_qc_weight || ""}
                style={{ width: "70%" }}
                icon="weight"
                disabled={(props.permission )?false:true}
                iconPosition="left"
                label={{ content: "g" }}
                labelPosition="right"
              />
            </Form.Field>
          </Grid.Column>
        }

        {props.prime_filler_inventory_id != null &&
          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>QC Weight</label>}
                content="Enter the QC Weight."
              />
              <Input
                id="prime_fill_qc_weight"
                name="prime_fill_qc_weight"
                onChange={props.onModalValueChange}
                value={props.prime_fill_qc_weight || ""}
                style={{ width: "70%" }}
                disabled={(props.permission)?false:true}
                icon="weight"
                iconPosition="left"
                label={{ content: "g" }}
                labelPosition="right"
              />
            </Form.Field>
          </Grid.Column>
        }

        <Grid.Column>
          <Form.Field fluid="true">
            <Popup
              trigger={<label>Capsule Size</label>}
              content="It is the Capsule Size."
            />
            <Input
              id="prime_capsule_size"
              name="prime_capsule_size"
              className="full_opacity"
              disabled
              value={props.prime_capsule_size || 0}
              icon="weight"
              iconPosition="left"
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field fluid="true">
            <Popup
              trigger={<label>Total Capsules Made</label>}
              content="Enter Total Capsules Made."
            />
            <Input
              id="prime_total_capsules_made"
              name="prime_total_capsules_made"
              onChange={props.onModalValueChange}
              disabled={(props.permission)?false:true}
              value={props.prime_total_capsules_made || ""}
              icon="check"
              iconPosition="left"
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field fluid="true" style={{ width: "80%" }}>
            <Popup
              trigger={<label>Powder Remaining</label>}
              content="Enter the Powder Remaining."
            />
            <Input
              id="prime_powder_remaining"
              name="prime_powder_remaining"
              onChange={props.onModalValueChange}
              value={props.prime_powder_remaining || ""}
              icon="weight" 
              disabled={(props.permission)?false:true}
              iconPosition="left"
              label={{ content: "g" }}
              labelPosition="right"
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default PrimeInfo;
