
import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";

import { changeAvailablityOfOrganism, checkValueExistOrNotInMultiVFun, formatDate, isAvailable_lower, multi_v_availablity, onGridReady } from "../helper";
import CustomConfirm from "../custom_elements/CustomConfirm";
import CustomDivider from "../custom_elements/CustomDivider";
import { baseURL } from "../../configuration/constants";
import Config from "../../configuration/config";
import {
    focusFirstElement, getDateString, increaseDateYear, getHeaders,
    getSessionStoredJsonItem, exsitingPreDigits, exsitingPreDigitsSelect
} from "../helper";


import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import CustomLoader from "../custom_elements/CustomLoader";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Multiselect } from 'multiselect-react-dropdown';
import { Form, Popup, Segment, Button, Input, Grid, Radio, TextArea, Icon } from "semantic-ui-react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Custom_DropDown } from "./DropDown";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/all-modules";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

let api, column_api;
let z;
let elemAmountPergram;
let elemServingSize;


const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
}

class ExistPrebiotic extends Component {
    state = {
        multi_v:"available",
        ingredient_type:"",
        multi_v_gram:"",
        files: [],
        AllergeanSubmitBtnMsg: "",
        allergeanList: [],
        attachment_arr: [],
        allergeanData: [],
        allergean: [{}],
        is_avaialble_value: "",
        serving_size: "",
        amount_per_gram: "",
        all: [],
        dataDown: [],
        supplement_id: 0,
        supplement: "",
        supplements1: [],
        supplements2: [],
        supplements: [],
        unit: "g",
        inventory: [],
        quantity: "",
        id: "",
        empty: [],
        multiSelectData: [],
        benefit: [],
        organism_barcode: "",
        concentration: "",
        qty: "",
        manufacture: "",
        storage_location: "",
        shelf: "",
        name: "",
        real_name: "",
        expiration_date: increaseDateYear(new Date()),
        is_available: 0,
        reorder_point: 0,
        scrap_factor: 0,
        receive_date: new Date(),
        open_date: new Date(),
        manufacture_lot: "",
        barcode: "",
        is_saving_successful: false,
        is_saving_failed: false,
        is_message_visible: true,
        showStore: false,
        columnDefs: [
            {
                headerName: "Ingredient Code",
                field: "organism_barcode",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 130
            },
            {
                headerName: "Concentration",
                field: "concentration",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 120
            },

            {
                headerName: "Quantity",
                field: "qty",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                width: 100,
            },
            {
                headerName: "Manufacturer",
                field: "manufacture",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 110
            },
            {
                headerName: "Expiration Date",
                field: "expiration_date",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                width: 135
            },
            {
                headerName: "Data Storage",
                field: "storage_location",
                filter: "agTextColumnFilter",
                width: 115,
                sortable: true
            },
            {
                headerName: "Shelf",
                field: "shelf",
                filter: "agTextColumnFilter",
                width: 100,
                sortable: true
            },
            {
                headerName: "Display Name",
                field: "name",
                filter: "agTextColumnFilter",
                width: 135,
                sortable: true
            },
            {
                headerName: "Internal Name",
                field: "real_name",
                filter: "agTextColumnFilter",
                width: 180,
                sortable: true
            }
        ]
    };

    constructor(props) {
        super(props);
        this.multiselectRef = React.createRef();
        this.allergenSelectRef = React.createRef();
    }

    componentDidMount = () => {
        this.fetchBenefit()
        this.fetchAllergeanData();
    }

    fetchAllergeanData = () => {
        axios.get(baseURL + "/allergens", getHeaders())
            .then((result) => {
                let allergeanArray = [];
                if (result.data.data.length > 0) {
                    let x = result.data.data;
                    x && x.map((elem, index) => {
                        let obj = { label: elem.allergen, name: elem.allergen, id: elem.id }
                        allergeanArray.push(obj);
                    })

                    this.setState({ allergeanData: allergeanArray, allergeanList: [] })
                }
                // else {
                //     toast.warning("Allergean Not Found");
                // }
            })
            .catch((err) => {
                toast.error("Something Goes Wrong");
            })
    }
    fetchBenefit = () => {
        axios
            .get(baseURL + "/benefits", getHeaders())
            .then(response => {
                let x = response.data.data;
                let emptyArray = [];
                x && x.map((elem, index) => {
                    let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id }
                    emptyArray.push(obj);
                })
                this.setState({
                    multiSelectData: emptyArray
                })
            })
    }
    fetchExistingAllergean = (id) => {

        let emptyArray = [];
        axios
            .get(baseURL + "/inventory_allergens/" + id, getHeaders())
            .then(response => {
                let x = response.data.data;

                x && x.map((elem, index) => {
                    let obj = { label: elem.allergen.allergen, name: elem.allergen.allergen, id: elem.allergen.allergen_id }
                    emptyArray.push(obj);
                })
                this.onSelectAllergen(emptyArray);
            })
    }
    onSelectAllergen = (allergean) => {
        this.setState({ allergeanList: allergean })
    }

    tableData = (value) => {
        let emptyArray = [];
        console.log(value);
        this.setState({
            ingredient_type:value.ingredient_type,
            multi_v_gram:value.multi_v_gram,
            multi_v:value.multi_v,
        })
        axios
            .get(baseURL + "/benefits/" + value.id, getHeaders())
            .then(response => {
                let x = response.data.data;
                x && x.map((elem, index) => {
                    let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id }
                    emptyArray.push(obj);
                })
                this.setState({

                    showStore: true,
                    organism_barcode: value.organism_barcode,
                    concentration: value.concentration,
                    qty: value.qty,
                    name: value.name,
                    real_name: value.real_name,
                    shelf: value.shelf,
                    storage_location: value.storage_location,
                    manufacture: value.manufacture,
                    empty: emptyArray,
                })
                this.onSelect(emptyArray, {})
            })

        let tempArray = [];
        axios
            .get(baseURL + "/supplements/" + value.id, getHeaders())
            .then(response => {
                z = response.data;
                z && z[0] && z[0].map((elem, index) => {
                    elemServingSize = elem.serving_size;
                    let obj = {};
                    obj = {
                        "id": elem.supplement_id,
                        "supplement": elem.supplement,
                        "quantity": elem.quantity,
                        "unit": elem.unit,
                        "amount_per_gram": elem.amount_per_gram,
                        "serving_size": elem.serving_size
                    }
                    tempArray.push(obj);
                })
                this.setState({
                    dataDown: tempArray,
                    supplements1: tempArray,
                    serving_size: elemServingSize
                })
            })
        axios
            .get(baseURL + "/supplements/existing/" + value.id, getHeaders())
            .then(res => {
                let tempArray = [];
                tempArray.push(...res.data);
                this.onInputChangeSupplement(tempArray, this.state.supplement);
                this.setState({
                    all: tempArray
                })
            })
        console.log(value);
        this.allergenSelectRef.current.resetSelectedValues();

        this.fetchExistingAllergean(value.id);
    }

    onInputChangeSupplement = (array, value) => {
        this.setState({
            supplement: value
        })
        let tempArray = array
        tempArray[0].filter((elem) => {
            if (elem.supplement === value) {
                if (elem.unit === "no unit") {
                    this.setState({
                        supplement_id: elem.id,
                        unit: ""
                    })
                }
                else {
                    this.setState({
                        supplement_id: elem.id,
                        unit: elem.unit
                    })
                }
            }
        })
    }

    onAddOrganismClick = () => {
        if (this.state.quantity === "" || this.state.quantity === 0 || this.state.supplement_id === 0 || this.state.supplement_id === "" || this.state.serving_size === ""
            || this.state.supplement === "") {
            alert("Please select supplement, Amount Per Serving and Serving Size");
            return;
        }

        let tempArray = this.state.inventory;
        let obj = {};
        obj = {
            "id": this.state.supplement_id,
            "supplement": this.state.supplement,
            "quantity": this.state.quantity,
            "unit": this.state.unit,
            "amount_per_gram": this.state.amount_per_gram,
            "serving_size": this.state.serving_size
        }
        tempArray.push(obj);
        var SelectedSupplement = this.state.supplement;
        let temps = this.state.all;
        temps && temps[0] && temps[0].filter((elem, index) => {
            if (elem.supplement === SelectedSupplement) {
                temps[0].splice(index, 1);
            }
        })

        this.setState({
            all: temps,
            inventory: tempArray,
            supplements2: tempArray
        })
        this.setState(this.resetOrganismState());
    }

    resetOrganismState = () => {
        return {
            quantity: 0,
            supplement_id: 0,
            supplement: "",
            amount_per_gram: ""
        };
    };

    onSelect = (selectedList, selectedItem) => {
        if (selectedList && selectedList.length) {
            this.setState({
                benefit: selectedList
            })
        }
        else {
            this.setState({
                benefit: []
            })
        }
    }


    /**
    * Handles the date change
    *
    * @param {String} name
    * @param {Date} date
    */
    onDateChange = (name, date) => {
        if (name === Config.inventory.receive_date) {
            this.setState({ receive_date: date });
            if (date) {
                this.setState({ expiration_date: increaseDateYear(date) });
            }
        } else if (name === Config.inventory.open_date) {
            this.setState({ open_date: date });
        } else if (name === Config.inventory.expiration_date) {
            this.setState({ expiration_date: date });
        } else {
            alert("Some Date Error Enterred!");
        }
    };

    changeAvailablity = async value => {
        var data = await changeAvailablityOfOrganism(value);
        this.setState(data);
    };

    AnswerKeyPress = (e) => {
        let code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    onInputAmountChange = (e, { name, value }) => {
        exsitingPreDigitsSelect()
        this.setState({
            [name]: value
        });
        let a = value;
        let b = this.state.serving_size;
        let c = a / b;
        this.setState({
            amount_per_gram: c
        })
    }


    // Handles submit click
    onSubmitClick = () => {


        let { manufacture, manufacture_lot, name, real_name, qty, scrap_factor, reorder_point,multi_v,ingredient_type,multi_v_gram } = this.state;
        
        var datass = checkValueExistOrNotInMultiVFun(multi_v,ingredient_type,multi_v_gram)
        if(!datass.unavailable && datass.checked){
            alert(datass.info);
            return;
        }

        if (!manufacture || !manufacture_lot || !name || !real_name) {
            toast.info("Please fill all the mandatory fields.");
            return;
        }
        if (scrap_factor === "" || reorder_point === "" || qty === "") {
            toast.info("Please fill all the mandatory information.");
            return;
        }

        this.setState({
            is_saving_successful: false,
            is_saving_failed: false
        });
        this.state.supplements = this.state.supplements1.concat(this.state.supplements2)
        let res = _.pick(this.state, [
            "organism_barcode",
            "multi_v","ingredient_type","multi_v_gram",
            "receive_date",
            "open_date",
            "expiration_date",
            "is_available",
            "concentration",
            "manufacture",
            "manufacture_lot",
            "qty",
            "reorder_point",
            "storage_location",
            "shelf",
            "scrap_factor",
            "notes",
            "name",
            "real_name",
            "benefit",
            "supplements",
            "attachment_arr",
            "allergeanList"
        ]);
        
        // adding the remaning fields.
        res.user_id = getSessionStoredJsonItem("user").user_id;
        res.open_date = formatDate(this.state.open_date);
        res.receive_date = formatDate(this.state.receive_date);
        res.expiration_date = formatDate(this.state.expiration_date);
        this.setState({ is_waiting_for_response: true });

        axios
            .post(baseURL + "/add_new_barcodes?type=pre", res, getHeaders())
            .then(response => {
                toast.success("Inventory Added Successfully" + "  Barcode is  " + response.data.barcode);
                this.setState({
                    is_waiting_for_response: false,
                    is_page_loading: false
                })
                focusFirstElement();
                this.setState({
                    benefit: [],
                    showStore: false
                })
                this.fetchBenefit()
                if (response.data.success === true) {
                    const interval = setInterval(() => {
                        window.location.reload(false);
                    }
                        , 1000);
                }
            })
            .catch(error => {
                toast.error("Invalid Data");
                this.setState({ is_page_loading: false });
            });
        this.multiselectRef.current.resetSelectedValues();
    };

    onCancelClick = () => {
        this.setState({
            receive_date: "",
            open_date: "",
            expiration_date: "",
            is_available: "",
            concentration: "",
            manufacture: "",
            manufacture_lot: "",
            qty: "",
            reorder_point: "",
            storage_location: "",
            shelf: "",
            scrap_factor: "",
            notes: "",
            name: "",
            real_name: ""
        })
    };

    onRemove = (value) => {
        this.setState({
            benefit: value
        })
    }

    deleteDown = (index, value) => {
        let secondTemp = this.state.inventory;
        secondTemp.splice(index, 1);
        this.setState({
            inventory: secondTemp,
            supplements2: secondTemp
        })
        this.state.all[0].push({
            supplement: value.supplement,
            supplement_id: value.id,
            unit: value.unit,
            quantity: value.quantity,
            serving_size: value.serving_size,
            amount_per_gram: value.amount_per_gram
        })
    }

    deleteUp = (index, value) => {
        let secondTemp = this.state.dataDown;
        secondTemp.splice(index, 1);
        this.setState({
            dataDown: secondTemp,
            supplements1: secondTemp
        })
        this.state.all[0].push({
            supplement: value.supplement,
            supplement_id: value.id,
            unit: value.unit,
            quantity: value.quantity,
            serving_size: value.serving_size,
            amount_per_gram: value.amount_per_gram
        })
    }

    onInputChangeServingSize = (e, { name, value }) => {
        exsitingPreDigits()
        this.setState({
            [name]: value
        });
        let tempArray = [];
        let obj = {};
        let tempArrayFromTable = [];

        this.state.inventory.map((elem, index) => {
            let cross = elem.quantity / value;
            obj = {
                "serving_size": value,
                "id": elem.id,
                "supplement": elem.supplement,
                "quantity": elem.quantity,
                "unit": elem.unit,
                "amount_per_gram": cross
            }
            tempArray.push(obj);
        })
        this.setState({
            supplements2: tempArray
        })

        this.state.dataDown.map((elem, index) => {
            let cross = elem.quantity / value;
            let obj = {};
            obj = {
                "id": elem.id,
                "supplement": elem.supplement,
                "quantity": elem.quantity,
                "unit": elem.unit,
                "amount_per_gram": cross,
                "serving_size": value
            }
            tempArrayFromTable.push(obj);
        })
        let a = this.state.quantity;
        let b = value;
        let c = a / b;
        this.setState({
            supplements1: tempArrayFromTable,
            amount_per_gram: c
        })
    };

    onupdatefiles = (fileItems) => {
        const scope = this;

        let attachment_arr_temp = this.state.attachment_arr;
        attachment_arr_temp = [];
        fileItems.map((elem, index) => {
            let mediaTypePdf = elem.file.type.split("/")[1];
            let mediaSize = elem.file.size;
            let mediaType = elem.file.type;
            let mediaName = elem.file.name;
            var selectedFile = elem.file;

            if (selectedFile) {
                var fileToLoad = selectedFile;
                var fileReader = new FileReader();
                var base64;
                fileReader.readAsDataURL(fileToLoad);
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let res = base64.slice(28);
                    if (mediaTypePdf === "pdf" && mediaSize <= 5242880) {
                        let obj = {};
                        obj = {
                            "base64Media": res,
                            "mediaName": mediaName,
                            "type": mediaType,
                            "size": mediaSize
                        }
                        attachment_arr_temp.push(obj);
                        const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                        scope.setState({
                            attachment_arr: final_array
                        })
                    }
                };
            }
        })
    }

    handleRemove = () => {
        const scope = this;
        this.setState({
            attachment_arr: []
        })
        let attachment_arr_temp = this.state.attachment_arr;
        if (this.pond) {
            const remainingAttachments = this.pond.getFiles().map((file) => file.source);
            remainingAttachments.map((elem, index) => {
                let mediaSize = elem.size;
                let mediaType = elem.type;
                let mediaName = elem.name;
                var selectedFile = remainingAttachments[0];
                if (selectedFile) {
                    var fileToLoad = selectedFile;
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        let res = base64.slice(28);
                        if (mediaSize <= 5242880) {
                            let obj = {};
                            obj = {
                                "base64Media": res,
                                "mediaName": mediaName,
                                "type": mediaType
                            }
                            attachment_arr_temp.push(obj);
                            const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                            scope.setState({
                                attachment_arr: final_array
                            })

                        }
                    };

                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
    }
    multi_v_availablity_fn = (type,value)=>{
        var multi_value = multi_v_availablity(type,value,this.state.multi_v);
        console.log(multi_value);
        this.setState(multi_value);
    }

    render() {
        const { is_page_loading, is_waiting_for_response, real_name, name, is_available, concentration, qty, reorder_point, manufacture_lot,
            expiration_date, open_date, quantity, scrap_factor, notes, multiSelectData, serving_size, shelf, storage_location,
            manufacture, receive_date, unit, supplement, is_confirm_open, benefit, dataDown, inventory, showStore, columnDefs } = this.state;
        return (
            <React.Fragment>
                <CustomConfirm
                    is_confirm_open={is_confirm_open}
                    header="Delete selected Record(s)"
                    cancel_button_text="Eh! Never Mind."
                    confirm_button_text="DELETE"
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}
                />
                {is_page_loading && <CustomLoader />}

                <br />
                <Segment className="transparent">
                    <Grid.Row>
                        <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column>
                                    <div
                                        id="myGrid"
                                        className="ag-theme-balham"
                                        ag-grid="gridOptions"
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        <AgGridReact
                                            rowHeight="30"
                                            rowSelection="single"
                                            onRowClicked={e => {
                                                let sample = e.data;
                                                this.tableData(sample)
                                            }}
                                            onGridReady={params => {
                                                api = params.api;
                                                api.showLoadingOverlay();
                                                api.sizeColumnsToFit();
                                                column_api = params.column_api;
                                                onGridReady(
                                                    params,
                                                    api,
                                                    column_api,
                                                    this.props.initial_sort
                                                );
                                            }}
                                            animateRows={true}
                                            pagination={true}
                                            paginationPageSize={10}
                                            paginationNumberFormatter={params => {
                                                return "[" + params.value.toLocaleString() + "]";
                                            }}
                                            columnDefs={columnDefs}
                                            floatingFilter={true}
                                            rowData={this.props.samples}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Row>
                    <Grid.Row />
                </Segment>
                <Segment style={{ display: showStore ? 'block' : 'none', marginTop: "70px" }}>
                    {is_waiting_for_response && (
                        <CustomLoader loading_text="Saving Inventory Data" />
                    )}
                    <Form>
                        <Grid columns="equal">
                            <CustomDivider
                                h_size="h3"
                                title="Prebiotic Information"
                                icon_name="warehouse"
                            />

                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Display Name</label>}
                                            content="Enter the Display Name."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="name"
                                            value={name}
                                            onChange={this.onInputChange}
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Add Name"
                                            disabled
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Internal Name</label>}
                                            content="Enter the Internal Name."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="real_name"
                                            onChange={this.onInputChange}
                                            value={real_name}
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Internal Name"
                                        // disabled
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    {/* <Form.Field fluid="true"> */}

                                    <Custom_DropDown
                                        label={"Is Available"}
                                        content={"Is Organism available ?"}
                                        onChange={this.changeAvailablity}
                                        isAvailable={this.props.isAvailable}
                                        is_available={"is_available"}
                                        classNames={"is-available opacity-1"}
                                        dropDownValue={this.state.is_avaialble_value}
                                        inventory={this.props.inventory}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Concentration</label>}
                                            content="Enter the concentration of the Ingredient."
                                        />
                                        <Input
                                            name="concentration"
                                            onChange={this.onInputChange}
                                            value={concentration}
                                            placeholder="Concentnration"
                                            label={{ content: "B CFU/g" }}
                                            labelPosition="right"
                                            disabled
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity</label>}
                                            content="Enter the Ingredient Quantity."
                                        />
                                        <Input
                                            name="qty"
                                            onChange={this.onInputChange}
                                            value={qty}
                                            placeholder="Quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.onInputChange}
                                            value={reorder_point}
                                            placeholder="Reorder Point"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Scrap Factor</label>}
                                            content="Enter the Ingredient Scrap Factor."
                                        />
                                        <Input
                                            name="scrap_factor"
                                            onChange={this.onInputChange}
                                            value={scrap_factor}
                                            placeholder="Scrap Factor"
                                            label={{ content: "%" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Popup
                                        trigger={<label>Notes</label>}
                                        content="Enter notes."
                                    />
                                    <TextArea
                                        name="notes"
                                        onChange={this.onInputChange}
                                        value={notes}
                                        placeholder="Add Notes here..."
                                        style={{ minHeight: 100 }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row >


         <Grid.Column >
    <div>Multi-V Availability</div>
    <Radio
    toggle
    className="allergen-radio-btn"
    onClick={()=>this.multi_v_availablity_fn('radio',this.state.multi_v)}
    checked={(this.state.multi_v == "available")?true:false}
    />
    
    </Grid.Column>
    {(this.state.multi_v == "available")&& (
    <>
    <Grid.Column  width={6}>
          <Custom_DropDown
                label={"Ingredient Type"}
                content={"Ingredient type."}
                onChange={(e)=>this.multi_v_availablity_fn("ingredient_type",e)}
                isAvailable={isAvailable_lower}
                is_available={"is_available"}
                classNames={"is-available"}                
                dropDownValue={this.state.ingredient_type}
                inventory={inventory}
            />
    </Grid.Column>
    <Grid.Column  width={6}>
            <span>Price per gram </span>
            <Input
            name="price_per_gram"
            type="number"
            fluid
            value={this.state.multi_v_gram}
            placeholder="Price per gram"
            onChange={(e)=>{ this.multi_v_availablity_fn('multi_v_gram',e.target.value) }}
        />
    </Grid.Column></>)}
    
               </Grid.Row >
                            {/* <CustomDivider
                                h_size="h3"
                                title="Benefits"
                                icon_name="cart plus"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <Popup
                                            trigger={<label>Benefits</label>}
                                            content="Benefits."
                                        />
                                        <Multiselect
                                            options={multiSelectData}
                                            onSelect={this.onSelect}
                                            selectedValues={benefit}
                                            onRemove={this.onRemove}
                                            displayValue="name"
                                            ref={this.multiselectRef}
                                            closeOnSelect={false}
                                            placeholder="Select Benefits"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row> */}

                            <Grid.Row>
                                <Grid.Column>
                                    <Grid.Column >
                                        <Form.Field>
                                            <Popup
                                                trigger={<label>Add Allergens</label>}
                                                content="Allergean."
                                            />
                                            <Multiselect
                                                options={this.state.allergeanData}
                                                onSelect={this.onSelectAllergen}
                                                selectedValues={this.state.allergeanList}
                                                onRemove={this.onRemoveAllergean}
                                                ref={this.allergenSelectRef}
                                                displayValue="name"
                                                placeholder="Select Allergens"

                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                </Grid.Column>
                            </Grid.Row>

                            <CustomDivider
                                h_size="h3"
                                title="Supplements"
                                icon_name="cart plus"
                            />
                            <Grid.Row className="block">
                                <Grid.Column width={5}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Serving Size</label>}
                                            content="Supplements."
                                        />
                                        <Input
                                            id="NumericTextBox_pre"
                                            type="number"
                                            name="serving_size"
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Serving Size"
                                            onChange={this.onInputChangeServingSize}
                                            value={serving_size}
                                        />

                                    </Form.Field>
                                </Grid.Column>

                            </Grid.Row>
                            {dataDown &&
                                dataDown.map((elem, index) => {
                                    elemAmountPergram = elem.quantity / serving_size;
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid.Row className="block">
                                                <Grid.Column width={5}>
                                                    <Form.Field required fluid="true">
                                                        <Popup
                                                            trigger={<label>Supplements</label>}
                                                            content="Enter the Supplements."
                                                        />
                                                        <Input
                                                            value={elem.supplement}
                                                            disabled
                                                            style={{ opacity: 1, backgroundColor: "rgb(184, 252, 255)" }}
                                                        >
                                                        </Input>
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={4}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Per Serving</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1 }}
                                                            icon="weight"
                                                            iconPosition="left"
                                                            label={elem.unit}
                                                            labelPosition="right"
                                                            value={Math.floor(elem.quantity * 1000) / 1000}
                                                            disabled
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={3}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Per Gram</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1, width: "100%", }}
                                                            icon="weight"
                                                            iconPosition="left"
                                                            labelPosition="right"
                                                            label={elem.unit}
                                                            placeholder="Amount Per Gram"
                                                            value={Math.floor(elemAmountPergram * 1000) / 1000}
                                                            disabled
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>


                                                <Grid.Column>
                                                    <Form.Field fluid="true">
                                                        <label style={{ visibility: "hidden" }}>.</label>
                                                        <Popup
                                                            trigger={
                                                                <Button
                                                                    style={{ marginLeft: "45px" }}
                                                                    icon="delete"
                                                                    negative
                                                                    circular
                                                                    onClick={() => this.deleteUp(index, elem)}
                                                                />
                                                            }
                                                            content="Delete this Supplement."
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    )
                                })
                            }


                            {inventory &&
                                inventory.map((elem, index) => {
                                    elemAmountPergram = elem.quantity / serving_size;
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid.Row className="block">
                                                <Grid.Column width={5}>
                                                    <Form.Field required fluid="true">
                                                        <Popup
                                                            trigger={<label>Supplements</label>}
                                                            content="Enter the Supplements."
                                                        />
                                                        <Input
                                                            value={elem.supplement}
                                                            disabled
                                                            style={{ opacity: 1, backgroundColor: "rgb(184, 252, 255)" }}
                                                        >
                                                        </Input>
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={4}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Per Serving</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            icon="weight"
                                                            iconPosition="left"
                                                            label={elem.unit}
                                                            labelPosition="right"
                                                            value={Math.floor(elem.quantity * 1000) / 1000}
                                                            disabled
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={3}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Per Gram</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            placeholder="Amount Per Gram"
                                                            onChange={this.onInputChange}
                                                            value={Math.floor(elemAmountPergram * 1000) / 1000}
                                                            disabled
                                                            type="number"
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>


                                                <Grid.Column>
                                                    <Form.Field fluid="true">
                                                        <label style={{ visibility: "hidden" }}>.</label>
                                                        <Popup
                                                            trigger={
                                                                <Button
                                                                    style={{ marginLeft: "45px" }}
                                                                    icon="delete"
                                                                    negative
                                                                    circular
                                                                    onClick={() => this.deleteDown(index, elem)}
                                                                />
                                                            }
                                                            content="Delete this Supplement."
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    )
                                })
                            }

                            <CustomDivider
                                h_size="h3"
                                title="Add Supplements"
                                icon_name="plus"
                            />

                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Supplements</label>}
                                            content="Supplements."
                                        />
                                        <input list="supplement"
                                            value={supplement}
                                            onChange={(e) => this.onInputChangeSupplement(this.state.all, e.target.value)}
                                        ></input>
                                        <datalist
                                            id="supplement"
                                        >
                                            {
                                                this.state.all && this.state.all[0] && this.state.all[0].map((elem, index) => {
                                                    return (
                                                        <option key={index}>
                                                            {elem.supplement}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </datalist>
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Amount Per Serving</label>}
                                            content="Enter the amount added."
                                        />
                                        <Input
                                            id="amount_added_select"
                                            type="number"
                                            style={{ opacity: 1 }}
                                            name="quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={this.state.unit}
                                            labelPosition="right"
                                            placeholder="Amount Added"
                                            onChange={this.onInputAmountChange}
                                            value={quantity}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Form.Field required fluid="true" >
                                        <Popup
                                            trigger={<label>Amount Per Gram</label>}
                                            content="Enter the amount added."
                                        />
                                        <Input
                                            type="number"
                                            style={{ opacity: 1 }}
                                            icon="weight"
                                            iconPosition="left"
                                            label={unit}
                                            labelPosition="right"
                                            name="amount_per_gram"
                                            placeholder="Amount Per Gram"
                                            onChange={this.onInputChange}
                                            value={parseFloat(this.state.amount_per_gram).toFixed(3)}
                                            disabled
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column style={{ textAlign: "center" }}>
                                    <Popup
                                        trigger={
                                            <Button
                                                animated="vertical"
                                                className="small"
                                                color="blue"
                                                onClick={this.onAddOrganismClick}
                                            >
                                                <Button.Content visible>
                                                    <Icon name="plus circle" />
                                                    Insert
                                                </Button.Content>
                                                <Button.Content hidden>
                                                    More&nbsp;
                                                    <Icon name="arrow right" />
                                                </Button.Content>
                                            </Button>
                                        }
                                        content="Add more Supplements(s)."
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <CustomDivider
                                h_size="h3"
                                title="Manufacturer Information"
                                icon_name="industry"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Receive Date</label>}
                                            content="Enter the Ingredient Receive Date."
                                        />
                                        <DatePicker
                                            selected={receive_date}
                                            placeholderText="Click to select a date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(receive_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.receive_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Open Date</label>}
                                            content="Enter the Ingredient Open Date."
                                        />

                                        <DatePicker
                                            selected={open_date}
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(open_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.open_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Expiration Date</label>}
                                            content="Enter the Ingredient Expiration Date."
                                        />
                                        <DatePicker
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            selected={expiration_date}
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(Config.default_calendar_date)}
                                            onChange={date => {
                                                this.onDateChange(
                                                    Config.inventory.expiration_date,
                                                    date
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer</label>}
                                            content="Enter the Manufacturer of the Ingredient."
                                        />
                                        <Input
                                            placeholder="Manufacturer"
                                            icon="building"
                                            iconPosition="left"
                                            name="manufacture"
                                            onChange={this.onInputChange}
                                            value={manufacture}
                                            disabled
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer Lot</label>}
                                            content="Enter the Manufacturer Lot of the Ingredient."
                                        />
                                        <Input
                                            name="manufacture_lot"
                                            onChange={this.onInputChange}
                                            value={manufacture_lot}
                                            placeholder="Manufacturer Lot"
                                            icon="hashtag"
                                            iconPosition="left"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />

                            <CustomDivider
                                h_size="h3"
                                title="Storage Information"
                                icon_name="dropbox"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Storage Location</label>}
                                            content="Enter the Ingredient Storage Location (Fridge Number)."
                                        />
                                        <Input
                                            placeholder="Storage Location (Fridge Number)"
                                            icon="boxes"
                                            iconPosition="left"
                                            name="storage_location"
                                            onChange={this.onInputChange}
                                            value={storage_location ? storage_location : " "}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Shelf</label>}
                                            content="Enter the Ingredient Shelf Number."
                                        />
                                        <Input
                                            placeholder="Shelf"
                                            icon="box"
                                            iconPosition="left"
                                            name="shelf"
                                            onChange={this.onInputChange}
                                            value={shelf}
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <CustomDivider
                                h_size="h3"
                                title="Upload Documents"
                                icon_name="upload"
                            />
                            <Grid.Column>
                                <FilePond
                                    acceptedFileTypes={['application/pdf']}
                                    ref={ref => (this.pond = ref)}
                                    allowMultiple={true}
                                    allowReorder={true}
                                    allowRemove={true}
                                    maxFiles={10}
                                    allowFileSizeValidation={true}
                                    maxFileSize="5MB"
                                    onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                                    onremovefile={this.handleRemove}
                                    checkValidity={true}
                                    dropValidation

                                />
                            </Grid.Column>

                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid textAlign="center">
                                        <Button.Group>
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onSubmitClick} positive>
                                                        Submit
                                                    </Button>
                                                }
                                                content="Submit the Ingredient Information to the Database."
                                            />
                                            <Button.Or />
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onCancelClick}>Cancel</Button>
                                                }
                                                content="Discard the Ingredient Informtaion."
                                            />
                                        </Button.Group>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Form>
                </Segment>

            </React.Fragment>
        );
    }
}

export default ExistPrebiotic;