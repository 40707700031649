//-------------------------------------------------------------------//
// Module: ErrorMessage                                              //
// Tasks: Display Semantic-UI specified Message                      //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import { Grid, Message } from "semantic-ui-react";

class ErrorMessage extends Component {
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {this.props.is_message_visible && (
              <Message
                onDismiss={this.props.hideMessage}
                error
                header={"Error!"}
                content={
                  this.props.message +
                  ""
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ErrorMessage;
