import React, { Component } from "react";
import {Button, Modal } from 'semantic-ui-react'


export default class ShowModal extends Component {
    getInitState = () => {
        return {
            open:true,
        };
    };
    Modal_return_value = (value)=>{
        this.setState({open:false})
        this.props.pop_up_open();
        this.props.Modal_return_value(value,this.props.warning);
    }

    state = this.getInitState();

    render() {

    return (
        <Modal
        open={this.state.open}
        style={{width: this.props.modal_style}}
      >
        <Modal.Content >
          <Modal.Description> <p dangerouslySetInnerHTML={{__html: this.props.Description}}></p> </Modal.Description>
        <br/> <br/>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={this.props.message=="quantitiy_warning"?"Yes, submit anyway":"Yes"}
            labelPosition='right'
            icon='checkmark'
            onClick={() => this.Modal_return_value(true)}
            positive
          />
          <Button color='black'
            content={this.props.message=="quantitiy_warning"?"No, go back":"No"}
            onClick={() => this.Modal_return_value(false)}/>


        </Modal.Actions>
      </Modal>
    ) 

}
}