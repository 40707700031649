import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./index.css";

class Accordian extends Component {
    state = {
        show: false
    };
    render() {
        const { title, abstractNote, tags, publicationTitle, yearOfPublication, url } = this.props;
        const { show } = this.state;
        return (
            <div>
                <div className="zotero-custom-accordiion">
                    <div className="all-list">
                        <div className ={show ? 'zotero-ca-block open' : 'zotero-ca-block'}>
                            <div
                                className="ca-heading"
                                onClick={() => {
                                    this.setState({
                                        show: !this.state.show
                                    })
                                }}
                                style={{ backgroundColor: show ? '#f1f3f6' : '#F8F8F9' }}>
                                {title}
                            </div>

                            <div className="ca-body" style={{ display: show ? 'block' : 'none' }} >
                                <div className="ca-abstract">{abstractNote}</div>
                                <div className="ca-tags">
                                    <strong>Tags</strong>
                                    <br />
                                    {tags}
                                </div>
                                <div className="ca-publication">
                                    <strong>Publication Title</strong>
                                    <br />
                                    {publicationTitle}
                                </div>
                                <div className="ca-publication">
                                    <strong>Year of Publication</strong>
                                    <br />
                                    {yearOfPublication}
                                </div>
                                <div className="ca-publication" style={{ display: url === "" ? 'none' : 'display' }}>
                                    <strong> Url</strong>
                                    <br />
                                    <Link to={{ pathname: url }} target="_blank">{url}</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Accordian;