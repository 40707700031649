export function getPrebioticsAndProbioticsNamesDefsArray(
    converted_role_options_json,
    all_role_ids
  ) {
    let editablePermission=localStorage.getItem("prebioticsManagement");
    return [
      {
        headerName: "id",
        field: "organism_id",
        hide: true
      },
      {
        headerName: "Ingredient barcode",
        field: "organism_barcode",
        editable: editablePermission?true:false,
        filter: "agTextColumnFilter",
        pinned: "left",
        width: 450,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      {
        headerName: "Ingredient Name",
        field: "organism_name",
        editable:editablePermission?true:false,
        filter: "agTextColumnFilter",
        width: 410
      },
      {
        headerName: "Tax ID",
        field: "tax_id",
        editable: editablePermission?true:false,
        pinned: "right",
        filter: "agTextColumnFilter",
        width: 230
      },
     
    ];
  }