import CustomDivider from "../custom_elements/CustomDivider";

import React, { Component } from "react";
import {
    Form,
    Input,
    Grid,
    Popup,
    Segment,
    Radio
} from "semantic-ui-react";
import MultiVAvailablity from "./multi_v_availablity";
import { Custom_DropDown } from "./DropDown";
import { isAvailable_lower } from "../helper";
const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
}
class ExistForFlourish extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showFlore: true,
            showFlore_tots: false,
        }
    }


    
    handleChange = (e) => {
        this.props.selectCapsuleSizeFunction(e.target.value)

        if (e.target.value === "1" || e.target.value === "0" || e.target.value === "00") {
            this.setState({
                showFlore: true,
                showFlore_tots: false
            })
        }
        if (e.target.value === "Powder") {
            this.setState({
                showFlore_tots: true,
                showFlore: false
            })
        }
    }

    render() {
        return (
            <Segment>
                <Grid>
                    <Segment className="transparent">
                        <Grid columns="equal">
                            <CustomDivider h_size="h3" icon_name="flask" title="Flourish" />
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Capsule Size</label>}
                                            content="The Capsule Size."
                                        />
                                        <select onChange={this.handleChange}
                                            value={this.props.flourish_capsule_size}
                                        >
                                            {
                                                this.props.options && this.props.options.map((elem, i) => {
                                                    return (
                                                        <option key={i}>
                                                            {elem.value}
                                                        </option>

                                                    )
                                                })
                                            }
                                        </select>

                                    </Form.Field>
                                </Grid.Column>


                                <Grid.Column width={4} style={{ display: this.state.showFlore ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point (number of months)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.props.onInputChange}
                                            value={this.props.reorder_point}
                                            placeholder="Reorder Point"
                                            type="text"
                                            onKeyPress={this.props.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={4} style={{ display: this.state.showFlore_tots ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point (grams)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.props.onInputChange}
                                            value={this.props.reorder_point}
                                            placeholder="Reorder Point"
                                            type="text"
                                            onKeyPress={this.props.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={4} style={{ display: this.state.showFlore ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity (number of months)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="quantity"
                                            onChange={this.props.onInputChange}
                                            value={this.props.quantity}
                                            placeholder="quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.props.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Number of Months (Ref.)</label>}
                                            content="Reference to the Number of Months."
                                        />
                                        <Input
                                            name="number_of_months"
                                            onChange={this.props.monthChanges}
                                            value={this.props.number_of_months}
                                            icon="calendar alternate outline"
                                            iconPosition="left"
                                            placeholder="Number of Months"
                                            onKeyPress={this.props.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                
                            </Grid.Row>

                            <Grid.Row>
                            <Grid.Column width={3} style={{ display: this.state.showFlore_tots ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity (grams)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="quantity"
                                            onChange={this.props.onInputChange}
                                            value={this.props.quantity}
                                            placeholder="quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.props.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Formulation Factor (Ref.)</label>}
                                            content="Enter the Formulation Factor."
                                        />
                                        <Input
                                            name="formulation_factor"
                                            disabled
                                            className="full_opacity"
                                            value={this.props.formulation_factor}
                                            icon="warning sign"
                                            iconPosition="left"
                                            placeholder="Formulation Factor"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                

                            </Grid.Row>

                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Segment>
                </Grid>
            </Segment>
        );
    }
}

export default ExistForFlourish;
