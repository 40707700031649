
import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";

import CustomLoader from "../custom_elements/CustomLoader";
import CustomDivider from "../custom_elements/CustomDivider";
import { getHeaders, getSessionStoredJsonItem, focusFirstElement, multi_v_availablity, checkValueExistOrNotInMultiVFun } from "../helper";
import { baseURL } from "../../configuration/constants";
import { getFinalConcentration } from "../formulation_mod/Formulas";
import All from "./all";

import {
    Form,
    Popup,
    Segment,
    Button,
    Input,
    Grid,
    TextArea
} from "semantic-ui-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import MultiVAvailablity from "./multi_v_availablity";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const options = [
    { value: 0 },
    { value: 1 },
    { value: "Powder" }
];

class PremadePrimeFormulla extends Component {
    getInitState = () => {
        return {
            files: [],
            attachment_arr: [],
            is_submit: false,
            showFlore: true,
            quantity: "",
            showFlore_tots: false,
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            is_page_loading: true,
            reorder_point: 0,
            storage_location: "",
            shelf: 0,
            admin_notes: "",
            concentration: 0,
            organism: [],
            total_amount_added: 0,
            total_final_concentration: 0,
            total_final_amount_added: 0,
            flourish_capsule_size: "",
            number_of_months: 3,
            formulation_factor: 1,
        };
    };

    state = this.getInitState();

    AnswerKeyPress = (e) => {
        let code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    isSubmitHandler = (value) => {
        this.setState({
            is_submit: value
        })
    }
    organism = (value) => {
        this.setState({
            organism: value
        })
    }
    total_amount_added = (value) => {
        this.setState({
            total_amount_added: value
        })
    }
    total_final_concentration = (value) => {
        this.setState({
            total_final_concentration: value
        })
    }
    total_final_amount_added = (value) => {
        this.setState({
            total_final_amount_added: value
        })
    }
    number_of_months = (value) => {
        this.setState({
            number_of_months: value
        })
    }

    formulation_factor = (value) => {
        this.setState({
            formulation_factor: value
        })
    }

    onSubmitClick = () => {
        let { quantity, organism} = this.state;
        
        if (!quantity || !organism) {
            toast.info("Please fill all the mandatory information.");
            return;
        }
        
        this.setState({
            is_saving_successful: false,
            is_saving_failed: false,
            is_submit: true
        });
        let res = _.pick(this.state, [
            "organism",

            "reorder_point",
            "storage_location",
            "shelf",
            "admin_notes",
            "total_amount_added",
            "total_final_concentration",
            "total_final_amount_added",
            "quantity",
            "concentration",
            "flourish_capsule_size",
            "number_of_months",
            "formulation_factor",
            "attachment_arr"
        ]);
        res.organism.map((organism, index) => {
            let final_concentration = getFinalConcentration(organism.final_concentration, organism.amount_added, this.state.total_amount_added);
            res.organism[index].final_concentration = final_concentration;
        });

        res.user_id = getSessionStoredJsonItem("user").user_id;
        res.total_final_amount_added = this.state.total_final_amount_added;
        this.setState({ is_waiting_for_response: true });

        axios
            .post(baseURL + "/add_for_prm?type=prm", res, getHeaders())
            .then(response => {
                toast.success("Inventory Added Successfully" + "  Barcode is  " + response.data.barcode);
                let init_state = this.getInitState();
                init_state["is_page_loading"] = false;
                init_state["is_waiting_for_response"] = false;
                this.setState(init_state);
                focusFirstElement();
                const interval = setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);
            })
            .catch(error => {
                toast.error("Invalid Data");
                this.setState({ is_page_loading: false });
            });
    }

    handleChange = (e) => {
        if (e.target.value === "1" || e.target.value === "0") {
            this.setState({
                showFlore: true,
                showFlore_tots: false,
                flourish_capsule_size: e.target.value
            })
        }
        if (e.target.value === "Powder") {
            this.setState({
                showFlore_tots: true,
                showFlore: false,
                flourish_capsule_size: e.target.value
            })
        }
    }

    onupdatefiles = (fileItems) => {
        const scope = this;

        let attachment_arr_temp = this.state.attachment_arr;
        attachment_arr_temp = [];
        fileItems.map((elem, index) => {
            let mediaTypePdf = elem.file.type.split("/")[1];
            let mediaSize = elem.file.size;
            let mediaType = elem.file.type;
            let mediaName = elem.file.name;
            var selectedFile = elem.file;
            if (selectedFile) {
                var fileToLoad = selectedFile;
                var fileReader = new FileReader();
                var base64;
                fileReader.readAsDataURL(fileToLoad);
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let res = base64.slice(28);
                    if (mediaTypePdf === "pdf" && mediaSize <= 5242880) {
                        let obj = {};
                        obj = {
                            "base64Media": res,
                            "mediaName": mediaName,
                            "type": mediaType,
                            "size": mediaSize
                        }
                        attachment_arr_temp.push(obj);
                        const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                        scope.setState({
                            attachment_arr: final_array
                        })
                    }
                };
            }
        })
    }



    handleRemove = () => {
        const scope = this;
        this.setState({
            attachment_arr: []
        })
        let attachment_arr_temp = this.state.attachment_arr;
        if (this.pond) {
            const remainingAttachments = this.pond.getFiles().map((file) => file.source);
            remainingAttachments.map((elem, index) => {
                let mediaSize = elem.size;
                let mediaType = elem.type;
                let mediaName = elem.name;
                var selectedFile = remainingAttachments[0];
                if (selectedFile) {
                    var fileToLoad = selectedFile;
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        let res = base64.slice(28);
                        if (mediaSize <= 5242880) {
                            let obj = {};
                            obj = {
                                "base64Media": res,
                                "mediaName": mediaName,
                                "type": mediaType
                            }
                            attachment_arr_temp.push(obj);
                            const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                            scope.setState({
                                attachment_arr: final_array
                            })
                        }
                    };

                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
    }
    

    render() {
        return (
            <React.Fragment>
                <br />
                <Grid.Row />
                <Segment className="transparent">
                    {this.state.is_waiting_for_response && (
                        <CustomLoader loading_text="Saving Inventory Data" />
                    )}
                    <Form>
                        <Grid columns="equal">
                            <CustomDivider
                                h_size="h3"
                                title="Contained Ingredients"
                                icon_name="dna"
                            />

                            
                            <Grid.Row>

                                <Grid.Column>
                                    <All
                                        isSubmitHandler={this.isSubmitHandler}
                                        organism={this.organism}
                                        is_submit={this.state.is_submit}
                                        total_amount_added={this.total_amount_added}
                                        total_final_concentration={this.total_final_concentration}
                                        total_final_amount_added={this.total_final_amount_added}
                                        number_of_months={this.number_of_months}
                                        formulation_factor={this.formulation_factor}
                                        AnswerKeyPress={this.AnswerKeyPress}
                                    />
                                </Grid.Column>
                                
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Capsule Size</label>}
                                            content="Select the Capsule Size."
                                        />
                                        <select onChange={this.handleChange}
                                            value={this.state.flourish_capsule_size}>
                                            {
                                                options && options.map((elem, i) => {
                                                    return (
                                                        <option key={i}>
                                                            {elem.value}
                                                        </option>

                                                    )
                                                })
                                            }
                                        </select>

                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5} style={{ display: this.state.showFlore ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point (number of capsules)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.onInputChange}
                                            value={this.state.reorder_point}
                                            placeholder="Reorder Point"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5} style={{ display: this.state.showFlore_tots ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point (grams)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.onInputChange}
                                            value={this.state.reorder_point}
                                            placeholder="Reorder Point"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5} style={{ display: this.state.showFlore ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity (number of capsules)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="quantity"
                                            onChange={this.onInputChange}
                                            value={this.state.quantity}
                                            placeholder="quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column width={5} style={{ display: this.state.showFlore_tots ? 'block' : 'none' }}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity (grams)</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="quantity"
                                            onChange={this.onInputChange}
                                            value={this.state.quantity}
                                            placeholder="quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Popup
                                        trigger={<label>Notes</label>}
                                        content="Enter notes."
                                    />
                                    <TextArea
                                        name="admin_notes"
                                        onChange={this.onInputChange}
                                        value={this.state.admin_notes}
                                        placeholder="Add Notes here..."
                                        style={{ minHeight: 100 }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />


                            <CustomDivider
                                h_size="h3"
                                title="Storage Information"
                                icon_name="dropbox"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Storage Location</label>}
                                            content="Enter the Organism Storage Location (Fridge Number)."
                                        />
                                        <Input
                                            placeholder="Storage Location (Fridge Number)"
                                            icon="boxes"
                                            iconPosition="left"
                                            name="storage_location"
                                            onChange={this.onInputChange}
                                            value={this.state.storage_location}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Shelf</label>}
                                            content="Enter the Organism Shelf Number."
                                        />
                                        <Input
                                            placeholder="Shelf"
                                            icon="box"
                                            iconPosition="left"
                                            name="shelf"
                                            onChange={this.onInputChange}
                                            value={this.state.shelf}
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <CustomDivider
                                h_size="h3"
                                title="Upload Documents"
                                icon_name="upload"
                            />
                            <Grid.Column>
                                <FilePond
                                    acceptedFileTypes={['application/pdf']}
                                    ref={ref => (this.pond = ref)}
                                    allowMultiple={true}
                                    allowReorder={true}
                                    allowRemove={true}
                                    maxFiles={10}
                                    allowFileSizeValidation={true}
                                    maxFileSize="5MB"
                                    onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                                    onremovefile={this.handleRemove}
                                    checkValidity={true}
                                    dropValidation
                                />
                            </Grid.Column>
                            <Grid.Row />
                            <Grid.Row />

                            <Grid.Row>
                                <Grid.Column>
                                    <Grid textAlign="center">
                                        <Button.Group>
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onSubmitClick} positive>
                                                        Submit
                                                    </Button>
                                                }
                                                content="Submit the Organism Information to the Database."
                                            />
                                            <Button.Or />
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onCancelClick}>Cancel</Button>
                                                }
                                                content="Discard the Organism Informtaion."
                                            />
                                        </Button.Group>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Form>
                </Segment>
            </React.Fragment>
        );
    }
}

export default PremadePrimeFormulla;