import React, { Component } from "react";
import axios from 'axios';
import { Header, Message } from "semantic-ui-react";
import { baseURL, portalURL, reportFormulationUrl } from "../../configuration/constants";
import { getHeaders } from "../helper";
import { toast } from "react-toastify";
import config from "../../configuration/config";
import HoverTagsData from "./Tags/hoverTagsData";
import "./index.css";

export class PastFormulations extends Component {

    state = {
        modalOpen: false,
        tableData: [],
        selectedSgNumber: "",
        SelectedRowTimepoint: "",
        SelectedRowTimepointArr: [],
        AbundanceArray: [],
        finalTableData: [],
        selectedRowDetails: "",
        organismArray: [],
        SelectedorganismArray: [],
        newArray: [],
        TimepointArray: [],
        organismBarcodeArray: [],
        Past_Formulation_data: []
    }

    pastFormula = () => {

        if (Object.keys(this.props.selectedSample).length > 0) {
            var sg_numberArr = [];
            var list_timepoint = [];

            var selectedRow = this.props.selectedSample;
            if (selectedRow.sg_number_count > 0) {
                selectedRow.timepoint_list.map((timpointArr, i) => {
                    sg_numberArr.push(timpointArr.sg_number)
                    list_timepoint.push(timpointArr.timepoint_number);
                })
            }
            let url = `${baseURL}/formulations/PastFormulations?sg_number=${sg_numberArr}&time_point=${list_timepoint}`;
            axios.get(url, getHeaders())
                .then((res) => {
                    if (res.data.length == 0) {
                        toast.error("No Previous Formula Found")
                        this.props.set_page_loading(false);
                        return;
                    };
                    // this.props.changeStatus(false);
                    this.setState({ Past_Formulation_data: res.data });
                    this.calculate_abudance_data(res.data);
                    this.props.set_page_loading(false);
                })
                .catch((err) => {
                    // this.props.changeStatus(false);
                    toast.error("Something goes wrong")
                }

                )
        }
    }
    pastReFormula = () => {
        var sg_number = [];
        var time_point_list = [];


        if (this.props.selectedSample.length > 0) {
            this.props.selectedSample.map((item) => {
                sg_number.push(item.sg_number);
                time_point_list.push(item.timepoint_number);
            })

            let url = `${baseURL}/formulations/PastFormulations?sg_number=${sg_number}&time_point=${time_point_list}`;
            axios.get(url, getHeaders())
                .then((res) => {
                    if (res.data.length == 0) {
                        toast.error("No Previous Formula Found")
                        this.props.set_page_loading(false);
                        return;
                    };

                    this.setState({ Past_Formulation_data: res.data });
                    this.calculate_abudance_data(res.data);
                    this.props.set_page_loading(false);
                })
                .catch((err) => {
                    this.props.set_page_loading(false);
                    toast.error("Something goes wrong")
                }

                )
        }
        else {
            toast.warning("No Previous Formula Found");
        }
    }
    componentDidMount = () => {
        if (this.props.ReformulationInfo) {
            this.pastReFormula();
        }
        else {
            this.pastFormula()
        }
    }
    calculate_abudance_data = (calculate_abudance_data) => {
        var tax_id_array = [];
        var sg_number = this.props.selectedSample.sg_number;
        var Past_Formulation_data = [...this.state.Past_Formulation_data];


        if (calculate_abudance_data.length === 0) return;
        calculate_abudance_data.map((item, index) => {

            // get tax_id
            item.organisms.map((organism) => {
                tax_id_array.push(organism.inventory.tax_id)
            })

            // get trends data from webportal
            let webURL = `${portalURL}/getFormulationAbudanceData`;

            axios
                .post(webURL, { sg_number: sg_number, token: config.token, tax_id: tax_id_array })
                .then(result => {
                    item.organisms.map((organism, organism_index) => {
                        if (result.data.data !== null) {
                            result.data.data.map((abudanceData) => {
                                if (organism.inventory.tax_id == abudanceData.tax_id) {
                                    Past_Formulation_data[index].organisms[organism_index].trends = abudanceData.trends;
                                }
                            })
                        }
                        else {
                            Past_Formulation_data[index].organisms[organism_index].trends = "NA";
                        }
                    })
                    this.setState({ Past_Formulation_data });
                });
        })
    }
    getArray = (item) => {

        if (item.amount_added === "NA") { toast.info("Please select another Organism ! Amount are Null"); return; }
        var ingredient = {
            amount_added: item.amount_added,
            concentration: item.inventory.concentration,
            final_amount_added: item.final_amount_added,
            final_concentration: item.final_concentration,
            inventory_id: item.inventory_id,
            success: item.success,
            organism_barcode: item.inventory.organism_barcode,
            scrap_amount: item.scrap_amount,
            total_cfu: item.total_cfu,
            current_tag: item.tags,
            // current_tag: item.current_tag,
            tags: item.tags
        }
        axios
            .post(baseURL + "/check-ingredient-availability", ingredient, getHeaders())
            .then(response => {
                this.props.getTagDataFromParent(response.data.success, response.data.data.available_inventory);
            })
    }

    render() {
        // console.log(this.props)

        return (
            <>
                {(this.state.Past_Formulation_data.length > 0) && <div class="ui segment" className={(this.props.ReformulationInfo) ? "PFC-Width" : ""}>


                    {<Message style={{ marginBottom: "0" }}>
                        <Header as="h2" textAlign="center">
                            Previous formulas
                        </Header>
                    </Message>}
                    <div className="previous-formulas-block">
                        {this.state.Past_Formulation_data && this.state.Past_Formulation_data.map((data, index) => {

                            return <div class="ui segment">
                                <div className="grid-1fr-block">
                                    <div className="col-md-6">
                                        <table className="pastformulation" >
                                            <thead style={{ fontWeight: 'bold' }} >
                                                <tr >
                                                    <th style={{ backgroundColor: "#b8bfc257" }} colSpan="2" >
                                                        {data.time_point && <b> {data.time_point}</b>} &nbsp;&nbsp;&nbsp;
                                                        {data.sample_id && (
                                                            <a href={`${reportFormulationUrl}/${data.sample_id}`} target="_blank">
                                                                <i class="fa fa-external-link" aria-hidden="true" onClick={() => {
                                                                }}></i>
                                                            </a>
                                                        )}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td rowSpan="1" style={{ fontWeight: 'bold' }} >SG Number</td> <td>{data.sample_id ? data.sample_id : ""}</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ fontWeight: 'bold' }}  >Formula ID</td> <td>{data.formula_id}</td>
                                                </tr>
                                                <tr >
                                                    <td style={{ fontWeight: 'bold' }} >Date Manufactured</td> <td>{data.date_made}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }} >Final Concentration</td> <td>{parseFloat(data.total_final_concentration).toFixed(3) + " CFU"}</td>
                                                </tr>

                                                <tr>
                                                    <td style={{ fontWeight: 'bold' }} className="types">Type</td> <td>{data.type.charAt(0).toUpperCase() + data.type.slice(1)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <table >

                                            <thead style={{ fontWeight: 'bold' }} >
                                                <tr>

                                                    <th style={{ backgroundColor: "#b8bfc257" }} rowSpan="1">ORGANISM BARCODES </th>
                                                    <th style={{ backgroundColor: "#b8bfc257" }} rowSpan="1">AMOUNT ADDED</th>
                                                    <th style={{ backgroundColor: "#b8bfc257" }} rowSpan="1">Tags</th>
                                                    <th style={{ backgroundColor: "#b8bfc257" }} rowSpan="1">TRENDS</th>
                                                    <th style={{ backgroundColor: "#b8bfc257" }} rowSpan="1">ADD</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.organisms && this.state.Past_Formulation_data && data.organisms.map((item, i) =>
                                                    <tr>
                                                        <td className="center aligned organism_barcode" >{item.inventory["organism_barcode"]}</td>
                                                        <td className="center aligned">{(item.amount_added == "NA") ? item.amount_added : item.amount_added + "g"}</td>
                                                        <td>{(item.tags && item.tags.length > 0) ? <HoverTagsData tags={item.tags} previous_formula={true} /> : ""}</td>
                                                        <td style={{
                                                            backgroundColor: ((
                                                                [undefined,0,"NA"].indexOf(item.trends)>-1
                                                            ) ? "white" : (item.trends < 0) ? "red" : "green")
                                                        }}> {
                                                                (item.trends === undefined) ?
                                                                    "loading..."
                                                                    : item.trends
                                                            }
                                                        </td>


                                                        {(item.amount_added !== "NA") ? <td onClick={() => this.getArray(item)} style={{ cursor: "pointer" }}>
                                                            <i className="fa fa-plus" aria-hidden="true" ></i>
                                                        </td> : "--"
                                                        }
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                }
                <br />
            </>

        )
    }
}