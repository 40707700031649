
import Config from "../../../configuration/config";
import { portalURL,baseURL } from "../../../configuration/constants";
import React, { Component } from "react";
import axios from "axios";
import {
  getHeaders, Product_Type,
} from "../../helper";
import DatePicker from "react-datepicker";
import { Form, Input, Grid, TextArea, Popup, Label, Segment,Radio } from "semantic-ui-react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { includes } from "lodash";
var sample_id_error = "";
var sampleId = "";
class SampleDataFromTable extends Component {
  is_mounted = true;

  state = {
    disabled: false,
    disabledInput: false,
    samples: [],
    product_type: "",
    id_sample:"",
    clinical_product_type:"",
    is_page_loading: true,
    showDropDown:false
  };

  handleChange = (value) => {
    this.props.floatFunction(value);
  }

  handleSubmit = (sampleId) => {
    
    let data = {
      sg_number: sampleId,
      token: Config.token
    }
    let resProduct;
    axios
      .post(portalURL + "/getKitProductType", data)
      .then(response => {
        this.props.setSampleId(sampleId,"sample_id");
        if (response.data.data.product_type == "") {
          this.props.flore_probiotics(response.data.data.flore_probiotics)
          toast.warn("Sample ID doesn't exists on the portal");
          this.checkError(response.data.data.flore_probiotics);
          return false;
        }
        else {
          resProduct = response.data.data.product_type;
          if(['(3 months)','(2 months)','(4 months)','(6 months)','(12 months)'].indexOf(resProduct) == -1){
            this.setState({showDropDown:false,id_sample:sampleId,clinical_product_type:resProduct});
            this.checkError(resProduct);
            this.apiCall(resProduct);
          }else{
              this.setState({showDropDown:true,id_sample:sampleId,clinical_product_type:resProduct});
            }
        }
      })
  }


  apiCall = (product_type)=>{
    this.handleChange(product_type)
    var ProductType= Product_Type[product_type];
    axios
    .get(baseURL + "/check_reformulation/" + this.state.id_sample+"/"+ProductType, getHeaders())
    .then(response => {
        this.props.changeInputReformulation(response.data.success);
      })
    this.props.flore_probiotics(product_type)
  }
  
  componentWillUnmount() { this.is_mounted = false; }

  handleBlur = (e) =>  this.handleSubmit(e); 

  selectFloreClinicalProductType = (value)=> {
    var clinical_product_type  = this.state.clinical_product_type.concat(' / ',value)
    this.setState({showDropDown:false});
    this.setState({ clinical_product_type });
    this.apiCall(clinical_product_type);
    this.checkError(clinical_product_type);

}

checkError=(clinical_product_type)=>{
  if(clinical_product_type.includes("é")){
    clinical_product_type=clinical_product_type.replace("é","e");
  }
  var value = ""; 
  this.props.rowData.forEach((details) => {
    var sample_id = details.sample_id.toLowerCase().trim();
    if (sample_id == this.state.id_sample.toLowerCase().trim()
     && clinical_product_type.trim() == details.product_type.trim()) {
    sample_id_error = `Already exists in formulation queue
    page either take another sg number
       or make formulation of this sgnumber
       in formulation page`;
      }
      value = sample_id_error; 
    });
      this.props.setSampleId(value);
  }


  render() {
    return (
      <Grid columns="equal">

        <Grid.Row>
          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Sample ID </label>}
                content="Enter the sample ID."
              />
              <form onSubmit={this.handleSubmit}>
                <Input
                  id="reformulation_sample_id"
                  name="sample_id"
                  onBlur={(e)=>this.handleBlur(e.target.value)}
                  placeholder="Sample ID"
                  disabled={(this.state.showDropDown || this.props.product_type)}
                  className={ (sample_id_error !== "" && !this.state.showDropDown ) ? "validation_error_input": "" }
                />
              </form>

              {(sample_id_error !== "" && !this.state.showDropDown) && (
                <Label basic color="red" pointing>
                  {sample_id_error}
                </Label>
              )}

              {this.props.new_save && (
                <Label basic color="red" pointing>
                  Please select a Sample ID
                </Label>
              )}

            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Grid.Column>
              <Form.Field fluid="true">
                <Popup
                  trigger={<label>Product Type</label>}
                  content="Enter the Product Type."
                />
                <select onChange={(event) => this.handleChange(event.target.value)}
                  disabled={(this.props.product_type || this.state.showDropDown) ? true : false}
                  

                  value={this.props.product_type}
                >
                  {
                    this.props.floatOptions && this.props.floatOptions.map((elem, i) => {
                      return (
                        <option value={elem.product_type} key={i}>
                          {elem.product_type}
                        </option>

                      )
                    })
                  }
                </select>
              </Form.Field>
            </Grid.Column>
          </Grid.Column>
          {this.state.showDropDown &&<Grid.Column >
              <Form.Field fluid="true">
                <Popup
                  trigger={<label>Flore Clinical Product Type</label>}
                  content="Select Flore Clinical Product Type."
                />
                <select
                onChange={(event) => this.selectFloreClinicalProductType(event.target.value)}>
                  <option selected disabled>Select Product Type</option>
                  <option value='prebiotics'>Prebiotic</option>
                  <option value='probiotics'>Probiotic</option>
                </select>
              </Form.Field>
            </Grid.Column>}
          <Grid.Column id="formulaColumn">

            <Form.Field fluid="true"  >
              <Popup
                trigger={<label>Formula ID</label>}

                content="Enter the Formula ID."
              />
              <Input
                id="formula_id"
                name="formula_id"
                onChange={this.props.onChange}
                value={this.props.formula_id}
                className="full_opacity"
                icon="warning sign"
                iconPosition="left"
                placeholder="Formula ID "
              />

            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Date Created</label>}
                content="Enter Date Created."
              />
              <DatePicker
                selected={this.props.date_created}
                placeholderText="Click to select the Date Created"
                todayButton={"Current Date"}
                dateFormat="MM/dd/yyyy"
                isClearable={true}
                showMonthDropdown
                showYearDropdown
                disabled={this.props.disabledPermission}
                dropdownMode="select"
                openToDate={new Date(Config.default_calendar_date)}
                onChange={date => {
                  this.props.onDateChange(
                    Config.formulations.date_created,
                    date
                  );
                }}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Lot</label>}
                content="Enter the Lot number."
              />
              <Input
                id="lot"
                name="lot"
                onChange={this.props.onChange}
                value={this.props.lot}
                icon="hashtag"
                iconPosition="left"
                placeholder="Lot"
                disabled={this.props.disabledPermission}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field required fluid="true">
              <Popup
                trigger={<label>Number of Months</label>}
                content="Enter the Number of Months."
              />
              <Input
                name="number_of_months"
                type="number"
                onChange={this.props.onChange}
                value={this.props.number_of_months}
                icon="calendar alternate outline"
                iconPosition="left"
                placeholder="Number of Months"
                // disabled={this.props.disabledPermission || this.props.is_number_of_month_disabled}
                disabled={this.props.disabledPermission }
              />
              {this.props.form_errors.number_of_months && (
                <Label basic color="red" pointing>
                  {this.props.form_errors.number_of_months}
                </Label>
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column >
            <Segment>
              <Form.Field required fluid="true">
                <Popup
                  trigger={<label>Reformulation</label>}
                  content="Reformulation?"
                />
                <Radio
                  toggle
                  onClick={this.props.changeReformulation}
                  checked={this.props.reformulate === true ? true : false}
                  disabled={this.props.reformulate === true ? true : false}
                />
              </Form.Field>
            </Segment>
          </Grid.Column>
          < Grid.Column ></ Grid.Column>
          < Grid.Column ></ Grid.Column>

        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Popup
              trigger={<label>Admin Notes</label>}
              content="Enter Admin notes."
            />
            <TextArea
              id="admin_notes"
              name="admin_notes"
              onChange={this.props.onChange}
              value={this.props.admin_notes}
              placeholder="Add Notes here..."
              style={{ minHeight: 100 }}
              disabled={this.props.disabledPermission}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
    );
  }
}
export default SampleDataFromTable;
