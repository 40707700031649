//----------------------------------------------------------------------//
// Module: Config                                                       //
// Tasks: 1) Contains Config data                                       //
// Author: Yash P Shah                                                  //
//----------------------------------------------------------------------//

const MAIN_TITLE = "Probiotic Formulations";

export default {
  default_calendar_date: new Date(),

  login: {},

  reset_password: {
    title: "Reset Password | " + MAIN_TITLE,
    section_title: "Reset Password"
  },

  inventory: {
    title: {
      create: "Create Inventory Item | " + MAIN_TITLE,
      display: "Inventory | " + MAIN_TITLE
    },
    section_title: {
      create: "Create Inventory Item",
      display: "Inventory"
    },
    open_date: "Open Date",
    receive_date: "Receive Date",
    expiration_date: "Expiration Date"
  },


  Newinventory: {
    section_title: {
      create: "Create Inventory",
      display: "Create New Inventory | " + MAIN_TITLE
    },

  },

  ManageSupplement: {
    section_title_manage: {
      create: "Manage Supplement Facts | " + MAIN_TITLE,
      display: "Manage Supplement Facts | " + MAIN_TITLE
    }
  },

  formulations: {
    title: {
      create: "Create Formulation | " + MAIN_TITLE,
      createRefill: "Create Refill | " + MAIN_TITLE,
      display: "All Formulations | " + MAIN_TITLE,
      createReformulation: "Create Reformulation | " + MAIN_TITLE,
      allergean: "Manage Allergens | " + MAIN_TITLE,
    },
    section_title: {
      create: "Create Formulation",
      createRefill: "Create Refill",
      display: "All Formulations",
      createReformulation: "Create Reformulation"
    },
    date_made: "Date Made",
    date_created: "Date Created"
  },
  formulationsAdmin: {
    title: {
      display: "Display Formulations (Admin) | " + MAIN_TITLE
    },
    section_title: {
      display: "Display Formulations (Admin)"
    }
  },
  formulationsOperator: {
    title: {
      display: "Manufacturing Queue | " + MAIN_TITLE
    },
    section_title: {
      display: "Manufacturing Queue"
    }
  },
  qualtiyAssurance: {
    title: {
      display: "Quality Assurance | " + MAIN_TITLE
    },
    section_title: {
      display: "Quality Assurance"
    }
  },
  // formulations_organism: {
  //   title: {
  //     display: "Prebiotics & Probiotics Management"
  //   },
  //   section_title: {
  //     display: "Prebiotics & Probiotics Management"
  //   }
  // },
  users: {
    title: {
      create: "Create User | " + MAIN_TITLE,
      display: "Display Users | " + MAIN_TITLE
    },
    section_title: {
      create: "Create User",
      display: "User Management"
    }
  },
  userPermission: {
    title: {
      display: "Group Management"
    },
    section_title: {
      display: "Group Management"
    }
  },
  group: {
    title: {
      create: "Create Group",
      display: "Create Group | " + MAIN_TITLE
    }
  },
  ai: {
    title: {
      suceesMarker: "Floré AI Success Marker",
      floreAiDashboard: "Floré AI Dashboard",
      monthlyUsageInfo: "Monthly Usage Info",
      suceesMarker_display: "Floré AI Success Marker | " + MAIN_TITLE,
      floreAiDashboard_display: "Floré AI Dashboard | " + MAIN_TITLE,
      monthlyUsageInfo_display: "Monthly Usage Info | " + MAIN_TITLE
    }
  },

  token: "U3VuX2dlbm9fMTIz",
};
