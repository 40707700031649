//-------------------------------------------------------------------//
// Module: ColumnDefs                                                //
// SubModule: InventoryColumnDefs                                    //
// Tasks: 1) Returns an Array of Inventory Column Defs               //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import { getSessionStoredJsonItem } from "../../components/helper";

export function getInventoryColumnDefsArray(
  converted_user_options_json,
  all_user_ids,
  dateComparator
) {
  let role_name = getSessionStoredJsonItem("user").role_name;
  let is_admin = role_name === "admin";
  let is_manager = role_name === "manager";

  let is_chrome = sessionStorage.getItem("is_chrome") === true;
  let editablePermission = localStorage.getItem("displayInventory");
  return [
    {
      headerName: "Ingredient Barcode",
      field: "organism_barcode",
      filter: "agTextColumnFilter",
      pinned: "left",
      width: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      // editable: editablePermission ? true : false,
      editable: false
    },
    {
      headerName: "Manufacturer Info",
      headerClass: "my-css-class",
      marryChildren: true,
      children: [
        {
          headerName: "Manufacturer",
          field: "manufacture",
          editable: false,
          filter: "agTextColumnFilter"
        },
        {
          headerName: "Manufacturer Lot",
          field: "manufacture_lot",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter"
        }
      ]
    },
    {
      headerName: "Concentration (B CFU/gm)",
      field: "concentration",
      type: "numericColumn",
      editable: false,
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Quantity (gm)",
      field: "qty",
      type: "numericColumn",
      editable: false,
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Reorder Point (gm)",
      field: "reorder_point",
      type: "numericColumn",
      editable: false,
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Dates",
      children: [
        {
          headerName: "Receive Date",
          field: "receive_date",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          cellRenderer: (params) => {
            let a, b, c;
            a = params.value.split(" ")[0];
            b = a.split("-");
            c = b[0];
            if (c === "1969") {
              return (
                `
                <div>
                ${""}
                </div>
                `
              )
            }
            else {
              return (
                `
                  <div>
                  ${params.value}
                  </div>
                  `
              )
            }
          }
        },
        {
          headerName: "Open Date",
          field: "open_date",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          cellRenderer: (params) => {
            let a, b, c;
            a = params.value.split(" ")[0];
            b = a.split("-");
            c = b[0];
            if (c === "1969") {
              return (
                `
                <div>
                ${""}
                </div>
                `
              )
            }
            else {
              return (
                `
                  <div>
                  ${params.value}
                  </div>
                  `
              )
            }
          }
        },
        {
          headerName: "Expiration Date",
          field: "expiration_date",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
        }
      ]
    },
    {
      headerName: "Storage Location",
      field: "storage_location",
      editable: false,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Shelf",
      field: "shelf",
      editable: false,
      filter: "agTextColumnFilter"
    },
    {
      headerName: "Scrap Factor",
      field: "scrap_factor",
      editable: false,
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "Notes",
      field: "notes",
      editable: false,
      filter: "agTextColumnFilter",
      width: 150
    },
    {
      headerName: "Timestamp",
      children: [
        {
          headerName: "Created At",
          field: "created_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator
        },
        {
          headerName: "Last Updated At",
          field: "updated_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator
        },
        {
          headerName: "Notification Sent?",
          field: "email_sent",
          editable: false,
          cellEditor: "agSelectCellEditor",
          comparator: dateComparator,
          cellEditorParams: {
            values: [0, 1]
          },
          refData: { 0: "No", 1: "Yes" }
        }
      ]
    },
    {
      headerName: "Username",
      field: "user_id",
      pinned: "left",
      width: 110,
      filter: "agTextColumnFilter",
      editable: false,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: all_user_ids
        //values: [13,6,2]
      },
      refData: converted_user_options_json
      //refData: { 13: "yash", 6: "jash", 2: "kash" }
    },
    {
      headerName: "Tax ID",
      field: "tax_id",
      pinned: "left",
      width: 100,
      filter: "agTextColumnFilter",
      editable: function (params) {
        let axis;
        let Tax=params.data.organism_barcode.split("_", 1);
        for (let i = 0; i < Tax.length; i++) {
          axis = Tax[i];
        } 
        if(params.data.tax_id==="" || params.data.tax_id===null && axis == "PRM" || axis == "PRE" || axis == "FOR"){
          return false;
        }
        else{
          return false;
        }
        
      },
      cellRenderer: (params) => {
        if (params.value === null) {
          return (
            `
            <div>
            ${"NA"}
            </div>
            `
          )
        }
        else {
          return (
            `
              <div>
              ${params.value}
              </div>
              `
          )
        }
      }
    },

    {
      headerName: "Scientific Name",
      field: "real_name",
      pinned: "left",
      width: 125,
      filter: "agTextColumnFilter",
      editable: function (params) {
        let axis;
        let Scientific=params.data.organism_barcode.split("_", 1);
        for (let i = 0; i < Scientific.length; i++) {
          axis = Scientific[i];
        }
        if(axis==="FOR" || axis==="PRM"){
          return false;
        }
        else{
          return false;
        }
        
      },
      cellRenderer: (params) => {
        if (params.value === null) {
          return (
            `
            <div>
            ${"NA"}
            </div>
            `
          )
        }
        else {
          return (
            `
              <div>
              ${params.value}
              </div>
              `
          )
        }
      }
    },

    {
      headerName: "Common Name",
      field: "name",
      pinned: "left",
      width: 130,
      filter: "agTextColumnFilter",
      editable: function (params) {
        let axis;
        let Scientific=params.data.organism_barcode.split("_", 1);
        for (let i = 0; i < Scientific.length; i++) {
          axis = Scientific[i];
        }
        if(axis==="PRO" || axis==="FOR" || axis==="PRM"){
          return false;
        }
        else{
          return false;
        }
        
      },
      cellRenderer: (params) => {
        if (params.value === null) {
          return (
            `
            <div>
            ${"NA"}
            </div>
            `
          )
        }
        else {
          return (
            `
              <div>
              ${params.value}
              </div>
              `
          )
        }
      }
    },

    {
      headerName: "Is Available?",
      field: "is_available",
      // filter: "agTextColumnFilter",
      editable: editablePermission ? true : false,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [0, 1]
      },
      refData: { 0: "No", 1: "Yes" }
    }
  ];
}
