//----------------------------------------------------------------------//
// Module: User                                                         //
// SubModule: CreateUser                                                //
// Tasks: 1) Fetches Roles from db                                      //
//        2) API call to save user in database.                         //
// Author: Yash P Shah                                                  //
//----------------------------------------------------------------------//

import Config from "../../configuration/config";
import CustomLoader from "../custom_elements/CustomLoader";
import SuccessMessage from "../custom_elements/SuccessMessage";
import ErrorMessage from "../custom_elements/ErrorMessage";
import {
  focusFirstElement,
  customPrint,
  getHeaders,
  isFormValid,getUserRole,
  simplifyModulePermissionData,
} from "../helper";
import {
  baseURL,
  PRINT_CATEGORIES,
  EMAIL_REGEX
} from "../../configuration/constants";

import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import {
  Form,
  Popup,
  Dropdown,
  Segment,
  Button,
  Header,
  Message,
  Input,
  Grid,
  Label
} from "semantic-ui-react";

class CreateUser extends Component {
  is_mounted = false;


  componentWillUnmount() {
    this.is_mounted = false;
  }

  // STATE INFO
  // default role_id -> 3 (viewer)
  getInitState = () => {
    return {
      is_saving_successful: false,
      is_saving_failed: false,
      is_waiting_for_response: false,
      is_message_visible: true,
      response_message: "",
      is_page_loading: true,
      createuserpermission:0,
      createuser:0,
      disabledPermission:"",
      full_name: "",
      username: "",
      password: "",
      role_id: '',

      form_errors: {
        full_name: "",
        username: "",
        password: ""
      },

      role_options: [
        {
          key: 1,
          text: "Admin",
          value: 1,
          icon: "user secret"
        },
        {
          key: 2,
          text: "Manager",
          value: 2,
          icon: "user circle"
        },
        {
          key: 3,
          text: "Technician",
          value: 3,
          icon: "user md"
        }
      ]
    };
  };

  state = this.getInitState();

  componentDidMount() {
    document.title = Config.users.title.create;
   
    this.getUserPermission();
    focusFirstElement();

    this.is_mounted = true;

    // API call to fetch the roles
    customPrint(
      Config.users.section_title.create,
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/users/roleOptions"
    );

    axios
      .get(baseURL + "/users/roleOptions", getHeaders())
      .then(response => {
        customPrint(
          Config.users.section_title.create,
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        //fetches new roles apart from Admin, Op, Viewer
        let new_roles = response.data.filter(role => {
          return (
            role.name.toUpperCase() !== "ADMIN" &&
            role.name.toUpperCase() !== "MANAGER" &&
            role.name.toUpperCase() !== "TECHNICIAN"
          );
        });

        //pushes new_role-object in new_role_options[]
        let new_role_options = this.state.role_options;
        let i = 3;
        new_roles.map(new_role => {
          i++;
          new_role_options.push({
            key: new_role.id,
            value: i,
            icon: "user",
            text: new_role.name
              .charAt(0)
              .toUpperCase()
              .concat(new_role.name.substr(1))
          });
          return new_role;
        });

        if (this.is_mounted) {
          this.setState({
            role_options: new_role_options,
            is_page_loading: false
          });
        }
      })
      .catch(error => {
        alert("Fetching Roles | Error: " + error);
        if (this.is_mounted) {
          this.setState({ is_page_loading: false });
        }
      });
  }

  getUserPermission = () => {

    let Role = getUserRole();
    let login = localStorage.getItem('login_success');
 

    if (login == 1) {
      axios
        .get(baseURL + "/users/PermissionDetails",getHeaders())
        .then(response => {
          let permissionData = null;

          permissionData = simplifyModulePermissionData(response.data);
          permissionData = permissionData.filter(data => {
            if (data.role_name === Role) {
              return data;
            }

          })
        
       
          let createuser = 0;
         
          let disabledPermission
          let createuserpermission = permissionData.forEach(result => {
          

            if (result.module_name == "Create User" && result.edit_permission === 1) {
              createuser = 1;
              disabledPermission=false;
            }
           
          })


          if ( createuser == 0 ) {
            createuserpermission = 0;
            disabledPermission=true;
          }
          else {

            createuserpermission = 1;
          }

          this.setState({
            createuserpermission,
            createuser,
            disabledPermission,
          });

   
      })
      .catch(error => {
        alert("Fetching Display Grid | Error: ", error);
      });
  }
}
onselectChange = (event,{value}) =>{

  var x = this.state.role_options;
  var y;

  let form_errors = { ...this.state.form_errors };

  form_errors.role_id = (value)?"":"Role is Required"; 
  this.setState({ form_errors })
  
  x.filter((elem)=>{
   if(elem.value===value){
     y=elem.key;     
   }
  
  })
  this.setState({
    role_id:y
  })

}
  //handles changes by user to input field or selection on role
  onInputChange = (e, { name, value }) => {
    let form_errors = { ...this.state.form_errors };

    switch (name) {
      case "full_name":
        form_errors.full_name =
          value.length < 3 ? "Minimum 3 characaters required" : "";
        break;
      case "username":
        form_errors.username = EMAIL_REGEX.test(value)
          ? ""
          : "Invalid email address used for username";
        break;
      case "password":
        form_errors.password =
          value.length < 6 ? "Minimum 6 characaters required" : "";
        break;

      default:
        break;
    }

    this.setState({ form_errors, [name]: value });
  };

  error_fun = (params,name)=>{
    let form_errors = { ...this.state.form_errors };
    form_errors[params] = `${name} is  Required`;
    this.setState({form_errors:form_errors});
  }

  //handles click on input button
onSubmitClick = event => {

  this.setState({
      form_errors: {
        full_name: "",
        username: "",
        password: "",

      },
    });

  if(this.state.full_name == "")
{
  this.error_fun("full_name","Full Name");
  return;
}
if(this.state.username == "")
{
  this.error_fun("username","Email");
  return;  
}
if(this.state.password == "")
{
  this.error_fun("password","Password");
  return;  
}
if(this.state.role_id == "")
{

  this.error_fun("role_id","Role");
  return;
}
    if (isFormValid(this.state)) {
      this.setState({
        is_saving_successful: false,
        is_saving_failed: false
      });

      let res = _.pick(this.state, [
        "username",
        "role_id",
        "password",
        "full_name"
      ]);

      this.setState({ is_waiting_for_response: true });

      // API call to save the user data
      customPrint(
        Config.users.section_title.create,
        PRINT_CATEGORIES.REQUEST,
        baseURL + "/users",
        res
      );

      axios
        .post(baseURL + "/users", res, getHeaders())
        .then(response => {
          customPrint(
            Config.users.section_title.create,
            PRINT_CATEGORIES.RESPONSE,
            response
          );


          if(!response.data.success){
            for (const [key, value] of Object.entries(response.data)) {
            let form_errors = { ...this.state.form_errors };
              form_errors[key] = value[0]; this.setState({form_errors:form_errors});}
            this.setState({ is_waiting_for_response: false, })
              return;
          }
          
         this.setState({
            is_saving_successful: response.data.success,
            response_message: response.data.message,
            is_waiting_for_response: false,
            is_message_visible: true,
            full_name: "",
            username: "",
            password: "",
            role_id: "",
          });
          setTimeout(() => {
            window.location.reload()
          },1000)

          focusFirstElement();
        })
        .catch(error => {
          alert("Saving User | Error: " + error);
          this.setState({ is_waiting_for_response: false });
        });
    } else {
      alert(
        "Please remove the errors shown in red font prior to submiting the form."
      );
    }
  };

  // handles cancel click
  onCancelClick = () => {
    let init_state = this.getInitState();
    init_state["is_page_loading"] = false;
    init_state["role_options"] = this.state.role_options;
    this.setState(init_state);
  };

  //hides the SUCCESS/FAILURE message
  hideMessage = () => {
    this.setState({ is_message_visible: false });
  };

  render() {


    return (
      <React.Fragment>
        {this.state.is_page_loading && <CustomLoader />}

        {this.state.is_saving_successful && (
          <SuccessMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="User"
            message={this.state.response_message}
          />
        )}

        {this.state.is_saving_failed && (
          <ErrorMessage
            is_message_visible={this.state.is_message_visible}
            hideMessage={this.hideMessage}
            module_name="User"
            message={this.state.response_message}
          />
        )}
        <br />

        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column />
            <Grid.Column>
              <Message>
                <Header as="h2" textAlign="center">
                  {Config.users.section_title.create}
                </Header>
              </Message>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0px" }}>
            <Grid.Column />
            <Grid.Column>
              <Segment className="transparent">
                {this.state.is_waiting_for_response && (
                  <CustomLoader loading_text="Saving User Data" />
                )}
                <Form id="createUserForm">
                  <Form.Field required fluid="true">
                    {/* Tooltip is provided to every label */}
                    <Popup
                      trigger={<label>Full name</label>}
                      content="Enter the full name of the User you want to enter."
                    />

                    <Input
                      id="full_name"
                      name="full_name"
                      onChange={this.onInputChange}
                      value={this.state.full_name}
                      icon="address card"
                      iconPosition="left"
                      placeholder="Enter Your Full Name"
                      disabled={this.state.disabledPermission}
                      className={
                        this.state.form_errors.full_name !== ""
                          ? "validation_error_input"
                          : ""
                      }
                    />
                    {this.state.form_errors.full_name && (
                      <Label basic color="red" pointing>
                        {this.state.form_errors.full_name}
                      </Label>

                      // <span className="validation_error_message">
                      //   {" "}
                      //   {this.state.form_errors.full_name}{" "}
                      // </span>
                    )}
                  </Form.Field>

                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Email Address</label>}
                      content="Enter the Username (Email ID)."
                    />

                    <Input
                      name="username"
                      value={this.state.username}
                      onChange={this.onInputChange}
                      icon="info"
                      iconPosition="left"
                      disabled={this.state.disabledPermission}
                      placeholder="Enter Your Email Address"
                      className={
                        this.state.form_errors.username !== ""
                          ? "validation_error_input"
                          : ""
                      }
                    />
                    {this.state.form_errors.username && (
                      <Label basic color="red" pointing>
                        {this.state.form_errors.username}
                      </Label>
                      // <span className="validation_error_message">
                      //   {" "}
                      //   {this.state.form_errors.username}{" "}
                      // </span>
                    )}
                  </Form.Field>

                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Password</label>}
                      content="Enter the Password."
                    />

                    <Input
                      name="password"
                      onChange={this.onInputChange}
                      value={this.state.password}
                      type="password"
                      icon="lock"
                      iconPosition="left"
                      placeholder="Enter Your Password"
                      disabled={this.state.disabledPermission}
                      className={
                        this.state.form_errors.password !== ""
                          ? "validation_error_input"
                          : ""
                      }
                    />
                    {this.state.form_errors.password && (
                      <Label basic color="red" pointing>
                        {this.state.form_errors.password}
                      </Label>
                      // <span className="validation_error_message">
                      //   {" "}
                      //   {this.state.form_errors.password}{" "}
                      // </span>
                    )}
                  </Form.Field>

                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Assign Role</label>}
                      content="Assign Role to this user."
                    />

                    <Dropdown
                      name="role_id"
                      onChange={this.onselectChange}
                      value={this.state.role_options.value}
                      fluid
                      search
                      selection
                      disabled={this.state.disabledPermission}
                      options={this.state.role_options}
                    />
                  {this.state.form_errors.role_id && (
                      <Label basic color="red" pointing>
                        {this.state.form_errors.role_id}
                      </Label>
                  )}
                  </Form.Field>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <Grid.Row>
                    <Grid.Column>
                      <Grid textAlign="center">
                        <Button.Group>
                          <Popup
                            trigger={
                              <Button
                                content="Submit"
                                icon="check"
                                labelPosition="left"
                                positive
                                disabled={this.state.disabledPermission}
                                onClick={this.onSubmitClick}
                              />
                            }
                            content="Enter the Formulation Information."
                          />

                          <Button.Or />
                          <Popup
                            trigger={
                              <Button
                                content="Cancel"
                                icon="ban"
                                labelPosition="right"
                                negative
                                disabled={this.state.disabledPermission}
                                onClick={this.onCancelClick}
                              />
                            }
                            content="Discard the Formulation Information."
                          />
                        </Button.Group>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row />
                  <Grid.Row />
                  <br />
                  <br />
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default CreateUser;
