//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: Flourish                                            //
// SubSubSubModule: Organisms                                        //
// SubSubSubSubModule: Organism                                      //
// Tasks: 1) Receives and displays Individual Organism               //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import {
  Grid,
  Segment,
  Form,
  Input,
  Button,
  Popup,
  Dropdown,
  Icon
} from "semantic-ui-react";


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';

class Organism extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organism_barcode: ""
    };
  }

  handleClick = () => {
    document.getElementById("inventory_id").style.backgroundColor = "transparent";
    document.getElementById('amount_added').style.backgroundColor = "transparent";
    document.getElementById('concentration').style.backgroundColor = "transparent";
    document.getElementById('final_concentration').style.backgroundColor = "transparent";
  }
  instanceArr = [];
  onRefreshClick = () => {
    toast.success("Refreshed Successfully");
    this.props.changeStructureFunction()
  }

  render() {

    return (
      <Segment style={{ width: "77rem" }}>
        <Grid>

          {this.props.is_disabled && (
            <Grid.Row>
              <Grid.Column width={5}>
                <Form.Field required fluid="true">
                  <Popup
                    trigger={<label>Ingredient Barcode</label>}
                    content="Enter the Ingredient Barcode."
                  />
                  <Input
                    id="inventory_id"
                    name="inventory_id"
                    style={{ opacity: 1, backgroundColor: "#b8fcff" }}
                    disabled={true}
                    value={this.props.organism.organism_barcode}
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={3}>
                <Form.Field required fluid="true" >
                  <Popup
                    trigger={<label>Amount Added</label>}
                    content="Enter the amount added."
                  />
                  <Input
                    step="any"
                    type="number"
                    style={{ opacity: 1 }}

                    onChange={(e) => 
                      this.props.manual_add_organism(
                        Number(e.target.value),
                        this.props.organism.inventory_id,
                        this.props.organism.organism_barcode
                        )}
                    value={this.props.organism.amount_added}
                    label={{ content: "g" }}
                    labelPosition="right"
                    placeholder="Amount Added"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ marginLeft: "10px" }} width={3}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Concentration</label>}
                    content="Enter the Concentration."
                  />
                  <Input
                    id="concentration"
                    name="concentration"
                    onChange={this.props.onChange}
                    value={this.props.organism.concentration}
                    className="full_opacity"
                    disabled
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Concentration"
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column width={3}>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Total CFU</label>}
                    content="Enter Total CFU."
                  />
                  <Input
                    id="final_concentration"
                    name="final_concentration"
                    onChange={this.props.onChange}
                    value={this.props.organism.concentration * this.props.organism.amount_added}
                    className="full_opacity"
                    disabled
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Total CFU"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={1} style={{ textAlign: "right" }}>
                <Form.Field fluid="true">
                  <label style={{ visibility: "hidden" }}>.</label>
                  <Popup
                    trigger={
                      <Button
                        onClick={() => {
                          this.props.onDeleteOrganismClick(
                            this.props.organism.organism_barcode,
                            " "
                          );
                        }}
                        icon="delete"
                        negative
                        circular
                      />
                    }
                    content="Delete this Organism."
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}



          {!this.props.is_disabled && (

            <Grid>
              <Grid.Row className="block" >
                <Grid.Column width={5}>
                  <Form.Field required fluid="true" >
                    <Button
                      id="deleteRecordsButton"
                      basic
                      onClick={this.onRefreshClick}
                      icon
                      labelPosition="right"
                    >
                      Refresh Inventory
                      <Icon name="refresh" />
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="block" >
                <Grid.Column width={5}>
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Ingredient Barcode</label>}
                      content="Enter the Ingredient Barcode."
                    />
                    {this.props.is_disabled && (
                      <Input
                        id="inventory_id"
                        name="inventory_id"
                        style={{ opacity: 1, backgroundColor: "#b8fcff" }}
                        disabled={true}
                        value={this.props.organism.organism_barcode}
                        fluid
                      />
                    )}
                    {!this.props.is_disabled && (

                      <Dropdown
                        id="inventory_id"
                        name="inventory_id"
                        style={{ opacity: 1, backgroundColor: "#b8sfcff" }}
                        onClick={this.handleClick}
                        onChange={(event, data) => {
                         let organism_barcode = "";
                          data.options.forEach(inventory => {
                            if (inventory.key === data.value) {
                              organism_barcode = inventory.text;
                            }
                          });
                          this.props.onChange(event, {
                            name: "organism_barcode",
                            value: organism_barcode
                          });
                          this.props.onChange(event, {
                            name: data.name,
                            value: data.value
                          });
                        }}
                        value={this.props.inventory_id}
                        fluid
                        search
                        selection
                        options={
                           this.props.inventory_options.filter((instance, index) => {
                            if (this.props.inventory_options[index]['active'] !== false) {
                              this.instanceArr.push({
                                key: instance.key,
                                value: instance.value,
                                active: instance.active,
                                text: instance.text,
                                icon: "dna"
                              })
                              return this.instanceArr;
                            }
                          })
                        }

                      />
                    )}
                  </Form.Field>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Form.Field required fluid="true" >
                    <Popup
                      trigger={<label>Amount Added</label>}
                      content="Enter the amount added."
                    />
                    <Input
                      id="amount_added"
                      type="number"
                      style={{ opacity: 1, width: "80%", }}
                      name="amount_added"
                      onChange={this.props.onChange}
                      disabled={this.props.disabledPermission ? this.props.disabledPermission : this.props.is_disabled}
                      value={
                        this.props.is_disabled
                          ? Math.floor(this.props.organism.amount_added * 1000) / 1000
                          : this.props.amount_added
                      }
                      onClick={this.handleClick}
                      icon="weight"
                      iconPosition="left"
                      label={{ content: "g" }}
                      labelPosition="right"
                      placeholder="Amount Added"
                      autocomplete="off"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Form.Field fluid="true">
                    <Popup
                      trigger={<label>Concentration</label>}
                      content="Enter the Concentration."
                    />
                    <Input
                      id="concentration"
                      name="concentration"
                      onChange={this.props.onChange}
                      value={
                        this.props.is_disabled
                          ? this.props.organism.concentration
                          : this.props.concentration
                      }
                      // value={this.props.concentration}
                      className="full_opacity"
                      disabled
                      icon="warning sign"
                      iconPosition="left"
                      placeholder="Concentration"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Form.Field fluid="true">
                    <Popup
                      trigger={<label>Total CFU</label>}
                      content="Enter Total CFU."
                    />
                    <Input
                      id="final_concentration"
                      name="final_concentration"
                      onChange={this.props.onChange}
                      value={
                        this.props.is_disabled
                          ? this.props.organism.concentration * Math.floor(this.props.organism.amount_added * 1000) / 1000
                          : this.props.concentration * Math.floor(this.props.amount_added * 1000) / 1000
                      }

                      className="full_opacity"
                      disabled
                      icon="warning sign"
                      iconPosition="left"
                      placeholder="Total CFU"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={1} style={{ textAlign: "right" }}>
                  <Form.Field fluid="true">
                    <label style={{ visibility: "hidden" }}>.</label>
                    <Popup
                      trigger={
                        <Button
                          onClick={() => {
                            this.props.onDeleteOrganismClick(
                              this.props.organism.organism_barcode,
                              " ");
                          }}
                          icon="delete"
                          disabled={!this.props.is_disabled}
                          negative
                          circular
                        />
                      }
                      content="Delete this Organism."
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}

        </Grid>
      </Segment>
    );
    //   <Segment>

    //   </Segment>
    // );
  }
}

export default Organism;
