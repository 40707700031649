//-------------------------------------------------------------------//
// Module: CustomDivider                                             //
// Tasks: Display Semantic-UI specified Divider                      //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import { Grid, Divider, Icon, Header } from "semantic-ui-react";

class CustomDivider extends Component {
  state = {};

  render() {
    return (
      <Grid.Row>
        <Grid.Column>
          <Divider horizontal>
            <Header as={this.props.h_size}>
              <Icon name={this.props.icon_name} />
              {this.props.title}
            </Header>
          </Divider>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default CustomDivider;
