import React, { Component } from "react";
import { Message, Segment, Header } from "semantic-ui-react";
import "./index.css";




class ClinicalInfo extends Component {
 

  render() {

    return (
      <>        
        <Message
          className="formulation-rationale__health-goal-call-summary__header"
          style={{ marginBottom: "0", marginTop: "10px" }}
        >
          <Header as="h2" textAlign="center">
            Clinical Information
          </Header>
        </Message>
        <Segment
          style={{ marginTop: "0", marginBottom: "0" }}
          className={"formulation-rationale__health-goal-call-summary__content"}
        >
            <table>
                       
                <tbody>
                  <tr>
                    <td><b>Organism of Interest or Concern</b></td>
                    <td><b>Pertinent Clinical Information (Diagnosis, Symptoms, Family History)</b></td>
                    <td><b>Additional Notes</b></td>
                  </tr>
                  <tr>
                    <td>{this.props.clinicalInfo.organism_interest_concern !== null?this.props.clinicalInfo.organism_interest_concern:""}</td>
                    <td>{this.props.clinicalInfo.pertinent_clinical_info !== null?this.props.clinicalInfo.pertinent_clinical_info:""}</td>
                    <td>{this.props.clinicalInfo.additional_notes !== null?this.props.clinicalInfo.additional_notes:""}</td>
                  </tr>               
                </tbody>

            </table>
        </Segment>

       
      </>
    );
  }

}

export default ClinicalInfo;
