import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../../configuration/constants";
import { onGridReady, getUserRole, simplifyModulePermissionData, getHeaders } from "../../helper";
import CustomLoader from "../../custom_elements/CustomLoader";
import Config from "../../../configuration/config";
import "./flore.css";
import { AgGridReact } from "ag-grid-react";
import { Header, Message, Grid, Segment, Icon, Button } from "semantic-ui-react";
import { postRequest } from '../../axios';
import { toast } from "react-toastify";

let api, column_api;

class CreateAiDashBoard extends Component {
    state = {
        is_page_loading: true,
        showstore: true,
        showProps: false,
        samples: [],
        samplesInventory: [],
        inventoryTags: [],
        checkbox1: true,
        checkbox2: true,
        checkbox3: true,
        checkbox4: true,
        disabledPermission: "",
        floreAiDashboardPermission: 0,
        floreDashboard: 0,
        rowSelection: "multiple",
        editType: "fullRow",
        formula_id: [],
        is_btn_disabled: true,
        columnDefs: [
            {
                headerName: "",
                field: "",
                checkboxSelection: true,
                width: 50,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
            },
            {
                headerName: "Sample ID",
                field: "sample_id",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
            },
            {
                headerName: "Formula ID",
                field: "formula_id",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
            },
            {
                headerName: "Product Type",
                field: "product_type",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
            },
            {
                headerName: "Type",
                field: "type",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
            },
            {
                headerName: "Date Made",
                field: "date_made",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
            },
            {
                headerName: "Is Completed?",
                field: "is_completed",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
            },
        ],
        columnDefsInventory: [
            {
                headerName: "Ingredients",
                field: "ingredient_name",
                filter: "agTextColumnFilter",
                sortable: true,
                width: 260
            },
            {
                headerName: "Ingredient Successful",
                field: "ingredient_wise_success_inventory",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 260
            },
            {
                headerName: "Total Time Ingredient Used",
                field: "ingredient_wise_inventory",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 260
            },
            {
                headerName: "Ingredient Wise Success Rate",
                field: "ingredient_wise_success_rate",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 260,
                cellRenderer: (params) => {
                    if (params.value === 0) {
                        return (
                            `
                            <div>
                            ${params.value}%
                            </div>
                            `
                        )
                    }
                    else {
                        return (
                            `
                            <div>
                            ${parseFloat(params.value).toFixed(2)} %
                            </div>
                            `
                        )
                    }
                }
            },

        ],
        columnDefsInventoryTag: [
            {
                headerName: "Tags",
                field: "tag_name",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 200
            },

            {
                headerName: "Ingredient Successful",
                field: "ingredient_wise_success_total_count",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 160
            },
            {
                headerName: "Total Time Ingredient Used",
                field: "ingredient_wise_total_count",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 180
            },
            {
                headerName: "Ingredient Wise Success Rate",
                field: "ingredient_wise_success_rate",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 210,
                cellRenderer: (params) => {
                    if (params.value === 0) {
                        return (
                            `
                            <div>
                            ${params.value}%
                            </div>
                            `
                        )
                    }
                    else {
                        return (
                            `
                            <div>
                            ${parseFloat(params.value).toFixed(2)} %
                            </div>
                            `
                        )
                    }
                }
            },
            {
                headerName: "Overall Successful",
                field: "success_total_count",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 140
            },
            {
                headerName: "Overall Used",
                field: "total_count",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 110
            },
            {
                headerName: "Overall Success Rate",
                field: "total_success_rate",
                sizeColumnToFit: true,
                filter: "agTextColumnFilter",
                sortable: true,
                width: 160,
                cellRenderer: (params) => {
                    if (params.value === 0) {
                        return (
                            `
                            <div>
                            ${params.value}%
                            </div>
                            `
                        )
                    }
                    else {
                        return (
                            `
                            <div>
                            ${parseFloat(params.value).toFixed(2)} %
                            </div>
                            `
                        )
                    }

                }
            },

        ],
    };

    componentDidMount = () => {
        document.title = Config.ai.title.floreAiDashboard_display;
        this.getPro();
        this.getUserPermission()
    };
    getUserPermission = () => {

        let Role = getUserRole();
        let login = localStorage.getItem('login_success');
        if (login == 1) {
            axios
                .get(baseURL + "/users/PermissionDetails", getHeaders())
                .then(response => {
                    let permissionData = null;

                    permissionData = simplifyModulePermissionData(response.data);
                    permissionData = permissionData.filter(data => {
                        if (data.role_name === Role) {
                            return data;
                        }
                    })

                    let floreDashboard = 0;
                    let disabledPermission;
                    let floreAiDashboardPermission = permissionData.forEach(result => {
                        if (result.module_name === "Flore AI Dashboard" && result.edit_permission === 1) {
                            floreDashboard = 1;
                            disabledPermission = false;
                        }
                    })
                    if (floreDashboard == 0) {
                        floreAiDashboardPermission = 0;
                        disabledPermission = true;
                    }
                    else {

                        floreAiDashboardPermission = 1;
                    }
                    this.setState({
                        floreAiDashboardPermission,
                        floreDashboard,
                        disabledPermission,
                    });
                })
                .catch(error => {
                    alert("Fetching Display Grid | Error: ", error);
                });
        }
    }
    handleCheckboxClick = (e) => {
        let isClicked = e.target.checked;
        this.setState({ [e.target.name]: isClicked }, () => {
            this.successArrayMaker()
        });

    };
    getPro = () => {
        const data = {
            ids: [1, 2, 3, 4],
        };
        axios
            .post(baseURL + "/flore_ai/success-formulations", data, getHeaders())
            .then((res) => {
                this.setState({
                    is_page_loading: false
                })
                let data = res.data;
                return data;
            })
            .then((samples) => {
                if (samples.length > 0) {
                    this.setState({
                        samples: samples.reverse(),
                    });
                }
            });
    };


    successArrayMaker = () => {
        this.setState({
            is_page_loading: true
        })
        let successMeasure = [];

        if (this.state.checkbox1) {
            successMeasure.push(1);
        }
        if (this.state.checkbox2) {
            successMeasure.push(2);
        }
        if (this.state.checkbox3) {
            successMeasure.push(3);
        }
        if (this.state.checkbox4) {
            successMeasure.push(4);
        }
        const data = { ids: successMeasure };
        if (successMeasure.length === 0) {
            axios
                .post(baseURL + "/flore_ai/success-formulations", data, getHeaders())
                .then((res) => {
                    this.setState({
                        is_page_loading: false,
                        samples: [],
                        inventoryTags: [],
                        samplesInventory: []
                    })
                })
                .catch(error => {
                    this.setState({
                        is_page_loading: false
                    })
                });

        }
        else {
            axios
                .post(baseURL + "/flore_ai/success-formulations", data, getHeaders())
                .then((res) => {
                    this.setState({
                        is_page_loading: false
                    })
                    let data = res.data;
                    return data;
                })
                .then((samples) => {
                    if (samples.length > 0) {
                        this.setState({
                            samples: samples.reverse(),
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        is_page_loading: false
                    })
                });
        }
    };

    tableData = (value) => {
        this.setState({ is_page_loading: true });
        axios
            .get(baseURL + "/flore_ai/success-inventory/" + value.id, getHeaders())
            .then((res) => {

                this.setState({ is_page_loading: false })
                let data = res.data;
                return data;

            })
            .then((samples) => {
                if (samples.length > 0) {
                    this.setState({ samplesInventory: samples, inventoryTags: [] });
                }
            });
    }

    tableDataTags = (value) => {
        this.setState({
            is_page_loading: true
        })
        axios
            .get(baseURL + "/flore_ai/success-inventory-tags/" + value.organism_id, getHeaders())
            .then((res) => {
                this.setState({
                    is_page_loading: false
                })
                let data = res.data;
                return data;

            })
            .then((samples) => {
                if (samples.length > 0) {
                    this.setState({
                        inventoryTags: samples,
                    });
                }
            });
    }
    onDeleteClick = async (e) => {

        this.setState({ is_page_loading: true });
        postRequest('/flore_ai/delete', { formula_ids: this.state.formula_id })
            .then((data) => {
                if (data.data.success) toast.success(data.data.message);
                window.location.reload(false);
            })
            .catch((err) => {
                this.setState({ is_page_loading: false });
                toast.error("Something Goes worng !");
            })
    }

    onRowSelected = (value) => {
        var formula_id = this.state.formula_id;

        if ((formula_id.indexOf(value.data.id) !== -1)) {
            formula_id = formula_id.filter((id) => id !== value.data.id);
        }
        else {
            formula_id.push(value.data.id)
        }

        this.setState({
            formula_id,
        });

    }

    render() {
        return (
            <React.Fragment>
                <br />
                <Message>
                    <Header as="h2" textAlign="center">
                        {Config.ai.title.floreAiDashboard}
                    </Header>
                </Message>
                {this.state.is_page_loading ? <CustomLoader /> : ""}

                <Segment className="transparent">
                    <Grid.Row>

                        <Grid.Column
                            style={{
                                visibility: this.state.showstore
                                    ? "visible"
                                    : "hidden",
                            }}>
                            <div className="data-filter-flex d-flex">
                                <form>
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            this.handleCheckboxClick(e)
                                        }
                                        name="checkbox1"
                                        checked={this.state.checkbox1}
                                        id="Improved Microbiome Score"
                                        // name="Improved Microbiome Score"
                                        value="Improved Microbiome Score"
                                        disabled={this.state.disabledPermission}
                                    />

                                    <label className="microbe">
                                        Improved Microbiome Score
                                    </label>

                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            this.handleCheckboxClick(e)
                                        }
                                        id="Positive Customer Feedbacks"
                                        // name="Positive Customer Feedbacks"
                                        value="Positive Customer Feedbackse"
                                        checked={this.state.checkbox2}
                                        name="checkbox2"
                                        disabled={this.state.disabledPermission}
                                        style={{ marginLeft: "5px" }}>
                                    </input>

                                    <label className="microbe">
                                        Positive Customer Feedbacks
                                    </label>

                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            this.handleCheckboxClick(e)
                                        }
                                        // name="Increase in Abundance of Probiotics"
                                        value="Increase in Abundance of Probiotics"
                                        name="checkbox3"
                                        checked={this.state.checkbox3}
                                        disabled={this.state.disabledPermission}
                                        style={{ marginLeft: "5px" }}>
                                    </input>

                                    <label className="microbe">
                                        Increase in Abundance of Probiotics
                                    </label>

                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            this.handleCheckboxClick(e)
                                        }
                                        // name="Increase in Abundance of Probiotics"
                                        value="Increase in Abundance of Probiotics"
                                        name="checkbox4"
                                        checked={this.state.checkbox4}
                                        disabled={this.state.disabledPermission}
                                        style={{ marginLeft: "5px" }}>
                                    </input>

                                    <label className="microbe">
                                        Increase in Bad Microbes Score
                                    </label>
                                </form>

                                <Button
                                    basic
                                    negative
                                    onClick={this.onDeleteClick}
                                    icon
                                    labelPosition="right"
                                    disabled={(this.state.formula_id.length === 0)}
                                >
                                    Delete<Icon name="trash" />
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row style={{ paddingTop: "2%" }}>
                        <Grid.Column>
                            <Segment
                                compact
                                raised
                                padded
                                style={{ display: "block", marginTop: "0" }}>
                                <Grid columns="equal">
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div
                                                id="myGrid"
                                                className="ag-theme-balham"
                                                ag-grid="gridOptions"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                }}>
                                                <AgGridReact
                                                    rowHeight="30"
                                                    rowSelection={this.state.rowSelection}
                                                    editType={this.state.editType}
                                                    onRowClicked={e => {
                                                        let sample = e.data;
                                                        this.tableData(sample)
                                                    }}
                                                    onGridReady={(params) => {
                                                        api = params.api;
                                                        api.showLoadingOverlay();
                                                        api.sizeColumnsToFit();
                                                        column_api =
                                                            params.column_api;
                                                        onGridReady(
                                                            params,
                                                            api,
                                                            column_api,
                                                            this.props
                                                                .initial_sort,
                                                        );
                                                    }}
                                                    animateRows={true}
                                                    pagination={true}
                                                    onRowSelected={this.onRowSelected}
                                                    paginationPageSize={10}
                                                    paginationNumberFormatter={(
                                                        params,
                                                    ) => {
                                                        return (
                                                            "[" +
                                                            params.value.toLocaleString() +
                                                            "]"
                                                        );
                                                    }}
                                                    columnDefs={
                                                        this.state.columnDefs
                                                    }
                                                    floatingFilter={true}
                                                    rowData={this.state.samples}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>

                    {this.state.is_page_loading ? <CustomLoader /> : ""}
                    <Grid.Row style={{ paddingTop: "2%" }}>
                        <Grid.Column>
                            <Segment
                                compact
                                raised
                                padded
                                style={{ display: "block", marginTop: "0" }}>
                                <Grid columns="equal">
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div
                                                id="myGrid"
                                                className="ag-theme-balham"
                                                ag-grid="gridOptions"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                }}>
                                                <AgGridReact
                                                    rowHeight="30"
                                                    rowSelection={this.state.rowSelection}
                                                    editType={this.state.editType}
                                                    onRowClicked={e => {
                                                        let sample = e.data;
                                                        this.tableDataTags(sample)
                                                    }}
                                                    onGridReady={(params) => {
                                                        api = params.api;
                                                        api.showLoadingOverlay();
                                                        // api.sizeColumnsToFit();
                                                        column_api =
                                                            params.column_api;
                                                        onGridReady(
                                                            params,
                                                            api,
                                                            column_api,
                                                            this.props
                                                                .initial_sort,
                                                        );
                                                    }}
                                                    animateRows={true}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    paginationNumberFormatter={(
                                                        params,
                                                    ) => {
                                                        return (
                                                            "[" +
                                                            params.value.toLocaleString() +
                                                            "]"
                                                        );
                                                    }}
                                                    columnDefs={
                                                        this.state.columnDefsInventory
                                                    }
                                                    floatingFilter={true}
                                                    rowData={this.state.samplesInventory}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>


                    {this.state.is_page_loading ? <CustomLoader /> : ""}
                    <Grid.Row style={{ paddingTop: "2%" }}>
                        <Grid.Column>
                            <Segment
                                compact
                                raised
                                padded
                                style={{ display: "block", marginTop: "0" }}>
                                <Grid columns="equal">
                                    <Grid.Row>
                                        <Grid.Column>
                                            <div
                                                id="myGrid"
                                                className="ag-theme-balham"
                                                ag-grid="gridOptions"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                }}>
                                                <AgGridReact
                                                    rowHeight="30"
                                                    rowSelection="single"
                                                    onGridReady={(params) => {
                                                        api = params.api;
                                                        api.showLoadingOverlay();
                                                        // api.sizeColumnsToFit();
                                                        column_api =
                                                            params.column_api;
                                                        onGridReady(
                                                            params,
                                                            api,
                                                            column_api,
                                                            this.props
                                                                .initial_sort,
                                                        );
                                                    }}
                                                    animateRows={true}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    paginationNumberFormatter={(
                                                        params,
                                                    ) => {
                                                        return (
                                                            "[" +
                                                            params.value.toLocaleString() +
                                                            "]"
                                                        );
                                                    }}
                                                    columnDefs={
                                                        this.state.columnDefsInventoryTag
                                                    }
                                                    floatingFilter={true}
                                                    rowData={this.state.inventoryTags}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>


                </Segment>
            </React.Fragment>
        );
    }
}

export default CreateAiDashBoard;
