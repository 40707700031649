import React, { Component } from "react";
import { Message, Segment, Header, Input, TextArea } from "semantic-ui-react";
import axios from "axios";

class ReformulationNotes extends Component {
  render() {

    return (
      <>
        <Message
          className="formulation-rationale__health-survey__header"
          style={{ marginBottom: "0" }}
        >
          <Header as="h2" textAlign="center">
            Reformulation Notes
          </Header>
        </Message>
        <Segment
          style={{ marginTop: "0", marginBottom: "0" }}
          className={
            (this.props.component == "reformulation")
              ? "formulation-rationale__health-survey" :
              "formulation-rationale__health-survey__content"
          }
        >
          <div className="rn-pos">
            <TextArea type="text" onChange={this.props.reformulation_notes} placeholder="Write something" />


          </div>

        </Segment>
      </>
    );
  }
}
export default ReformulationNotes