
import Organism from "./organism";
import CustomDivider from "../../custom_elements/CustomDivider";
import React, { Component } from "react";
import {
  Grid,
  Button,
  Popup,
  Icon
} from "semantic-ui-react";

class Organisms extends Component {
  state = {
    organismArray: []
  };

  deleteUp = (index, value) => {
    this.props.deleteUpfromOrganism(index, value)
  }
  render() {

    return (
      <Grid columns="equal">
        <CustomDivider
          h_size="h4"
          title="Contained Ingredients"
          icon_name="dna"
        />
        <Grid.Row>
          <Grid.Column>
            {this.props.organisms.map(organism => (
              <Organism
                is_disabled={true}
                organism={organism}
                key={organism.organism_barcode}
                onDeleteOrganismClick={this.props.onDeleteOrganismClick}
                onChangeDataUp={this.props.onChangeDataUp}
                onSelectAfterInsert={this.props.onSelectAfterInsert}
                onRemoveAfterInsert={this.props.onRemoveAfterInsert}
              />
            ))}
            <Button style={{
              cursor: "pointer",
              marginBottom: "1%",
              position: "absolute",
              marginLeft: "65rem",
              display: !this.props.formualtion_ai ? "block" : "none"
            }}
              onClick={this.props.predict_dosage}>
              Predict Dosage
            </Button>

            <br />
            <CustomDivider
              h_size="h4"
              title="New Ingredient"
              icon_name="add circle"
            />
            <Organism
              predict_dosage={this.props.predict_dosage}
              key={13}
              is_disabled={false}
              disabledPermission={this.props.disabledPermission}
              inventory_options={this.props.inventory_options}
              selectedOrganism={this.props.selectedOrganism}
              final_amount_added={this.props.final_amount_added}
              total_cfu={this.props.total_cfu}
              organism_barcode={this.props.organism_barcode}
              inventory_id={this.props.inventory_id}
              multiSelectData={this.props.multiSelectData}
              amount_added={this.props.amount_added}
              tags={this.props.tags}
              onSelect={this.props.onSelect}
              multiselectRef={this.props.multiselectRef}
              concentration={this.props.concentration}
              final_concentration={this.props.final_concentration}
              onChange={this.props.onChange}
              scrap_amount={this.props.scrap_amount}
              changeStructureFunction={this.props.changeStructureFunction}
              onSelectAfterInsert={this.props.onSelectAfterInsert}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column style={{ textAlign: "center" }}>
            <Popup
              trigger={
                <Button
                  animated="vertical"
                  className="small"
                  onClick={this.props.onAddOrganismClick}
                  color="blue"
                  disabled={this.props.disabledPermission}
                >
                  <Button.Content visible>
                    <Icon name="plus circle" />
                    Insert
                  </Button.Content>
                  <Button.Content hidden>
                    More&nbsp;
                    <Icon name="arrow right" />
                  </Button.Content>
                </Button>
              }
              content="Add more Organism(s)."
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Organisms;
