import React, { Component } from 'react'
import axios from "axios";
import { baseURL } from "./../../configuration/constants";
import { adult_info, baby_info, getHeaders,kids_info,prebiotic_info, tots_info } from "./../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment';

class Summary extends Component {
    state = {formula_info:{}, performed_by_pin:"",confirmed_by_pin:"",performed_by:"",performed_by_name:"",confirmed_by:"",confirmed_by_name:"",performed_date:"",performed_time:"",wfi:[],wmfi:"",fpc:"",pmfi:"",packaging_info:[],flourish_capsule:true,is_probiotic:false,is_prime:false,number_ingredients:0,ingredient_list:"",prime_text:"",intentional_overage:"",vitiman_c:"",traget_weight:0}

    componentDidUpdate(prevProps) {             
        if (this.props.formula_id && (this.props.formula_id !== prevProps.formula_id)) {
          console.log('Trigger mount prop changed');
          this.componentDidMount();
          // You can perform actions here when triggerMount prop changes
        }
      }
  
    componentDidMount(){      
          
        console.log(this.props)  
        if(this.props.selected_row_value.is_completed === 1){
            this.getAllStep('');
        }     
        
        if(this.props.pin){
            this.getPerformUser(this.props.pin,'performer');
        }
        this.setState({performed_date: moment().format('MM/DD/YYYY')});
        this.setState({performed_time: moment().format('h:mm A')});
        let number_ingredients = 0 ;
        this.setState({formula_info:this.props.selected_row_value});
        let intentional_overage =  ((this.props.selected_row_value.total_final_amount_added *10)/100).toFixed(2)
        this.setState({intentional_overage:intentional_overage})
        if(this.props.selected_row_value.flourish_capsule_size === "powder"){
            this.setState({flourish_capsule:false})
        }else{
            //number_ingredients++; 
        }        
        if(this.props.selected_row_value.is_prebiotic === 'No'){
            this.setState({is_probiotic:true})
        }
        if(this.props.selected_row_value.is_prime === 'Yes'){
            this.setState({is_prime:true})
            this.setState({prime_text:"(Prime)"})
        }
        number_ingredients = number_ingredients + this.props.selected_row_value.organisms.length;        
        this.setState({number_ingredients: number_ingredients})
        if (this.props.selected_row_value.is_prebiotic === 'Yes') {
            this.state.is_prebiotic = true;
            prebiotic_info[0].quantity = this.props.selected_row_value.number_of_months;
            prebiotic_info[1].quantity = this.props.selected_row_value.number_of_months * 30;
            this.setState({ packaging_info: prebiotic_info });
        } else {
            this.state.is_probiotic = true;
            switch (this.props.selected_row_value.product_type) {
                case "monthly_flore":
                    adult_info[1].quantity = adult_info[3].quantity = this.props.selected_row_value.number_of_months;
                    this.setState({ packaging_info: adult_info });
                    break;
                case "monthly_tots":
                    tots_info[1].quantity = tots_info[3].quantity = this.props.selected_row_value.number_of_months;
                    this.setState({ packaging_info: tots_info });
                    break;
                case "monthly_flore_baby":
                    baby_info[1].quantity = baby_info[3].quantity = this.props.selected_row_value.number_of_months;
                    this.setState({ packaging_info: baby_info });
                    break;
                case "monthly_flore_kids":
                    kids_info[1].quantity = kids_info[3].quantity = this.props.selected_row_value.number_of_months;
                    this.setState({ packaging_info: kids_info });
                    break;
                default:
                    if (this.props.selected_row_value.age <= 12) {
                        kids_info[1].quantity = kids_info[3].quantity = this.props.selected_row_value.number_of_months;
                        this.setState({ packaging_info: kids_info });
                    } else {
                        adult_info[1].quantity = adult_info[3].quantity = this.props.selected_row_value.number_of_months;
                        this.setState({ packaging_info: adult_info });

                    }
                    break;
            }

        }
        this.labelGeneration();
    }

    getAllStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name : step_name
        }
        axios.post(baseURL+"/get-all-step",req, getHeaders())
          .then(res=>{
            let result = res.data;
            let all_step = result.all_step
            console.log(result);
            if(result.success === true && all_step.length > 0){
                let step ="";
                //let mmr_approval = all_step.filter(step=>step.step_name==="mmr_approval")[0];
                this.getPerformUser(this.props.pin,'performer');
                this.setState({performed_date: moment().format('MM/DD/YYYY')});
                this.setState({performed_time: moment().format('h:mm A')});

                step = all_step.filter(step=>step.step_name==="weighing_of_flourish_ingredients")[0];
                this.setState({wfi:JSON.parse(step.step_json).organisms});
                
                step = all_step.filter(step=>step.step_name==="weight_of_mixed_flourish_ingredients")[0];
                this.setState({wmfi:JSON.parse(step.step_json)});

                step = all_step.filter(step=>step.step_name==="flourish_pill_count")[0];
                if(step){
                    this.setState({fpc:JSON.parse(step.step_json)});
                }
                

                step = all_step.filter(step=>step.step_name==="pilling_of_mixed_flourish_ingredient")[0];
                if(step){
                    this.setState({pmfi:JSON.parse(step.step_json)});
                }

                step = all_step.filter(step=>step.step_name==="processing")[0];
                this.setState({packaging_info:JSON.parse(step.step_json).packaging_info});

                //console.log(wfi);
            } 
        })
    } 

    labelGeneration=()=>{
        let req = {
            sample_id : this.props.selected_row_value.sample_id,
            formulation_id:this.props.selected_row_value.id
          }
          axios.post(baseURL+"/label-generation",req, getHeaders())
            .then(res=>{
              let result = res.data;   
              if(result.success === true && result.label !== null){
                let label = JSON.parse(result.label);                 
                if(label[0]){
                    let ingredient_list ="";
                    let ingredient = label[0].data.pro_names;
                    if(label[0].data.pre_names.length > 0){
                        ingredient.concat(label[0].data.pre_names);
                    }
                    ingredient_list = ingredient.join(',');                                        
                    if(this.state.flourish_capsule){
                        
                        ingredient_list = ingredient_list.concat(",Vegan Capsule (HPMC and Water)");
                    }
                    this.setState({ingredient_list : ingredient_list});
                    let calculation = label[0].data.calculation;
                    let vitiman_c = calculation.filter(cal=>cal.supplement==="Vitamin C");
                    this.setState({vitiman_c : vitiman_c.total_value});
                    
                }                
                
              }
              if(this.props.selected_row_value.is_completed !== 1){
                this.setState({wfi:this.props.selected_row_value.organisms});
              }

                var total_final_amount = 0;
                this.props.selected_row_value.organisms.map((org)=>{
                    total_final_amount = total_final_amount+org.final_amount_added;
                })
                this.setState({traget_weight:total_final_amount});
                
              
              
          })

    }

    getPerformUser = (pin,user_type)=>{
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }   
          axios.post(baseURL+"/users/getUserByPin",{pin:pin}, getHeaders())
          .then(res=>{               
            if(res.data.success === true && res.data.user !== null){
                let user = res.data.user;
                if(user_type === 'performer'){                   
                    this.setState({performed_by:user.id,performed_by_name:user.full_name});
                    
                }else{
                    this.setState({confirmed_by:user.id,confirmed_by_name:user.full_name});
                }
                 
              //this.setState({user_full_name:user.full_name}); 
            }
        })
    }
    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('review_finished_product_specifications');        
    };
    render() {
        console.log(this.state);
        return (
            <>
                <div className='cs-box'>
                    <div className='cs-body'>
                        <h3 className='text-center'>MMR {this.state.formula_info.formula_id}</h3>
                        <h2 className='text-center m-0'>Summary</h2>
                        <div className='text-center'>
                            <p>Work Order: {this.state.formula_info.sample_id}<br />
                                Formula ID: {this.state.formula_info.formula_id}<br />
                                Product Name: {this.state.formula_info.display_product_type}<br />
                                Type: {this.state.formula_info.type}</p>
                            <p>MMR Approval Information:<br />
                                Approved by {this.state.performed_by_name} {this.state.performed_date}<br />
                                {this.state.performed_time}</p>
                        </div>
                        <div className='mt-4 summary-table'>
                            <table className='w-100'>
                                <tr>
                                    <td style={{ verticalAlign:"top" }}>
                                        <table className='w-100'>
                                            <tr>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Total Final Concentration:</h4>
                                                        <p>{this.state.formula_info.total_final_concentration} BCFU/g +/-</p>
                                                    </div>
                                                </td>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Final Amount Added:</h4>
                                                        <p>{this.state.formula_info.total_final_amount_added}g +/- 2%</p>
                                                    </div>
                                                </td>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <h4 className='m-0 text-center mb-1'>Environmental Conditions:</h4>
                                                    <p>18C-25C<br />
                                                        30% -50% RH</p>
                                                </td>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Number of Months:</h4>
                                                        <p>{this.state.formula_info.number_of_months}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Prime Added:</h4>
                                                        <p>{this.state.formula_info.is_prime}</p>
                                                    </div>
                                                </td>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <h4 className='m-0 text-center mb-1'>Mixing Time:</h4>
                                                    <p>10 to 20 seconds</p>
                                                </td>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Number of Ingredients:</h4>
                                                        <p>{this.state.number_ingredients}</p>
                                                    </div>
                                                </td>
                                                <td className='text-center pb-2' style={{ width: "20%", verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Intentional Overage:</h4>
                                                        <p>{this.state.intentional_overage}g</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                {
                                                    this.state.is_probiotic && (
                                                        <td className='text-center' style={{ width: "20%",verticalAlign:"top" }}>
                                                            {
                                                                this.state.flourish_capsule &&(
                                                                    <>
                                                                    <h4 className='m-0 text-center mb-1'>Target Pill Weight:</h4>
                                                                    <p>460 mg +/- 13%</p>
                                                                    </>
                                                                )
                                                            }

            {
                                                                !this.state.flourish_capsule &&(
                                                                    <>
                                                                    <h4 className='m-0 text-center mb-1'>Target Sachet Weight:</h4>
                                                                    <p>30g +/- 13%</p>
                                                                    </>
                                                                )
                                                            }
                                                        
                                                        </td>
                                                    )
                                                }
                                                {
                                                    !this.state.is_probiotic && (
                                                        <td className='text-center' style={{ width: "20%",verticalAlign:"top" }}>
                                                            <h4 className='m-0 text-center mb-1'>Target Stick Pack Weight:</h4>
                                                            <p>8g +/- 13%</p>
                                                        </td>
                                                    )
                                                }

                                                <td className='text-center' style={{ width: "20%",verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Nutritional Claims:</h4>
                                                        {
                                                            this.state.vitiman_c &&(
                                                                <p>Vitamin C - {this.state.vitiman_c}</p>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </td>
                                                <td colSpan={3} style={{ verticalAlign:"top" }}>
                                                    <div className='green-bg'>
                                                        <h4 className='m-0 text-center mb-1'>Ingredient List:</h4>
                                                        <p>{this.state.ingredient_list}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td  style={{ width: "20%", verticalAlign: "top" }}>
                                        
                                    <div className='text-center mb-2'>
                                                    <h4 className='m-0 text-center mb-1'>
                                                        {
                                                            this.state.is_probiotic?!this.state.flourish_capsule?"Powder Weight":"Target Pills" :"Target Sachets"
                                                        }
                                                        :
                                                        
                                                    </h4>
                                                    <p>
                                                        <strong>
                                                        {
                                                            this.state.is_probiotic?!this.state.flourish_capsule?"93% to 100% of 90g":"93% to 100% of 100 Pills" :"93% to 100% of 100 Stick Packs"
                                                        }
                                                        
                                                        </strong>
                                                    </p>
                                                </div>
                                    {
                                            this.state.is_probiotic && (
                                            <div className='text-center '>
                                                <>
                                                {
                                                    !this.state.flourish_capsule  && (
                                                        <>
                                                            <h4 className='m-0 text-center mb-1'>Powder Per Package:</h4>
                                                            <p className='mb-2'><strong>30g +/- 0%</strong></p>
                                                        </>
                                                    )
                                                }
                                                </>
                                                <>
                                                {
                                                    this.state.flourish_capsule && (
                                                        <>
                                                        <div className='mb-2'>
                                                            <h4 className='m-0 text-center mb-1'>Pills Per Package:</h4>
                                                            <p><strong>30 +/- 0%</strong></p>
                                                        </div>
                                                        <div>
                                                        {this.state.is_prime &&(
                                                            <>
                                                                <h4 className='m-0 text-center mb-1'>Pills Per Package(Prime):</h4>
                                                                <p className='mb-0'><strong>23 +/- 0%</strong></p>   
                                                                <p><strong>7 +/- 0%</strong></p>   
                                                            </>
                                                        )}
                                                        </div>
                                                        </>
                                                    )
                                                }
                                                </>
                                            </div>
                                            )
                                        }
                                        {
                                            !this.state.is_probiotic && (
                                                <div className='text-center'>
                                                    <h4 className='m-0 text-center mb-1'>Stick Pack:</h4>
                                                     <p><strong>30 +/- 0%</strong></p>
                                                </div>
                                            )
                                        }
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0 text-center mb-1'>Statement of Intentional Overage:</h4></div>
                            <div><h5 className='m-0 text-center fw-500'>Sun Genomics has a 20% overage for all concentrations and %DV compared to the label claim. There is no intentional overage in regard to amount of material manufactured. There is a 3% scrap factor accounted for inventory purposes.</h5></div>
                        </div>
                        <div className='mt-5'>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td>Statement of theoretical yield expected at each point/step</td>
                                            <td className='text-start'>
                                                <div>
                                                    <p className='m-0'>Sun Genomics has 5 points at which yield should be recorded</p>
                                                    <ul className='cs-list order-count'>
                                                        <li>1. Weight of each ingredient prior to adding to shaker</li>
                                                        <li>2. Weight of combined ingredients post mixing</li>
                                                        <li>3. Count of total capsules made</li>
                                                        <li>4. QC weight of capsules</li>
                                                        <li>5. Count of capsule per sachet</li>
                                                    </ul>
                                                    <p className='m-0'>Yield is to be calculated against theoretical quantities</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4>Ingredient Weight</h4>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th className='text-center'>Ingredient Barcode</th>
                                            <th>Target Amount (g)</th>
                                            <th>Required Yield</th>
                                        </tr>
                                        {
                                            this.state.wfi && this.state.wfi.map((value)=>{
                                                return(
                                                    <tr>
                                                        <td>{value.ingredient_barcode?value.ingredient_barcode:value.organism_barcode}</td>
                                                        <td>{value.final_amount_added}</td>
                                                        <td>{value.required_yield?value.required_yield:"-"}</td>
                                                    </tr>
                                                )
                                            }) 
                                        }                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4>Combined Ingredient Weight {this.is_prebiotic && this.state.is_prime && !this.state.flourish_capsule?"(Flurish)":"" }</h4>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th className='text-center'>Target Weight of Mixed Ingredients (g)</th>
                                            <th>Yield Minimum</th>
                                            <th>Yield Maximum</th>
                                        </tr>
                                        <tr>
                                            <td>{this.state.traget_weight}</td>
                                            <td>98%</td>
                                            <td>102%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {
                            this.state.is_probiotic && (
                                <>
                                {
                                    !this.state.flourish_capsule && this.state.is_prime && (
                                    <div className='mt-5'>
                                        <h4>Combined Ingredient Weight (Prime)</h4>
                                        <div className='custom-table custom-header-table'>
                                            <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                <tbody>
                                                    <tr>
                                                        <th className='text-center'>Target Weight of <br/>Mixed Ingredients (g)</th>
                                                        <th>Yield Minimum</th>
                                                        <th>Yield <br/>Maximum</th>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>98%</td>
                                                        <td>102%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}

                                <div className='mt-5'>
                                    <h4>{this.state.flourish_capsule?"Total Capsule Count":"Total Powder Weight"} {this.state.is_prime?"(Flurish)":""}</h4>
                                    <div className='custom-table custom-header-table'>
                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                            <tbody> 
                                                <tr>
                                                    <th className='text-center'>{this.state.flourish_capsule?"Target Capsule Count":"Target Formulated Grams"}  </th>
                                                    <th>Yield Minimum</th>
                                                    <th>Yield Maximum</th>
                                                </tr>
                                                <tr>
                                                    <td>{(this.props.selected_row_value.number_of_months*30)+10} {!this.state.flourish_capsule && this.state.is_prime?"& 23":""}</td>
                                                    <td>93%</td>
                                                    <td>100%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    this.state.is_prime &&(
                                        <div className='mt-5'>
                                            <h4>{this.state.flourish_capsule?"Total Capsule Count(Prime)":"Total Powder Weight(Prime)"}</h4>
                                            <div className='custom-table custom-header-table'>
                                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                    <tbody> 
                                                        <tr>
                                                            <th className='text-center'>{this.state.flourish_capsule?"Target Capsule Count":"Target Formulated Grams"}  </th>
                                                            <th>Yield Minimum</th>
                                                            <th>Yield Maximum</th>
                                                        </tr>
                                                        <tr>
                                                            <td>7</td>
                                                            <td>93%</td>
                                                            <td>100%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    this.state.flourish_capsule &&(
                                    <>
                                        <div className='mt-5'>
                                            <h4>Final Pill Weight {this.state.is_prime?"(Flurish)":""}</h4>
                                            <div className='custom-table custom-header-table'>
                                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                    <tbody> 
                                                        <tr>
                                                            <th className='text-center'>Target Pill Weight (mg)</th>
                                                            <th>Yield Minimum</th>
                                                            <th>Yield Maximum</th>
                                                        </tr>
                                                        <tr>
                                                            <td>460</td>
                                                            <td>87%</td>
                                                            <td>113%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {
                                            this.state.is_prime &&(
                                                <div className='mt-5'>
                                                    <h4>Final Pill Weight(Prime)</h4>
                                                    <div className='custom-table custom-header-table'>
                                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                            <tbody> 
                                                                <tr>
                                                                    <th className='text-center'>Target Pill Weight (mg)</th>
                                                                    <th>Yield Minimum</th>
                                                                    <th>Yield Maximum</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>{this.state.flourish_capsule?460:5}</td>
                                                                    <td>87%</td>
                                                                    <td>113%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            )
                                        }
                                        <div className='mt-5'>
                                            <h4>Capsule per Sachet Count{this.state.is_prime?'(Flurish)':""}</h4>
                                            <div className='custom-table custom-header-table'>
                                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                    <tbody> 
                                                        <tr>
                                                            <th className='text-center'>Target Pills Per Sachet</th>
                                                            <th>Yield Minimum</th>
                                                            <th>Yield Maximum</th>
                                                        </tr>
                                                        <tr>
                                                            <td>{this.state.is_prime?'30 & 23':30}</td>
                                                            <td>100%</td>
                                                            <td>100%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {
                                            this.state.is_prime &&(
                                                <div className='mt-5'>
                                                    <h4>Capsule per Sachet Count(Prime)</h4>
                                                    <div className='custom-table custom-header-table'>
                                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                            <tbody> 
                                                                <tr>
                                                                    <th className='text-center'>Target Pills Per Sachet</th>
                                                                    <th>Yield Minimum</th>
                                                                    <th>Yield Maximum</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>7</td>
                                                                    <td>100%</td>
                                                                    <td>100%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            )
                                        }
                                    </>)
                                }
                                {
                                    !this.state.flourish_capsule &&(
                                    <>
                                        <div className='mt-5'>
                                            <h4> Sachet Final Weight{this.state.is_prime?'(Flurish)':""}</h4>
                                            <div className='custom-table custom-header-table'>
                                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                    <tbody> 
                                                        <tr>
                                                            <th className='text-center'>Target Gram Per Sachet</th>
                                                            <th>Yield Minimum</th>
                                                            <th>Yield Maximum</th>
                                                        </tr>
                                                        <tr>
                                                            <td>{this.state.is_prime?'30 & 23':30}</td>
                                                            <td>100%</td>
                                                            <td>100%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {
                                            this.state.is_prime &&(    
                                                <div className='mt-5'>
                                                    <h4>Sachet Final Weight(Prime)</h4>
                                                    <div className='custom-table custom-header-table'>
                                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                                            <tbody> 
                                                                <tr>
                                                                    <th className='text-center'>Target Gram Per Sachet</th>
                                                                    <th>Yield Minimum</th>
                                                                    <th>Yield Maximum</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>7</td>
                                                                    <td>100%</td>
                                                                    <td>100%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            )
                                        }
                                    </>
                                    )
                                }                                   
                                
                                </>
                            )
                        }
                        
                       
                        

                        <div className='mt-5'>
                            <div><h4 className='m-0 text-center mb-1'>Statement Of Deviation Requirements:</h4></div>
                            <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                        </div>
                        <div className='mt-5'>
                            <div><h4 className='m-0 text-center mb-1'>CAPA Requirements:</h4></div>
                            <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                        </div>
                        <div className='mt-5'>
                            <h4>Ingredient</h4>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody> 
                                        <tr>
                                            <th className='text-center'>Ingredient Barcode</th>
                                            <th>Target Amount (g)</th>
                                            <th>Scrap Amount (g)</th>
                                            <th>Required Yield</th>
                                        </tr>
                                        {
                                            this.state.wfi && this.state.wfi.map((value)=>{
                                                return(
                                                    <tr>
                                                        <td>{value.ingredient_barcode?value.ingredient_barcode:value.organism_barcode}</td>
                                                        <td>{value.final_amount_added}</td>
                                                        <td>{value.scrap_amount?value.scrap_amount:"-"}</td>
                                                        <td>{value.required_yield?value.required_yield:"-"}</td>
                                                    </tr>
                                                )
                                            }) 
                                        }
                                        <tr>
                                            <td>MAN_000031_XXX</td>
                                            <td>{this.state.pmfi.total_amount?this.state.pmfi.total_amount:100}</td>
                                            <td>0</td>
                                            <td>{this.state.fpc.yield?this.state.fpc.yield:0+"%"}</td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4>Packaging</h4>
                            <div className='custom-table custom-header-table'>
                                <table className='w-100' cellPadding={0} cellSpacing={0}>
                                    <tbody> 
                                        <tr>
                                            <th className='text-center'>Packaging Material</th>
                                            <th>Description</th>
                                            <th>Quantity</th>
                                        </tr>
                                        {
                                            this.state.packaging_info && this.state.packaging_info.map((value)=>{
                                                return(
                                                    <tr>
                                                        <td>{value.package_material}</td>
                                                        <td>{value.description}</td>
                                                        <td>{value.quantity}</td>
                                                    </tr>
                                                )
                                            }) 
                                        }                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-5 mb-4'>
                            <div><h4 className='m-0 text-center'>Admin Notes/Special Instructions:<br/></h4></div>
                            <div><h5 className='m-0 text-center fw-500'>{this.state.formula_info.admin_notes}</h5></div>
                        </div>
                    </div>

                    {/* <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                            <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                            <button type='button' className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                            <button type='button' className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                            <button type='button' className='ui icon secondary labeled button ps-1-5em ms-2'>Next</button>
                        </div>
                    </div> */}
                </div>
            </>
        )
    }
}
export default Summary;