//-------------------------------------------------------------------//
// Module: CustomNavButton                                           //
// Tasks: 1) Uses Route from React-Router to change path             //                                          //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React from "react";
import { Route } from "react-router-dom";
import { Label } from "semantic-ui-react";

const style = {
  border: 0
};

const CustomNavButton = props => (
  <Route
    render={({ history }) => (
      <React.Fragment>
        <Label
          basic
          as="a"
          content={props.text}
          icon={props.icon}
          color="black"
          style={style}
          onClick={() => {
            history.push(props.path);
          }}
        />
      </React.Fragment>
    )}
  />
);

export default CustomNavButton;
