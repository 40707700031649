//-------------------------------------------------------------------//
// Module: ColumnDefs                                                //
// SubModule: FormulationAdminColumnDefs                             //
// Tasks: 1) Returns an Array of Admin Column Defs                   //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

export function getFormulationsAdminColumndDefsArray(
  converted_user_options_json,
  all_user_ids,
  dateComparator,
  converted_inventory_options_json,
  all_inventory_ids
) {
  let is_chrome = sessionStorage.getItem("is_chrome") === true;

  return [
    {
      headerName: "id",
      field: "id",
      hide: true
    },
    {
      headerName: "inventory_id",
      field: "inventory_id",
      hide: true
    },
    {
      headerName: "organisms_id",
      field: "organisms_id",
      hide: true
    },
    {
      headerName: "Formulation Info",
      children: [
        {
          headerName: "Sample ID",
          field: "sample_id",
          filter: "agTextColumnFilter",
          pinned: "left",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          editable: true,
          width: 145
        },
        {
          headerName: "Formula ID",
          field: "formula_id",
          filter: "agTextColumnFilter",
          pinned: "left",
          editable: true,
          width: 130
        },
        {
          headerName: "Admin Notes",
          field: "admin_notes",
          editable: true,
          filter: "agTextColumnFilter",
          width: 150
        },
        {
          headerName: "Operator Notes",
          field: "operator_notes",
          editable: false,
          filter: "agTextColumnFilter",
          width: 150
        },
        {
          headerName: "Number of Months",
          field: "number_of_months",
          type: "numericColumn",
          editable: true,
          filter: "agNumberColumnFilter",
          width: 150
        },
        {
          headerName: "Formulation Factor",
          field: "formulation_factor",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 150
        }
      ]
    },
    {
      headerName: "Dates",
      children: [
        {
          headerName: "Date Made",
          field: "date_made",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: true,
          comparator: dateComparator,
          width: 110
        },
        {
          headerName: "Date Created",
          field: "date_created",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: true,
          comparator: dateComparator,
          width: 110
        }
      ]
    },
    {
      headerName: "Ingredient Barcode",
     // field: "inventory_id",
      field: "organism_barcode",
      filter: "agTextColumnFilter",
      editable: true,
      cellEditor: "agSelectCellEditor",
      width: 150,
      pinned: "right",
      cellEditorParams: {
        values: all_inventory_ids
      },
      refData: converted_inventory_options_json
    },
    {
      headerName: "Ingredients Info",
      children: [
        {
          headerName: "Amount Added",
          field: "amount_added",
          type: "numericColumn",
          editable: true,
          filter: "agNumberColumnFilter",
          width: 140
        },
        {
          headerName: "Total CFU",
          field: "total_cfu",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 130
        },
        {
          headerName: "Final Concentration",
          field: "final_concentration",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 150
        }
      ]
    },
    {
      headerName: "Total Info",
      children: [
        {
          headerName: "Total Final Concentration",
          field: "total_final_concentration",
          type: "numericColumn",
          editable: false,
          filter: "agNumberColumnFilter",
          width: 160
        }
      ]
    },
    {
      headerName: "Timestamp",
      children: [
        {
          headerName: "Created At",
          field: "created_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 150
        },
        {
          headerName: "Last Updated At",
          field: "updated_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 150
        },
        {
          headerName: "Deleted At",
          field: "deleted_at",
          filter: is_chrome ? "agDateColumnFilter" : false,
          editable: false,
          comparator: dateComparator,
          width: 150
        }
      ]
    },
    {
      headerName: "Is Completed?",
      field: "is_completed",
      filter: true,
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [0, 1]
      },
      refData: { 0: "No", 1: "Yes" },
      width: 135
    },
    {
      headerName: "Username",
      field: "user_id",
      filter: "agTextColumnFilter",
      editable: true,
      cellEditor: "agSelectCellEditor",
      width: 150,
      cellEditorParams: {
        values: all_user_ids
      },
      refData: converted_user_options_json
      // refData: { 0: "yash", 1: "jash", 2: "kash" }
    }
  ];
}
