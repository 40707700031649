

import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';

import { Segment, Grid, Button, Input, Popup, Form, Icon } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Modal from 'react-modal';
import ToggleButton from "@material-ui/lab/ToggleButton";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { onGridReady, getHeaders } from "../../helper";
import { portalURL, baseURL } from "../../../configuration/constants";
import FloreTagRenderer from "./FloreTagRenderer";
import { funciton_alert } from "./FloreTagRenderer";
import CustomLoader from "../../custom_elements/CustomLoader";
import "./flore.css";
import Config from '../../../configuration/config';

let api, column_api;
var scope;
const successModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -300px)',
        border: 'none',
        background: 'none',
        padding: '0'
    }
};

let formulation_id = [];
let inventory_id = [];
let organism_id = [];

Modal.setAppElement('#container');
class FloreAiIngredientTable extends Component {

    constructor(props) {
        super(props);

        scope = this;
        this.state = {
            flag_value: "",
            select_deselect_flag: false,
            disabledMaster: true,
            disabledRemovedBtn: true,
            selectedTags: [],
            isSubmitActive: true,
            formulation_id: "",
            inventory_id: "",
            tag_name: "",
            organism_id: "",
            tempArray: [],
            IdArray: [],
            report_tag: [],
            tag_ids: [],
            samples: [],
            select_deselect_all: false,
            openModal: false,
            defaultColDef: {
                resizable: true,
                sortable: true,
                enableCellChangeFlash: true,
                autoHeight: true
            },
            tags_length: 0,
            selected_tags_length: 0,
            columnDefs: [
                {
                    headerName: "",
                    field: "",
                    checkboxSelection: true,
                    width: 30,
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                },

                {
                    headerName: "Ingredient  Barcode",
                    field: "organism_barcode",
                    filter: "agTextColumnFilter",
                    width: 140,
                },
                {
                    headerName: "Internal names",
                    field: "internal_name",
                    filter: "agTextColumnFilter",
                    width: 150
                },
                {
                    headerName: "Final Amount Added",
                    field: "final_amount_added",
                    width: 140,
                    filter: "agTextColumnFilter"
                },

                {
                    headerName: "Tags",
                    field: "inventory_tags",
                    width: 600,
                    cellRendererFramework: (params) => {
                        return <FloreTagRenderer
                            data={params}
                            on_select_deselect={this.check_value}
                            remove={this.onRemove}
                            collect_changes={this.props.collect_changes}
                            selected_ids={this.props.selected_ids}
                        />
                    },
                }
            ],

        };
    }
    closeModal = () => {
        this.setState({
            openModal: false,
            tempArray: [],
            IdArray: [],
            report_tag: [],
            isSubmitActive: true
        })
    }

    handleChange = (e) => {
        if (e.target.value === "") {
            this.setState({
                report_tag: [],
                IdArray: []
            })
        }

        this.setState({
            tag_name: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            tag_name: this.state.tag_name,
            token: Config.token
        }
        axios
            .post(portalURL + "/getMasterTagsAll", data)
            .then(res => {
                let response = res.data.data;
                this.setState({
                    report_tag: response
                })
            })
    }

    submitTags = () => {
        let data = {
            tags: this.state.tempArray,
            formulation_id: formulation_id,
            inventory_id: inventory_id,
            organism_id: organism_id
        }

        axios
            .post(baseURL + "/link-tags", data, getHeaders())
            .then(res => {
                if (res.data.is_exist == 0) {
                    toast.info("Tags already exists");
                    return;
                }
                else {
                    var tags = [];
                    res.data.inserted_tags_data.map((item) => {
                        tags.push(`${item.inserted_tag_id} ${item.inventory_id}`)
                    })

                    this.props.collect_changes({
                        action: "tag_inserted",
                        data: tags,
                        message: "<tags> will be removed"
                    });
                    toast.success("Tags Inserted Successfully.");
                    this.props.event()
                    this.setState({
                        openModal: false,
                        tempArray: [],
                        report_tag: [],
                        IdArray: [],
                        isSubmitActive: true,
                        disabledMaster: true
                    })
                    organism_id = [];
                    inventory_id = [];
                    formulation_id = [];
                }

            })
            .catch(error => {
                alert("Updating from Display Grid | Error: ", error);
            });

    }

    selectSearchTag = (value) => {
        let cloneTempArray = this.state.tempArray;
        let cloneIdArray = this.state.IdArray;
        if (cloneIdArray.includes(value.ReportTag.id)) {
            let findIndex = cloneIdArray.findIndex((item) => item === value.ReportTag.id)
            cloneIdArray.splice(findIndex, 1)
            cloneTempArray.splice(findIndex, 1)

            this.setState({
                IdArray: cloneIdArray,
                tempArray: cloneTempArray,
            })
        }
        else if (!cloneIdArray.includes(value.ReportTag.id)) {
            cloneIdArray.push(value.ReportTag.id);

            cloneTempArray.push({ tag_id: value.ReportTag.id, tag_name: value.ReportTag.tags });

            this.setState({
                IdArray: cloneIdArray,
                tempArray: cloneTempArray
            })
        }

        if (this.state.tempArray.length !== 0) {
            this.setState({ isSubmitActive: false });
        } else {
            this.setState({ isSubmitActive: true });
        }
    }

    onRowSelected = (value) => {
        console.log(value);
        // this.setState({ select_deselect: false });
        if (value.node.selected == false) {
            organism_id.filter((elem, index) => {
                var index = organism_id.indexOf(value.data.id);
                if (index > -1) {
                    organism_id.splice(index, 1);
                }
            })

            inventory_id.filter((elem, index) => {
                var index = inventory_id.indexOf(value.data.inventory_id);
                if (index > -1) {
                    inventory_id.splice(index, 1);
                }
            })

            formulation_id.filter((elem, index) => {
                var index = formulation_id.indexOf(value.data.formulation_id);
                if (index > -1) {
                    formulation_id.splice(index, 1);
                }
            })
        }
        else {
            formulation_id.push(value.data.formulation_id);
            inventory_id.push(value.data.inventory_id);
            organism_id.push(value.data.id);
        }

        if (organism_id.length == 0) {
            this.setState({
                disabledMaster: true
            })
        }
        else {
            this.setState({
                disabledMaster: false,
            })
        }
    }

    onModal = () => {
        this.setState({
            openModal: true
        })
    }

    check_value = (value) => {
        this.props.selected_tag(value);
    }

    On_remove_tag = async () => {
        funciton_alert('', (function callFunction() {
            toast.success("Tags Removed Successfully");
            scope.props.event();
        }))
    }

    select_deselect_all = () => {
        var tags_array = [];

        if (!this.state.select_deselect_flag) {
            this.props.data.forEach(element => {
                element.inventory_tags.forEach(items => {
                    tags_array.push(`${items.id} ${items.inventory_id}`);
                });
            });
            this.props.collect_changes({
                action: "select_all",
                data: [],
                message: "All tags will be deselected ? Do You want to excute this action. "
            });
            this.setState({ select_deselect_flag: true });
        }
        else {
            var undo_btn_array = [];
            this.props.data.forEach(element => {
                element.inventory_tags.forEach(items => {
                    undo_btn_array.push(`${items.id} ${items.inventory_id}`);
                });
            });
            this.props.collect_changes({
                action: "deSelect_all",
                data: undo_btn_array,
                message: "Are you sure you want to undo your previous action? "
            });
            this.setState({ select_deselect_flag: false });
        }
        this.page_loading_true();
        this.props.selected_tag(tags_array);
    }
    tags_lengths = (tags_length, selected_tags_length) => {
        if (tags_length === selected_tags_length) {
            this.setState({ select_deselect_flag: true });
        }
        else {
            this.setState({ select_deselect_flag: false });
        }
        this.setState({ disabledRemovedBtn: !(selected_tags_length !== 0) });
    }
    undo_btn = () => {
        this.props.undo_btn()
    }

    page_loading_true = () => {
        this.setState({ is_page_loading: true });
    }
    page_loading_false = () => {
        this.setState({ is_page_loading: false });
    }

    render() {

        return (
            <>

                <Segment
                    compact
                    raised
                    padded
                    style={{ display: "block", marginTop: "0" }}
                >
                    <Button
                        id="deleteRecordsButton"
                        style={{ marginBottom: "10x" }}
                        basic
                        onClick={this.On_remove_tag}
                        disabled={this.state.disabledRemovedBtn}
                        icon
                        labelPosition="right"
                    >
                        Remove
                        <Icon name="remove bookmark" />
                    </Button>
                    <Button
                        id="deleteRecordsButton"
                        style={{ marginLeft: "11px", marginBottom: "10x" }}
                        basic
                        onClick={this.select_deselect_all}
                        icon
                        labelPosition="right"
                    >
                        {(this.state.select_deselect_flag) ? "Deselect" : "Select"} All
                        <Icon name="chevron right" />
                    </Button>
                    <Popup
                        trigger={<Button
                            id="deleteRecordsButton"
                            style={{ marginLeft: "11px", marginBottom: "10x" }}
                            basic
                            onClick={this.undo_btn}
                            icon
                            labelPosition="right"
                        >
                            Undo
                            <Icon name="undo icon" />
                        </Button>
                        }
                        content={"Action will be Revert."}
                    />


                    <Button
                        style={{ float: "right" }}
                        disabled={this.state.disabledMaster}
                        onClick={this.onModal}
                    >
                        <i className="fa fa-bandcamp" aria-hidden="true"></i>
                    </Button>
                    <br />
                    <br />
                    <div className="box_loader"> {this.state.is_page_loading && <CustomLoader />}</div>
                    <Grid columns="equal">
                        <Grid.Row>
                            <Grid.Column>
                                <div
                                    id="myGrid"
                                    className="ag-theme-balham custom-tags-table"
                                    ag-grid="gridOptions"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    <AgGridReact
                                        rowHeight="30"
                                        rowSelection="multiple"
                                        onRowClicked={this.onRowClicked}
                                        onGridReady={params => {
                                            api = params.api;
                                            api.showLoadingOverlay();
                                            column_api = params.column_api;
                                            let initial_sort = [{ colId: "id", sort: "desc" }]
                                            onGridReady(
                                                params,
                                                api,
                                                column_api,
                                                initial_sort
                                            );
                                        }}
                                        onRowSelected={this.onRowSelected}
                                        animateRows={true}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationNumberFormatter={params => {
                                            return "[" + params.value.toLocaleString() + "]";
                                        }}
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        floatingFilter={true}
                                        rowData={this.props.data}
                                        enableCellTextSelection={true}
                                        ensureDomOrder={true}
                                        suppressRowClickSelection={true}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Modal
                            isOpen={this.state.openModal}
                            onRequestClose={this.closeModal}
                            style={successModalStyles}
                        >
                            <div style={{ backgroundColor: "#fff", padding: "20px 0px 50px 0px", width: "695px" }}>
                                <Form.Field required fluid="true" >
                                    <Popup
                                        trigger={<label style={{ display: "block", textAlign: "center", fontSize: "20px", fontWeight: "700" }}>
                                            Master Tags</label>}
                                        content="Enter the Tags."
                                    />
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <form onSubmit={this.handleSubmit}>
                                            <Input
                                                type="text"
                                                onChange={(e) => { this.handleChange(e) }}
                                                placeholder="Enter Tags"
                                                autoFocus
                                                style={{ width: "500px", marginTop: "10px" }}
                                                disabled={this.props.disabledPermission}
                                                onFocus={function (e) {
                                                    var val = e.target.value;
                                                    e.target.value = '';
                                                    e.target.value = val;
                                                }}
                                            />
                                        </form>

                                    </div>
                                </Form.Field>

                                <div className="btn-grp-prime" style={{ marginTop: "15px", paddingLeft: "5px" }}>
                                    {
                                        this.state.report_tag && this.state.report_tag.length ? this.state.report_tag.map((tag, index) => {
                                            return (

                                                <ToggleButton
                                                    style={{ backgroundColor: this.state.IdArray.includes(tag.ReportTag.id) ? "darkgray" : "#BDC3C7", margin: "2px" }}
                                                    key={index}
                                                    onClick={() => this.selectSearchTag(tag)}>
                                                    {tag.ReportTag.tags}
                                                </ToggleButton>
                                            );
                                        })

                                            :
                                            <div></div>
                                    }

                                </div>

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button.Group>
                                        <Popup
                                            trigger={
                                                <Button
                                                    content="Submit"
                                                    icon="check"
                                                    labelPosition="left"
                                                    positive
                                                    onClick={this.submitTags}
                                                    style={{ marginTop: "20px" }}
                                                    disabled={
                                                        this.state.isSubmitActive
                                                    }
                                                />
                                            }
                                            content="Enter the Formulation Information."
                                        />
                                    </Button.Group>
                                </div>

                            </div>

                        </Modal>
                    </Grid>
                </Segment>
            </>
        );
    }
}

export default FloreAiIngredientTable;
