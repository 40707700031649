
import React, { Component } from "react";
import {
    Grid,
    Segment,
    Form,
    Input,
    Button,
    Popup,
    Icon,
    Table
} from "semantic-ui-react";
import { toast } from 'react-toastify';

import CustomDivider from "../custom_elements/CustomDivider";
import CustomDimmer from "../custom_elements/CustomDimmer";

import { getHeaders } from "../helper";

import axios from "axios";
import { baseURL } from "../../configuration/constants";

class Sub extends Component {

    onRefreshClick = (e) => {
        toast.success("Refreshed Successfully");
        this.props.fetchDataSub();
        axios
            .get(baseURL + "/barcode_Type_Based?type=for", getHeaders())
            .then(res => {
                let data = res.data;
                this.props.refreshHandler(data);
            })
    }

    render() {

        return (
            <Segment>
                <CustomDimmer
                    active={this.props.is_prime_filler_amount_added_exceeding_qty}
                    title="Not Enough Quantity!"
                    pre_content="Organism Inventory only has ("
                    content={this.props.qty}
                    post_content=" gm) of this Prime Filler Organism."
                    methods
                    onClickOutside={this.props.onClickOutside}
                />
                <Grid>

                    <CustomDivider
                        h_size="h4"
                        title="New Ingredients"
                        icon_name="dna"
                    />
                    <Grid.Row>
                        <Grid.Column width={11}></Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field required fluid="true">
                                <Button
                                    basic
                                    onClick={this.onRefreshClick}
                                    icon
                                    labelPosition="right"
                                >
                                    Refresh Inventory
                                    <Icon name="refresh" />
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="block" >
                        <Grid.Column width={5}>
                            <Form.Field required fluid="true">
                                <Popup
                                    trigger={<label>Ingredient Barcode</label>}
                                    content="Enter the Ingredient Barcode."
                                />
                                <input list="browsersFor"
                                    style={{ opacity: 1, backgroundColor: "#b8sfcff" }}
                                    onChange={(e) => this.props.onInputChangeSub(this.props.all, e.target.value)}
                                ></input>
                                <datalist
                                    id="browsersFor"
                                >
                                    {
                                        this.props.all && this.props.all.map((elem, index) => {
                                            return (
                                                <option key={index}>
                                                    {elem.organism_barcode}
                                                </option>
                                            )
                                        })
                                    }
                                </datalist>
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label>Amount Added</label>}
                                    content="Enter the amount added."
                                />
                                <Input
                                    id="NumericTextBox_flourish"
                                    className="amount_added"
                                    type="number"
                                    style={{ opacity: 1, width: "80%", }}
                                    name="amount_added"
                                    icon="weight"
                                    iconPosition="left"
                                    label={{ content: "g" }}
                                    labelPosition="right"
                                    placeholder="Amount Added"
                                    onChange={this.props.onInputChanges}
                                    value={this.props.amount_added}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Form.Field fluid="true">
                                <Popup
                                    trigger={<label>Concentration</label>}
                                    content="Enter the Concentration."
                                />
                                <Input
                                    name="concentration"
                                    className="full_opacity"
                                    disabled
                                    icon="warning sign"
                                    iconPosition="left"
                                    placeholder="Concentration"
                                    value={Math.floor(this.props.concentration * 1000) / 1000}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Form.Field fluid="true">
                                <Popup
                                    trigger={<label>Total CFU</label>}
                                    content="Enter Total CFU."
                                />
                                <Input
                                    name="final_concentration"
                                    className="full_opacity"
                                    disabled
                                    icon="warning sign"
                                    iconPosition="left"
                                    placeholder="Total CFU"
                                    value={Math.floor(this.props.FinalConcetration * 1000) / 1000}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={1} style={{ textAlign: "right" }}>
                            <Form.Field fluid="true">
                                <label style={{ visibility: "hidden" }}>.</label>
                                <Popup
                                    trigger={
                                        <Button
                                            onClick={() => {
                                                this.props.onDeleteOrganismClick(
                                                    this.props.organism.organism_barcode
                                                );
                                            }}
                                            icon="delete"
                                            disabled={!this.props.is_disabled}
                                            negative
                                            circular
                                        />
                                    }
                                    content="Delete this Organism."
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Popup
                                trigger={
                                    <Button
                                        animated="vertical"
                                        className="small"
                                        color="blue"
                                        onClick={this.props.onAddOrganismClick}
                                    >
                                        <Button.Content visible>
                                            <Icon name="plus circle" />
                                            Insert
                                        </Button.Content>
                                        <Button.Content hidden>
                                            More&nbsp;
                                            <Icon name="arrow right" />
                                        </Button.Content>
                                    </Button>
                                }
                                content="Add more Organism(s)."
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3} />
                        <Grid.Column width={10}>
                            <Segment>
                                <Table celled definition color="green">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width={8}>
                                                <Icon name="check circle" />
                                                Total Amount Added
                                            </Table.Cell>
                                            <Table.Cell width={4}>
                                                {parseFloat(this.props.tableAmount).toFixed(3)}
                                            </Table.Cell>
                                            <Table.Cell positive width={4}>
                                                g
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Icon name="check circle" />
                                                Total Final Concentration
                                            </Table.Cell>
                                            <Table.Cell>
                                                {parseFloat(this.props.tableConcentration).toFixed(3)}
                                            </Table.Cell>
                                            <Table.Cell positive>B CFU /g</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Icon name="check circle" />
                                                Total Final Amount Added
                                            </Table.Cell>

                                            <Table.Cell>
                                                {parseFloat(this.props.tableFinalAmount).toFixed(3)}
                                            </Table.Cell>
                                            <Table.Cell positive>g</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={3} />
                    </Grid.Row>
                    <Grid.Row />
                </Grid>

            </Segment>
        );
    }
}

export default Sub;
