import React, { Component } from "react";
import { Message, Segment, Header, Grid, Accordion, Icon } from "semantic-ui-react";
import "../../formulation_rationale/HealthSummary/index.css";
class NewReformulationForm extends Component {
    state = { activeIndexs:[0] }

    componentDidMount() {  }
    generateSurveyText = (surveyText,activeIndexs) => {
      return (
        <div>
          {surveyText.length > 0 && surveyText.map((item, index) => {
              return (<Accordion>
              <Accordion.Title
                active={activeIndexs.includes(activeIndexs)}
                index={index}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" style={{transform:(activeIndexs.includes(index))? "rotate(90deg)":"rotate(0deg)"}} />
                {item.sample_id}
              </Accordion.Title>
              <Accordion.Content active={activeIndexs.includes(index)}>
                <Grid>
              <Grid.Row className="block">

                <Grid.Column width={this.props.component == "all_formulation" ? 10 : 12}>
                  <b>Formula Id: </b>&nbsp;&nbsp;{item.formula_id}<br />
                  <b>Sample Id: </b>&nbsp;&nbsp;{item.sample_id}<br />
                  <b>No. of Months: </b>&nbsp;&nbsp;{item.no_of_months}<br />
                   {
                     item.reasons &&
                    <div>
                      <b>Reason:</b> <br />
                      <ul >
                        {item.reasons.map((items) => {
                          return <li> {items} </li>
                        })}
                      </ul>
                    </div>
                  }
                </Grid.Column>
                  
                {item.notes
                   && 
                   <Grid.Column width={this.props.component == "all_formulation" ? 6 : 16} style={{ wordBreak: "break-word" }}>
                  <b>Notes: </b>&nbsp;&nbsp;{item.notes}  
                  </Grid.Column>}
              </Grid.Row>
            </Grid> 
              </Accordion.Content> 
            </Accordion>)
            }
          )}
        </div>
      );
    };

    handleClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeIndexs } = this.state;
      const newIndex = [];
      const currentIndexPosition = activeIndexs.indexOf(index);
      var x = false;
      if (currentIndexPosition > -1) {
        newIndex.splice(currentIndexPosition, 1);
        x = true;
      }
      if (!x) {
        newIndex.push(index);
      }
      this.setState({ activeIndexs: newIndex });
    };

    genrateReformulationLogs = (logs)=>{
    
    return (
      <div>
        {logs.map((item, index) =>{
         return ( <div style={{ overflow: 'scroll-x', width: "100%" }}>
                 <table style={{ width: '100%' }} className="ui celled table position-relative">
                   <thead style={{ fontWeight: 'bold' }}>
                     <tr style={{ textAlign: "center" }}>
                       <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Organism Barcode</th>
                       <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Required Quantity</th>
                       <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Available Quantity </th>
                       <th style={{ backgroundColor: "#b8bfc257", fontWeight: 'bold' }} rowSpan="1">Is Available?</th>
                     </tr>
                   </thead>
                   <tbody>
                       <tr style={{ textAlign: "center" }} key={index}>
                           <td className="center aligned" style={{ padding: "7px" }}>{item.organism_barcode}</td>
                           <td className="center aligned" style={{ padding: "7px" }}>{item.required_qty}</td>
                           <td className="center aligned" style={{ padding: "7px" }}>{item.available_qty}</td>
                           <td className="center aligned" style={{ padding: "7px" }}>{(item.is_available === 1) ? "Yes" : "No"}</td>
                         </tr> 
          
                   </tbody>
                 </table><br/>
        </div>)
        })}
      </div>
      ) }
    
render() {
  var { activeIndexs } = this.state;
  var surveyText;
  var reformulation_logs_data;
if(Array.isArray(this.props.reformulation_logs) && !this.props.reformulation_logs_exists){
  var reformulation_logs_array = [];
  var reformulation_details_array = [];
  if(this.props.reformulation_logs.length>0){
    this.props.reformulation_logs && this.props.reformulation_logs.map((item)=>{
        item.data.sample_id = item.sample_id
        if(item.data.type =="logs"){
          reformulation_logs_array.push(item.data);
        }else{
          reformulation_details_array.push(item.data);
        }
      })
      surveyText = this.generateSurveyText(reformulation_details_array,activeIndexs);
      reformulation_logs_data = this.genrateReformulationLogs(reformulation_logs_array);
    }
  

}else{
 
  if(!Array.isArray(this.props.reformulation_logs)){
    surveyText = this.generateSurveyText([this.props.reformulation_logs],activeIndexs);
  }
  if(this.props.reformulation_logs_exists){
    reformulation_logs_data = this.genrateReformulationLogs(this.props.reformulation_logs);
  }
}
  return (
    <>
        <Message
          className="formulation-rationale__health-survey__header"
          style={{ marginBottom: "0" }}
        >
          <Header as="h2" textAlign="center">
            Reformulation Info
          </Header>
        </Message>
      <Segment
      style={{marginTop: "0px"}}
        className={
            (this.props.component == "all_formulation") ? "formulation-rationale__health-survey__content mnh-auto" : 
            (this.props.component == "reformulation") ? "formulation-rationale__health-survey " : "formulation-rationale__health-survey__content"
          }>
      {surveyText}
      {reformulation_logs_data}

      </Segment>

     </>
    );
  }
}
export default NewReformulationForm
