//-------------------------------------------------------------------//
// Module: CustomConfirm                                             //
// Tasks: Display Semantic-UI specified Confirm                      //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import { Confirm } from "semantic-ui-react";

import React, { Component } from "react";

class CustomConfirm extends Component {
  state = {};

  render() {
    return (
      <Confirm
        open={this.props.is_confirm_open}
        content={this.props.content}
        header={this.props.header}
        onCancel={this.props.onCancel}
        onConfirm={this.props.onConfirm}
        cancelButton={this.props.cancel_button_text}
        confirmButton={this.props.confirm_button_text}
      />
    );
  }
}

export default CustomConfirm;
