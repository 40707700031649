import React, { Component, Fragment } from "react";
import axios from "axios";

import { Message, Segment, Header, Grid, Button } from "semantic-ui-react";
import { getHeaders } from "../helper";
import { baseURL } from "../../configuration/constants";
import { onGridReady } from "../helper";
import CustomLoader from "../custom_elements/CustomLoader";

import { AgGridReact } from "ag-grid-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

let api, column_api;

const ModalStyles = {
	content: {
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, 9%)',
		border: 'none',
		background: 'none',
		padding: '0',
	}
};


class GetLogApi extends Component {
	state = {
		logsData: [],
		openModal: false,
		post_params_date: "",
		post_params_sg_number: "",
		post_params_status: "",
		is_page_loading: true,
		response_params_statusCode: "",
		response_params_sg_number: "",
		response_params_tracking_id: "",

		columnDefs: [
			{
				headerName: "Sample ID",
				field: "sg_number",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Api Type",
				field: "api_type",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Created At",
				field: "created_at",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Information",
				field: "post_params.status",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Success",
				field: "success",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
				cellRendererFramework: function (params) {
					if (params.value === "n") {
						return (
							<div>No</div>
						)
					}
					else {
						return (
							<div>Yes</div>
						)
					}
				}
			},
			{
				headerName: "Update",
				field: "success",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
				cellRendererFramework: function (params) {
					if (params.value === "n") {
						return (
							<Button style={{ width: "105%" }}>
								Yes
							</Button>
						)
					}
					else {
						return false;
					}
				},
				cellStyle: function () {
					return { fontWeight: '100' };
				}
			},
			{
				headerName: "Params",
				field: "",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
				cellRendererFramework: function (params) {
					return (
						<Button style={{ background: "none" }}>
							<i className="fa fa-bandcamp fa-1x" aria-hidden="true"></i>
						</Button>
					)
				},
				cellStyle: function () {
					return { fontWeight: '100' };
				}
			},
		],
	};
	componentDidMount = () => {
		axios
			.get(baseURL + "/get-logsApi", getHeaders())
			.then(response => {
				this.setState({
					logsData: response.data,
					is_page_loading:false
				})
			})
			.catch(error => {
				alert("Fetching Display Grid | Error: ", error);
			});
	}

	RowClcked = (value) => {
		let data = {
			"blank": ""
		}

		if (value.colDef.headerName === "Params") {
			this.setState({
				openModal: true,
				post_params_date: value.data.post_params.date,
				post_params_sg_number: value.data.post_params.sg_number,
				post_params_status: value.data.post_params.status,
				response_params_statusCode: value.data.response_params.statusCode,
				response_params_tracking_id: value.data.response_params.tracking_id
			})
		}
		if (value.colDef.headerName === "Update") {
			if (value.data.api_type === "lims" && value.data.success === "n") {
				axios
					.post(baseURL + "/trigger-api/LIMS/" + value.data.id, data, getHeaders())
					.then(response => {
						toast.success("Updated Successfully");
						window.location.reload(false);
						return;
					})
					.catch(error => {
						alert("Fetching Display Grid | Error: ", error);
					});
			}
			else if (value.data.api_type === "webportal" && value.data.success === "n") {
				axios
					.post(baseURL + "/trigger-api/WEBPORTAL/" + value.data.id, data, getHeaders())
					.then(response => {
						toast.success("Updated Successfully");
						window.location.reload(false);
						return;
					})
					.catch(error => {
						alert("Fetching Display Grid | Error: ", error);
					});
			}
			else {
				return false;
			}
		}
	}

	closeModal = () => {
		this.setState({
			openModal: false,
		})
	}

	render() {
		return (
			<Fragment>
				{this.state.is_page_loading && <CustomLoader />}
				<Segment>
					<Message>
						<Header as="h3" textAlign="center">
							Logs Api
						</Header>
					</Message>
					<Grid.Row>
						<Grid.Column>
							<Segment
								compact
								raised
								padded
								style={{ display: "block", marginTop: "0" }}>
								<Grid columns="equal">
									<Grid.Row>
										<Grid.Column>
											<div
												id="myGrid"
												className="ag-theme-balham"
												ag-grid="gridOptions"
												style={{
													height: "100%",
													width: "100%",
												}}>
												<AgGridReact
													rowHeight="30"
													rowSelection="single"
													onCellClicked={this.RowClcked}
													onGridReady={(params) => {
														api = params.api;
														api.showLoadingOverlay();
														api.sizeColumnsToFit();
														column_api =
															params.column_api;
														onGridReady(
															params,
															api,
															column_api,
															this.props
																.initial_sort,
														);
													}}
													animateRows={true}
													pagination={true}
													paginationPageSize={50}
													paginationNumberFormatter={(
														params,
													) => {
														return (
															"[" +
															params.value.toLocaleString() +
															"]"
														);
													}}
													columnDefs={
														this.state.columnDefs
													}
													floatingFilter={true}
													rowData={this.state.logsData}
													suppressPropertyNamesCheck={true}
												/>
											</div>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Segment>
				<Modal
					isOpen={this.state.openModal}
					onRequestClose={this.closeModal}
					style={ModalStyles}
				>
					<div style={{ backgroundColor: "#fff", padding: "54px", height: "327px" }}>
						<div style={{ display: "inline-block", width: "200px" }}>
							<h3>Post Params</h3>
							<p><strong>Sg Number :</strong>{this.state.post_params_sg_number}</p>
							<p><strong>Status :</strong>{this.state.post_params_status}</p>
							<p><strong>Date :</strong>{this.state.post_params_date}</p>
						</div>
						<div style={{ display: "inline-block" }}>
							<h3>Response Params</h3>
							<p><strong>Sg Number :</strong>{this.state.post_params_sg_number}</p>
							<p><strong>Status Code :</strong>{this.state.response_params_statusCode}</p>
							<p><strong>Tracking ID :</strong>{this.state.response_params_tracking_id}</p>
						</div>

					</div>
				</Modal>
			</Fragment>
		)
	}
}

export default GetLogApi;