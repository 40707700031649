import React, { Component } from "react";
import axios from "axios";

import { Message, Segment, Header, Grid, Button } from "semantic-ui-react";
import Modal from 'react-modal';
import { AgGridReact } from "ag-grid-react";

import "./index.css";
import { baseURL } from "../../../configuration/constants";
import { onGridReady, getHeaders, errorHandler } from "../../helper";
import CustomLoader from "../../custom_elements/CustomLoader";
import TagColumnRenderer from "../../inventory_mod/NewFormulation/tagColumnRenderer";
import Zotero from "./Zotero";
import TableAccordian from "./tableAccordian";
import SuccessIngredient from "./successIngredient";
import { toast } from "react-toastify";

const successModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: 'none',
        padding: '0',
        width: '900px',
        outline: '15px solid #fff'
    }
};

const IngredientModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        background: 'none',
        padding: '0',
        width: '900px'
    }
};

const ZoteroModalStyles = {
    content: {
        top: '5%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, 0%)',
        border: 'none',
        background: 'none',
        padding: '0',
    }
};

let payload = { sg_number: "" };
let surveyData = [];
let api, column_api;

Modal.setAppElement('#container');

class Tags extends Component {
    state = {
        cpyBtnText: "Copy All Tags",
        serach_tag_name: "",
        formulaId: "",
        total_cfu: "",
        allSucess: [],
        zotero: [],
        all_inventory: [],
        openSucessFormullaModal: false,
        openIngredientModal: false,
        openZotero: false,
        survey: [],
        is_page_loading: false,
        columnDefs: [
            {
                headerName: "Tags",
                field: "tag.tag_name",
                filter: "agTextColumnFilter",
                width: 220,
                cellRendererFramework: function (params) {
                    return <TagColumnRenderer
                        data={params}
                        modulefield={params.colDef.field}
                        previous_formula={false}
                    />
                }
            },
            {
                headerName: "Reference PDF's",
                field: "tag.refrences_pdf",
                width: 130,
                cellStyle: params => params.value > 0 ? { color: 'blue', cursor: 'pointer' } : null
            },
            {
                headerName: "Successful formulas",
                field: "tag.success_formula",
                width: 145,
                cellStyle: params => params.value > 0 ? { color: 'blue', cursor: 'pointer' } : null,
                cellRendererFramework: function (params) {
                    return (
                        <div>{params.value}</div>
                    )
                }
            },
            {
                headerName: "Successful Ingredient",
                field: "tag.success_ingredient",
                width: 160,
                cellStyle: params => params.value > 0 ? { color: 'blue', cursor: 'pointer' } : null
            },
            {
                headerName: "Overall Successful",
                field: "tag.overall_success",
                width: 140
            },
            {
                headerName: "Overall Used",
                field: "tag.overall_used",
                width: 125
            },
            {
                headerName: "Overall Success Rate",
                field: "tag.overall_success_rate",
                width: 160,
                cellRenderer: (params) => {
                    return (
                        `
                        <div>
                        ${parseFloat(params.value).toFixed(2)} %
                        </div>
                        `
                    )
                }
            },
        ]
    };
    componentDidUpdate(prevProps) {
        const applicationURL = document.URL;
        let selectedSample = this.props.selectedSample;
        let previousSelectedSampleSGNumber = prevProps.selectedSample.sg_number;
        let selectedSampleSGNumber = selectedSample.sg_number;
        let url;
        if (previousSelectedSampleSGNumber !== selectedSampleSGNumber) {
            this.setState({
                is_page_loading: true
            })
            if (applicationURL.includes("formulation-dev")) {

                url = `${baseURL}/get-formula-tags/` + selectedSample.sg_number;
            } else {
                url = `${baseURL}/get-formula-tags/` + selectedSample.sg_number;
            }
            axios.post(url, payload, getHeaders()).then(res => {
                if (res.data.success === false) {
                    this.setState({
                        survey: [],
                        is_page_loading: false,
                    })
                }
                else {
                    this.setState({
                        cpyBtnText: "Copy All Tags",
                    })
                    this.props.getMultiSelectTask(res.data.data)
                    surveyData = res.data.data;
                    if (surveyData.length > 0) {
                        this.setState({
                            survey: surveyData,
                            is_page_loading: false,

                        }) }
                }
            });
        }
    }

    onRowClicked = (value) => {
        
        this.setState({
            serach_tag_name: value.data.tag.search_tag
        })
        if (value.colDef.headerName === "Successful formulas" && value.data.tag.success_formula !== 0) {
            this.successFormulla(value)

        }
        else if (value.colDef.headerName === "Successful Ingredient") {
            this.successFullIngredient(value)
        }
        else if (value.colDef.headerName === "Reference PDF's" && value.data.tag.refrences_pdf !== 0) {
            this.setState({
                openZotero: true,
                zotero: value.data
            })
        }
        else {
            return false;
        }
    }

    successFormulla = (value) => {
        let url = `${baseURL}/get-success-formula/` + value.data.tag.tag_id + "/formula";
        axios.post(url, payload, getHeaders()).then(res => {
            this.setState({
                openSucessFormullaModal: true,
                allSucess: res.data
            })
        })
    }

    successFullIngredient = (value) => {
        if (value.data.tag.success_ingredient !== 0) {
            let url_ingredient = `${baseURL}/get-success-formula/` + value.data.tag.tag_id + "/ingredient";
            axios.post(url_ingredient, payload, getHeaders()).then(res => {
                if (res.data.data == 'Record not found') {
                    errorHandler(res.data.data);
                    return;
                }
                this.setState({
                    openIngredientModal: true,
                    all_inventory: res.data
                })
            });
        }
    }

    closeModal = () => {
        this.setState({
            openIngredientModal: false,
            openSucessFormullaModal: false,
            openZotero: false
        })
    }

    copyAllTags = () => {
        const str = "\n";
        let tagList = [];
        this.state.survey && this.state.survey.map((item, index) => {
            tagList.push(item.tag.tag_name);
            this.setState({ cpyBtnText: "Tags Copied!" });
            navigator.clipboard.writeText(tagList.join(str));
        })
    }

   getArrayFromTagAccordian = (value) => {
        axios
            .post(baseURL + "/check-ingredient-availability", value, getHeaders())
            .then(response => {

                this.props.getTagDataFromParent(response.data.success, response.data.data.available_inventory)
            })
    }


    render() {
        const { is_page_loading, columnDefs, openSucessFormullaModal, openIngredientModal, openZotero, zotero,
            serach_tag_name, allSucess, survey, cpyBtnText } = this.state;
        return (
            <>
                <Message style={{ marginBottom: "0" }}>
                    <Header as="h2" textAlign="center">
                        Tags
                    </Header>
                </Message>
                {is_page_loading && <CustomLoader />}
                <Segment
                    compact
                    raised
                    padded
                    style={{ display: "block", marginTop: "0" }}
                >
                    <Button style={{
                        cursor: "pointer",
                        marginBottom: "1%"
                    }}
                        onClick={this.copyAllTags}>
                        {cpyBtnText}
                    </Button>
                    <Grid.Row>
                        <Grid.Column>
                            <div
                                id="myGrid"
                                className="ag-theme-balham"
                                ag-grid="gridOptions"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                }}
                            >
                                <AgGridReact
                                    rowHeight="30"
                                    rowSelection="multiple"
                                    onCellClicked={this.onRowClicked}
                                    onGridReady={params => {
                                        api = params.api;
                                        api.showLoadingOverlay();
                                        column_api = params.column_api;
                                        let initial_sort = [{ colId: "id", sort: "desc" }]
                                        onGridReady(
                                            params,
                                            api,
                                            column_api,
                                            initial_sort
                                        );
                                    }}
                                    animateRows={true}
                                    pagination={true}
                                    paginationPageSize={50}
                                    paginationNumberFormatter={params => {
                                        return "[" + params.value.toLocaleString() + "]";
                                    }}
                                    columnDefs={columnDefs}
                                    floatingFilter={true}
                                    rowData={survey}
                                    ensureDomOrder={true}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Segment>


                <Modal
                    isOpen={openSucessFormullaModal}
                    onRequestClose={this.closeModal}
                    style={successModalStyles}
                >
                    <Message className="success-formulla">
                        <Header as="h3" textAlign="center">
                            Successful formula
                        </Header>
                    </Message>

                    <div className="tags-info-table-scroll">
                        <table id="example" className="table">
                            <thead>
                                <tr>
                                    <th>More Info</th>
                                    <th>SG Number</th>
                                    <th>Fmrx _ID</th>
                                    <th>Create Date</th>
                                    <th>Made Date</th>
                                    <th>Created By</th>
                                </tr>
                            </thead>
                            {allSucess && allSucess.map((elem, index) => {
                                return (
                                    <TableAccordian
                                        key={index}
                                        sampleId={elem.formulation.sample_id}
                                        formulaId={elem.formulation.formula_id}
                                        dateCreated={elem.formulation.date_created}
                                        dateMade={elem.formulation.date_made}
                                        userName={elem.formulation.user_name}
                                        data={elem}
                                        index={index}
                                        serach_tag_name={serach_tag_name}
                                        getArrayFromTagAccordian={this.getArrayFromTagAccordian}
                                        arr={this.props.arr}
                                    />
                                )
                            })}
                        </table>
                    </div>


                </Modal>

                <Modal
                    isOpen={openIngredientModal}
                    onRequestClose={this.closeModal}
                    style={IngredientModalStyles}
                >
                    <SuccessIngredient all_inventory={this.state.all_inventory} serach_tag_name={serach_tag_name} />

                </Modal>
                <Modal
                    isOpen={openZotero}
                    onRequestClose={this.closeModal}
                    style={ZoteroModalStyles}
                >
                    <Zotero zotero={zotero} serach_tag_name={serach_tag_name} />

                </Modal>

            </>
        );
    }
}

export default Tags;