import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";

import {
    focusFirstElement, getDateString, increaseDateYear, getHeaders, getSessionStoredJsonItem,formatDate,
    digitsAfterPointAddIngredient, digitsAfterPointOrganism, changeAvailablityOfOrganism, checkValueExistOrNotInMultiVFun, multi_v_availablity, isAvailable_lower
} from "../helper";
import Config from "../../configuration/config";
import CustomLoader from "../custom_elements/CustomLoader";
import CustomDivider from "../custom_elements/CustomDivider";
import { baseURL } from "../../configuration/constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Popup, Segment, Button, Input, Grid, Radio, TextArea, Icon } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from 'multiselect-react-dropdown';
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import $ from 'jquery';
import { Custom_DropDown } from "./DropDown";
import MultiVAvailablity from "./multi_v_availablity";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

let elemAmountPergram;
let cross;


const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
}

class CreatePrebiotic extends Component {
    getInitState = () => {
        return {
            multi_v:"available",
            ingredient_type:"",
            multi_v_gram:"",
            attachment_arr: [],
            check: 0,
            is_avaialble_value: "",
            serving_size: "",
            amount_per_gram: "",
            supplement_id: 0,
            unit: "g",
            inventory: [],
            id: "",
            quantity: "",
            multiSelectData: [],
            benefit: [],
            is_saving_successful: false,
            is_saving_failed: false,
            is_waiting_for_response: false,
            response_message: "",
            is_page_loading: true,
            barcode: "",
            is_available: 0,
            concentration: 0,
            qty: "",
            reorder_point: 0,
            scrap_factor: 0,
            name: "",
            real_name: "",
            receive_date: new Date(),
            open_date: new Date(),
            expiration_date: increaseDateYear(new Date()),
            manufacture: "",
            manufacture_lot: "",
            storage_location: "",
            shelf: 0,
            notes: "",
            allergeanData: [],
            allergean: [{}],
            allergeanList: [],
            supplement: "",
            supplements: [],
            all: []
        };
    };

    state = this.getInitState();

    constructor(props) {
        super(props);
        this.multiselectRef = React.createRef();
        this.fetchAllergeanData();
    }

    fetchAllergeanData = () => {
        axios.get(baseURL + "/allergens", getHeaders())
            .then((result) => {
                let allergeanArray = [];
                if (result.data.data.length > 0) {
                    let x = result.data.data;
                    x && x.map((elem, index) => {
                        let obj = { label: elem.allergen, name: elem.allergen, id: elem.id }
                        allergeanArray.push(obj);
                    })

                    this.setState({ allergeanData: allergeanArray, allergeanList: [] })
                }
                // else {
                //     toast.warning("Allergean Not Found");
                // }
            })
            .catch((err) => {
                toast.error("Something Goes Wrong");
            })
    }
    onSelectAllergen = (allergean) => {
        this.setState({ allergeanList: allergean })
    }
    // fetchExistingAllergean = () => {

    //     if (this.state.showAllergeanSelectBox) {
    //         let emptyArray = [];
    //         // this.multiselectRef.current.state.options = this.state.allergeanData;

    //         axios
    //             .get(baseURL + "/inventory_allergens/" + this.state.id, getHeaders())
    //             .then(response => {
    //                 let x = response.data.data;

    //                 x && x.map((elem, index) => {
    //                     let obj = { label: elem.allergen.allergen, name: elem.allergen.allergen, id: elem.allergen.allergen_id }
    //                     emptyArray.push(obj);
    //                 })
    //                 this.onSelectAllergen(emptyArray);
    //             })
    //     }
    // }
    onSelect = (selectedList, selectedItem) => {
        if (selectedList && selectedList.length) {
            this.setState({
                benefit: selectedList
            })
        }
        else {
            this.setState({
                benefit: []
            })
        }
    }

    AnswerKeyPress = (e) => {
        let code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }
    componentDidMount = () => {
        this.fetchBenefit()
        this.fetchSupplements()
    }

    fetchBenefit = () => {
        axios
            .get(baseURL + "/benefits", getHeaders())
            .then(response => {
                let x = response.data.data;
                let emptyArray = [];
                x && x.map((elem, index) => {
                    let obj = { name: elem.benefit.benefit, id: elem.benefit.benefit_id }
                    emptyArray.push(obj);
                })
                this.setState({
                    multiSelectData: emptyArray,
                    is_page_loading: false
                })
            })
    }

    fetchSupplements = () => {
        axios
            .get(baseURL + "/supplements/new/null", getHeaders())
            .then(res => {
                let tempArray = [];
                tempArray.push(...res.data);
                this.onInputChangeSupplement(tempArray, this.state.supplement);
                this.setState({
                    all: tempArray
                })
            })
    }

    onInputChangeSupplement = (array, value) => {
        this.setState({
            supplement: value
        })
        let tempArray = array
        tempArray[0].filter((elem) => {
            if (elem.supplement === value) {
                if (elem.unit === "no unit") {
                    this.setState({
                        supplement_id: elem.supplement_id,
                        unit: ""
                    })
                }
                else {
                    this.setState({
                        supplement_id: elem.supplement_id,
                        unit: elem.unit
                    })
                }
            }
        })
    }

    onInputChangeServingSize = (e, { name, value }) => {
        digitsAfterPointAddIngredient()
        this.setState({
            [name]: value
        });
        let tempArray = [];
        let obj = {};
        this.state.inventory && this.state.inventory.map((elem, index) => {
            cross = elem.quantity / value;
            obj = {
                "id": elem.id,
                "supplement": elem.supplement,
                "quantity": elem.quantity,
                "unit": elem.unit,
                "amount_per_gram": cross,
                "serving_size": value
            }
            tempArray.push(obj);
        })

        let a = this.state.quantity;
        let b = value;
        let c = a / b;
        this.setState({
            supplements: tempArray,
            amount_per_gram: c
        })
    };

    onInputChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    onInputAmountChange = (e, { name, value }) => {
        digitsAfterPointOrganism()
        this.setState({
            [name]: value
        });
        let a = value;
        let b = this.state.serving_size;
        let c = a / b;
        this.setState({
            amount_per_gram: c
        })
    }

    onAddOrganismClick = () => {
        if (this.state.quantity === "" || this.state.quantity === 0 || this.state.supplement_id === 0 || this.state.supplement_id === ""
            || this.state.serving_size === "" || this.state.supplement === "") {
            alert("Please select supplement, Amount Per Serving and Serving Size");
            return;
        }

        let tempArray = this.state.inventory;
        let obj = {};
        obj = {
            "id": this.state.supplement_id,
            "supplement": this.state.supplement,
            "quantity": this.state.quantity,
            "unit": this.state.unit,
            "amount_per_gram": this.state.amount_per_gram,
            "serving_size": this.state.serving_size
        }
        tempArray.push(obj);
        let SelectedSupplement = this.state.supplement;

        let temps = this.state.all;
        temps && temps[0] && temps[0].filter((elem, index) => {
            if (elem.supplement === SelectedSupplement) {
                temps[0].splice(index, 1);
            }
        })

        this.setState({
            all: temps,
            inventory: tempArray,
            supplements: tempArray
        })
        this.setState(this.resetOrganismState());
    }
    resetOrganismState = () => {
        return {
            quantity: 0,
            id: 0,
            supplement: "",
            amount_per_gram: ""
        };
    };

    onDateChange = (name, date) => {
        if (name === Config.inventory.receive_date) {
            this.setState({ receive_date: date });
            if (date) {
                this.setState({ expiration_date: increaseDateYear(date) });
            }
        } else if (name === Config.inventory.open_date) {
            this.setState({ open_date: date });
        } else if (name === Config.inventory.expiration_date) {
            this.setState({ expiration_date: date });
        } else {
            alert("Some Date Error Enterred!");
        }
    };

    changeAvailablity = async value => {
        var data = await changeAvailablityOfOrganism(value);
        this.setState(data);
    };
    
    onSubmitClick = () => {
        let {multi_v,ingredient_type,multi_v_gram, manufacture, manufacture_lot, name, real_name, qty, scrap_factor, reorder_point } = this.state;
            console.log(this.state);
        var datass = checkValueExistOrNotInMultiVFun(multi_v,ingredient_type,multi_v_gram)
        if(!datass.unavailable && datass.checked){
            alert(datass.info);
            return;
        }
        

        if (!manufacture || !manufacture_lot || !name || !real_name || !qty) {
            toast.info("Please fill all the mandatory information.");
            return;
        }

        if (scrap_factor === "" || reorder_point === "") {
            toast.info("Please fill all the mandatory information.");
            return;
        }

        this.setState({
            is_saving_successful: false,
            is_saving_failed: false
        });
        

        let res = _.pick(this.state, [
            "receive_date",
            "multi_v","ingredient_type","multi_v_gram",
            "open_date",
            "expiration_date",
            "is_available",
            "concentration",
            "manufacture",
            "manufacture_lot",
            "qty",
            "reorder_point",
            "storage_location",
            "shelf",
            "scrap_factor",
            "notes",
            "name",
            "real_name",
            "benefit",
            "supplements",
            "attachment_arr",
            "allergeanList"
        ]);
        res.multi_v = multi_v;

        // adding the remaning fields.
        res.user_id = getSessionStoredJsonItem("user").user_id;
        res.open_date = formatDate(this.state.open_date);
        res.expiration_date = formatDate(this.state.expiration_date);
        res.receive_date = formatDate(this.state.receive_date);
        this.setState({ is_waiting_for_response: true });
        axios
            .post(baseURL + "/add_new_barcodes?type=pre", res, getHeaders())
            .then(response => {
                toast.success("Inventory Added Successfully" + "  Barcode is  " + response.data.barcode);
                let init_state = this.getInitState();
                init_state["is_page_loading"] = false;
                init_state["is_waiting_for_response"] = false;
                this.setState(init_state);
                focusFirstElement();
                this.setState({
                    benefit: [],
                    attachment_arr: []
                })
                this.fetchBenefit()
                setInterval(() => {
                    window.location.reload(false);
                }
                    , 1000);
            })
            .catch(error => {
                toast.error("Invalid Data");
                this.setState({ is_page_loading: false });
            });
        this.multiselectRef.current.resetSelectedValues()
    };

    onCancelClick = () => {
        let init_state = this.getInitState();
        init_state["is_page_loading"] = false;
        this.setState(init_state);
        this.setState({
            attachment_arr: []
        })
    };

    onRemove = (value) => {
        this.setState({
            benefit: value
        })
    }

    delete = (index, value) => {
        let secondTemp = this.state.inventory;
        secondTemp.splice(index, 1);
        this.setState({
            inventory: secondTemp
        })
        this.state.all && this.state.all[0] && this.state.all[0].push({
            supplement: value.supplement,
            supplement_id: value.id,
            unit: value.unit,
            quantity: value.quantity
        })
    }

    onupdatefiles = (fileItems) => {
        const scope = this;
        let attachment_arr_temp = this.state.attachment_arr;
        attachment_arr_temp = [];
        fileItems.map((elem, index) => {
            let mediaTypePdf = elem.file.type.split("/")[1];
            let mediaSize = elem.file.size;
            let mediaType = elem.file.type;
            let mediaName = elem.file.name;
            var selectedFile = elem.file;

            if (selectedFile) {
                var fileToLoad = selectedFile;
                var fileReader = new FileReader();
                var base64;
                fileReader.readAsDataURL(fileToLoad);
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    let res = base64.slice(28);
                    if (mediaTypePdf === "pdf" && mediaSize <= 5242880) {
                        let obj = {};
                        obj = {
                            "base64Media": res,
                            "mediaName": mediaName,
                            "type": mediaType,
                            "size": mediaSize
                        }
                        attachment_arr_temp.push(obj);
                        const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                        scope.setState({
                            attachment_arr: final_array
                        })
                    }
                };
            }
        })
    }


    handleRemove = () => {
        const scope = this;
        this.setState({
            attachment_arr: []
        })
        let attachment_arr_temp = this.state.attachment_arr;
        if (this.pond) {
            const remainingAttachments = this.pond.getFiles().map((file) => file.source);
            remainingAttachments.map((elem, index) => {
                let mediaSize = elem.size;
                let mediaType = elem.type;
                let mediaName = elem.name;
                var selectedFile = remainingAttachments[0];
                if (selectedFile) {
                    var fileToLoad = selectedFile;
                    var fileReader = new FileReader();
                    var base64;
                    fileReader.onload = function (fileLoadedEvent) {
                        base64 = fileLoadedEvent.target.result;
                        let res = base64.slice(28);
                        if (mediaSize <= 5242880) {
                            let obj = {};
                            obj = {
                                "base64Media": res,
                                "mediaName": mediaName,
                                "type": mediaType
                            }
                            attachment_arr_temp.push(obj);
                            const final_array = [...new Map(attachment_arr_temp.map(item => [JSON.stringify(item), item])).values()];
                            scope.setState({
                                attachment_arr: final_array
                            })

                        }
                    };

                    fileReader.readAsDataURL(fileToLoad);
                }
            })
        }
    }
    multi_v_availablity_fn = (type,value)=>{
        var multi_value = multi_v_availablity(type,value,this.state.multi_v);
        this.setState(multi_value);
    }
    


    render() {
        const { is_page_loading, is_waiting_for_response, real_name, name, is_available, concentration, qty, reorder_point, manufacture_lot,
            expiration_date, open_date, quantity, scrap_factor, notes, multiSelectData, serving_size, shelf, storage_location,
            manufacture, receive_date, unit, supplement } = this.state;

        return (
            <React.Fragment>
                {is_page_loading ? <CustomLoader /> : ""}
                <br />

                <Grid.Row />
                <Segment className="transparent" id="transparent" style={{ display: "block" }}>
                    {is_waiting_for_response && (
                        <CustomLoader loading_text="Saving Inventory Data" />
                    )}
                    <Form>
                        <Grid columns="equal">
                            <CustomDivider
                                h_size="h3"
                                title="Prebiotic Information"
                                icon_name="dna"
                            />

                            <Grid.Row>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Display Name</label>}
                                            content="Enter the Display Name."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="name"
                                            value={name}
                                            onChange={this.onInputChange}
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Display Name"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Internal Name</label>}
                                            content="Enter the Internal Name."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="real_name"
                                            onChange={this.onInputChange}
                                            value={real_name}
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Internal Name"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Custom_DropDown
                                        label={"Is Available"}
                                        content={"Is Organism available ?"}
                                        onChange={this.changeAvailablity}
                                        isAvailable={this.props.isAvailable}
                                        is_available={"is_available"}
                                        classNames={"is-available opacity-1"}
                                        dropDownValue={this.state.is_avaialble_value}
                                        inventory={this.props.inventory}
                                    />

                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Concentration</label>}
                                            content="Enter the concentration of the Ingredient."
                                        />
                                        <Input
                                            name="concentration"
                                            onChange={this.onInputChange}
                                            value={concentration}
                                            placeholder="Concentnration"
                                            label={{ content: "B CFU/g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Quantity</label>}
                                            content="Enter the Ingredient Quantity."
                                        />
                                        <Input
                                            name="qty"
                                            onChange={this.onInputChange}
                                            value={qty}
                                            placeholder="Quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Reorder Point</label>}
                                            content="Enter the Reorder Point."
                                        />
                                        <Input
                                            className="full_opacity_red"
                                            name="reorder_point"
                                            onChange={this.onInputChange}
                                            value={reorder_point}
                                            placeholder="Reorder Point"
                                            icon="weight"
                                            iconPosition="left"
                                            label={{ content: "g" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Scrap Factor</label>}
                                            content="Enter the Ingredient Scrap Factor."
                                        />
                                        <Input
                                            name="scrap_factor"
                                            onChange={this.onInputChange}
                                            value={scrap_factor}
                                            placeholder="Scrap Factor"
                                            label={{ content: "%" }}
                                            labelPosition="right"
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Popup
                                        trigger={<label>Notes</label>}
                                        content="Enter notes."
                                    />
                                    <TextArea
                                        name="notes"
                                        onChange={this.onInputChange}
                                        value={notes}
                                        placeholder="Add Notes here..."
                                        style={{ minHeight: 100 }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row >
                                <Grid.Column width={4}>
                                <div>Multi-V Availability</div>
                                <Radio
                                toggle
                                className="allergen-radio-btn"
                                onClick={()=>this.multi_v_availablity_fn('radio',this.state.multi_v)}
                                checked={(this.state.multi_v == "available")?true:false}
                                />
                                </Grid.Column>
                                {(this.state.multi_v == "available")&& (
                                <>
                                <Grid.Column  width={6}>
                                    <Custom_DropDown
                                            label={"Ingredient Type"}
                                            content={"Ingredient type."}
                                            onChange={(e)=>this.multi_v_availablity_fn("ingredient_type",e)}
                                            isAvailable={isAvailable_lower}
                                            is_available={"is_available"}
                                            classNames={"is-available"}                
                                            dropDownValue={this.state.ingredient_type}
                                            inventory={inventory}
                                        />
                                </Grid.Column>
                                <Grid.Column  width={6}>
                                        <span>Price per gram </span>
                                        <Input
                                        name="price_per_gram"
                                        type="number"
                                        fluid
                                        value={this.state.multi_v_gram}
                                        placeholder="Price per gram"
                                        onChange={(e)=>{ this.multi_v_availablity_fn('multi_v_gram',e.target.value) }}
                                    />
                                </Grid.Column></>)}
                            </Grid.Row >



                            {/* <CustomDivider
                                h_size="h3"
                                title="Benefits"
                                icon_name="cart plus"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field>
                                        <Popup
                                            trigger={<label>Benefits</label>}
                                            content="Benefits."
                                        />
                                        <Multiselect
                                            options={multiSelectData}
                                            onSelect={this.onSelect}
                                            onRemove={this.onRemove}
                                            ref={this.multiselectRef}
                                            displayValue="name"
                                            closeOnSelect={false}
                                            placeholder="Select Benefits"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row> */}

                            <CustomDivider
                                h_size="h3"
                                title="Allergens"
                                icon_name="tint"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Grid.Column >
                                        <Form.Field>
                                            <Popup
                                                trigger={<label>Add Allergens</label>}
                                                content="Allergean."
                                            />
                                            <Multiselect
                                                options={this.state.allergeanData}
                                                onSelect={this.onSelectAllergen}
                                                selectedValues={this.state.allergeanList}
                                                onRemove={this.onRemoveAllergean}
                                                ref={this.multiselectRef}
                                                displayValue="name"
                                                placeholder="Select Allergens"
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                </Grid.Column>
                            </Grid.Row>




                            <CustomDivider
                                h_size="h3"
                                title="Supplements"
                                icon_name="cart plus"
                            />

                            <Grid.Row className="block">
                                <Grid.Column width={5}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Serving Size</label>}
                                            content="Supplements."
                                        />
                                        <Input
                                            id="NumericTextBox"
                                            type="number"
                                            style={{ opacity: 1 }}
                                            name="serving_size"
                                            icon="weight"
                                            iconPosition="left"
                                            placeholder="Serving Size"
                                            onChange={this.onInputChangeServingSize}
                                            value={serving_size}
                                        />

                                    </Form.Field>
                                </Grid.Column>

                            </Grid.Row>

                            {this.state.inventory &&
                                this.state.inventory.map((elem, index) => {
                                    elemAmountPergram = elem.quantity / serving_size;
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid.Row className="block">
                                                <Grid.Column width={11}></Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row className="block" >
                                                <Grid.Column width={5}>
                                                    <Form.Field required fluid="true">
                                                        <Popup
                                                            trigger={<label>Supplement</label>}
                                                            content="Enter the Ingredient Barcode."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1, backgroundColor: "rgb(184, 252, 255)" }}
                                                            value={elem.supplement}
                                                            disabled
                                                        >
                                                        </Input>
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Per Serving</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            style={{ opacity: 1, width: "100%", }}
                                                            icon="weight"
                                                            iconPosition="left"
                                                            label={elem.unit}
                                                            labelPosition="right"
                                                            value={Math.floor(elem.quantity * 1000) / 1000}
                                                            disabled
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column width={5}>
                                                    <Form.Field required fluid="true" >
                                                        <Popup
                                                            trigger={<label>Amount Per Gram</label>}
                                                            content="Enter the amount added."
                                                        />
                                                        <Input
                                                            icon="weight"
                                                            iconPosition="left"
                                                            label={elem.unit}
                                                            labelPosition="right"
                                                            placeholder="Amount Per Gram"
                                                            value={elemAmountPergram.toFixed(3)}
                                                            disabled
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>


                                                <Grid.Column width={1}>
                                                    <Form.Field fluid="true">
                                                        <label style={{ visibility: "hidden" }}>.</label>
                                                        <Popup
                                                            trigger={
                                                                <Button
                                                                    icon="delete"
                                                                    negative
                                                                    circular
                                                                    onClick={() => this.delete(index, elem)}
                                                                />
                                                            }
                                                            content="Delete this Organism."
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    )
                                })
                            }

                            <CustomDivider
                                h_size="h3"
                                title="Add Supplements"
                                icon_name="plus"
                            />
                            <Grid.Row className="block">
                                <Grid.Column width={5}>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Supplements</label>}
                                            content="Supplements."
                                        />
                                        <input list="supplements"
                                            style={{ opacity: 1, backgroundColor: "#b8sfcff" }}
                                            value={supplement}
                                            onChange={(e) => this.onInputChangeSupplement(this.state.all, e.target.value)}
                                        ></input>
                                        <datalist
                                            id="supplements"
                                        >
                                            {
                                                this.state.all && this.state.all[0] && this.state.all[0].map((elem, index) => {
                                                    return (
                                                        <option key={index}>
                                                            {elem.supplement}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </datalist>
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column width={5}>
                                    <Form.Field required fluid="true" >
                                        <Popup
                                            trigger={<label>Amount Per Serving</label>}
                                            content="Enter the amount added."
                                        />
                                        <Input
                                            id="amount_added"
                                            type="number"
                                            style={{ opacity: 1 }}
                                            name="quantity"
                                            icon="weight"
                                            iconPosition="left"
                                            label={unit}
                                            labelPosition="right"
                                            placeholder="Amount Per Serving"
                                            onChange={this.onInputAmountChange}
                                            value={quantity}
                                        // pattern="^\d*(\.\d{0,3})?$"
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column width={5}>
                                    <Form.Field required fluid="true" >
                                        <Popup
                                            trigger={<label>Amount Per Gram</label>}
                                            content="Enter the amount added."
                                        />
                                        <Input
                                            label={unit}
                                            type="number"
                                            style={{ opacity: 1 }}
                                            name="amount_per_gram"
                                            icon="weight"
                                            iconPosition="left"
                                            labelPosition="right"
                                            placeholder="Amount Per Gram"
                                            onChange={this.onInputChange}
                                            value={parseFloat(this.state.amount_per_gram).toFixed(3)}
                                            disabled
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column style={{ textAlign: "center" }}>
                                    <Popup
                                        trigger={
                                            <Button
                                                animated="vertical"
                                                className="small"
                                                color="blue"
                                                onClick={this.onAddOrganismClick}
                                            >
                                                <Button.Content visible>
                                                    <Icon name="plus circle" />
                                                    Insert
                                                </Button.Content>
                                                <Button.Content hidden>
                                                    More&nbsp;
                                                    <Icon name="arrow right" />
                                                </Button.Content>
                                            </Button>
                                        }
                                        content="Add more Organism(s)."
                                    />
                                </Grid.Column>
                            </Grid.Row>


                            <CustomDivider
                                h_size="h3"
                                title="Manufacturer Information"
                                icon_name="industry"
                            />
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Receive Date</label>}
                                            content="Enter the Ingredient Receive Date."
                                        />
                                        <DatePicker
                                            selected={receive_date}
                                            placeholderText="Click to select a date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(receive_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.receive_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Open Date</label>}
                                            content="Enter the Ingredient Open Date."
                                        />

                                        <DatePicker
                                            selected={open_date}
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(open_date)}
                                            onChange={date => {
                                                this.onDateChange(Config.inventory.open_date, date);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Expiration Date</label>}
                                            content="Enter the Ingredient Expiration Date."
                                        />
                                        <DatePicker
                                            placeholderText="Click here to select date"
                                            todayButton={"Current Date"}
                                            dateFormat="MM/dd/yyyy"
                                            selected={expiration_date}
                                            isClearable={true}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            openToDate={new Date(Config.default_calendar_date)}
                                            onChange={date => {
                                                this.onDateChange(
                                                    Config.inventory.expiration_date,
                                                    date
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer</label>}
                                            content="Enter the Manufacturer of the Ingredient."
                                        />
                                        <Input
                                            placeholder="Manufacturer"
                                            icon="building"
                                            iconPosition="left"
                                            name="manufacture"
                                            onChange={this.onInputChange}
                                            value={manufacture}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field required fluid="true">
                                        <Popup
                                            trigger={<label>Manufacturer Lot</label>}
                                            content="Enter the Manufacturer Lot of the Ingredient."
                                        />
                                        <Input
                                            name="manufacture_lot"
                                            onChange={this.onInputChange}
                                            value={manufacture_lot}
                                            placeholder="Manufacturer Lot"
                                            icon="hashtag"
                                            iconPosition="left"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />

                            <CustomDivider
                                h_size="h3"
                                title="Storage Information"
                                icon_name="dropbox"
                            />

                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Storage Location</label>}
                                            content="Enter the Ingredient Storage Location (Fridge Number)."
                                        />
                                        <Input
                                            placeholder="Storage Location (Fridge Number)"
                                            icon="boxes"
                                            iconPosition="left"
                                            name="storage_location"
                                            onChange={this.onInputChange}
                                            value={storage_location}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column>
                                    <Form.Field fluid="true">
                                        <Popup
                                            trigger={<label>Shelf</label>}
                                            content="Enter the Ingredient Shelf Number."
                                        />
                                        <Input
                                            placeholder="Shelf"
                                            icon="box"
                                            iconPosition="left"
                                            name="shelf"
                                            onChange={this.onInputChange}
                                            value={shelf}
                                            type="text"
                                            onKeyPress={this.AnswerKeyPress}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                            <CustomDivider
                                h_size="h3"
                                title="Upload Documents"
                                icon_name="upload"
                            />
                            <Grid.Column>
                                <FilePond
                                    acceptedFileTypes={['application/pdf']}
                                    ref={ref => (this.pond = ref)}
                                    allowMultiple={true}
                                    allowReorder={true}
                                    allowRemove={true}
                                    maxFiles={10}
                                    allowFileSizeValidation={true}
                                    maxFileSize="5MB"
                                    onaddfile={() => this.onupdatefiles(this.pond.getFiles())}
                                    onremovefile={this.handleRemove}
                                    checkValidity={true}
                                    dropValidation
                                />
                            </Grid.Column>


                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row />
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid textAlign="center">
                                        <Button.Group>
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onSubmitClick} positive>
                                                        Submit
                                                    </Button>
                                                }
                                                content="Submit the Ingredient Information to the Database."
                                            />
                                            <Button.Or />
                                            <Popup
                                                trigger={
                                                    <Button onClick={this.onCancelClick}>Cancel</Button>
                                                }
                                                content="Discard the Ingredient Informtaion."
                                            />
                                        </Button.Group>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row />
                            <Grid.Row />
                        </Grid>
                    </Form>
                </Segment>

            </React.Fragment>
        );
    }
}

export default CreatePrebiotic;