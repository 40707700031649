import labelImg from '../../../images/label.jpg'
import qrBar from '../../../images/qr-bar.jpg'
import qrCode from '../../../images/qr-code.jpg'
import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders, loadImage } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input } from 'semantic-ui-react';
import { moment } from "moment";
import QRCode from "react-qr-code";
var Barcode = require('react-barcode');


class LabelGeneration extends Component {
    state = {label_data:{},step_current:{},servings_per_container:'',pro_name:[],calories:{},calculation:[],other_calculation:[],note:"",pre_names:"",is_capsule:false,allergy_note:"",allergy_note_2:"",customer:{},best_before:"",made_date:"",product_type:"",pin:"",user:"",is_probiotic:false,pinError:false}
    componentDidMount(){
        this.getCurrentStep('label_generation');
        this.labelGeneration();
    }

    getCurrentStep=(step_name)=>{
        let req = {
          formula_id : this.props.selected_row_value.formula_id,
          step_name:step_name
        }
        axios.post(baseURL+"/get-current-step",req, getHeaders())
          .then(res=>{
            let result = res.data;       
            console.log(result);
            if(result.success === true && result.current_step !== null){
              let current_step = result.current_step;
              current_step.step_json = JSON.parse(current_step.step_json);             
              this.setState({step_current : current_step});
              this.onInputChange(null,{name:"pin","value":current_step.performed_by})
             
            }
        })
    }

    labelGeneration=()=>{
        let req = {
            sample_id : this.props.selected_row_value.sample_id,
            formulation_id:this.props.selected_row_value.id
          }
          axios.post(baseURL+"/label-generation",req, getHeaders())
            .then(res=>{
              let result = res.data;   
              if(result.success === true && result.label !== null){
                let label = JSON.parse(result.label);   
                if(label[0]){
                    let data = label[0].data
                    console.log(data);
                    this.setState({label_data : label[0]});              
                    this.setState({servings_per_container : data.servings_per_container});
                    this.setState({pro_name : data.pro_names});
                    this.setState({calories : data.calories[0]});
                    this.setState({calculation : data.calculation});
                    this.setState({other_calculation : data.other_calculation});
                    this.setState({note : data.note});
                    if(data.pre_names){
                        this.setState({pre_names : data.pre_names.join(',')});
                    }                
                    this.setState({is_capsule : data.is_capsule});
                    this.setState({allergy_note : data.allergy_note});
                    this.setState({allergy_note_2 : data.allergy_note_2});
                    this.setState({customer : data.user});
                    this.setState({product_type : data.display_product_type});
                    this.setState({best_before : data.best_before!== "" ?data.best_before:moment().add(1, 'years').format('MM/DD/YYYY')});
                    this.setState({made_date : data.date_made !== "" ?data.date_made:moment().format('MM/DD/YYYY')});
                    if(this.props.selected_row_value.is_prebiotic == 'No'){
                        this.setState({is_probiotic:true});
                    }
                }                             
                
              }
          })

    }

    onInputChange = (e, { name, value }) => {
        this.setState({ [name]: value });        
         if( value.length >= 3){
            axios.post(baseURL+"/users/getUserByPin",{pin:value}, getHeaders())
            .then(res=>{               
                if(res.data.success === true){
                    let user = res.data.user;                    
                    this.setState({                        
                        user: user?user:"",
                        pinError:user?false:true
                    }); 
                    //this.setState({user_full_name:user.full_name}); 
                }
            })
        }else{
            this.setState({                        
                user:"",
                pinError:true
            }); 
        }   
        
    };

    

    handleSubmit = ()=>{
        const { pin,user } = this.state;
        this.setState((prevState) => ({  
            pinError: user?false:true,
        }));


        if (!this.state.pin) {
            toast.error("PIN is missing");
            return;
        }
        
        if (Object.keys(this.state.user).length == 0) {
            toast.error("PIN is incorrect");
            return;
        }
        let param = {
            sample_id : this.props.selected_row_value.sample_id,
            formula_id : this.props.selected_row_value.formula_id,
            step:4,
            step_json:"",
            step_name:'label_generation',
            step_next:'operator_mmr_review',
            performed_by:pin
          }
          axios.post(baseURL+"/mmr-formulations",param, getHeaders())
        .then(res=>{               
          let result = res.data;                   
          if(result.success === true){
              toast.success("Successfully saved Step");
              this.props.onNextStep();
          }else{
            toast.error("Step has not saved");
          }
        })
    }
    handleClick = () => {       
        // Call the function passed from the parent
        this.props.onPrevClick('production_line_checkout');

    };

    handlePauseClick = ()=>{
    this.props.onNextClick('pause');
  }
  handleQuitClick = ()=>{
    this.props.onNextClick('quit');
  }

  handleNextClick = () => {     
        // Call the function passed from the parent
        this.props.onNextClick('operator_mmr_review');        
    };


    render(){          
        return (
            <> 
             <Form onSubmit={this.handleSubmit}>      
                <div className='cs-box'>
                    <div className='cs-body'>
                        <h3>Label Generation</h3>
                        <div className='mt-2 row'>
                            <div className='col-md-6 border-r'>
                                <div className='cs-label mb-3'>Print Dymo labels for formulation: </div><br/>
                                <div className='cs-label mb-3'>Find formulation information on formulation.sungenomics.com. </div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                {this.state.is_probiotic?(
                                <><div className='cs-label mb-3'>Sachet labels: Follow <em>SOP_033 Labeling Standard.</em> </div><br /><div className='cs-label mb-3'>Print 11 sachet labels (can print 10 and 1 in bulk for easier log application)</div><br /><br /><div className='cs-label mb-3'>Box labels: Following <em>SOP_033 Labeling Standards.</em> </div><br /><br /><div className='cs-label mb-3'>Print 1 box label</div><br /><br /><br /></>):(
                                    <><div className='cs-label mb-3'>1 Box label for every month of prebiotic being
                                    formulated: Following <em>SOP_033 Labeling Standard.</em> </div><br /><div className='cs-label mb-3'>Print 1 box label per month of prebiotic being formulated plus an additional 2 for in process pouches</div><br /><br /><br /><br /><br /><br /><br /><div className='cs-label mb-3'>Print 1 nutrition label from formulation site</div><br /></>)}
                                <div className='cs-label mb-3'>Note: Multiple Dymo labels can be printed at
                                once to assemble multiple formulation
                                preps at once, however all extra labels are
                                to be disposed of once packaging is
                                complete. No loose labels are to be left
                                over at the end of the workday.</div><br/><br/>
                            </div>
                           
                            {this.state.is_probiotic &&(
                            <div className='col-md-6'>
                                <img src={labelImg} alt='label'/>
                            </div>
                            )}

                            {!this.state.is_probiotic &&(
                            <>
                                <div className='col-md-6'>
                                    <img className='mxw-100' src={loadImage("step4/3.jpg")} alt='img' />
                                    <br/>
                                    <img className='mxw-100' src={loadImage("step4/1.jpg")} alt='img' />
                                    <br/>
                                    <img className='mxw-100' src={loadImage("step4/2.jpg")} alt='img' />
                                </div>
                                
                            </>
                            )}
                           
                        </div>
                        <div className='mt-5'>
                            <h3>Representative Labels</h3>
                            <h4 className='text-center'>Supplement Facts Label</h4>
                            <div className='sf-box'>
                                <div className='sf-inner-box'>
                                    <div className='sf-heading1'>Supplement Facts</div>
                                    <div className='sf-heading2'>Serving Size 1 capsule (573mg)</div>
                                    <div className='sf-heading2'>Servings Per Containers {this.state.servings_per_container}</div>
                                    <div className='sf-table'>
                                    {/* ---Headers---- */}
                                        <table className='w-100' cellPadding={0} cellSpacing={0}>
                                            <tr>
                                                <th></th>
                                                <th>Amount Per Serving</th>
                                                <th>%DV*</th>
                                            </tr>
                                            <tbody>
                                            {
                                                this.state.pro_name.length > 0  && this.state.pro_name &&(<tr>
                                                    <td>Probiotic Blend</td>
                                                    <td>{this.state.label_data.data.total_pro_amount}g ({this.state.label_data.data.probiotic_blend} Billing CFU✝)</td>
                                                    <td></td>
                                                    </tr>)

                                                

                                            }
                                            {/* -- Display PRO Ingredients -- */}        
                                        {
                                            
                                            this.state.pro_name.length > 0  && this.state.pro_name.map((item)=>{
                                                return(
                                                    <tr>
                                                        <td>{item}</td>
                                                        <td></td>
                                                        <td>**</td>
                                                    </tr>
                                                    
                                                    )
                                                })
                                            }

                                            {/* -- Display Calories -- */}                                        
                                            {
                                            Object.keys(this.state.calories).length > 0  && this.state.calories.total_value > 0 && (
                                                <tr>
                                                    <td>{this.state.calories.supplement}</td>
                                                    <td>{this.state.calories.total_value > 0 ?this.state.calories.total_value:""}</td>
                                                    <td></td>
                                                </tr>

                                                )
                                            }
                                            {/* -- Display PRE Ingredients -- */}
                                            {/* -- ------------ Level 1 ------------ -- */}
                                            {
                                                Object.keys(this.state.calculation).length > 0  && this.state.calculation && this.state.calculation.map((item)=>{
                                                    var dv_value = item.dv_value > 0 && item.dv_value !== '**'?item.dv_value+"%":"";
                                                    return(
                                                        item.total_value > 0 && (item.supplement === 'Total Fat' || item.supplement === 'Sodium' || item.supplement === 'Cholesterol' || item.supplement === 'Total Carbohydrates') &&(
                                                    <tr>
                                                        <td>{item.supplement}</td>
                                                        <td>{item.total_value } {item.unit}</td>
                                                        <td>{dv_value}</td>
                                                    </tr>)

                                                    )
                                                })
                                            }

                                            {/* -- ------------ Level 2 ------------ -- */}
                                            {
                                                Object.keys(this.state.calculation).length > 0  && this.state.calculation && this.state.calculation.map((item)=>{
                                                    var dv_value = item.dv_value > 0 && item.dv_value !== '**'?item.dv_value+"%":"";
                                                    return(
                                                        item.total_value > 0 && (item.supplement === 'Total Fat' || item.supplement === 'Saturated Fat' || item.supplement === 'Dietary Fiber') &&(
                                                    <tr>
                                                        <td>{item.supplement}</td>
                                                        <td>{item.total_value } {item.unit}</td>
                                                        <td>{dv_value}</td>
                                                    </tr>)

                                                    )
                                                })
                                            }

                                            {/* -- ------------ Level 3 ------------ -- */}
                                            {
                                                Object.keys(this.state.calculation).length > 0  && this.state.calculation && this.state.calculation.map((item)=>{
                                                    var dv_value = item.dv_value > 0 && item.dv_value !== '**'?item.dv_value+"%":"";
                                                    return(
                                                        item.total_value > 0 && (item.supplement === 'Total Sugars' || item.supplement === 'Including Added Sugars') &&(
                                                    <tr>
                                                        <td>{item.supplement}</td>
                                                        <td>{item.total_value } {item.unit}</td>
                                                        <td>{dv_value}</td>
                                                    </tr>)

                                                    )
                                                })
                                            }
                                            {/* -- ------------ Others (Split) ------------ -- */}

                                            {
                                                Object.keys(this.state.calculation).length > 0  && this.state.calculation && this.state.calculation.map((item)=>{
                                                    var dv_value = item.dv_value > 0 && item.dv_value !== '**'?item.dv_value+"%":"";
                                                    return(
                                                        item.total_value > 0 && item.supplement_type === 'nutrient' && (item.supplement !== 'Cholesterol' && item.supplement !== 'Total Carbohydrates' && item.supplement !== 'Total Sugars' && item.supplement !== 'Dietary Fiber' && item.supplement !== 'Including Added Sugars' && item.supplement !== 'Total Fat' && item.supplement !== 'Sodium' && item.supplement !== 'Saturated Fat' && item.supplement !== 'Trans Fat') &&(
                                                    <tr>
                                                        <td>{item.supplement}</td>
                                                        <td>{item.total_value } {item.unit}</td>
                                                        <td>{dv_value}</td>
                                                    </tr>)

                                                    )
                                                })
                                            }

                                            {
                                                Object.keys(this.state.calculation).length > 0  && this.state.calculation && this.state.calculation.map((item)=>{
                                                    var dv_value = item.dv_value > 0 && item.dv_value !== '**'?item.dv_value+"%":"";
                                                    return(
                                                        item.total_value > 0 && item.supplement_type !== 'nutrient' && (item.supplement !== 'Cholesterol' && item.supplement !== 'Total Carbohydrates' && item.supplement !== 'Total Sugars' && item.supplement !== 'Dietary Fiber' && item.supplement !== 'Including Added Sugars' && item.supplement !== 'Total Fat' && item.supplement !== 'Sodium' && item.supplement !== 'Saturated Fat' && item.supplement !== 'Trans Fat') &&(
                                                    <tr>
                                                        <td>{item.supplement}</td>
                                                        <td>{item.total_value } {item.unit}</td>
                                                        <td>{dv_value}</td>
                                                    </tr>)

                                                    )
                                                })
                                            }
                                            {/* -- ------------ Nutrients w/o RDI DRV ------------ -- */}
                                            {
                                                this.state.other_calculation.length > 0  && this.state.other_calculation.map((item)=>{
                                                    return(
                                                        item.total_value > 25 &&(
                                                        <tr>
                                                            <td>{item.supplement}</td>
                                                            <td>{item.total_value} {item.unit}</td>
                                                            <td>**</td>
                                                        </tr>)
                                                        
                                                        )
                                                    })
                                            }  
                                            </tbody>                                     
                                        </table>
                                    </div>
                                    <div className='sf-heading3'>
                                        {
                                            this.state.note && this.state.note !=="" &&(<span>*{this.state.note}<br/></span>)
                                        }                            
                                        ** Daily Value (DV) not established<br/>
                                        ✝At the time of manufacture
                                    </div>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div className='cs-w-60'>
                                        {
                                            this.state.pre_names !=="" && ( <div className='sf-heading3 p-0 mb-1'>Ingredients: {this.state.pre_names}</div>)
                                        }
                                        {
                                            this.state.is_capsule === true &&( <div className='sf-heading3 p-0 mb-1'>Other Ingredients: Vegan Capsule (HPMC and Water)</div>)
                                        }                                   
                                    
                                        <div className='sf-heading3 p-0 mb-1'><strong>Warning:</strong> consult your physician before using any product if you are pregnant, nursing, trying to conceive, taking medication or have a medical condition.</div>
                                        {
                                            this.state.allergy_note !== "" && (<div className='sf-heading3 p-0 mb-1'><strong>{this.state.allergy_note} {this.state.allergy_note_2}</strong></div>)
                                        }

                                        
                                    </div>
                                    <div className='cs-w-40'>
                                        <div className='sf-right-box mb-1'>
                                            {
                                                this.state.customer && Object.keys(this.state.customer).length > 0 &&  (
                                                    <div className='sf-heading3 p-0 text-center'>
                                                        <div>{this.state.customer.sg_number}</div>
                                                        <div>{this.state.customer.formula_id}</div>
                                                        <div><strong>Best if Used By:</strong></div>
                                                        <div>{this.state.best_before}</div>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                        <div className='sf-right-box'>
                                            <div className='sf-heading3 p-0 text-center'>
                                                Manufactured by Floré <br/>
                                                5151 Shoreham Pl <br/>
                                                San Diego, CA 92122, <br/>
                                                USA
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4 className='text-center'>Pill Sachet Label</h4>
                            {
                                this.state.customer && Object.keys(this.state.customer).length > 0 &&  (
                                    <div className='ps-box'>
                                        <div className='sf-heading2 text-center'>{this.state.customer.formula_id} DOM: {this.state.made_date}</div>
                                        <div className='d-flex align-items-center mb-1'>
                                            <div className='ps-heading1 w-50'>{this.state.customer.sg_number}</div>
                                            <div className='w-50'>
                                                <div className='ps-heading2 mb-1'>{this.state.customer.customer_name}</div>
                                                <div className='ps-heading2'>{this.state.product_type}</div>
                                            </div>
                                        </div>
                                        <div className='mt-1 text-center'>
                                            {/* <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={this.state.customer.sg_number}
                                            viewBox={`0 0 256 256`}
                                            /> */}
                                            <Barcode 
                                                value={this.state.customer.formula_id?this.state.customer.formula_id:""}
                                                height="40"
                                                displayValue={false} 
                                            />
                                        </div>
                                    </div>

                                )
                            }
                            
                        </div>
                        <div className='mt-5'>
                            <h4 className='text-center'>Rigid Pill Box Label</h4>
                            <div className='rpb-box'>
                                <div className='d-flex align-items-center mb-1'>
                                {
                                this.state.customer && Object.keys(this.state.customer).length > 0 &&  (
                                    <div className='pe-1'>
                                        <div className='ps-heading1 mb-1'>{this.state.customer.customer_name}</div>
                                        <div className='ps-heading2 mb-1'>WO: {this.state.customer.sg_number} {this.state.customer.formula_id}</div>
                                        <div className='ps-heading3'>Exp: {this.state.best_before} (at 4 deg C) {this.state.product_type}</div>
                                        <div className='mt-1 text-center'>
                                            <Barcode 
                                                value={this.state.customer.formula_id?this.state.customer.formula_id:""}
                                                height="70"
                                                displayValue={false} 
                                            />
                                            
                                        </div>
                                    </div>
                                )}
                                    <div>
                                        <div className='text-center'>
                                            
                                         <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "96px", width: "100%" }}
                                            value={this.state.customer.formula_id?this.state.customer.formula_id:""}
                                            viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>Packaging Staging</h3>
                            <ul className="cs-list">
                                <li>Place labels on packaging materials</li>
                                {this.state.is_probiotic && (
                                <>
                                    <li>
                                    Ensure rigid pill box labels include customer SG
                                    identified label and nutrition label
                                    </li>
                                    <li>
                                    Ensure sachets labels include fmrx and SG identifier label
                                    </li>
                                </>
                                )}
                                {!this.state.is_probiotic && (
                                <li>
                                    Ensure tearaway box labels include customer SG identified
                                    label and nutrition label.
                                </li>
                                )}
                            </ul>
                        
                    
                        <div className='text-center mt-5'>
                            <div className="d-inline-flex">
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.user.full_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                    <Input
                                        error={this.state.pinError}
                                        id="pin"
                                        name="pin"
                                        onChange={this.onInputChange}
                                        value={this.state.pin}
                                        placeholder="Enter Your PIN"
                                        type="password"
                                        className="full_opacity"
                                        autoComplete="new-password"
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                    <div className='cs-footer text-center'>
                        <hr />
                        <div className='text-center mt-2'>
                            <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                            <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                            <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                            { this.props.step_name === 'production_line_checkout' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                            { this.props.step_name !== "production_line_checkout" && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}                        
                        </div>
                    </div>
                </div>
            </Form>     
            </>
          )
    }
}

export default LabelGeneration

