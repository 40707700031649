import React from 'react';

import {
  Icon,
  Grid,
  Button
} from "semantic-ui-react";

import "./pdf.css";

export default (props) => {
  const bodyRef = React.createRef();
  const createPdf = () => props.createPdf(bodyRef.current);
  // const print = () => window.print();
  return (
    <section style={{ display: "flex", justifyContent: "center" }}>
      <Grid>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <section className="pdf-body" ref={bodyRef}>
                {props.children}
              </section>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{ display: "flex", justifyContent: "center" }}>
              <Button
                icon
                className="download"
                id="downloadButton"
                labelPosition="right"
                onClick={createPdf}>
                Download PDF
                  <Icon name="download" />
              </Button>
            </Grid.Column>
            {/* <Grid.Column>
              <Button
                icon
                labelPosition="right"
                id="printButton"
                onClick={print}>
                Print
              <Icon name="print" />
              </Button>
            </Grid.Column> */}
          </Grid.Row>

        </Grid>
      </Grid>

    </section>

  )
}