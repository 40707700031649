import React, { Component, Fragment } from "react";
import axios from "axios";

import { getHeaders, onPageSizeChanged, resetSort, resetFilter } from "../../helper";
import { baseURL, page_size_options } from "../../../configuration/constants";
import Config from "../../../configuration/config";
import { onGridReady } from "../../helper";
import CustomLoader from "../../custom_elements/CustomLoader";

import { Message, Segment, Header, Grid, Button, Icon, Dropdown, Popup } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

let api, column_api;
const range = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment()
      .subtract(1, "month")
      .startOf("month"),
    moment()
      .subtract(1, "month")
      .endOf("month")
  ],
  "Last Year": [
    moment()
      .subtract(1, "year")
      .startOf("year"),
    moment()
      .subtract(1, "year")
      .endOf("year")
  ]
};


class MonthlyUsageInfo extends Component {
  state = {
    fourth: "",
    logsData: [],
    test: "",
    end: "",
    is_page_loading: true,
    fromDate: new Date().setDate(new Date().getDate() - 30),
    toDate: new Date().setDate(new Date().getDate()),

    columnDefs: [
      {
        headerName: "Ingredient",
        field: "organism_barcode",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 260
      },
      {
        headerName: "Days til run out",
        field: "days_untill_run_out",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 260,
        cellRenderer: (params) => {
          if (params.value == Infinity) {
            return (
              `
                  <div>
                   NA
                  </div>
                  `
            )
          }
          else {
            return (
              `
                  <div>
                  ${Math.floor(params.value * 1000)/1000}
                  </div>
                  `
            )
          }
        }
      },
      {
        headerName: "Total amount left in all the lots",
        field: "qty",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 260,
        cellRenderer: (params) => {
          return (
            `
                  <div>
                  ${Math.floor(params.value * 1000)/1000}
                  </div>
                  `
          )
        }
      },
      {
        headerName: "How much ingredient used?",
        field: "count",
        sizeColumnToFit: true,
        filter: "agTextColumnFilter",
        sortable: true,
        width: 260,
        cellRenderer: (params) => {
          return (
            `
                  <div>
                  ${Math.floor(params.value * 1000)/1000}
                  </div>
                  `
          )
        }
      }
    ],
  };
  componentDidMount = () => {
    document.title = Config.ai.title.monthlyUsageInfo_display;
    const data = {
      "start_date": "",
      "end_date": ""
    }
    axios
      .post(baseURL + "/inventory/monthly_usage_info", data, getHeaders())
      .then(response => {
        this.setState({
          logsData: response.data.data,
          is_page_loading: false
        })
      })
      .catch(error => {
        alert("Fetching Display Grid | Error: ", error);
      });

    let first = parseInt(new Date(this.state.fromDate).getMonth() + 1);
    let second = parseInt(new Date(this.state.fromDate).getDate());
    let five = first + "-" + second
    this.setState({
      fourth: five
    })
  }

  Apply = (event, picker) => {
    let month = picker.startDate._d.getMonth() + 1;
    let year = picker.startDate._d.getFullYear();
    let date = picker.startDate._d.getDate();

    let month_end = picker.endDate._d.getMonth() + 1;
    let year_end = picker.endDate._d.getFullYear();
    let date_end = picker.endDate._d.getDate();

    let start_date = year + "-" + month + "-" + date;
    let end_date = year_end + "-" + month_end + "-" + date_end;
    if (new Date(end_date) > new Date()) {
      toast.info("Invalid Date")
    }
    else {
      this.setState({
        fromDate: start_date,
        toDate: end_date,
        test: start_date,
        end: end_date,
        is_page_loading: true
      })
      const data = {
        "start_date": start_date,
        "end_date": end_date
      }
      let url = `/inventory/monthly_usage_info`;
      axios
        .post(baseURL + url, data, getHeaders())
        .then(res => {
          this.setState({
            is_page_loading: false,
            logsData: res.data.data,

          })
        })
    }
  }



  render() {

    return (
      <Fragment>

        <Segment>
          <Message>
            <Header as="h3" textAlign="center">
              {Config.ai.title.monthlyUsageInfo}
            </Header>
          </Message>
          {this.state.is_page_loading && <CustomLoader />}
          <Grid.Row>
            <Grid.Column>
              <Segment
                compact
                raised
                padded
                style={{ display: "block", marginTop: "0" }}>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column width={5} style={{ top: "5px", position: "relative" }}>
                      <Popup
                        trigger={<label>How much ingredient used between</label>}
                        content="How much ingredient used between"
                      />
                      <DateRangePicker
                        ranges={range}
                        alwaysShowCalendars={true}
                        onApply={this.Apply}
                      >
                        <button style={{ marginLeft: "10px" }}>
                          <Icon name="calendar alternate" />
                        </button>
                      </DateRangePicker>
                    </Grid.Column>
                    <Grid.Column>
                      <Dropdown
                        id="page-size"
                        placeholder="Page Size"
                        selection
                        options={page_size_options}
                        onChange={newPageSize => onPageSizeChanged(newPageSize, api)}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        basic
                        icon
                        onClick={() => resetSort(api)}
                        labelPosition="left"
                        style={{ position: "absolute", left: "51px" }}
                      >
                        <Icon name="sort alphabet up" />
                        Reset Sort(s)
                      </Button>
                    </Grid.Column>

                    <Grid.Column className="centerAlign">
                      <Button
                        basic
                        onClick={() => resetFilter(api)}
                        icon
                        labelPosition="left"
                      >
                        Reset Filter(s)
                        <Icon name="filter" />
                      </Button>
                    </Grid.Column>

                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <div
                        id="myGrid"
                        className="ag-theme-balham"
                        ag-grid="gridOptions"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}>
                        <AgGridReact
                          rowHeight="30"
                          rowSelection="single"
                          onCellClicked={this.RowClcked}
                          onGridReady={(params) => {
                            api = params.api;
                            api.showLoadingOverlay();
                            api.sizeColumnsToFit();
                            column_api =
                              params.column_api;
                            onGridReady(
                              params,
                              api,
                              column_api,
                              this.props
                                .initial_sort,
                            );
                          }}
                          animateRows={true}
                          pagination={true}
                          paginationPageSize={50}
                          paginationNumberFormatter={(
                            params,
                          ) => {
                            return (
                              "[" +
                              params.value.toLocaleString() +
                              "]"
                            );
                          }}
                          columnDefs={
                            this.state.columnDefs
                          }
                          floatingFilter={true}
                          rowData={this.state.logsData}
                          suppressPropertyNamesCheck={true}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Segment>

      </Fragment>
    )
  }
}

export default MonthlyUsageInfo;