/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { Form, Popup, Input, TextArea } from "semantic-ui-react";
import labelImg from "../../../images/step8-img.jpg";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders, loadImage } from "../../../helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class GatheringOfFlourishIngredients extends Component {
  state = {
    organisms: [],
    performed_by_pin: "",
    confirmed_by_pin: "",
    performed_by: "",
    performed_by_name: "",
    confirmed_by: "",
    confirmed_by_name: "",
    is_probiotic: false,
    is_multi_v: false,
    formErrors: {performed_by_pin: false,confirmed_by_pin:false}
  };

  constructor(props) {
    super(props);   
    this.inputRefs = []; // Array to store references to input elements
    
  }
  
  componentDidMount() {
    this.setState({ organisms: this.props.selected_row_value.organisms });
    this.getCurrentStep("gathering_of_flourish_ingredients");
    if (this.props.selected_row_value.is_prebiotic === "No") {
      this.setState({ is_probiotic: true });
    }    
    this.inputRefs = this.props.selected_row_value.organisms.map(() => React.createRef());
    
    
  }

  getCurrentStep = (step_name) => {
    let req = {
      formula_id: this.props.selected_row_value.formula_id,
      step_name: step_name,
    };
    axios.post(baseURL + "/get-current-step", req, getHeaders()).then((res) => {
      let result = res.data;
      console.log(result);

      if (result.success === true && result.current_step !== null) {
        let current_step = result.current_step;
        let step_json = JSON.parse(current_step.step_json);
        this.setState({ step_current: current_step });
        // this.onInputChange(0)(null, {
        //   name: "performed_by_pin",
        //   value: current_step.performed_by,
        // });
        // this.onInputChange(0)(null, {
        //   name: "confirmed_by_pin",
        //   value: current_step.confirmed_by,
        // });
        this.onInputChange(0)(null, {
          name: "operator_notes",
          value: step_json.operator_notes,
        });        
        step_json.organisms.map((org, i) => {
          this.state.organisms[i].barcode = org.barcode;
          this.state.organisms[i].validationErrors = 0;
          this.state.organisms[i].primary_operator = org.primary_operator;
          this.state.organisms[i].primary_operator_name = org.primary_operator_name;
          this.state.organisms[i].secondary_signoff = org.secondary_signoff;
          this.state.organisms[i].secondary_signoff_name = org.secondary_signoff_name;
        });
      } else {
        this.state.organisms = this.props.selected_row_value.organisms;
        this.state.organisms.map((org, i) => {
          this.state.organisms[i].barcode = "";
          this.state.organisms[i].validationErrors = 0;
          this.state.organisms[i].primary_operator = "";
          this.state.organisms[i].secondary_signoff = "";
          this.state.organisms[i].primary_operator_name = "";
          this.state.organisms[i].secondary_signoff_name = "";
          this.state.organisms[i].formErrors = {barcode:false,primary_operator:false,secondary_operator:false};
        });
      }
    });
  };

  handleClick = () => {
    // Call the function passed from the parent
    this.props.onPrevClick("processing");
  };

  handleNextClick = () => {
    // Call the function passed from the parent
    this.props.onNextClick("weighing_of_flourish_ingredients");
  };

  handlePauseClick = () => {
    this.props.onNextClick("pause");
  };
  handleQuitClick = () => {
    this.props.onNextClick("quit");
  };

  focusNextInput = (index) => {
    const nextIndex = index + 1;
    if (nextIndex < this.inputRefs.length) {
      this.inputRefs[nextIndex].current.focus();
    }
  };

  handleKeyPress = (event, index) => {
    if (event.key === 'Enter') {
      this.focusNextInput(index);
      event.preventDefault();
    }
    
  };

  onInputChange = (i) => (e, { name, value }) => {
    switch (name) {
      case "performed_by_pin":
        if (value.length >= 3) {
          if (this.state.confirmed_by_pin !== value) {
            this.getPerformUser(value, "performer");
          } else {
            toast.error("Please enter the another pin");
          }
        } else {
          this.setState({ performed_by: "", performed_by_name: "" });
        }
        break;
      case "confirmed_by_pin":
        if (value.length >= 3) {
          if (this.state.performed_by_pin !== value) {
            this.getPerformUser(value, "confirmer");
          } else {
            toast.error("Please enter the another pin");
          }
        } else {
          this.setState({ confirmed_by: "", confirmed_by_name: "" });
        }
        break;
      case "barcode":
        let organism_barcode = this.state.organisms[i].inventory.organism_barcode;
        let length = organism_barcode.length;
        if(value.length === length){
          if(!value.includes(organism_barcode)){
            toast.error("Please enter vaild barcode");
            this.state.organisms[i].barcode = "";           
            this.state.organisms[i].validationErrors = 1;  
          }else{
            this.state.organisms[i].barcode = value;  
            this.state.organisms[i].validationErrors = 0;  
          }
        }else{
          this.state.organisms[i].barcode = value;
        }
        break;
      case "primary_operator":
        this.state.organisms[i].primary_operator = value;        
        if (value.length >= 3) {
          if (this.state.organisms[i].secondary_signoff !== value) {
            this.getPerformUser(value, "primary_operator", i);
          } else {
            toast.error("Please enter another pin");
          }
        } else {
          this.state.organisms[i].primary_operator_name = "";
          this.setState((prevState)=>({...prevState.organisms,organisms:this.state.organisms }));
        }
        break;
      case "secondary_signoff":
        this.state.organisms[i].secondary_signoff = value;        
        if (value.length >= 3) {
          if (this.state.organisms[i].primary_operator !== value) {
            this.getPerformUser(value, "secondary_signoff", i);
          } else {
            toast.error("Please enter another pin");
          }
        } else {
          this.state.organisms[i].secondary_signoff_name = "";
          this.setState({ operator_name: "" });
        }
        break;
      default:
        break;
    }

    this.setState({ [name]: value });
  };

  getPerformUser = (pin, user_type, index = 0) => {
    if (!pin) {
      toast.error("Please fill pin number.");
      return;
    }
    axios
      .post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
      .then((res) => {
        if (res.data.success === true) {
          let user = res.data.user;
          switch (user_type) {
            case "performer":
              
              this.setState(prevState => ({
                  performed_by: user?user.id:"",
                  performed_by_name: user?user.full_name:"",
                  formErrors: {
                      ...prevState.formErrors,
                      performed_by_pin:user?false:true
                  }
              }));             
              break;
            case "confirmer":
              this.setState(prevState => ({
                  confirmed_by: user?user.id:"",
                  confirmed_by_name: user?user.full_name:"",
                  formErrors: {
                      ...prevState.formErrors,
                      confirmed_by_pin:user?false:true
                  }
              })); 
              
              break;
            case "primary_operator":
              this.state.organisms[index].primary_operator_name =
              user?user.full_name:"";
              this.state.organisms[index].formErrors.primary_operator =user?false:true;
              this.setState({ organisms: this.state.organisms });
              break;
            case "secondary_signoff":
              this.state.organisms[index].secondary_signoff_name =
                user?user.full_name:"";
              this.state.organisms[index].formErrors.secondary_operator =user?false:true;
              this.setState({ organisms: this.state.organisms });
              break;

            default:
              break;
          }

          //this.setState({user_full_name:user.full_name});
        }else{
          var formErrors = {};
          if(user_type === 'performer'){
              formErrors = {performed_by_pin: true};
          }else{
              formErrors = {confirmed_by_pin:true};                   
          }

          this.setState({formErrors});
      }
      });
  };

  handleSubmit = () => {
    let input_field = false;
    let vaild_barcode = false;
    const organisms = [];
    this.state.organisms.map((value,index) => {
      if (
        value.barcode === "" ||
        value.primary_operator === "" ||
        value.primary_operator_name === "" ||
        value.secondary_signoff === "" ||
        value.secondary_signoff_name === ""
      ) {
        input_field = true;        
        value.formErrors.primary_operator = value.primary_operator_name?false:true;
        value.formErrors.secondary_operator = value.secondary_operator_name?false:true;
        value.formErrors.barcode = value.barcode?false:true; 
      }else if(!value.barcode.includes(value.inventory.organism_barcode)){
        vaild_barcode = true;
        value.formErrors.barcode = vaild_barcode;
      }else{
        value.formErrors.barcode = false;
      }
      organisms.push(value);
    });
    this.setState((prevState) => ({            
      formErrors: {
          ...prevState.formErrors, // spread the existing formErrors
          // performed_by_pin: !this.state.performed_by_name?true:false,
          // confirmed_by_pin: !this.state.confirmed_by_name?true:false,
          organisms:organisms
      }
    }));

    if (input_field) {
      toast.error("Please complete all required field(s) ");
      return;
    }else if(vaild_barcode){
      toast.error("Please enter vaild barcode");
      return
    }
    

    /*  if (!this.state.operator_notes) {
            toast.error("Please fill all inputs.");
            return;
        } */

    // if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
    //   toast.error("PIN is missing");
    //   return;
    // }

    // if ((!this.state.performed_by_name && this.state.performed_by_pin) || 
    // (!this.state.confirmed_by_name && this.state.confirmed_by_pin) ) {
    //   toast.error("PIN is incorrect");
    //   return;
    // }
    
    

    let step_json = { operator_notes: this.state.operator_notes };
    let ingredient = [];
    this.state.organisms.map((org) => {
      ingredient.push({
        ingredient_barcode: org.inventory.organism_barcode,
        barcode: org.barcode,
        primary_operator: org.primary_operator,
        secondary_signoff: org.secondary_signoff,
        primary_operator_name: org.primary_operator_name,
        secondary_signoff_name: org.secondary_signoff_name,
      });
    });
    step_json.organisms = ingredient;
    let param = {
      sample_id: this.props.selected_row_value.sample_id,
      formula_id: this.props.selected_row_value.formula_id,
      step: 8,
      step_json: JSON.stringify(step_json),
      step_name: "gathering_of_flourish_ingredients",
      step_next: "weighing_of_flourish_ingredients",
      // performed_by: this.state.performed_by_pin,
      // confirmed_by: this.state.confirmed_by_pin,
    };
    axios
      .post(baseURL + "/mmr-formulations", param, getHeaders())
      .then((res) => {
        let result = res.data;
        if (result.success === true) {
          toast.success("Successfully saved Step");
          this.props.onNextStep();
        } else {
          toast.error("Step has not saved");
        }
      });
  };

  render() {
    console.log(this.state);
    const {formErrors} = this.state;
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <div className="cs-box">
            <div className="cs-body">
              <h3>Gathering of Flourish Ingredients</h3>
              <div className="mt-2 row">
                <div className="col-md-6 border-r">
                  <ul className="cs-list">
                    <li>Gather probiotics:</li>

                    <li>
                      Obtain formulation at
                      <br />
                      <a href="https://formulation.flore.com/" target="_blank">
                        <u>www.formulation.flore.com</u>
                      </a>{" "}
                      under "Display Formulations for Operator".
                    </li>
                    <li>
                      Highlight desired formulation and select "Start
                      Formulation".
                    </li>
                  </ul>
                  <br />
                  <br />
                  {this.state.is_probiotic && (
                    <>
                      <ul className="cs-list">
                        <li>
                          Sign into workstation on <em>FF_038 Line Checkout</em>
                        </li>
                        <li>
                          Mark "Line ID" of manufacture on Batch Record and QC
                          Checklist.
                        </li>
                        <li>Perform Pre-processing QC</li>
                        <li>Visually confirm piller is assembled correctly.</li>
                        <li>Visually confirm hood is clean.</li>
                        <li>
                          Visually confirm scale is operational and clean.
                        </li>
                        <li>
                          Visually Confirm formulation id matches with packaging
                          and online portal.
                        </li>
                        <li>
                          Visually confirm underpads are in place and clean.
                        </li>
                        <li>Ensure gloves are new and clean.</li>
                        <li>
                          Gather and visually confirm shaker cleanliness for use
                          in mixing.
                        </li>
                        <li>
                          Gather and visually confirm pill dropper cleanliness
                          for use in formulations.
                        </li>
                        <li>
                          Initial "Pre-process QC" on Batch Record and QC
                          Checklist.
                        </li>
                      </ul>
                      <br />
                      <br />
                      <ul className="cs-list">
                        <li>
                          Retrieve numbered probiotics called for in recipe from
                          refrigerator and place in hood by matching Ingredient
                          barcode to formulations portal.
                        </li>
                        <li>
                          Initial "Ingredient Pulling QC" field on Batch Record
                          and QC Checklist.
                        </li>
                        <li>
                          Have a secondary employee review and confirm pulling
                          of probiotics.
                        </li>
                        <li>
                          Secondary employee shall initial as the secondary
                          confirmation in "Ingredient Pulling QC".
                        </li>
                      </ul>
                    </>
                  )}
                  {!this.state.is_probiotic && (
                    <>
                      <ul className="cs-list">
                        <li>
                          Mark "Line ID of manufacture on Batch Record and QC
                          Checklist.
                        </li>
                        <li>Perform Pre-processing QC</li>
                        <li>Visually confirm hood is clean.</li>
                        <li>
                          Visually confirm scale is operational and clean.
                        </li>
                        <li>
                          Visually Confirm formulation id matches with packaging
                          and online portal.
                        </li>
                        <li>
                          Visually confirm underpads are in place and clean.
                        </li>
                        <li>Ensure gloves are new and clean.</li>
                        <li>
                          Gather and visually confirm shaker cleanliness for use
                          in mixing.
                        </li>
                      </ul>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <ul className="cs-list">
                        <li>
                          Retrieve numbered prebiotics called for in recipe from
                          refrigerator and place in hood by matching Ingredient
                          barcode to formulations portal.
                        </li>
                        <li>
                          Scan barcode of prompted ingredients into popup table
                        </li>
                        <li>
                          Have a secondary employee review and confirm pulling
                          of prebiotics.
                        </li>
                        <li>Secondary employee shall confirm via PIN</li>
                      </ul>
                    </>
                  )}
                </div>
                {!this.state.is_probiotic && (
                  <div className="col-md-6">
                    <img
                      className="w-100"
                      src={loadImage("step8-img2.jpg")}
                      alt="label"
                    />
                  </div>
                )}

                {this.state.is_probiotic && (
                  <div className="col-md-6">
                    <img
                      className="w-100"
                      src={loadImage("step8-img.jpg")}
                      alt="label"
                    />
                  </div>
                )}
              </div>
              <div className="mt-5">
                <h4>Formulation Ingredients</h4>
                <div className="custom-table custom-header-table">
                  <table className="w-100" cellPadding={0} cellSpacing={0}>
                    <tbody>
                      <tr>
                        <th>Ingredient Barcode</th>
                        <th>Scan of Barcode</th>
                        <th>Primary Operator</th>
                        <th>Secondary Signoff</th>
                      </tr>
                      
                      {this.state.organisms &&
                        this.state.organisms.map((org, i) => {
                          
                          return (
                            <tr>
                              <td>{org.inventory.organism_barcode}</td>
                              <td>                              
                                <div className="ui input">
                                  <Form.Field required fluid="true">
                                    <Input
                                      error={org.formErrors?org.formErrors.barcode:false}
                                      id={`barcode_${i}`}
                                      name="barcode"
                                      onChange={this.onInputChange(i)}
                                      value={org.barcode ? org.barcode : ""}
                                      ref={this.inputRefs[i]} 
                                      onKeyDown={(event) => this.handleKeyPress(event, i)}
                                      placeholder="Enter Scan of Barcode"
                                      type="text"
                                      className={org.validationErrors === 1 ? 'full_opacity form-control w-100 text-center error' : 'full_opacity form-control w-100 text-center'}
                                    />
                                  </Form.Field>
                                </div>
                              </td>
                              <td>
                                <div className="ui input">
                                  <Form.Field required fluid="true">
                                    <Input
                                      error={org.formErrors?org.formErrors.primary_operator:false}
                                      id={`primary_operator_${i}`}
                                      name="primary_operator"
                                      onChange={this.onInputChange(i)}
                                      value={
                                        org.primary_operator
                                          ? org.primary_operator
                                          : ""
                                      }
                                      placeholder="Enter Primary Operator"
                                      type="password"
                                      className="full_opacity form-control w-100 text-center"
                                    />
                                  </Form.Field>
                                </div>
                                {this.state.organisms[i]
                                  .primary_operator_name && (
                                  <div className="ui input">
                                    {
                                      this.state.organisms[i]
                                        .primary_operator_name
                                    }
                                  </div>
                                )}
                              </td>
                              <td>
                                <div className="ui input">
                                  <Form.Field required fluid="true">
                                    <Input
                                      error={org.formErrors?org.formErrors.secondary_operator:false}
                                      id={`secondary_signoff_${i}`}
                                      name="secondary_signoff"
                                      onChange={this.onInputChange(i)}
                                      value={
                                        org.secondary_signoff
                                          ? org.secondary_signoff
                                          : ""
                                      }
                                      placeholder="Enter Secondary Signoff"
                                      type="password"
                                      className="full_opacity form-control w-100 text-center"
                                    />
                                  </Form.Field>
                                </div>
                                {
                                    this.state.organisms[i].secondary_signoff_name &&(
                                        <div className="ui input">{this.state.organisms[i].secondary_signoff_name}</div>
                                    )  
                                }
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-5">
                <div>
                  <h4 className="m-0">Operator Notes:</h4>
                </div>
                <div>
                  <Form.Field required fluid="true">
                    <TextArea
                      id="operator_notes"
                      name="operator_notes"
                      onChange={this.onInputChange(0)}
                      value={this.state.operator_notes}
                      placeholder="Operator Notes"
                      className="form-control"
                    />
                  </Form.Field>
                </div>
              </div>
              {/* <div className="text-center mt-5">
                <div className="d-inline-flex">
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1">Performed By: </h4>
                    </div>
                    <div className="m-0 pe-1">
                      <h4 className="m-0">
                        <u>{this.state.performed_by_name}</u>
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1"> PIN</h4>
                    </div>
                    <div className="ui input">
                      <Form.Field required fluid="true">
                        <Input
                          error={formErrors.performed_by_pin}
                          id="performed_by_pin"
                          name="performed_by_pin"
                          onChange={this.onInputChange(0)}
                          value={this.state.performed_by_pin}
                          placeholder="Enter Your PIN"
                          type="password"
                          className="full_opacity"
                          autoComplete="new-password"
                        />
                      </Form.Field>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-2 mb-5">
                <div className="d-inline-flex">
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1">Confirmed By: </h4>
                    </div>
                    <div className="m-0 pe-1">
                      <h4 className="m-0">
                        <u>{this.state.confirmed_by_name} </u>
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1"> PIN</h4>
                    </div>
                    <div className="ui input">
                      <Form.Field required fluid="true">
                        <Input
                          error={formErrors.confirmed_by_pin}
                          id="confirmed_by_pin"
                          name="confirmed_by_pin"
                          onChange={this.onInputChange(0)}
                          value={this.state.confirmed_by_pin}
                          placeholder="Enter Your PIN"
                          type="password"
                          className="full_opacity"
                          autoComplete="new-password"
                        />
                      </Form.Field>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="cs-footer text-center">
              <hr />
              <div className="text-center mt-2">
                <button
                  type="button"
                  onClick={this.handleClick}
                  className="gray-btn"
                >
                  Prev
                </button>
                <button
                  type="button"
                  onClick={this.handlePauseClick}
                  className="ui icon secondary labeled button pause-btn ps-1-5em ms-2"
                >
                  Pause
                </button>
                <button
                  type="button"
                  onClick={this.handleQuitClick}
                  className="ui icon secondary labeled button quit-btn ps-1-5em ms-2"
                >
                  Quit
                </button>
                {this.props.step_name === "processing" && (
                  <button type="submit" className="theme-btn ms-2">
                    Next
                  </button>
                )}
                {this.props.step_name !== "processing" && (
                  <button
                    type="button"
                    onClick={this.handleNextClick}
                    className="theme-btn ms-2"
                  >
                    {" "}
                    Next{" "}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default GatheringOfFlourishIngredients;
