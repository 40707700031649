//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: StartFormulation                                    //
// Tasks: 1) Displays Modal for StartFormulation                     //
//        2) Manages PrimeInfo, FlourishInfo,                        //
//           FlourishOrganism, and SummaryInfo                       //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import {
  focusFirstElement,
  customPrint,
  getHeaders,
  getSessionStoredJsonItem,
  convertInventoryOptionsJSON
} from "../../helper";
import CustomLoader from "../../custom_elements/CustomLoader";
import { baseURL, PRINT_CATEGORIES } from "../../../configuration/constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, { Component } from "react";
import {
  Button,
  Icon,
  Modal,
  Segment,
  Grid,
  Form,
  Popup
} from "semantic-ui-react";
import axios from "axios";
import ShowModal from "../../Pop Up/Modal";
import MmrApproval from "./MmrSteps/MmrApproval";
import StepPause from "./MmrSteps/StepPause";
import StepQuit from "./MmrSteps/StepQuit";
import OperatorSignIn from "./MmrSteps/OperatorSignIn";
import ProductionLineCheckout from "./MmrSteps/ProductionLineCheckout";
import LabelGeneration from "./MmrSteps/LabelGeneration";
import PackagingStaging from "./MmrSteps/PackagingStaging";
import OperatorMmrReview from "./MmrSteps/OperatorMmrReview";
import Processing from "./MmrSteps/Processing";
import GatheringOfFlourishIngredients from "./MmrSteps/GatheringOfFlourishIngredients";
import WeighingOfFlourishIngredients from "./MmrSteps/WeighingOfFlourishIngredients";
import AdditionOfFlourishIngredients from "./MmrSteps/AdditionOfFlourishIngredients";
import MixingOfFlourishIngredients from "./MmrSteps/MixingOfFlourishIngredients";
import WeightofMixedFlourishIngredients from "./MmrSteps/WeightofMixedFlourishIngredients";
import WeightofPrimeIngredients from "./MmrSteps/WeightofPrimeIngredients"
import AdditionofPrimeIngredients from "./MmrSteps/AdditionofPrimeIngredients"
import MixingofPrimeIngredients from "./MmrSteps/MixingofPrimeIngredients"
import WeightofMixedPrimeIngredients from "./MmrSteps/WeightofMixedPrimeIngredients"
import PillingofMixedFlourishIngredient from "./MmrSteps/PillingofMixedFlourishIngredient"
import FlourishPillCount from "./MmrSteps/FlourishPillCount"
import FlourishPillWeight from "./MmrSteps/FlourishPillWeight"
import FlourishLabelGeneration from "./MmrSteps/FlourishLabelGeneration"
import PillPackagingFlourish from "./MmrSteps/PillPackagingFlourish"
import PillingofMixedPrimeIngredient from "./MmrSteps/PillingofMixedPrimeIngredient"
import PillWeightOfPrimePowder from "./MmrSteps/PillWeightOfPrimePowder"
import PillPackagingofPrimePills from "./MmrSteps/PillPackagingofPrimePills"
import EquipmentSanitization from "./MmrSteps/EquipmentSanitization"
import BPRApproval from "./MmrSteps/BPRApproval"
import BPRReviewSubmission from "./MmrSteps/BPRReviewSubmission"
import ReviewFinishedProductSpecifications from "./MmrSteps/ReviewFinishedProductSpecifications"




const bodyRef = React.createRef();
let warning = '';
let popUp_description="";
class StartMMRBPRModal extends Component {
  is_mounted = false;
 
  constructor(props) {
    super(props);

    if (!this.props.selected_row_value) {
      return "Error 404: No Selected Row Data Received!";
    }
  }
  state = {
    calories: "",
    is_popUp_disabled:false,
    modalOpen: false,
    converted_inventory_options_json: [],
    sortOrganism: "",
    show: false,
    probiotic_blend: "",
    qc_weight: "",
    pre_names: "",
    pro_names: "",
    currentAge: "",
    downloadData: "",
    servings_per_container: "",
    qc_out_of_spec:0,
    organism_alert:false,
    accept_total_capsule_size_popup:false,
    step:{},
    step_current:{},
    step_name:"",
    step_next:"",
    step_prev:"",

  };

  openPdf = async () => {

    this.setState({ is_page_loading: true });
    const res = await axios.post(baseURL + "/generate-label-pdf",
      [{ sg_number: this.props.selected_row_value.sample_id, id: this.props.selected_row_value.id }]
      , getHeaders());
    if (res.data.success) {
      window.open(res.data.data, '_blank').focus();
    }
    else {
      toast.error("Something Went Wrong!");
    }

    this.setState({ is_page_loading: false });
  }
 
  next = () => {
    this.props.onStateChange({
      defaultValue: false,
      modalOpen: false,
      is_updating_failed: false,
      is_updating_successful: false,
      is_waiting_for_response: true
    });

    let res = {
      is_completed: 1,
      id: this.props.selected_row_value.id,
      date_made: this.props.selected_row_value_date_made,
      prime_fill_qc_weight: this.props.selected_row_value.prime_fill_qc_weight,
      prime_powder_remaining: this.props.selected_row_value.prime_powder_remaining,
      prime_total_capsules_made: this.props.selected_row_value.prime_total_capsules_made,
      flourish_powder_remaining: this.props.selected_row_value.flourish_powder_remaining,
      flourish_total_capsules_made: this.props.selected_row_value_qc_weight.flourish_total_capsules_made,
      operator_notes: this.props.selected_row_value.operator_notes,
      flourish_fill_qc_weight: this.props.selected_row_value_qc_weight.flourish_fill_qc_weight,
      filled_tube: 
      
      this.props.selected_row_value_filled_tube.filled_tube === "" ||
      this.props.selected_row_value_filled_tube.filled_tube === undefined
      || this.props.selected_row_value_filled_tube.filled_tube === null
      ? "" :this.props.selected_row_value_filled_tube.filled_tube,
      
      filled_spoon:this.props.spoon, 


      qc_out_of_spec:this.state.qc_out_of_spec,
      user_id: getSessionStoredJsonItem("user").user_id
    }
    res.qc_out_of_spec = this.state.qc_out_of_spec

    customPrint(
      "Custom Modal",
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/formulations"
    );
    
  
    axios
      .put(baseURL + "/formulations/" + res.id, res, getHeaders())
      .then(response => {
        customPrint("Custom Modal", PRINT_CATEGORIES.RESPONSE, response);
        this.props.onStateChange({
          is_updating_successful: response.data.success,
          is_updating_failed: !response.data.success,
          response_message: response.data.message,
          is_message_visible: true
        });
        this.openPdf()
        focusFirstElement();

        setInterval(() => {
          this.setState({
            is_page_loading: false,
            is_waiting_for_response: false,
          })
        }, 8000)

        setInterval(() => {
          this.onCloseModalClick();
          window.location.reload(true);
        }, 9000)
      })
      .catch(error => {
        alert("Saving Custom Modal | Error: " + error);
        this.props.onStateChange({
          is_updating_failed: true,
          response_message: "Error in Updating Formulation",
          is_waiting_for_response: false
        });
      });

  }

  onUpdateClick = () => {
    var prime_filler_inventory_id = this.props.selected_row_value.prime_filler_inventory_id
    var flourish_powder_remaining = this.props.selected_row_value.flourish_powder_remaining;
    var prime_powder_remaining = this.props.selected_row_value.prime_powder_remaining;

    var flourish_capsule_size = this.props.selected_row_value.flourish_capsule_size
    var flourish_total_capsule_size = this.props.selected_row_value.flourish_total_capsules_made
    
    var flourish_fill_qc_weight = this.props.selected_row_value_qc_weight.flourish_fill_qc_weight
    var prime_fill_qc_weight = this.props.selected_row_value.prime_fill_qc_weight

    var prime_total_capsules_made = this.props.selected_row_value.prime_total_capsules_made
    var accept_total_capsule_size_popup  = this.state.accept_total_capsule_size_popup
    var number_of_months  = this.props.number_of_months;

    var flourish  = (flourish_total_capsule_size == " " || flourish_total_capsule_size == null);
    var prime  = (prime_filler_inventory_id !== null && (prime_total_capsules_made == " " || prime_total_capsules_made == null));
    

    if(flourish || prime ){
      var section = "";
      section  = (flourish && prime) && "Prime & Flourish";
      section  = (prime) ? "Prime":(flourish) ? "Flourish":"";
      alert(section + " Total Capsule size should not be blank");
      return;
    }

    if((flourish_powder_remaining  == "" 
      || flourish_powder_remaining == null)
      || (prime_filler_inventory_id !==null &&
         (prime_powder_remaining  == "" || prime_powder_remaining == null)) ) 
    {
      alert("Powder remaining can't be empty");
      return;
    }

    if( prime_filler_inventory_id !== null && (prime_fill_qc_weight == "" || prime_fill_qc_weight == null))
    {
      alert("Please fill prime QC Weight");      
      return;
    }
    if( flourish_fill_qc_weight == undefined || flourish_fill_qc_weight == "")
    {
      alert("Please fill flourish QC Weight");      
      return;
    }

    var flag = true;
    this.state.sortOrganism && this.state.sortOrganism.map((item,index)=>{
      if(item.qty<0 && !this.state.organism_alert){
        flag = window.confirm("Are you sure you want to mark this formula/refill as made?");
        this.setState({organism_alert:flag}); 
      };
    })
    if(!flag) return;

    var check_value  = ((this.props.number_of_months *30)+4)

  if(flourish_capsule_size !== "powder"){
    if( number_of_months == 3 && !accept_total_capsule_size_popup &&
      ( (parseInt(flourish_total_capsule_size) < check_value || parseInt(flourish_total_capsule_size) > 100) ))
      {
        warning = "total_capsule_size";
        popUp_description="please confirm everything was input correctly";
        this.setState({is_popUp_disabled:true});
        return;
      }

      if( number_of_months && !accept_total_capsule_size_popup
         && (parseInt(flourish_total_capsule_size) < check_value || parseInt(flourish_total_capsule_size) > 204))
        {
          warning = "total_capsule_size";
          popUp_description="please confirm everything was input correctly";
          this.setState({is_popUp_disabled:true});
          return;
        }
    
      if(!accept_total_capsule_size_popup &&
        ( (parseInt(flourish_total_capsule_size) < check_value)  || (parseInt(flourish_total_capsule_size) < check_value)
        ) )
        {
          warning = "total_capsule_size";
          popUp_description="please confirm everything was input correctly";
          this.setState({is_popUp_disabled:true});
          return;
        }
      }

     if ((
      (
        ["0","1"].indexOf(flourish_capsule_size) !== -1 &&
        ( flourish_fill_qc_weight < 0.38 || flourish_fill_qc_weight > 0.5))
        ||(["0","1"].indexOf(prime_total_capsules_made) !== -1 && 
        prime_filler_inventory_id !== null && (prime_fill_qc_weight < 0.38 || prime_fill_qc_weight > 0.5))
      )
     )
    {
      warning = "Qc Weight";
      popUp_description="The QC weight spec is out of range. Please confirm it is inputted correctly!";
      this.setState({is_popUp_disabled:true});
      return;
    }
    else{
      this.next();
      this.setState({is_popUp_disabled:false});
    }
    
    this.onOpenModalClick();
    this.setState({ defaultValue: true })
 

    
  };

  //handles click on cancel button
  onCancelClick = () => {
    this.onCloseModalClick();
  };

  componentDidMount() {
    focusFirstElement();
    this.is_mounted = true;

    // API call to get the inventory options
    customPrint(
      "Start Formulation Modal",
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/inventory/inventoryOptions"
    );

    axios
      .get(baseURL + "/inventory/inventoryOptions", getHeaders())
      .then(response => {
        customPrint(
          "Start Formulation Modal",
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        const inventory_options_json = response.data;
        const converted_inventory_options_json = convertInventoryOptionsJSON(
          inventory_options_json
        );

        if (this.is_mounted) {
          this.setState({
            converted_inventory_options_json
          });
        }
      })
      .catch(error => {
        alert("Fetching Inventory Options | Error: " + error);
      });
      this.setState({accept_total_capsule_size_popup:this.props.accept_total_capsule_size_popup})
      console.log(this.state.accept_total_capsule_size_popup);
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  onOpenModalClick = () => {
    this.setState({ modalOpen: true});
    axios
      .get(baseURL + "/sortInventory?formula_id=" + this.props.shareSorting, getHeaders())
      .then(res => {
        this.setState({
          sortOrganism: res.data
        })
      })
      axios.get(baseURL+"/get-last-step/"+this.props.shareSorting, getHeaders())
      .then(res=>{
        let result = res.data;       
        //console.log(result);
        if(result.success === true && res.data.step !== null){
          this.setState({
            step:result.step,
            step_name:result.step.step_name,
            step_next:result.step.step_next
          });
        }else{
          this.state.step_next = ""
        }       
        
      })
  };

  onCloseModalClick = () => {
    this.setState({
       modalOpen: false,
    });
  };
  onCloseModalBtn = (e, data) => {
    console.log(data.trigger);
    if (data.trigger === 'close') {
      this.onCloseModalClick();
      
    }
  };
  Modal_return_value = (value, message) => {
    if (value) {
      if(warning == "total_capsule_size")
      {
        if(value){
         this.setState({accept_total_capsule_size_popup:value})
         warning = "";
         this.callQcWeightPopup();      
          return;
        }
        this.setState({accept_total_capsule_size_popup:value})
        return;
      }
      else{
        this.callQcWeightPopup();      
    }
    } else {
      warning = "";
      return;
    }
  };

  // set pop_up value
  pop_up_open = () => {
    this.setState({ is_popUp_disabled: false });
  };

  callQcWeightPopup(){
    var flourish_fill_qc_weight = this.props.selected_row_value_qc_weight.flourish_fill_qc_weight
    var flourish_capsule_size  = this.props.selected_row_value.flourish_capsule_size
    var prime_total_capsules_made  = this.props.selected_row_value.prime_total_capsules_made
    var prime_fill_qc_weight  = this.props.selected_row_value.prime_fill_qc_weight
    var prime_filler_inventory_id  = this.props.selected_row_value.prime_filler_inventory_id
    
    setTimeout(() => {
      if ( (
          ["0","1"].indexOf(flourish_capsule_size) !== -1 && (
             flourish_fill_qc_weight < 0.38 || flourish_fill_qc_weight > 0.5)
          || ( ["0","1"].indexOf(prime_total_capsules_made) !== -1 && 
          prime_filler_inventory_id !== null
           &&( prime_fill_qc_weight < 0.38 || prime_fill_qc_weight > 0.5 ) ) )  && warning == "" )
         {
          popUp_description="The QC weight spec is out of range. Please confirm it is inputted correctly!";
          warning="QC Weight";
          this.setState({is_popUp_disabled:true});
          this.setState({ is_popUp_disabled: true, popUp_description: "" });
        return;
      } else {
        this.setState({qc_out_of_spec:1,is_popUp_disabled: false})
        this.next();
      }
      return;
  }, 500);
  }

  handlePrevClick = (step_prev) => {
    //console.log("prev",step_prev);
    this.setState({step_prev : step_prev });
  };

  handleNextClick = (step_next)=>{
    this.setState({step_prev : "",step_next:step_next});
  } 
  
  

  render() {
    let { selected_row_value,spoon_value, selected_row_value_qc_weight, selected_row_value_filled_tube, selected_row_value_date_made } = this.props;
    /* //console.log((Object.keys(this.state.step).length > 0 &&
    this.state.step_next === "operator_sign_in" && 
    this.state.step_prev === "") ||  this.state.step_prev === "operator_sign_in" ); */
    console.log(this.state);
    console.log((Object.keys(this.state.step).length));
    return (
      
      <React.Fragment>
         {this.state.is_popUp_disabled && (
          <ShowModal
            message={warning}
            Description={popUp_description}
            Modal_return_value={this.Modal_return_value}
            pop_up_open={this.pop_up_open}
            modal_style={"500px"}
          /> 
       )}
        <Modal
          trigger={
            <Button
              style={{ fontWeight: "initial" }}
              secondary
              onClick={this.onOpenModalClick}
              icon
              labelPosition="right"
              disabled={this.props.is_special_button_disabled}
            >
              Start Formulation(MMR)
              <Icon name="chevron circle right" />
            </Button>
          }
          open={this.state.modalOpen}
          onClose={this.onCloseModalBtn}
          centered={false}
          
        >
          {this.props.is_waiting_for_response && (
            <CustomLoader loading_text="Updating Formulation Data" />
          )}
          <Modal.Header className="modal_header">
            MMR and BPR
            <Button floated='right' color="black" onClick={this.onCloseModalClick}>
              <Icon name='close' />
            </Button>
          </Modal.Header>
          <Modal.Description>
            <Segment>
              {(this.state.step_next === "quit") && 
              (<StepQuit
                selected_row_value={this.props.selected_row_value}
                onNextClick={this.handleNextClick}
                onCloseModal= {this.onCloseModalClick}
              />)}

              {(this.state.step_next === "pause") && 
              (<StepPause
                selected_row_value={this.props.selected_row_value}
                onNextClick={this.handleNextClick}
                onCloseModal= {this.onCloseModalClick}
              />
              )}

            {/*   {((Object.keys(this.state.step).length == 0 &&
              this.state.step_name === "" && this.state.step_next === "")  ||
              this.state.step_prev === "mmr_approval") && 
              (<MmrApproval
                selected_row_value={this.props.selected_row_value}
                step_prev={this.state.step_prev}
                step_name={this.state.step_name}
                onPrevClick={this.handlePrevClick}
                onNextClick={this.handleNextClick}
                onNextStep = {this.onOpenModalClick}
              
                />)} */}
              

              { ((Object.keys(this.state.step).length == 0 &&
               this.state.step_name === "" && this.state.step_next === "") ||  this.state.step_prev === "operator_sign_in")   && 
              (<OperatorSignIn
                selected_row_value={this.props.selected_row_value}
                step_prev={this.state.step_prev}
                step_name={this.state.step_name}
                onPrevClick={this.handlePrevClick}
                onNextClick={this.handleNextClick}
                onNextStep = {this.onOpenModalClick}
              />)}
              
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "production_line_checkout" && 
              this.state.step_prev === "") ||  this.state.step_prev === "production_line_checkout")   &&
              (<ProductionLineCheckout
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}                               
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "label_generation" && 
              this.state.step_prev === "") ||  this.state.step_prev === "label_generation")   &&
              (<LabelGeneration
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}

              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "packaging_staging" && 
              this.state.step_prev === "") ||  this.state.step_prev === "packaging_staging")   &&
              (<PackagingStaging
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}

              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "operator_mmr_review" && 
              this.state.step_prev === "") ||  this.state.step_prev === "operator_mmr_review")   &&
              (<OperatorMmrReview
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}

              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "processing" && 
              this.state.step_prev === "") ||  this.state.step_prev === "processing")   &&
              (<Processing
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "gathering_of_flourish_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "gathering_of_flourish_ingredients")   &&
              (<GatheringOfFlourishIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "weighing_of_flourish_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "weighing_of_flourish_ingredients")   &&
              (<WeighingOfFlourishIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "addition_of_flourish_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "addition_of_flourish_ingredients")   &&
              (<AdditionOfFlourishIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "mixing_of_flourish_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "mixing_of_flourish_ingredients")   &&
              (<MixingOfFlourishIngredients 
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "weight_of_mixed_flourish_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "weight_of_mixed_flourish_ingredients")   &&
              (<WeightofMixedFlourishIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "weight_of_prime_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "weight_of_prime_ingredients")   &&
              (<WeightofPrimeIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "addition_of_prime_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "addition_of_prime_ingredients")   &&
              (<AdditionofPrimeIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "mixing_of_prime_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "mixing_of_prime_ingredients")   &&
              (<MixingofPrimeIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "weight_of_mixed_prime_ingredients" && 
              this.state.step_prev === "") ||  this.state.step_prev === "weight_of_mixed_prime_ingredients")   &&
              (<WeightofMixedPrimeIngredients
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "pilling_of_mixed_flourish_ingredient" && 
              this.state.step_prev === "") ||  this.state.step_prev === "pilling_of_mixed_flourish_ingredient")   &&
              (<PillingofMixedFlourishIngredient
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "flourish_pill_count" && 
              this.state.step_prev === "") ||  this.state.step_prev === "flourish_pill_count")   &&
              (<FlourishPillCount
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "flourish_pill_weight" && 
              this.state.step_prev === "") ||  this.state.step_prev === "flourish_pill_weight")   &&
              (<FlourishPillWeight
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "flourish_label_generation" && 
              this.state.step_prev === "") ||  this.state.step_prev === "flourish_label_generation")   &&
              (<FlourishLabelGeneration
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "pill_packaging_flourish" && 
              this.state.step_prev === "") ||  this.state.step_prev === "pill_packaging_flourish")   &&
              (<PillPackagingFlourish
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "pilling_of_mixed_prime_ingredient" && 
              this.state.step_prev === "") ||  this.state.step_prev === "pilling_of_mixed_prime_ingredient")   &&
              (<PillingofMixedPrimeIngredient
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "pill_weight_of_prime_powder" && 
              this.state.step_prev === "") ||  this.state.step_prev === "pill_weight_of_prime_powder")   &&
              (<PillWeightOfPrimePowder
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "pill_packaging_of_prime_pills" && 
              this.state.step_prev === "") ||  this.state.step_prev === "pill_packaging_of_prime_pills")   &&
              (<PillPackagingofPrimePills
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "equipment_sanitization" && 
              this.state.step_prev === "") ||  this.state.step_prev === "equipment_sanitization")   &&
              (<EquipmentSanitization
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "bpr_review_submission" && 
              this.state.step_prev === "") ||  this.state.step_prev === "bpr_review_submission")   &&
              (<BPRReviewSubmission
                selected_row_value={this.props.selected_row_value}
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "bpr_approval" && 
              this.state.step_prev === "") ||  this.state.step_prev === "bpr_approval")   &&
              (<BPRApproval
                selected_row_value={this.props.selected_row_value}
                selected_row_value_date_made={
                  this.props.selected_row_value_date_made
                }
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}
                onCloseModalClick={this.onCloseModalClick}
              />)}
              {((Object.keys(this.state.step).length > 0 &&
              this.state.step_next === "review_finished_product_specifications" && 
              this.state.step_prev === "") ||  this.state.step_prev === "review_finished_product_specifications")   &&
              (<ReviewFinishedProductSpecifications
                selected_row_value={this.props.selected_row_value}
                selected_row_value_date_made={
                  this.props.selected_row_value_date_made
                }
                step_name={this.state.step_name}
                step_prev={this.state.step_prev}
                onPrevClick={this.handlePrevClick}
                onNextStep = {this.onOpenModalClick}
                onNextClick={this.handleNextClick}                
              />)}
              




              
              {/* <LabelGeneration/> */}
            </Segment>
          </Modal.Description>

        </Modal>
      </React.Fragment>
    );
  }
}

export default StartMMRBPRModal;
