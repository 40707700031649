import React, { Component } from "react";

import {
  Grid,
  Label,
  Modal,
  Segment,
  Form,
  Button,
  Popup,
  Input
} from "semantic-ui-react";
import CustomLoader from "../custom_elements/CustomLoader";
import axios from "axios";
import { baseURL, PRINT_CATEGORIES } from "../../configuration/constants";
import { customPrint } from "../helper";

class ForgotPasswordModal extends Component {
  state = {
    modalOpen: false
  };

  //handles click on cancel button
  onCancelClick = () => {
    this.onCloseModalClick();
  };

  componentWillUnmount() {
    this.is_mounted = false;
  }
  getInitState = () => {
    return {
      is_forgot_password_successful: false,
      is_forgot_password_failed: false,
      is_waiting_for_response: false,
      is_message_visible: true,
      response_message: "",
      is_page_loading: true,
    }
  }
  onOpenModalClick = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModalClick = () => {
    this.setState({ modalOpen: false });
  };

  onSendResetLinkClick = () => {
    this.onOpenModalClick();

    // this.props.onStateChange({
    //   modalOpen: false
    // });

    // /api/password/request

    let res = { username: this.props.username };
    // API call to fetch the roles
    customPrint(
      "Forgot Password Modal",
      PRINT_CATEGORIES.REQUEST,
      baseURL + "/password/request",
      res
    );

    axios
      .post(baseURL + "/password/request", res)
      .then(response => {
        customPrint(
          "Forgot Password Modal",
          PRINT_CATEGORIES.RESPONSE,
          response
        );

        // this.props.onStateChange({
        //   is_updating_successful: response.data.success,
        //   is_updating_failed: !response.data.success,
        //   is_page_loading: false,
        //   response_message: response.data.message,
        //   is_waiting_for_response: false,
        //   is_message_visible: true
        // });

        alert(response.data.message);
        this.onCloseModalClick();
      })
      .catch(error => {
      
       // alert("Send Reset Password Link| Error: " + error);
       alert("Email Id is not registered with us! Please check and try again later");
        this.props.onStateChange({
          is_updating_failed: true,
          is_message_visible:true,
          response_message: "Error in Sending Reset Password Link",
          is_waiting_for_response: false
        });
      });
  };

  render() {
    return (
      <React.Fragment>
         

        <Modal
          style={{ width: "50%" }}
          trigger={
            <Grid textAlign="center">
              <Grid.Row>
                <Grid.Column>
                  <Label
                    id="hyper-link"
                    className="hyper-link"
                    basic
                    color="blue"
                    onClick={this.onOpenModalClick}
                  >
                    Forgot Password ?
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
          open={this.state.modalOpen}
          onClose={this.onCloseModalClick}
          centered={false}
        >
          <Modal.Header className="modal_header">
            Forgot Password ?
          </Modal.Header>
          <Modal.Description>
            <Segment>
              {this.props.is_waiting_for_response && (
                <CustomLoader loading_text="Loading..." />
              )}
         
              <Form>
         
                <Grid columns="equal">
                  <Grid.Row />
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field required fluid="true">
                        <Popup
                          trigger={<label>Username / Email ID </label>}
                          content="Username/Email ID."
                        />
                        <Input
                          id="username"
                          name="username"
                          placeholder="Enter email ID"
                          onChange={this.props.onChange}
                          value={this.props.username}
                          icon="user"
                          iconPosition="left"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row />
                  <Grid.Row />
                </Grid>
              </Form>
            </Segment>
          </Modal.Description>

          <Modal.Actions>
            <Grid textAlign="center">
              {/* <Grid.Row/> */}
              <Grid.Row>
                <Grid.Column>
                  <Button.Group>
                    <Popup
                      trigger={
                        <Button
                          content="Send Reset Link"
                          icon="check"
                          labelPosition="left"
                          positive
                          onClick={this.onSendResetLinkClick}
                        />
                      }
                      content="Share the Reset Password Link."
                    />
                    <Button.Or />

                    <Popup
                      trigger={
                        <Button
                          content="Cancel"
                          icon="ban"
                          labelPosition="right"
                          negative
                          onClick={this.onCancelClick}
                        />
                      }
                      content="Cancel the operation."
                    />
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row /> */}
            </Grid>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ForgotPasswordModal;
