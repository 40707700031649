//-------------------------------------------------------------------//
// Module: CustomSearchBar                                           //
// Tasks: Display Semantic-UI specified SearchBar                    //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import _ from "lodash";
import { Search } from "semantic-ui-react";

class CustomSearchBar extends Component {
  state = {
    isLoading: false,
    value: this.props.custom_value,
    results: []
  };

  onBlur = (ev, data) => {
    this.props.onChange(ev, {
      name: data.name,
      value: data.value
    });
  };

  //Searching...
  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title });

    this.props.onChange(e, {
      name: this.props.name,
      value: result.title
    });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.resetComponent();

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(this.props.custom_data, isMatch)
      });
    }, 300);
  };

  render() {
    const { isLoading, value, results } = this.state;
    return (
      <Search
        placeholder={this.props.placeholder}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 50, {
          leading: true
        })}
        onBlur={this.onBlur}
        name={this.props.custom_name}
        results={results}
        value={value}
        {...this.props}
      />
    );
  }
}

export default CustomSearchBar;
