
import React, { Component } from "react";
import {
    Grid,
    Segment,
    Form,
    Input,
    Button,
    Popup,
    Icon,
    Table,
    Radio
} from "semantic-ui-react";
import { toast } from 'react-toastify';

import {
    getHeaders,isAvailable_lower,notEnterZeroBefore
} from "../helper";
import $ from "jquery";
import {
    baseURL
} from "../../configuration/constants";

import axios from "axios";
import CustomDivider from "../custom_elements/CustomDivider";
import CustomDimmer from "../custom_elements/CustomDimmer";
import { Custom_DropDown } from "./DropDown";

let total_amount_added_after_insert = 1;

const inventory = {
    content: {
        height: '30px',
        marginTop: '6px',
    }
}


class All extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteFinalConcentration: 0,
            number_of_months: 3,
            formulation_factor: 1,
            is_prime_filler_amount_added_exceeding_qty: false,
            inventory: [],
            amount_added: "",
            final_amount_added: 0,
            organism_barcode: "",
            tableConcentration: 0,
            concentration: 0,
            FinalConcetration: 0,
            scrap_amount: "",
            inventory_id: 0,
            tableAmount: 0,
            tableFinalAmount: 0,
            scrap_factor: 0,
            qty: "",
            all: [
                {
                    "organism_barcode": "",
                    "qty": "",
                    "scrap_amount": "",
                    "concentration": "",
                    "id": ""
                }
            ]
        }
    }

    componentDidMount = () => {
        this.fetchData();
    }
    fetchData = () => {
        axios
            .get(baseURL + "/inventory/inventoryOptions?type=pre,pro", getHeaders())
            .then((res => {
                let tempArray = [];
                tempArray.push(...res.data);
                this.onInputChange(tempArray, this.state.organism_barcode);
                this.setState({
                    all: tempArray
                })
            }))
    }

    monthChanges = (e, { name, value }) => {
        let formulation = Number((value / 3).toFixed(3));
        this.props.number_of_months(value)
        this.props.formulation_factor(formulation)

        let FinalAmountChangeByMonths = Number(total_amount_added_after_insert) * formulation;
        this.setState({
            [name]: value,
            tableFinalAmount: FinalAmountChangeByMonths,
            final_amount_added: FinalAmountChangeByMonths,
            formulation_factor: formulation
        })
        let tempArray = [];
        let obj = {};
        this.state.inventory.map((organism) => {
            let practise = formulation * organism.amount_added
            obj = {
                "amount_added": organism.amount_added,
                "final_amount_added": organism.amount_added * formulation,
                "organism_barcode": organism.organism_barcode,
                "total_cfu": organism.final_concentration * practise,
                "final_concentration": organism.final_concentration,
                "concentration": organism.final_concentration * organism.amount_added,
                "inventory_id": organism.inventory_id,
                "scrap_amount": organism.scrap_amount
            }
            tempArray.push(obj);
            this.props.organism(tempArray);

            const result = tempArray.reduce((acc, value) => ({
                amount_added: parseFloat(acc.amount_added) + parseFloat(value.amount_added),
            }));
            this.props.total_amount_added(result.amount_added);

            const concentration = tempArray.reduce((acc, value) => ({
                concentration: acc.concentration + value.concentration,
            }));
            let total_final_concentration = concentration.concentration / result.amount_added;
            this.props.total_final_concentration(total_final_concentration);

            let SelectedOrganismBarcode = this.state.organism_barcode;
            let temp = this.state.all;
            temp.filter((elem, index) => {
                if (elem.organism_barcode === SelectedOrganismBarcode) {
                    temp.splice(index, 1);
                }
            })

            this.props.total_final_amount_added(result.amount_added * formulation);

            this.setState({
                tableAmount: result.amount_added,
                tableFinalAmount: result.amount_added * formulation,
                tableConcentration: concentration.concentration / result.amount_added,
                all: temp,
                inventory: tempArray,
                deleteFinalConcentration: concentration.concentration,
            })
        })
        
    };
    onInputChanges = (e, { name, value }) => {
        let FinalAmountChangeByOnInput;
        this.setState({
            [name]: value
        });

        if (this.state.inventory_id === 0) {
            alert("Please select both the Ingredient Barcode and the Amount added!");
            return;
        }
        if (value > this.state.qty) {
            FinalAmountChangeByOnInput = this.state.formulation_factor * this.state.qty;
            this.setState({
                tableFinalAmount: FinalAmountChangeByOnInput,
                amount_added: this.state.qty,
                is_prime_filler_amount_added_exceeding_qty: true
            })
        }
        else {
            FinalAmountChangeByOnInput = this.state.formulation_factor * value;
            this.setState({
                tableFinalAmount: FinalAmountChangeByOnInput
            })
        }
        let tableConcentration = this.state.concentration * value;
        this.setState({
            tableConcentration: tableConcentration / FinalAmountChangeByOnInput,
            FinalConcetration: this.state.concentration * value
        })

        $('#NumericTextBoxs').keypress(function(event) {
            if ((event.which !== 46 || $(this).val().indexOf('.') !== -1)&&(event.which < 48 || event.which > 57)) {
              event.preventDefault();
           }
           if(this.value.indexOf(".")>-1 && (this.value.split('.')[1].length > 2))		{
                event.preventDefault();
            }
        });
    };
    onInputChange = (array, value) => {
        this.setState({
            organism_barcode: value
        })
        let concentration;
        let id;
        let organism_barcode;
        let scrap_amount;
        let qty;
        let scrap_factor;
        let tempArray = array;
        tempArray.filter((elem) => {
            if (elem.organism_barcode === value) {
                concentration = elem.concentration;
                id = elem.id
                organism_barcode = elem.organism_barcode
                scrap_amount = elem.scrap_factor
                qty = elem.qty
                scrap_factor = elem.scrap_factor
                this.setState({
                    concentration: concentration,
                    inventory_id: id,
                    organism_barcode: organism_barcode,
                    scrap_amount: scrap_amount,
                    qty: qty,
                    scrap_factor: scrap_factor
                })
            }
        })
    }

    onAddOrganismClick = () => {
        this.props.isSubmitHandler(false)
        if (this.state.amount_added === 0 || this.state.inventory_id === 0 || this.state.amount_added === "") {
            alert("Please select both the Ingredient Barcode and the Amount added!");
            return;
        }
        let tempArray = this.state.inventory;
        let obj = {};
        obj = {
            "amount_added": this.state.amount_added,
            "final_amount_added": this.state.tableFinalAmount,
            "organism_barcode": this.state.organism_barcode,
            "total_cfu": this.state.concentration * this.state.tableFinalAmount,
            "final_concentration": this.state.concentration,
            "concentration": this.state.concentration * this.state.amount_added,
            "inventory_id": this.state.inventory_id,
            "scrap_amount": Number((Number(this.state.scrap_factor) * Number(this.state.amount_added)) / 100).toFixed(3)
        }
        tempArray.push(obj);

        const result = tempArray.reduce((acc, value) => ({
            amount_added: parseFloat(acc.amount_added) + parseFloat(value.amount_added),
        }));
        total_amount_added_after_insert = result.amount_added;
        this.props.total_amount_added(result.amount_added);

        const resultFinalAmount = tempArray.reduce((acc, value) => ({
            final_amount_added: acc.final_amount_added + value.final_amount_added,
        }));
        this.props.total_final_amount_added(resultFinalAmount.final_amount_added);

        const concentration = tempArray.reduce((acc, value) => ({
            concentration: acc.concentration + value.concentration,
        }))
        let total_final_concentration = concentration.concentration / total_amount_added_after_insert;
        this.props.total_final_concentration(total_final_concentration);

        var SelectedOrganismBarcode = this.state.organism_barcode;
        var temp = this.state.all
        temp.filter((elem, index) => {
            if (elem.organism_barcode === SelectedOrganismBarcode) {
                temp.splice(index, 1);
            }
        })
        this.setState({
            tableConcentration: concentration.concentration / result.amount_added,
            tableAmount: parseFloat(result.amount_added).toFixed(3),
            tableFinalAmount: resultFinalAmount.final_amount_added,
            deleteFinalConcentration: concentration.concentration,
            all: temp,
            inventory: tempArray
        })

        this.props.organism(tempArray);
        this.setState(this.resetOrganismState());
    }

    resetOrganismState = () => {
        return {
            organism_barcode: "",
            inventory_id: 0,
            amount_added: "",
            FinalConcetration: 0,
            concentration: 0,
            Final: 0,
            scrap_amount: 0,
            final_amount_added: 0
        };
    };

    delete = (index, value) => {
        let findScrapAmount = Number(value.scrap_amount) * 100;
        let scrap_amount = findScrapAmount / value.amount_added
        let scrap_factor = scrap_amount / 100;
        this.state.all.unshift({
            organism_barcode: value.organism_barcode,
            concentration: value.final_concentration,
            id: value.inventory_id,
            scrap_factor: scrap_factor
        })
        let total_final_amount_added = this.state.tableFinalAmount - value.final_amount_added;

        let total_amount_added = this.state.tableAmount - value.amount_added;
        let total_cfu = this.state.deleteFinalConcentration - value.concentration;
        let total_final_concentration = total_cfu / total_amount_added;
        this.props.total_final_concentration(total_final_concentration);

        let tempArray = this.state.inventory;
        tempArray.splice(index, 1);
        if ((total_cfu === 0 && total_amount_added === 0) || total_amount_added < 0) {
            this.setState({
                deleteFinalConcentration: 0,
                inventory: tempArray,
                tableAmount: total_amount_added,
                tableFinalAmount: total_final_amount_added,
                tableConcentration: 0
            })
        }
        else {
            this.setState({
                deleteFinalConcentration: total_cfu,
                inventory: tempArray,
                tableAmount: total_amount_added,
                tableFinalAmount: total_final_amount_added,
                tableConcentration: total_final_concentration
            })
        }

    }

    onClickOutside = () => {
        this.setState({
            is_prime_filler_amount_added_exceeding_qty: false,
        });
    }
    componentDidUpdate = (prevprops, prevstate) => {
        if (prevprops.is_submit !== this.props.is_submit && this.props.is_submit) {
            this.setState({
                inventory: [],
                tableConcentration: 0,
                tableAmount: 0,
                tableFinalAmount: 0
            })
        }
    }
    onRefreshClick = (e) => {
        this.fetchData();
        toast.success("Refreshed Successfully");
    }

    AnswerKeyPress = (e) => {
        notEnterZeroBefore(e)
    }
    render() {
        return (
            <Segment>
                <CustomDimmer
                    active={this.state.is_prime_filler_amount_added_exceeding_qty}
                    title="Not Enough Quantity!"
                    pre_content="Organism Inventory only has ("
                    content={this.state.qty}
                    post_content=" gm) of this Prime Filler Organism."
                    methods
                    onClickOutside={this.onClickOutside}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Form.Field fluid="true">
                                <Popup
                                    trigger={<label>Number of Months (Ref.)</label>}
                                    content="Reference to the Number of Months."
                                />
                                <Input
                                    id="answer_key_press"
                                    name="number_of_months"
                                    onChange={this.monthChanges}
                                    value={this.state.number_of_months}
                                    icon="calendar alternate outline"
                                    iconPosition="left"
                                    placeholder="Number of Months"
                                    type="text"
                                    onKeyPress={this.AnswerKeyPress}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field fluid="true">
                                <Popup
                                    trigger={<label>Formulation Factor (Ref.)</label>}
                                    content="Enter the Formulation Factor."
                                />
                                <Input
                                    name="formulation_factor"
                                    disabled
                                    className="full_opacity"
                                    onChange={this.props.onChange}
                                    value={this.state.formulation_factor}
                                    icon="warning sign"
                                    iconPosition="left"
                                    placeholder="Formulation Factor"
                                />
                            </Form.Field>
                        </Grid.Column>
                        
                    </Grid.Row>
                    {this.state.inventory &&
                        this.state.inventory.map((elem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Grid.Row className="block">
                                        <Grid.Column width={11}></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="block" >
                                        <Grid.Column width={5}>
                                            <Form.Field required fluid="true">
                                                <Popup
                                                    trigger={<label>Ingredient Barcode</label>}
                                                    content="Enter the Ingredient Barcode."
                                                />
                                                <Input
                                                    style={{ opacity: 1, backgroundColor: "rgb(184, 252, 255)" }}
                                                    value={elem.organism_barcode}
                                                    disabled
                                                >
                                                </Input>
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column width={4}>
                                            <Form.Field required fluid="true" >
                                                <Popup
                                                    trigger={<label>Amount Added</label>}
                                                    content="Enter the amount added."
                                                />
                                                <Input
                                                    style={{ opacity: 1, width: "80%", }}
                                                    icon="weight"
                                                    iconPosition="left"
                                                    label={{ content: "g" }}
                                                    labelPosition="right"
                                                    value={Math.floor(elem.amount_added * 1000) / 1000}
                                                    disabled
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Form.Field fluid="true">
                                                <Popup
                                                    trigger={<label>Concentration</label>}
                                                    content="Enter the Concentration."
                                                />
                                                <Input
                                                    className="full_opacity"
                                                    name="concentration"
                                                    disabled
                                                    icon="warning sign"
                                                    iconPosition="left"
                                                    placeholder="Concentration"
                                                    value={Math.floor(elem.final_concentration * 1000)/1000}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Form.Field fluid="true">
                                                <Popup
                                                    trigger={<label>Total CFU</label>}
                                                    content="Enter the Final Concentration."
                                                />
                                                <Input
                                                    className="full_opacity"
                                                    disabled
                                                    icon="warning sign"
                                                    iconPosition="left"
                                                    placeholder="Final Concentration"
                                                    value={Math.floor(elem.concentration * 1000)/1000}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={1} style={{ textAlign: "right" }}>
                                            <Form.Field fluid="true">
                                                <label style={{ visibility: "hidden" }}>.</label>
                                                <Popup
                                                    trigger={
                                                        <Button
                                                            icon="delete"
                                                            negative
                                                            circular
                                                            onClick={() => this.delete(index, elem)}
                                                        />
                                                    }
                                                    content="Delete this Organism."
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </React.Fragment>
                            )
                        })
                    }
                    <CustomDivider
                        h_size="h4"
                        title="New Ingredient"
                        icon_name="dna"
                    />
                    <Grid.Row>
                        <Grid.Column width={11}></Grid.Column>
                        <Grid.Column width={5}>
                            <Popup
                                trigger={<label>.</label>}
                            />
                            <Form.Field required fluid="true">
                                <Button
                                    basic
                                    onClick={this.onRefreshClick}
                                    icon
                                    labelPosition="right"
                                >
                                    Refresh Inventory
                                    <Icon name="refresh" />
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="block" >
                        <Grid.Column width={5}>
                            <Form.Field required fluid="true">
                                <Popup
                                    trigger={<label>Ingredient Barcode</label>}
                                    content="Enter the Ingredient Barcode."
                                />
                                <input list="chrome"
                                    style={{ opacity: 1, backgroundColor: "#b8sfcff" }}
                                    value={this.state.organism_barcode}
                                    onChange={(e) => this.onInputChange(this.state.all, e.target.value)}
                                ></input>
                                <datalist
                                    id="chrome"
                                >
                                    {
                                        this.state.all && this.state.all.map((elem, index) => {
                                            return (
                                                <option key={index}>
                                                    {elem.organism_barcode}
                                                </option>
                                            )
                                        })
                                    }
                                </datalist>
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label>Amount Added</label>}
                                    content="Enter the amount added."
                                />
                                <Input
                                    id="NumericTextBoxs"
                                    type="number"
                                    style={{ opacity: 1, width: "80%", }}
                                    name="amount_added"
                                    icon="weight"
                                    iconPosition="left"
                                    label={{ content: "g" }}
                                    labelPosition="right"
                                    placeholder="Amount Added"
                                    onChange={this.onInputChanges}
                                    value={this.state.amount_added}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Form.Field fluid="true">
                                <Popup
                                    trigger={<label>Concentration</label>}
                                    content="Enter the Concentration."
                                />
                                <Input
                                    name="concentration"
                                    className="full_opacity"
                                    disabled
                                    icon="warning sign"
                                    iconPosition="left"
                                    placeholder="Concentration"
                                    value={Math.floor(this.state.concentration * 1000)/1000}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Form.Field fluid="true">
                                <Popup
                                    trigger={<label>Total CFU</label>}
                                    content="Enter the Final Concentration."
                                />
                                <Input
                                    name="final_concentration"
                                    className="full_opacity"
                                    disabled
                                    icon="warning sign"
                                    iconPosition="left"
                                    placeholder="Final Concentration"
                                    value={Math.floor(this.state.FinalConcetration * 1000)/1000}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={1} style={{ textAlign: "right" }}>
                            <Form.Field fluid="true">
                                <label style={{ visibility: "hidden" }}>.</label>
                                <Popup
                                    trigger={
                                        <Button
                                            onClick={() => {
                                                this.props.onDeleteOrganismClick(
                                                    this.props.organism.organism_barcode
                                                );
                                            }}
                                            icon="delete"
                                            disabled={!this.props.is_disabled}
                                            negative
                                            circular
                                        />
                                    }
                                    content="Delete this Organism."
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column style={{ textAlign: "center" }}>
                            <Popup
                                trigger={
                                    <Button
                                        animated="vertical"
                                        className="small"
                                        color="blue"
                                        onClick={this.onAddOrganismClick}
                                    >
                                        <Button.Content visible>
                                            <Icon name="plus circle" />
                                            Insert
                                        </Button.Content>
                                        <Button.Content hidden>
                                            More&nbsp;
                                            <Icon name="arrow right" />
                                        </Button.Content>
                                    </Button>
                                }
                                content="Add more Organism(s)."
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3} />
                        <Grid.Column width={10}>
                            <Segment>
                                <Table celled definition color="green">
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width={8}>
                                                <Icon name="check circle" />
                                                Total Amount Added
                                            </Table.Cell>
                                            <Table.Cell width={4}>
                                                {parseFloat(this.state.tableAmount).toFixed(3)}
                                            </Table.Cell>
                                            <Table.Cell positive width={4}>
                                                g
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Icon name="check circle" />
                                                Total Final Concentration
                                            </Table.Cell>
                                            <Table.Cell>
                                                {parseFloat(this.state.tableConcentration).toFixed(3)}
                                            </Table.Cell>
                                            <Table.Cell positive>B CFU /g</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Icon name="check circle" />
                                                Total Final Amount Added
                                            </Table.Cell>

                                            <Table.Cell>
                                                {parseFloat(this.state.tableFinalAmount).toFixed(3)}
                                            </Table.Cell>
                                            <Table.Cell positive>g</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={3} />
                    </Grid.Row>
                    <Grid.Row />
                </Grid>

            </Segment>
        );
    }
}

export default All;
