//-------------------------------------------------------------------//
// Module: CustomLoader                                              //
// Tasks: Display Semantic-UI specified Custom Loader                //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

class CustomLoader extends Component {
  render() {
    return (
      <React.Fragment>
        <Dimmer active>
          <Loader className="workaround">
            {this.props.loading_text ? this.props.loading_text : "Loading..."}
          </Loader>
        </Dimmer>
      </React.Fragment>
    );
  }
}

export default CustomLoader;
