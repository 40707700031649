//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: TotalInfo                                           //
// Tasks: 1) Receives and displays TotalInfo                         //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import CustomDivider from "../custom_elements/CustomDivider";

import React, { Component } from "react";
import { Segment, Button, Grid, Table, Icon, Popup } from "semantic-ui-react";

class TotalRefillInfo extends Component {
  state = {};
  render() {
    return (
      <Grid columns="equal">
        <CustomDivider
          h_size="h3"
          title="Total Information"
          icon_name="balance scale"
        />

        <Grid.Row>
          <Grid.Column width={3} />
          <Grid.Column width={10}>
            <Segment>
              <Table celled definition color="green">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Icon name="check circle" />
                      Total Amount Added
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {parseFloat(this.props.total_amount_added).toFixed(3)}
                    </Table.Cell>
                    <Table.Cell positive width={4}>
                      g
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="check circle" />
                      Total Final Concentration
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(this.props.gain).toFixed(3)}
                    </Table.Cell>
                    <Table.Cell positive>B CFU /g</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Icon name="check circle" />
                      Total Final Amount Added
                    </Table.Cell>

                    <Table.Cell>
                      {parseFloat(this.props.total_final_amount_added).toFixed(3)}
                    </Table.Cell>
                    <Table.Cell positive>g</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
          <Grid.Column width={3} />
        </Grid.Row>

        <Grid.Row />
        <Grid.Row />
        <Grid.Row />
        <Grid.Row />
        <Grid.Row />

        <Grid.Row>
          <Grid.Column>
            <Grid textAlign="center">
              <Button.Group>
                {this.props.module_name_total_info === "Create Reformulation" &&
                  <Popup
                    trigger={
                      <Button
                        content="Create Reformulation"
                        icon="check"
                        labelPosition="left"
                        positive
                        onClick={this.props.onSubmitClick}

                        disabled={this.props.disabledPermission}
                      />
                    }
                    content="Enter the Formulation Information."
                  />}
                {this.props.module_name_total_info === undefined &&
                  <Popup
                    trigger={
                      <Button
                        content="Create Refill"
                        icon="check"
                        labelPosition="left"
                        positive
                        onClick={this.props.onSubmitClick}
                        disabled={this.props.disabledPermission || this.props.btn_disable}
                      />
                    }
                    content="Enter the Formulation Information."
                  />}


                <Button.Or />
                <Popup
                  trigger={
                    <Button
                      content="Cancel"
                      icon="ban"
                      labelPosition="right"
                      negative
                      onClick={this.props.onCancelClick}
                      disabled={this.props.disabledPermission}
                    />
                  }
                  content="Discard the Formulation Information."
                />
              </Button.Group>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
        <Grid.Row />
      </Grid>
    );
  }
}

export default TotalRefillInfo;
