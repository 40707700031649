import React, { Component } from "react";

import PdfContainer from "./container";
import "../../pdf.css";

class MultiPdfDownload extends Component {
    getInitState = () => {
        return {
            width: 1127,
            height: 693
        }
    }

    state = this.getInitState();

    componentDidMount = () => {
        let setRatio = document.getElementById("pdf-print");
        let height = setRatio.offsetHeight;
        let width = 1.62 * height;
        this.setState({
            width,
            height
        })
        window.addEventListener("resize", () => {
            let setRatio = document.getElementById("pdf-print");
            let height = setRatio.offsetHeight;
            let width = 1.62 * height;
            this.setState({
                width,
                height
            })

        })
    }

    render() {
        return (
            <>
                <PdfContainer createPdf={this.props.createPdf}>
                    <br />
                    <br />
                    <div id="pdf-print" style={{ borderRight: "9px solid #fff", borderRadius: "25px", width: this.state.width, height: this.state.height }}>
                        <div style={{ backgroundColor: "#fff", border: "6px solid black", outline: "0", borderRadius: "25px", height: "693.39px", width: "1127px" }}>
                            <div className="pdf-wrap">
                                <div className="main-row">
                                    <div className="main-col w-33">

                                        {/* <div className="supplement-info mt-10">SG number: SG99988877</div>
                                        <div className="supplement-info mt-10">Formula ID: fmrx_989933_001</div> */}

                                        <div className="supplement-heading">Supplement Facts</div>
                                        <div className="supplement-info mt-10">Serving Size: {this.props.qc_weight}</div>
                                        <div className="supplement-info mb-10">Servings Per Containers: {this.props.servings_per_container}</div>
                                        <div className="border-top10"></div>
                                        <div className="info-box">
                                            <div className="info-heading">Probiotic Blend: {this.props.probiotic_blend} Billion CFU
                                                <img src={require("../../images/up-arrow.png")} className="img-arrow-up" />
                                            </div>
                                            {this.props.pro_names && this.props.pro_names.map((elem, index) => {
                                                return (
                                                    <div style={{ padding: "4px", borderBottom: "1px solid" }} key={index}>
                                                        <div className="pro">
                                                            {elem}
                                                        </div>
                                                        <div className="pro-values">
                                                            **
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className="main-row">
                                            <div className="main-col w-50">
                                                <div className="info-box">
                                                    <div className="info-heading d-flex align-center border-bottom10">
                                                        <div>Amount Per Serving</div>
                                                        <div className="ml-auto">% Daily Value</div>
                                                    </div>
                                                    {this.props.downloadData && this.props.downloadData.map((elem, index) => {
                                                        if (index < 4) {
                                                            if (elem.supplement === "Total Fat" || elem.supplement === "Sodium") {
                                                                return (
                                                                    <div className="info-row" key={index}>
                                                                        <div className="info-col w-40 font-24 fw-600">{elem.supplement} {elem.total_value}{elem.unit === "no unit" ? "" : elem.unit} </div>
                                                                        <div className="info-cols font-24 fw-600 ml-auto text-right">{elem.dv_value != "**" ? (elem.dv_value) + "%" : (elem.dv_value)}</div>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (elem.supplement === "Saturated Fat" || elem.supplement === "Trans Fat") {
                                                                return (

                                                                    <div className="info-sub-row">
                                                                        <div className="info-blank-space"></div>
                                                                        <div className="info-row">
                                                                            <div className="info-col w-40">{elem.supplement} {elem.total_value}{elem.unit === "no unit" ? "" : elem.unit}</div>
                                                                            <div className="info-cols  font-24 fw-600 ml-auto text-right">{elem.dv_value != "**" ? (elem.dv_value) + "%" : (elem.dv_value)}</div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            }
                                                            else {

                                                            }
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="main-heading fw-500">Calories Per Serving: {this.props.calories}</div>
                                    </div>
                                    <div className="main-col w-66">
                                        <div className="main-row mt-20">

                                            <div className="main-col w-50">
                                                <div className="info-box">
                                                    <div className="info-heading d-flex align-center border-bottom10">
                                                        <div>Amount Per Serving</div>
                                                        <div className="ml-auto">% Daily Value</div>
                                                    </div>
                                                    {this.props.downloadData && this.props.downloadData.map((elem, index) => {
                                                        if (index < 10 && index > 3) {
                                                            if (elem.supplement === "Potassium" || elem.supplement === "Cholesterol" || elem.supplement === "Total Carbohydrates") {
                                                                return (
                                                                    <div className="info-row" key={index}>
                                                                        <div className="info-col w-40 font-24 fw-600">{elem.supplement} {elem.total_value}{elem.unit === "no unit" ? "" : elem.unit} </div>
                                                                        <div className="info-cols font-24 fw-600 ml-auto text-right">{elem.dv_value != "**" ? (elem.dv_value) + "%" : (elem.dv_value)}</div>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (elem.supplement === "Dietary Fiber" || elem.supplement === "Total Sugars") {
                                                                return (
                                                                    <div className="info-sub-row">
                                                                        <div className="info-blank-space"></div>
                                                                        <div className="info-row">
                                                                            <div className="info-col w-40">{elem.supplement} {elem.total_value}{elem.unit === "no unit" ? "" : elem.unit}</div>
                                                                            <div className="info-cols  font-24 fw-600 ml-auto text-right">{elem.dv_value != "**" ? (elem.dv_value) + "%" : (elem.dv_value)}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <div className="info-sub-row">
                                                                        <div className="info-blank-space"></div>
                                                                        <div className="info-row">
                                                                            <div className="info-col" style={{ paddingLeft: "18px" }}>{elem.supplement} {elem.total_value}{elem.unit === "no unit" ? "" : elem.unit}</div>
                                                                            <div className="info-cols  font-24 fw-600 ml-auto text-right">{elem.dv_value != "**" ? (elem.dv_value) + "%" : (elem.dv_value)}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        }

                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderTop: "4px solid #323232" }}></div>
                                        {this.props.downloadData && this.props.downloadData.map((elem, index) => {
                                            if (index >= 10) {
                                                return (
                                                    <div className="supplement-mg"><span className="circle"></span>
                                                        <pre>{elem.supplement}</pre>
                                                        <pre>  {elem.total_value}{elem.unit === "no unit" ? "" : elem.unit}</pre>
                                                        <pre>   {elem.dv_value != "**" ? (elem.dv_value) + "%" : (elem.dv_value)}</pre>
                                                    </div>
                                                )
                                            }

                                        })}
                                        <div className="info-dtl">
                                            <div>
                                                <strong>Other Ingredients: </strong>
                                                {this.props.pre_names && this.props.pre_names.map((elem, index) => {
                                                    return (
                                                        <span key={index}>
                                                            <pre> {elem}</pre>
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                            <div style={{ display: this.props.currentAge >= 4 ? 'block' : 'none' }}>
                                                <strong>Note: * </strong>Percent Daily Values are based on a 2,000-calorie diet.
                                            </div>
                                            <div style={{ display: this.props.currentAge <= 3 && this.props.currentAge >= 1 ? 'block' : 'none' }}>
                                                <strong>Note: *</strong>Percent Daily Values are based on a 1,000-calorie diet.
                                            </div>
                                            <div><strong>**</strong> Daily Value (DV) not established.</div>
                                            <div><img src={require("../../images/up-arrow.png")} style={{ width: "11px" }} />At the time of Manufacturer.</div>
                                            <div>
                                                <strong>Warning:</strong> Consult your physician before using any product if you are pregnant, nursing, trying to conceive, taking medication or have a medical condition.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </PdfContainer>
            </>
        );
    }
}

export default MultiPdfDownload;