import React, { Component } from "react";
import axios from "axios";
import Select from 'react-select';

import { baseURL } from "../../../configuration/constants";
import { onGridReady, getUserRole, getHeaders, simplifyModulePermissionData } from "../../helper";
import FloreAiIngredientTable from "./floreAiIngredientTable";
import CustomLoader from "../../custom_elements/CustomLoader";
import Config from "../../../configuration/config";

import { AgGridReact } from "ag-grid-react";
import { Button, Popup } from "semantic-ui-react";
import { Header, Message, Grid, Segment, TextArea, Form } from "semantic-ui-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { response_from_delete_api } from './FloreTagRenderer';
import ShowModal from "../../Pop Up/Modal";
import { number } from "prop-types";

let api, column_api;
let popUp_description = "";
var select_deselect_all = false;
let popUp_return_value = false;
class CreateAi extends Component {

	constructor(props) {
		super(props);
		this.child = React.createRef();
	}
	state = {
		detect_changes: [],
		is_popUp_disabled: false,
		selected_ids: [],
		selctedTagsSubmit: [],
		is_page_loading: true,
		render_value: "",
		showstore: true,
		showProps: false,
		samples: [],
		ingredients: [],
		formulaId: "",
		selectedTags: [],
		inventoryTags: [],
		checkbox1: false,
		checkbox2: false,
		checkbox3: false,
		checkbox4: false,
		selectBox: true,
		unsucessful_checkbox: [],
		sucessful_checkbox: [],

		unsucessful_checkbox1: false,
		unsucessful_checkbox2: false,
		unsucessful_checkbox3: false,
		unsucessful_checkbox4: false,
		unsucessful_checkbox5: false,

		unSuccesful_btn: false,
		showCheckbox: false,
		isSubmitActive: false,
		selectedTag: [],
		render_status: false,
		select_deselect_tags: false,
		tags_length: 0,
		selected_tags_length: 0,
		columnDefs: [
			{
				headerName: "Sample ID",
				field: "sample_id",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Formula ID",
				field: "formula_id",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Product Type",
				field: "product_type",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Type",
				field: "type",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
			{
				headerName: "Date Made",
				field: "date_made",
				sizeColumnToFit: true,
				filter: "agTextColumnFilter",
				sortable: true,
			},
		],
		disabledPermission: "",
		aiDashboardPermission: 0,
		dashboard: 0,
		toggle_option: null,

		comment: '',

	};

	blur = (e) => {
		if (e.target.value === "Successful") {
			this.setState({
				showstore: true,
				showProps: false,
			});
		}
		if (e.target.value === "Unsuccessful") {
			this.setState({
				showProps: true,
				showstore: false,
			});
		}
	};

	componentDidMount = () => {
		document.title = Config.ai.title.suceesMarker_display;
		this.getPro();
		this.getUserPermission()
	};
	changeCheckBox = () => {
		this.setState({
			checkbox1: false, checkbox2: false, sucessful_checkbox: [],
			checkbox3: false, checkbox4: false, comment: '', detect_changes: [],
			unsucessful_checkbox: [], isSubmitActive: false, selected_ids: [], selectedTag: []
		})
	}


	handleCheckboxClick = (e) => {

		let isClicked = e.target.checked;
		var sucessful_checkbox = [...this.state.sucessful_checkbox];
		var index = sucessful_checkbox.indexOf(e.target.value)

		if (index > -1) {
			sucessful_checkbox = sucessful_checkbox.filter(number => number !== sucessful_checkbox[index]);
		}
		else {
			sucessful_checkbox.push(e.target.value);
		}
		this.setState({ sucessful_checkbox, [e.target.name]: isClicked }, () => { this.isFormReady(); });
		this.collect_changes({
			name: e.target.name,
			action: "checkbox_selection",
			data: e.target.checked,
			value: e.target.value,
			message: "Checkbox will be in previous state? Do you want to execute"
		})
	};

	getPro = () => {
		axios
			.get(baseURL + "/flore_ai/formulations ", getHeaders())
			.then((res) => {
				let data = res.data;
				return data;
			})
			.then((samples) => {
				if (samples.length > 0) {
					this.setState({
						samples: samples.reverse(),
						is_page_loading: false
					});
				}
			});
	};

	getUserPermission = () => {

		let Role = getUserRole();
		let login = localStorage.getItem('login_success');
		if (login == 1) {
			axios
				.get(baseURL + "/users/PermissionDetails", getHeaders())
				.then(response => {
					let permissionData = null;

					permissionData = simplifyModulePermissionData(response.data);
					permissionData = permissionData.filter(data => {
						if (data.role_name === Role) {
							return data;
						}
					})

					let dashboard = 0;
					let disabledPermission;
					let aiDashboardPermission = permissionData.forEach(result => {
						if (result.module_name == "Flore AI Success Marker" && result.edit_permission === 1) {
							dashboard = 1;
							disabledPermission = false;
						}
					})
					if (dashboard == 0) {
						aiDashboardPermission = 0;
						disabledPermission = true;
					}
					else {

						aiDashboardPermission = 1;
					}
					this.setState({
						aiDashboardPermission,
						dashboard,
						disabledPermission,
					});
				})
				.catch(error => {
					alert("Fetching Display Grid | Error: ", error);
				});
		}
	}

	handleSelectedSample = (sample) => {
		this.setState({
			formulaId: sample.id,
			detect_changes: [],
			selectBox: false,
			toggle_option: null,
			selectedTag: [],
			comment: '',
			showCheckbox: true,
			sucessful_checkbox: [],
			unsucessful_checkbox: [],
			selected_ids: []
		}, () => {
			this.getFloreIngredientList();
		});
		var succesful_beacuse = document.getElementById("succesful_beacuse");
		var unsucessful_beacuse = document.getElementById("unsucessful_beacuse");
		succesful_beacuse.checked = false;
		unsucessful_beacuse.checked = false;
	};

	getFloreIngredientList = () => {
		axios
			.get(
				baseURL + `/flore_ai/inventory/${this.state.formulaId}`,
				getHeaders(),
			)
			.then((res) => {
				let data = res.data;
				return data;
			})
			.then((ingredients) => {
				this.fetch_ingrdients(ingredients);
			});
	};

	fetch_ingrdients = (ingredients) => {

		var selected_ids = [];
		var tags_length = 0;
		var selected_tags_length = 0;
		var j = [...this.state.selectedTag];
		if (ingredients.length > 0) {
			ingredients.forEach(element => {
				if (element.inventory_tags.length > 0) {
					element.inventory_tags && element.inventory_tags.forEach(items => {
						items.selected = false;
						tags_length++;
						if (j.length > 0 && j.includes(`${items.id} ${items.inventory_id}`)) {
							items.selected = true;
							selected_tags_length++;
							selected_ids.push(`${items.id} ${items.inventory_id}`);
						}
					});
				}
			});
		}
		if (tags_length !== 0 && this.state.toggle_option !== null) {
			this.child.current.tags_lengths(tags_length, selected_tags_length);
			setTimeout(() => {
				this.child.current.page_loading_false();
			}, 1000);
		}

		this.setState({
			ingredients,
			selected_ids,
			tags_length,
			selected_tags_length,
		});
		this.isFormReady();
	}

	handleCreateSuccessMarker = () => {
		if (this.state.selectedTag.length == 0 && this.state.toggle_option === true) {
			alert('Please select atleast one tag');
			return;
		}

		var packet;
		var url;

		if (this.state.toggle_option !== null && this.state.toggle_option === true) {
			let selTags = [];
			let invTag = [];

			let x = this.state.selectedTag;
			for (let i = 0; i < x.length; i++) {
				let y = x[i].split(" ");
				selTags.push(y[0]);
				invTag.push(y[1]);
			}

			packet = {
				formula_id: this.state.formulaId,
				inventory_id: invTag,
				success_type: 1,
				success_measure: this.state.sucessful_checkbox,
				tag_id: selTags,
				comment: this.state.comment
			};
			url = '/flore_ai/create';
		}
		else {

			packet = {
				formula_id: this.state.formulaId,
				success_type: 2,
				success_measure: this.state.unsucessful_checkbox,
				comment: this.state.comment
			};
			url = '/flore_ai/unsuccess';

		}

		axios.post(baseURL + url, packet, getHeaders())
			.then((res) => {
				if (res.data.success == true) {
					var msg = res.data.message || "Formula Marked";
					toast.success(msg);
					this.setState({
						is_page_loading: false
					})
					window.location.reload();
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	isFormReady = () => {
		this.setState({ isSubmitActive: (this.state.selected_ids.length !== 0 && this.state.sucessful_checkbox.length !== 0) ? true : false })
	};
	isUnsuccesful_form = (e) => {
		var unsucessful_checkbox = [...this.state.unsucessful_checkbox];
		var index = unsucessful_checkbox.indexOf(e.target.value)
		if (index > -1) {
			unsucessful_checkbox = unsucessful_checkbox.filter(number => number !== unsucessful_checkbox[index]);
		}
		else {
			unsucessful_checkbox.push(e.target.value);
		}
		this.setState({
			unsucessful_checkbox,
			isSubmitActive: (unsucessful_checkbox.length > 0) ? true : false
		});
	};

	event = () => {
		let removed_tag_arr = [];
		let formulation_id = [];
		let inventory_id = [];
		let organism_id = [];
		response_from_delete_api.forEach((item) => {
			if (!inventory_id.includes(item.inventory_id)) {
				inventory_id.push(item.inventory_id);
			}
			if (!organism_id.includes(item.organism_id)) {
				organism_id.push(item.organism_id);
				formulation_id.push(item.formulation_id);
			}
			removed_tag_arr.push({ tag_id: item.tag_id, tag_name: item.tag_name });
		});
		if (response_from_delete_api.length !== 0) {
			this.collect_changes({
				formulation_id: formulation_id, inventory_id: inventory_id, organism_id: organism_id, tags: removed_tag_arr,
				action: "tag_readded", message: "Removed Tags will be added back! Do you want to readded those tags"
			})
		}
		this.getFloreIngredientList()
	}

	getRemoved = () => {
		this.getFloreIngredientList()
	}
	selected_tag = (tag) => {
		this.setState({ selectedTag: tag });
		this.getFloreIngredientList();
	}
	collect_changes = (value) => {
		var changes = [...this.state.detect_changes];
		changes.push(value);
		this.setState({ detect_changes: changes });
	}

	undo_btn = () => {

		if (this.state.detect_changes.length > 0) {
			var detect_changes = [...this.state.detect_changes];
			var data = detect_changes.pop();
			var filter_changes = [];

			popUp_description = data.message;
			this.setState({ is_popUp_disabled: true })
		}
	}
	Modal_return_value = (value, message) => {

		if (value) {
			var detect_changes = [...this.state.detect_changes];
			var data = detect_changes.pop();
			switch (data.action) {
				case "checkbox_selection": {
					var data = this.state.detect_changes.pop();
					var sucessful_checkbox = this.state.sucessful_checkbox;
					var index = sucessful_checkbox.indexOf(data.value)

					if (!data.data) {
						sucessful_checkbox.push(data.value);
					}
					else {
						sucessful_checkbox = sucessful_checkbox.filter(number => number !== sucessful_checkbox[index]);
					}
					this.setState({ [data.name]: !data.data, sucessful_checkbox }, () => { this.isFormReady(); });
					detect_changes.filter(item => item.action == data.action);
					this.setState({ detect_changes });
					return;
				}
				case "selection_tag": {
					this.child.current.page_loading_true();
					this.setState({ is_popUp_disabled: false });
					var tag = [];
					var selected_tag = [...this.state.selectedTag];
					if (selected_tag.length > 0) {
						tag = selected_tag.filter(function (item) {
							return !data.data.includes(item);
						})
					}
					detect_changes.filter(item => item.action == data.action);
					this.setState({ selectedTag: tag, detect_changes }, () => { this.getFloreIngredientList(); });
					return;
				}
				case "deSelection_tag": {
					this.child.current.page_loading_true();
					var tag = [];
					var selectedTag = [...this.state.selectedTag];
					if (selectedTag.length > 0) selectedTag.push(data.data[0]);
					detect_changes.filter(item => item.action == data.action);
					this.setState({ selectedTag, detect_changes }, () => { this.getFloreIngredientList(); });
					return;
				}
				case "tag_inserted": {
					this.child.current.page_loading_true();
					this.setState({ is_popUp_disabled: false });
					var tags = [];
					var selectedTag = [...this.state.selectedTag];
					data.data.forEach(elem => {
						let tagIds = elem.split(' ');
						tags.push(tagIds[0]);
					})
					let datas = { tags: tags }
					axios
						.post(baseURL + "/formulation-tags-delete", datas, getHeaders())
						.then(res => {
							if (res.data.success) {
								this.event();
							}
						})
					detect_changes.filter(item => item.action == data.action);
					this.setState({ detect_changes }, () => { this.getFloreIngredientList(); });
					return;
				}
				case "tag_readded": {
					this.child.current.page_loading_true();
					this.setState({ is_popUp_disabled: false });
					var dataAction = data.action;
					delete data.action;
					axios
						.post(baseURL + "/link-tags", data, getHeaders())
						.then(res => {
							if (res.data.is_exist == 0) {
								toast.info("Tags already exists");
								return;
							}

						});
					detect_changes.filter(item => item.action == dataAction);
					this.setState({ detect_changes });
					setTimeout(() => { this.getFloreIngredientList(); }, 3000);
					return;
				}
				case "select_all": {
					this.child.current.page_loading_true();
					this.setState({ is_popUp_disabled: false });
					var tag = [];
					var selectedTag = [...this.state.selectedTag];
					selectedTag = data.data;
					detect_changes.filter(item => item.action == data.action);
					var x = detect_changes.filter(function (item) {
						return !["deSelection_tag", "selection_tag"].includes(item.action);
					})

					this.setState({ selectedTag, detect_changes: x }, () => { this.getFloreIngredientList(); });
					return;
				}
				case "deSelect_all": {
					this.child.current.page_loading_true();
					this.setState({ is_popUp_disabled: false });
					var tag = [];
					var selectedTag = [...this.state.selectedTag];
					selectedTag = [];
					selectedTag = data.data;
					detect_changes.filter(item => item.action == data.action);
					this.setState({ selectedTag, detect_changes }, () => { this.getFloreIngredientList(); });
					return;
				}
				default: return;
			}
		}

	}

	pop_up_open = () => {
		popUp_description = "";
		this.setState({ is_popUp_disabled: false });
	};


	render() {


		const succesful_beacuse = [
			{ name: "Improved Microbiome Score", value: 1, check_field: "checkbox1" },
			{ name: "Positive Customer Feedbacks", value: 2, check_field: "checkbox2" },
			{ name: "Increase in Abundance of Probiotics", value: 3, check_field: "checkbox3" },
			{ name: "Increase in Bad Microbes Score", value: 4, check_field: "checkbox4" },
		];
		const unsuccesful_beacuse = [
			{ name: "Decreased Microbiome Score", value: 5 },
			{ name: "Negative Customer Feedbacks", value: 6 },
			{ name: "Decrease in Bad Microbes Score", value: 8 },
			{ name: "Decrease In Abundance of Beneficial/Probiotic microbes", value: 7 },
			{ name: "No Effect", value: 9 },
		]

		return (
			<React.Fragment>
				{this.state.is_page_loading && <CustomLoader />}
				<br />
				<Message>
					<Header as="h2" textAlign="center">
						{Config.ai.title.suceesMarker}
					</Header>
				</Message>
				{this.state.is_popUp_disabled && (
					<ShowModal
						Description={popUp_description}
						Modal_return_value={this.Modal_return_value}
						pop_up_open={this.pop_up_open}
						modal_style={"32rem"}
					/>
				)}
				<Segment className="transparent">
					<Grid.Row>
						<Grid.Column>
							<Segment
								compact
								raised
								padded
								style={{ display: "block", marginTop: "0" }}>
								<Grid columns="equal">
									<Grid.Row>
										<Grid.Column>
											<div
												id="myGrid"
												className="ag-theme-balham"
												ag-grid="gridOptions"
												style={{
													height: "100%",
													width: "100%",
												}}>
												<AgGridReact
													rowHeight="30"
													rowSelection="single"
													onRowClicked={(e) => {
														let sample = e.data;
														this.handleSelectedSample(sample);
													}}
													onGridReady={(params) => {
														api = params.api;
														api.showLoadingOverlay();
														api.sizeColumnsToFit();
														column_api =
															params.column_api;
														onGridReady(
															params,
															api,
															column_api,
															this.props
																.initial_sort,
														);
													}}
													animateRows={true}
													pagination={true}
													paginationPageSize={10}
													paginationNumberFormatter={(
														params,
													) => {
														return (
															"[" +
															params.value.toLocaleString() +
															"]"
														);
													}}
													columnDefs={
														this.state.columnDefs
													}
													floatingFilter={true}
													rowData={this.state.samples}
												/>
											</div>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<br />
					{this.state.showCheckbox && (
						<div>

							<input type="radio" name="fav_language" id="succesful_beacuse" onChange={(e) => {
								this.setState({
									toggle_option: true
								}, () => { this.changeCheckBox(); })
							}} /> &nbsp; &nbsp;
							<label for="succesful_beacuse">Successful because ?</label> &nbsp; &nbsp; &nbsp;
							<input type="radio" name="fav_language" id="unsucessful_beacuse" onChange={(e) => {
								this.setState({
									toggle_option: false
								}, () => { this.changeCheckBox(); })
							}} />&nbsp; &nbsp;
							<label for="unsucessful_beacuse">Unsuccessful because ?</label><br />
						</div>
					)}

					{(this.state.toggle_option !== null && this.state.toggle_option === true) && (
						<div>
							<Grid.Row style={{ paddingTop: "3%" }}>

								<Grid.Column
									style={{
										visibility: this.state.showstore
											? "visible"
											: "hidden",
									}}>
									<div>
										<div
											style={{
												fontWeight: "bold",
												fontSize: "15px",
												paddingBottom: "5px",
											}}>
											Successful because ?
										</div>
										<form>
											{succesful_beacuse && succesful_beacuse.map((succesful_arr) => {

												return <label className="microbe">
													<input
														style={{ marginRight: "7px" }}
														type="checkbox"
														value={succesful_arr.value}
														onChange={(e) => this.handleCheckboxClick(e)}
														name={succesful_arr.check_field}
														id={succesful_arr.name}
														disabled={this.state.disabledPermission}
														checked={this.state[`${succesful_arr.check_field}`]}
													></input>

													{succesful_arr.name}
												</label>

											})}
										</form>
									</div>
								</Grid.Column><br />
								<Grid.Column>
									<Form>
										<TextArea placeholder='Tell us more' onChange={(e) => { this.setState({ comment: e.target.value }) }} />
									</Form>
								</Grid.Column>

								<Grid.Row style={{ paddingTop: "3%" }}>
									<FloreAiIngredientTable
										data={this.state.ingredients}
										event={this.event}
										selected_tag={this.selected_tag}
										handleTagsArray={this.handleTagsArray}
										select_deselect_tags={this.state.select_deselect_tags}
										getRemoved={this.getRemoved}
										disabledPermission={this.state.disabledPermission}
										arraySuccessIngredient={this.arraySuccessIngredient}
										selected_ids={this.state.selected_ids}
										tags_length={this.state.tags_length}
										selected_tags_length={this.state.selected_tags_length}
										ref={this.child}
										render_value={this.state.render_value}
										undo_btn={this.undo_btn}
										collect_changes={this.collect_changes}
									>
									</FloreAiIngredientTable>
								</Grid.Row>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<div className="space-req"></div>
								</Grid.Column>
							</Grid.Row>
						</div>
					)}
					{(this.state.toggle_option == null) && (
						<div ><br /><br /></div>
					)}

					{(this.state.toggle_option !== null && this.state.toggle_option === false) && (
						<Grid.Row style={{ height: "11rem" }}>
							<Grid.Column
								style={{
									visibility: this.state.showstore
										? "visible"
										: "hidden",
								}}>
								<br />
								<br />
								<div>

									<div
										style={{
											fontWeight: "bold",
											fontSize: "15px",
											paddingBottom: "5px",
										}}>
										Unsuccessful because ?
									</div>
									<form>
										{unsuccesful_beacuse && unsuccesful_beacuse.map((unsuccesful_arr) => {

											return <label className="microbe unsuccesful-checkbox" style={{ whiteSpace: "nowrap" }}>
												<input
													type="checkbox"
													value={unsuccesful_arr.value}
													onChange={(e) => {
														this.isUnsuccesful_form(e)
													}
													}
													name={unsuccesful_arr.name}
													disabled={this.state.disabledPermission}
													id={unsuccesful_arr.name}
													style={{ marginRight: "5px" }}
												></input>
												{unsuccesful_arr.name}
											</label>

										})}

									</form>
								</div>
							</Grid.Column><br />
							<Grid.Column>
								<Form>
									<TextArea placeholder='Tell us more' onChange={(e) => { this.setState({ comment: e.target.value }) }} />
								</Form>
							</Grid.Column>
						</Grid.Row>

					)}

					<Grid.Row>
						<Grid.Column>
							<Grid textAlign="center" style={{ marginTop: "6rem" }}>
								<Button.Group>
									<Popup
										trigger={
											<Button
												content="Submit"
												icon="check"
												labelPosition="left"
												positive
												disabled={!this.state.isSubmitActive}
												onClick={this.handleCreateSuccessMarker}
											/>
										}
										content="Enter the Formulation Information."
									/>
								</Button.Group>
							</Grid>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row>
						<Grid.Column>
							<div className="extra-space"></div>
						</Grid.Column>
					</Grid.Row>

				</Segment>
			</React.Fragment>
		);
	}
}

export default CreateAi;
