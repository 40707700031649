
import React, { Component } from "react";
import { TextArea, Grid, Segment, Form, Input, Popup, Button } from "semantic-ui-react";
import { notEnterZeroBefore } from "../../helper";
import "./index.css";

const labelModalStyles = {
    fontSize: '13px',
};

const headingTitleStyles = {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '9px'
};

const logsInputBox = {
    height: '28px',
    marginTop: '5px',
};

class FormullaInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            receive_date: "",
            open_date: "",
            expiration_date: "",
            updated_at: "",
            created_at: "",
        };
    }

    AnswerKeyPressMonth = (e) => {
        notEnterZeroBefore(e)
    }

    render() {


        return (
            <Segment>
                <Grid>
                    <div style={headingTitleStyles}>Formula Info</div>
                    <Grid.Row className="block" columns={3}>
                        <Grid.Column>
                            <Form.Field required fluid="true">
                                <Popup
                                    trigger={<label style={labelModalStyles}>Number of Months</label>}
                                    content="Enter the Number of Months."
                                />
                                <Input
                                    id="answer_key_press"
                                    name="number_of_months"
                                    fluid
                                    placeholder="Months"
                                    value={this.props.number_of_months === null ? "" : this.props.number_of_months}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                    onKeyPress={this.AnswerKeyPressMonth}
                                />
                            </Form.Field>
                        </Grid.Column>


                        <Grid.Column>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Is Prime Added?</label>}
                                    content="Enter Is Prime Added."
                                />
                                <Input
                                    value={this.props.is_prime}
                                    className="isPrime"
                                    disabled={this.props.is_completed === "Yes" ? true : false}
                                >
                                </Input>
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Payment</label>}
                                    content="Enter the Payment."
                                />
                                <select
                                    className="inventory"
                                    value={this.props.payment_type}
                                    onChange={this.props.createAll}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                >
                                    {
                                        this.props.Payment && this.props.Payment.map((elem, i) => {
                                            return (
                                                <option key={i}>
                                                    {elem.type}
                                                </option>

                                            )
                                        })
                                    }
                                </select>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <div style={headingTitleStyles}>Notes</div>
                    <Grid.Row className="block" columns={3}>
                        <Grid.Column>
                            <Form.Field required fluid="true">
                                <Popup
                                    trigger={<label className="notesInventory pl-0" style={labelModalStyles}>Admin Notes</label>}
                                    content="Enter the Admin Notes."
                                />
                                <TextArea
                                    className="notes white-textarea"
                                    name="admin_notes"
                                    fluid
                                    placeholder=" Add Notes here"
                                    value={this.props.admin_notes === null ? "" : this.props.admin_notes}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label className="notesInventory pl-0" style={labelModalStyles}>Operator Notes</label>}
                                    content="Enter the Operator Notes."
                                />
                                <TextArea
                                    className="notes white-textarea"
                                    name="operator_notes"
                                    fluid
                                    placeholder="Add Operator Notes here"
                                    value={this.props.operator_notes === null ? "" : this.props.operator_notes}
                                    onChange={this.props.onInputChange}
                                    disabled={(this.props.disabledPermissionOnEdit === "")?false:this.props.disabledPermissionOnEdit}
                                />
                            </Form.Field>
                        </Grid.Column>              
                        <Grid.Column>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label className="notesInventory pl-0" style={labelModalStyles}>Reformulation Notes</label>}
                                    content="Reformulation Notes."
                                />
                                <TextArea
                                    className="notes white-textarea"
                                    name="reformulation_notes"
                                    fluid
                                    placeholder="Reformulation Notes here"
                                    value={this.props.reformulation_notes}
                                    onChange={this.props.onInputChange}
                                    disabled={this.props.disabledPermissionOnEdit || this.props.is_completed === "Yes" ? true : false}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label className="notesInventory pl-0" style={labelModalStyles}>QA Notes</label>}
                                    content="QA Notes."
                                />
                                <TextArea
                                    className="notes white-textarea"
                                    name="qa_note"
                                    fluid
                                    placeholder="QA Notes"
                                    value={this.props.qa_notes}
                                    disabled
                                />
                            </Form.Field>
                        </Grid.Column>

                    </Grid.Row>

                    <div style={headingTitleStyles}>Logs</div>
                    <Grid.Row className="block">

                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Date Created</label>}
                                    content="Date Created."
                                />
                                <Input
                                    disabled
                                    fluid
                                    value={this.props.date_created}
                                    style={logsInputBox}
                                    placeholderText="Click here to select date"
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Date Made</label>}
                                    content="Date Made."
                                />
                                <Input
                                    disabled
                                    fluid
                                    value={this.props.date_made}
                                    style={logsInputBox}
                                    placeholderText="Click here to select date"
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Created By</label>}
                                    content="Created By."
                                />
                                <Input
                                    disabled
                                    fluid
                                    value={this.props.refill_type === "Automatic" ? "Automatic" : this.props.username}
                                    style={logsInputBox}
                                    placeholderText="Click here to select date"
                                    readonly={true}
                                />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>Last Updated At</label>}
                                    content="Enter Last Updated At."
                                />
                                <Input
                                    disabled
                                    fluid
                                    value={this.props.updated_at}
                                    style={logsInputBox}
                                    placeholderText="Click here to select date"
                                    readonly={true}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>QA accepted by</label>}
                                    content="QA accepted by."
                                />
                                <Input
                                    disabled
                                    fluid
                                    value={this.props.qa_accepted_by}
                                    style={logsInputBox}
                                    placeholderText="QA accepted by"
                                    readonly={true}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field required fluid="true" >
                                <Popup
                                    trigger={<label style={labelModalStyles}>QA accepted date and time</label>}
                                    content="QA accepted date and time."
                                />
                                <Input
                                    disabled
                                    fluid
                                    value={this.props.qa_verified_at}
                                    style={logsInputBox}
                                    placeholderText="QA accepted date and time"
                                    readonly={true}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Grid>
                                <Button.Group style={{ marginBottom: "12px" }}>
                                    <Popup
                                        trigger={
                                            <Button positive onClick={this.props.onSubmitsupplement}>
                                                Submit
                                            </Button>
                                        }
                                        content="Submit the Data to the Database."
                                    />
                                </Button.Group>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Segment>
        );
    }
}

export default FormullaInfo;
