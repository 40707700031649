//-------------------------------------------------------------------//
// Module: Auth                                                      //
// Tasks: 1) Checks if the user token is expired                     //
//        2) Handles login                                           //
//        3) Handles logout                                          //
//        4) Check if the user role is authorized                    //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import { getSessionStoredJsonItem } from "../components/helper";

class Auth {
  constructor() {
    this.is_authenticated = false;
  }

  /**
   * Handles the login click.
   *
   * @param {object} user_object
   * @param {method} _callback
   */
  login(user_object, _callback) {
 
    // Checks if the token has expired or not.
    let exp_date = new Date(user_object.expires_at);
    let cur_date = new Date();

    if (exp_date < cur_date) {
      this.is_authenticated = false;
    } else {
      sessionStorage.setItem("user", JSON.stringify(user_object));
      this.is_authenticated = true;
    }

    if (_callback) _callback();
  }

  /**
   * Handles logout click.
   *
   * @param {method} _callback
   */
  logout(_callback) {
    this.is_authenticated = false;
    sessionStorage.removeItem("user");
    if (_callback) _callback();
  }

  /**
   * Checks if the use has logged in.
   */
  isAuthenticated() {
    this.is_authenticated = getSessionStoredJsonItem("user") ? true : false;
    return this.is_authenticated;
  }

  /**
   * Checks if the user is authorized.
   *
   * @param {object} rest
   * @returns {boolean}
   */
  isAuthorized(rest) {
    if (!getSessionStoredJsonItem("user")) return false;

    // let role_name = getSessionStoredJsonItem("user").role_name; //ex: admin
    // let module_name = rest.module_name; //ex: formulations
    // let path = rest.path; //ex: display_formulations
    // let path_action = path.substr(0, path.indexOf("_"));
    
    let is_authorized = true;

    // if (
    //   (role_name !== "admin" &&
    //     role_name !== "manager" &&
    //     role_name !== "technician") ||
    //  // (module_name === "users" && role_name !== "admin") ||
    //   (module_name === "inventory" &&
    //     path_action === "create" &&
    //     role_name !== "admin" &&
    //     role_name !== "manager") ||
    //   (module_name === "formulations" &&
    //     path_action === "create" &&
    //     role_name !== "admin" &&
    //     role_name !== "manager")
    // ) {
    //   is_authorized = false;
    // }

    return is_authorized;
  }
}

export default new Auth();
