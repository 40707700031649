import React from 'react';
import './index.css';

function Footer() {
    return (
        <footer className="formulation-rationale__footer">
            <p>&copy; 2019 Sun Genomics.</p>
        </footer>
    )
}

export default Footer;