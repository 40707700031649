
import CustomDivider from "../custom_elements/CustomDivider";
import React, { Component } from "react";
import {
  Form,
  Input,
  Grid,
  Popup,
  Segment,
  Dropdown,
  Radio,
  Label,
} from "semantic-ui-react";


class Prime extends Component {

  handleChange = (e) => {
    this.props.selectCapsuleSizeFunction(e.target.value)
  }

  render() {

    return (
      <React.Fragment>
        <Segment className="transparent">
          <Grid columns="equal">
            <CustomDivider h_size="h3" icon_name="flask" title="Prime" />

            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Form.Field required fluid="true">
                    <Popup
                      trigger={<label>Is Prime Added?</label>}
                      content="Is Prime Added?"
                    />
                    <Radio
                      toggle
                      onClick={this.props.changePrimeAddition}
                      checked={this.props.is_prime_added === true}
                      disabled={this.props.disabledPermission || this.props.is_prime_added_disabled}
                    />
                  </Form.Field>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Capsule Size</label>}
                    content="The Capsule Size."
                  />
                  <select onChange={this.handleChange}
                    value={this.props.is_prime_added === true ? this.props.prime_capsule_size : ""}
                    disabled={this.props.is_prime_added === true ? false : true}
                  >
                    {
                      this.props.options && this.props.options.map((elem, i) => {
                        return (
                          <option key={i}>
                            {elem.value}
                          </option>

                        )
                      })
                    }
                  </select>

                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Total Amount Added</label>}
                    content="Total Amount Added."
                  />

                  <Input
                    name="prime_total_amount_added"
                    value={
                      this.props.is_prime_added
                        ? Math.floor(this.props.prime_total_amount_added * 1000) / 1000
                        : 0
                    }
                    disabled={this.props.disabledPermission}
                    className="full_opacity"
                    icon="weight"
                    iconPosition="left"
                    label={{ content: "g" }}
                    labelPosition="right"
                    placeholder="Total Amount Added"

                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Flourish Ingredient</label>}
                    content="The Flourish"
                  />
                  {/* The organism name will always be FLOURISH */}
                  <Input
                    value="Flourish"
                    disabled={this.props.disabledPermission}
                    className="full_opacity"
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Flourish"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Flourish Amount Added</label>}
                    content="Enter the Flourish Amount Added."
                  />
                  <Input
                    id="prime_flourish_amount_added"
                    name="prime_flourish_amount_added"
                    disabled={this.props.disabledPermission ? this.props.disabledPermission : (this.props.is_prime_added ? false : true)}
                    onChange={this.props.onChange}
                    value={
                      this.props.is_prime_added
                        ? this.props.prime_flourish_amount_added
                        : 0
                    }
                    icon="weight"
                    iconPosition="left"
                    label={{ content: "g" }}
                    labelPosition="right"
                    placeholder="Flourish Amount Added"
                    type="number"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required fluid="true">
                  <Popup
                    trigger={<label>Filler Ingredient</label>}
                    content="Pick the Flourish Organism."
                  />
                  <Dropdown
                    id="prime_filler_inventory_id"
                    name="prime_filler_inventory_id"
                    disabled={this.props.disabledPermission ? this.props.disabledPermission : (this.props.is_prime_added ? false : true)}
                    style={{ opacity: 1, backgroundColor: "#b8sfcff" }}
                    onChange={(event, data) => {
                      this.props.onChange(event, {
                        name: data.name,
                        value: data.value
                      });
                    }}
                    value={
                      this.props.is_prime_added
                        ? this.props.prime_filler_inventory_id
                        : 0
                    }
                    fluid
                    search
                    selection
                    options={
                      this.props.inventory_options
                        ? this.props.inventory_options
                        : []
                    }
                    className={
                      this.props.form_errors.prime_filler_inventory_id !== ""
                        ? "validation_error_input"
                        : ""
                    }
                  />
                </Form.Field>
                {this.props.form_errors.prime_filler_inventory_id && (
                  <Label basic color="red" pointing>
                    {this.props.form_errors.prime_filler_inventory_id}
                  </Label>
                )}
              </Grid.Column>
              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Filler Amount Added</label>}
                    content="Enter the Filler Amount Added."
                  />
                  <Input
                    id="prime_filler_amount_added"
                    name="prime_filler_amount_added"
                    type="number"
                    disabled={this.props.disabledPermission ? this.props.disabledPermission : (this.props.is_prime_added ? false : true)}
                    onChange={this.props.onChange}
                    value={this.props.is_prime_added ? this.props.prime_filler_amount_added : 0}
                    icon="weight"
                    iconPosition="left"
                    label={{ content: "g" }}
                    labelPosition="right"
                    placeholder="Filler Amount Added"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row />
            <Grid.Row />
          </Grid>
        </Segment>
      </React.Fragment>
    );
  }
}

export default Prime;
