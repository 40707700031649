//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: Flourish                                            //
// SubSubSubModule: Organisms                                        //
// SubSubSubSubModule: Organism                                      //
// Tasks: 1) Receives and displays Individual Organism               //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//

import React, { Component } from "react";
import {
  Grid,
  Segment,
  Form,
  Input,
  Button,
  Popup
} from "semantic-ui-react";

import $ from 'jquery';

class  RefillOrganism extends Component {

  AnswerKeyPress = () => {
    $("#amount_added").on("input", function () {
      if (/^0/.test(this.value)) {
        this.value = this.value.replace(/^0/, "")
      }
    });
  }

  render() {
    let amountAddedstyle = localStorage.getItem('rowSelectValue');
    let inventoryExists = "";

    if (amountAddedstyle == 0) {
      this.props.inventory_options.map(result => {

        if (inventoryExists != 1) {
          if (result.text === this.props.organism.inventory.organism_barcode) {

            inventoryExists = 1;
            return inventoryExists;

          }
          else {
            inventoryExists = 0;
          }
        }
      })

      let organismIndex = this.props.inventory_options.findIndex(element => element.key == this.props.organism.inventory.id);
      if (organismIndex >= 0) {
        this.props.inventory_options[organismIndex].active = false
      }


    }

    // console.log(this.props.organism);
    return (
<Segment style={{ width: "98.6%" }}>
        <Grid>

        {this.props.organism &&     (<Grid.Row className="Refillblock" >
            <Grid.Column width={5}>
              <Form.Field required fluid="true">
                <Popup
                  trigger={<label>Ingredient Barcode</label>}
                  content="Enter the Ingredient Barcode."
                />
                <Input
                  id={"refillInventory_id" + this.props.organism.inventory_id}
                  name={"refillInventory_id" + this.props.index}
                  style={{opacity: 1}}

                    className= {(this.props.organism.background_color == undefined || this.props.organism.background_color == null)? "red-bg" :
                  (
                     this.props.organism.background_color !== null &&
                     this.props.organism.background_color !== " "
                   )
                   ?this.props.organism.background_color == "green" ? "green-bg":"yellow-bg"
                   :""}

                  disabled
                  value={this.props.organism.inventory.organism_barcode}

                  fluid
                  search
                  selection

                />

              </Form.Field>
            </Grid.Column>

            <Grid.Column width={3}    >
              <Form.Field required fluid="true" >
                <Popup
                  trigger={<label>Amount Added</label>}
                  content="Enter the amount added."
                />
                <Input
                  // className="refillInput "
                  className= {(this.props.organism.background_color == undefined || this.props.organism.background_color == null)? "red-bg" :
                  (
                     this.props.organism.background_color !== null &&
                     this.props.organism.background_color !== " "
                   )
                   ?this.props.organism.background_color == "green" ? "green-bg":"yellow-bg"
                   :""}
                  //id={this.props.inventory_options.find(element => element.key == this.props.organism.inventory_id && element.status) ? "amount_background" + (this.props.organism.inventory_id) : "amount_added" + (this.props.organism.inventory_id)}
                  id={"amount_background" + this.props.organism.inventory_id}
                  type="number"
                  style={{ opacity: 1, width: "80%"}}
                  name={"amount_background" + this.props.organism.inventory_id}
                  onChange={(e) =>
                     this.props.onChangeOf_organism(
                      Number(e.target.value),
                      this.props.organism.inventory_id,
                      this.props.organism.inventory.organism_barcode,
                      )}
                  disabled={this.props.organism.background_color == undefined}
                  value={ this.props .organism.amount_added }
                  onClick={this.handleClick}
                  onKeyPress={this.AnswerKeyPress}
                  icon="weight"
                  iconPosition="left"
                  label={{ content: "g" }}
                  labelPosition="right"
                  placeholder="Amount Added"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={3}>
              <Form.Field fluid="true">
                <Popup
                  trigger={<label>Concentration</label>}
                  content="Enter the Concentration."
                />
                <Input
                  id="concentration"
                  name="concentration"
                  style={{ opacity: 1, width: "80%", backgroundColor: "red" }}
                  onChange={this.props.onChange}
                  value={
                    this.props.organism.inventory.concentration
                      ? this.props.organism.inventory.concentration
                      : ""
                  }
                  className="full_opacity"
                  disabled
                  icon="warning sign"
                  iconPosition="left"
                  placeholder="Concentration"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={3}>
              <Form.Field fluid="true">
                <Popup
                  trigger={<label>Total CFU</label>}
                  content="Enter Total CFU."
                />
                <Input
                  id="final_concentration"
                  name="final_concentration"
                  style={{ opacity: 1, width: "80%", backgroundColor: "red" }}
                  onChange={this.props.onChange}
                  value={
                    this.props.organism.amount_added * this.props.organism.inventory.concentration
                      ? this.props.organism.amount_added * this.props.organism.inventory.concentration
                      : ""
                  }
                  className="full_opacity"
                  disabled
                  icon="warning sign"
                  iconPosition="left"
                  placeholder="Final Concentration"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={1} style={{ textAlign: "right" }}>
              <Form.Field fluid="true">
                <label style={{ visibility: "hidden" }}>.</label>
                <Popup
                  trigger={
                    <Button
                      onClick={() => {
                        const r = window.confirm("Do you really want to delete this organism?");
                        if (r == true) {
                          let organismIndex = this.props.inventory_options.findIndex(element => element.key == this.props.organism.inventory.id);
                          let zero = this.props.organism.amount_added * this.props.organism.inventory.concentration;

                          if (organismIndex >= 0) {
                            this.props.inventory_options[organismIndex].active = true

                          }
                          this.props.onDeleteOrganismClick(
                            this.props.organism.inventory.organism_barcode,
                            "Refill_organism"
                          )

                            

                        } else {
                          return false;
                        }
                      }}
                      icon="delete"
                      disabled={this.props.is_disabled}
                      negative
                      circular
                    />
                  }
                  content="Delete this Organism."
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>)}
        </Grid>
      </Segment>
    );
  }
}

export default RefillOrganism;
