//-------------------------------------------------------------------//
// Module: Formulations                                              //
// SubModule: CreateFormulation                                      //
// SubSubModule: Flourish                                            //
// Tasks: 1) Receives and displays Flourish                          //
// Author: Yash P Shah                                               //
//-------------------------------------------------------------------//
// custom_elements/CustomDivider
import CustomDivider from "../../custom_elements/CustomDimmer";
import Organisms from "./Organisms";

import React, { Component } from "react";
import { Form, Input, Grid, Popup, Segment } from "semantic-ui-react";

class Flourish extends Component {
  state = {};

  handleChange = (e) => {
    this.props.selectCapsuleSizeFunction(e.target.value)
  }

  render() {

    return (
      <React.Fragment>
        <Segment className="transparent">
          <Grid columns="equal">
            <CustomDivider h_size="h3" icon_name="flask" title="Flourish" />
            <Grid.Row>
              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Capsule Size</label>}
                    content="Enter the Capsule Size."
                  />
                  <select onChange={this.handleChange}
                  disabled={this.props.is_number_of_month_disabled}
                    value={this.props.flourish_capsule_size}>
                    {
                      this.props.options && this.props.options.map((elem, i) => {
                        return (
                          <option key={i}>
                            {elem.value}
                          </option>
                        )
                      })
                    }
                  </select>

                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Number of Months (Ref.)</label>}
                    content="Reference to the Number of Months."
                  />
                  <Input
                    name="number_of_months"
                    disabled
                    className="full_opacity"
                    onChange={this.props.onChange}
                    value={this.props.number_of_months}
                    icon="calendar alternate outline"
                    iconPosition="left"
                    placeholder="Number of Months"
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field fluid="true">
                  <Popup
                    trigger={<label>Formulation Factor (Ref.)</label>}
                    content="Enter the Formulation Factor."
                  />
                  <Input
                    name="formulation_factor"
                    disabled
                    className="full_opacity"
                    onChange={this.props.onChange}
                    value={this.props.formulation_factor}
                    icon="warning sign"
                    iconPosition="left"
                    placeholder="Formulation Factor"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row />

            <Grid.Row>
              <Grid.Column>
                <Organisms
                  manual_add_organism={this.props.manual_add_organism}
                  onChangeOf_organism={this.props.onChangeOf_organism}
                  organisms={this.props.organisms}
                  organism_barcode={this.props.organism_barcode}
                  inventory_id={this.props.inventory_id}
                  amount_added={this.props.amount_added}
                  concentration={this.props.concentration}
                  final_concentration={this.props.final_concentration}
                  scrap_amount={this.props.scrap_amount}
                  final_amount_added={this.props.final_amount_added}
                  total_cfu={this.props.total_cfu}
                  refillInventory_id={this.props.refillInventory_id}
                  refillInventory_id0={this.props.refillInventory_id0}
                  refillInventory_id1={this.props.refillInventory_id1}
                  refillInventory_id2={this.props.refillInventory_id2}
                  refillInventory_id3={this.props.refillInventory_id3}
                  refillamountAdded={this.props.refillamountAdded}
                  refillAmountAdded={this.props.refillAmountAdded}
                  disabledPermission={this.props.disabledPermission}
                  tags={this.props.tags}
                  //methods
                  onChange={this.props.onChange}
                  onRefillChange={this.props.onRefillChange}
                  onAddOrganismClick={this.props.onAddOrganismClick}
                  onDeleteRefillOrganismClick={this.props.onDeleteRefillOrganismClick}
                  onDeleteOrganismClick={this.props.onDeleteOrganismClick}
                  changeStructureFunction={this.props.changeStructureFunction}

                  //json
                  inventory_options={this.props.inventory_options}
                  duplicate={this.props.duplicate}
                  available_inventory_json={this.props.available_inventory_json}
                  formulation_organism={this.props.formulation_organism}
                  multiSelectData={this.props.multiSelectData}
                  onSelect={this.props.onSelect}
                  multiselectRef={this.props.multiselectRef}
                  changeTagOnRefillOrganism={this.props.changeTagOnRefillOrganism}
                  changeTagOnManualWayOrganism={this.props.changeTagOnManualWayOrganism}

                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </React.Fragment>
    );
  }
}

export default Flourish;
