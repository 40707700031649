import React, { Component } from "react";
import { Form, Input } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders } from "../../../helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class PackagingStaging extends Component {
  state = { pin: "", user: "", is_probiotic: false };

  componentDidMount() {
    this.getCurrentStep("packaging_staging");
  }

  getCurrentStep = (step_name) => {
    let req = {
      formula_id: this.props.selected_row_value.formula_id,
      step_name: step_name,
    };
    axios.post(baseURL + "/get-current-step", req, getHeaders()).then((res) => {
      let result = res.data;
      if (result.success === true && result.current_step !== null) {
        let current_step = result.current_step;
        current_step.step_json = JSON.parse(current_step.step_json);
        this.setState({ step_current: current_step });
        this.onInputChange(null, {
          name: "pin",
          value: current_step.performed_by,
        });
      }
      if (this.props.selected_row_value.is_prebiotic == "No") {
        this.setState({ is_probiotic: true });
      }
    });
  };
  onInputChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    if (!value) {
      toast.error("Please fill pin number.");
      return;
    } else if (value.length === 3) {
      axios
        .post(baseURL + "/users/getUserByPin", { pin: value }, getHeaders())
        .then((res) => {
          if (res.data.success === true && res.data.user !== null) {
            let user = res.data.user;
            this.setState({ user: user });
            //this.setState({user_full_name:user.full_name});
            if(this.state.pin.length !== 3){
              this.setState({user:""});
            } 
          } else {
            this.setState({ user: "" });
          }
        });
    } else {
      this.setState({ user: "" });
    }
  };

  handleSubmit = () => {
    const { pin } = this.state;
    if (!pin) {
      toast.error("Please fill vaild pin.");
      return;
    } else if (Object.keys(this.state.user).length == 0) {
      toast.error("Please fill vaild pin.");
      return;
    }

    let param = {
      sample_id: this.props.selected_row_value.sample_id,
      formula_id: this.props.selected_row_value.formula_id,
      step: 5,
      step_json: "",
      step_name: "packaging_staging",
      step_next: "operator_mmr_review",
      performed_by: pin,
    };
    axios
      .post(baseURL + "/mmr-formulations", param, getHeaders())
      .then((res) => {
        let result = res.data;
        if (result.success === true) {
          toast.success("Successfully saved Step");
          this.props.onNextStep();
        } else {
          toast.error("Step has not saved");
        }
      });
  };
  handleClick = () => {
    // Call the function passed from the parent
    this.props.onPrevClick("label_generation");
  };
  handlePauseClick = () => {
    this.props.onNextClick("pause");
  };
  handleQuitClick = () => {
    this.props.onNextClick("quit");
  };

  handleNextClick = () => {
    // Call the function passed from the parent
    console.log(this.props.onNextClick("operator_mmr_review"));
  };
  render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <div className="cs-box">
            <div className="cs-body">
              <h3>Packaging Staging</h3>
              <ul className="cs-list">
                <li>Place labels on packaging materials</li>
                {this.state.is_probiotic && (
                  <>
                    <li>
                      Ensure rigid pill box labels include customer SG
                      identified label and nutrition label
                    </li>
                    <li>
                      Ensure sachets labels include fmrx and SG identifier label
                    </li>
                  </>
                )}
                {!this.state.is_probiotic && (
                  <li>
                    Ensure tearaway box labels include customer SG identified
                    label and nutrition label.
                  </li>
                )}
              </ul>
              <div className="text-center mt-5">
                <div className="d-inline-flex">
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1">Performed By: </h4>
                    </div>
                    <div className="m-0 pe-1">
                      <h4 className="m-0">
                        <u>{this.state.user.full_name}</u>
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1"> PIN</h4>
                    </div>
                    <div className="ui input">
                      <Form.Field required fluid="true">
                        <Input
                          id="pin"
                          name="pin"
                          onChange={this.onInputChange}
                          value={this.state.pin}
                          placeholder="Enter Your PIN"
                          type="password"
                          className="full_opacity"
                          autoComplete="new-password"
                        />
                      </Form.Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cs-footer text-center">
              <hr />
              <div className="text-center mt-2">
                <button
                  type="button"
                  onClick={this.handleClick}
                  className="gray-btn"
                >
                  Prev
                </button>
                <button
                  type="button"
                  onClick={this.handlePauseClick}
                  className="ui icon secondary labeled button pause-btn ps-1-5em ms-2"
                >
                  Pause
                </button>
                <button
                  type="button"
                  onClick={this.handleQuitClick}
                  className="ui icon secondary labeled button quit-btn ps-1-5em ms-2"
                >
                  Quit
                </button>
                {this.props.step_name === "label_generation" && (
                  <button type="submit" className="theme-btn ms-2">
                    Next
                  </button>
                )}
                {this.props.step_name !== "label_generation" && (
                  <button
                    type="button"
                    onClick={this.handleNextClick}
                    className="theme-btn ms-2"
                  >
                    {" "}
                    Next{" "}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default PackagingStaging;
